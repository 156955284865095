import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar'
import Footer from '../../_components/user/footer/footer'
import EmailAccountsTabing from '../../_components/user/EmailAccounts/EmailAccountsTabing';
import EmailAccountTable from '../../_components/clients/emailaccounts/EmailAccountTable'




class CEmailAccountsPage extends React.Component {
    componentDidMount() {
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    }
    render() {
        return (
            <>
                <div className="adminmain mheight">
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <EmailAccountTable /> 
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState (state) {
  const { loggingIn } = state.authentication
  return { loggingIn }
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
}

const connectedCEmailAccountsPage = connect(
  mapState,
  actionCreators
)(CEmailAccountsPage)
export { connectedCEmailAccountsPage as CEmailAccountsPage }