import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import { history } from "../../_helpers";
import { userActions } from '../../_actions';
import { GetUserDetails } from "../../_helpers/Utility";
import Clients from '../../_components/user/Clients/Clients';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import { CommonConstants } from "../../_constants/common.constants";
import { ContactlessOutlined } from "@material-ui/icons";
import Footer from '../../_components/user/footer/footer';


class ClientsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          ActiveCount:0,
          AllClientCount:0,
          Churn:0
        };
        var Details = GetUserDetails();
        
        if (Details != null) {
            this.setState({ ClientID: Details.ClientID });
            this.setState({ UserID: Details.ChildUserID });
        }
        //this.GetCounts(Details.ParentUserID)
      }
    
      componentDidMount() {
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    }

      GetCounts(PUID){
        const InputParameters = {
            ParentUserID: PUID,
        }
        Axios({
            url: CommonConstants.MOL_APIURL + "/clients/ClientCounts",
            method: "POST",
            data: InputParameters
        }).then((Res) => {
            if(Res.data != null)
            {
                this.setState({ActiveCount:Res.data.ActiveCount});
                this.setState({AllClientCount:Res.data.AllClientCount});
                this.setState({Churn:Res.data.Churn});
            }
        });
      }
    
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                        <div className="row">
                            {/* <div className="col">
                                <h4 className="headertitle float-left pl-0">Clients</h4>
                            </div>  */}
                        </div> 
                    
                        {/* <div className="w-100 whiterow px-5">
                        <div className="row pt-4">
                            <div className="col">
                                <div className="bg-light-sky boxcardcounter align-center">
                                    <div class="line-title mb-2">
                                        <span class="clr-sky box-main-title">{this.state.ActiveCount}</span>
                                    </div>
                                    <div class="box-inner-title">
                                        <string class="clr-sky">Total Active Clients</string>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="bg-light-sky boxcardcounter align-center">
                                    <div class="line-title mb-2">
                                        <span class="clr-sky box-main-title">{this.state.AllClientCount}</span>
                                    </div>
                                    <div class="box-inner-title">
                                        <string class="clr-sky">Total Clients All-Time</string>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="bg-light-sky boxcardcounter align-center">
                                    <div class="line-title mb-2">
                                        <span class="clr-sky box-main-title">{this.state.Churn} %</span>
                                    </div>
                                    <div class="box-inner-title">
                                        <string class="clr-sky">Churn</string>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div> */}
                    <div class="paddcols pt-0">
                        <div className="row">
                            <div className="col">
                                    <Clients/> 
                            </div>
                        </div>      
                    </div>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedClientsPage = connect(mapState, actionCreators)(ClientsPage);
export { connectedClientsPage as ClientsPage };