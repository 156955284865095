import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class CEditAccountCategoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Code: "",
      Fields: {},
      Errors: {},
      IsExist: false,
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      OldName:null,
      UserIDby:null,
      Role:null,
      IsButtonDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = `Edit Account Category | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role=Details.Role;
      this.state.UserIDby=Details.ChildUserID
    }
    this.EditAccountclientstatus();
  }

   // form validation
   async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var FieldName = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistAccountCategoryName(FieldName);
  
    if(FieldName == ""){
      FormIsValid = false;
      Errors["Name"] = "Please enter name";
    }
    if(IsExist == true){
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

    // handle change
    HandleChange(field, e) {
      
      let Fields = this.state.Fields;
      Fields[field] = e.target.value;
      this.setState({ Fields });
      if (Fields.Name != "" && Fields.Name.indexOf(" ") != 0) {
        this.state.Errors.Name = null;
        this.state.NameAvailable = null;
        this.CheckExistAccountCategoryName(e.target.value);
      } else {
        this.state.NameAvailable = null;
        this.state.Errors.Name = null;
      }
    }

//check exist name
async CheckExistAccountCategoryName(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      Role: this.state.Role
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_categories/AccountCategoriesStatusExists",
      method: "POST",
      data: str_in,
    })
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if(this.state.OldName == Name){
          this.setState({ IsExist: false });
          return false;
        }else{
          this.setState({ NameAvailable: resdata.data.Data[0]?.Name });
          this.setState({ IsExist: true });
          return true;
        }
        
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
  }

  //update account category
  EditAccountclientstatus() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var EditFields = {
      ID: id,
      Role:this.state.Role
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_categories/AccountCategoriesGetByID",
      method: "POST",
      data: EditFields,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data.Data[0].Name;
        this.setState({OldName:res.data.Data[0].Name})
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data?.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  //back to account category
  BackBtn() {
    history.push("/CAccountCategory");
  }

  //update account category
  async SaveBtn(e) {
    // this.state.Errors.Name = null;
    // this.state.NameAvailable = null;
    this.setState({IsButtonDisabled:true});
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var FinalValidation = await this.FromValidation()
    if (FinalValidation == true) {
      var FieldName = document.getElementById("Name").value.trim();
      var data = {
        ID: this.state.id,
        Name: FieldName,
        LastUpdatedBy: this.state.UserIDby,
        LastUpdatedDate: new Date(),
        Role:this.state.Role
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/account_categories/AccountCategoriesUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Account Category
              <br />
              Account category updated successfully.
            </div>
          );
          history.push("/CAccountCategory");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    } else{
      this.setState({IsButtonDisabled:false});
      //document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col ">
                  <h4 className="headertitlepad lg-headertitle float-left">
                    Edit Account Category
                  </h4>
                </div>
              </div>

              <div className="bg-white">  
              <div className="row py-3 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        id="Name"
                        name="Name"
                        placeholder="Enter Name"
                        type="text"
                      />
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )}
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="row py-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtoList"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditAccountCategoryPage = connect(
  mapState,
  actionCreators
)(CEditAccountCategoryPage);
export { connectedCEditAccountCategoryPage as CEditAccountCategoryPage };
