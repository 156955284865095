import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import CustomVariableSet from "../../_components/user/CustomVariableSet/CustomvariablesetTabing.js";
import { GetUserDetails } from "../../_helpers/Utility";
const moment = require("moment");
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
toast.configure();
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import $ from "jquery";
class CustomVariableSetPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientID: null,
      UserID: null,
      StartDate: null,
      EndDate: null,
      exportdata: {
        Start: moment(new Date().setMonth(new Date().getMonth() - 3)).format(
          "YYYY/MM/DD"
        ),
        End: moment(new Date()).format("YYYY/MM/DD"),
      },
      pageData: null,
    };
    this.daterefect = React.createRef()
    this.daterefect2 = React.createRef()
    this.DateAnalize = this.DateAnalize.bind(this);
  }
  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title = `Custom Variable Sets | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
    }
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    var start = moment(d).format("YYYY-MM-DD");
    var d1 = new Date();
    var end = moment(d1).format("YYYY-MM-DD");
    this.setState({ StartDate: start });
    this.setState({ EndDate: end });
    const currentDate = new Date();

    const previousMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
    
    const formattedDate = previousMonthDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
    
    // Set the value of the "from-date" input field
    document.getElementById("from-date").value = formattedDate;
        const $datepickerElement = $(this.daterefect.current); 
    $datepickerElement.datetimepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        container: '#app',
        todayBtn: true,
        todayHighlight: true,
        minView: '2',
    });
    document.getElementById("to-date").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    const $datepickerElement2 = $(this.daterefect2.current); 
    $datepickerElement2.datetimepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        container: '#app',
        todayBtn: true,
        todayHighlight: true,
        minView: '2',
    });
    // document.getElementById("from-date").value = start;
    // document.getElementById("to-date").value = end;
    var d = new Date();
    d.setMonth(d.getMonth() - 3);
    var start = moment(d).format("YYYY/MM/DD");
    var d1 = new Date();
    var end = moment(d1).format("YYYY/MM/DD");
    this.setState({
      exportdata: {
        Start: start,
        End: end,
      },
    });
  }
  DateAnalize() {
    // var fromdate = document.getElementById("from-date").value;
    // var todate = document.getElementById("to-date").value;
    var tempfromdate = document.getElementById("from-date").value;
        var temptodate = document.getElementById("to-date").value;
        var fromdate = moment(tempfromdate).format("YYYY/MM/DD");
        var todate = moment(temptodate).format("YYYY/MM/DD");
        if (moment(fromdate, "YYYY-MM-DD").isAfter(moment(todate, "YYYY-MM-DD"))) {
          toast.error("Please select an appropriate date range");
        } else {
          this.setState({
            exportdata: {
              Start: fromdate,
              End: todate,
            },
          });
        }
   
  }
  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };
  render() {
    return (
      <>
           
        <div className="adminmain mheight">
        <Sidebar className=""/> 
         <div className="bodyhome px-3">
          <div className="row align-items-center">
              <div className="col"> 
                  <h4 className="headertitlenop lg-headertitle float-left">Custom Variable Sets</h4>
              </div>
              <div className="col px-3">
                <div className="listing-li float-right py-3">
                  <div className="btn-group datepicker-hed">
                  <div className="col-lg-6 timedatepikker date-input">
                                <input className="form-control m-input"
                              type='text'
                              id='from-date'
                              ref={this.daterefect}
                            /> 
                             
                            </div>
                            <div className="col-lg-6 timedatepikker date-input">
                            <input className="form-control m-input"
                              type='text'
                              id='to-date'
                              ref={this.daterefect2}
                            /> 
                              </div>
                    <button
                      onClick={this.DateAnalize}
                      id="aCompanyState"
                      className="btn btn-primary btnColor m-btn btn-radius"
                      autocomplete="off"
                    >
                      <i className="la flaticon-diagram"></i>
                      <span>Analyze</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
   
          <div className="row">
            <div className="col">
              <CustomVariableSet
                daterange={this.state.exportdata}
                updateFromChild={this.UpdateFromChild}
              />
            </div>
          </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCustomVariableSetPage = connect(
  mapState,
  actionCreators
)(CustomVariableSetPage);
export { connectedCustomVariableSetPage as CustomVariableSetPage };
