import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class EditListspage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Code: "",
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      OldName:"",
      Role:""
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = "Edit List | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;

    }
    this.ListGetById();
  }
  //form validation
  async FromValidation() {
    let formIsValid = true;
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistListName(Name);
    let Errors = {};

      if (Name == "" && Name.indexOf(" ") != "") {
        formIsValid = false;
        Errors["Name"] = "Please enter Name";
      }
      if (IsExist == true) {
        formIsValid = false;
      }
      this.setState({ Errors: Errors });

    return formIsValid;
  }

  //change to check error
  HandleChange() {
    var Name = document.getElementById("Name").value.trim();
    if (Name != "" && Name.indexOf(" ") != "") {
      this.state.Errors.Name = null;
      this.state.StatusAvailable = null;
      this.CheckExistListName(Name);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  //check exist Status
  async CheckExistListName(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      UserID: this.state.UserID,
      Role:this.state.Role

    };
    var ResListData = await  Axios({
      url: CommonConstants.MOL_APIURL + "/list/CheckExistList",
      method: "POST",
      data: str_in,
    })
      if (ResListData.data.StatusMessage == "SUCCESS") {
        if (ResListData.data.Data.length > 0) {
          if(this.state.OldName == Name){
            this.setState({ IsExist: true });
            return false;
          }else{
            this.setState({ StatusAvailable: ResListData.data.Data[0].Name });
            this.setState({ IsExist: true });
            return true;
          }

        } else {
          this.setState({ IsExist: false });
          return false;
        }
      }
  }

  // Get by id List Name
  ListGetById() {
    console.log(this.props.location.state,"vd")
    const id = this.props.location.state.data;
    this.setState({ id: id });
    var str_in = {
      id: id,
      Role:this.state.Role

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/list/ListByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      
      if (res.data?.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data?.Data[0].Name;
        this.setState({OldName:res.data.Data[0].Name})
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data?.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  //Update List Name
  async SaveBtn(e) {
    this.state.Errors.Name = null;
    this.state.StatusAvailable = null;
    e.preventDefault();
    e.currentTarget.disabled = true;
    var FinalValidation = await this.FromValidation()
    if (FinalValidation == true) {
      var Name = document.getElementById("Name").value.trim();
      var data = {
        id: this.state.id,
        Name: Name,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
        Role:this.state.Role,
        ClientID:this.state.ClientID

      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/list/ListUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              List
              <br />
              List updated successfully.
            </div>
          );
          if (this.props.location.state["Pagename"] == "AccountContact") {
            history.push({
              pathname: "/editaccount",
              state: { data: this.props.location.state?.AccountData },
            });
          }    if (this.props.location.state["Pagename"] == "ViewContact") {
            history.push({
              pathname: "/viewcontacts",
              state: { data: this.props.location.state?.pid,
                EmailData:this.props.location.state?.EmailData 
               },
            });
          }
          else{
            history.push("/list");
          }
        } else {
          toast.error(res.data.Message);
        }
      });

  }
  else {
    document.getElementById("submit").disabled = false;
  }
}

  BackBtn() {
    // history.push("/list");
    if (this.props.location.state["Pagename"] == "AccountContact") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.props.location.state?.AccountData },
      });
    }  if (this.props.location.state["Pagename"] == "ViewContact") {
      history.push({
        pathname: "/viewcontacts",
        state: { data: this.props.location.state?.pid,EmailData:this.props.location.state.EmailData },
      });
    }
    else{
      history.push("/list");
    }
  }

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain"> 
            <Sidebar className="" /> 
          <div className="bodyhome px-3"> 
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left mb-0 py-4 ">Edit List</h4>
                </div>
              </div>

              <div className="bg-white px-4">
              <div className="row py-4">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">List Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        id="Name"
                        name="Name"
                        placeholder=""
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          List name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              </div>
              <div class="row py-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
 
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditListspage = connect(mapState, actionCreators)(EditListspage);
export { connectedEditListspage as EditListspage };
