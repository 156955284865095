import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  IconButton,
  Collapse,
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

const SortingIcons = ({ SortedBy, sortedColumn, ColumnName }) => {
  return (
    <span className="shorting">
      <ArrowUpward
        className={
          SortedBy === 1 && sortedColumn === ColumnName ? "active" : null
        }
      />
      <ArrowDownward
        className={
          SortedBy === -1 && sortedColumn === ColumnName ? "active" : null
        }
      />
    </span>
  );
};

function MeetingDialCallHistory({
  meetingId,
  UserIDs,
  ClientIDs,
  Roles,
  ChildUserIDs,
}) {
  const [Page, setPage] = useState(1);
  const [RowsPerPage, setRowsPerPage] = useState(100);
  const [Search, setSearch] = useState("");
  const [SortField, setSortField] = useState("MeetingPhoneNumber");
  const [SortedBy, setSortedBy] = useState(1);
  const [Sflag, setSflag] = useState(false);
  const [Data, setData] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Rlen, setRlen] = useState(0);
  const [Flen, setFlen] = useState(0);
  const [CountPage, setCountPage] = useState(0);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [CUserID, SetCUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [sortedColumn, setSortedColumn] = React.useState("MeetingPhoneNumber");

  useEffect(() => {
    let Details = GetUserDetails();
    if (Details != null) {
        SetClientID(Details.ClientID);
        SetUserID(Details.ParentUserID);
        SetCUserID(Details.ChildUserID);
        SetRole(Details.Role);
    }
    // Fetch initial data
    MeetingDialCallNotesDetails(
      meetingId,
      Details.ClientID,
      Details.ParentUserID,
      Search,
      Page,
      RowsPerPage
    );
  }, [Search, Page, RowsPerPage]);

  // contact note get
  const MeetingDialCallNotesDetails = (
    meetingId,
    CID,
    UID,
    Search,
    Page,
    RowPage
  ) => {
      let MeetingdialcallNoteData = {
        Page: parseInt(Page),
        ClientID: CID,
        UserID: UID,
        MeetingID: meetingId,
        RowsPerPage: parseInt(RowPage),
        Sort: true,
        Field: SortField,
        Role: Role,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingDialCallGet",
        method: "POST",
        data: MeetingdialcallNoteData,
      }).then((responsenote) => {
        if (responsenote.data.StatusMessage === "SUCCESS") {
          setData(responsenote.data?.PageData);
          setRows(responsenote.data?.PageData);
          setRlen(responsenote.data?.TotalCount);
          setFlen(responsenote.data?.TotalCount);
          setCountPage(responsenote.data?.PageCount);
        } else {
          toast.error(responsenote.data.Message);
        }
      });
    
  };

  // call note contact change display rows
  const ChangeRowSelected = (event) => {
    setPage(1);
    setRowsPerPage(Number(event.target.value));
  };

  // search for record contact note
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      let SearchedVal = document.getElementById("NotesSearch").value;
      if (SearchedVal == Search) {
        setSearch(SearchedVal);
        setPage(1);
      } else {
        setSearch(SearchedVal);
        setPage(1);
      }
    }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      setPage(NewPage);
    } else {
      setPage(NewPage);
    }
  };

  const Sorting = (Field) => {
    setSortField(Field);
    setSortedColumn(Field);
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      setSortedBy(1);
      SortBy = 1;
    } else {
      setSortedBy(SortBy);
    }

    setSortField(Field);

    MeetingDialCallNotesDetails(
      meetingId,
      ClientID,
      UserID,
      Search,
      Page,
      RowsPerPage
    );
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h3 className="xs-headertitle py-3 mb-0">Dial Session</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              aria-controls="tbl_meeting"
              onChange={ChangeRowSelected}
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="NotesSearch"
              onKeyPress={(event) => RequestSearch(event)}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => {
                    Sorting("SdrPhoneNumber");
                  }}
                >
                  From
                  <SortingIcons
                    SortedBy={SortedBy}
                    sortedColumn={sortedColumn}
                    ColumnName={"SdrPhoneNumber"}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    Sorting("MeetingPhoneNumber");
                  }}
                >
                  To
                  <SortingIcons
                    SortedBy={SortedBy}
                    sortedColumn={sortedColumn}
                    ColumnName={"MeetingPhoneNumber"}
                  />
                </TableCell>
                <TableCell>Recording</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row?.SdrPhoneNumber}</TableCell>
                    <TableCell>{row?.MeetingPhoneNumber}</TableCell>
                    <TableCell>
                      {row?.RecodingFileName ? (
                        <audio
                          controls
                          style={{ outline: "none" }}
                          type="audio/mp3"
                        >
                          <source
                            src={
                              CommonConstants.CallRecordingBookkedMeetingURL +
                              row?.RecodingFileName
                            }
                          />
                        </audio>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div class="row">
        <div class="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div class="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
}

export default MeetingDialCallHistory;
