import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientResearch from "../../_components/user/ClientResearch/ClientResearchTable";
//import CollapsibleTable from '../_components/usertable';
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { history } from "../../_helpers";
import Axios from "axios";

class ClientResearchPage extends React.Component {
  constructor() {
    super();
    this.state = {
      ExportData: [],
      CName: "",
    };
    this.AddBtn = this.AddBtn.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }
  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });

    var InputParameter = {
      UserID: Details.ParentUserID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ClientResearchExport",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      this.setState({ ExportData: Res.data?.Data });
    });
  }
  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  ExportCsv = () => {};

  //Add function
  AddBtn() {
    history.push("/addclientresearch");
  }
  render() {
    return (
      <>
        <div className="adminmain mheight">
        <Sidebar className=""/> 
            <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitle lg-headertitle  float-left">
                Client Research
                </h4>
              </div> 
 
              <div className="col">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <CSVDownloader
                        className="px-0"
                        data={this.state.ExportData}
                        filename={this.state.CName + "Client-Research"}
                        bom={true}
                      >
                        <a
                          onClick={this.ExportCsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i className="la la-icon-font-size-13 la-download"></i>
                          <span>Export</span>
                        </a>
                      </CSVDownloader>
                    </li>
                    <li>
                      <a
                        onClick={this.AddBtn}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="">
              <div className="row">
                <div className="col"> 
                    <ClientResearch updateFromChild={this.UpdateFromChild} /> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientResearchPage = connect(
  mapState,
  actionCreators
)(ClientResearchPage);
export { connectedClientResearchPage as ClientResearchPage };
