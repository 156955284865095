import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable(props) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState("CreatedDate");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = "Client Direct Mail | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ClientDirectMail(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage]);

  // Client Direct mail get list
  const ClientDirectMail = (CID, UID, URole) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/client_direct_mail/ClientDirectMailGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      SetIsLoading(true);
    }
  };

  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    SetIsLoading(true);
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search:SearchedVal,
        Archive: false,
        Sort: true,
        Field: Field,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/client_direct_mail/ClientDirectMailGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
  };

  const EditBtn = (id) => {
    history.push("/editclientdirectmail", id);
  };

  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Client Direct Mail.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          ID: id,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/client_direct_mail/ClientDirectMailDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Client Direct Mail deleted successfully.",
                "success"
              );
              SetPage(1);
              SetSearch("");
              SetIsLoading(true);
              document.getElementById("search").value = "";
              ClientDirectMail(ClientID, UserID, Role);
              history.push("/clientdirectmail");
              props.updateFromChild(true);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  return (
    <>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div class='bg-white p-3'>
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
     
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />

<Table className="table-ref"  aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >
                    Client Name
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClientName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClientName" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("FieldName");
                    }}
                  >
                    Type
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "FieldName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "FieldName" ? "active" : null} />
                </span>
                 
                </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("OrderDate");
                    }}
                  >
                    Order Date
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "OrderDate" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "OrderDate" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("Quantity");
                    }}
                  >
                    Quantity
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Quantity" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Quantity" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("Spend");
                    }}
                  >
                    Spend
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Spend" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Spend" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("Description");
                    }}
                  >
                    Description
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Description" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Description" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (Rows?.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row.ClientName}</TableCell>
                  <TableCell>{row.FieldName}</TableCell>
                  <TableCell>
                    {moment(new Date(row.OrderDate).toDateString()).format(
                      "MM/DD/YYYY"
                    )}
                  </TableCell>
                  <TableCell>{row.Quantity}</TableCell>
                  <TableCell>{row.Spend}</TableCell>
                  <TableCell
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.Description}
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        EditBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>


        <div class="row ">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
 
    </>
  );
}
