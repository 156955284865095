import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";

import "react-toastify/dist/ReactToastify.css";
toast.configure();
import loadingicon from "../../../images/loading.gif";


class CEditContactSourcepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      ContactSourceNameAvailble: null,
      ClientID: null,
      UserID: null,
      OldName: null,
      IsExist: false,
      Role: null,
      UserIDby: null,
      BtnDisabled: false,
      IsLoading:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }

  componentDidMount() {
    document.title = "Edit Contact Source | SalesHive"
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserIDby = Details.ChildUserID;
      this.state.Role = Details.Role;
      this.state.UserID = Details.ParentUserID;
    }
    this.ContactSourceEdit();
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExists = await this.ContactSourceNameCheckExist(Name);
    if (IsExists == "BlankData" && Name === "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter name";
    }

    if (IsExists === "ExistCountry") {
      FormIsValid = false;
      Errors["Name"] = "Name already exists."; // Modify the error message
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  async HandleChange(field, e) {
    let Errors = { ...this.state.Errors };
    const value = e.target.value.trim();
    let IsExists;


    if (field === "Name") { // Change "Country" to "Name"
      IsExists = await this.ContactSourceNameCheckExist(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"

      if (IsExists === "BlankData" && value === "") {
        Errors.Name = "Please enter name"; // Change "Country" to "Name"
      } else if (IsExists === "ExistCountry") {
        Errors.Name = "Name already exists."; // Change "Country" to "Name"
      } else if (IsExists === "AllDone") {
        Errors.Name = null; // Change "Country" to "Name"
      }
    }

    this.setState({ Errors });
  }

  async ContactSourceNameCheckExist(Name) {
    if (Name !== "") {
      var str_in = {
        ClientID: this.state.ClientID,
        Name: Name,
        Role: this.state.Role
      };
      let resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contactsource/ContactSourceExists",
        method: "POST",
        data: str_in,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if (this.state.OldName === Name) {

          } else {
            this.setState({ ContactSourceNameAvailble: resdata.data.Data[0]?.Name });
            return "ExistCountry";
          }
        } else {
          return "AllDone";
        }
      }
    } else {
      return "BlankData";
    }
  }

  // contact source data eidt
  ContactSourceEdit() {
    const id = this.props.location.state;
    this.setState({ id: id });

    var str_in = {
      _id: id,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contactsource/ContactSourceGetByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data.Data[0].Name;
        this.setState({ OldName: res.data.Data[0].Name })
        this.setState({IsLoading:false})

      } else {
        toast.error(res.data.Message);
        this.setState({IsLoading:false})

      }
    });
  }

  // back button
  BackBtn() {
    history.push("/CContactSource");
  }

  // save button update
  async SaveBtn(e) {
    e.preventDefault();
    this.setState({ BtnDisabled: true })

    var FinalValidation = await this.FromValidation()

    if (FinalValidation == true) {
      var Name = document.getElementById("Name").value.trim();
      var ClientPocData = {
        _id: this.state.id,
        Name: Name,
        ClientID:this.state.ClientID,
        LastUpdatedBy: this.state.UserIDby,
        LastUpdatedDt: new Date(),
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contactsource/ContactSourceUpdate",
        method: "POST",
        data: ClientPocData,
      }).then((res) => {

        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Contact source<br />Contact Source updated successfully.</div>);
          history.push("/CContactSource");
        } else {
          toast.error(res.data.Message);
          this.setState({ BtnDisabled: false })

        }
      });
    } else {
      this.setState({ BtnDisabled: false })
    }
  }

  render() {
    return (
      <>
             {
        this.state.IsLoading === true ?  <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
      }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left">
                  Edit Contact Source
                  </h4>
                </div>
              </div>

            <div class="bg-white">
              <div className="row mx-0 py-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="row mb-3 mt-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditContactSourcepage = connect(
  mapState,
  actionCreators
)(CEditContactSourcepage);
export { connectedCEditContactSourcepage as CEditContactSourcepage };
