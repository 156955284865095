import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../../_components/user/footer/footer";

import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import ActionItems from "../../_components/user/ActionItems/ActionItemsTable";
//import CollapsibleTable from '../_components/usertable';

import { history } from "../../_helpers";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
toast.configure();

class ActionItemsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FileName: "",
      ExportData: [],
      CName: "",
    };
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title="Action Items | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });

    var InputParameter = {
      ClientID: Details.ClientID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/action_items/ActionItemsExport",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      this.setState({ ExportData: Res.data.Data });
    });
  }
  ExportCsv() {
    toast.success(
      <div>
        Data exported successfully.
      </div>
    )
  }

  //Add page
  AddPage() {
    history.push("/addactionitems");
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if(value == true){
      this.componentDidMount()
    }
  }
  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome"> 
            <div className="row">
              <div className="col">
                <h4 className="headertitle float-left pl-0">Action Items</h4>
              </div>
              <div className="col">
           
                <div className="align-items-center d-flex justify-content-end pt-1"> 
                    <div className=" listing-li float-right action-li">
                      <ul>
                        <li>
                          <CSVDownloader
                                  className="px-0"
                                  data={this.state.ExportData}
                                  filename={this.state.CName + "-ActionItems"}
                                  bom={true}
                                >
                            <a
                              onClick={this.ExportCsv}
                              className="btn btngroup m-btn export-btn"
                            >
                              <i className="la la-icon-font-size-13 la-download"></i> Export </a>
                            </CSVDownloader>
                        </li>
                        <li className="px-0">
                          <a
                            onClick={() => {
                              this.AddPage();
                            }}
                            className="btn btngroup m-btn"
                          >
                            <i class="la la-icon-font-size-13 la-plus"></i>
                            <span>Add</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
              </div> 
            </div>
            <div class="paddcols">
              <div className="row">
                <div className="col">
                  <ActionItems updateFromChild={this.UpdateFromChild}/>
                </div>
              </div>
              <div className="row">
                <div className="col dataTables_info"></div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedActionItemsPage = connect(
  mapState,
  actionCreators
)(ActionItemsPage);
export { connectedActionItemsPage as ActionItemsPage };
