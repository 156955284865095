import React, { useEffect } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
const Moment = require("moment");

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import Paper from '@material-ui/core/Paper'; 
import Pagination from "@material-ui/lab/Pagination";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import ContactList from "./ContactList";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../../user/StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ListGet(GetUserData.ClientID,GetUserData.ParentUserID,GetUserData.Role);
  }, [Search, Page, RowsPerPage]);

  //get contact list
  const ListGet = (CID,UID,Role) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const listDatas = Axios({
      url: CommonConstants.MOL_APIURL + "/list/ListGet",
      method: "POST",
      data: InputParameter,
    });
    listDatas.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);
    }).catch((err)=>{
      SetRows([]);
      SetRlen(0);
      SetFlen(0);
      SetCountPage(0);
      SetIsLoading(false)
      setIsDataLoaded(true);
    });
  };


// Updated UpdateFromChild function to close the modal
const UpdateFromChild = (value, close) => {
  if (value === true) {
    ListGet(ClientID, UserID, Role);
    close(); // Close the modal
  }
};

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value.trim();
      SetSearch(SearchedVal);
      SetPage(1);
      
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
       
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    
  };

  //delete new code
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a List.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons:true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var data = {
          ListID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,

        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/list/ListDelete",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire("Deleted!", "List deleted successfully.", "success");
              SetPage(1);
              document.getElementById("search").value = "";
              ListGet(ClientID,UserID,Role);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //edit page
  const EditBtn = (ID) => {
    history.push({pathname:"/ceditlists", state:{data:ID, Pagename:"ListPage"}});
    // history.push("/editlist", ID);
  };

  //View contact page
  const ViewBtn = () => {
    history.push({pathname:"/ccontacts", state:{data:ID, Pagename:"ListPage"}});
  };

    //get sort field data
    const SortData = (Field) => {
    SetIsLoading(true)
      
      var SearchedVal = document.getElementById("search").value;
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        SetSflag(false);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          SerchBox: false,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
        };
        
        const AccountCategoriesList = Axios({
          url:CommonConstants.MOL_APIURL +"/list/ListGet",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((Result) => {
          
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

        });
      } else {
        SerchBox = true;
        SetSflag(true);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          search: SearchedVal,
          SerchBox: SerchBox,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
        };
        
        const AccountCategoriesList = Axios({
          url:CommonConstants.MOL_APIURL +"/list/ListGet",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((Result) => {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
    SetIsLoading(false)

        });
      }
    };
  return (
    <div>
      {
        IsLoading ?<div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>

      }
      
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table class='table-ref w-100' aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>  <a
                    onClick={() => {
                      SortData("Name");
                    }}
                    
                    ><b>Name</b>
                     <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Name" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Name" ? "active" : null} />
                    </span>
                    </a></TableCell>
                <TableCell><a
                    onClick={() => {
                      SortData("TotalProspect");
                    }}><b>Total Contact</b>
                    
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "TotalProspect" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "TotalProspect" ? "active" : null} />
                    </span>
                    </a></TableCell>
                    <TableCell><a
                    onClick={() => {
                      SortData("TotalMeeting");
                    }}><b>Meeting Count</b>
                     <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "TotalMeeting" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "TotalMeeting" ? "active" : null} />
                    </span></a></TableCell>
                <TableCell><a
                    onClick={() => {
                      SortData("CreatedDate");
                    }}><b>Created Date</b>
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "CreatedDate" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "CreatedDate" ? "active" : null} />
                    </span>
                    </a></TableCell>
                <TableCell><a
                    onClick={() => {
                      SortData("Prospectlist");
                    }}><b>Campaign Name</b>
                     <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Prospectlist" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Prospectlist" ? "active" : null} />
                    </span></a></TableCell>
                <TableCell><b>Action</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row?.Name.replace(/\.[^/.]+$/, "")}</TableCell>
                    <TableCell>{row?.TotalProspect}</TableCell>
                    <TableCell>{row?.TotalMeeting}</TableCell>
                    <TableCell>
                      {Moment(new Date(row?.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    {/* <TableCell>{[...new Set(row?.Prospectlist.toString())]}</TableCell> */}
                    <TableCell>
                      {row?.Prospectlist
                        ? row?.Prospectlist
                            .filter((prospect) => prospect !== null)
                            .join(', ')
                        : ''}
                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row.ListID);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                      title="Delete"
                        onClick={() => {
                          DeleteBtn(row.ListID);

                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>

                      <Popup
                        trigger={
                          <a
                          className="btn-eyesicon"
                          title="Add Contact"
                           >
                            <i class="la la-plus add-icon"></i>
                          </a>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div>
                            <div className="modal-black"></div>
                            <div className="filterPopup largerPopup">
                              <div className="paddingboxTerms">
                                <div className="modal-header py-4 px-3">
                                  <div className="w-100 d-flex px-3">
                                    <h5 className="mb-0">Contact List</h5>
                                    <button className="close" onClick={close}>
                                      <span
                                        aria-hidden="true"
                                        class="la la-remove"
                                      ></span>
                                    </button>
                                  </div>
                                </div>

                                <div className="modal-body px-3">
                                <div className="px-3">
                                  <ContactList ListID={row.ListID} handClose={close} updateFromChild={(value) => UpdateFromChild(value, close)} />
                                </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Popup>
                      <a
                        title="View"
                        onClick={() => {
                          ViewBtn();
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-eye edit-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
