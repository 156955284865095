import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";
import Footer from "../../../_components/user/footer/footer";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import { CommonConstants } from "../../../_constants/common.constants";

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));
const SortableList = SortableContainer(({ items }) => {
  return (
    <ul>
      {items.map((value, Index) => (
        <SortableItem
          id={Index}
          key={`item-${Index}`}
          index={Index}
          value={value.ClientStatusName}
        />
      ))}
    </ul>
  );
});
class ClientStatusSetOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      pager: {},
      pageOfItems: [],
      files: [],
      BtnDisabled:false,
      UserID: null,
      BtnDisabled:false
    };
    this.saveOrder = this.saveOrder.bind(this);
    this.backbtn = this.backbtn.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title="Client Statuses Order | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.UserID = Details.ChildUserID;
    }
    this.GetClientStatusOrder();
  }
  GetClientStatusOrder() {
    var InputParameter = {
      Page: 1,
      RowsPerPage: 100,
      Field: "OrderBy",
      SortBy: 1,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusGet",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      this.setState({ items: result.data.PageData });
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      items: arrayMoveImmutable(this.state.items, oldIndex, newIndex),
    });
  };

  saveOrder() {
    let OrderBy = 0;
    for (let i = 0; i < this.state.items.length; i++) {
      this.state.items[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.savedata();
  }

  savedata() {
    this.setState({BtnDisabled:true});
    let InputParameter = {
      ClientStatusList: this.state.items,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/clientstatus/ClientStatusSetOrder",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Success
            <br />
            Client status order updated successfully.
          </div>
        );
        history.push("/clientstatuses");
      } else {
        toast.error(res.data.Message);
        this.setState({BtnDisabled:false});

      }
      this.GetClientStatusOrder();
    });
  }

  backbtn() {
    history.push("/clientstatuses");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad  lg-headertitle float-left pl-0">
                  Client Statuses Order
                  </h4>
                </div>
              </div>

            <div class="bg-white">
              <div className="row py-3 mx-0">
                <div className="col-xl-12 offset-xl-12 ">
                  <span className="alertinfo">
                    &nbsp;<b>NOTE</b> : Please drag & drop item to change order of client statuses and press save button.
.
                  </span>

                  <div class="spacupgroup">
                    <SortableList
                      items={this.state.items}
                      onSortEnd={this.onSortEnd}
                    />
                  </div>
                </div>
              </div>
            </div>

              <div class="row py-4 px-2">
                <div class="pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={() => {
                      this.saveOrder();
                    }}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save order
                  </button>
                  <a
                    id="backtolist"
                    onClick={() => {
                      this.backbtn()
                    }}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientStatusSetOrderPage = connect(
  mapState,
  actionCreators
)(ClientStatusSetOrderPage);
export { connectedClientStatusSetOrderPage as ClientStatusSetOrderPage };
