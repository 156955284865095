import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Axios from "axios";
const moment = require("moment");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../../user/StickyHeader/StickyHeader";

export default function AccountAllTable({updateFromChild}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("FieldName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [sortedColumn, setSortedColumn] = React.useState("FieldName");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = `Custom Meeting Field | SalesHive`;
    
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    MeetingCustomFieldGet(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage]);


  // Get List Meeting Custom field List
  const MeetingCustomFieldGet = (CID,UID,URole) => {
    SetIsLoading(true)
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      FieldFor: "meeting",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/meetingcustom/MeetingCustomGet",
      method: "POST",
      data: InputParameter,
    });
    ApiResponse.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);
    });
  };

  // Search Query
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      let SearchedVal = document.getElementById("Search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
        
      }
    }
  };

  // Delete function
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a custom meeting field.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText:"No,cancel!",
      reverseButtons:true,
    }).then((result) => {
      if (result.isConfirmed) {
        let InputParameter = {
          _id: id,
          IsDeleted: true,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
          Role:Role
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/meetingcustom/MeetingCustomDelete",
          method: "POST",
          data: InputParameter,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Custom meeting field deleted successfully.",
                "success"
              );
             
              document.getElementById("Search").value = ""
              MeetingCustomFieldGet(ClientID,UserID,Role);
              history.push("/CCustomMeeting")
              updateFromChild(true)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //page handle
  const HandleChangePage = (event, newPage) => {
    SetPage(newPage);
  };

//page row select
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(event.target.value);
    SetPage(1);
  };

//edit page
  const editPage = (id) => {
    history.push({ pathname: "/CEditCustomMeeting", state: { data: id } });
  };

//Sort with name
  const SortData = (field) => {
    let SearchedVal = document.getElementById("Search").value;
    SetIsLoading(true)
    let serchbox;
    if (SearchedVal == "") {
      serchbox = false;
      SetSflag(false);
      let Sortfield = field;
      let Sortedby;
      if (SortedBy == 1) {
        Sortedby = -1;
        SetSortedBy(-1);
      } else {
        Sortedby = 1;
        SetSortedBy(1);
      }
      SetSortField(field);
      let InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: serchbox,
        Sort: true,
        Field: SortField,
        Sortby: Sortedby,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const ApiResponse = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/meetingcustom/MeetingCustomGet",
        method: "POST",
        data: InputParameter,
      });
      ApiResponse.then((result) => {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetIsLoading(false)
      });
    } else {
      serchbox = true;
      SetSflag(true);
      let Sortfield = field;
      let Sortedby;
      if (SortedBy == 1) {
        Sortedby = -1;
        SetSortedBy(-1);
      } else {
        Sortedby = 1;
        SetSortedBy(1);
      }
      SetSortField(field);
      let InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: serchbox,
     
        Sort: true,
        Field: SortField,
        Sortby: Sortedby,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const ApiResponse = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/meetingcustom/MeetingCustomGet",
        method: "POST",
        data: InputParameter,
      });
      ApiResponse.then((result) => {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetIsLoading(false)
      });
    }
  };


  return (
    <div>
      {
      IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
    }
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table className='table-ref' stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
              <TableCell  onClick={() => {
                      SortData("FieldName"); setSortedColumn("FieldName");
                    }}>
                    Field Name
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "FieldName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "FieldName" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row.FieldName}</TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          editPage(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        href="#"
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </div>
  );
}
