import { CkpisPage } from '../clients/CkpisPage/CkpisPage';
import { CAccountsPage } from '../clients/CAccountsPage/CAccountsPage';
import { CAddAccountPage } from '../clients/CAccountsPage/CAddAccountPage/CAddAccountPage';
import { CEditAccountsPage } from '../clients/CAccountsPage/CEditAccountsPage/CEditAccountsPage';
import { CViewAccountPage } from '../clients/CAccountsPage/CViewAccountPage/CViewAccountPage';
import { CcontactsPage } from '../clients/CcontactsPage/CcontactsPage';
import { CAddContactPage } from '../clients/CcontactsPage/CAddContactPage/CAddContactPage';
import { CEditContactPage } from '../clients/CcontactsPage/CEditContactPage/CEditContactPage';
import { CViewContactPage } from '../clients/CcontactsPage/CViewContactPage/CViewContactPage';
import { CPotentialMeetingsPage } from '../clients/CPotentialMeetingsPage/CPotentialMeetingsPage';
import { CAddPotentialMeetingsPage } from '../clients/CPotentialMeetingsPage/CAddPotentialMeetingsPage';
import { CViewPotentialMeetingsPage } from '../clients/CPotentialMeetingsPage/CViewPotentialMeetingsPage';
import { CEditPotentialMeetingsPage } from '../clients/CPotentialMeetingsPage/CEditPotentialMeetingsPage';
import { CBookedMeetingsPage } from '../clients/CBookedMeetingsPage/CBookedMeetingsPage';
import { CAddBookedMeetingPage } from '../clients/CBookedMeetingsPage/CAddBookedMeetingPage/CAddBookedMeetingPage';
import { CEditBookedMeetingPage } from '../clients/CBookedMeetingsPage/CEditBookedMeetingPage/CEditBookedMeetingPage';
import { CViewBookedMeetingPage } from '../clients/CBookedMeetingsPage/CViewBookedMeetingPage/CViewBookedMeetingPage';
import { COpportunitiesPage } from '../clients/COpportunitiesPage/COpportunitiesPage';
import { CAddOpportunitiesPage } from '../clients/COpportunitiesPage/CAddOpportunitiesPage';
import { CEditOpportunitiesPage } from '../clients/COpportunitiesPage/CEditOpportunitiesPage';
import { CViewOpportunitiesPage } from '../clients/COpportunitiesPage/CViewOpportunitiesPage';
import { CLinkedInFollowUpPage } from '../clients/CLinkedInFollowUpPage/CLinkedInFollowUpPage';
import { CReschedulingPage } from '../clients/CReschedulingPage/CReschedulingPage';
import { CCampaignsPage } from '../clients/CCampaignsPage/CCampaignsPage';
import { CAddCampaignsPage } from '../clients/CCampaignsPage/CAddCampaignsPage/CAddCampaignsPage';
import { CEditCampaignsPage } from '../clients/CCampaignsPage/CEditCampaignsPage/CEditCampaignsPage';
import { CColdCallingContactPage } from '../clients/CColdCallingContactPage/ColdCallingContactPage';
import { CColdCallingReportingPage } from '../clients/CColdCallingReportingPage/ColdCallingReportingPage';
import { CCallHistoryPage } from '../clients/CCallHistoryPage/CallHistoryPage';
import { CCallRecordingsPage } from '../clients/CCallRecordingsPage/CallRecordingsPage';
import { CListsPage } from '../clients/CListsPage/CListsPage';
import { CAddListspage } from '../clients/CListsPage/CAddListspage';
import { CEditListspage } from '../clients/CListsPage/CEditListspage';
import { CVariablePage } from '../clients/CVariablePage/CVariablePage';
import { CCustomVariablePage } from '../clients/CCustomVariablePage/CCustomVariablePage';
import { CAddCustomVariablePage } from '../clients/CCustomVariablePage/CAddCustomVariablePage';
import { CEditCustomVariablePage } from '../clients/CCustomVariablePage/CEditCustomVariablePage';
import { CCustomVariableSetPage } from '../clients/CCustomVariableSetPage/CCustomVariableSetPage';
import { CCallToActionPage } from '../clients/CCallToActionPage/CCallToActionPage';
import { CEmailHistoryPage } from '../clients/CEmailHistoryPage/CEmailHistoryPage';
import { CEmailResponcesPage } from '../clients/CEmailResponces/CEmailResponces';
import { CDocumentsPage } from '../clients/CDocumentsPage/CDocumentsPage';
import { CAddDocumentsPage } from '../clients/CDocumentsPage/CAddDocumentsPage';
import { CEditDocumentsPage } from '../clients/CDocumentsPage/CEditDocumentsPage';
import { CUsersPerClientPage } from '../clients/CUsersPerClientPage/CUsersPerClientPage';
import { CEditUsersPerClientPage } from '../clients/CUsersPerClientPage/CEditUsersPerClientPage';
import { CAddUsersPerClientPage } from '../clients/CUsersPerClientPage/CAddUsersPerClientPage';
import { CUpdateUsersPerClientPage } from '../clients/CUsersPerClientPage/CUpdateUsersPerClientPage';
import { CAccountCategoryPage } from '../clients/CAccountCategoryPage/CAccountCategoryPage';
import { CAddAccountsPage } from '../clients/CAccountCategoryPage/CAddAccountPage/CAddAccountPage';
import { CAddAccountCategoryPage } from '../clients/CAccountCategoryPage/CAddAccountCategoryPage';
import { CEditAccountCategoryPage } from '../clients/CAccountCategoryPage/CEditAccountCategoryPage';
import { CAccountStatusPage } from '../clients/CAccountStatusPage/CAccountStatusPage';
import { CAddAccountStatusPage } from '../clients/CAccountStatusPage/CAddAccountStatusPage';
import { CEditAccountStatusPage } from '../clients/CAccountStatusPage/CEditAccountStatusPage';
import { CAccountStatusOrderPage } from '../clients/CAccountStatusPage/CAccountStatusOrderPage';
import { CReasonUnqualifiedPage } from '../clients/CReasonUnqualifiedPage/CReasonUnqualifiedPage';
import { CAddReasonUnqualifiedPage } from '../clients/CReasonUnqualifiedPage/CAddReasonUnqualifiedPage';
import { CEditReasonUnqualifiedPage } from '../clients/CReasonUnqualifiedPage/CEditReasonUnqualifiedPage';
import { CReasonUnqualifiedOrderPage } from '../clients/CReasonUnqualifiedPage/CReasonUnqualifiedOrderPage';
import { CCustomAccountsFieldPage } from '../clients/CCustomAccountsFieldPage/CCustomAccountsFieldPage';
import { CAddCustomAccountsFieldPage } from '../clients/CCustomAccountsFieldPage/CAddCustomAccountsFieldPage';
import { CEditCustomAccountsFieldPage } from '../clients/CCustomAccountsFieldPage/CEditCustomAccountsFieldPage';
import { CContactReasonUnqualifiedPage } from '../clients/CContactReasonUnqualifiedPage/CContactReasonUnqualifiedPage';
import { CAddContactReasonUnqualifiedPage } from '../clients/CContactReasonUnqualifiedPage/CAddContactReasonUnqualifiedPage';
import { CEditContactReasonUnqualifiedPage } from '../clients/CContactReasonUnqualifiedPage/CEditContactReasonUnqualifiedPage';
import { CContactReasonUnqualifiedSetOrderPage } from '../clients/CContactReasonUnqualifiedPage/CContactReasonUnqualifiedSetOrderPage/CContactReasonUnqualifiedSetOrderPage';
import { CClientPOCPage } from '../clients/CClientPOCPage/CClientPOCPage';
import { CAddClientPOCPage } from '../clients/CClientPOCPage/CAddClientPOCPage';
import { CEditClientPOCPage } from '../clients/CClientPOCPage/CEditClientPOCPage';
import { CClientPOCSetOrderPage } from '../clients/CClientPOCPage/CClientPOCSetOrderPage/CClientPOCSetOrderPage';
import { CContactSourcepage } from '../clients/CContactSourcepage/CContactSourcepage';
import { CAddContactSourcepage } from '../clients/CContactSourcepage/CAddContactSourcepage';
import { CEditContactSourcepage } from '../clients/CContactSourcepage/CEditContactSourcepage';
import { CContactSourceSetOrderPage } from '../clients/CContactSourcepage/CContactSourceSetOrderPage/CContactSourceSetOrderPage';
import { CContactsCustomFieldpage } from '../clients/CCustomContactsFieldpage/CContactsCustomFieldpage';
import { CAddContactsCustomFieldpage } from '../clients/CCustomContactsFieldpage/CAddContactsCustomFieldpage';
import { CEditContactsCustomFieldpage } from '../clients/CCustomContactsFieldpage/CEditContactsCustomFieldpage';
import { CContactTagpage } from '../clients/CContactTagpage/CContactTagpage';
import { CAddContactTagpage } from '../clients/CContactTagpage/CAddContactTagpage';
import { CEditContactTagpage } from '../clients/CContactTagpage/CEditContactTagpage';
import { CBlacklistsPage } from '../clients/CBlacklistsPage/CBlacklistsPage';
import { CEmailAccountsPage } from '../clients/CEmailAccountsPage/CEmailAccountsPage';
import { EditCEmailAccountsPage } from '../clients/CEmailAccountsPage/EditCEmailAccountsPage';
import { AddCEmailAccountsPage } from '../clients/CEmailAccountsPage/AddCEmailAccountsPage';
import { CCampaignCategoriesPage } from '../clients/CCampaignCategoriesPage/CCampaignCategoriesPage';
import { CEditCampaignCategoriesPage } from '../clients/CCampaignCategoriesPage/CEditCampaignCategoriesPage';
import { CAddCampaignCategoriesPage } from '../clients/CCampaignCategoriesPage/CAddCampaignCategoriesPage';
import { CAddCampaignInCategoriesPage } from '../clients/CCampaignCategoriesPage/CAddCampaignInCategoriesPage';
import { CSendingSchedularPage } from '../clients/CSendingSchedularPage/CSendingSchedularPage';
import { CAddSendingSchedularPage } from '../clients/CSendingSchedularPage/CAddSendingSchedularPage';
import { CEditSendingSchedularPage } from '../clients/CSendingSchedularPage/CEditSendingSchedularPage';
import { CEmailSignaturePage } from '../clients/CEmailSignaturePage/CEmailSignaturePage';
import { CDefaultCountryBlackListPage } from '../clients/CDefaultCountryBlackListPage/CDefaultCountryBlackListPage';
import { CAddDefaultCountryBlacklistPage } from '../clients/CDefaultCountryBlackListPage/CAddDefaultCountryBlacklistPage';
import { CEditDefaultCountryBlacklistPage } from '../clients/CDefaultCountryBlackListPage/CEditDefaultCountryBlacklistPage';
import { CMeetingOwnerPage } from '../clients/CMeetingOwnerPage/CMeetingOwnerPage';
import { CAddMeetingOwnerPage } from '../clients/CMeetingOwnerPage/CAddMeetingOwnerPage';
import { CEditMeetingOwnerPage } from '../clients/CMeetingOwnerPage/CEditMeetingOwnerPage';
import { CMeetingGoalPage } from '../clients/CMeetingGoalPage/CMeetingGoalPage';
import { CAddMeetingGoalPage } from '../clients/CMeetingGoalPage/CAddMeetingGoalPage';
import { CEditMeetingGoalPage } from '../clients/CMeetingGoalPage/CEditMeetingGoalPage';
import { CMeetingStatusPage } from '../clients/CMeetingStatusPage/CMeetingStatusPage';
import { CAddMeetingStatusPage } from '../clients/CMeetingStatusPage/CAddMeetingStatusPage';
import { CEditMeetingStatusPage } from '../clients/CMeetingStatusPage/CEditMeetingStatusPage';
import { CMeetingStatusesOrderPage } from '../clients/CMeetingStatusPage/CMeetingStatusesOrderPage';
import { CMeetingSourcePage } from '../clients/CMeetingSourcePage/CMeetingSourcePage';
import { CAddMeetingSourcePage } from '../clients/CMeetingSourcePage/CAddMeetingSourcePage';
import { CEditMeetingSourcePage } from '../clients/CMeetingSourcePage/CEditMeetingSourcePage';
import { CMeetingReminderPage } from '../clients/CMeetingReminderPage/CMeetingReminderPage';
import { CSlackNotificationsPage } from '../clients/CSlackNotificationsPage/CSlackNotificationsPage';
import { CCustomMeetingPage } from '../clients/CCustomMeetingPage/CCustomMeetingPage';
import { CAddCustomMeetingPage } from '../clients/CCustomMeetingPage/CAddCustomMeetingPage';
import { CEditCustomMeetingPage } from '../clients/CCustomMeetingPage/CEditCustomMeetingPage';
import { CPotentialMeetingStatusPage } from '../clients/CPotentialMeetingStatusPage/CPotentialMeetingStatusPage';
import { CAddPotentialMeetingStatusPage } from '../clients/CPotentialMeetingStatusPage/CAddPotentialMeetingStatusPage';
import { CEditPotentialMeetingStatusPage } from '../clients/CPotentialMeetingStatusPage/CEditPotentialMeetingStatusPage';
import { CPotentialMeetingStatusOrderPage } from '../clients/CPotentialMeetingStatusPage/CPotentialMeetingStatusOrderPage'
import { CApiKeyPage } from '../clients/CApiKeyPage/CApiKeyPage';
import { CCampaignSummaryPage } from '../clients/CCampaignSummaryPage/CCampaignSummaryPage';
import { CDespammerPage } from '../clients/CDespammerPage/CDespammerPage';
import { CEmailValidationPage } from '../clients/CEmailValidationPage/CEmailValidationPage';
import { CEditClientPage } from '../clients/CEditClientPage/CEditClientPage';
import { CAccountCustomColumnPage } from '../clients/CAccountCustomColumnPage/CAccountCustomColumnPage'
import { CContactCustomColumnPage } from '../clients/CContactCustomColumnPage/CContactCustomColumnPage'
import { CEditMyProfilePage } from '../clients/CMyProfile/CEditMyProfilePage'
import { PhoneSettingPage } from '../user/PhoneSettingsPage'
import { BookedMeetingCustomColumnPage } from '../user/BookedMeetingCustomColumnPage/BookedMeetingCustomColumnPage'
import {HelpPage}  from '../user/HelpPage';
import {AddKnowledgeBaseArticlePage}  from '../user/KnowledgeBasePage';
import {ArticleCategoriesPage}  from '../user/ArticleCategoriesPage';
import {ArticleListsPage}  from '../user/ArticleListsPage';

export const clientRoutes = [
    { path: '/ckpis', component: CkpisPage },
    { path: '/caccounts', component: CAccountsPage },
    { path: '/caddaccounts', component: CAddAccountPage },
    { path: '/ceditaccounts', component: CEditAccountsPage },
    { path: '/cviewaccounts', component: CViewAccountPage },
    { path: '/ccontacts', component: CcontactsPage },
    { path: '/caddcontacts', component: CAddContactPage },
    { path: '/ceditcontacts', component: CEditContactPage },
    { path: '/cviewcontacts', component: CViewContactPage },
    { path: '/cpotentialmeetings', component: CPotentialMeetingsPage },
    { path: '/caddpotentialmeetings', component: CAddPotentialMeetingsPage },
    { path: '/ceditpotentialmeetings', component: CEditPotentialMeetingsPage },
    { path: '/cviewpotentialmeetings', component: CViewPotentialMeetingsPage },
    { path: '/cbookedmeetings', component: CBookedMeetingsPage },
    { path: '/caddbookedmeetings', component: CAddBookedMeetingPage },
    { path: '/ceditbookedmeetings', component: CEditBookedMeetingPage },
    { path: '/cviewbookedmeetings', component: CViewBookedMeetingPage },
    { path: '/copportunities', component: COpportunitiesPage },
    { path: '/caddopportunities', component: CAddOpportunitiesPage },
    { path: '/ceditopportunities', component: CEditOpportunitiesPage },
    { path: '/cviewopportunities', component: CViewOpportunitiesPage },
    { path: '/clinkedinfollowup', component: CLinkedInFollowUpPage },
    { path: '/crescheduling', component: CReschedulingPage },
    { path: '/ccampaigns', component: CCampaignsPage },
    { path: '/caddcampaigns', component: CAddCampaignsPage },
    { path: '/ceditcampaigns', component: CEditCampaignsPage },
    { path: '/ccoldcallingContact', component: CColdCallingContactPage },
    { path: '/ccoldcallingReporting', component: CColdCallingReportingPage },
    { path: '/ccallhistory', component: CCallHistoryPage },
    { path: '/ccallrecordings', component: CCallRecordingsPage },
    { path: '/clists', component: CListsPage },
    { path: '/caddlists', component: CAddListspage },
    { path: '/ceditlists', component: CEditListspage },
    { path: '/cvariable', component: CVariablePage },
    { path: '/ccustomVariable', component: CCustomVariablePage },
    { path: '/caddcustomvariable', component: CAddCustomVariablePage },
    { path: '/ceditcustomvariable', component: CEditCustomVariablePage },
    { path: '/ccustomvariableSet', component: CCustomVariableSetPage },
    { path: '/ccalltoaction', component: CCallToActionPage },
    { path: '/cEmailHistory', component: CEmailHistoryPage },
    { path: '/CEmailResponces', component: CEmailResponcesPage },
    { path: '/cdocuments', component: CDocumentsPage },
    { path: '/cadddocuments', component: CAddDocumentsPage },
    { path: '/ceditdocuments', component: CEditDocumentsPage },
    { path: '/cusersperclient', component: CUsersPerClientPage },
    { path: '/ceditusersperClient', component: CEditUsersPerClientPage },
    { path: '/caddUsersPerClient', component: CAddUsersPerClientPage },
    { path: '/cupdateusersperclient', component: CUpdateUsersPerClientPage },
    { path: '/caccountcategory', component: CAccountCategoryPage },
    { path: '/caddaccountcategory', component: CAddAccountCategoryPage },
    { path: '/caddaccountsincategory', component: CAddAccountsPage },
    { path: '/ceditaccountcategory', component: CEditAccountCategoryPage },
    { path: '/caccountstatus', component: CAccountStatusPage },
    { path: '/caddaccountStatus', component: CAddAccountStatusPage },
    { path: '/ceditaccountStatus', component: CEditAccountStatusPage },
    { path: '/caccountstatusOrder', component: CAccountStatusOrderPage },
    { path: '/caccountreasonUnqualified', component: CReasonUnqualifiedPage },
    { path: '/caddaccountreasonUnqualified', component: CAddReasonUnqualifiedPage },
    { path: '/ceditaccountreasonUnqualified', component: CEditReasonUnqualifiedPage },
    { path: '/caccountreasonUnqualifiedOrder', component: CReasonUnqualifiedOrderPage },
    { path: '/ccustomaccountsfield', component: CCustomAccountsFieldPage },
    { path: '/caddcustomaccountsfield', component: CAddCustomAccountsFieldPage },
    { path: '/ceditcustomaccountsField', component: CEditCustomAccountsFieldPage },
    { path: '/ccontactreasonUnqualified', component: CContactReasonUnqualifiedPage },
    { path: '/caddcontactreasonUnqualified', component: CAddContactReasonUnqualifiedPage },
    { path: '/ceditcontactreasonUnqualified', component: CEditContactReasonUnqualifiedPage },
    { path: '/ccontactreasonunqualifiedsetorder', component: CContactReasonUnqualifiedSetOrderPage },
    { path: '/cclientpoc', component: CClientPOCPage },
    { path: '/caddclientpoc', component: CAddClientPOCPage },
    { path: '/ceditclientpoc', component: CEditClientPOCPage },
    { path: '/cclientpocsetorder', component: CClientPOCSetOrderPage },
    { path: '/ccontactsource', component: CContactSourcepage },
    { path: '/caddcontactsource', component: CAddContactSourcepage },
    { path: '/ceditcontactsource', component: CEditContactSourcepage },
    { path: '/ccontactsourcesetorder', component: CContactSourceSetOrderPage },
    { path: '/ccontactscustomField', component: CContactsCustomFieldpage },
    { path: '/caddcontactscustomfield', component: CAddContactsCustomFieldpage },
    { path: '/ceditcontactscustomfield', component: CEditContactsCustomFieldpage },
    { path: '/ccontacttag', component: CContactTagpage },
    { path: '/caddcontacttag', component: CAddContactTagpage },
    { path: '/ceditcontacttag', component: CEditContactTagpage },
    { path: '/cblacklists', component: CBlacklistsPage },
    { path: '/cemailaccounts', component: CEmailAccountsPage },
    { path: '/ceditemailaccounts', component: EditCEmailAccountsPage },
    { path: '/caddemailaccounts', component: AddCEmailAccountsPage },
    { path: '/ccampaigncategories', component: CCampaignCategoriesPage },
    { path: '/caddcampaigncategories', component: CAddCampaignCategoriesPage },
    { path: '/caddcampaignincategories', component: CAddCampaignInCategoriesPage },
    { path: '/ceditcampaigncategories', component: CEditCampaignCategoriesPage },
    { path: '/csendingschedular', component: CSendingSchedularPage },
    { path: '/caddsendingschedular', component: CAddSendingSchedularPage },
    { path: '/ceditsendingschedular', component: CEditSendingSchedularPage },
    { path: '/cemailsignature', component: CEmailSignaturePage },
    { path: '/cdefaultcountryblacklist', component: CDefaultCountryBlackListPage },
    { path: '/cadddefaultcountryblacklist', component: CAddDefaultCountryBlacklistPage },
    { path: '/ceditdefaultcountryblacklist', component: CEditDefaultCountryBlacklistPage },
    { path: '/cmeetingowner', component: CMeetingOwnerPage },
    { path: '/caddmeetingowner', component: CAddMeetingOwnerPage },
    { path: '/ceditmeetingowner', component: CEditMeetingOwnerPage },
    { path: '/cmeetinggoal', component: CMeetingGoalPage },
    { path: '/caddmeetinggoal', component: CAddMeetingGoalPage },
    { path: '/ceditmeetinggoal', component: CEditMeetingGoalPage },
    { path: '/cmeetingstatus', component: CMeetingStatusPage },
    { path: '/caddmeetingstatus', component: CAddMeetingStatusPage },
    { path: '/ceditmeetingstatus', component: CEditMeetingStatusPage },
    { path: '/cmeetingstatusesorder', component: CMeetingStatusesOrderPage },
    { path: '/cmeetingsource', component: CMeetingSourcePage },
    { path: '/caddmeetingsource', component: CAddMeetingSourcePage },
    { path: '/ceditmeetingsource', component: CEditMeetingSourcePage },
    { path: '/cmeetingreminder', component: CMeetingReminderPage },
    { path: '/cslacknotifications', component: CSlackNotificationsPage },
    { path: '/ccustommeeting', component: CCustomMeetingPage },
    { path: '/caddcustommeeting', component: CAddCustomMeetingPage },
    { path: '/ceditcustommeeting', component: CEditCustomMeetingPage },
    { path: '/cpotentialmeetingStatus', component: CPotentialMeetingStatusPage },
    { path: '/caddpotentialmeetingstatus', component: CAddPotentialMeetingStatusPage },
    { path: '/ceditpotentialmeetingstatus', component: CEditPotentialMeetingStatusPage },
    { path: '/cpotentialmeetingstatusorder', component: CPotentialMeetingStatusOrderPage },
    { path: '/capiKey', component: CApiKeyPage },
    { path: '/ccampaignsummary', component: CCampaignSummaryPage },
    { path: '/cdespammer', component: CDespammerPage },
    { path: '/cemailvalidation', component: CEmailValidationPage },
    { path: '/ceditclient', component: CEditClientPage },
    { path: '/ccontactcustomcolumn', component: CContactCustomColumnPage },
    { path: '/caccountcustomcolumn', component: CAccountCustomColumnPage },
    { path: '/ceditmyprofile', component: CEditMyProfilePage },
    { path: '/cphonesettings', component: PhoneSettingPage },
    { path: '/bookedmeetingcustomcolumn', component: BookedMeetingCustomColumnPage },
    { path: '/chelp', component: HelpPage },
    { path: '/caddarticle', component: AddKnowledgeBaseArticlePage },
    { path: '/ceditarticle', component: AddKnowledgeBaseArticlePage },
    { path: '/carticlecategories', component: ArticleCategoriesPage },
    { path: '/carticlelists', component: ArticleListsPage },
];
