import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import StyleHeader from "../StickyHeader/StickyHeader";

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [sortedColumn, setSortedColumn] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [ExportData, SetExportData] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = "User Per Clients | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    ExportDataMethod(Details.ClientID, Details.ParentUserID);
    UserPerClients(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  //Export Data
  const ExportDataMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
    };

    Axios({
      url:
        CommonConstants.MOL_APIURL + "/user_per_clients/UserPerClientsExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetExportData(Res.data.Data);
    });
  };
  const ExportCsv = () => {
    toast.success(
      <div className="toastsize">
        User Per Client <br />
        Data exported successfully.
      </div>
    );
  };

  //   // User per clients get list
  const UserPerClients = (CID, UID, URole) => {
    SetIsLoading(true)
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/user_per_clients/UserPerClientsGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }

    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const AccountCategoriesList = Axios({
      url: CommonConstants.MOL_APIURL + "/user_per_clients/UserPerClientsGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  };

  const EditBtn = (id) => {
    history.push("/editusersperclient", { id: id, page: "UserPerClients" });
  };

  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a user per client.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          ID: id,
          LastUpdatedBy: CUserID,
          LastUpdatedDt: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/user_per_clients/UserPerClientsDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "user per client deleted successfully.",
                "success"
              );
              UserPerClients(ClientID, UserID, Role);
              ExportDataMethod(ClientID, UserID);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  return (
    <>
     {
      IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
    }
      <div className="row align-items-center">
        <div className="col ">
          <h4 className="headertitle lg-headertitle  float-left pl-0">
            User Per Clients
          </h4>
        </div>
        <div className="col ">
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <CSVDownloader
                  className="px-0"
                  data={ExportData}
                  filename={`UserPerClient`}
                  bom={true}
                >
                  <a
                    onClick={ExportCsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <span>
                      <i class="la la-icon-font-size-13 la-download"></i>
                      Export
                    </span>
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div class="bg-white p-3">
        <div className="row pt-2">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label class="textlabelte">
              Search:
              <input
                type="Search"
                id="search"
                onKeyPress={RequestSearch}
                class="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>

        <div className="table-bordered">
          <TableContainer component={Paper}>
            <StyleHeader isDataLoaded={isDataLoaded} />

            <Table className="table-ref" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >
                    Client Name{" "}
                    <span className="shorting">
                      <ArrowUpward
                        className={
                          SortedBy === 1 && sortedColumn === "ClientName"
                            ? "active"
                            : null
                        }
                      />
                      <ArrowDownward
                        className={
                          SortedBy === -1 && sortedColumn === "ClientName"
                            ? "active"
                            : null
                        }
                      />
                    </span>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      SortData("UsersPerClientName");
                    }}
                  >
                    User Per Client
                    <span className="shorting">
                      <ArrowUpward
                        className={
                          SortedBy === 1 &&
                          sortedColumn === "UsersPerClientName"
                            ? "active"
                            : null
                        }
                      />
                      <ArrowDownward
                        className={
                          SortedBy === -1 &&
                          sortedColumn === "UsersPerClientName"
                            ? "active"
                            : null
                        }
                      />
                    </span>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      SortData("Email");
                    }}
                  >
                    Email
                    <span className="shorting">
                      <ArrowUpward
                        className={
                          SortedBy === 1 && sortedColumn === "Email"
                            ? "active"
                            : null
                        }
                      />
                      <ArrowDownward
                        className={
                          SortedBy === -1 && sortedColumn === "Email"
                            ? "active"
                            : null
                        }
                      />
                    </span>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows.map((row) => (
                    <TableRow key={row.name} row={row}>
                      <TableCell>{row.ClientName}</TableCell>
                      <TableCell
                        className="labeltds d-flex align-items-center"
                        scope="row"
                      >
                        <div class="carduser_pic">
                          <img
                            style={imgstyle}
                            src={
                              row.ProfileImage == ""
                                ? CommonConstants.Image_url + "default.png"
                                : CommonConstants.Image_url + row.ProfileImage
                            }
                          ></img>
                        </div>
                        {row.UsersPerClientName}
                      </TableCell>
                      <TableCell>{row.Email}</TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            EditBtn(row?._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-edit-1 edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteBtn(row?._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i class="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div class="row ">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </>
  );
}
