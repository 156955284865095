import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    
  } from '@material-ui/core';
  import moment from 'moment';
  import Pagination from "@material-ui/lab/Pagination";
  import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from 'react-toastify';



function List({UserID,ClientID,Role,AccountID,Domain}) {

    const [Data, setData] = useState([]);
    const [Rows, setRows] = useState([]);
    const [RLen, setRLen] = useState(0);
    const [FLen, setFLen] = useState(0);
    const [CountPage, setCountPage] = useState(0);
    const [Page, setPage] = useState(1);
    const [RowsPerPage, setRowsPerPage] = useState(15);
    const [Search, setSearch] = useState("");
    const [SortField, setSortField] = useState("CreateDate");
    const [SortedBy, setSortedBy] = useState(-1);
    const [Sflag, setSflag] = useState(false);

    useEffect(() => {
        FetchingMethod(Domain,Search, Page, RowsPerPage);
      }, [Search, Page, RowsPerPage, SortField, SortedBy,Domain,AccountID,ClientID]);

      // Lists get all
 const FetchingMethod = (DomainList, Search, Page, RowPage) => {
    if(Domain?.trim() != "" && AccountID?.trim() != "" && ClientID?.trim() != "" && UserID?.trim() !== ""){
        const RequestData = {
        Page: parseInt(Page),
        ClientID: ClientID,
        Domain: DomainList,
        RowsPerPage: parseInt(RowPage),
        Sort: true,
        Field: SortField,
        Role: Role,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        };
        Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/ListsGet",
        method: "POST",
        data: RequestData,
        }).then((Response) => {
          if(Response.data.StatusMessage === "SUCCESS"){
            setData(Response.data?.PageData);
            setRows(Response.data?.PageData);
            setRLen(Response.data?.TotalCount);
            setFLen(Response.data?.TotalCount);
            setCountPage(Response.data?.PageCount);
          }else{
            toast.error(Response.data.Message)
          }
            
        });
    }
  }

  //Sorting Contact list
 const SortingMethod = (FieldName) => {
    setSortField(FieldName)
    setSortedBy(-SortedBy)
  }

  // search for record
 const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("ListSearch").value;
        setSearch(SearchedVal)
      }
  };

  // change display rows
 const ChangeRowSelected = (Event) => {
    setRowsPerPage(Event.target.value)
    setPage(1)
  };

  // change page
const HandleChangePage = (Event, NewPage) => {
    if (NewPage != Page) {
    setPage(NewPage)
    }
  };

  // edit contact page
 const EditLists = (id) => {
    // history.push("/editlist", id);
    history.push({
      pathname: "/ceditlists",
      state: {
        data: id,
        Pagename: "AccountContact",
        AccountData: AccountID,
      },
    });
  };

  // add contact page
 const ListsAdd = () => { 
    // history.push("/addlist");
    history.push({
      pathname: "/caddlists",
      state: { data: AccountID, Pagename: "AccountContact" },
    });
    
  };


  return (
    <>
        <div className="row border-bottom mx-0 align-items-center py-3 ">
              <div className="col d-flex align-items-center">
                <h3 className=" float-left mb-0 xs-headertitle addpotential_header">Lists</h3>
              </div>
              <div>
              <div className="col d-flex align-items-center">
              <button
                onClick={ListsAdd}
                className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air "
              >
                <i class="la la-plus la-icon-font-size-13"></i>
                <span className="Mobile_button">Add</span>
              </button>
              </div>
              </div>
            </div>
            <div className="row padt-25 mx-0">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={ChangeRowSelected}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="ListSearch"
                    onKeyPress={(event) => RequestSearch(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div class='px-3'>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortingMethod("Name");
                          }}
                        >
                          Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortingMethod("CreatedDate");
                          }}
                        >
                          Created Date
                        </a>
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Rows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      Rows?.map((row) => (
                        <TableRow>
                          <TableCell>{row.Name}</TableCell>
                          <TableCell>
                            {row.CreatedDate
                              ? moment(
                                  new Date(row.CreatedDate).toDateString()
                                ).format("MM/DD/YYYY")
                              : ""}
                          </TableCell>

                          <TableCell>
                            <a
                              onClick={() => {
                                EditLists(row._id);
                              }}
                            >
                              <i className="la flaticon-edit-1 edit-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            </div>
            {/* {Sflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {RLen == 0
                      ? 0
                      : (Page - 1) * RowsPerPage +
                        1}{" "}
                    to{" "}
                    {Page * RowsPerPage >
                    RLen
                      ? RLen
                      : Page * RowsPerPage}{" "}
                    of {RLen} entries (filtered from{" "}
                    {FLen} total entries)
                  </p>
                </div>
              </div>
            ) : ( */}
              <div class="row mx-0  pb-2">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {RLen == 0
                      ? 0
                      : (Page - 1) * RowsPerPage +
                        1}{" "}
                    to{" "}
                    {Page * RowsPerPage >
                    RLen
                      ? RLen
                      : Page * RowsPerPage}{" "}
                    of {RLen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    onChange={HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            {/* )} */}
    </>
  )
}

export default List