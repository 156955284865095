import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
let CryptoJS = require("crypto-js");
import Popupone from "reactjs-popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from '@mui/material/Switch';
import Footer from "../../../_components/user/footer/footer";
import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
const EmailTemplete = require("../../../_helpers/email-templete");
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails,EmailValidation } from "../../../_helpers/Utility";
const Fileupload = require("../../../_helpers/fileupload");
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

toast.configure();

import loadingicon from "../../../images/loading.gif";

import ProfileImageUpload from '../../../_components/ProfileUpload';
const REACT_APP_ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT

const imgstyle = { width: "100px", height: "100px" };

class EditUsersPerClientPage extends React.Component {
  constructor(props) {
    let Details = GetUserDetails();
    super(props);
    this.childRef = React.createRef();

    this.state = {
      Profile: [],
      UserProfile: [],
      FinalImg: "",
      FileImg: "",
      PopUpVal: true,
      InvitePopup: false,
      ImgFlag: false,
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      Role:Details.Role,
      Message:"",
      Email:"",
      BtnDisabled:false,
      FormData:{
        FirstName: "",
        LastName: "",
        Email: "",
        ProfileImage: "",
        ID: "",
        UserID:"",
        Password:"",
        IsTwoStepVerification:"",
        IsSentPotentialMeetingNotification:"",
        IsSentMeetingNotification:"",
        IsSentWeeklyEmailNotification:"",

      },
      errors:{},
      showPassword: false,
      ButtonDisabled:false,
      IsLoading:true,
    };
        
    this.GetUserPerClient();
    this.ResetPassword = this.ResetPassword.bind(this);
    this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this);
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }

  componentDidMount() {
    document.title="Edit | SalesHive";
    
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  // Method for decrypt user password
  decryptPassword(encryptedPassword) {
    try {
      const decryptedPasswordBytes = CryptoJS.AES.decrypt(encryptedPassword.replace(/ /g, '+'), "my-secret-key@123");
      return decryptedPasswordBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Error during password decryption:', error);
      return ''; // Return an empty string as a default value
    }
  }

  // Method for fetching user details
  GetUserPerClient() {
    const Inputparameters={
      ID:this.props.location.state.id,
      Role:this.state.Role
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/users_per_client/UserPerClientGetByID",
      method: "POST",
      data:Inputparameters
    }).then((Res) => {
      let Pass;
      
      if(Res.data.Data.Password != undefined){
        Pass = this.decryptPassword(Res.data.Data?.U?.Password)
      }else{
        Pass=""
      }
      this.setState({UserProfile:Res.data.Data,FinalImg:Res.data.Data.ProfileImage})

      let Data = {
        FirstName: Res.data.Data.UsersPerClientName,
        LastName:Res.data.Data.LastName,
        Email: Res.data.Data?.U?.Username,
        ProfileImage: Res.data.Data.ProfileImage,
        ID: this.props.location.state.id,
        UserID:Res.data.Data?.UserID,
        LastUpdatedBy:this.state.UserID,
        LastUpdatedDate:new Date(),
        Password:Pass,
        IsTwoStepVerification:Res.data.Data?.IsTwoStepVerification,
        IsSentMeetingNotification:Res.data.Data?.IsSentMeetingNotification,
        IsSentPotentialMeetingNotification:Res.data.Data?.IsSentPotentialMeetingNotification,
        IsSentWeeklyEmailNotification:Res.data.Data?.IsSentWeeklyEmailNotification,
      }
      this.setState({FormData:Data})
      this.setState({Email:Res.data.Data.Email,IsLoading:false})
      
    });
  }

  // Method for form validation
  FormValidation() {
    let formIsValid = true;
      let errors = {};
    let FirstName = document.getElementById("FirstName").value;
      if (FirstName.length<=0) {
        formIsValid = false;
        errors["FirstName"] = "Please enter name.";
      }
      this.setState({ errors: errors });
    
    return formIsValid;
  }

  // Method for back button (Back to list page)
  backbtn() {
    
    if(this.props.location.state.page == "UserPerClients"){
      history.push("/userperclients");
    }else{
      history.push("/usersperclient");
    }
  }

  // Methd for upload user profile
  HandleUpload(field, e) {
    e.preventDefault();
    if (
      Fileupload.checkMimeType(e.target.files[0]) &&
      Fileupload.checkFileSize(e.target.files[0])
    ) {
      let temp = URL.createObjectURL(e.target.files[0]);
      
      this.setState({
        FinalImg: e.target.files[0],
      });
      this.setState({ ImgFlag: true });
      this.setState({ FileImg: temp });
      document.getElementById('OldImage').src=`${temp}`;
      
    } else {
    
    }
  }

  // Method for update user details
  savebtn() {
    
    
    this.setState({BtnDisabled:true})
    if(this.FormValidation()){
      let Email = document.getElementById('Email').value;
      let FirstName =  document.getElementById('FirstName').value;
      let LastName = document.getElementById('LastName').value;
      let IsTwoStepVerification=document.getElementById('IsTwoStepVerification').checked;
      let IsSentMeetingNotification =  document.getElementById('IsSentMeetingNotification').checked;
      let IsSentPotentialMeetingNotification =  document.getElementById('IsSentPotentialMeetingNotification').checked;
      let IsSentWeeklyEmailNotification =  document.getElementById('IsSentWeeklyEmailNotification').checked;
  

      let InputParameter;

      if(this.state.ImgFlag==false){
         InputParameter = {
          ID:this.props.location.state.id,
          FirstName:FirstName,
          LastName:LastName,
          Email:Email,
          IsTwoStepVerification:IsTwoStepVerification,
          IsSentMeetingNotification:IsSentMeetingNotification,
          IsSentPotentialMeetingNotification:IsSentPotentialMeetingNotification,
          IsSentWeeklyEmailNotification:IsSentWeeklyEmailNotification,
          LastUpdatedDate:new Date(),
          LastUpdatedBy:this.state.UserID,
        };
      }else{
        
        let name = this.state.FinalImg.name;
        let filetype = name.split(".");
        
        let file_Name;
        if (/\s/g.test(filetype[0])) {
          file_Name =
            filetype[0].replaceAll(/\s/g, "") +
            new Date().getTime() +
            "." +
            filetype[1] +
            "";
        } else {
          file_Name = filetype[0] + new Date().getTime() + "." + filetype[1] + "";
        }
        Fileupload.getBase64(this.state.FinalImg, (result) => {
          let rs = result;
          let splirs = rs.split("base64,");
          let _base64 = splirs[1];
          let myresult = {
            fields: "Coverimg",
            path: "./Content/UploadedFiles/UserProfilePic",
            file: _base64,
            imageName: file_Name,
          };
          Axios({
            url:CommonConstants.MOL_APIURL +"/responder_profile/ImageSave",
            method: "POST",
            data: myresult,
          }).then((res) => {
           });

        })
        InputParameter = {
          ID:this.props.location.state.id,
          FirstName:FirstName,
          LastName:LastName,
          IsTwoStepVerification:IsTwoStepVerification,
          IsSentMeetingNotification:IsSentMeetingNotification,
          IsSentPotentialMeetingNotification:IsSentPotentialMeetingNotification,
          IsSentWeeklyEmailNotification:IsSentWeeklyEmailNotification,
          LastUpdatedDate:new Date(),
          LastUpdatedBy:this.state.UserID,
          ProfileImage:file_Name,
          Role:this.state.Role

        };
      }
      
      Axios({
        url: CommonConstants.MOL_APIURL + "/users_per_client/UserPerClientUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize">Users per client<br/>Users per client updated successfully.</div>);
          if(this.props.location.state.page == "UserPerClients"){
            history.push("/userperclients");
          }else{
            history.push("/usersperclient");
          }
        } else {
        this.setState({BtnDisabled:false})
          toast.error(res.data.Message);
        }
      });
    }else{
    this.setState({BtnDisabled:false})
    } 
  }
 
  // Method for open update email popup
  OpenInvitePopup = () => {
    this.setState({ InvitePopup: true });
  }
  
  //Method for close update email popup
  CloseInvitePopup = () => {
    this.setState({ InvitePopup: false });
    this.setState({ Message: "" });
  }

  // Method for check email is already exists or not
  async  CheckUpdateEmail(){
    let Emails = document.getElementById("change_mail").value;
      let InputParameters = {
        ParentUserID: "",
        // UserID: this.state.UserID,
        Email: Emails,
      };
    let ApiResponse = await  Axios({
        url:  `${CommonConstants.MOL_APIURL}/AdminUser/UpdateEmailValidation`,
        method: "POST",
        data: InputParameters,
      });
        if (ApiResponse.data.StatusMessage == "SUCCESS") {
            if(ApiResponse.data.Flag){
                this.setState({Message:ApiResponse.data.Message});
              return false;
            }else{
              this.setState({Message:""});
              return true;
            }
            
        }
     
  }

     // Whole form validation
  async FormValidation1() {
      let formIsValid = true;
      let Email = document.getElementById("change_mail").value;
      let IsExists = await this.CheckUpdateEmail(Email);
  
      if(IsExists){
        formIsValid = true;
      }else{
        formIsValid = false;
      }
      if(Email.trim().length <=0 ){
        formIsValid=false;
        this.setState({Message:"Please enter email."})
      }else if(EmailValidation(Email) === false){
        formIsValid=false;
        this.setState({Message:"Invalid email."})
      }
    
      return formIsValid;
  }

//Email change
  async SubmitInvitePopup(e) {
    this.setState({IsLoading:true})
    e.preventDefault();
    e.currentTarget.disabled = true;
    let Final_flag =  await this.FormValidation1()
    if(Final_flag==true){
      let sendEmail = document.getElementById("change_mail").value;
      let RequestBody = {
        ClientID: this.state.ClientID,
        UserID:this.state.UserID,
        LastUpdatedBy:this.state.UserProfile.ClientUserID,
        LastUpdatedDate: new Date(),
        Email:sendEmail,
        Navigation: CommonConstants.change_Email_usersperclient
      }
      Axios({
        url: CommonConstants.MOL_APIURL + "/users_per_client/SentMailForUpdateEmail",
        method: "POST",
        data:RequestBody
      }).then((Res) => {
        let {StatusMessage,Message,Data} = Res?.data;
        if(StatusMessage == 'SUCCESS'){
            if(Data?.MailSended){
              toast.success(Message);
              this.setState({ InvitePopup: false });
            }else{
              toast.error(Message);
            }
        }else{
          toast.error(Message)
        }
        this.setState({IsLoading:false})
      })
    }else{
    this.setState({IsLoading:false})
    e.currentTarget.disabled = false;
    }

  //   e.preventDefault();
  //   e.currentTarget.disabled = true;
  //   let Final_flag =  await this.FormValidation1()
  //   if(Final_flag==true){
  //   let sendEmail = document.getElementById("change_mail").value;
  //   let tmp_token =
  //     Math.random().toString(36).substring(2, 15) +
  //     Math.random().toString(36).substring(2, 15);
  //   // let email_token = CryptoJS.MD5(tmp_token).toString();
  //   let email_token = CryptoJS.AES.encrypt(JSON.stringify(tmp_token),REACT_APP_ENCRYPTION_SALT).toString();
  //   let uid = this.state.UserProfile.ClientUserID;
  //   let str_in = {
  //     uID: uid,
  //     Email_Token: email_token,
  //   };
   
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/user/changeemail",
  //     method: "POST",
  //     data: str_in,
  //   }).then((res) => {
     
  //     if (res.data != "") {
       
  //       let Username = sendEmail;
  //       let email = CryptoJS.AES.encrypt(
  //         JSON.stringify(sendEmail),
  //         REACT_APP_ENCRYPTION_SALT
  //       ).toString();
  //       let emailData = EmailTemplete.userEmailChange(
  //         email_token,
  //         email,
  //         CommonConstants.change_Email_usersperclient
  //       );
  //       let str_mail = {
  //         email: Username,
  //         html: emailData,
  //         subject: "Please Confirm Your Email",
  //       };
  //       Axios({
  //         url: CommonConstants.MOL_APIURL + "/AdminUser/EmailSend",
  //         method: "POST",
  //         data: str_mail,
  //       }).then((res) => {
  //       toast.success("Mail sent successfully.");
          
  //       });
  //     }
  //   });
  //   this.setState({ InvitePopup: false });
  // }else{
  //   document.getElementById("m_emailInvite_submit").disabled = false;
  // }
  // this.setState({IsLoading:false})

  }
  
 //Reset password
 ResetPassword() {
  this.setState({IsLoading:true})

  let RequestBody = {
    "UserName": this.state.Email,
    "LastUpdatedDate": new Date(),
    "LastUpdatedBy": this.state.UserID
  }
  Axios({
    url: CommonConstants.MOL_APIURL + "/user/ForgetPasswordMailSent",
    method: "POST",
    data: RequestBody,
  }).then((res) => {
    if(res.data.StatusMessage == 'SUCCESS'){
      toast.success(<div className="toastsize">Client user edit <br/>Thank you, Please check your email for password reset link.</div>);
    this.setState({IsLoading:false})
    }else{
      toast.error(res.data.Message);
      this.setState({IsLoading:false})
    }
  });

  // let tmp_token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  // let forgot_token = CryptoJS.AES.encrypt(JSON.stringify(tmp_token),REACT_APP_ENCRYPTION_SALT).toString();
 
  //     let InputParameters = {
  //         "UserName": this.state.Email,
  //         "Forgot_Token":forgot_token,
  //          "LastUpdatedDate": new Date(),
  //          "LastUpdatedBy": this.state.UserID
  //     };
  //     Axios({
  //         url: CommonConstants.MOL_APIURL + "/login/forgotpassword",
  //         method: "POST",
  //         data: InputParameters
  //     }).then(resdata => {
        
  //       if (resdata.data != "") {
  //         let Username = resdata.data.data[0]?.Username;
  //         let emailData = EmailTemplete.userForgotpassword(forgot_token,this.state.Email);
  //         let InputParameters = {
  //           email: Username,
  //           html: emailData,
  //           subject: "Reset Password",
  //         };
          
  //         Axios({
  //           url: CommonConstants.MOL_APIURL + "/user/emailsend",
  //           method: "POST",
  //           data: InputParameters,
  //         }).then((res) => {
  //       toast.success(<div className="toastsize">Users per client edit <br/>Thank you, Please check your email for password reset link.</div>);
  //     });
  //     }
  //     this.setState({IsLoading:false})


  // });
}

// Function to validate individual form field
ValidateField(fieldName, value) {
  // Define your validation rules here
  const newErrors = { ...this.state.errors };
  switch (fieldName) {
    case "FirstName":
      // Check if the FirstName field is empty
      newErrors.FirstName = (value?.trim() === "" || value === null || value === undefined) ? "Please enter name." : "";
      break;
    default:
      break;
  }
  
  this.setState({ errors: newErrors });
}

//Update OnChange Handler
HandleInputChange = (e) => {
  const { name, value, type, checked } = e.target;
    
  this.setState((prevState) => {
    let updatedFormData = { ...prevState.FormData };

    if (type === "checkbox") {
      // For the checkbox, store the boolean value directly
      updatedFormData[name] = checked;
    } else {
      // For other fields, store their values
      updatedFormData[name] = value;
    }
    return {
      FormData: updatedFormData,
    };
  });
};

//Update OnBlur Handler
HandleBlur = (e) => {
  const { name, value } = e.target;
  
  
  this.ValidateField(name, value);
};

//Update Sales Strategists
HandleSubmit = async (e) => {
  e.preventDefault();
  this.setState({ ButtonDisabled: true });
  let Image = await this.childRef.current.SubmitData();
  let ImageName = Image.FileName;
  this.setState({ FinalImg: ImageName, ButtonDisabled: true });
  this.setState((prevState) => ({
    FormData: {
      ...prevState.FormData,
      ProfileImage: ImageName,
    },
  }));
  const { FormData } = this.state;
  const fieldNames = Object.keys(FormData);
  
  let isValid = true;

  fieldNames.forEach((fieldName) => {
    this.ValidateField(fieldName, FormData[fieldName]);
    if (this.state.errors[fieldName]) {
      isValid = false;
    }
  });

  if (isValid) {
    Axios({
      url: CommonConstants.MOL_APIURL + "/users_per_client/UserPerClientUpdate",
      method: "POST",
      data: FormData,
    }).then((res) => {
      
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(<div className="toastsize">Users per client<br/>Users per client updated successfully.</div>);
        if(this.props.location.state.page == "UserPerClients"){
          history.push("/userperclients");
        }else{
          history.push("/usersperclient");
        }
      } else {
      this.setState({ButtonDisabled:false})
        toast.error(res.data.Message);
      }
    });

    // You can submit the form data to your server or perform other actions here
  } else {
    this.setState({ ButtonDisabled: false });
  }
};

//Remove Quotes from password
RemoveQuotes = (str) => {
  // Check if the string starts and ends with double quotes
  if (str.startsWith('"') && str.endsWith('"')) {
      // If yes, remove the first and last character
      return str.slice(1, -1);
  } else {
      // If not, return the string as it is
      return str;
  }
}
  render() {
  const {FormData,errors} = this.state;

    return (
      <>
      {
        this.state.IsLoading ?  <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> :<></>
      }
     
         <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" className="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone">
                <div className="row max-cols">
                  <label className="col-lg-4 view-lable-meet">Email</label>
                  <div className="col-lg-8">
                    <input
                      className="form-control m-input"
                      name="Email"
                      placeholder="Email"
                      id="change_mail"
                      
                      type="text"
                      onBlur={this.CheckUpdateEmail.bind(this, "Email")}
                      />
                       <span style={{ color: "red" }}>
                          {this.state.Message}
                        </span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a
                  id="m_emailInvite_submit"
                  href="javascript:void(0);"
                  onClick={this.SubmitInvitePopup}
                  className="btn btn-primary btnColor"
                >
                  Send{" "}
                </a>
              </div>
            </div>
          </div>
        </Popupone>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row pt-4 pb-3">
                <div className="col px-3">
                  <h4 className="headertitlenop lg-headertitle float-left px-1">Edit</h4>
                </div>
              </div>

            <div className="whiterow">
              <div className="row pt-3 px-3">
                <div className="col-lg-6 boxsinput_group px-3">
                  <div className="row max-cols px-1">
                    <label className="col-lg-4 view-lable-meet">First Name </label>
                    <div className="col-lg-8">
                    <input type="text" className="form-control" id="FirstName" placeholder="Enter first name" name="FirstName" value={FormData.FirstName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.FirstName && (
                        <div className="errorinput">{errors.FirstName}</div>
                      )}
                    
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 boxsinput_group px-3">
                  <div className="row max-cols px-1">
                    <label className="col-lg-4 view-lable-meet">Last Name</label>
                    <div className="col-lg-8">
                    <input type="text" className="form-control" id="LastName" placeholder="Enter last name" name="LastName" value={FormData.LastName}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 boxsinput_group px-3">
                  <div className="row max-cols px-1">
                    <label className="col-lg-4 view-lable-meet">Email</label>
                    <div className="col-lg-8">
                    <input type="Email" className="form-control" id="Email" readOnly placeholder="Email" name="Email" value={FormData.Email}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-item-center">
                  <a href="javascript:void(0);"
                                      id="lnkUpdateEmail"
                                      onClick={this.OpenInvitePopup}
                  
                    className="btn btn-primary btngroup">Update Email</a>
                </div>
                <div className="col-lg-6 boxsinput_group px-3">
                  <div className="row max-cols px-1">
                    <label className="col-lg-4 view-lable-meet">Password</label>
                    <div className="col-lg-8 position-relative d-flex align-items-center">
                    <input  type={this.state.showPassword ? 'text' : 'password'} id="Password" className="form-control" readOnly placeholder="Password" name="Password" value={this.RemoveQuotes(FormData.Password)}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/>
                           <IconButton
                      disableRipple
                      onClick={this.handleClickShowPassword}
                      className='position-absolute rigterpin mr-2'
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-item-center">
                     
                  <button id="ResetPasswordBtn" onClick={this.ResetPassword} className="btn btn-primary btngroup ">Reset Password</button>
                </div>
                <div className="col-lg-6 my-3 px-3">
                  <div className="m-checkbox-inline px-1">
                    <label className="check_bx">
                        <input type="checkbox" id="IsSentMeetingNotification" name="IsSentMeetingNotification" checked={FormData.IsSentMeetingNotification}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/><span className="checkmark"></span>
                        Is Sent Meeting Notification
                    </label> 
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 my-3 px-3">
                  <div className="m-checkbox-inline px-1">
                    <label className="check_bx">
                        <input type="checkbox" id="IsSentPotentialMeetingNotification"  name="IsSentPotentialMeetingNotification" checked={FormData.IsSentPotentialMeetingNotification}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/><span className="checkmark"></span>
                        Is Sent Potential Meeting Notification
                    </label> 
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 my-3 px-3">
                  <div className="m-checkbox-inline px-1">
                    <label className="check_bx">
                        <input type="checkbox" id="IsSentWeeklyEmailNotification" name="IsSentWeeklyEmailNotification" checked={FormData.IsSentWeeklyEmailNotification}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/><span className="checkmark"></span>
                        Is Sent Weekly Email Notification
                    </label> 
                  </div>
                </div>
                <div className="col-lg-6"></div>
                
                <div className="col-lg-6 boxsinput_group d-flex">
                  <div className="toggleswich mr-2">
                    <input type="checkbox" className="checktogle" id="IsTwoStepVerification" name="IsTwoStepVerification" checked={FormData.IsTwoStepVerification}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}/>
                    <b className="switch"> 
                      <span className="checkion"></span>
                      <span className="uncheckion"></span>
                    </b>
                    <b className="track"></b>
                  </div>
                  <label>Is Two Step Verification</label> 
                </div>
              
                <div className="col-lg-6"></div>
                
                <ProfileImageUpload
                  profileImage={this.state.FinalImg}
                  ref={this.childRef}
                /> 

                {/* <div className="col-lg-6 boxsinput_group mb-5 px-3">
                  <div className="row max-cols px-1">
                    <label className="col-lg-4 view-lable-meet">Upload Profile Image </label>
                    <div className="col-lg-8">
                    <input type="file"
                     id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        name="avatar"
                        onChange={this.HandleUpload.bind(this, "coverimage")}/> <br/>
                    <span id="ImageTitle"></span> <br/>
                    <img src="" style={imgstyle} id="OldImage"/>
                    </div>
                  </div>
                </div>  */}
              </div>
            </div>

              <div className="row py-4">
                <div className="col-lg-12 pull-left px-3">
                  <button
                    id="submit"
                    disabled={this.state.ButtonDisabled}
                    onClick={this.HandleSubmit}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="usersperclient"
                    onClick={this.backbtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
       <Footer/>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditUsersPerClientPage = connect(
  mapState,
  actionCreators
)(EditUsersPerClientPage);
export { connectedEditUsersPerClientPage as EditUsersPerClientPage };
