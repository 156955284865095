import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import ClientSidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import Help from "../../_components/user/Help/help";
import Popupone from "reactjs-popup";
import { GetUserDetails } from "../../_helpers/Utility";
const moment = require("moment");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import $ from "jquery";
toast.configure();
class HelpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientID: null,
      UserID: null,
      CUserID: null,
      StartDate: null,
      EndDate: null,
      exportdata: {
        Start: moment(new Date().setMonth(new Date().getMonth() - 3)).format(
          "YYYY/MM/DD"
        ),
        End: moment(new Date()).format("YYYY/MM/DD"),
      },
      pageData: null,
      Role : "",
    };
    // this.daterefect = React.createRef()
    // this.daterefect2 = React.createRef()
    // this.DateAnalize = this.DateAnalize.bind(this);
  }
  componentDidMount = async () => {
    var Details = await GetUserDetails();
    if (Details != null) {
      this.setState({ Role: Details.Role })
    }
  }
  render() {
    const {Role} = this.state;
    return (
      <>   
        <div className="adminmain mheight"> 
        { Role === "Client" ? <ClientSidebar className="" /> : <Sidebar className="" /> }
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="lg-headertitle py-3 float-left">Submit a Help Ticket</h4>
              </div>
              <div className="col padright">
                
              </div>
            </div>  

          <div className="listblock">
             <Help />
          </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedHelpPage = connect(mapState, actionCreators)(HelpPage);
export { connectedHelpPage as HelpPage };
