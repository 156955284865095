import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Pagenations from "../../Pagenations";
import StyleHeader from "../StickyHeader/StickyHeader";

const imgstyle = {
  height: "40px",
  width: "40px",
  borderradius: "50%",
};
export default function AddAccountCategoryTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Reload, SetReload] = React.useState(false);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect( () => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    AdminGetMethod(GetUserData.ClientID,GetUserData.ParentUserID,GetUserData.Role);
  }, [Search, Page, RowsPerPage,Reload]);


  //get admin list
  const AdminGetMethod = (CID,UID,URole) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const AdminListAPI = Axios({
      url:
        CommonConstants.MOL_APIURL + "/AdminUser/AdminListGet",
      method: "POST",
      data: InputParameter,
    });
    AdminListAPI.then((Result) => {
      
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  };

 
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value?.trim();;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
        SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          SerchBox: false,
          Archive: false,
          Sort: true,
          Field: Field,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          Search:SearchedVal
        };
        const AdminListAPI = Axios({
          url:CommonConstants.MOL_APIURL +"/AdminUser/AdminListGet",
          method: "POST",
          data: InputParameter,
        });
        AdminListAPI.then((Result) => {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false)
        });
  };

//edit page
  const EditBtn = (ID) => {
    
    history.push("/editadmin", ID);
  };

 //Update Status
 const UpdateStatus=(ID,Flag)=>{
  
  const InputParameters={
    ID:ID,
    Flag:Flag,
    LastUpdatedBy:CUserID,
    LastUpdatedDate:new Date()
  }
  Axios({
    url: CommonConstants.MOL_APIURL + "/AdminUser/UpdateIsTwoStepVerification",
    method: "POST",
    data: InputParameters,
  }).then((res) => {
    if (res) {
      if ((res.statusText = "Ok")) {
        if(res.data.Data.IsTwoStepVerification == true){
          toast.success(<div className="toastsize">Admin <br/> Two Step Verification Activated successfully. </div>);
        }else{
          toast.success(<div className="toastsize">Admin <br/> Two Step Verification Deactivated  successfully. </div>);
        }
        AdminGetMethod(ClientID,UserID,Role)
      } else {
      }
    }
  });
}
  //delete new code
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete an admins.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var data = {
          UserProfileID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:CommonConstants.MOL_APIURL +"/AdminUser/AdminDelete",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Admins deleted successfully.",
                "success"
              );
              AdminGetMethod(ClientID,UserID,Role);
              history.push("/adminuser")
              SetPage(1)
              SetSearch("")
              document.getElementById("search").value = "";
              
             
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  return (
    <div>
      {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      <div class='bg-white p-3'>
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />

          <Table className="table-ref"  aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => { SortData("IsTwoStepVerification"); setSortedColumn("IsTwoStepVerification");}}>Is Two Step Verification <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsTwoStepVerification" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsTwoStepVerification" ? "active" : null} />
                  </span></TableCell>
                <TableCell onClick={() => {SortData("Firstname"); setSortedColumn("Firstname");}}>
                    Name
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Firstname" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Firstname" ? "active" : null} />
                  </span>
                  
                </TableCell>
                <TableCell onClick={() => { SortData("Username"); setSortedColumn("Username");}}>
                  
                    Email
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Username" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Username" ? "active" : null} />
                  </span>
                  
                </TableCell>
                <TableCell >Action  
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { Rows.length == 0 ? <p className="text-center">No data available in table</p> :
              Rows?.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>
                    <div className="toggleswich">
                      <input type="checkbox" onChange={()=>{UpdateStatus(row.UserID,!row.IsTwoStepVerification)}} className="checktogle" checked={row.IsTwoStepVerification}/>
                      <b className="switch">
                        <span className="checkion"></span>
                        <span className="uncheckion"></span>
                      </b>
                      <b className="track"></b>
                    </div>
                  </TableCell>
                  <TableCell>
                  <div className="flexcontentpro">
                      <span class="carduser_pic mr-2">
                    <img
                      style={imgstyle}
                      src={
                        row.ProfileImage == ""
                          ? CommonConstants.Image_url + "default.png"
                          : CommonConstants.Image_url + row.ProfileImage
                      }
                    ></img></span>
                    <span>{row?.FullName}</span>
                  </div>
                  </TableCell>
                  <TableCell>{row.Username}</TableCell>
                  <TableCell>
                    <a
                      
                      onClick={() => {
                        EditBtn(row._id);
                      }}
                      className="btn-eyesicon" title="Edit"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
                      
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon" title="Delete"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      <Pagenations
         Sflag={Sflag}
         CountPage={CountPage}
         HandleChangePage={HandleChangePage}
         Rlen={Rlen}
         Page={Page}
         RowsPerPage={RowsPerPage}
         Flen={Flen}
      />
      </div>
  
    </div>
  );
}
