import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@mui/material/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const label = { inputProps: { "aria-label": "Size switch demo" } };

import Pagenations from "../../Pagenations";
import StyleHeader from "../StickyHeader/StickyHeader";

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { UnarchiveIcon } from "../../common/UnarchiveIcon/UnarchiveIcon";


const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
        <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
        <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("UsersPerClientName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [PUserID, SetPUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState("UsersPerClientName");
  const [IsArchive, SetIsArchive] = React.useState(false);
  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [AnchorElf, SetfAnchorEl] = React.useState(null);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [AID, SetAID] = React.useState("");

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  useEffect(() => {
    document.title = "Users Per Client | SalesHive";

    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ChildUserID);
      SetPUserID(Details.ParentUserID);
      SetRole(Details.Role);
    }
    UsersPerClient(Details.ClientID, Details.ParentUserID, Details.Role,IsArchive);
  }, [Search, Page, RowsPerPage,IsArchive]);

  // User per client Get list
  const UsersPerClient = (CID, UID, URole,isArc) => {
    SetIsLoading(true);
    let HardBounceData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IsArchive: isArc,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientGet",
      method: "POST",
      data: HardBounceData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value.trim();
      var check = document.getElementById("include_Archive").checked;
      if(SearchedVal == Search){
        if(check === true){
          SetIsArchive(true)
          SetSearch(SearchedVal);
          
        }else{
          SetSearch(SearchedVal);
          SetIsArchive(false)
        }
      }else{
        if(check === true){
          SetIsArchive(true)
          SetSearch(SearchedVal);
        }else{
          SetSearch(SearchedVal);
          SetIsArchive(false)
        }
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };
  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }

    SetSortField(Field);
    SetSflag(SearchedVal !== "");

    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: PUserID,
      Role: Role,
      IsArchive: false
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientGet",
      method: "POST",
      data: InputParameter,
    });
    ApiResponse.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
      document.getElementById("include_Archive").checked = false
    });

    // let SearchedVal = document.getElementById("search").value;
    // SetIsLoading(true);

    // let SerchBox;
    // if (SearchedVal == "") {
    //   SerchBox = false;
    //   SetSflag(false);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     SerchBox: false,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: PUserID,
    //     Role: Role,
    //   };
    //   const AccountCategoriesList = Axios({
    //     url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   AccountCategoriesList.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false);
    //   });
    // } else {
    //   SerchBox = true;
    //   SetSflag(true);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     search: SearchedVal,
    //     SerchBox: SerchBox,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: PUserID,
    //     Role: Role,
    //   };
    //   const AccountCategoriesList = Axios({
    //     url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   AccountCategoriesList.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false);
    //   });
    // }
  };

  // Delete method
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a client users.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No,cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          ID: ID,
          LastUpdatedBy: UserID,
          ClientID:ClientID,
          LastUpdatedDate: new Date(),
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/users_per_client/UsersPerClientDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Client users deleted successfully.",
                "success"
              );
              SetIsArchive(false)
              UsersPerClient(ClientID, PUserID, Role,false);
              document.getElementById("include_Archive").checked = false
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Edit btn
  const EditBtn = (id) => {
    history.push("/editusersperclient", { id: id, page: "UsersPerClient" });
  };

  // two step verification update
  const ToggleBtnHandle = (e, ID) => {
    let InputParameter = {
      ID: ID,
      IsTwoStepVerification: e.target.checked,
      LastUpdatedBy: UserID,
      LastUpdatedDate: new Date(),
      Role: Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/users_per_client/TwoStepVerificationUpdate",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res) {
        if ((res.statusText = "Ok")) {
          if (res.data.Data.IsTwoStepVerification == true) {
            toast.success(
              <div className="toastsize">
                Client users
                <br /> Two Step Verification Activated successfully.{" "}
              </div>
            );
          } else {
            toast.success(
              <div className="toastsize">
                Client users
                <br /> Two Step Verification Deactivated successfully.{" "}
              </div>
            );
          }
          UsersPerClient(ClientID, PUserID, Role,false);
        } else {
        }
      }
    });
  };

  //Search archive
  const ClickOnSearch = () => {
    var check = document.getElementById("include_Archive").checked;
    // SetIsArchive(check);
    SetIsLoading(true)
    UsersPerClient(ClientID,PUserID,Role,check);
  };

    //Handle click
    const HandleClick = (event, id) => {
      SetAID(id);
      SetAnchorEl(event.currentTarget);
    };
  
    const HandleClickElF = (event, id) => {
      SetAID(id);
      SetfAnchorEl(event.currentTarget); 
      setSelectedRowId(id);
    };
  
    
    //Handle close Second
    const HandleCloseSecond = () => {
      SetAID(null);
      SetfAnchorEl(null);
      setSelectedRowId(null);
    };
  
  /*------------*/
    const HandleClickEl = (event, id) => {
      SetAID(id);
      SetAnchorEl(event.currentTarget); 
      setSelectedRowId(id);
    };
  
  
    const HandleCloseSecondEl = () => {
      SetAID(null);
      SetAnchorEl(null);
      setSelectedRowId(null);
    };
  
    /*------------ */
    //Handle close
    const HandleClose = () => {
      SetAID(null);
      SetAnchorEl(null);   
    };
    
    //Handle close one
    const HandleClickOne = (event, id) => {
      SetAID(id);
      SetfAnchorEl(event.currentTarget);
    };

     //Archive User
  const ArchivedUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Archive a client user.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "Archive",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/users_per_client/ArchiveProfile",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Archived!",
                "Client User Archived successfully.",
                "success"
              );
              SetIsArchive(false)
              document.getElementById("include_Archive").checked = false
              HandleClose();
              HandleCloseSecond();
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              UsersPerClient(ClientID, PUserID, Role,false);
              // UsersPerClientRender(ClientID,UserID, Role,false);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseSecond();
      }
    });
  };

  //Unarchive User
  const UnArchiveUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to unarchive a client user.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unarchive it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "UnArchive",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/users_per_client/ArchiveProfile",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Unarchived!",
                "Client user unarchived successfully.",
                "success"
              );
              SetIsArchive(false)
              HandleClose();
              HandleCloseSecond();
              document.getElementById("include_Archive").checked = false
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              UsersPerClient(ClientID, PUserID, Role,false);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseSecond();
      }
    });
  };

  // const UsersPerClientRender = (CID, UID, URole,Isarchive) => {
  //   SetIsLoading(true);
  //   let HardBounceData = {
  //     Page: Page,
  //     RowsPerPage: RowsPerPage,
  //     Sort: true,
  //     Field: SortField,
  //     SortBy: SortedBy,
  //     Search: Search,
  //     Type: "User",
  //     ClientID: CID,
  //     UserID: UID,
  //     Role: URole,
  //     IsArchive: Isarchive,
  //   };
  //   const rows1 = Axios({
  //     url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientGet",
  //     method: "POST",
  //     data: HardBounceData,
  //   });
  //   rows1.then((result) => {
  //     SetData(result.data.PageData);
  //     SetRows(result.data.PageData);
  //     SetRlen(result.data.TotalCount);
  //     SetFlen(result.data.TotalCount);
  //     SetCountPage(result.data.PageCount);
  //     SetIsLoading(false);
  //     setIsDataLoaded(true);

  //   });
  // };
  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="row pt-3">
        <div className="col padd-0"></div>
        <div className="col padright flexdisplayer pr-3 pt-0">
          <div className="fulleriger">
            <label className="check_bx floatrighter mr-3">
              <input id="include_Archive" type="checkbox" />
              <span className="checkmark"></span>
              Include Archived Client Users
            </label>
            <button
              onClick={ClickOnSearch}
              type="submit"
              className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
            >
              <span>
                <i className="la flaticon-search"></i> &nbsp;
                <span>Search</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref"  aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Is Two Step Verification</TableCell>
                <TableCell onClick={() => {SortData("UsersPerClientName"); }}>
                    Name
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"UsersPerClientName"} />
                </TableCell>
                <TableCell  onClick={() => {SortData("Email");}}>
                    Email
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Email"} />
                </TableCell>
                <TableCell onClick={() => {SortData("IsSentMeetingNotification");}}>
                    Is Sent Meeting Notification
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"IsSentMeetingNotification"} />
                </TableCell>
                <TableCell  onClick={() => {SortData("IsSentPotentialMeetingNotification");}}>
                    Is Sent Potential Meeting Notification
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"IsSentPotentialMeetingNotification"} />
                </TableCell>
                <TableCell onClick={() => { SortData("IsSentWeeklyEmailNotification"); }}>
                    Is Sent Weekly Email Notification
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"IsSentWeeklyEmailNotification"} />

                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row, index) => (
                  <TableRow key={row._id} row={row}>
                    <TableCell>
                      {row?.IsTwoStepVerification ? (
                        <div className="toggleswich">
                          <input
                            type="checkbox"
                            className="checktogle"
                            onChange={(e) => {
                              ToggleBtnHandle(e, row._id);
                            }}
                            defaultChecked
                          />
                          <b className="switch">
                            <span className="checkion"></span>
                            <span className="uncheckion"></span>
                          </b>
                          <b className="track"></b>
                        </div>
                      ) : (
                        <div className="toggleswich">
                          <input
                            type="checkbox"
                            className="checktogle"
                            onChange={(e) => {
                              ToggleBtnHandle(e, row._id);
                            }}
                          />
                          <b className="switch">
                            <span className="checkion"></span>
                            <span className="uncheckion"></span>
                          </b>
                          <b className="track"></b>
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      className="labeltds d-flex align-items-center"
                      scope="row"
                    >
                      <div className="carduser_pic">
                        <img
                          style={imgstyle}
                          src={
                            row.ProfileImage == ""
                              ? CommonConstants.Image_url + "default.png"
                              : CommonConstants.Image_url + row.ProfileImage
                          }
                        ></img>
                      </div>
                      {row?.UsersPerClientName}
                    </TableCell>

                    <TableCell>{row?.Email}</TableCell>
                    <TableCell>
                      {row?.IsSentMeetingNotification ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {row?.IsSentPotentialMeetingNotification ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {row?.IsSentWeeklyEmailNotification ? "Yes" : "No"}
                    </TableCell>
                    {/* <TableCell>
                      <a title="Edit"
                        onClick={() => {
                          EditBtn(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a title="Delete"
                        onClick={() => {
                          DeleteBtn(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell> */}
                    <TableCell>
                      {row.IsArchive ? (
                        <div>
                          <a
                            className="btn-eyesicon dopdownwe"
                            onClick={(e) => {
                              HandleClick(e, row._id);
                            }}
                          >
                            <Button
                              // aria-controls="simple-menu"
                              // aria-haspopup="true"
                              aria-controls={`simple-menu-${index}`}
                              aria-haspopup="true"
                              onClick={(e) => HandleClickEl(e, row._id)}
                              
                            >
                              <i className="la la-ellipsis-h add-icon"></i>
                            </Button>
                            <Menu
                              className="droptable"
                              anchorEl={AnchorEl}
                              open={Boolean(AnchorEl && row._id === selectedRowId)}
                              onClose={HandleCloseSecondEl}
                              id={`simple-menu-${index}`}
                            >
                              <MenuItem
                                onClick={() => {
                                  DeleteBtn(row._id);
                                }}
                              >
                                <i className="la flaticon-delete-1 mr-3"></i>{" "}
                                Delete
                              </MenuItem>
                              <MenuItem
                                onClick={() => UnArchiveUser()}
                                color="black"
                              >
                                <UnarchiveIcon className='mr-3' />
                              
                                Unarchive
                              </MenuItem>
                            </Menu>
                          </a>
                        </div>
                      ) : (
                        <div>
                          <a
                            onClick={() => {
                              EditBtn(row?._id);
                            }}
                            className="btn-eyesicon"
                            title="Edit"
                          >
                            <i className="la flaticon-edit-1 edit-icon"></i>
                          </a>
                          <a
                            className="btn-eyesicon dopdownwe"
                            onClick={(e) => {
                              HandleClickOne(e, row._id);
                            }}
                            title="Archive"
                          >
                            <Button
                              //aria-controls="simple-menu1"
                              //aria-haspopup="true"

                              aria-controls={`simple-menu-${index}`}
                              aria-haspopup="true"
                              onClick={(e) => HandleClickElF(e, row._id)}
                            >
                              <i className="la la-ellipsis-h add-icon"></i>
                            </Button>
                            <Menu
                              className="droptable"
                              //id="simple-menu1"
                              //anchorEl={AnchorElf}
                              //keepMounted
                              //open={Boolean(AnchorElf)}
                              //onClose={HandleCloseSecond} 

                              anchorEl={AnchorElf}
                              open={Boolean(AnchorElf && row._id === selectedRowId)}
                              onClose={HandleCloseSecond}
                              id={`simple-menu-${index}`}
                            >
                              <MenuItem onClick={() => ArchivedUser()}>
                                <i className="la flaticon-download mr-3"></i>{" "}
                                Archive
                              </MenuItem>
                            </Menu>
                          </a>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='px-0 py-2'>
      <Pagenations
         Sflag={Sflag}
         CountPage={CountPage}
         HandleChangePage={HandleChangePage}
         Rlen={Rlen}
         Page={Page}
         RowsPerPage={RowsPerPage}
         Flen={Flen}
      />
      </div>
    
    </>
  );
}
