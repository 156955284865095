import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import Popupone from "reactjs-popup";
var CryptoJS = require("crypto-js");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmailTemplete = require("../../../_helpers/email-templete");
import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
import SalesStrategistsTabing from "../../../_components/user/SalesStrategists/SalesStrategistsTabing";
import{GetUserDetails,EmailValidation} from '../../../_helpers/Utility'
import loadingicon from "../../../images/loading.gif";

const REACT_APP_ENCRYPTION_SALT =  process.env.REACT_APP_ENCRYPTION_SALT

class SalesStrategistsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PopUpVal: true,
      InvitePopup: false,
      CheckEmail: null,
      UserID:null,
      ParentUserID:null,
      ClientID:null,
      Errors:{},
      fields:{},
      EmailNotNull:false,
      TitleNotNull:false,
      IsExist:false,
      IsExist1:false,
      Message:"",
      ListUpdate:false,
      FormData:{
        Email:""
      },
      errors:{},
      IsLoading:false,
      IsSendingBtn: false,
    };
    this.sendButtonRef = React.createRef();
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this);
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    var Details = GetUserDetails();
    if(Details != null)
    {
        this.state.UserID= (Details.ChildUserID);
        this.state.ParentUserID=(Details.ParentUserID);
        this.state.ClientID=(Details.ClientID);
    }
    
    //document.addEventListener('mousedown', this.CloseInvitePopup);
  }
 
  async  CheckAdminInvite(Email){
    let Emails = document.getElementById("EmailInvite").value;
      var InputParameters = {
        ParentUserID: this.state.ParentUserID,
        UserID: this.state.UserID,
        Email: Emails,
        ClientID:this.state.ClientID

      };
    let resdata = await  Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/InviteValidation",
        method: "POST",
        data: InputParameters,
      });
      
        if (resdata.data.StatusMessage == "SUCCESS") {
            if(resdata.data.Flag){
                this.setState({Message:resdata.data.Message});
              return false;
            }else{
              this.setState({Message:""});
              return true;
            }
            
        }
     
  }


    OpenInvitePopup() {
      this.setState({ InvitePopup: true });
      this.setState({errors:{},FormData:{Email:""}});
    }
    CloseInvitePopup() {
      this.setState({ InvitePopup: false });
      this.setState({errors:{},FormData:{Email:""}});
    }



  // Function to validate individual form field
  async ValidateField(fieldName, value) {
    const newErrors = { ...this.state.errors };
    
    switch (fieldName) {
      case "Email":
        const trimmedValue = value.trim();
        newErrors.Email = !trimmedValue ? "Please enter email." : EmailValidation(trimmedValue) ? "" : "Invalid email.";
    
        // Check if the email already exists
        if (newErrors.Email === "") {
          const emailExists = await this.CheckAdminInvite(trimmedValue);
          if (!emailExists) {
            newErrors.Email = this.state.Message;
          }
        }
    
        break;
    
      default:
        break;
    }
    
    this.setState({ errors: newErrors });
    }
  
  //Update OnChange Handler
  HandleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((prevState) => {
      let updatedFormData = { ...prevState.FormData };

      if (type === "checkbox") {
        // For the checkbox, store the boolean value directly
        updatedFormData[name] = checked;
      } else {
        // For other fields, store their values
        updatedFormData[name] = value;
      }

      return {
        FormData: updatedFormData,
      };
    });
  };


  HandleBlur = (e) => {
    if (this.sendButtonRef.current && this.sendButtonRef.current.contains(e.relatedTarget)) {
      return;
    }
  
    const { name, value } = e.target;
    this.ValidateField(name, value);
  };

  //Send Invite
  // HandleSubmit = async (e) => {
  //   e.preventDefault();
  //   this.setState({ IsSendingBtn: true, ButtonDisabled: true,IsLoading:false });
    
  //   const { FormData } = this.state;
  //   const fieldNames = Object.keys(FormData);
  //   let isValid = true;
  //   const RequestBody ={
  //     UserID: this.state.ParentUserID,
  //     Email: Email,
  //     IsRegistered: false,
  //     IsActive: true,
  //     IsDeleted: false,
  //     VerificationCode: VerificationCodeChar,
  //     CreatedDate: new Date(),
  //     LastUpdatedBy: null,
  //     LastUpdatedDate: null,
  //     Navigation:CommonConstants.new_sales_strategists_url
  //   }

  //   // fieldNames.forEach((fieldName) => {
  //   //   this.ValidateField(fieldName, FormData[fieldName]);
  //   //   if (this.state.errors[fieldName]) {
  //   //     isValid = false;
  //   //   }
  //   // });
  //   for (let fieldName of fieldNames) {
  //     const value = FormData[fieldName];
  //     await this.ValidateField(fieldName, value);
  //     if (this.state.errors[fieldName]) {
  //       isValid = false;
  //     }
  //   }

  //   if (isValid && this.state.FormData.Email?.trim() !== "") {
  
  //   let Email = this.state.FormData.Email;
  //   var FinalEmail = CryptoJS.AES.encrypt(
  //     JSON.stringify(Email),
  //     REACT_APP_ENCRYPTION_SALT
  //   ).toString();
  //   let VerificationCodeChar = (Math.random() + 1)
  //   .toString(36)
  //   .substring(7);
  //   var UID = CryptoJS.AES.encrypt(
  //     JSON.stringify(this.state.ParentUserID),
  //     REACT_APP_ENCRYPTION_SALT
  //   ).toString();
    
  //   var VC = CryptoJS.AES.encrypt(
  //     JSON.stringify(VerificationCodeChar),
  //     REACT_APP_ENCRYPTION_SALT
  //   ).toString();
  //   var EmailData = EmailTemplete.salesStrategistsInvitation(
  //     FinalEmail,
  //     UID,
  //     VC,
  //     CommonConstants.new_sales_strategists_url
  //   );
  //   var EmailCheck = {
  //     Email: Email,
  //   };

  //   Axios({
  //     url:CommonConstants.MOL_APIURL +"/sales_strategists_profile/FindInviteUser",
  //     method: "POST",
  //     data: EmailCheck,
  //   }).then((ResFindUser) => {
  //     if (ResFindUser.data != "") {
  //       this.setState({ CheckEmail: ResFindUser.data });
  //     } else {
  //       var str_mail = {
  //         Email: Email,
  //         html: EmailData,
  //         subject: "SalesHive User Account",
  //       };
  //       Axios({
  //         url:
  //           CommonConstants.MOL_APIURL + "/sales_strategists_profile/EmailSend",
  //         method: "POST",
  //         data: str_mail,
  //       }).then((res) => {
  //         this.setState({ InvitePopup: false });
  //         if (res.data == "email send") {
            
  //           var data = {
  //             UserID: this.state.ParentUserID,
  //             Email: Email,
  //             IsRegistered: false,
  //             IsActive: true,
  //             IsDeleted: false,
  //             VerificationCode: VerificationCodeChar,
  //             CreatedDate: new Date(),
  //             LastUpdatedBy: null,
  //             LastUpdatedDate: null,
  //           };
  //           Axios({
  //             url:
  //               CommonConstants.MOL_APIURL +
  //               "/sales_strategists_profile/ClientProfileAdd",
  //             method: "POST",
  //             data: data,
  //           }).then((res) => {
  //             if (res) {
  //               this.setState({ IsSendingBtn: false });             
  //               this.setState({ ButtonDisabled: false,IsLoading:false });
  //               this.setState({ListUpdate:!this.state.ListUpdate})
  //               toast.success(<div className="toastsize">Sales Strategists<br/>
  //                 Invitation mail sent successfully.</div>)
  //                 history.push("/salesstrategists");
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });
                         

  //     // You can submit the form data to your server or perform other actions here
  //   } else {
  //     this.setState({ ButtonDisabled: false,IsLoading:false });
  //     this.setState({ IsSendingBtn: false });
  //   }
  
  // };

  HandleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ IsSendingBtn: true, ButtonDisabled: true,IsLoading:true });
    
    const { FormData } = this.state;
    const fieldNames = Object.keys(FormData);
    let isValid = true;
    for (let fieldName of fieldNames) {
      const value = FormData[fieldName];
      await this.ValidateField(fieldName, value);
      if (this.state.errors[fieldName]) {
        isValid = false;
      }
    }
    if (isValid && this.state.FormData.Email?.trim() !== "") {
      let Email = this.state.FormData.Email?.trim();
      const RequestBody ={
        UserID: this.state.ParentUserID,
        CreatedBy: this.state.UserID,
        Email: Email,
        CreatedDate: new Date(),
        Navigation:CommonConstants.new_sales_strategists_url
      }
     
      Axios({
        url:CommonConstants.MOL_APIURL +"/sales_strategists_profile/InviteSalesStrategistsUser",
        method: "POST",
        data: RequestBody,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          this.setState({ IsSendingBtn: false });
          toast.success(
            <div className="toastsize">
              Sales strategists  <br />
              Invitation mail sent successfully.
            </div>
          );
          this.setState({ ButtonDisabled: false,IsLoading:false });
          this.setState({ InvitePopup: false });
          this.setState({ ListUpdate: !this.state.ListUpdate });
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false,IsLoading:false,IsSendingBtn:false})
     
        
        }
      });
    }else{
      this.setState({ ButtonDisabled: false,IsLoading:false });
      this.setState({ IsSendingBtn: false });
    }
    
  }

  render() {
    const { FormData, errors } = this.state;

    return (
      <>
          <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent largerPopup750">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Send Mail</h5>
                <a className="close-ion" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" className="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone p-4">
                <div className="row max-cols">
                  <label className="col-lg-4 view-lable-meet">Email</label>
                  <div className="col-lg-8">
                    <input
                      className="form-control m-input"
                      id="EmailInvite"
                      name="Email"
                      placeholder="Enter Email"
                      type="text"
                      value={FormData.Email}
                        onChange={this.HandleInputChange}
                        onBlur={this.HandleBlur}
                      />
                      {errors.Email && (
                        <div className="errorinput">{errors.Email}</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  id="m_emailInvite_submit"
                  ref={this.sendButtonRef}
                  onClick={this.HandleSubmit}
                  disabled={this.state.ButtonDisabled}
                  className="btn btn-primary btnColor"
                >
                  Send{" "}
                </button>
              </div>
            </div>
          </div>
        </Popupone>
        {
          this.state.IsLoading ? <div id="hidelod" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
        }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle  pl-0">Sales Strategists</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a
                        onClick={this.OpenInvitePopup}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span> Invite</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
             

            <div className="row">
              <div className="col">
                <SalesStrategistsTabing listupdate={this.state.ListUpdate}/>
              </div>
            </div> 
          </div> 
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedSalesStrategistsPage = connect(
  mapState,
  actionCreators
)(SalesStrategistsPage);
export { connectedSalesStrategistsPage as SalesStrategistsPage };
