import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import Pagenations from "./Pagenations";
import StyleHeader from "../StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({
  AddUpdate,
  updateFromChild,
  UpdateEditKeyboard,
  HideEditBox,
  StatusUpdate
}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ProspectNoteId, SetProspectNoteId] = React.useState("");
  // const [fields, Setfields] = React.useState({});
  const [errors, Seterrors] = React.useState({});
  // const [IsExist, SetIsExist] = React.useState(false);
  const [KeywordAvailable, SetKeywordAvailable] = React.useState(null);
  const [OldKeyword, SetOldKeyword] = React.useState(null);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsEditOpen, SetIsEditOpen] = React.useState(false);
  const [IsDisableEditOpen, SetIsDisableEditOpen] = React.useState(false);
  const [HideEditKeywords, SetHideEditKeywords] = React.useState(true);
  const [sortedColumn, setSortedColumn] = React.useState("CreatedDate");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [lastSortedField, setLastSortedField] = React.useState(null); // Track the last sorted field
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    KeywordsGet(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage, AddUpdate]);

  useEffect(() => {
   // console.log("HideEditBox in useEffect: ", HideEditBox);
    SetHideEditKeywords(HideEditBox);
    if (StatusUpdate === 'success') {
      SetSearch("");
      document.getElementById("search").value = "";
    }
  }, [HideEditBox,StatusUpdate]);

  const FormValidation = async () => {
    let formIsValid = true;
    let errors = {};
    var FieldName = document.getElementById("KeywordEdit").value.trim();
    var IsExist = await CheckExistKeyword(FieldName);

    if (FieldName == "") {
      formIsValid = false;
      errors["KeywordEdit"] = "Please enter keyword";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    Seterrors(errors);
    // this.setState({ errors: errors });

    return formIsValid;
  };

  const CheckExistKeyword = async (Keyword) => {
    var InputParameter = {
      Keyword: Keyword,
      Role: Role,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/despammer/DespammerCheck",
      method: "POST",
      data: InputParameter,
    });

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data?.length > 0) {
        if (OldKeyword == Keyword) {
         // SetIsExist(false);
          return false;
        } else {
          SetKeywordAvailable(resdata.data.Data[0]?.Keyword);
       //   SetIsExist(true);
          toast.error(
            <div className="toastsize">
              Keyword
              <br />
              Keyword value already exist.
            </div>
          );
          return true;
        }
      } else {
       // SetIsExist(false);
        // this.setState({IsExist:false})
        return false;
      }
    }
  };

  const Updatekeywordbtn = async (e) => {
    errors.KeywordEdit = null;
    SetKeywordAvailable(null);
    e.preventDefault();
    SetIsDisableEditOpen(true);
    SetIsLoading(true);
    var FinalValidation = await FormValidation();
    if (FinalValidation == true) {
      //  var Keyword = document.getElementById("KeywordEdit").value
      var FieldName = document.getElementById("KeywordEdit").value.trim();

      var Inputparameter = {
        KeywordID: ProspectNoteId,
        Keyword: FieldName,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: CUserID,
        Role: Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/despammer/DespammerUpdate",
        method: "POST",
        data: Inputparameter,
      }).then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          // document.getElementById("Keyword_edit_sec").style.display = "none";
          SetHideEditKeywords(true);
          toast.success(
            <div className="toastsize">
              Keyword
              <br />
              Keyword update successfully.
            </div>
          );
          SetSearch("");
          document.getElementById("search").value = "";
         // KeywordsGet(ClientID, UserID, Role);
          SetIsDisableEditOpen(false);
        } else {
          toast.error(result.data.Message);
          SetIsDisableEditOpen(false);
          SetIsLoading(false);
        }
      }).catch((err)=>{
        SetIsDisableEditOpen(false);
        SetIsLoading(false);
      })
    } else {
      SetIsDisableEditOpen(false);
      SetIsLoading(false);
    }
  };
  //Get Keyword List
  const KeywordsGet = async (cID, uID, Roles) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: cID,
      UserID: uID,
      Role: Roles,
    };
    const KeywordList = await Axios({
      url: CommonConstants.MOL_APIURL + "/despammer/DespammerGet",
      method: "POST",
      data: InputParameter,
    });
    if (KeywordList.data.StatusMessage == "SUCCESS") {
      SetRows(KeywordList.data.PageData);
      SetRlen(KeywordList.data.TotalCount);
      SetFlen(KeywordList.data.TotalCount);
      SetCountPage(KeywordList.data.PageCount);
      SetHideEditKeywords(true);
      SetIsLoading(false);
      setIsDataLoaded(true);

    }
  };

  //get sort field data
  const SortData = (Field) => {
      setSortedColumn(Field);
      SetIsLoading(true);
      const SearchedVal = document.getElementById("search").value?.trim();
      const isNewSortField = Field !== SortField;
      let SortBy = SortValue === 1 ? -1 : 1;
    
      if (isNewSortField) {
        SetSortValue(1);
        SortBy = 1;
      } else {
        SetSortValue(SortBy);
      }
    
      SetSortField(Field);
      SetSflag(SearchedVal !== "");
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: Field,
        SortBy: SortBy,
        Type: "User",
        Role: Role,
      };
      const KeywordList = Axios({
        url: CommonConstants.MOL_APIURL + "/despammer/DespammerGet",
        method: "POST",
        data: InputParameter,
      });
      KeywordList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //edit page
  const CancelBtn = (ID) => {
    SetHideEditKeywords(true);
  };

  //delete Keyword
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Keyword.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    })
      .then((Result) => {
        if (Result.isConfirmed) {
          var InputData = {
            ID: ID,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: CUserID,
            Role: Role,
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/despammer/DespammerDelete",
            method: "POST",
            data: InputData,
          }).then((Res) => {
            if (Res) {
              if ((Res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Keyword deleted successfully.",
                  "success"
                );
                KeywordsGet(ClientID, UserID, Role);
                SetSearch("");
                document.getElementById("search").value = "";
                updateFromChild(true);
                SetPage(1);
              } else {
              }
            }
          });
        } else if (Result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  // show add sec
  const EditBtn = (ID) => {
    SetIsLoading(true);
    SetProspectNoteId(ID);
    UpdateEditKeyboard(true);
    // document.getElementById("Keyword_edit_sec").style.display = "block";
    SetHideEditKeywords(false);
    var InputParameter = {
      KeywordID: ID,
      LastUpdatedDate: new Date(),
      LastUpdatedBy: CUserID,
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/despammer/DespammerGetByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("KeywordEdit").value =
          res.data.Data[0]?.Keyword;
        SetOldKeyword(res.data.Data[0]?.Keyword);
        SetIsLoading(false);
      } else {
        toast.error(res.data.Message);
        SetIsLoading(false);
      }
    });
  };

  return (
    <>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      {HideEditKeywords === false ? (
        <div className="row my-3 px-1 py-3 bg-white mx-0" id="Keyword_edit_sec">
          <div className="col-lg-12 row px-3 pb-3">
            <label className="col-lg-1 text-small">Keyword</label>
            <div className="col-lg-4 px-5 mx-5">
              <input
                className="form-control m-input"
                data-val="true"
                data-val-required="Keyword"
                id="KeywordEdit"
                name="Keyword"
                placeholder="Keyword"
                type="text"
              />
              <span style={{ color: "red" }}>{errors["KeywordEdit"]}</span>
            </div>
          </div>
          <div className="col-lg-12 px-4 full-right">
            <button
              disabled={IsDisableEditOpen}
              className="btn btn-primary btn-lightgreen mr-1"
              id="submit"
              onClick={Updatekeywordbtn}
            >
              <i className="la la-save"></i> Save
            </button>
            <a className="btn btn-secondary" onClick={CancelBtn}>
              <i className="la flaticon-cancel"></i> Cancel
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        <div className="row bg-white  mx-0 pb-3">
          <div className="col">
            <div className="row pt-4 px-2">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    onChange={ChangeRowSelected}
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    value={RowsPerPage}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="search"
                    onKeyPress={RequestSearch}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>

            <div className="table-bordered mx-2">
              <TableContainer component={Paper}>
              <StyleHeader isDataLoaded={isDataLoaded} />

                <Table className="table-ref"  aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                    <TableCell onClick={() => { SortData("Keyword"); setSortedColumn("Keyword"); }}>
                    Keyword
                  <span className="shorting">
                    <ArrowUpward className={SortValue === 1 && sortedColumn === "Keyword" ? "active" : null} />
                    <ArrowDownward className={SortValue === -1 && sortedColumn === "Keyword" ? "active" : null} />
                  </span>
                </TableCell>
                      <TableCell onClick={() => { SortData("CreatedDate"); setSortedColumn("CreatedDate"); }}>
                      Created Date
                  <span className="shorting">
                    <ArrowUpward className={SortValue === 1 && sortedColumn === "CreatedDate" ? "active" : null} />
                    <ArrowDownward className={SortValue === -1 && sortedColumn === "CreatedDate" ? "active" : null} />
                  </span>
                </TableCell>
                      
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {Rows?.length === 0 ? (
                    <p className="text-center">No data available in table</p>
                  ) : (
                    Rows.map((row) => (
                      <TableRow key={row._id} row={row}>
                        <TableCell>{row.Keyword}</TableCell>
                        <TableCell>
                          {moment(
                            new Date(row.CreatedDate).toDateString()
                          ).format("MM/DD/YYYY")}
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              EditBtn(row._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-edit-1 edit-icon"></i>
                          </a>
                          <a
                            onClick={() => {
                              DeleteBtn(row._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-delete-1 delete-icon"></i>
                          </a>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {/* {Sflag ? (
              <div className="row">
                <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div className="col dataTables_info">
                  <p>
                    Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                    {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                    {Rlen} entries (filtered from {Flen} total entries)
                  </p>
                </div>
              </div>
            ) : ( */}
              <div className="row px-1">
                <div className="col dataTables_info">
                  <p>
                    Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                    {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                    {Rlen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    onChange={HandleChangePage}
                    page={Page}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
}
