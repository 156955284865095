import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import { CSVReader, CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientActionItems from "../../_components/user/ClientActionItems/ClientActionItemsTable";
//import CollapsibleTable from '../_components/usertable';

import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";

class ClientActionItemsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: null,
      ClientID: null,
      ExportData: [],
    };
    this.ExportCsv = this.ExportCsv.bind(this);
    this.addpage = this.addpage.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ChildUserID });
    }
    this.ExportMethod(Details.ParentUserID, Details.ClientID);
  }

  ExportMethod(UID, CID) {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/client_action_items/ClientActionItemExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      this.setState({ ExportData: Res.data.Data });
    });
  }

  ExportCsv() {
    toast.success(<div className="toastsize">Data exported successfully.</div>);
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  addpage() {
    history.push("/addclientactionitems");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitle lg-headertitle  float-left pl-0 mb-0">
                  Client Action Items
                </h4>
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <CSVDownloader
                        className="px-0"
                        data={this.state.ExportData}
                        filename={"AllClients-ActionItems"}
                        bom={true}
                      >
                        <a
                          onClick={this.ExportCsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>Export</span>
                        </a>
                      </CSVDownloader>
                    </li>
                    <li>
                      <a
                        onClick={() => this.addpage()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="">
              <div className="row">
                <div className="col">
                  <ClientActionItems updateFromChild={this.UpdateFromChild} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientActionItemsPage = connect(
  mapState,
  actionCreators
)(ClientActionItemsPage);
export { connectedClientActionItemsPage as ClientActionItemsPage };
