import React, { useState, useEffect } from 'react';
import Axios from "axios";
import CreatableSelect from 'react-select/creatable';
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditor from "react-froala-wysiwyg";
import Froala from "froala-editor";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";

const AddKnowledgeBaseArticle = () => {
    const [CategoryList, setCategoryList] = useState([]);
    const [TextField, setTextField] = useState({ data: "" });

    const [ArticleTitle, setArticleTitle] = useState('');
    const [CategoryOptions, setCategoryOptions] = useState(null);
    // const [TextField, setTextField] = useState('');
    const [VideoUrl, setVideoUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [SelectedCategory, setSelectedCategory] = useState(null);

    // config for code editor
    let config = {
        placeholderText: "Edit Your Content Here!",
        charCounterCount: false,
        toolbarButtons: [
            "fontSize",
            "bold",
            "italic",
            "underline",
            "insertLink",
            "insertImage",
            "insertVideo",
            "html",
            "Variable",
        ],
        //shortcutsEnabled: ["insertTemplateButton"],
        imageUploadURL: CommonConstants.MOL_APIURL + "/knowledge_base/upload_image",
        imageUploadRemoteUrls: false,
        videoUploadURL: CommonConstants.MOL_APIURL + "/knowledge_base/upload_video",
        enter: Froala.ENTER_BR,
        key: CommonConstants.EditorValidationKey,
        heightMin: '230px',
        heightMax: '100%',
        resize: true,
        pastePlain: true,
        attribution: false,
        pasteDeniedAttrs: ['style'],
        fontSize: ['8', '10', '12', '14', '18', '24', '30'],
        fontSizeDefaultSelection: '12'
    };
    // get value for text editor addd

    const KnowledgeBaseSc1ModelChange = (model) => {
        setTextField({
            data: model,
        });
    };

    useEffect(() => {
        document.title = `Add Article | SalesHive`;
        const fetchCategory = async () => {
            await getCategories();
        }
        fetchCategory()
    }, []);

    const getCategories = async () => {
        try {
            const response = await Axios({
                url: CommonConstants.MOL_APIURL + "/knowledge_base/fetchCategories",
                method: "GET"
            });
            if (response.data && response.data.StatusMessage == "SUCCESS") {
                const categoryData = response.data.Data;
                if (categoryData.length > 0) {
                    const categoryList = categoryData.map((val) => {
                        return { value: val._id, label: val.FieldName, cid: val._id };
                    });
                    setCategoryList(categoryList);
                }
            }
            else {
                toast.error(response.data.Message);
                return [];
            }
        }
        catch (error) {
            toast.error(error.message);
            return [];
        }
    }

    const handleChangeCategoryOptions = (selectedOption) => {
        setCategoryOptions(selectedOption.value);
        setSelectedCategory(selectedOption);
    };

    const FromValidation = () => {
        let formIsValid = true;

        if (ArticleTitle == "") {
            formIsValid = false;
            toast.error("Please enter article title");
            return formIsValid;
        }

        if (CategoryOptions == null) {
            formIsValid = false;
            toast.error("Please select category");
            return formIsValid;
        }

        if (TextField == null || TextField.data.replace(/<br>/g, '').replace(/&nbsp;/g, '').trim() === "") {
            formIsValid = false;
            toast.error("Please enter description");
            return formIsValid;
        }
        return formIsValid;
    }

    const SubmitForm = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            var FormValidationFlag = await FromValidation();
            if (FormValidationFlag === true) {
                // const textFieldData = !TextField?.data || /^(&nbsp;|\s|<br>)*$/.test(TextField.data);
                const textFieldData = TextField?.data.replace(/<[\/]{0,1}(p)[^><]*>/ig, '');
                // const textFieldData = TextField?.data;
                // const textFieldData = TextField;
                const formData = new FormData();
                formData.append('ArticleTitle', ArticleTitle);
                formData.append('CategoryOptions', CategoryOptions);
                formData.append('TextField', textFieldData);
                formData.append('VideoUrl', VideoUrl);

                const response = await Axios.post(CommonConstants.MOL_APIURL + '/knowledge_base/SubmitArticleRequest', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data.StatusMessage == "SUCCESS") {
                    setIsLoading(false);
                    setArticleTitle('');
                    setCategoryOptions(null);
                    setTextField({ data: "" });
                    setVideoUrl('');
                    history.push("/articlecategories");
                    toast.success(response.data.Message);
                }
                else if (response.data.StatusMessage == "ERROR") {
                    setIsLoading(false);
                    toast.error(response.data.Message);
                }
            }
            else {
                setIsLoading(false);
            }
        }
        catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    }

    const backButton = (e) => {
        e.preventDefault();
        history.push("/articlecategories");
    };

    return (
        <>
            {
                isLoading === true ? <div id="hideloding" className="loding-display">
                    <img src={loadingicon} />
                </div> : null
            }
            <div>
                <div class="bg-white p-3">
                    <form onSubmit={SubmitForm}>
                        <div className="row">
                            <div class="col-lg-12 boxsinput_group pt-0">
                                <div class="row max-cols">
                                    <label class="col-lg-12 view-lable-meet pt-0">Article Title</label>
                                    <div class="col-lg-12 dropdown-custom">
                                        <input
                                            class="form-control m-input"
                                            data-val="true"
                                            data-val-required="Enter article title"
                                            id="ArticleTitle"
                                            name="ArticleTitle"
                                            placeholder="Enter article title"
                                            type="text"
                                            value={ArticleTitle}
                                            onChange={(e) => setArticleTitle(e.target.value)}
                                        />
                                        {/* <span style={{ color: "red" }}>
                                            {this.state.Errors["Name"]}
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 boxsinput_group">
                                <div class="row max-cols">
                                    <label class="col-lg-12 view-lable-meet">Category</label>
                                    <div class="col-lg-12 dropdown-custom" style={{ position: 'relative', zIndex: 11 }}>
                                        <CreatableSelect
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            options={CategoryList}
                                            id="Category"
                                            value={SelectedCategory}
                                            onChange={handleChangeCategoryOptions}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 boxsinput_group">
                                <div class="row max-cols">
                                    <label class="col-lg-12 view-lable-meet">Text Field</label>
                                    <div class="col-lg-12">
                                        <FroalaEditor
                                            id="signature"
                                            config={config}
                                            onModelChange={KnowledgeBaseSc1ModelChange}
                                            model={TextField.data}
                                            ref={TextField}
                                            height="500"
                                        />
                                        {/* <textarea style={{ height: '100px' }}
                                            class="form-control m-area"
                                            data-val="true"
                                            data-val-required="Write something"
                                            id="TextField"
                                            name="TextField"
                                            placeholder="Write something"
                                            type="text"
                                            value={TextField}
                                            onChange={(e) => setTextField(e.target.value)}
                                        /> */}
                                        {/* <span style={{ color: "red" }}>
                                            {this.state.Errors["Name"]}
                                        </span> */}
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 boxsinput_group">
                                <div class="row max-cols">
                                    <label class="col-lg-12 view-lable-meet">Video URL</label>
                                    <div class="col-lg-12">
                                        <input
                                            class="form-control m-input"
                                            data-val="true"
                                            data-val-required="Write something"
                                            id="VideoUrl"
                                            name="VideoUrl"
                                            placeholder="Write something"
                                            type="text"
                                            value={VideoUrl}
                                            onChange={(e) => setVideoUrl(e.target.value)}
                                        />
                                        {/* <span style={{ color: "red" }}>
                                            {this.state.Errors["Name"]}
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row border-top pt-3 pb-2">
                            <div className="col-lg-12">
                                <div className="pull-left">
                                    <button
                                        id="submit"
                                        href="javascript:void(0);"
                                        className="btn btn-primary btn-lightgreen mr-1"
                                        value="Save"
                                    // onClick={SubmitForm}
                                    // disabled={state.IsBtnDisabled}
                                    >
                                        <i className="la la-save"></i> Submit
                                    </button>
                                    <a
                                        href="javascript:void(0);"
                                        onClick={backButton}
                                        className="btn btn-secondary"
                                        value="Save"
                                    >
                                        <i className="la la-arrow-circle-left"></i> Back
                                    </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddKnowledgeBaseArticle;