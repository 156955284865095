import React, { useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import {
  getFirstDayOfQuarter,
  GetUserDetails,
} from "../../../_helpers/Utility";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

const DispositionByDate = ({ FromDate, ToDate, TagID, ColdCallerID }) => {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);

  const [PageData, SetPageData] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [activeButton, SetActiveButton] = React.useState("Week");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [HeaderCustomData, SetHeaderCustomData] = React.useState([]);

  useEffect(() => {
    const Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);

      if (FromDate != "" && ToDate != "") {
        GetPhoneReportingDetails(
          Details.ClientID,
          Details.ParentUserID,
          FromDate,
          ToDate,
          activeButton
        );
      }
    }
  }, [Page, RowsPerPage, activeButton, FromDate, ToDate, TagID, ColdCallerID]);

  //Getting Phone Reporting Details
  const GetPhoneReportingDetails = (
    ClientID,
    UserID,
    FromDate,
    ToDate,
    DisplayBy
  ) => {
    SetIsLoading(true);
    let requestData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Field: SortField,
      SortBy: SortedBy,
      ClientID: ClientID,
      UserID: UserID,
      FromDate: FromDate,
      ToDate: ToDate,
      DisplayBy: DisplayBy,
      TagID:TagID,
      ColdCallerID:ColdCallerID
    };
    
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL +"/cold_calling_reporting/GetDispositionByDate",
      method: "POST",
      data: requestData,
    });
    ApiResponse.then((response) => {
      if ((response.data.StatusMessage = "SUCCESS")) {
        if (response.data.PageData.length > 0) {
          SetPageData(response.data.PageData);
          SetRlen(response.data.TotalCount);
          SetFlen(response.data.TotalCount);
          SetCountPage(response.data.PageCount);
          SetHeaderCustomData(response.data.headerStatusArray)
          SetIsLoading(false);
          setIsDataLoaded(true);

        } else {
          SetPageData([]);
          SetRlen(0);
          SetFlen(0);
          SetCountPage(0);
          SetIsLoading(false);
          setIsDataLoaded(true);

        }
      } else {
        SetPageData([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetIsLoading(false);
        setIsDataLoaded(true);

      }
    });
  };

  //Method for Change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //Method for Change Selected Rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //Method code change Data grouping [Daily,Weekly,Monthky]
  const handleGroupingDate = (name) => {
    SetActiveButton(name);
    SetPage(1)
  };
  return (
    <>
      <div className="row mb-2 align-items-center px-3 py-2">
        <div class="col-md-6">
          <h4 class="smheadtext xs-headertitle pl-0 mt-0 pb-2">Disposition By Date </h4>
        </div>

        <div className="col-md-6">
          <div class="listing-li float-right dailyfilter mt-3">
            <ul class="mt-0">
              <li className="px-0">
                <a
                  className={`btn btngroup m-btn ${
                    activeButton === "Day" ? "active" : ""
                  }`}
                  onClick={() => handleGroupingDate("Day")}
                >
                  <span>Daily</span>
                </a>
              </li>
              <li className="px-0">
                <a
                  className={`btn btngroup m-btn ${
                    activeButton === "Week" ? "active" : ""
                  }`}
                  onClick={() => handleGroupingDate("Week")}
                >
                  <span>Weekly</span>
                </a>
              </li>
              <li className="px-0">
                <a
                  className={`btn btngroup m-btn ${
                    activeButton === "Month" ? "active" : ""
                  }`}
                  onClick={() => handleGroupingDate("Month")}
                >
                  <span>Monthly</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row px-3">
        <div className="col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
      </div>

      <div className="table-bordered pt-0 mx-3 mt-0">
        <TableContainer component={Paper} className="position-relative">
        <StyleHeader isDataLoaded={isDataLoaded} />
          {IsLoading === true ? (
            <div id="hideloding" className="loadingchart">
              <img src={loadingicon} />
            </div>
          ) : (
            <></>
          )}
             <Table className="table-ref"  aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {HeaderCustomData.length > 0
    ? HeaderCustomData.map((header, index) => (
        <TableCell key={header?.Name}>
          {header.Name ? header?.Name : ''}
        </TableCell>
      ))
    : // If the headerStatusArray is empty, use static TableCell values
      [
        "Asked for Info/Follow Up Later",
        "Bad Data",
        "Called",
        "DNC",
        "DNC All",
        "Email Only No Voicemail",
        "Leave No Message/Email",
        "Meeting Set",
        "Not Available",
        "Not Interested",
        "Not Qualified",
        "Not The Right Person",
        "Prospect Hang Up",
        "Voicemail 1",
        "Voicemail 2",
        "Voicemail 3"
      ].map((name, index) => (
        <TableCell key={name}>{name}</TableCell>
      ))}
                {/* <TableCell>Asked for Info/Follow Up Later</TableCell>
                <TableCell>Bad Data</TableCell>
                <TableCell>Called</TableCell>
                <TableCell>DNC</TableCell>
                <TableCell>DNC All</TableCell>
                <TableCell>Email Only No Voicemail</TableCell>
                <TableCell>Leave No Message/Email</TableCell>
                <TableCell>Meeting Set</TableCell>
                <TableCell>Not Available</TableCell>
                <TableCell>Not Interested</TableCell>
                <TableCell>Not Qualified</TableCell>
                <TableCell>Not The Right Person</TableCell>
                <TableCell>Prospect Hang Up</TableCell>
                <TableCell>Voicemail 1</TableCell>
                <TableCell>Voicemail 2</TableCell>
                <TableCell>Voicemail 3</TableCell> */}
              </TableRow>
            </TableHead>
            {/* <TableBody>
       {PageData?.length === 0 ? (
  <p className="text-center">No data available in table</p>
) : (
  PageData.map((row, i) => (
    <React.Fragment key={i}>
      <TableRow row={row}>
        <TableCell>{row.Date}</TableCell>
        {row?.Columns?.length > 0 ? (
          row?.Columns?.map((col, index) => (
            <TableCell key={col?.Name}>{col?.Value ?? 0}</TableCell>
          ))
        ) : (
          // If Columns is empty or undefined, render a default TableCell with 0 for each column
          <TableCell>{0}</TableCell>
        )}
      </TableRow>
    </React.Fragment>
  ))
)}
            </TableBody> */}
            <TableBody>
  {PageData?.length === 0 ? (
    <p className="text-center">No data available in table</p>
  ) : (
    PageData.map((row, i) => (
      <React.Fragment key={i}>
        <TableRow row={row}>
          <TableCell>{row.Date}</TableCell>
          {HeaderCustomData.length > 0
            ? HeaderCustomData.map((header, index) => {
                const col = row?.Columns?.find(c => c.Name === header.Name);
                return (
                  <TableCell key={header?.Name}>
                    {col?.Value ?? 0}
                  </TableCell>
                );
              })
            : [
                "Asked for Info/Follow Up Later",
                "Bad Data",
                "Called",
                "DNC",
                "DNC All",
                "Email Only No Voicemail",
                "Leave No Message/Email",
                "Meeting Set",
                "Not Available",
                "Not Interested",
                "Not Qualified",
                "Not The Right Person",
                "Prospect Hang Up",
                "Voicemail 1",
                "Voicemail 2",
                "Voicemail 3"
              ].map((name, index) => {
                const col = row?.Columns?.find(c => c.Name === name);
                return (
                  <TableCell key={name}>
                    {col?.Value ?? 0}
                  </TableCell>
                );
              })}
        </TableRow>
      </React.Fragment>
    ))
  )}
</TableBody>

          </Table>
        </TableContainer>
      </div>

      <div className="row px-3 pb-3">
        <div class="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div class="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
};

export default DispositionByDate;
