import React from 'react';
const moment=require('moment');
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CallRecordings from '../../_components/clients/coldcalling/callrecordings';
//import CollapsibleTable from '../_components/usertable';
import { GetUserDetails } from "../../_helpers/Utility";
import $ from "jquery";
import { toast } from "react-toastify";


class CCallRecordingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FileName: "",
      FromDate: "",
      ToDate: "",
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.daterefect = React.createRef();
    this.daterefect2 = React.createRef();
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    let FromDates = new Date(moment().subtract(14, "days"));
    const formattedDate = FromDates.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    document.getElementById("from-date").value = formattedDate;
    const $datepickerElement = $(this.daterefect.current);
    $datepickerElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });
    document.getElementById("to-date").value = new Date().toLocaleDateString(
      "en-US",
      { month: "2-digit", day: "2-digit", year: "numeric" }
    );
    const $datepickerElement2 = $(this.daterefect2.current);
    $datepickerElement2.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });
    var FromDate = moment(document.getElementById("from-date").value).format("YYYY-MM-DD");
    var ToDate = moment(document.getElementById("to-date").value).format("YYYY-MM-DD");
    this.setState({ FromDate: FromDate, ToDate: ToDate });
    //    this.setState({FromDate:FromDates,ToDate:ToDates})
  }

  SaveBtn() {
    let FromDate = moment(document.getElementById("from-date").value).format("YYYY-MM-DD");
    let ToDate = moment(document.getElementById("to-date").value).format("YYYY-MM-DD");
    if (moment(FromDate, "YYYY-MM-DD").isAfter(moment(ToDate, "YYYY-MM-DD"))) {
      toast.error("Please select an appropriate date range");
    } else {
      this.setState({ FromDate: FromDate, ToDate: ToDate });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad  lg-headertitle float-left pl-0">
                  Call Recordings
                </h4>
              </div>
              <div className="col pt-3">
                <div className="btn-group datepicker-hed">
                  <input id="fromtab" name="fromtab" type="hidden" value="" />
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control datepicker"
                      id="from-date"
                      name="from-date"
                      type="text"
                      ref={this.daterefect}
                      autocomplete="off"
                    />
                  </div>
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control datepicker"
                      id="to-date"
                      name="to-date"
                      type="text"
                      ref={this.daterefect2}
                      autocomplete="off"
                    />
                  </div>
                  <button
                    id="aCompanyState"
                    className="btn btn-primary btnColor m-btn btn-radius"
                    onClick={this.SaveBtn}
                    autocomplete="off"
                  >
                    <i className="la flaticon-diagram"></i>
                    <span>Analyze</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="paddcols">
              <div className="row">
                <div className="col">
                  <CallRecordings
                    ToDate={this.state.ToDate}
                    FromDate={this.state.FromDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCCallRecordingsPage = connect(mapState, actionCreators)(CCallRecordingsPage);
export { connectedCCallRecordingsPage as CCallRecordingsPage };