import React from 'react';
import { connect } from 'react-redux';
import Axios from "axios";

import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import EmailWarmupTab from '../../_components/user/EmailWarmup/EmailWarmupTabing';
//import CollapsibleTable from '../_components/usertable';
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";


class EmailWarmUpPage extends React.Component {
   constructor(props){
    super(props);
    this.state = {
        ExportData:[],
        CName: "",
        ClientID:null,
        UserID:null,
        CUserID:null,
        DynamicParameterExportsData:"",
        ClientName:"",
        dataFromChild: null, // State to hold data passed from child
        Tabindex:0,
        Role:null
    };
    this.ExportCsv = this.ExportCsv.bind(this);
    this.ExportCsvGet = this.ExportCsvGet.bind(this); // Bind the ExportCsvGet method
    this.handleChildData = this.handleChildData.bind(this); // Bind the handleChildData method
   }
    componentDidMount(){
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    
        document.title="Email Warm Up | SalesHive"
        var Details = GetUserDetails();
        if (Details != null) {
          this.state.ClientID = Details.ClientID;
          this.state.UserID = Details.ParentUserID;
          this.state.CUserID = Details.ChildUserID;
          this.state.Role = Details.Role;

        }
        this.ExportCsvGet(); // Call with `this.`
        GetClientDetails(Details.ClientID).then((result) => {
          this.setState({ CName: result[0].Name });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if Tabindex has changed, then call ExportCsvGet with the updated value.
        if (prevState.Tabindex !== this.state.Tabindex) {
          this.ExportCsvGet();
        }
      }
    
      handleChildData(data) {
        this.setState({ Tabindex: data });
      }
    
      // Sender Receiver API call export CSV
      ExportCsv() {
        toast.success(
          <div>
            Data exported successfully.
          </div>
        );
      }
    
      // Export Data
      ExportCsvGet() {
        const { ClientID, UserID, Tabindex ,Role} = this.state;
        const ExportDataParam = {
          ClientID,
          UserID,
          Tabindex,
          Role
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/email_warm_up/EmailWarmUpExport",
          method: "POST",
          data: ExportDataParam,
        }).then((res) => {
          this.setState({ 
            ExportData: res?.data?.Data, 
            ClientName: res?.data?.ClientName?.Name,
            IsLoading: false,
          });
        });
      }
    //Reload page while delete perform
    UpdateFromChild = (value) => {
        if (value == true) {
          this.componentDidMount();
        }
      };
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="headertitlenop float-left lg-headertitle py-4">Email Warm Up</h4> 
                        </div>
                    </div>
                    <div className="row">
              <div className="col">
                <div className="listing-li float-right">
                  <ul>

                    <li>
                    <CSVDownloader className="px-0"     
                            data={this.state.ExportData}
                            filename={`${this.state.CName}-Email Warm Up`}
                            bom={true}
                          >
                      <a
                        onClick={this.ExportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        
                        <span>
                    
                            <i class="la la-icon-font-size-13 la-download"> </i>
                            Export
               
                        </span>
                      </a>
                      </CSVDownloader>
                    </li>

                  </ul>
                </div>
              </div>
                    </div>
                        <div className="row">
                            <div className="col"> 
                                <EmailWarmupTab updateFromChild={this.UpdateFromChild} updateFromChildD={this.handleChildData}/> 
                            </div>
                        </div>      
 
                    </div> 

                     

                </div>
                <Footer/>


            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedEmailWarmUpPage = connect(mapState, actionCreators)(EmailWarmUpPage);
export { connectedEmailWarmUpPage as EmailWarmUpPage };