import React from "react";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import ContactSource from "../../../_components/user/ContactSource/ContactSource";

import { history } from "../../../_helpers";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";

class ContactSourcePage extends React.Component {
  componentDidMount() {
    window.addEventListener('storage', (event) => {
            
      if (event.key === 'clientChanged') {
        window.location.reload();
      }
    });
}
  // add button
  AddBtn() {
    history.push("/addcontactsource");
  }

  // set order button
  SetOrderBtn() {
    history.push("/contactsourcesetOrder");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitlenop lg-headertitle  float-left py-4 mb-0">Contact Source</h4>
              </div> 
              <div className="col">
                <div className="listing-li float-right padb-15 px-2">
                  <ul>
                    <li>
                      <a
                        onClick={this.SetOrderBtn.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 flaticon-settings"></i>
                        <span>Set Order</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.AddBtn.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="paddcols px-0">
              <div className="row">
                <div className="col">
                  <ContactSource />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedContactSourcePage = connect(
  mapState,
  actionCreators
)(ContactSourcePage);
export { connectedContactSourcePage as ContactSourcePage };
