import React from "react";
import Axios from "axios";

import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";


import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/js/plugins.pkgd.min.js';

import FroalaEditor from "react-froala-wysiwyg";
import Froala from "froala-editor";

import loadingicon from "../../../src/images/loading.gif";

import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { CommonConstants } from "../../_constants/common.constants";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../_helpers/Utility";

var EmailSignaturerVariable = [{ "name": "FromName" },
  { "name": "AliasTitle" },
  { "name": "FromEmail" },
  { "name": "Unsubscribe" },
  { "name": "Disclaimer" }
  ];
  var Vari = {};
  if (EmailSignaturerVariable.length > 0) {
    for (var i = 0; i < EmailSignaturerVariable.length; i++) {
      if(EmailSignaturerVariable[i].name === "Title"){
        Vari["{Alias" + EmailSignaturerVariable[i].name + "}"] = EmailSignaturerVariable[i].name;
      }else{
        Vari["{" + EmailSignaturerVariable[i].name + "}"] = EmailSignaturerVariable[i].name;
      }
    }
  }

class CEmailSignaturePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Signature: null,
      EmailSignatureID: null,
      ClientID: null,
      UserID: null,
      Role:null,
      UserIDby:null,
      PreviewText:"",
      BtnDisabled:false
    };

    this.EmailSignatureModelChange = this.EmailSignatureModelChange.bind(this);
    this.SaveEmailSignature = this.SaveEmailSignature.bind(this);
    
  }
  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title="Email Signature | SalesHive";

    Froala.RegisterCommand("Variable", {
      title: "Advanced options",
      type: "dropdown",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: Vari,
      callback: function (cmd, val) {
        var editorInstance = this;
        editorInstance.html.insert(val);
      },
    });

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.UserIDby = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.EmailSignatureGet();
  }

  // email signature get
  EmailSignatureGet() {
    var str_in = {
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
      Role:this.state.Role
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignatureList",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ EmailSignatureID: resdata.data.Data[0]._id });
          document.getElementById("unsubscribe").value = resdata.data.Data[0].UnsubscribeText;
          this.setState({ Signature: resdata.data.Data[0].Signature });
          this.EmailSignaturePerview(resdata.data.Data[0]._id)
        }
        else
        {
          document.getElementById("hideloding").style.display = "none";   
        }
      }
      else
      {
        toast.error(<div className="toastsize">Email Signature<br />Error Email Signature.</div>);
        document.getElementById("hideloding").style.display = "none"; 
      }
    });
  }

  // handle change model
  EmailSignatureModelChange(model) {
    
    this.setState({
      Signature: model,
    });
  }

  
  

  
   config = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false,
    toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'insertImage', 'html', 'Variable'],
    //shortcutsEnabled: ["insertTemplateButton"],
    imageUploadURL: CommonConstants.MOL_APIURL + "/meetingremindersetting/upload_image",
    imageUploadRemoteUrls: false,
    enter: Froala.ENTER_BR,
    key:CommonConstants.EditorValidationKey,
    height:300,
    pastePlain: true,
    attribution: false
  }
  // save email signature
  SaveEmailSignature(e) {
    this.setState({BtnDisabled:true});
    var UnsubscribeText= document.getElementById("unsubscribe").value
    if(UnsubscribeText =="" || UnsubscribeText == null)
    {
      toast.error("Please enter unsubscribe text");
      this.setState({BtnDisabled:false});
      return false
    }
    if(this.state.Signature == null || this.state.Signature.replace(/<br>/g, '').replace(/&nbsp;/g, '').trim() === "")
    {
      toast.error(<div className="toastsize">Edit Email Signature<br />Signature text is empty, fill it!</div>);
      this.setState({BtnDisabled:false});
      return false
    }
    document.getElementById("hideloding").style.display = "";
    e.preventDefault();
    var data = {
      EmailSignatureID: this.state.EmailSignatureID,
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
      Signature: this.state.Signature.replace(/<[\/]{0,1}(p)[^><]*>/ig, ''),
      UnsubscribeText:UnsubscribeText,
      Role:this.state.Role
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignatureAdd",
      method: "POST",
      data: data,
    }).then((res) => {
      
      if (res.data.StatusMessage == "SUCCESS") {
        this.state.EmailSignatureID == res.data.Data._id;
        this.EmailSignaturePerview(res.data.Data._id)
        toast.success(<div className="toastsize">Edit Email Signature Setting<br/>Email signature updated successfully!</div>);
    this.setState({BtnDisabled:false});
        
      } else {
        toast.error("Error while save signature detail, Please try again!");
        document.getElementById("hideloding").style.display = "none";
    this.setState({BtnDisabled:false});

      }
    });
  }

   EmailSignaturePerview=(EmailSignatureID)=>{
    var data = {
      EmailSignatureID:EmailSignatureID,
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
      Role: this.state.Role,
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignaturePreview",
      method: "POST",
      data: data
    }).then(res => {
      
      if (res.data.StatusMessage = "SUCCESS") {
        this.setState({ PreviewText: res.data.Data });
        document.getElementById("hideloding").style.display = "none";
      }
      else {
        toast.error(<div className="toastsize">Email Signature<br />Error Email Signature.</div>);
        document.getElementById("hideloding").style.display = "none";
      }
  });
  }
  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle pl-0 mb-0">Email Signature</h4>
                </div>
              </div>
            <div class="bg-white">
              <div className="row py-3 mx-0">
                <div class="col-lg-12 boxsinput_group">
                  <div class="row">
                    <label class="col-lg-2 view-lable-meet">
                      Email Signature Text
                    </label>
                    <div class="col-lg-10">
                      <FroalaEditor model={this.state.Signature} onModelChange={this.EmailSignatureModelChange} config={this.config} id="signature"/>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 boxsinput_group borderbtnm mb-4 pb-4">
                  <div class="row">
                    <label class="col-lg-2 view-lable-meet">
                      Unsubscribe Text
                    </label>
                    <div class="col-lg-10">
                      <input type="text" class="form-control m-input" id="unsubscribe" name="unsubscribe" placeholder="Enter unsubscribe text" />
                    </div>
                  </div>
                </div>

                <div class="row px-3">
                  <div class="col-lg-12 pull-left">
                    <button id="submit" 
                    onClick={this.SaveEmailSignature} 
                    class="btn btn-primary btn-lightgreen mr-1"
                    disabled={this.state.BtnDisabled} >
                      <i class="la la-save"></i> Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="row border-bottom border-top mx-0 mb-3">
                <div className="col">
                  <h4 className="headertitlepad xs-headertitle float-left mb-0">Preview</h4>
                
                  
                </div>
                    {/* {this.state.PreviewText} */}
              </div>
              <div className="row px-4">
                <div className="col">
                  <div dangerouslySetInnerHTML={{__html: this.state.PreviewText}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEmailSignaturePage = connect(
  mapState,
  actionCreators
)(CEmailSignaturePage);
export { connectedCEmailSignaturePage as CEmailSignaturePage };
