import React, { useState, useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import Froala from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import StarIcon from "@material-ui/icons/Star";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants, skipPhoneScriptVariable, phoneScriptVariable } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../../../_components/user/StickyHeader/StickyHeader.js";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// A function to reorder the rows array when dragged
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default function ScriptTable(props) {
    const [Data, SetData] = React.useState([]);
    const [Flen, SetFlen] = React.useState(0);
    const [Sflag, SetSflag] = React.useState(false);
    const [Rlen, SetRlen] = React.useState(0);
    const [CountPage, SetCountPage] = React.useState(0);
    const [Rows, SetRows] = React.useState([]);
    const [Page, SetPage] = React.useState(1);
    const [RowsPerPage, SetRowsPerPage] = React.useState(100);
    const [Search, SetSearch] = React.useState("");
    const [SortField, SetSortField] = React.useState("OrderBy");
    const [SortedBy, SetSortedBy] = React.useState(1);
    const [ClientID, SetClientID] = React.useState(0);
    const [UserID, SetUserID] = React.useState(0);
    const [Role, SetRole] = React.useState("");
    const [CUserID, SetCUserID] = React.useState(0);
    const [IsLoading, SetIsLoading] = React.useState(true);
    const [IsDataLoaded, setIsDataLoaded] = React.useState(false);
    const [IsScript, setIsScript] = useState(false);
    const [SignatureSC, SetSignatureSC] = useState({ data: "" });
    const [ButtonLabel, SetButtonLabel] = useState("Add");
    const [DataID, SetDataID] = useState("");
    const [OpenScript, SetOpenScript] = React.useState(false);
    const [ScriptName, SetScriptName] = React.useState("");
    const [NoteDetail, SetNoteDetail] = React.useState("");
    let config = {
        placeholderText: "Type something!",
        charCounterCount: false,
        toolbarButtons: [
            "bold",
            "italic",
            "underline",
            "insertLink",
            "html",
            "Variable",
        ],

        imageUploadRemoteUrls: false,
        enter: Froala.ENTER_BR,
        key: CommonConstants.EditorValidationKey,
        heightMin: '230px',
        heightMax: '100%',
        resize: true,
        pastePlain: true,
        attribution: false,
        pasteDeniedAttrs: ['style'],
        events: {
            'link.beforeInsert': function (link, text, attrs) {
                attrs.target = "_blank";
            }
        },
    };

    const onDragEnd = async (result) => {
        SetIsLoading(true); // Set the loader to true at the start
        if (!result.destination) {
            SetIsLoading(false); // If there's no destination, stop the loader
            return;
        }
    
        const newRows = reorder(Rows, result.source.index, result.destination.index);
        try {
            // Attempt to reorder data and call the script API
            await ReOrderData(newRows);
            await ScriptGet(ClientID, UserID, Role);
        } catch (error) {
            console.error("Error during API calls:", error); // Log any errors encountered
        } finally {
            // Always set the loader to false, regardless of success or failure
            SetIsLoading(false);
        }
    };

    useEffect(() => {
        document.title = 'Dispositions Status | SalesHive';
        let Details = GetUserDetails();
        if (Details != null) {
            SetClientID(Details.ClientID);
            SetUserID(Details.ParentUserID);
            SetCUserID(Details.ChildUserID);
            SetRole(Details.Role);
        }
        GetVariableDropDownDetails();
        ScriptGet(Details.ClientID, Details.ParentUserID, Details.Role);
    }, [Search, Page, RowsPerPage]);

    // get value for text editor add
    const VariableModelChange = (model) => {
        model = model.replace(/<[\/]{0,1}(p)[^><]*>/ig, '')
        SetSignatureSC({
            data: model,
        });
    };

    //Get Campaign step details
    const GetVariableDropDownDetails = async () => {
        var VariableDropDown = await phoneScriptVariable;
        var TempVariableDropDown = {};
        for (var i = 0; i < VariableDropDown.length; i++) {
            TempVariableDropDown["{" + VariableDropDown[i].split(" ").join("") + "}"] =
                VariableDropDown[i];
        }
        Froala.RegisterCommand("Variable", {
            title: "Advanced options",
            type: "dropdown",
            focus: false,
            undo: false,
            refreshAfterCallback: true,
            options: TempVariableDropDown,
            callback: function (cmd, val) {
                var editorInstance = this;
                editorInstance.html.insert(val);
            },
            // Callback on refresh.
            refresh: function ($btn) {
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
            },
        });
    }

    const ReOrderData = async (Arr) => {
        var data = {
            ScriptArray: Arr,
            ClientID: ClientID,
            UserID: UserID,
            Role: Role
        }
        const response = await Axios({
            url: CommonConstants.MOL_APIURL + "/script/ScriptReOrder",
            method: "POST",
            data: data,
        });
        if (response.data.StatusMessage === "SUCCESS") {
            toast.success("Reordered Successfully");
        } else {
            toast.error("Failed to reorder");
        }
    }

    //dispositions status get list
    const ScriptGet = (CID, UID, URole) => {
        SetIsLoading(true)
        let ScriptData = {
            Page: Page,
            RowsPerPage: RowsPerPage,
            Sort: true,
            Field: SortField,
            SortBy: SortedBy,
            Search: Search,
            Type: "User",
            ClientID: CID,
            UserID: UID,
            Role: URole,
        };
        const ApiResponse = Axios({
            url: CommonConstants.MOL_APIURL + "/script/ScriptGet",
            method: "POST",
            data: ScriptData,
        });
        ApiResponse.then((result) => {
            SetData(result.data.PageData);
            SetRows(result.data.PageData);
            SetRlen(result.data.TotalCount);
            SetFlen(result.data.TotalCount);
            SetCountPage(result.data.PageCount);
            SetIsLoading(false);
            setIsDataLoaded(true);

        });
    };
    // Search details
    const RequestSearch = (event) => {
        if (event.key === "Enter") {
            let SearchedVal = document.getElementById("search").value;
            if (SearchedVal == Search) {
                SetSearch(SearchedVal);
                SetPage(1);
            } else {
                SetSearch(SearchedVal);
                SetPage(1);
                SetIsLoading(true);
            }
        }
    };
    // Delete function
    const DeleteBtn = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "you want to delete a script.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34bfa3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                let ContactTagData = {
                    _id: id,
                    LastUpdatedBy: CUserID,
                    LastUpdatedDate: new Date(),
                    Role: Role,
                    ClientID: ClientID,
                    UserID: UserID
                };
                Axios({
                    url: CommonConstants.MOL_APIURL + "/script/ScriptDelete",
                    method: "POST",
                    data: ContactTagData,
                }).then((res) => {

                    if (res) {
                        if ((res.StatusMessage = "SUCCESS")) {
                            Swal.fire(
                                "Deleted!",
                                "Script deleted successfully.",
                                "success"
                            );
                            SetIsLoading(true);
                            ScriptGet(ClientID, UserID, Role);
                            SetPage(1)
                            SetSearch("")
                            document.getElementById("search").value = ""
                        } else {
                        }
                    }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
        });
    };

    //Close Notes 
    const CloseNotes = () => {
        SetOpenScript(false);
        SetNoteDetail("");
        SetScriptName("");
    }

    // copy script
    const CopyBtn = async (id) => {
        let ScriptData = {
            ID: id,
            ClientID: ClientID,
            UserID: UserID,
            Role: Role,
        };
        const ApiResponse = Axios({
            url: CommonConstants.MOL_APIURL + "/script/ScriptCopy",
            method: "POST",
            data: ScriptData,
        });
        ApiResponse.then((result) => {
            if (result.data.StatusMessage === "SUCCESS") {
                toast.success(
                    <div className="toastsize">
                        Copy Script
                        <br />
                        Script copied successfully.
                    </div>
                );
                SetIsLoading(true);
                ScriptGet(ClientID, UserID, Role);
            } else {
                toast.error(
                    <div className="toastsize">
                        Copy Script
                        <br />
                        Failed to copy script.
                    </div>
                );
            }
        });
    }

    // change page
    const HandleChangePage = (event, NewPage) => {
        if (NewPage == Page) {
            SetPage(NewPage);
        } else {
            SetPage(NewPage);
            SetIsLoading(true);
        }
    };

    // change display rows
    const ChangeRowSelected = (Event) => {
        SetRowsPerPage(Number(Event.target.value));
        SetPage(1);
        SetIsLoading(true);
    };

    // edit button
    const EditBtn = async (id) => {
        SetDataID(id);
        var Data = {
            ID: id,
            Role: Role
        };
        var requestAddScript = await Axios({
            url: CommonConstants.MOL_APIURL + "/script/ScriptGetByID",
            method: "POST",
            data: Data,
        });
        if (requestAddScript.data?.StatusMessage === "SUCCESS") {
            setIsScript(true)
            SetButtonLabel("Edit");
            SetSignatureSC({ data: requestAddScript.data?.ScriptData.ScriptBody });
            document.getElementById('AddScriptName').value = requestAddScript.data?.ScriptData?.ScriptName;
        }
    };
    // add button
    const AddBtn = (id) => {
        setIsScript(true)
        SetButtonLabel("Add");
    };

    const ViewBtn = async (id) => {
        SetIsLoading(true)
        var Data = {
            ID: id,
            Role: Role
        };
        var requestDataScript = await Axios({
            url: CommonConstants.MOL_APIURL + "/script/ScriptView",
            method: "POST",
            data: Data,
        });
        if (requestDataScript.data?.StatusMessage === "SUCCESS") {
            SetOpenScript(true);
            SetScriptName(requestDataScript.data?.ScriptData?.ScriptName)
            var BodyData = await ReplaceBody(requestDataScript.data?.ScriptData?.ScriptBody, requestDataScript.data?.ContactData);
            SetNoteDetail(BodyData);
        } else {
            toast.error("Failed to view script")
        }
        SetIsLoading(false)
    };

    const ReplaceBody = async (Body, Arr) => {
        try {
            let randomIndex = Math.floor(Math.random() * Arr.length);
            let randomObject = Arr[randomIndex];
            console.log(randomObject);
            var reg = Body.match(/{(.*?)}/g);
            if (reg !== null) {
                for (let i = 0; i < reg.length; i++) {
                    var variable = reg[i].replace(/[{}]/g, '');
                    if (randomObject[variable]) {
                        Body = Body.replace(reg[i], randomObject[variable]);
                    }
                }
            }
            return Body;

        } catch (e) {
            toast.error("Failed to replace variables");
        }
    }

    const AddScriptClose = async () => {
        setIsScript(false)
        SetSignatureSC({ data: "" });
    }
    const ScriptPopupSubmit = async () => {
        var BodyMain = SignatureSC.data.replace(/<[\/]{0,1}(p)[^><]*>/ig, '')
        var ScriptName = document.getElementById('AddScriptName').value;
        function hasVariables(str) {
            // Convert skipVariables to lowercase for case-insensitive comparison
            let skipVariablesOne = skipPhoneScriptVariable.map(variable => variable.toLowerCase());
            var regsub = str.match(/{(.*?)}/g);
            if (regsub === null) {
                return false;
            }
            // Filter out variables that should be skipped and convert to lowercase in the filter function
            regsub = regsub.filter(variable => !skipVariablesOne.includes(variable.toLowerCase()));
            return regsub.length > 0;
        }

        // Check if body or subject contain variables
        var bodyHasVariables = hasVariables(BodyMain);
        if (bodyHasVariables === false) {
            if (BodyMain == "" && ScriptName == "") {
                toast.error(
                    <div className="toastsize">
                        Add Script
                        <br />
                        Script Name and Body is empty, fill it!
                    </div>
                );
            } else if (BodyMain == "" && ScriptName != "") {
                toast.error(
                    <div className="toastsize">
                        Add Script
                        <br />
                        Body is empty, fill it!
                    </div>
                );
            } else if (BodyMain != "" && ScriptName == "") {
                toast.error(
                    <div className="toastsize">
                        Add Script
                        <br />
                        Script Name is empty, fill it!
                    </div>
                );
            } else {
                if (ButtonLabel === "Add") {
                    var Data = {
                        ScriptName: ScriptName,
                        ScriptBody: BodyMain,
                        Role: Role,
                        CreatedBy: CUserID,
                        CreatedDt: new Date(),
                        ClientID: ClientID,
                        UserID: UserID
                    };
                    var requestAddScript = await Axios({
                        url: CommonConstants.MOL_APIURL + "/script/ScriptAdd",
                        method: "POST",
                        data: Data,
                    });
                    if (requestAddScript.data?.StatusMessage === "EXISTS") {
                        toast.error(
                            <div className="toastsize">
                                Add Script
                                <br />
                                Script Name is already added, change it!
                            </div>
                        );
                    } else if (requestAddScript.data?.StatusMessage === "ERROR") {
                        toast.error(
                            <div className="toastsize">
                                Add Script
                                <br />
                                {requestAddScript.data?.Message}
                            </div>
                        );
                    } else if (requestAddScript.data?.StatusMessage === "SUCCESS") {
                        setIsScript(false)
                        SetSignatureSC({ data: "" });
                        toast.success(<p>Add Script<br />You have inserted new script.</p>);
                        ScriptGet(ClientID, UserID, Role)
                    }
                } else if (ButtonLabel === "Edit") {
                    var Data = {
                        ID: DataID,
                        ScriptName: ScriptName,
                        ScriptBody: BodyMain,
                        Role: Role,
                        LastUpdatedBy: CUserID,
                        LastUpdatedDate: new Date(),
                        ClientID: ClientID,
                        UserID: UserID
                    };
                    var requestAddScript = await Axios({
                        url: CommonConstants.MOL_APIURL + "/script/ScriptUpdate",
                        method: "POST",
                        data: Data,
                    });
                    if (requestAddScript.data?.StatusMessage === "EXISTS") {
                        toast.error(
                            <div className="toastsize">
                                Edit Script
                                <br />
                                Script Name is already added, change it!
                            </div>
                        );
                    } else if (requestAddScript.data?.StatusMessage === "ERROR") {
                        toast.error(
                            <div className="toastsize">
                                Edit Script
                                <br />
                                {requestAddScript.data?.Message}
                            </div>);
                    } else if (requestAddScript.data?.StatusMessage === "SUCCESS") {
                        setIsScript(false)
                        SetSignatureSC({ data: "" });
                        SetDataID("");
                        toast.success(<p>Edit Script<br />You have edited script.</p>);
                        ScriptGet(ClientID, UserID, Role)
                    }
                }

            }
        } else {
            toast.error(<p>Add Script<br />Wrong Variable inserted in body.</p>);
        }
    }


    const MakeDefaultBtn = async (id) => {
        let ScriptData = {
            ID: id,
            ClientID: ClientID,
            Role: Role,
            LastUpdatedBy:CUserID
        };
        const ApiResponse = Axios({
            url: CommonConstants.MOL_APIURL + "/script/ScriptSetsMakeDefaultUpdate",
            method: "POST",
            data: ScriptData,
        });
        ApiResponse.then((result) => {
            if (result.data.StatusMessage === "SUCCESS") {
                toast.success(
                    <div className="toastsize">
                        Script
                        <br />
                        Scripts make default updated successfully.
                    </div>
                );
                SetIsLoading(true);
                ScriptGet(ClientID, UserID, Role)
            } else {
                toast.error(
                    <div className="toastsize">
                        Script
                        <br />
                        Failed to make default Script.
                    </div>
                );
            }
        });
      }

    return (
        <div>
            {IsLoading ? (
                <div id="hideloding" className="loding-display">
                    <img src={loadingicon} />
                </div>
            ) : (
                <></>
            )}
            <div className="row align-items-center border-bottom mx-0">
                <div className="col">
                    <h4 className="headertitlebd xs-headertitle float-left ">Script</h4>
                </div>
                <div className="col">
                    <div className="listing-li float-right">
                        <ul class='my-0'>
                            <li>
                                <a
                                    onClick={() => {
                                        AddBtn();
                                    }}
                                    // onClick={this.AddBtn}
                                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                                >
                                    <i className="la la-icon-font-size-13 la-plus"></i>
                                    <span>Add</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class=''>
                <div className="row">
                    <div className="col padd-0"></div>
                </div>
                <div className="row pt-4 mx-0">
                    <div className="col-sm-12 col-md-6">
                        <label className="textlabelte">
                            Show
                            <select
                                name="tbl_meeting_length"
                                onChange={ChangeRowSelected}
                                aria-controls="tbl_meeting"
                                className="form-control form-control-sm"
                                value={RowsPerPage}
                            >
                                {CommonConstants.show_rows.map((value) => (
                                    <option value={value} key={value}>{value}</option>
                                ))}
                            </select>
                            entries
                        </label>
                    </div>
                    <div className="col-sm-12 col-md-6 full-right">
                        <label className="textlabelte">
                            Search:{" "}
                            <input
                                type="search"
                                id="search"
                                onKeyPress={RequestSearch}
                                className="form-control form-control-sm ml-2"
                                placeholder=""
                                aria-controls="tbl_meeting"
                            />
                        </label>
                    </div>
                </div>
                <div class='px-3'>
                    <div className="table-bordered">
                        <TableContainer component={Paper}>
                            <StyleHeader isDataLoaded={IsDataLoaded} />
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="Rows" direction="vertical">
                                    {(provided) => (
                                        <Table className="table-ref" {...provided.droppableProps} ref={provided.innerRef} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Script Count</TableCell>
                                                    <TableCell>Meeting Count</TableCell>
                                                    <TableCell>Conversion Rate</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {Rows?.length === 0 ? (
                                                    <p className="text-center">No data available in table</p>
                                                ) : (
                                                    Rows?.map((row, index) => (
                                                        <Draggable key={row._id} draggableId={row._id} index={index}>
                                                            {(provided) => (
                                                                <TableRow key={row?._id} ref={provided.innerRef} {...provided.draggableProps}>
                                                                    <TableCell align="center" {...provided.dragHandleProps}>
                                                                        <i className="fa fa-bars cursor-pointer"></i>
                                                                    </TableCell>
                                                                    <TableCell>{row.ScriptName}</TableCell>
                                                                    <TableCell>{row?.PhoneScriptCount || 0}</TableCell>
                                                                    <TableCell>{row?.MeetingCount || 0}</TableCell>
                                                                    <TableCell>{row?.ConversionRate || 0 }</TableCell>
                                                                    <TableCell>
                                                                        <a
                                                                            onClick={() => {
                                                                                EditBtn(row._id);
                                                                            }}
                                                                            className="btn-eyesicon"
                                                                            title="Edit"
                                                                        >
                                                                            <i className="la flaticon-edit-1 edit-icon"></i>
                                                                        </a>
                                                                        <a
                                                                            onClick={() => {
                                                                                if (!row?.IsMakeDefault) { // Only delete if IsMakeDefault is false
                                                                                    DeleteBtn(row._id, row.StatusOrderDetails);
                                                                                  }
                                                                            }}
                                                                            className={`btn-eyesicon ${row.IsMakeDefault ? "disabled-delete" : ""}`}
                                                                            title="Delete"
                                                                            style={{
                                                                                cursor: row?.IsMakeDefault ? "not-allowed" : "pointer",
                                                                                pointerEvents: row?.IsMakeDefault ? "none" : "auto", // Prevent click if IsMakeDefault is true
                                                                              }}
                                                                        >
                                                                            <i className="la flaticon-delete-1 delete-icon"></i>
                                                                        </a>
                                                                        <a
                                                                            onClick={() => {
                                                                                CopyBtn(row._id);
                                                                            }}
                                                                            className="btn-eyesicon"
                                                                            title="Duplicate"
                                                                        >
                                                                            <i className="la flaticon-add add-icon edit-icon"></i>
                                                                        </a>
                                                                        <a
                                                                            onClick={() => {
                                                                                ViewBtn(row._id);
                                                                            }}
                                                                            className="btn-eyesicon"
                                                                            title="View"
                                                                        >
                                                                            <i className="la flaticon-eye edit-icon"></i>
                                                                        </a>
                                                                        {row.IsMakeDefault ? (
                                                                            <a
                                                                                onClick={() => {
                                                                                const defaultCount = Rows?.filter((item) => item?.IsMakeDefault === true).length;
                                                                                
                                                                                if (defaultCount > 1) {
                                                                                    MakeDefaultBtn(row._id); // Allow action if more than 1 default exists
                                                                                }
                                                                                }}
                                                                                    title="Make Default"
                                                                                style={{
                                                                                cursor: Rows?.filter((item) => item?.IsMakeDefault === true).length === 1 ? "not-allowed" : "pointer",
                                                                                pointerEvents: Rows?.filter((item) => item?.IsMakeDefault === true).length === 1 ? "none" : "auto",
                                                                                }}
                                                                            >
                                                                                {/* <StarIcon color={Rows?.filter((item) => item?.IsMakeDefault === true).length === 1 ? "disabled" : "primary"} />
                                                                                 */}
                                                                                 <StarIcon
      style={{
        color: "#F8BF58", // Transparent fill
      }}
    />
                                                                            </a>
                                                                            ) : (
                                                                            <a
                                                                                onClick={() => {
                                                                                MakeDefaultBtn(row._id);
                                                                                }}
                                                                                    title="Make Default"
                                                                            >
                                                                                {/* <StarIcon color="secondary" /> */}
                                                                                <StarIcon
      style={{
        color: "transparent", // Transparent fill
        stroke: "#ccc",       // Stroke color
        strokeWidth: 1,       // Stroke width
      }}
    />
                                                                            </a>
                                                                            )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </Draggable>

                                                    )))}
                                            </TableBody>

                                            <div className="row"></div>
                                        </Table>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </TableContainer>
                    </div>
                </div>

                <div className="row mx-0 pb-2">
                    <div className="col dataTables_info">
                        <p>
                            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                            entries
                        </p>
                    </div>
                    <div className="col pageright">
                        <Pagination
                            component="div"
                            count={CountPage}
                            page={Page}
                            onChange={HandleChangePage}
                            showFirstButton
                            showLastButton
                        />
                    </div>
                </div>
            </div>
            <Popup open={IsScript}>
                <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup1000">
                        <div className="paddingboxTerms">
                            <div className="modal-header py-4 px-3">
                                <div className="w-100 d-flex px-3">
                                    <h5 className="mb-0">{ButtonLabel} Script</h5>
                                    <button
                                        className="close"
                                        onClick={AddScriptClose}
                                    >
                                        <span
                                            aria-hidden="true"
                                            class="la la-remove"
                                        ></span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="px-4">
                                    <div class="row mb-4">
                                        <label class="col-lg-2 col-form-label textright-vmb">
                                            Script Name
                                        </label>
                                        <div class="col-lg-9 d-flex">
                                            <input
                                                class="form-control m-input"
                                                type="text"
                                                id="AddScriptName"
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label class="col-lg-2 col-form-label textright-vmb">
                                            Body
                                        </label>
                                        <div class="col-lg-9">
                                            <div id="parent-wrapper-id" className="resizer-block hide-link-inset">
                                                <FroalaEditor
                                                    id="signature2"
                                                    config={{
                                                        scrollableContainer: '#parent-wrapper-id',
                                                        ...config
                                                    }}
                                                    onModelChange={VariableModelChange}
                                                    model={SignatureSC.data}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={AddScriptClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    id="SubmitStepTemplate"
                                    onClick={ScriptPopupSubmit}
                                    class="btn btn-primary btnColor"
                                >
                                    <i class="la la-save mr-2"></i> Save{" "}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
            <Popup open={OpenScript} modal>
                <div className="modal-black"></div>
                <div className="filterPopup bigpopupcontent">
                    <div className="paddingboxTerms">
                        <div className="modal-header">
                            <h5>{ScriptName}</h5>
                            <a className="close-ion" onClick={() => { CloseNotes(); }}>
                                <span aria-hidden="true" class="la la-remove"></span>
                            </a>
                        </div>
                        <div className="modal-content bordernone">
                            <div class="row max-cols">
                                {/* <h6>Body</h6>
                  <br/> */}
                                <p><div dangerouslySetInnerHTML={{ __html: NoteDetail }} /></p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                onClick={() => { CloseNotes(); }}
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
}
