
import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

//Sorted item
const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));

//sorted list
const SortableList = SortableContainer(({ Items }) => {
  return (
    <ul>
      {Items.map((Value, Index) => (
        <SortableItem
          id={Index}
          key={`item-${Index}`}
          index={Index}
          value={Value.Status}
        />
      ))}
    </ul>
  );
});

class  CPotentialMeetingStatusOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: [],
      ClientID: null,
      UserID: null,
      Role:null,
      BtnDisabledDis: false
    };
    this.SaveOrder = this.SaveOrder.bind(this);
    this.BackBtn = this.BackBtn.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title = `Potential Meeting Status Order | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
    }
    this.GetEngagementStatusOrder();
  }

//Get set order
  GetEngagementStatusOrder() {
    var Details = GetUserDetails();
    var InputParameter = {
      Page: 1,
      RowsPerPage: 100,
      Field: "OrderBy",
      Sortby: 1,
      Type: "User",
      ClientID: Details.ClientID,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeetingstatus/PotentialMeetingStatusGet",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((Result) => {
      this.setState({ Items: Result.data.PageData });
      document.getElementById("hideloding").style.display = "none";
    });
  }

  //Sort End
  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      Items: arrayMoveImmutable(this.state.Items, oldIndex, newIndex),
    });
  };

//save order
  SaveOrder() {
    this.setState({ BtnDisabledDis: true });
    let OrderBy = 0;
    for (let i = 0; i < this.state.Items.length; i++) {
      this.state.Items[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.SaveData();
  }

//add order
  SaveData() {
    let InputParameter = {
      PotentialMeetingStatusList: this.state.Items,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeetingstatus/PotentialMeetingStatusSetOrder",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Engagement Status
            <br />
            engagement status order updated successfully.
          </div>
        );
        history.push("/CPotentialMeetingStatus");
      } else {
        toast.error(res.data.Message);
        this.setState({ BtnDisabledDis: false });
      }
      this.GetEngagementStatusOrder();
    });
  }

//back 
  BackBtn() {
    history.push("/CPotentialMeetingStatus");
  }

  render() {
    return (
      <>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome px-3">
              <div className="row px-3">
                <div className="col padd-0">
                  <h4 className="headertitlepad float-left mb-0">
                    Engagement Statuses Order
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row pt-4 px-4">
                <div className="col-xl-12 offset-xl-12 p-0">
                  <span className="alertinfo">
                    &nbsp;<b>NOTE</b> : Please drag &amp; drop item to change
                    order of engagement status and press save button.
                  </span>

                  <div class="spacupgroup">
                    <SortableList
                      Items={this.state.Items}
                      onSortEnd={this.OnSortEnd}
                    />
                  </div>
                </div>
              </div>
              <div id="hideloding" className="loding-display">
                <img src={loadingicon} />
              </div> 
              </div>
              <div class="row px-3 mt-3">
                <div class="pull-left">
                  <button
                  disabled={this.state.BtnDisabledDis}
                    id="submit"
                    onClick={() => {
                      this.SaveOrder();
                    }}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save order
                  </button>
                  <a
                    id="backtolist"
                    onClick={() => {
                      this.BackBtn();
                    }}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCPotentialMeetingStatusOrderPage = connect(
  mapState,
  actionCreators
)(CPotentialMeetingStatusOrderPage);
export { connectedCPotentialMeetingStatusOrderPage as CPotentialMeetingStatusOrderPage };
