import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ContentPipelineTabing from "../../_components/user/ContentPipeline/ContentPipeline";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";
import { GetClientDetails } from "../../_helpers/Utility";
import loadingis from "../../images/loading.gif";
import { toast } from "react-toastify";
import Partnership from "../../_components/user/Partnership/Partnership";
import ClientSidebar from '../../_components/clients/nav-sidebar/Sidebar';
toast.configure();

class ContentPipelinePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: null,
      ClientID: null,
      CUserID: null,
      ExportData: [],
      GraphData: [],
      SourceData: {},
      TotalReschedules: 0,
      Role: null,
      CName: "",
      isLoading: false,
    };
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
      this.setState({ Role: Details.Role });
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });
  }
  // add button 
  AddBtn() {
    history.push("/addcontentpipeline");
  }

  //Export CSV
  Exportdetails = async (CID, UID) => {
    var Details = GetUserDetails();
    const InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: Details.Role,
    };
    try {
      const rows1 = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/ContentPipeline/ContentPipeLineExport",
        method: "POST",
        data: InputParameters,
      });
      if (rows1.data.StatusMessage === "SUCCESS") {
        return rows1.data;
        
      }
    } catch (error) {
      throw error;
    }
  };

  exportcsv = async () => {
    try {
      const result = await this.Exportdetails(
        this.state.ClientID,
        this.state.UserID
      );
      this.setState({ isLoading: true });
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;

        if (data.length > 0) {
          this.setState({ exportData: data });

          const downloader = document.createElement("a");
          const csvContent =
            "data:text/csv;charset=utf-8," +
            encodeURIComponent(this.convertToCSV(data));

          downloader.setAttribute("href", csvContent);
          downloader.setAttribute(
            "download",
            this.state.CName + "-ContectCalendar.csv"
          );
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
          this.setState({ isLoading: false });
        } else {
          toast.error("No data available for export.");
          this.setState({ isLoading: false });
        }
      } else {
        toast.error("Failed to export data. Please try again.");
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error("exportcsv error:", error);
      // toast.error('An error occurred while exporting data.');
    }
  };

  convertToCSV(data) {
    const headers = Object.keys(data[0]);
    const csvRows = [];
    csvRows.push(headers.join(","));

    data.forEach((item) => {
      const values = headers.map((header) => {
        const value =
          typeof item[header] === "string"
            ? item[header]
            : String(item[header]);
        const escapedValue = value.replace(/"/g, '""');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  }

  render() {
    return (
      <>
        {this.state.isLoading && (
          <div id="hidelodings" className="loding-display">
            <img src={loadingis} />
          </div>
        )}
        <div className="adminmain mheight">
        {this.state.Role  === "Client" ?(<ClientSidebar className="" />):(<Sidebar className="" />)}
          <div className="bodyhome partner-space">
            <div className="row">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle float-left pl-0">
                Content Calendar
                </h4>
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a
                        className="btn btngroup m-btn m-btn--custom"
                        onClick={this.exportcsv}
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        Export
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.AddBtn.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols px-3 py-0">
              <div className="row">
                <div className="col px-1">
                  <ContentPipelineTabing />
                </div>
              </div>
            </div>
            {/* <Partnership />  */}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedContentPipelinePage = connect(
  mapState,
  actionCreators
)(ContentPipelinePage);
export { connectedContentPipelinePage as ContentPipelinePage };
