import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { toast } from "react-toastify";
import StyleHeader from "../StickyHeader/StickyHeader";

export default function CountryBlacklistTable(props) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Country");
  const [sortedColumn, setSortedColumn] = React.useState("Country");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = "Country Blacklist | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
  }, []);

  useEffect(() => {
    let details = GetUserDetails();
    GetCountryBlacklist(details.ClientID, details.Role);
  }, [Search, page, RowsPerPage]);

  // cotact tag get list
  const GetCountryBlacklist = (CID, URole) => {
    var ContactTagData = {
      Page: page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/countryblacklist/CountryBlacklistGet",
      method: "POST",
      data: ContactTagData,
    });
    rows1
      .then((result) => {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetIsLoading(false);
        setIsDataLoaded(true);
      })
      .catch(() => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
        setIsDataLoaded(true);
      });
  };

  // Search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        SetIsLoading(true);
      }
    }
  };
  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }

    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    var InputParameter = {
      Page: page,
      RowsPerPage: RowsPerPage,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Search: SearchedVal,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      Role: Role,
    };

    const CountryBlackList = Axios({
      url: CommonConstants.MOL_APIURL + "/countryblacklist/CountryBlacklistGet",
      method: "POST",
      data: InputParameter,
    });
    CountryBlackList.then((Result) => {
      SetData(Result.data.PageData);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
    });
  };
  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    SetIsLoading(true);
  };
  // change page
  const HandleChangePage = (event, NewPage) => {
    if (NewPage == page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      SetIsLoading(true);
    }
  };
  const EditCountryBlacklist = (id) => {
    history.push("/editcountryblacklist", id);
  };

  const DeleteCountryBlacklist = (id, Country) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a country blacklist.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var CountryBlacklistData = {
          CountryBlacklistID: id,
          LastUpdatedBy: UserID,
          Country: Country,
          ClientID: ClientID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/countryblacklist/CountryBlacklistDelete",
          method: "POST",
          data: CountryBlacklistData,
        }).then((res) => {
          if (res) {
            console.log("res.data", res.data);
            if (res.data.StatusMessage === "SUCCESS") {
              Swal.fire(
                "Deleted!",
                "Country blacklist deleted successfully.",
                "success"
              );
              SetSearch("");
              SetPage(1);
              document.getElementById("search").value = "";
              GetCountryBlacklist(ClientID, Role);
              SetIsLoading(false);
              props.updateFromChild(true);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Data is safe :)", "error");
      }
    });
  };
  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row pt-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_CountryBlacklist_length"
              value={RowsPerPage}
              onChange={ChangeRowSelected}
              aria-controls="tbl_CountryBlacklist"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_CountryBlacklist"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <StyleHeader isDataLoaded={isDataLoaded} />
          <Table className="table-ref" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  className=""
                  onClick={() => {
                    SortData("Country");
                    setSortedColumn("Country");
                  }}
                >
                  Country
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Country"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Country"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  className=""
                  onClick={() => {
                    SortData("Abbreviation");
                    setSortedColumn("Abbreviation");
                  }}
                >
                  Abbreviation
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Abbreviation"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Abbreviation"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.map((row) => (
                <TableRow>
                  <TableCell>{row.Country}</TableCell>
                  <TableCell>{row.Abbreviation}</TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        EditCountryBlacklist(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteCountryBlacklist(row._id, row.Country);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
        <div class="row align-items-center">
          <div class="col DataTables_info">
            <p class="mb-0">
              Showing {Rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > Rlen ? Rlen : page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </div>
  );
}
