import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import $ from "jquery";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import loadingicon from "../../../images/loading.gif";
import { GetUserDetails,GetClientDetails } from "../../../_helpers/Utility";
import SearchIcon from "@material-ui/icons/Search";
import { CSVReader, CSVDownloader } from "react-papaparse";
const Fileupload = require("../../../_helpers/fileupload");

import Pagenations from "../../Pagenations";
import StyleHeader from "../StickyHeader/StickyHeader";

import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

const SortingIcons = ({ SortedBy, sortedColumn, ColumnName }) => {
  return (
    <span className="shorting">
      <ArrowUpward
        className={
          SortedBy === 1 && sortedColumn === ColumnName ? "active" : null
        }
      />
      <ArrowDownward
        className={
          SortedBy === -1 && sortedColumn === ColumnName ? "active" : null
        }
      />
    </span>
  );
};

const imgstyle = {
  height: "40px",
  width: "40px",
  borderradius: "50%",
};

export default function DNCHistoryTable({ ToDate, FromDate }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("CreatedDate");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [Cname, SetCname] = React.useState("");

  useEffect(() => {
    document.title = "DNC History | SalesHive";
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetClientDetails(GetUserData.ClientID).then((result) => {
      SetCname(result[0]?.Name);
    });
    GettingAccountInfoList(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role,
      FromDate, 
      ToDate
    );
  }, [FromDate,ToDate,Search, Page, RowsPerPage]);

  //Getting SDR Assignment list
  const GettingAccountInfoList = (CID, UID, URole,FromDate, ToDate) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      ClientID: CID,
      Role: URole,
      ToDate: ToDate,
      FromDate: FromDate,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/DNChistory/DncHistoryListGet",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value?.trim();
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }

    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      UserID: UserID,
      ClientID: ClientID,
      Role: Role,
      ToDate: ToDate,
      FromDate: FromDate,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/DNChistory/DncHistoryListGet",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      if(Result.data.StatusMessage === "SUCCESS"){
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetPage(1);
        SetIsLoading(false);
      }
    });
  };


    //Export details
    const Exportdetails = async (UID, CID, URole,FromDate, ToDate) => {
      var str_in = {
        UserID: UID,
        ClientID: CID,
        Role: URole,
        ToDate: ToDate,
        FromDate: FromDate,
      };
  
      const rows1 = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/DNChistory/DncHistoryListExport",
        method: "POST",
        data: str_in,
      });
      if (rows1.data.StatusMessage === "SUCCESS") {
        return rows1.data;
      }
    };
  
    const Exportcsv = async () => {
      try {
        SetIsLoading(true);
        const result = await Exportdetails(UserID, ClientID, Role,FromDate, ToDate);
        if (result.StatusMessage === "SUCCESS") {
          const data = result.Data;
          if (data.length > 0) {
            const downloader = document.createElement("a");
            const csvContent =
              "data:text/csv;charset=utf-8," +
              encodeURIComponent(convertToCSV(data));
            downloader.setAttribute("href", csvContent);
            downloader.setAttribute("download",Cname+"_DNCHistory.csv");
            downloader.click();
            toast.success(<div>Data exported successfully.</div>);
            SetIsLoading(false);
          } else {
            toast.error("No data available for export.");
            SetIsLoading(false);
          }
        } else {
          toast.error("Failed to export data. Please try again.");
          SetIsLoading(false);
        }
      } catch (error) {
        toast.error("An error occurred while exporting data.");
      }
    };
  
    function convertToCSV(data) {
      const headers = Object.keys(data[0]);
  
      const csvRows = [];
      csvRows.push(headers.join(","));
  
      data.forEach((item) => {
        const values = headers.map((header) => {
          // Check if the value is a string before attempting to replace characters
          const value =
            typeof item[header] === "string"
              ? item[header]
              : String(item[header]);
          const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
          return `"${escapedValue}"`; // Enclose value in double quotes
        });
        csvRows.push(values.join(","));
      });
  
      return csvRows.join("\n");
    }

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col padd-0"></div>
        <div className="col padright flexdisplayer pr-3">
          <div className="fulleriger d-flex align-items-center">
            <button
              onClick={Exportcsv}
              type="submit"
              className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
            >
              <i class="la la-icon-font-size-13 la-download"></i>
              <span>
                <span>Export</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search{" "}
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
          <StyleHeader isDataLoaded={isDataLoaded} />

          <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => {
                    SortData("PhoneNumber");
                    setSortedColumn("PhoneNumber");
                  }}
                >
                  Phone Number
                  <SortingIcons
                    SortedBy={SortedBy}
                    sortedColumn={sortedColumn}
                    ColumnName={"PhoneNumber"}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("ResultCode");
                    setSortedColumn("ResultCode");
                  }}
                >
                  Code
                  <SortingIcons
                    SortedBy={SortedBy}
                    sortedColumn={sortedColumn}
                    ColumnName={"ResultCode"}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Reason");
                    setSortedColumn("Reason");
                  }}
                >
                  Reason
                  <SortingIcons
                    SortedBy={SortedBy}
                    sortedColumn={sortedColumn}
                    ColumnName={"Reason"}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("CreatedDate");
                    setSortedColumn("CreatedDate");
                  }}
                >
                  Created Date
                  <SortingIcons
                    SortedBy={SortedBy}
                    sortedColumn={sortedColumn}
                    ColumnName={"CreatedDate"}
                  />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? (
                <p className="text-center">No data in Available</p>
              ) : (
                Rows?.map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell>{row?.PhoneNumber}</TableCell>
                    <TableCell>{row?.ResultCode}</TableCell>
                    <TableCell>{row?.Reason}</TableCell>
                    <TableCell>
                    {row?.PSTCreatedDate}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      <Pagenations
        Sflag={Sflag}
        CountPage={CountPage}
        HandleChangePage={HandleChangePage}
        Rlen={Rlen}
        Page={Page}
        RowsPerPage={RowsPerPage}
        Flen={Flen}
      />
    </div>
  );
}
