import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Opportunities from "../../../_components/user/Opportunities/Opportunities";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { GetClientDetails } from "../../../_helpers/Utility";
import loadingi from "../../../images/loading.gif";

class OpportunitiesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ExportData: [],
      Role: null,
      ClientID: null,
      UserID: null,
      CName:""
    };

    this.addpage = this.addpage.bind(this);
  }
  addpage() {
    history.push({
      pathname: "/addbookedmeeting",
      state: { Pagename: "oppotunities", BackPage: "/opportunities" },
    });
  }
  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ChildUserID });
      this.setState({ Role: Details.Role });
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });
    document.getElementById("hidelod").style.display = "none";
    const InputParameter = {
      ClientID: Details.ClientID ,
      Role:Details.Role
    };
    // Axios({
    //   url:
    //     CommonConstants.MOL_APIURL + "/opportunities/MeetingOpportunitiesExport",
    //   method: "POST",
    //   data: InputParameter,
    // }).then((res) => {
    //   this.setState({ ExportData: res?.data?.Data });
    // });
  }


  Exportdetails = async (CID, URole) => {
   
    var str_in = {
      ClientID: CID,
      Role: URole,
    };

    try {
      const rows1 = await Axios({
        url: CommonConstants.MOL_APIURL + '/opportunities/MeetingOpportunitiesExport',
        method: 'POST',
        data: str_in,
      });

      if (rows1.data.StatusMessage === 'SUCCESS') {
        return rows1.data;
      }
    } catch (error) {
      console.error('Exportdetails error:', error);
      throw error;
    }
  };

  exportcsv = async () => {
    try {
  
      document.getElementById('hidelod').style.display = 'block';
      const result = await this.Exportdetails(this.state.ClientID, this.state.Role);

      if (result.StatusMessage === 'SUCCESS') {
        const data = result.Data;

        if (data.length > 0) {
          this.setState({ exportData: data });

          const downloader = document.createElement('a');
          const csvContent =
            'data:text/csv;charset=utf-8,' +
            encodeURIComponent(this.convertToCSV(data));

          downloader.setAttribute('href', csvContent);
          downloader.setAttribute(
            'download',
            this.state.CName + '-Meeting.csv'
          );
          downloader.click();
          toast.success(
            <div>
              Meeting <br /> Data exported successfully.
            </div>
          );
          document.getElementById('hidelod').style.display = 'none';
        } else {
          toast.error('No data available for export.');
          document.getElementById('hidelod').style.display = 'none';
        }
      } else {
        toast.error('Failed to export data. Please try again.');
        document.getElementById('hidelod').style.display = 'none';
      }
    } catch (error) {
      console.error('exportcsv error:', error);
      toast.error('An error occurred while exporting data.');
    }
  };

  convertToCSV(data) {
    const headers = Object.keys(data[0]);
    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
      const values = headers.map(header => {
        const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
        const escapedValue = value.replace(/"/g, '""');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
  }

  render() {
    return (
      <>
    <div id="hidelod" className="loding-display">
        <img src={loadingi} />
      </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col d-flex justify-content-between align-items-center">
                <h4 className="headertitle lg-headertitle pl-0 py-4 mb-0">Opportunities</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right pb-3">
                  <ul>
                    <li>
                      <a
                        className="btn btngroup m-btn m-btn--custom"
                        onClick={this.exportcsv}
                      >
                      
                          <i class="la la-icon-font-size-13 la-download"></i>
                          Export
                 
                      </a>
                    </li>
                    <li>
                      <a
                        className="btn btngroup m-btn m-btn--custom  "
                        onClick={() => this.addpage()}
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-border-box p-3">
              <div className="row">
                <div className="col">
                  <Opportunities />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedOpportunitiesPage = connect(
  mapState,
  actionCreators
)(OpportunitiesPage);
export { connectedOpportunitiesPage as OpportunitiesPage };
