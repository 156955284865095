import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import CBookedMeeting from "../../_components/clients/bookedmeeting/BookedmeetingTabing";
//import CollapsibleTable from '../_components/usertable';

class CBookedMeetingsPage extends React.Component {
  componentDidMount() {
    window.addEventListener('storage', (event) => {
            
      if (event.key === 'clientChanged') {
        window.location.reload();
      }
    });
}
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col px-3">
                <h4 className="pt-4 pb-3 mb-0 headertitle lg-headertitle float-left pl-0">Booked Meetings</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CBookedMeeting />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCBookedMeetingsPage = connect(
  mapState,
  actionCreators
)(CBookedMeetingsPage);
export { connectedCBookedMeetingsPage as CBookedMeetingsPage };
