import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Axios from "axios";

import DomainTable from '../../../_components/user/GlobalBlackList/DomainTable';
import ContactTable from '../../../_components/user/GlobalBlackList/ContactTable'; 
import PhoneNumberDNC from '../../../_components/user/GlobalBlackList/PhoneNumberDNC';  
import PhoneNumberBlacklist from '../../../_components/user/GlobalBlackList/PhoneNumberBlacklist';  
 
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import loadingicon from "../../../images/loading.gif";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [ClientID, setClientID] = useState(0);
  const [UserID, setUserID] = useState(0);
  const [Role, setRole] = useState("");
  const [CUserID, setPUserID] = useState(0);
  const [phonenumberBtn, setPhonenumberBtn] = useState(false);
  const [Errors, setErrors] = useState({});
  const [UpdateKey, setUpdateKey] = useState(0);
  const [UserEmail, setUserEmail] = useState("");
  const [PhoneNumberChaild, setPhoneNumberChaild] = useState(false);
  const [AddBox, setAddBox] = useState(false);
  const [OldPhoneNumberData, setOldPhoneNumberData] = useState("");

  useEffect(() => {
    document.title = "Global Blacklist | SalesHive";
    const Details = GetUserDetails();

    if (Details) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      setUserEmail(Details.Username);
      setPUserID(Details.ChildUserID);
      setRole(Details.Role);
    }

    if (props?.propdata?.location?.state) {
      setValue(props?.propdata?.location?.state?.page === "contact" ? 1 : 0);
    }
  }, [props]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setAddBox(false);
    setErrors({});
  };

  const addPage = () => {
    history.push("/addglobalblacklist");
  };

  const addContactPage = () => {
    history.push("/addglobalblacklistcontact");
  };

  const PhoneNumberAdd = async () => {
    setPhonenumberBtn(true);
    const isValid = await PhoneNumberOnBlur();
    if (isValid) {
      const Phonenumber = document.getElementById("phonenumber").value.trim();
      const PhonenumberAddData = {
        ClientID,
        PhoneNumber: Phonenumber,
        UserID,
        CreatedBy: CUserID,
      };
      try {
        const res = await Axios.post(
          `${CommonConstants.MOL_APIURL}/global_phonenumberblacklist/phonenumberadd`,
          PhonenumberAddData
        );
        if (res.data.StatusMessage === "SUCCESS") {
          setPhoneNumberChaild(true);
          toast.success(
            <div className="toastsize">
              Phone Number<br />
              Phone Number added successfully.
            </div>
          );
          document.getElementById("phonenumber").value = "";
          setPhoneNumberChaild(false);
          PhoneNumberClose();
          setUpdateKey(UpdateKey + 1);
        } else {
          toast.error("Internal server error");
          setPhonenumberBtn(false);
        }
      } catch (error) {
        toast.error("Error while adding phone number");
        setPhonenumberBtn(false);
      }
    } else {
      setPhonenumberBtn(false);
    }
  };

  const PhoneNumberClose = () => {
    setErrors({});
    setAddBox(false);
    setPhonenumberBtn(false);
  };

  const PhoneNumberOnBlur = async () => {
    const phoneNumber = document.getElementById("phonenumber").value.trim();
    const errors = {};

    if (phoneNumber === "" || phoneNumber.indexOf(" ") !== -1) {
      errors["PhoneNumberlist"] = "Please enter phone number";
    } else if (!/^[0-9]+$/.test(phoneNumber)) {
      errors["PhoneNumberlist"] = "Invalid phone number";
    }

    const isExist = await PhoneNumberCheckExist(phoneNumber);

    if (isExist) {
      errors["PhoneNumberlist"] = "Phone number already exists!";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const PhoneNumberCheckExist = async (phoneNumber) => {
    const res = await Axios.post(
      `${CommonConstants.MOL_APIURL}/global_phonenumberblacklist/phonenumberCheackExists`,
      { ClientID, UserID, PhoneNumber: phoneNumber }
    );
    if (res.data.StatusMessage === "SUCCESS") {
      return res.data.PhoneNumberData.length > 0 && OldPhoneNumberData !== phoneNumber;
    }
    return false;
  };

  return (
    <div className="tabdes"> 
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example"> 
            <Tab label="Domain" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Contact" {...a11yProps(1)} /> 
            <Tab label="Phone Number" {...a11yProps(2)} /> 
            <Tab label="DNC" {...a11yProps(3)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
            <div className="row mx-0 align-items-center border-bottom">
              <div className="col">
                  <h4 className="headertitle xs-headertitle pl-0 float-left">Domain</h4>   
              </div> 
              <div className="col">
                  <div className="listing-li float-right">
                      <ul className='my-0'>
                       <li>
                       <a
                        onClick={addPage}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                        </li> 
                      </ul>
                  </div>
              </div>
            </div>


           

            <div className="row p-3">
            <div className="col">
              <div className="table-bordered border-0 my-0">
                <DomainTable />
              </div>
            </div>
          </div> 
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={1}>
            <div className="row mx-0 align-items-center">
              <div className="col border-bottom">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Contact</h4>   
              </div> 
              <div className="col border-bottom">
                  <div className="listing-li float-right padb-15">
                      <ul>
                       <li>
                       <a
                        onClick={addContactPage}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                        </li> 
                      </ul>
                  </div>
              </div>
            </div>



            <div className="row">
            <div className="col">
              <div className="table-bordered border-0 my-0">
                <ContactTable />
              </div>
            </div>
          </div> 
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={2}>
        <div className="row align-items-center mx-0 border-bottom">
            <div className="col">
              <h4 className="headertitle xs-headertitle float-left  pl-0">Phone Number</h4>
            </div>
            <div className="col">
              <div className="listing-li float-right padb-15 pr-1">
                <ul>
                  <li>
                    <a
                      onClick={() => setAddBox(true)}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                
                </ul>
              </div>
            </div>
          </div>


          {AddBox ? (
            <div className="addkeyword row my-4 mx-0" id="whitelistcontacview">
              <div className="col-sm-12 col-md-6 row">
                <label className="col-lg-3 view-lable-meet">Phone Number</label>
                <div className="col-lg-9">
                  <input
                    className="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter Phone number"
                    id="phonenumber"
                    onBlur={PhoneNumberOnBlur}
                    name="PhoneNumber"
                    placeholder="Enter phone number"
                    type="text"
                  />
                  <span style={{ color: "red" }} id="validation_email">
                    {Errors["PhoneNumberlist"]}
                  </span>
                </div>
              </div>
              <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
                <button
                  disabled={phonenumberBtn}
                  className="btn btn-primary btn-lightgreen mr-1"
                  onClick={PhoneNumberAdd}
                >
                  <i className="la la-save"></i> Save
                </button>
                <a
                  className="btn btn-secondary"
                  onClick={PhoneNumberClose}
                >
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}



<div className="row">
<div className="col px-0">
              <div className="table-bordered border-0 my-0">
                <PhoneNumberBlacklist 
                  addWhiteListed={PhoneNumberChaild}
                  key={UpdateKey}
                />
              </div>
            </div>
          </div> 
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={3}>
            <div className="row">
            <div className="col">
              <div className="table-bordered border-0 my-0">
                <PhoneNumberDNC />
              </div>
            </div>
          </div> 
        </TabPanel>
      </div>  
    </div>
  );
}



