import React from "react";
import { connect } from "react-redux";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Axios from "axios";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import addNewFilter from '../../images/addFilter.svg'
import existFilter from '../../images/existFilter.svg'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "react-toastify/dist/ReactToastify.css";

import { GetUserDetails } from "../../_helpers/Utility";
import { userActions } from "../../_actions";

import Footer from "../../_components/user/footer/footer";
import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import PotentialMeeting from "../../_components/clients/potentialmeeting/potentialmeeting";
import { CommonConstants } from "../../_constants/common.constants";
import { history } from "../../_helpers";
import { GetClientDetails } from "../../_helpers/Utility";
import { EditAttributes } from "@material-ui/icons";
import loadingicon from "../../images/loading.gif";

class CPotentialMeetingsPage extends React.Component {
 
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      rowsPerPage: 10,
      setdata: [],
      exportdata: [],
      filterdata1: [],
      popupval: false,
      ClientID: null,
      UserID: null,
      CUserID: null,
      selectedvalue: "",
      dropdownvalue: "",
      sortField: "",
      sortedBy: 1,
      data: [],
      filterdata1: [],
      filterpropertydata1: [],
      defaulselectfilter: "",
      rows2: [],
      rlen: 0,
      flen: 0,
      countpage: 0,
      Counter: [0],
      Counter2: [],
      filterResponse: {},
      newdata: [],
      rows: [{}],
      senddata: {},
      display: true,
      FilterParameters: [],
      IsApply: false,
      FieldAttributes:[{id: 0, ColumnName: 'AccountName', InputType: 'ContainTo'}],
      OpenAddFilter:false,
      FilterName:[],
      EditFieldAttributes:[],
      FilterID:null,
      DeletedFilterProperty:[],
      CName: "",
      Role:"",
      IsPopupOpen:false,
      filterBtnDisabled:false,
      IsLoading:false
    };
    this.counter = 0;
    this.addpage = this.addpage.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleEditRemoveClick = this.handleEditRemoveClick.bind(this);
    this.ExpressionHandler = this.ExpressionHandler.bind(this);
    this.handledDropdownChange = this.handledDropdownChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.Getfilterpropertylist = this.Getfilterpropertylist.bind(this);
    this.editapplyfilterSearch = this.editapplyfilterSearch.bind(this);
  }
componentDidMount(){
    window.addEventListener('storage', (event) => {
            
      if (event.key === 'clientChanged') {
        window.location.reload();
      }
    });

  let StateArr = this.state.FieldAttributes;
  let ColumnName = document.getElementById("ColumnName" +0).value;
   let InputType = document.getElementById("expression" +0).value;
    let indexed = StateArr.findIndex(v => v.id === 0);
    if(indexed == -1){
      StateArr.push({id:0,ColumnName:ColumnName,InputType:InputType})
    }else{
      StateArr[indexed]={id:0,ColumnName:ColumnName,InputType:InputType}
    }
    this.setState({FieldAttributes:StateArr});

    
}
  // pass data child to parent
  update = (pdata) => {
    this.setState({
      fromchild: pdata,
    });
  };

     //Reload page while delete perform
  UpdateFromChild = (value) => {
      if(value == true){
        this.componentDidMount()
      }
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role=Details.Role
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });

    this.Getfilterlist();

  }

  toggleDisplay() {
    this.setState({
      display: !this.state.display,
    });
  }

  ExportCsvPotential=async ()=>{
    const InputParameter = {
      ClientID: this.state.ClientID,
      UserID:this.state.UserID,
      Role:this.state.Role,
      IsApply: this.state.FilterParameters.length===0?false:true,
      PotentialMeetingFilterArray: this.state.FilterParameters,
    };
    try {
      const rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/potentialmeeting/PotenialMeetingExport",
      method: "POST",
      data: InputParameter,
    })

    if (rows1.data.StatusMessage === "SUCCESS") {
      return rows1.data;
    }
  } catch (error) {
    console.error("Exportdetails error:", error);
    throw error; // Rethrow error for handling in exportcsv function
  }
  }


  exportcsv = async () => {
    try {
      this.setState({ IsLoading: true });

      const result = await this.ExportCsvPotential();

      if (result && result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          this.setState({ exportData: data });
          const downloader = document.createElement("a");
          const csvContent =
            "data:text/csv;charset=utf-8," +
            encodeURIComponent(this.convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute(
            "download",
            this.state.CName + "-PotentialMeetings.csv"
          );
          downloader.click();
          toast.success("Meeting Data exported successfully.");
          this.setState({ IsLoading: false });
        } else {
          toast.error("No data available for export.");
          this.setState({ IsLoading: false });
        }
      } else {
        toast.error("Failed to export data. Please try again.");
        this.setState({ IsLoading: false });
      }
      this.setState({ IsLoading: false });
    } catch (error) {
      toast.error("An error occurred while exporting data.");
      this.setState({ IsLoading: false });
    }
  };

  convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const csvRows = [];
    csvRows.push(headers.join(","));

    data.forEach((item) => {
      const values = headers.map((header) => {
        const value =
          typeof item[header] === "string"
            ? item[header]
            : String(item[header]);
        const escapedValue = value.replace(/"/g, '""');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  };

  handledDropdownChange(i) {
    // this.setState({ selectedvalue: e.target.value });
    let StateArr = this.state.FieldAttributes;
    let ColumnName = document.getElementById("ColumnName" +i).value?.trim();
     let InputType = document.getElementById("expression" +i).value;
     document.getElementById("expression" +i).value = 'ContainTo'
     document.getElementById("searchvalue" + i).value = ""
    //  document.getElementById("searchvalue"+i).type = "date";
      let indexed = StateArr.findIndex(v => v.id === i);
      if(indexed == -1){
        StateArr.push({id:i,ColumnName:ColumnName,InputType:InputType})
      }else{
        StateArr[indexed]={id:i,ColumnName:ColumnName,InputType:InputType}
      }
      this.setState({FieldAttributes:StateArr});
  }

  handleClick = () => {

    let StateArray = this.state.FieldAttributes;
    
    StateArray.push({id: StateArray.length+1, ColumnName: 'AccountName', InputType: 'ContainTo'});
    this.setState({FieldAttributes:StateArray})
    
  };

  ExpressionHandler = (i)=>{
    let StateArr = this.state.FieldAttributes;
    let ColumnName = document.getElementById("ColumnName" +i).value;
     let InputType = document.getElementById("expression" +i).value;
      let indexed = StateArr.findIndex(v => v.id === i);
      if(indexed == -1){
        StateArr.push({id:i,ColumnName:ColumnName,InputType:InputType})
      }else{
        StateArr[indexed]={id:i,ColumnName:ColumnName,InputType:InputType}
      }
      this.setState({FieldAttributes:StateArr});
  }

  handleRemoveClick = (id) => {
    var countArray2 = [];
    this.state.FieldAttributes.map((element) => {
      if (element.id != id) {
        countArray2.push(element);
      }
    });
    this.setState({
      FieldAttributes: countArray2,
    });
  };

   handleExistingfilter=()=>{
        
    if(this.state.EditFieldAttributes.length>0){
      this.setState({IsPopupOpen:true,filterBtnDisabled:false})
    }else{

      toast.error(
        <div className="toastsize">
          Filter
          <br />
          No filter available
        </div>
      );
    }
  }

  closePopup = () => {
    this.setState({IsPopupOpen:false})
  };

  handledContainDropdownChange(e) {
    this.setState({ dropdownvalue: e.target.value });
  }


  addpage() {
    history.push({
      pathname: "/caddpotentialmeetings",
      state: { Pagename: "" },
    });
  }
 

  // filter validation 
  FilterValidation(){
    try {
    let ValidationArr=[];
    
    this.state.FieldAttributes.map((newvalue) => {
      var searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value?.trim();
      if(searchedVal1.trim() == ""){
        ValidationArr.push(false)
      }else{
        ValidationArr.push(true)
      }
      var expressionval = document.getElementById("expression" + newvalue.id).value;
      if(expressionval== "between"){
        let FilterPropertyValue2 = document.getElementById("EndsWith" +newvalue.id).value?.trim();
        if(FilterPropertyValue2.trim() == ""){
          ValidationArr.push(false)
        }else{
          ValidationArr.push(true)
        }
      }
     });

     return ValidationArr.includes(false);

      
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  filterSearch() {
    try {
      
    if(this.FilterValidation() == false){
      if(this.state.FieldAttributes.length <= 0){
        toast.error(<div className="toastsize">Filter<br/>Please add filter property at least one</div>)
      }else{

      var PotentialMeetingFilterSecArray = [];
      this.state.FieldAttributes.map((newvalue) => {
        var searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value?.trim();
        var expressionval = document.getElementById("expression" + newvalue.id).value;
        var Cloumnval = document.getElementById("ColumnName" + newvalue.id).value;
        if(expressionval == "between"){
        var searchedVal2 = document.getElementById("EndsWith" + newvalue.id).value?.trim();
          PotentialMeetingFilterSecArray.push({
            ColumnName: Cloumnval,
            expression: expressionval,
            searchvalue: searchedVal1,
            searchvalue1: searchedVal2,
          });
        }else{
          PotentialMeetingFilterSecArray.push({
            ColumnName: Cloumnval,
            expression: expressionval,
            searchvalue: searchedVal1,
            searchvalue1: null,
  
          });
        }
       });
      this.setState({ FilterParameters: PotentialMeetingFilterSecArray })
      this.setState({ IsApply: true });
      this.setState({FieldAttributes:[]});
      this.setState({OpenAddFilter:false});
      this.setState({FieldAttributes:[{id: 0, ColumnName: 'AccountName', InputType: 'ContainTo'}]})
      }
    }else{
      toast.error(<div className="toastsize">Filter<br/>
        Property value can not be an empty</div>)
    }
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  }

 

  // Edit add filter property
  editsavefilterpropertyfilter() {
    try {

    var PotentialMeetingFilterSecArray = [];
    this.state.Counter2.map((editnewvalue) => {
      var searchedVal1 = document.getElementById(
        "editsearchvalue" + editnewvalue
      ).value?.trim();
      var expressionval = document.getElementById(
        "editexpression" + editnewvalue
      ).value;
      var Cloumnval = document.getElementById(
        "EditColumnName" + editnewvalue
      ).value;

      PotentialMeetingFilterSecArray.push({
        ColumnName: Cloumnval,
        expression: expressionval,
        searchvalue: searchedVal1,
      });
    });
    var filterdropoid = document.getElementById("FilterID").value;

    var str_in = {
      page: 1,
      ClientID: this.state.ClientID,
      rowsPerPage: this.state.rowsPerPage,
      sort: true,
      sortby: this.state.sortedBy,
      type: "User",
      IsApply: false,
      FilterID: filterdropoid,
      UserID: this.state.UserID,
      IsSave: true,
      CreatedBy: this.state.CUserID,
      CreatedDate: new Date(),
      PotentialMeetingFilterArray: PotentialMeetingFilterSecArray,
      LastUpdatedDate: new Date(),
      LastUpdatedBy: this.state.CUserID,
      Role:this.state.Role
    };

    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/potentialmeeting/EditPotentialMeeting",
      method: "POST",
      data: str_in,
    });

    rows1.then((filterresult) => {
      if (filterresult.statusText == "OK") {
        toast.success(
          "potential meeting filter add successfully.",
          "Edit potential meeting filter"
        );

        this.setState({ data: filterresult.data.pagedata });
        // this.setState({ rows2: filterresult.data.pagedata });
        // this.setState({ rlen: filterresult.data.totalcount });
        // this.setState({ flen: filterresult.data.totalcount });
        // this.setState({ countpage: filterresult.data.pagecount });
        // this.setState({filterdata1:filterresult.data.filterdata})
      } else {
        toast.error(filterresult.data.Message);
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
    });
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  }

  //Check filter name exists or not
 async CheckFilterName(){
  try {

    let FilterName = document.getElementById('FilterName').value.trim();
    let flag=false;
    const InputParameters = {
      ClientID:this.state.ClientID,
      FilterName:FilterName,
      Role:this.state.Role
    }
   let res = await Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/CheckFilterName",
      method:"POST",
      data:InputParameters
    })
      if(res.data.StatusMessage == "SUCCESS"){
        if(res.data.Data <= 0){
          flag = true
        }else{
          flag = false
        }
      }else{
        toast.error("Something went wrong in filter name exists api")
      }
    
    return flag;
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  }


  // Save Filter Property
 async addfilterPotentialfilter() {
  try {
    
  this.setState({filterBtnDisabled:true})
    let FilterName = document.getElementById('FilterName').value.trim();
    if(FilterName == ""){
      toast.error(<div className="toastsize">Filter<br/>Please enter filter name</div>)
      this.setState({filterBtnDisabled:false})

    }else{
      if(await this.CheckFilterName() == false){
        toast.error(<div className="toastsize">Filter<br/>Filter value already exist</div>);
        this.setState({filterBtnDisabled:false})
      }else{
        if(this.FilterValidation() == false){
          if(this.state.FieldAttributes.length <= 0){
            toast.error(<div className="toastsize">Filter<br/>Please add filter property at least one</div>);
             this.setState({filterBtnDisabled:false})

          }else{  
          var PotentialMeetingFilterSecArray = [];
          this.state.FieldAttributes.map((newvalue) => {
            var searchedVal1 = document.getElementById("searchvalue" + newvalue.id)?.value?.trim();
            var expressionval = document.getElementById("expression" + newvalue.id)?.value;
            var Cloumnval = document.getElementById("ColumnName" + newvalue.id)?.value;
            if(expressionval == "between"){
            var searchedVal2 = document.getElementById("EndsWith" + newvalue.id).value?.trim();
              PotentialMeetingFilterSecArray.push({
                ColumnName: Cloumnval,
                expression: expressionval,
                searchvalue: searchedVal1,
                searchvalue1: searchedVal2,
              });
            }else{
              PotentialMeetingFilterSecArray.push({
                ColumnName: Cloumnval,
                expression: expressionval,
                searchvalue: searchedVal1,
                searchvalue1: null,
      
              });
            }
           });

           const InputParameters = {
            ClientID:this.state.ClientID,
            FilterName:FilterName,
            FilterParameters:PotentialMeetingFilterSecArray,
            CreatedDate:new Date(),
            UserID:this.state.UserID,
            Role:this.state.Role
           }

           let res = await Axios({
            url:CommonConstants.MOL_APIURL + "/potentialmeeting/SaveFilter",
            method:"POST",
            data:InputParameters
           });
           if(res.data.StatusMessage == "SUCCESS"){
            this.setState({ FilterParameters: PotentialMeetingFilterSecArray, IsApply: true })
            // this.setState({ IsApply: true });
            this.setState({FieldAttributes:[]});
            this.setState({OpenAddFilter:false});
            this.setState({FieldAttributes:[{id: 0, ColumnName: 'AccountName', InputType: 'ContainTo'}]})
            this.Getfilterlist();
            toast.success(<div className="toastsize">Filter <br/>
              Filter Added SuccessFully.</div>)
           }else{
            toast.error("Error in save filter");
            this.setState({filterBtnDisabled:false})
           }
          }
        }else{
          toast.error(<div className="toastsize">Filter<br/>
            Property value can not be an empty</div>);
        this.setState({filterBtnDisabled:false})

        }
      }
     
    }
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  }

  // Get Filter List
  Getfilterlist() {
    var InputParameters = {
      ClientID: this.state.ClientID,
      Role:this.state.Role
    };
    
    const rows3 = Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/GetEditFilterDropDown",
      method: "POST",
      data: InputParameters,
    });
    rows3.then((result) => {
      
      this.setState({ FilterName: result.data.Data });
      if(result.data.Data.length > 0){
        this.Getfilterpropertylist(result.data.Data[0]._id);
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
    });
  }

  handleChangeFilter() {
    var filterdropoid = document.getElementById("FilterID").value?.trim();
    this.Getfilterpropertylist(filterdropoid);
  }

  // Get FilteProperty list with dropdown
  Getfilterpropertylist(filterid) {
    this.setState({FilterID:filterid})
    var InputParameters = {
      ClientID: this.state.ClientID,
      FilterID: filterid,
      IsDeleted: false,
      Role:this.state.Role
    };
    const rows4 = Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/GetEditFilterProperties",
      method: "POST",
      data: InputParameters,
    });
    rows4.then((result) => {
      let FilteredArr = [];
      result.data?.Data?.forEach((el,i)=>{
          el.id = i;
          el.IsSaved=true;
          FilteredArr.push(el)
      })
      this.setState({ EditFieldAttributes: FilteredArr});
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
    })
  }

  //Reset Filter
  ResetFilter=()=>{
    this.setState({IsApply:false});
    this.setState({FilterParameters:[]})
  }



  //Open Add Filter model
  OpenAddFilter = () => {
    this.setState({OpenAddFilter:true});
    this.setState({filterBtnDisabled:false});
    this.closePopup();
    this.Getfilterlist();
  }

  //Edit column name 
  EditColumnName = (ID) =>{
    try {
      
    let SavedData = this.state.EditFieldAttributes;
    let FilterPropertyName = document.getElementById("EditColumnName"+ID).value;
    document.getElementById("Editsearchvalue" +ID).value = "";
    // document.getElementById("EditEndsWith" +ID).value = "";
    document.getElementById("Editexpression" +ID).value = "ContainTo"
    let indexed = SavedData.findIndex(v => v.id === ID);
    SavedData[indexed].FilterPropertyName = FilterPropertyName;
    SavedData[indexed].FilterPropertyValue1 = "";
    SavedData[indexed].FilterPropertyValue2 = "";
    SavedData[indexed].ExpressionName = "ContainTo";
    this.setState({EditFieldAttributes:SavedData})
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  }

  //Edit Expression value
  EditExpressValue = (ID)=>{
    try {

    let SavedData = this.state.EditFieldAttributes;
    let ExpressionName = document.getElementById("Editexpression" +ID).value;
    let indexed = SavedData.findIndex(v => v.id === ID);
    SavedData[indexed].ExpressionName = ExpressionName;
    
    this.setState({EditFieldAttributes:SavedData})
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  }

  //Edit FilterPropertyValue1
  EditFilterPropertyValue1 = (ID)=>{
    try {
      

      let SavedData = this.state.EditFieldAttributes;
      let FilterPropertyValue1 = document.getElementById("Editsearchvalue" +ID).value;
      let indexed = SavedData.findIndex(v => v.id === ID);
      SavedData[indexed].FilterPropertyValue1 = FilterPropertyValue1;
      this.setState({EditFieldAttributes:SavedData});
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  //Edit FilterPropertyValue2
  EditFilterPropertyValue2 = (ID)=>{
    try {
      let SavedData = this.state.EditFieldAttributes;
      let FilterPropertyValue2 = document.getElementById("EditEndsWith" +ID).value?.trim();
      let indexed = SavedData.findIndex(v => v.id === ID);
      SavedData[indexed].FilterPropertyValue2 = FilterPropertyValue2;
      this.setState({EditFieldAttributes:SavedData})
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  //Add new filter property
  handleeditPropertyClick = () => {
    let StateArray = this.state.EditFieldAttributes;
    StateArray.push({id: StateArray.length+1, FilterPropertyName: 'AccountName', ExpressionName: 'ContainTo',IsSaved:false});
    this.setState({EditFieldAttributes:StateArray})
  };

  //Remove filter property
  handleEditRemoveClick = (id) => {
    try {
      
    let SavedData = this.state.EditFieldAttributes;
    
    if(SavedData.length <=1){
        toast.error(<div>Filter<br/>Required filter property at least one</div>);
    this.setState({filterBtnDisabled:false})

    }else{
    let DeletedArr = this.state.DeletedFilterProperty;
    
    const FilterPropertyID = SavedData.findIndex((item)=>item.id == id);
    if(SavedData[FilterPropertyID].IsSaved == true){
      DeletedArr.push(SavedData[FilterPropertyID]._id)
      this.setState({DeletedFilterProperty:DeletedArr})
    }
    const FilteredData = SavedData.filter((item) => item.id !== id);
    this.setState({EditFieldAttributes:FilteredData}); 
  }
} catch (error) {
  toast.error("An error occurred. Please try again.");
  this.setState({filterBtnDisabled:false})

}
  };

  //Update Filter validation
  UpdateFilterValidation(){
    try {
      
    let Flags = [];
    this.state.EditFieldAttributes.forEach((el)=>{
      var searchedVal1 = document.getElementById("Editsearchvalue" + el.id).value?.trim();
        if(searchedVal1.trim() == ""){
          Flags.push(false)
        }else{
          Flags.push(true)
        }
        if(el.ExpressionName == "between"){
          let FilterPropertyValue2 = document.getElementById("EditEndsWith" +el.id).value?.trim();
          if(FilterPropertyValue2.trim() == ""){
            Flags.push(false)
          }else{
            Flags.push(true)
          }
        }
    });
  
       return Flags.includes(false);
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
  }

  //Update and apply filter
 async editapplyfilterSearch() {
  this.setState({filterBtnDisabled:true})
  if(this.UpdateFilterValidation() == false){
    
    const InputParameters = {
      ClientID:this.state.ClientID,
      FilterParameters:this.state.EditFieldAttributes,
      LastUpdatedDate:new Date(),
      UserID:this.state.UserID,
      FilterID:this.state.FilterID,
      DeletedFilterProperty:this.state.DeletedFilterProperty,
      Role:this.state.Role
    }
    

     let res = await Axios({
      url:CommonConstants.MOL_APIURL + "/potentialmeeting/UpdateFilterProperties",
      method:"POST",
      data:InputParameters
     });
     
     if(res.data.StatusMessage == "SUCCESS"){
      toast.success(<div className="toastsize">Filter <br/>Filter Updated SuccessFully.</div>)
      var PotentialMeetingFilterSecArray = [];
      this.state.EditFieldAttributes.map((el) => {
        
        if(el.ExpressionName == "between"){
          PotentialMeetingFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1,
            searchvalue1: el.FilterPropertyValue2,
          });
        }else{
          PotentialMeetingFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1,
            searchvalue1: null,
  
          });
        }
       });
      this.setState({ FilterParameters: PotentialMeetingFilterSecArray,IsApply: true })
      // this.setState({ IsApply: true });
      this.setState({OpenAddFilter:false})
     }else{
      toast.error("Error in save filter");
      this.setState({filterBtnDisabled:false})

     }
     
  }else{
    toast.error(<div className="toastsize">Filter<br/>
      Property value can not be an empty</div>);
      this.setState({filterBtnDisabled:false})
  }
  }

  //Apply Edit Filter
  ApplyEditFilter(){
    var PotentialMeetingFilterSecArray = [];
    this.state.EditFieldAttributes.map((el) => {
      
      if(el.ExpressionName == "between"){
        PotentialMeetingFilterSecArray.push({
          ColumnName: el.FilterPropertyName,
          expression: el.ExpressionName,
          searchvalue: el.FilterPropertyValue1,
          searchvalue1: el.FilterPropertyValue2,
        });
      }else{
        PotentialMeetingFilterSecArray.push({
          ColumnName: el.FilterPropertyName,
          expression: el.ExpressionName,
          searchvalue: el.FilterPropertyValue1,
          searchvalue1: null,

        });
      }
     });
    this.setState({ FilterParameters: PotentialMeetingFilterSecArray })
    this.setState({ IsApply: true });
    this.setState({OpenAddFilter:false})
  }

  //close edit filter
  CloseEditFilter(){
    this.setState({EditFieldAttributes:[]});
    this.setState({FieldAttributes:[]});
    this.setState({FieldAttributes:[{id: 0, ColumnName: 'AccountName', InputType: 'ContainTo'}]})
    this.setState({ IsApply: false });
    this.Getfilterlist();
    this.setState({OpenAddFilter:false});
    this.setState({filterBtnDisabled:false})

  }
  render() {
    return (
      <>
       {
          this.state.IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
        }
        <Popup>
          {(close) => (
            <div>
              <div className="modal-black"></div>
              <div className="filterPopup rounded-0">
                <div className="paddingboxTerms">
                <div className="modal-header pb-0 border-bottom-0 px-3">
                <h5 className='mb-0 font-weight-bolder'>Filter</h5>
                <a
                      className="close-ion"
                      onClick={this.closeTermsConditionModal}
                    >
                      <span aria-hidden="true" className="la la-remove"></span>
                    </a>

                    {/* <button className="close" onClick={close}></button> */}
                  </div>
                  <div className="modal-content bordernone  p-2">
                  <div className="row shadowcard border my-2 mx-2 py-4">
                  <div className="col-xl-6 allcentertext py-4 border-right px-1">
                  {/* <i className="la la-filter iconsfonts"></i> */}
                  <img src={addNewFilter} className='w-42px'/>
                      <div className='py-3'>
        <p className="description-text text-black  mb-0 ">Add a new filter to refine your search results.</p>
        </div>
                        <a
                          id="lnkAddNewFilter"
                          href="javascript:void(0);"
                          className="btn btn-md btn-primary btnColor"
                        >
                          Add New Filter
                        </a>
                      </div>
                      <div className="col-xl-6 allcentertext">
                        {/* <i className="la la-filter iconsfonts"></i> */}
                        <img src={existFilter} className='w-42px'/>

<div className='py-3'>
<p className="description-text text-black  mb-0">Select from your existing filters for your results.</p>
</div>
                        <a
                          id="lnkAddNewFilter"
                          href="javascript:void(0);"
                          className="btn btn-md btn-primary btnColor"
                        >
                          Select from existing Filter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Popup>
        <div className="adminmain mheight"> 
            <Sidebar menupage="/potentialmeeting" className="" /> 
          <div className="bodyhome px-3">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle mb-0 py-4">
                  Potential Meetings
                </h4>
              </div>
              <div className="col">
                <div className="listing-li float-right pt-3">
                  <ul className="mt-0">
                    <li>
                  <a
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        onClick={this.exportcsv}
                      >
                        <i className="la la-icon-font-size-13 la-download"></i>
                        <span>
                          
                            Export
                          
                        </span>
                        {/* <span>Export</span> */}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => this.addpage()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                    <li>
                      <a className="btn btngroup m-btn " onClick={()=>{this.OpenAddFilter()}}>
                              <i className="fa la-icon-font-size-13 fa-filter"></i>

                              <span>Add Filter</span>
                            
                        <Popup open={this.state.OpenAddFilter}>
                      
                            <div>
                              <div className="modal-black"></div>
                              <div className="filterPopup rounded-0">
                              <div className="paddingboxTerms">
                                <div className="modal-header pb-0 border-bottom-0 px-3">
                                <div className="w-100 d-flex alig-items-center">
                                    <h5 className='mb-0 font-weight-bolder'>Filter</h5>
                                    <button className="close" onClick={()=>this.setState({OpenAddFilter:false})}>
                                        <span
                                          aria-hidden="true"
                                          className="la la-remove"
                                        ></span>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="modal-content  bordernone  p-2">
                                    <div className="">
                                    <div className="row shadowcard border my-2 mx-2 py-4">
                                    <div className="col-xl-6 allcentertext">
                                          {/* <i className="la la-filter iconsfonts"></i> */}
                                          <img src={addNewFilter} className='w-42px'/>
                      <div className='py-3 text-body'>
        <p className="description-text text-black  mb-0 ">Add a new filter to refine your search <br/> results.</p>
        </div>
                                          <Popup
                                            trigger={
                                              <button className="btn btn-md btn-primary btnColor">
                                                <span className="Mobile_button">
                                                  Add New Filter
                                                </span>
                                              </button>
                                            }
                                            modal
                                            nested
                                          >
                                            {(close) => (
                                              <div>
                                                <div className="modal-black"></div>
                                                <div className="filterPopup largerPopup1000">
                                                  <div className="paddingboxTerms">
                                                    <div className="modal-header py-4 px-2">
                                                      <div className="w-100 d-flex px-3">
                                                        <h5 className="mb-0">
                                                          New Filter
                                                        </h5>
                                                        <button
                                                          className="close"
                                                          onClick={()=>{close();
                                                          this.setState({FieldAttributes:[]});
                                                          this.setState({OpenAddFilter:false,filterBtnDisabled:false});
                                                          this.setState({FieldAttributes:[{id: 0, ColumnName: 'AccountName', InputType: 'ContainTo'}]})}}
                                                        >
                                                          <span
                                                            aria-hidden="true"
                                                            className="la la-remove"
                                                          ></span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                    <div className="p-3">
                                                      <div className="modal-body">
                                                        <div className="col-sm-6 mb-5 px-0">
                                                          <div className="row mb-3">
                                                            <label className="col-lg-5 col-form-label text-left grey-contect">
                                                              Filter Name
                                                            </label>
                                                            <div className="col-lg-7">
                                                              <input
                                                                className="form-control m-input"
                                                                data-val-required="Please enter title"
                                                                id="FilterName"
                                                                name="FilterName"
                                                                placeholder="Filter Name"
                                                                type="text"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="table-bordered">
                                                          <table
                                                            className="table table-bordered table-hover"
                                                            id="tab_logic"
                                                          >
                                                            <TableContainer
                                                              component={Paper}
                                                            >
                                                              <Table
                                                                size="small"
                                                                aria-label="a dense table"
                                                              >
                                                                <TableHead>
                                                                  <TableRow>
                                                                    <TableCell>
                                                                      Property{" "}
                                                                    </TableCell>
                                                                  
                                                                    <TableCell align="right"></TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                  </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                  {this.state.FieldAttributes.map(
                                                                    (
                                                                      itemcount,i
                                                                    ) => (
                                                                      <TableRow
                                                                            sx={{
                                                                          "&:last-child td, &:last-child th":
                                                                          {
                                                                            border: 0,
                                                                          },
                                                                        }}                          
                                                                      >
                                                                        <TableCell align="right">
                                                                          <select
                                                                            className="form-control m-input"
                                                                            id={"ColumnName" +itemcount.id}
                                                                            onChange={()=>{this.handledDropdownChange(itemcount.id)}}
                                                                            // value={this.state.selectedvalue}
                                                                            autocomplete="off"
                                                                          >
                                                                            <option id="accountname" value="AccountName" > Account Name </option>
                                                                            <option id="company" value="Company" > Company </option>
                                                                            <option id="createddate" value="CreatedDate" > Created Date </option>
                                                                            <option id="email" value="Email" > Email </option>
                                                                            <option id="engagamentstatusid" value="Status" > Engagament Status </option>
                                                                            <option id="firstname" value="FirstName" > First Name </option>
                                                                            <option id="lastname" value="LastName" > Last Name </option>
                                                                            <option id="linkedinUrl" value="LinkedInUrl" > LinkedIn URL </option>
                                                                            <option id="MeetingSourceID" value="MeetingSourceID">  Meeting Source </option>
                                                                            <option id="MeetingOwnerID" value="MeetingOwnerID" > Owner Name </option>
                                                                            <option id="ReminderEmailAccountID" value="ReminderEmailAccountID" > Reminder Email Account </option>
                                                                            <option id="title" value="Title" > Title </option>
                                                                          </select>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                          {this.state.FieldAttributes[i].ColumnName =="CreatedDate" ? (
                                                                            <select id={"expression" +itemcount.id}
                                                                              className="form-control m-input"
                                                                              onChange={()=>{this.ExpressionHandler(itemcount.id)}}
                                                                            >
                                                                              <option id="Equals" value="Equals">Equals</option>
                                                                              <option id="Lessthan" value="Lessthan" > Less Than </option>
                                                                              <option id="LessThanEqualTo" value="LessThanEqualTo"> Less Than Equal To </option>
                                                                              <option id="greaterthan" value="GreaterThan"> Greater Than </option>
                                                                              <option id="greaterthanequalto"  value="GreaterThanEqaulTo"> Greater Than Equal To </option>
                                                                              <option id="between"  value="between"> Between </option>
                                                                            </select>
                                                                          ) : (
                                                                            <select id={ "expression" + itemcount.id } onChange={()=>{this.ExpressionHandler(itemcount.id)}} className="form-control m-input">
                                                                              <option id="ContainTo" value="ContainTo">Contains</option>
                                                                              <option id="EqualTo" value="EqualTo">Equals</option>
                                                                            </select>
                                                                          )}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                        {
                                                                            itemcount.ColumnName == "CreatedDate" ? 
                                                                                itemcount.InputType == "between" ? (<>
                                                                                <div className="d-flex">
                                                                                <input type="date"  id={"searchvalue" +itemcount.id} className="form-control m-input required mr-2"/>  
                                                                                     <input type="date"  id={"EndsWith" +itemcount.id} className="form-control m-input required "/>
                                                                                </div>
                                                                                    
                                                                                    </>
                                                                                ) :
                                                                                ((<input type="date"  id={"searchvalue" +itemcount.id} className="form-control m-input required"/> )):
                                                                                (<input type="text"  id={"searchvalue" +itemcount.id} className="form-control m-input required"/> )

                                                                        }
                                                                         
                                                                        </TableCell>
                                                                        <TableCell align="right"></TableCell>
                                                                        <TableCell align="left">
                                                                        <button type="button"
                                                                            id={ "delete" + itemcount.id } className="btn btngroup"
                                                                            onClick={() => { this.handleRemoveClick( itemcount.id ); }}
                                                                          >
                                                                            <i className="la flaticon-delete-1"></i>
                                                                          </button>
                                                                        </TableCell>
                                                                      </TableRow>
                                                                    )
                                                                  )}
                                                                </TableBody>
                                                              </Table>
                                                            </TableContainer>
                                                          </table>
                                                        </div>
                                                        <div className="row pb-5">
                                                          <div className="col-sm-12 text-left">
                                                            <button
                                                              type="button"
                                                              className="btn btngroup"
                                                              value="ADD Row"
                                                              onClick={() => {
                                                                this.handleClick();
                                                                
                                                              }}
                                                            >
                                                              <i className="la la-plus la-icon-font-size-13"></i>{" "}
                                                              Add Property{" "}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="border-top modal-content text-right mt-5">
                                                        <div className="row pb-4">
                                                          <div className="col-lg-12 pull-right px-5">
                                                            <a
                                                              id="backtolist"
                                                              className="btn btn-secondary mr-2"
                                                              onClick={() => {
                                                                close();
                                                                this.setState({FieldAttributes:[]});
                                                                this.setState({OpenAddFilter:false});
                                                                this.setState({FieldAttributes:[{id: 0, ColumnName: 'AccountName', InputType: 'ContainTo'}]})
                                                              }}
                                                            >
                                                              Cancel
                                                            </a>
                                                            <a
                                                              id="submit"
                                                              className="btn btn-primary btn-lightgreen mr-1"
                                                              onClick={() => {
                                                                this.filterSearch();
                                                                this.closeTermsConditionModal;
                                                              }}
                                                              value="Apply"
                                                            >
                                                              Apply
                                                            </a>
                                                            <button
                                                              id="submit"
                                                              className="btn btn-primary btn-lightgreen mr-1"
                                                              onClick={() => {
                                                                this.addfilterPotentialfilter();
                                                              }}
                                                              disabled={this.state.filterBtnDisabled}
                                                              value="Save"
                                                            >
                                                              <i className="la la-save"></i>{" "}
                                                              Save
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Popup>
                                        </div>
                                        <div className="col-xl-6 allcentertext brdr-left">
                                          {/* <i className="la la-filter iconsfonts"></i> */}
                                          <img src={existFilter} className='w-42px'/>
                                        <div className='py-3 text-body'>
        <p className="description-text text-black  mb-0">Select from your existing filters for your <br/> results.</p>
        </div>
                                          {this.state.IsPopupOpen && ( 
                                              <Popup
                                                modal
                                                nested
                                                onClose={this.closePopup} 
                                              >
                                              </Popup>
                                            )}
                                            <button
                                              className="btn btn-md mt-1 btn-primary btnColor"
                                              onClick={this.handleExistingfilter}
                                            >
                                              Select from existing Filter
                                            </button>
                                      <Popup
                                        
                                        modal
                                        nested
                                        open={this.state.IsPopupOpen} 
                                        closeOnDocumentClick={false} 
                                        onClose={this.closePopup}
                                      >
                                          {/* <Popup
                                            trigger={
                                              <button className="btn btn-md btn-primary btnColor">
                                                Select from existing Filter
                                              </button>
                                            }
                                            modal
                                            nested
                                          > */}
                                            {(close) => (
                                              <div>
                                                <div className="modal-black"></div>
                                                <div className="filterPopup largerPopup1000">
                                                  <div className="paddingboxTerms">
                                                    <div className="modal-header py-4 px-3">
                                                      <div className="w-100 d-flex px-2">
                                                        <h5 className="mb-0">
                                                          Edit Filter
                                                        </h5>
                                                        <button
                                                          className="close"
                                                          onClick={()=>{this.CloseEditFilter();}}
                                                        >
                                                          <span
                                                            aria-hidden="true"
                                                            className="la la-remove"
                                                          ></span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                    <div className="px-2 mx-1">
                                                      <div className="modal-body">
                                                        <div className="col-sm-6 mb-5 px-0">
                                                          <div className="row mb-3">
                                                            <label className="col-lg-5 col-form-label text-left">
                                                              Filter Name
                                                            </label>
                                                            <div className="col-lg-7">
                                                              <select
                                                                className="form-control m-input m--hide"
                                                                id="FilterID"
                                                                name="FilterID"
                                                                onChange={() => {
                                                                  this.handleChangeFilter();
                                                                }}
                                                              >
                                                                {this.state
                                                                  .FilterName &&
                                                                  this.state.FilterName.map(
                                                                    (
                                                                      valuefilter
                                                                    ) => (
                                                                      <option
                                                                      key={valuefilter._id}
                                                                        value={
                                                                          valuefilter._id
                                                                        }
                                                                      >
                                                                        {
                                                                          valuefilter.FilterName
                                                                        }
                                                                      </option>
                                                                    )
                                                                  )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="table-bordered">
                                                          <TableContainer
                                                            component={Paper}
                                                          >
                                                            <Table
                                                              size="small"
                                                              aria-label="a dense table"
                                                            >
                                                              <TableHead>
                                                                <TableRow>
                                                                  <TableCell>
                                                                    Property{" "}
                                                                  </TableCell>
                                                                </TableRow>
                                                              </TableHead>
                                                                <TableBody>
                                                                  {this.state.EditFieldAttributes.map(
                                                                    (
                                                                      itemcount2,
                                                                      index
                                                                    ) => (
                                                                      <TableRow
                                                                        sx={{
                                                                          "&:last-child td, &:last-child th":
                                                                          {
                                                                            border: 0,
                                                                          },
                                                                        }}
                                                                      >
                                                                        <TableCell>
                                                                          <select
                                                                            className="form-control m-input"
                                                                            id={"EditColumnName"+itemcount2.id}
                                                                            onChange={()=>{this.EditColumnName(itemcount2.id)}}
                                                                            value={itemcount2.FilterPropertyName}
                                                                            // value=""
                                                                            // value="Email"
                                                                            autocomplete="off"
                                                                          >
                                                                            <option id="accountname" value="AccountName" > Account Name </option>
                                                                            <option id="company" value="Company" > Company </option>
                                                                            <option id="createddate" value="CreatedDate" > Created Date </option>
                                                                            <option id="email" value="Email" > Email </option>
                                                                            <option id="engagamentstatusid" value="Status" > Engagament Status </option>
                                                                            <option id="firstname" value="FirstName" > First Name </option>
                                                                            <option id="lastname" value="LastName" > Last Name </option>
                                                                            <option id="linkedinUrl" value="LinkedInUrl" > LinkedIn URL </option>
                                                                            <option id="MeetingSourceID" value="MeetingSourceID">  Meeting Source </option>
                                                                            <option id="MeetingOwnerID" value="MeetingOwnerID" > Owner Name </option>
                                                                            <option id="ReminderEmailAccountID" value="ReminderEmailAccountID" > Reminder Email Account </option>
                                                                            <option id="title" value="Title" > Title </option>
                                                                          </select>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                        {itemcount2.FilterPropertyName =="CreatedDate" ? (
                                                                            <select id={"Editexpression" +itemcount2.id}
                                                                              className="form-control m-input"
                                                                              value={itemcount2.ExpressionName}
                                                                              onChange={()=>{this.EditExpressValue(itemcount2.id)}}
                                                                            >
                                                                              <option id="Equals" value="Equals">Equals</option>
                                                                              <option id="Lessthan" value="Lessthan" > Less Than </option>
                                                                              <option id="LessThanEqualTo" value="LessThanEqualTo"> Less Than Equal To </option>
                                                                              <option id="greaterthan" value="GreaterThan"> Greater Than </option>
                                                                              <option id="greaterthanequalto"  value="GreaterThanEqaulTo"> Greater Than Equal To </option>
                                                                              <option id="between"  value="between"> Between </option>
                                                                            </select>
                                                                          ) : (
                                                                            <select id={"Editexpression" + itemcount2.id } 
                                                                            value={itemcount2.ExpressionName}
                                                                            onChange={()=>{this.EditExpressValue(itemcount2.id)}}
                                                                            className="form-control m-input">
                                                                              <option id="ContainTo" value="ContainTo">Contains</option>
                                                                              <option id="EqualTo" value="EqualTo">Equals</option>
                                                                            </select>
                                                                          )}
                                                                        </TableCell>

                                                                        <TableCell align="right">
                                                                        {
                                                                            itemcount2.FilterPropertyName == "CreatedDate" ? 
                                                                                itemcount2.ExpressionName == "between" ? (<>
                                                                                <div className="d-flex">
                                                                                <input type="date"  id={"Editsearchvalue" +itemcount2.id} onChange={()=>{this.EditFilterPropertyValue1(itemcount2.id)}} value={itemcount2.FilterPropertyValue1} className="form-control m-input required"/>  
                                                                                     <input type="date"  id={"EditEndsWith" +itemcount2.id} onChange={()=>{this.EditFilterPropertyValue2(itemcount2.id)}} value={itemcount2.FilterPropertyValue2} className="form-control m-input required"/>
                                                                                </div>
                                                                                   
                                                                                    </>
                                                                                ) :
                                                                                ((<input type="date"  id={"Editsearchvalue" +itemcount2.id} onChange={()=>{this.EditFilterPropertyValue1(itemcount2.id)}} value={itemcount2.FilterPropertyValue1} className="form-control m-input required"/> )):
                                                                                (<input type="text"  id={"Editsearchvalue" +itemcount2.id} onChange={()=>{this.EditFilterPropertyValue1(itemcount2.id)}} value={itemcount2.FilterPropertyValue1} className="form-control m-input required"/> )

                                                                        }
                                                                        </TableCell>
                                                                        <TableCell>

                                                                        </TableCell>

                                                                        <TableCell align="left">
                                                                          <button
                                                                            type="button"
                                                                            id={"delete" +itemcount2.id}
                                                                            className="btn btngroup"
                                                                            onClick={() => {this.handleEditRemoveClick(itemcount2.id);}}
                                                                          >
                                                                            <i className="la flaticon-delete-1"></i>
                                                                          </button>
                                                                        </TableCell>
                                                                      </TableRow>
                                                                    )
                                                                  )}
                                                                </TableBody>

                                                            </Table>
                                                          </TableContainer>
                                                        </div>
                                                        <div className="row pb-5 mb-5">
                                                          <div className="col-sm-12 text-left mb-2">
                                                            <button
                                                              type="button"
                                                              className="btn btngroup"
                                                              onClick={() => {
                                                                this.handleeditPropertyClick();
                                                              }}
                                                            >
                                                              <i className="la la-plus la-icon-font-size-13"></i>{" "}
                                                              Add Property{" "}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="border-top modal-content text-right py-4 px-4">
                                                        <div className="row px-2">
                                                          <div className="col-lg-12 pull-right">
                                                            <a
                                                              id="backtolist"
                                                              className="btn btn-secondary mr-2"
                                                              onClick={() => {
                                                                this.CloseEditFilter();
                                                              }}
                                                            >
                                                              Cancel
                                                            </a>
                                                            <a
                                                              id="submit"
                                                              className="btn btn-primary btn-lightgreen mr-1"
                                                              onClick={() => {
                                                                this.ApplyEditFilter();
                                                                this.closeTermsConditionModal;
                                                              }}
                                                            >
                                                              Apply
                                                            </a>
                                                            <button
                                                              id="submit"
                                                              className="btn btn-primary btn-lightgreen mr-1"
                                                              onClick={() => {
                                                                this.editapplyfilterSearch();
                                                              }}
                                                              disabled={this.state.filterBtnDisabled}
                                                            >
                                                              <i className="la la-save"></i>{" "}
                                                              Save
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Popup>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                         
                        </Popup>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="paddcols pb-3">
              <div className="row">
                {this.state.IsApply ==true ? <div className="col-12">
                  <a onClick={()=>{this.ResetFilter()}} className="float-right text-a reset-text">Reset</a>
                </div>:null}
               
                <div className="col">
                  <div className="">
                    <PotentialMeeting FilterParameters={this.state.FilterParameters} IsApply={this.state.IsApply} updateFromChild={this.UpdateFromChild}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCPotentialMeetingsPage = connect(
  mapState,
  actionCreators
)(CPotentialMeetingsPage);
export { connectedCPotentialMeetingsPage as CPotentialMeetingsPage };
