import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Footer from "../../_components/user/footer/footer";
import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import ClientPOC from "../../_components/clients/contactsettings/clientpoc/clientpoc";
import { history } from "../../_helpers";

class CClientPOCPage extends React.Component {
  componentDidMount() {
    window.addEventListener('storage', (event) => {
            
      if (event.key === 'clientChanged') {
        window.location.reload();
      }
    });
}
  addclientpoc() {
    history.push("/CAddClientPOC");
  }
  setOrderclientpoc() {
    history.push("/CClientPOCSetOrder");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle float-left pl-0 mb-0">Client POC</h4>
              </div> 
              <div className="col">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a
                        onClick={this.setOrderclientpoc.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 flaticon-settings"></i>
                        <span>Set Order</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.addclientpoc.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-white">
              <div className="row mx-0">
                <div className="col">
                  <ClientPOC />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCClientPOCPage = connect(
  mapState,
  actionCreators
)(CClientPOCPage);
export { connectedCClientPOCPage as CClientPOCPage };
