
import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import "react-toastify/dist/ReactToastify.css";
import StyleHeader from "../StickyHeader/StickyHeader";

toast.configure();

export default function PhoneNumberBlacklist(props) {
  const [PhoneNumberData, SetPhoneNumberData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [EditData, SetEditData] = React.useState([]);
  const [OldPhoneNumberData, SetOldPhoneNumberData] = React.useState("");

  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  // Domain tab
  const [IsExist, SetIsExist] = React.useState(false);
  const [IsValid, SetIsValid] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState("CreatedDate");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [IsPhoneNumberAdded, SetIsPhoneNumberAdded] = React.useState(props.addWhiteListed)

  useEffect(() => {
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
    }
 
      PhoneNumberGet( Details.ParentUserID);
    
  }, [Search, Page, RowsPerPage, ClientID, UserID,SortField,SortedBy,IsPhoneNumberAdded]);

  // Sync state with props.addWhiteListed but only if it changes
  useEffect(() => {
    SetIsPhoneNumberAdded(props.addWhiteListed);
  }, [props.addWhiteListed]);

  // Getting Phone number blacklist method
  const PhoneNumberGet = (UID) => {
    SetIsLoading(true)
    let RequestBody = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      UserID: UID,
    };

    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/global_phonenumberblacklist/phonenumberget",
      method: "POST",
      data: RequestBody,
    });
    ApiResponse.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        if (result.data.PageData.length > 0) {
          SetPhoneNumberData(result.data.PageData);
          SetRlen(result.data.TotalCount);
          SetFlen(result.data.TotalCount);
          SetCountPage(result.data.PageCount);
          SetIsLoading(false)
          setIsDataLoaded(true);
        }
        else {
          SetPhoneNumberData([]);
          SetRlen(0);
          SetFlen(0);
          SetCountPage(0);
          SetIsLoading(false)
        }
      }
      else {
        SetPhoneNumberData([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetIsLoading(false)
      }

    });
  };

  // For search searching phone number
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);

      }
    }
  };

   // Change Row per page
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(event.target.value);
    SetPage(1);
  };

  // Change page
  const HandleChangePage = (event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  // Sorting phone number data
  const SortData = (Field) => {
    setSortedColumn(Field)
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
    SetSortField(Field);
  };

  // Delete for domain function
  const PhoneNumberDelete = (id, PhoneNumber) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a phone number .",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsLoading(true)
        let data = {
          PhoneNumberID: id,
          OldPhoneNumber: PhoneNumber,
          LastUpdatedBy: CUserID,
          UserID:UserID

        };

        Axios({
          url: CommonConstants.MOL_APIURL + "/global_phonenumberblacklist/phonenumberdelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire("Deleted!", "Phone number deleted successfully.", "success");
              PhoneNumberGet(UserID);
              SetIsLoading(false)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  

 

  

  // Edit desing change
  const EditPhoneNumber = (PhoneNumberID) => {
    SetIsLoading(true)
    document.getElementById(PhoneNumberID + "labelbox").style.display = "none";
    document.getElementById(PhoneNumberID + "text").style.display = "block";
    document.getElementById(PhoneNumberID + "editicon1").style.display = "none";
    document.getElementById(PhoneNumberID + "saveicon1").style.display = "block";
    document.getElementById(PhoneNumberID + "editicon2").style.display = "none";
    document.getElementById(PhoneNumberID + "saveicon2").style.display = "block";

    let RequestBody = {
      PhoneNumberID: PhoneNumberID
    };
    Axios({
      url: CommonConstants.MOL_APIURL +  "/global_phonenumberblacklist/phonenumberByID",
      method: "POST",
      data: RequestBody,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById(PhoneNumberID + "text").value =res.data.Data?.PhoneNumber;
        SetOldPhoneNumberData(res.data.Data?.PhoneNumber);
        SetEditData(res.data?.data);
        SetIsLoading(false)
      } else {
        toast.error("Internal serever error");
      }
    });
  };

  // hide display
  const HideEditDomain = (PhoneNumberID) => {
    document.getElementById(PhoneNumberID + "labelbox").style.display = "block";
    document.getElementById(PhoneNumberID + "text").style.display = "none";
    document.getElementById(PhoneNumberID + "editicon1").style.display = "block";
    document.getElementById(PhoneNumberID + "saveicon1").style.display = "none";
    document.getElementById(PhoneNumberID + "editicon2").style.display = "block";
    document.getElementById(PhoneNumberID + "saveicon2").style.display = "none";
  };

  // Update domain data
  const PhoneNumberUpdate = async (id) => {
    let Final_flag = await PhoneNumberValidation(id);
    let PhoneNumber = document.getElementById(id + "text").value;
    if (Final_flag == true) {
      SetIsLoading(true)
      let objPhoneNumberData = {
        PhoneNumberID: id,
        PhoneNumber: PhoneNumber,
        LastUpdatedBy: CUserID,
        OldPhoneNumber: OldPhoneNumberData,
        UserID: UserID
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/global_phonenumberblacklist/phonenumberupdate",
        method: "POST",
        data: objPhoneNumberData,
      }).then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          toast.success(<div>Phone Number<br />Phone number updated successfully.</div>);
          HideEditDomain(id);
          PhoneNumberGet(UserID);
          SetIsLoading(false)
        } else {
          toast.error("Internal server Error");
          SetIsLoading(false)
        }
      });
    }
  };

  // formvalidation for white tab
  const PhoneNumberValidation = async (id) => {

    let formIsValid = true;
    let phonenumber = document.getElementById(id + "text").value;
    let IsExist = await PhoneNumberCheckExist(phonenumber);


    if (phonenumber?.trim() == "") {
      formIsValid = false;
      toast.error("Please enter phone number");
      SetIsLoading(false)
    }else if(!/^[0-9]+$/.test(phonenumber?.trim())){
      formIsValid = false;
      toast.error("Invalid phone number");
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    return formIsValid;
  };


  // cheack exists for contact email tab
  const PhoneNumberCheckExist = async (phonenumber) => {
    let RequestBody = {
      UserID: UserID,
      PhoneNumber: phonenumber,

    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/global_phonenumberblacklist/phonenumberCheackExists",
      method: "POST",
      data: RequestBody,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.PhoneNumberData.length > 0) {
        if (OldPhoneNumberData == phonenumber) {
          SetIsExist(false);
          return false;
        } else {
          toast.error("Phone number is already exists!");
          SetIsExist(true);
          SetIsLoading(false)
          return true;
        }
      } else {
        SetIsExist(false);
        SetIsLoading(false)
        return false;
      }
    }
  };

  

  

  return (
    <div>
      {IsLoading == true ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>}
      <div className='px-4 mx-2'>
        <div className="row padt-25">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                className="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value} key={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:{" "}
              <input
                type="search"
                id="search"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <StyleHeader isDataLoaded={isDataLoaded} />
            <Table className="table-ref" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => { SortData("PhoneNumber"); }}>
                    {" "}
                    Phone Number
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "PhoneNumber" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "PhoneNumber" ? "active" : null} />
                      </span>
                  </TableCell>
                  <TableCell onClick={() => { SortData("CreatedDate"); }}>
                    {" "}
                    Created Date
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "CreatedDate" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "CreatedDate" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PhoneNumberData.length === 0 ? (<p className="text-center">No data available in table</p>) : (
                  PhoneNumberData.map((row) => (
                    <TableRow key={row._id}>
                       <TableCell>
                      <label
                        id={row?._id + "labelbox"}
                      >
                        {row.PhoneNumber}{" "}
                      </label>{" "}
                      <input
                        type="textbox"
                        id={row?._id + "text"}
                        className="form-control m-input"
                        style={{ display: "none" }}
                        value={Fields["PhoneNumber"]}
                        autocomplete="off"
                      />
                    </TableCell>

                      {/* <TableCell>{row.PhoneNumber}</TableCell> */}
                      <TableCell> {moment(new Date(row.CreatedDate).toDateString()).format("MM/DD/YYYY")}</TableCell>
                      <TableCell className="px-0 d-flex">
                        <a onClick={() => { EditPhoneNumber(row?._id); }} id={row?._id + "editicon1"} className="btn-eyesicon" title="Edit" > <i className="la flaticon-edit-1 edit-icon"></i></a>
                        <a onClick={() => { PhoneNumberDelete(row._id, row.PhoneNumber); }} id={row._id + "editicon2"} className="btn-eyesicon" title="Delete"><i className="la flaticon-delete-1 delete-icon"></i></a>
                        <a onClick={() => { PhoneNumberUpdate(row?._id); }} style={{ display: "none" }} id={row?._id + "saveicon1"} className="btn-eyesicon" title="Save" > <i className="la la-save delete-icon"></i> </a>
                        <a onClick={() => { HideEditDomain(row?._id); }} style={{ display: "none" }} id={row?._id + "saveicon2"} className="btn-eyesicon" title="Cancel"><i className="la flaticon-cancel delete-icon"></i></a>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        
          <div className="row pb-2">
            <div className="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                page={Page}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
      </div>
    </div>
  );
}



