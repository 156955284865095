import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-datetime-picker";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";

import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import $ from 'jquery';
import 'bootstrap-datetime-picker/js/bootstrap-datetimepicker.js'; // Import datetime picker JS
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.css'; // Import datetime picker CSS
import Axios from "axios";
import { toast } from "react-toastify";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Alert from "@mui/material/Alert";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const moment = require("moment");

class CallDialAddBookedMeetingPage extends Component {
  // Constructor and other methods if needed...
  constructor(props) {
    let Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      statusAvailable: null,
      meetingstatus: [],
      meetingsource: [],
      meetingowner: [],
      meetingtimezone: [],
      meetingaccount: [],
      meetingseniority: [],
      meetingsetbyResponder: [],
      meetingsetbyColdCallers: [],
      checked: true,
      StatusName: "Set",
      IsExist: false,
      meetingCustomField: [],
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      datevalue: null,
      FileRecording: null,
      FileName: "",
      PotentialMeetingID: "",
      PotentialMeetingIDHideAndShowAlert: "",
      OpenPotentialAlert: false,
      EmailAvailable: null,
      Role: null,
      MeetingSourceData: "",
      MeetingOwnerData: "",
      MeetingSetByData: "",
      MeetingReminderData: "",
      IsButtonDisabled: false,
      ContractVal: 0,
      IsValid: false,
      IsWebsite: true,
      WebsiteNotNull: false,
      IsInValidWebsite: false,
      IsValidEmail: false,
      RecordingAudioFile: null,
      IsLoading: true,
      PMConvetNotes: [],
      IsContactLimintation: false,
      RecordingAudioPath: null,
      attempts: 0,
      isSuccess: false,
    };
    this.intervalId = null;
    this.pikerdatevalue = this.pikerdatevalue.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.handleChangeSinglePost = this.handleChangeSinglePost.bind(this);
    this.OnChangeUpload = this.OnChangeUpload.bind(this);
    this.CloseAlertOnClick = this.CloseAlertOnClick.bind(this);
    this.ContractValHandle = this.ContractValHandle.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
    this.HandleChangedropdownBlur = this.HandleChangedropdownBlur.bind(this);
    this.HandleChangeotherFieldBlur =
      this.HandleChangeotherFieldBlur.bind(this);
    this.handleChangeWebsitecheck = this.handleChangeWebsitecheck.bind(this);

    // Create refs for input fields
    this.daterefect = React.createRef();
    this.dateevtset = React.createRef();
    //  this.datetimepickerRef = React.createRef();
    //  this.eventCreatedDtRef = React.createRef();

    this.contractValueRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.titleRef = React.createRef();
    this.companyRef = React.createRef();
    this.linkedInURLRef = React.createRef();
    this.directPhoneRef = React.createRef();
    this.directPhoneExtRef = React.createRef();
    this.companyPhoneRef = React.createRef();
    this.statusRef = React.createRef();
    this.meetingSourceIDRef = React.createRef();
    this.meetingOwnerIDRef = React.createRef();
    this.reminderEmailAccountRef = React.createRef();
    this.meetingSetByIDRef = React.createRef();
    this.timeZoneIDRef = React.createRef();
    this.industryRef = React.createRef();
    this.seniorityIDRef = React.createRef();
    this.externalAccountIDRef = React.createRef();
    this.externalContactIDRef = React.createRef();
    this.websiteRef = React.createRef();
    this.externalField1Ref = React.createRef();
    this.externalField2Ref = React.createRef();
    this.externalField3Ref = React.createRef();
    this.modalRef = React.createRef();
  }
  componentDidMount() {
    // document.title = `Add Booked Meetings | SalesHive`;
    this.GetBookedmeeting();
    this.fetchCallRecording();
    // document.getElementById("EventCreatedDt").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

    const $datepickerElement = $(this.daterefect.current); 

    $datepickerElement.datetimepicker({
        format: "mm/dd/yyyy HH:ii P",
        autoclose: true,
        container: $(this.modalRef.current),
        todayBtn: true,
        todayHighlight: true,
        showMeridian: true,
        minuteStep: 5,
      });

      const $dateevtsetElement = $(this.dateevtset.current);
      $dateevtsetElement.datetimepicker({
        format: "mm/dd/yyyy",
        autoclose: true,
        container: $(this.modalRef.current),
        todayBtn: true,
        todayHighlight: true,
        minView: "2",
      }); 
  }
  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Cleanup interval on component unmount
    }
  }

  fetchCallRecording = async () => {
    this.intervalId = setInterval(async () => {
      const { attempts, isSuccess } = this.state;

      if (attempts >= 4 || isSuccess) {
        clearInterval(this.intervalId); // Stop further requests
        return;
      }

      try {
        let Details = GetUserDetails();
        let RequestBody = {
          ClientID: Details.ClientID,
          Role:"ColdCallers",
          CallSid:this.props.CallSid
        }
        const response = await Axios({url: CommonConstants.MOL_APIURL +"/twiliocalling/FetchingCallRecordingForBookedMeeting",method:"POST",data:RequestBody});

        if (response.data.StatusMessage === "SUCCESS") {
          console.log("Call recording => ",response.data.Data?.RecordingFileName)
          this.setState({RecordingAudioPath:response.data.Data?.RecordingFileName ?? ""})
          this.setState({ isSuccess: true });
          clearInterval(this.intervalId); // Stop further requests
        }
      } catch (error) {
        toast.error("Error fetching call recording.");
      }

      this.setState((prevState) => ({ attempts: prevState.attempts + 1 }));
    }, 6000); // Request every 5 seconds
  };

  //get book meeting
  GetBookedmeeting() {
    let Details = GetUserDetails();

    let str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
    };

    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/FindMeetingAllStatus",
      method: "POST",
      data: str_in,
    });
    rows
      .then((result) => {
        //
        let RArr = result.data?.meetingsetby[0]?.ResponderDetails || [];
        let CArr = result.data?.meetingsetby[0]?.ColdCallersDetails || [];
        RArr.forEach((RArr1) => {
          RArr1.value = "R" + RArr1.value;
        });
        CArr.forEach((CArr1) => {
          CArr1.value = "C" + CArr1.value;
        });
        const combinedArray = [...RArr, ...CArr];
        combinedArray.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({
          meetingstatus: result.data?.meetingstatus,
          meetingsource: result.data?.meetingsource,
          meetingowner: result.data?.meetingowner,
          meetingtimezone: result.data?.meetingtimezone,
          meetingaccount: result.data?.meetingaccount,
          meetingseniority: result.data?.meetingseniority,
          meetingsetbyResponder: combinedArray,
          meetingsetbyColdCallers:
            result.data?.meetingsetby[0]?.ColdCallersDetails,
          // meetingCustomField: result.data?.meetingCustomField,
        });
        this.setState({ IsLoading: false });
      })
      .catch((error) => {
        // toast.error("An error occurred saving. Please try again.");
        this.setState({ IsLoading: false });
      });
  }

  async FromValidation() {
    try {
      let formIsValid = true;
      this.setState({ IsValid: false });
      let fields = this.state.fields;

      // Accessing values through refs
      let FirstName = this.firstNameRef.current.value.trim(); // Use ref
      let LastName = this.lastNameRef.current.value.trim(); // Use ref
      let Title = this.titleRef.current.value.trim(); // Use ref
      let Company = this.companyRef.current.value.trim(); // Use ref
      let Email = this.emailRef.current.value.trim(); // Use ref
      let Website = this.websiteRef.current.value.trim(); // Use ref
      let TimeZoneID = this.timeZoneIDRef.current.value; // Use ref
      let DirectPhoneCurrent = this.directPhoneRef.current.value.trim(); // Use ref
      let DirectPhoneExtCurrent = this.directPhoneExtRef.current.value.trim(); // Use ref
      let CompanyPhoneCurrent = this.companyPhoneRef.current.value.trim(); // Use ref
      let UTCMeetingDate = this.daterefect.current.value; // Use ref
      let MeetingDate = this.dateevtset.current.value; // Use ref

      let IsExistEmail = await this.CheckExistBookedMeetingName(Email);
      let IsExistWebsite = await this.CheckWebSite(Website);
      let errors = {};

      // Phone number validations
      if (isNaN(DirectPhoneCurrent)) {
        errors["DirectPhone"] = "Invalid direct phone";
      } else {
        errors["DirectPhone"] = null;
      }

      if (isNaN(DirectPhoneExtCurrent)) {
        errors["DirectPhoneExt"] = "Invalid direct phone ext";
      } else {
        errors["DirectPhoneExt"] = null;
      }

      if (isNaN(CompanyPhoneCurrent)) {
        errors["CompanyPhone"] = "Invalid company phone";
      } else {
        errors["CompanyPhone"] = null;
      }

      // Field validations
      if (FirstName === "") {
        formIsValid = false;
        errors["FirstName"] = "Please enter first name";
      }
      if (LastName === "") {
        formIsValid = false;
        errors["LastName"] = "Please enter last name";
      }
      if (!UTCMeetingDate) {
        formIsValid = false;
        errors["datevalue"] = "Please enter Meeting Date";
      }
      if (!MeetingDate) {
        formIsValid = false;
        errors["EventCreatedDt"] = "Please enter Meeting Set Date";
      }
      if (Title === "") {
        formIsValid = false;
        errors["Title"] = "Please enter Title";
      }
      if (Company === "") {
        formIsValid = false;
        errors["Company"] = "Please enter Company";
      }

      // Website validation
      if (IsExistWebsite === "InvalidWebsite") {
        formIsValid = false;
        errors["Website"] = "Invalid website!";
      }
      if (IsExistWebsite === "BlankDataWebsite" && Website === "") {
        formIsValid = false;
        errors["Website"] = "Please enter Website";
      }

      // Email validation
      if (IsExistEmail === "InvalidEmail") {
        formIsValid = false;
        errors["Email"] = "invalid email.";
      }
      if (IsExistEmail === "ExistEmail") {
        formIsValid = false;
        errors["Email"] = "Email Already Exist";
      }
      if (IsExistEmail === "BlankData" && Email === "") {
        formIsValid = false;
        errors["Email"] = "Please enter email";
      }

      // Additional fields validation
      let MeetingSourceID = this.meetingSourceIDRef.current.value; // Use ref
      let MeetingOwnerID = this.meetingOwnerIDRef.current.value; // Use ref
      let ReminderEmailAccount = this.reminderEmailAccountRef.current.value; // Use ref

      if (MeetingSourceID === "") {
        formIsValid = false;
        errors["MeetingSourceID"] = "Please select Meeting Source";
      }

      if (ReminderEmailAccount === "") {
        formIsValid = false;
        errors["ClientPOCID"] = "Please select reminder email account";
      }
      if (MeetingOwnerID === "") {
        formIsValid = false;
        errors["MeetingOwnerID"] = "Please select owner";
      }

      if (TimeZoneID === "") {
        formIsValid = false;
        errors["TimeZoneID"] = "Please select Time Zone";
      }

      this.setState({ errors: errors });
      return formIsValid;
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Email != "") {
      this.state.errors.Email = null;
      this.state.EmailAvailable = null;
      this.CheckExistBookedMeetingName(e.target.value);
    } else {
      this.state.EmailAvailable = null;
      this.state.errors.Email = null;
    }
  }

  async CheckWebSite(Email) {
    let regexp = new RegExp(
      "^(?!(https?://)?www\\.)" + // Ensure it does not start with https://www.
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    if (Email != "") {
      if (!regexp.test(Email)) {
        return "InvalidWebsite";
      } else {
        return "AllDoneWebsite";
      }
    } else {
      return "BlankDataWebsite";
    }
  }

  async handleChangeWebsitecheck() {
    try {
      let errors = { ...this.state.errors };
      // Access the website value through refs
      let Website = this.websiteRef.current.value.trim(); // Use ref

      if (Website === "") {
        errors["Website"] = "Please enter Website";
      } else {
        let IsExist = await this.CheckWebSite(Website);
        if (IsExist === "InvalidWebsite") {
          errors["Website"] = "Invalid website!";
        }
        if (IsExist === "AllDoneWebsite") {
          errors["Website"] = null;
        }
      }

      this.setState({ errors: errors });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  async HandleChangedropdownBlur(Name) {
    try {
      let errors = { ...this.state.errors };

      // Access the values through refs
      let MeetingSourceID = this.meetingSourceIDRef.current.value; // Use ref
      let MeetingOwnerID = this.meetingOwnerIDRef.current.value; // Use ref
      let ReminderEmailAccount = this.reminderEmailAccountRef.current.value; // Use ref
      let TimeZoneID = this.timeZoneIDRef.current.value; // Use ref

      if (Name === "MeetingSource") {
        if (MeetingSourceID === "") {
          errors["MeetingSourceID"] = "Please select Meeting Source";
        } else {
          errors["MeetingSourceID"] = null;
        }
      }

      if (Name === "ReminderEmailAccount") {
        if (ReminderEmailAccount === "") {
          errors["ClientPOCID"] = "Please select reminder email account";
        } else {
          errors["ClientPOCID"] = null;
        }
      }

      if (Name === "MeetingOwner") {
        if (MeetingOwnerID === "") {
          errors["MeetingOwnerID"] = "Please select owner";
        } else {
          errors["MeetingOwnerID"] = null;
        }
      }

      if (Name === "TimeZoneID") {
        if (TimeZoneID === "") {
          errors["TimeZoneID"] = "Please select Time Zone";
        } else {
          errors["TimeZoneID"] = null;
        }
      }

      this.setState({ errors: errors });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  async HandleChangeotherFieldBlur(Name) {
    try {
      let errors = { ...this.state.errors };

      // Access the values through refs
      let FirstName = this.firstNameRef.current.value.trim(); // Use ref
      let LastName = this.lastNameRef.current.value.trim(); // Use ref
      let Title = this.titleRef.current.value.trim(); // Use ref
      let Company = this.companyRef.current.value.trim(); // Use ref
      let DirectPhoneCurrent = this.directPhoneRef.current.value.trim(); // Use ref
      let DirectPhoneExtCurrent = this.directPhoneExtRef.current.value.trim(); // Use ref
      let CompanyPhoneCurrent = this.companyPhoneRef.current.value.trim(); // Use ref

      if (Name === "DirectPhone") {
        if (isNaN(DirectPhoneCurrent)) {
          errors["DirectPhone"] = "Invalid direct phone";
        } else {
          errors["DirectPhone"] = null;
        }
      }

      if (Name === "DirectPhoneExt") {
        if (isNaN(DirectPhoneExtCurrent)) {
          errors["DirectPhoneExt"] = "Invalid direct phone ext";
        } else {
          errors["DirectPhoneExt"] = null;
        }
      }

      if (Name === "CompanyPhone") {
        if (isNaN(CompanyPhoneCurrent)) {
          errors["CompanyPhone"] = "Invalid company phone";
        } else {
          errors["CompanyPhone"] = null;
        }
      }

      if (Name === "FirstName") {
        if (FirstName === "") {
          errors["FirstName"] = "Please enter first name";
        } else {
          errors["FirstName"] = null;
        }
      }

      if (Name === "LastName") {
        if (LastName === "") {
          errors["LastName"] = "Please enter last name";
        } else {
          errors["LastName"] = null;
        }
      }

      if (Name === "Title") {
        if (Title === "") {
          errors["Title"] = "Please enter Title";
        } else {
          errors["Title"] = null;
        }
      }

      if (Name === "Company") {
        if (Company === "") {
          errors["Company"] = "Please enter Company";
        } else {
          errors["Company"] = null;
        }
      }

      this.setState({ errors: errors });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  // check exit Email
  async HandleChangeBlur() {
    try {
      let errors = { ...this.state.errors };
      let Email = this.emailRef.current.value.trim(); // Use ref

      if (Email === "") {
        errors["Email"] = "Please enter Email";
      } else {
        let IsExist = await this.CheckExistBookedMeetingName(Email);
        if (IsExist === "InvalidEmail") {
          errors["Email"] = "Invalid email.";
        }
        if (IsExist === "ExistEmail") {
          errors["Email"] = "Email Already Exists";
        }
        if (IsExist === "AllDone") {
          errors["Email"] = null;
        }
      }

      this.setState({ errors: errors });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  async CheckExistBookedMeetingName(Email) {
    try {
      let regexp =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (Email != "") {
        if (!regexp.test(Email)) {
          return "InvalidEmail";
        } else {
          // this.setState({ IsExist: false });
          let str_in;

          str_in = {
            ClientID: this.state.ClientID,
            Email: Email,
            Role: this.state.Role,
            ConvertMeeting: false,
          };

          let resdata = await Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/bookedmeeting/BookedMeetingCheckExists",
            method: "POST",
            data: str_in,
          });
          if (resdata.data.StatusMessage == "SUCCESS") {
            if (resdata.data.Data.length > 0) {
              this.setState({ EmailAvailable: resdata.data.Data[0]?.Email });
              return "ExistEmail";
            } else {
              return "AllDone";
            }
          }
        }
      } else {
        return "BlankData";
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  async savebtn(e, close, PerformNextActionAfterMeeting,  DisconnectCall) {
    try {
      this.state.errors.Email = null;
      this.state.EmailAvailable = null;
      this.setState({ IsButtonDisabled: true });

      let Final_flag = await this.FromValidation();
      let ContractValue = this.contractValueRef.current.value; // Use ref
      if (Final_flag === true) {
        this.setState({ IsLoading: true });
        let UTCMeetingDate = this.daterefect.current.value; // Use ref
        let FirstName = this.firstNameRef.current.value; // Use ref
        let LastName = this.lastNameRef.current.value; // Use ref
        let Email = this.emailRef.current.value.trim(); // Use ref
        let Title = this.titleRef.current.value; // Use ref
        let Company = this.companyRef.current.value; // Use ref
        let LinkedInURL = this.linkedInURLRef.current.value; // Use ref
        let DirectPhone = this.directPhoneRef.current.value; // Use ref
        let DirectPhoneExt = this.directPhoneExtRef.current.value; // Use ref
        let CompanyPhone = this.companyPhoneRef.current.value; // Use ref
        let Status = this.statusRef.current.value; // Use ref

        let MeetingSourceID = this.meetingSourceIDRef.current.value; // Use ref
        let MeetingOwnerID = this.meetingOwnerIDRef.current.value; // Use ref
        let ReminderEmailAccount = this.reminderEmailAccountRef.current.value; // Use ref
        let MeetingSetbyID = this.meetingSetByIDRef.current.value; // Use ref
        let EventCreatedDt = this.dateevtset.current.value; // Use ref
        let TimeZoneID = this.timeZoneIDRef.current.value; // Use ref

        let Industry = this.industryRef.current.value; // Use ref
        let SeniorityID = this.seniorityIDRef.current.value; // Use ref
        let ExternalAccountIDs = this.externalAccountIDRef.current.value; // Use ref
        let ExternalContactIDs = this.externalContactIDRef.current.value; // Use ref
        let Website = this.websiteRef.current.value; // Use ref
        let ExternalField1 = this.externalField1Ref.current.value; // Use ref
        let ExternalField2 = this.externalField2Ref.current.value; // Use ref
        let ExternalField3 = this.externalField3Ref.current.value; // Use ref

        let TZdata = {
          TimeZoneID: TimeZoneID,
        };

        let TZdetails = await Axios({
          url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetTimezoneMeeting",
          method: "POST",
          data: TZdata,
        });

        let FieldID = null;
        let FieldName = "";

        if (TZdetails.data.length > 0) {
          FieldID = TZdetails.data[0].FieldID;
          FieldName = TZdetails.data[0].FieldName;
        }

        let BookedMeetingCustomFieldValueArray = [];
        if (this.state.meetingCustomField.length > 0) {
          this.state.meetingCustomField.map((value) => {
            let customval = document.getElementById(value._id)?.value;
            let customvalid = value._id;
            if (customval.length !== 0) {
              BookedMeetingCustomFieldValueArray.push({
                customval,
                customvalid,
              });
            }
          });
        }

        let MeetingData = {
          FirstName: FirstName,
          LastName: LastName,
          Title: Title,
          Company: Company,
          Email: Email,
          LinkedInUrl: LinkedInURL,
          DirectPhone: DirectPhone,
          DirectPhoneExt: DirectPhoneExt,
          CompanyPhone: CompanyPhone,
          Website: Website,
          MeetingStatus: this.statusRef.current.options[this.statusRef.current.selectedIndex].textContent,
          MeetingSource: this.meetingSourceIDRef.current.options[this.meetingSourceIDRef.current.selectedIndex].textContent,
          EventStartDt: UTCMeetingDate,
          MeetingSetBy:this.meetingSetByIDRef.current.options[this.meetingSetByIDRef.current.selectedIndex].textContent,
          EventCreatedDt: EventCreatedDt,
          TimeZone: this.timeZoneIDRef.current.options[this.timeZoneIDRef.current.selectedIndex].textContent,
          MeetingOwner: this.meetingOwnerIDRef.current.options[this.meetingOwnerIDRef.current.selectedIndex].textContent,
          ReminderEmailAccount: this.reminderEmailAccountRef.current.options[this.reminderEmailAccountRef.current.selectedIndex].textContent,
          ContractValue: ContractValue,
          Industry: Industry,
          ContactCategory: "",
          AccountCategory: "",
          CompanyNameLong: "",
          Address1: "",
          Address2: "",
          City: "",
          State: "",
          Country: "",
          Zip: "",
          MeetingID: "",
        };

        // Audio Upload
        const dataAudio = new FormData();
        dataAudio.append("soundFile", this.state.RecordingAudioFile);

        Axios({
          url: CommonConstants.MOL_APIURL + "/bookedmeeting/UploadMeetingMp3",
          method: "POST",
          data: dataAudio,
        })
          .then((resp) => {
            let MeetingSetParameter = {
              FirstName: FirstName,
              LastName: LastName,
              Email: Email,
              Title: Title,
              Company: Company,
              Website: Website,
              LinkedInUrl: LinkedInURL,
              DirectPhone: DirectPhone,
              DirectPhoneExt: DirectPhoneExt,
              CompanyPhone: CompanyPhone,
              Status: this.state.StatusName,
              StatusID: Status,
              MeetingSourceID: MeetingSourceID,
              MeetingOwnerID: MeetingOwnerID,
              TimeZoneID: TimeZoneID,
              ContractValue: this.state.ContractVal,
              Industry: Industry,
              ResponderColdCallersType: MeetingSetbyID?.charAt(0),
              ResponderColdCallersID: MeetingSetbyID,
              IsReminderMailSent: this.state.checked,
              RecordingAudioPath: this.state.FileName,
              ReminderEmailAccountID: ReminderEmailAccount,
              SeniorityID: SeniorityID,
              EventStartDtByTimeZone: UTCMeetingDate,
              EventStartDt: UTCMeetingDate,
              EventCreatedDt: EventCreatedDt,
              Role: this.state.Role,
              ExternalField1: ExternalField1,
              ExternalField2: ExternalField2,
              ExternalField3: ExternalField3,
              IsDeleted: false,
              ClientID: this.state.ClientID,
              UserID: this.state.UserID,
              CreatedBy: this.state.CUserID,
              CreatedDate: new Date(),
              LastUpdatedBy: null,
              LastUpdatedDate: null,
              CustomMeetingDetails: BookedMeetingCustomFieldValueArray,
              FieldID: FieldID,
              FieldName: FieldName,
              ExternalAccountID: ExternalAccountIDs,
              ExternalContactID: ExternalContactIDs,
              MeetingData: MeetingData,
              PotentialNotes: this.state.PMConvetNotes || [],
              RecordingAudioPath: resp.data.message === "success" ? resp.data.ImageName : null,
              ProspectMeetingID: this.props.ID || "000000000000000000000000",
              CallSid: this.props.CallSid
            };
            Axios({
              url:
                CommonConstants.MOL_APIURL + "/bookedmeeting/BookedMeetingAddFromDialer",
              method: "POST",
              data: MeetingSetParameter,
            })
              .then((res) => {
                if (res.data.StatusMessage === "SUCCESS") {
                  toast.success(
                    <div>
                      Add Meeting
                      <br />
                      {Title} Meeting added successfully!
                    </div>
                  );
                  // Optionally reset the form or navigate away

                  close();
                  PerformNextActionAfterMeeting();
                  DisconnectCall();
                  this.setState({ IsButtonDisabled: false, IsLoading: false });
                } else {
                  toast.error(res.data.Message);
                  this.setState({ IsButtonDisabled: false, IsLoading: false });
                }
              })
              .catch((error) => {
                toast.error(
                  "An error occurred while updating. Please try again."
                );
                this.setState({ IsButtonDisabled: false, IsLoading: false });
              });
          })
          .catch((error) => {
            this.setState({ IsButtonDisabled: false, IsLoading: false });
          });
      } else {
        this.setState({ IsButtonDisabled: false, IsLoading: false });
      }
    } catch (error) {
      toast.error("An error occurred while saving. Please try again.");
      this.setState({ IsLoading: false, BtnDisabledUpdate: true });
    }
  }

  pikerdatevalue(newvalue) {
    this.setState({ datevalue: newvalue });
  }

  handleChangecheck = (e) => {
    const { checked } = e.target;
    this.setState({
      checked: checked,
    });
  };

  handleChangelist = (e) => {
    let str_in = {
      MeetingStatusID: e.target.value,
      ClientID: this.state.ClientID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingStatusName",
      method: "POST",
      data: str_in,
    })
      .then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          //
          this.setState({ StatusName: res.data.data[0]?.Status });
        } else {
          toast.error(res.data.Message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while mp3 saving. Please try again.");
        this.setState({ IsLoading: false });
      });
  };

  OnChangeUpload(e) {
    this.setState({ RecordingAudioFile: e.target.files[0] });
  }

  handleChangeSinglePost(value, id) {}

  CloseAlertOnClick() {
    this.setState({ OpenPotentialAlert: false });
    this.setState({ PotentialMeetingIDHideAndShowAlert: "" });
  }
  //default change the state
  handleDropdownChangeMeetingSource = (event) => {
    const selectedValue = event.target.value;
    this.setState({ MeetingSourceData: selectedValue });
  };

  handleDropdownChangeMeetingOwne = (event) => {
    const selectedValue = event.target.value;
    this.setState({ MeetingOwnerData: selectedValue });
  };

  handleDropdownChangeMeetingSetBy = (event) => {
    const selectedValue = event.target.value;
    this.setState({ MeetingSetByData: selectedValue });
  };

  handleDropdownChangeMeetingReminder = (event) => {
    const selectedValue = event.target.value;
    this.setState({ MeetingReminderData: selectedValue });
  };

  ContractValHandle = (e) => {
    this.setState({ ContractVal: e.target.value });
  };

  render() {
     const { CloseBookMeetingModel, PerformNextActionAfterMeeting,  DisconnectCall} = this.props;
    return (
      <div>
        {/* Modal Popup Trigger */}
        {/* <Popup trigger={<button>Model</button>} modal nested>
          {(close) => ( */}
            <div>
              <div className="modal-black"></div>
              <div className="filterPopup largerPopup position-rel" ref={this.modalRef}>
                <div className="paddingboxTerms">
                  <div className="px-3">
                    <div className="w-100 d-flex model-header">
                      <h5 className="mb-0">Add Meeting</h5>
                      <button className="close" onClick={CloseBookMeetingModel}>
                        <CloseIcon />
                      </button> 
                    </div>
                  </div>

                  <div className="modal-body px-3 pb-3">
                    {this.state.IsLoading ? (
                      <div id="hideloding" className="loding-display">
                        <img src={loadingicon} />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="py-3 bg-white mb-3">
                      <div className="row borderbtnm py-3 mx-0">
                        <div className="col padd-0">
                          <h3 className=" float-left xs-headertitle addpotential_header pl-0">
                            Contact Info
                          </h3>
                        </div>
                      </div>

                      <div className="row pt-1 mx-0">
                        <div className="col-lg-6 boxsinput_group px-3">
                          <div className="row max-cols  d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              First Name
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-required="Please enter first name"
                                id="FirstName"
                                ref={this.firstNameRef}
                                name="FirstName"
                                type="text"
                                defaultValue={this.props.FirstName ?? ""}
                                onBlur={() =>
                                  this.HandleChangeotherFieldBlur("FirstName")
                                }
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["FirstName"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Last Name
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                id="LastName"
                                ref={this.lastNameRef}
                                name="LastName"
                                defaultValue={this.props.LastName ?? ""}
                                type="text"
                                onBlur={() =>
                                  this.HandleChangeotherFieldBlur("LastName")
                                }
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["LastName"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group px-3">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Title
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                id="Title"
                                ref={this.titleRef}
                                name="Title"
                                defaultValue={this.props.Title ?? ""}
                                type="text"
                                onBlur={() =>
                                  this.HandleChangeotherFieldBlur("Title")
                                }
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["Title"]}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Company
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                id="Company"
                                name="Company"
                                ref={this.companyRef}
                                defaultValue={this.props.Company ?? ""}
                                type="text"
                                onBlur={() =>
                                  this.HandleChangeotherFieldBlur("Company")
                                }
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["Company"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group px-3">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Email
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-regex="Invalid email."
                                data-val-remote="Email already exist."
                                data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                                data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                                data-val-required="Please enter email."
                                id="Email"
                                ref={this.emailRef}
                                defaultValue={this.props.Email ?? ""}
                                name="Email"
                                type="text"
                                onBlur={this.HandleChangeBlur}
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["Email"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              LinkedIn URL
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                id="LinkedInURL"
                                ref={this.linkedInURLRef}
                                defaultValue={this.props.LinkedInURL ?? ""}
                                name="LinkedInURL"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group px-3">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Direct Phone
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-regex="Invalid direct phone"
                                data-val-regex-pattern="^[0-9]*$"
                                id="DirectPhone"
                                name="DirectPhone"
                                ref={this.directPhoneRef}
                                defaultValue={this.props.DirectPhone ?? ""}
                                type="text"
                                onBlur={() =>
                                  this.HandleChangeotherFieldBlur("DirectPhone")
                                }
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["DirectPhone"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Direct Phone Ext
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-regex="Invalid direct phone ext"
                                data-val-regex-pattern="^[0-9]*$"
                                id="DirectPhoneExt"
                                name="DirectPhoneExt"
                                ref={this.directPhoneExtRef}
                                defaultValue={this.props.DirectPhoneExt ?? ""}
                                type="text"
                                onBlur={() =>
                                  this.HandleChangeotherFieldBlur(
                                    "DirectPhoneExt"
                                  )
                                }
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["DirectPhoneExt"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group px-3">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Company Phone
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-regex="Invalid company phone"
                                data-val-regex-pattern="^[0-9]*$"
                                id="CompanyPhone"
                                ref={this.companyPhoneRef}
                                defaultValue={this.props.CompanyPhone ?? ""}
                                name="CompanyPhone"
                                type="text"
                                onBlur={() =>
                                  this.HandleChangeotherFieldBlur(
                                    "CompanyPhone"
                                  )
                                }
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["CompanyPhone"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Website
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-regex="Invalid company phone"
                                data-val-regex-pattern="^[0-9]*$"
                                id="Website"
                                ref={this.websiteRef}
                                defaultValue={this.props.Website ?? ""}
                                name="Website"
                                type="text"
                                onBlur={this.handleChangeWebsitecheck}
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["Website"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-3 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4  view-lable-meet potential_text">
                              Industry
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-required="Please enter Industry "
                                id="Industry"
                                ref={this.industryRef}
                                defaultValue={this.props.Industry ?? ""}
                                name="Industry"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row borderbtnm border-top border-bottom mt-2 px-0 pt-4 pb-3 mx-0">
                        <div className="col padd-0">
                          <h3 className=" float-left xs-headertitle addpotential_header">
                            Meeting Info
                          </h3>
                        </div>
                      </div>

                      <div className="row px-1 pt-1 mx-0">
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Meeting Status
                            </label>
                            <div className="col-lg-7">
                              <select
                                className="form-control  m-input"
                                data-val="true"
                                data-val-number="The field Status must be a number."
                                id="Status"
                                ref={this.statusRef}
                                name="Status"
                                onChange={(e) => this.handleChangelist(e)}
                              >
                                {/* <option value="">--Select--</option> */}
                                {this.state.meetingstatus?.map((value) => (
                                  <option value={value?._id}>
                                    {value?.Status}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Meeting Source
                            </label>
                            <div className="col-lg-7">
                              <select
                                className="form-control  m-input"
                                data-val="true"
                                data-val-number="The field ContactSourceID must be a number."
                                id="MeetingSourceID"
                                ref={this.meetingSourceIDRef}
                                name="MeetingSourceID"
                                onChange={() =>
                                  this.HandleChangedropdownBlur("MeetingSource")
                                }
                              >
                                <option value="">--Select--</option>
                                {this.state.meetingsource.map((value) => (
                                  <option value={value?._id}>
                                    {value?.Name}
                                  </option>
                                ))}
                              </select>
                              <span style={{ color: "red" }}>
                                {this.state.errors["MeetingSourceID"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Meeting Set Date
                            </label>
                            <div className="col-lg-7 timedatepikker date-input">
                              <input
                                className="form-control m-input"
                                type="text"
                                id="EventCreatedDt"
                                ref={this.dateevtset}
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["EventCreatedDt"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Meeting Set By
                            </label>
                            <div className="col-lg-7">
                              <select
                                className="form-control  m-input"
                                data-val="true"
                                data-val-number="The field SalesRepID must be a number."
                                id="MeetingSetbyID"
                                ref={this.meetingSetByIDRef}
                                name="MeetingSetbyID"
                                onChange={this.handleChange.bind(
                                  this,
                                  "MeetingSetbyID"
                                )}
                              >
                                {/* <option value="">--Select--</option> */}
                                {this.state.meetingsetbyResponder.length > 0
                                  ? this.state.meetingsetbyResponder?.map(
                                      (value) => (
                                        <option value={value?.value}>
                                          {value?.label}
                                        </option>
                                      )
                                    )
                                  : ""}
                              </select>
                              <span style={{ color: "red" }}>
                                {this.state.errors["MeetingSetbyID"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols  d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Meeting Date
                            </label>
                            <div className="col-lg-7 timedatepikker date-input">
                              <input
                                className="form-control m-input"
                                type="text"
                                //value='2012-05-15 21:05'
                                id="datetimepicker"
                                //data-date-format='mm-dd-yyyy hh:ii'
                                //onChange={this.pikerdatevalue}
                                ref={this.daterefect}
                              />
                              <span style={{ color: "red" }}>
                                {this.state.errors["datevalue"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Meeting Time Zone
                            </label>
                            <div className="col-lg-7">
                              <select
                                className="form-control  m-input"
                                data-val="true"
                                data-val-number="The field SalesRepID must be a number."
                                id="TimeZoneID"
                                ref={this.timeZoneIDRef}
                                name="TimeZoneID"
                                onChange={() =>
                                  this.HandleChangedropdownBlur("TimeZoneID")
                                }
                              >
                                <option value="">--Select--</option>
                                {this.state.meetingtimezone.map((value) => (
                                  <option value={value?.FieldID}>
                                    {value?.FieldName}
                                  </option>
                                ))}
                              </select>
                              <span style={{ color: "red" }}>
                                {this.state.errors["TimeZoneID"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Owner
                            </label>
                            <div className="col-lg-7">
                              <select
                                className="form-control  m-input"
                                data-val="true"
                                data-val-number="The field MeetingOwnerID must be a number."
                                id="MeetingOwnerID"
                                ref={this.meetingOwnerIDRef}
                                name="MeetingOwnerID"
                                onChange={() =>
                                  this.HandleChangedropdownBlur("MeetingOwner")
                                }
                              >
                                <option value="">--Select--</option>
                                {this.state.meetingowner.map((value) => (
                                  <option value={value._id}>
                                    {value.Name}
                                  </option>
                                ))}
                              </select>
                              <span style={{ color: "red" }}>
                                {this.state.errors["MeetingOwnerID"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Reminder Email Account
                            </label>
                            <div className="col-lg-7">
                              <select
                                className="form-control  m-input"
                                data-val="true"
                                data-val-number="The field ClientPOCID must be a number."
                                id="ClientPOCID"
                                name="ClientPOCID"
                                ref={this.reminderEmailAccountRef}
                                //defaultValue={this.state.MeetingReminderData}
                                onChange={() =>
                                  this.HandleChangedropdownBlur(
                                    "ReminderEmailAccount"
                                  )
                                }
                              >
                                <option value="">--Select--</option>
                                {this.state.meetingaccount.map((value) => (
                                  <option value={value._id}>
                                    {value.SMTPFromEmail}
                                  </option>
                                ))}
                              </select>
                              <span style={{ color: "red" }}>
                                {this.state.errors["ClientPOCID"]}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols  d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Contract Value
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-required="Please enter ContractValue name"
                                id="ContractValue"
                                ref={this.contractValueRef}
                                name="ContractValue"
                                type="text"
                                value={this.state.ContractVal}
                                onChange={this.ContractValHandle}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols  d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              External Account ID
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-required="Please enter first name"
                                id="ExternalAccountID"
                                ref={this.externalAccountIDRef}
                                name="ExternalAccountID"
                                type="number"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              External Contact ID
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                id="ExternalContactID"
                                ref={this.externalContactIDRef}
                                name="ExternalContactID"
                                type="number"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols  d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              External Field 1
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-required="Please enter first name"
                                id="ExternalField1"
                                ref={this.externalField1Ref}
                                name="ExternalField1"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols  d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              External Field 2
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-required="Please enter first name"
                                id="ExternalField2"
                                ref={this.externalField2Ref}
                                name="ExternalField2"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols  d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              External Field 3
                            </label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                data-val="true"
                                data-val-required="Please enter first name"
                                id="ExternalField3"
                                ref={this.externalField3Ref}
                                name="ExternalField3"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-6"></div> */}
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Seniority
                            </label>
                            <div className="col-lg-7">
                              <select
                                className="form-control  m-input"
                                data-val="true"
                                data-val-number="The field SalesRepID must be a number."
                                id="SeniorityID"
                                ref={this.seniorityIDRef}
                                defaultValue={this.props.ColdCallerUserID ?? ""}
                                name="SeniorityID"
                                onChange={this.handleChange.bind(
                                  this,
                                  "SeniorityID"
                                )}
                              >
                                <option value="">--Select--</option>
                                {this.state.meetingseniority.map((value) => (
                                  <option value={value.FieldID}>
                                    {value.FieldName}
                                  </option>
                                ))}
                              </select>
                              <span style={{ color: "red" }}>
                                {this.state.errors["SeniorityID"]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="pt-3">
                            <label class="check_bx">
                              <input
                                id="include_source"
                                type="checkbox"
                                onChange={(e) => this.handleChangecheck(e)}
                                defaultChecked={this.state.checked}
                              />
                              <span className="checkmark"></span>
                              Send Reminder
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 boxsinput_group">
                          <div className="row max-cols  d-flex align-items-center">
                            <label className="col-lg-4 view-lable-meet potential_text">
                              Call Recording
                            </label>
                            <div className="col-lg-7">
                            {this.state.RecordingAudioPath ? (
                            <audio
                              controls
                              style={{ outline: "none" }}
                              type="audio/mp3"
                            >
                              <source
                                src={
                                  CommonConstants.CallRecoingURL +
                                  this.state.RecordingAudioPath+".mp3"
                                }
                              />
                              Your browser does not support the audio element.
                            </audio>
                          ) : (
                            ""
                          )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-2">
                        <div className="row mt-3 mb-4 borderbtnm mx-0"></div>
                        <div className="row mb-1 mx-0">
                          <div className="col-lg-12 pull-left">
                            <button
                              id="submit"
                              href="javascript:void(0);"
                              className="btn btn-primary btn-lightgreen mr-1"
                              value="Save"
                              onClick={(e) => this.savebtn(e, CloseBookMeetingModel, PerformNextActionAfterMeeting, DisconnectCall)}
                              disabled={this.state.IsButtonDisabled}
                            >
                              <i className="la la-save"></i> Save
                            </button>
                            <button
                              id="backtolist"
                              href="javascript:void(0);"
                              className="btn btn-secondary"
                              value="Save"
                              onClick={CloseBookMeetingModel}
                            >
                              <i className="la la-arrow-circle-left"></i> Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* )}
        </Popup> */}
      </div>
    );
  }
}

export default CallDialAddBookedMeetingPage;
