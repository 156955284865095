import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import ContactsTabing from '../../_components/clients/contacts/ContactsTabing';
import CollapsibleTable from '../../_components/usertable';



class CcontactsPage extends React.Component {
    componentDidMount() {
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row"> 
                        <div className='col'>
                        <h4 className="headertitlebd lg-headertitle">Contacts</h4> 
                        </div>
                        <div className="col padright">
                        </div>
                    </div> 
                    <div className="col px-0">
                        <ContactsTabing />
                    </div>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCcontactsPage = connect(mapState, actionCreators)(CcontactsPage);
export { connectedCcontactsPage as CcontactsPage };