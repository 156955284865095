import React from "react";
import { connect } from "react-redux";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddDispositionsStatusPage extends React.Component {
  constructor(props) {
    super(props);
      const urlParams = window.location.pathname;
      var NavigationParams = urlParams === "/defaultphonesettings" ? "Default" : ""
    this.state = {
      NavigationParams,
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
      NextAction: [],
      Action:[],
      NextActionID: null,
      CustomVariable: {},
      IsExist: false,
      Checked: true,
      NextActionDetails: {},
      ActionToID:null,
      ActionDetails:{},
      IsButtonDisabled: false,
      isEditMode: false,  // New state variable for edit mode
      dispositionId: null, // Store the ID if editing
      OldName:null,
      DefaultNextActionID:null,
      IsValidEmail:false,
      IsLoading:false,
      ContactStatus:[],
      ContactStatusDetails: {},
      ContactStatusID:null,
      ActionToID2:null,
      ActionDetails2:{},
      ActionToID3:null,
      ActionDetails3:{},
      DispositionEmail:[],
      DispositionEmailDetails: {},
      DispositionEmailID:null,
      OldDispositionsStatus:null,
      VoiceMailRecording:[],
      VoiceMailRecordingID:null,
      VoiceMailRecordingDetails:{},
      IsCountChecked:false
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
    // this.HandleChangeCallStatusBlur = this.HandleChangeCallStatusBlur.bind(this);
    this.HandleChangedropdownBlur = this.HandleChangedropdownBlur.bind(this);
  }
  componentDidMount() {

    // document.title = `Add Dispositions Status | SalesHive`;

    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
      this.setState({ Role: Details.Role });
    }
    this.DispositionNextActionGet(Details.Role);
    this.ContactStatusGet(Details.ClientID,Details.ParentUserID,Details.Role);
    const { dispositionIdForEdit, isEditMode } = this.props;
    {
      isEditMode ?  document.title = `Edit Dispositions Status | SalesHive` :`Add Dispositions Status | SalesHive` ;
    }
    // Check if there's a disposition ID for editing
    if (isEditMode && dispositionIdForEdit) {
      this.setState({ isEditMode: true, dispositionId: dispositionIdForEdit });
      this.EditDispositionNextAction(dispositionIdForEdit,Details.Role); // Fetch the details for the disposition
    }
    console.log("cst",this.state.ContactStatusDetails, this.state.ContactStatusID)
  }
 // New function to fetch disposition details for editing
 EditDispositionNextAction(id,Role) {
  this.setState({IsLoading:true})
  var DispositionStatusIdData = {
    id: id,
    Role: Role,
    PageType:this.state.NavigationParams
  };

  Axios({
    url: CommonConstants.MOL_APIURL + "/dispositionstatus/DispositionsStatusGetByID",
    method: "POST",
    data: DispositionStatusIdData,
  }).then((res) => {
    if (res.data.StatusMessage == "SUCCESS") {
      document.getElementById("DisplayName").value = res.data.Data?.DisplayName;
      // document.getElementById("DispositionsStatus").value = res.data.Data?.DispositionsStatus;
      document.getElementById("Note").value = res.data.Data?.Note ?? "";
      document.getElementById("Email").value = res.data.Data?.Email ?? "";

      this.setState({
        NextActionID: res.data.Data?.NextActionID,
        ActionToID: res.data.Data?.ActionToID,
        ActionToID2:res.data.Data?.ActionToID2,
        ActionToID3:res.data.Data?.ActionToID3,
        ContactStatusID:res.data.Data?.ContactStatusID,
        DispositionEmailID:res.data.Data?.DispositionEmailID,
        OldDispositionsStatus: res.data.Data?.DispositionsStatus,
        VoiceMailRecordingID:res.data.Data?.VoiceMailRecordingID,
        OldName: res.data.Data?.DisplayName,
        IsLoading:false,
        IsCountChecked: res.data.Data?.IsCountConnection ?? false,
      });

    } else {
      toast.error(res.data.Message);
      this.setState({IsLoading:false})
    }
  });
}
  // form validation
  async FromValidations() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var DisplayName = document.getElementById("DisplayName").value.trim();
    // var DispositionsStatus = document.getElementById("DispositionsStatus").value.trim();
    var NextAction = document.getElementById("NextAction").value;
    var Email = document.getElementById("Email").value.trim();
    var IsExist = await this.DispositionsStatusCheckExist(DisplayName);
    let IsValidEmailFlag = await this.EmailValidationCheck(Email);
    if (DisplayName == "") {
      FormIsValid = false;
      Errors["DisplayName"] = "Please enter disposition name";
    }
    // if (DispositionsStatus == "") {
    //     FormIsValid = false;
    //     Errors["DispositionsStatus"] = "Please enter dispositions status";
    //   }

    if (NextAction == "") {
      FormIsValid = false;
      Errors["NextAction"] = "Please select next action";
    }
    if (IsExist == "ExistStatus") {
      FormIsValid = false;
      Errors["DisplayName"] = "Disposition Name already exist.";
    }
    if (IsExist == "BlankData" && DisplayName == "") {
      FormIsValid = false;
      Errors["DisplayName"] = "Please enter disposition name";
    }
    if(IsValidEmailFlag == true){
      FormIsValid = false;
      Errors["Email"] = "invalid email."; 
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }
  // check email valid
    async EmailValidationCheck (Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["Email"] = "invalid email.";
      this.setState({ IsValidEmail: true });
      this.setState({Errors:Errors});
      return true;
    } else {
      this.setState({ IsValidEmail: false });

      return false;
    }
    };
  // form validation
  async HandleChangeBlur() {
    let Errors = { ...this.state.Errors };
    var DisplayName = document.getElementById("DisplayName").value.trim();
    var IsExist = await this.DispositionsStatusCheckExist(DisplayName);
    if (IsExist == "ExistStatus") {
      
      Errors["DisplayName"] = "Disposition Name already exist.";
    }

    if (IsExist == "BlankData" && DisplayName == "") {
     
      Errors["DisplayName"] = "Please enter disposition name";
    }

    if (IsExist == "AllDone") {
      Errors["DisplayName"] = null;
    }
    this.setState({ Errors: Errors });
  }

// HandleChangeCallStatusBlur() {
//     let Errors = { ...this.state.Errors };
//     var DispositionsStatus = document.getElementById("DispositionsStatus").value.trim();
//     if (DispositionsStatus == "") {
//       Errors["DispositionsStatus"] = "Please enter dispositions status";
//     }else{
//         Errors["DispositionsStatus"] = null;
//     }
//     this.setState({ Errors: Errors });
//   }

  async HandleChangedropdownBlur() {
    let Errors = { ...this.state.Errors };
    var NextAction = document.getElementById("NextAction").value;
    if (NextAction == "") {
      Errors["NextAction"] = "Please select action";
    } else {
      Errors["NextAction"] = null;
    }
    this.setState({ Errors: Errors });
  }

  // handle change
  HandleChange(Field, E) {
    let Fields = this.state.Fields;
    Fields[Field] = E.target.value;
    this.setState({ Fields });
    if (Fields.DisplayName != "") {
      this.state.Errors.DisplayName = null;
      this.state.StatusAvailable = null;
      this.DispositionsStatusCheckExist(E.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.DisplayName = null;
    }
  }

  // back button
  BackBtn() {
    this.props.onUpdate(true)
  }

    // find check exits meeting source
    async DispositionsStatusCheckExist(Name) {
      if (Name != "") {
        var DispositionsStatus = {
          ClientID: this.state.ClientID,
          UserID:this.state.UserID,
          DisplayName: Name,
          Role: this.state.Role,
          PageType:this.state.NavigationParams
        };
        var ResponseData = await Axios({
          url: CommonConstants.MOL_APIURL + "/dispositionstatus/DispositionsStatusExists",
          method: "POST",
          data: DispositionsStatus,
        });
  
        if (ResponseData.data.StatusMessage == "SUCCESS") {
          if (ResponseData.data.Data.length > 0) {
            if (this.state.OldName == Name) {
              return "AllDone";
            } else {
              this.setState({ StatusAvailable: ResponseData.data.Data[0].Name });
              return "ExistStatus";
            }
          } else {
            return "AllDone";
          }
        }
      } else {
        return "BlankData";
      }
    }

  // dispositions status next action get
  DispositionNextActionGet(Role) {
    var InputParams = {
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/dispositionstatus/DispositionNextActionGet",
      method: "POST",
      data: InputParams,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ NextAction: res.data.Data,
          Action:res.data.DataAction});
             this.setState({ IsLoading: false });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // Contact Status Get
  ContactStatusGet(CID,UID,Role) {
    var InputParams = {
      ClientID:CID,
      UserID:UID,
      Role: Role,
      PageType:this.state.NavigationParams
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/dispositionstatus/ProspectStatusDataGet",
      method: "POST",
      data: InputParams,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
      this.setState({ ContactStatus: res.data.ProspectStatusData});
      this.setState({ DispositionEmail: res.data.DispositionEmailData});
      this.setState({VoiceMailRecording:res.data.VoiceMailRecordingsData})
      this.setState({ IsLoading: false });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // handle change list
  HandleChangeList = (E) => {
    const selectedFieldID = E.target.value;
    this.setState({ NextActionID: selectedFieldID });

    const selectedField = this.state.NextAction.find(
        (item) => item.FieldID === selectedFieldID
    );

    if (selectedField) {
        const selectedFieldName = selectedField.FieldName;
    
        // Store FieldID and FieldName in 'ondata' object
        const ondata = {
          NextActionID: selectedFieldID,
          NextActionName: selectedFieldName,
        };
        // Set state for FieldID
        this.setState({ NextActionDetails: ondata });

    }
  };

    // handle for move to change list Action 1
    HandleChangeActionToList = (E) => {
      const selectedFieldID = E.target.value;
      if(selectedFieldID == ""){
        this.setState({ ActionToID: "" });
        const ondata = {
          ActionToID: "",
          ActionName: "",
        };
        // Set state for FieldID
        this.setState({ ActionDetails: ondata });
      }else{
        this.setState({ ActionToID: selectedFieldID });
  
        const selectedField = this.state.Action.find(
            (item) => item.FieldID === selectedFieldID
        );
    
        if (selectedField) {
            const selectedFieldName = selectedField.FieldName;
        
            // Store FieldID and FieldName in 'ondata' object
            const ondata = {
              ActionToID: selectedFieldID,
              ActionName: selectedFieldName,
            };
            // Set state for FieldID
            this.setState({ ActionDetails: ondata });
    
        }
      }
   
    };

      // handle for move to change list Action 1
      HandleChangeDispositionEmailList = (E) => {
        const selectedFieldID = E.target.value;
        if(selectedFieldID == ""){
          this.setState({ DispositionEmailID: "" });
          const ondata = {
            DispositionEmailID: "",
            DispositionEmailName: "",
          };
          // Set state for FieldID
          this.setState({ DispositionEmailDetails: ondata });
        }else{
          this.setState({ DispositionEmailID: selectedFieldID });
    
          const selectedField = this.state.DispositionEmail.find(
              (item) => item._id === selectedFieldID
          );
      
          if (selectedField) {
              const selectedFieldName = selectedField.Name;
          
              // Store FieldID and FieldName in 'ondata' object
              const ondata = {
                DispositionEmailID: selectedFieldID,
                DispositionEmailName: selectedFieldName,
              };
              // Set state for FieldID
              this.setState({ DispositionEmailDetails: ondata });
      
          }
        }
     
      };
      // handle for voice mail recordings
      HandleChangeVoiceMailRecordings = (E) => {
        const selectedFieldID = E.target.value;
        if(selectedFieldID == ""){
          this.setState({ VoiceMailRecordingID: "" });
          const ondata = {
            VoiceMailRecordingID: "",
            VoiceMailRecordingName: "",
            VoiceMailFileName: "",
            Duration:0,
          };
          // Set state for FieldID
          this.setState({ VoiceMailRecordingDetails: ondata });
        }else{
          this.setState({ VoiceMailRecordingID: selectedFieldID });
    
          const selectedField = this.state.VoiceMailRecording.find(
              (item) => item._id === selectedFieldID
          );
      
          if (selectedField) {
              const selectedFieldName = selectedField.Name;
              const selectedFileName = selectedField.FileName;
              const selectedDuration = selectedField.Duration;
          
              // Store FieldID and FieldName in 'ondata' object
              const ondata = {
                VoiceMailRecordingID: selectedFieldID,
                VoiceMailRecordingName: selectedFieldName,
                VoiceMailFileName: selectedFileName,
                Duration:selectedDuration,
              };
              // Set state for FieldID
              this.setState({ VoiceMailRecordingDetails: ondata });
      
          }
        }
     
      };
    // Action 2
    HandleChangeActionTo2List = (E) => {
      const selectedFieldID = E.target.value;
      if(selectedFieldID == ""){
        this.setState({ ActionToID2: "" });
        const ondata = {
          ActionToID2: "",
          ActionName: "",
        };
        // Set state for FieldID
        this.setState({ ActionDetails2: ondata });
      }else{
        this.setState({ ActionToID2: selectedFieldID });
  
        const selectedField = this.state.Action.find(
            (item) => item.FieldID === selectedFieldID
        );
    
        if (selectedField) {
            const selectedFieldName = selectedField.FieldName;
        
            // Store FieldID and FieldName in 'ondata' object
            const ondata = {
              ActionToID2: selectedFieldID,
              ActionName: selectedFieldName,
            };
            // Set state for FieldID
            this.setState({ ActionDetails2: ondata });
    
        }
      }
   
    };
    // Action 3
    HandleChangeActionTo3List = (E) => {
      const selectedFieldID = E.target.value;
      if(selectedFieldID == ""){
        this.setState({ ActionToID3: "" });
        const ondata = {
          ActionToID3: "",
          ActionName: "",
        };
        // Set state for FieldID
        this.setState({ ActionDetails3: ondata });
      }else{
        this.setState({ ActionToID3: selectedFieldID });
  
        const selectedField = this.state.Action.find(
            (item) => item.FieldID === selectedFieldID
        );
    
        if (selectedField) {
            const selectedFieldName = selectedField.FieldName;
        
            // Store FieldID and FieldName in 'ondata' object
            const ondata = {
              ActionToID3: selectedFieldID,
              ActionName: selectedFieldName,
            };
            // Set state for FieldID
            this.setState({ ActionDetails3: ondata });
    
        }
      }
   
    };
      // handle for contact status to change list
      HandleChangeContactStatusList = (E) => {
        
        const selectedFieldID = E.target.value;
        if(selectedFieldID == ""){
          this.setState({ ContactStatusID: "" });
          const ondata = {
            ContactStatusID: "",
            ContactStatusName: "",
          };
          // Set state for FieldID
          this.setState({ ContactStatusDetails: ondata });
        }else{
          this.setState({ ContactStatusID: selectedFieldID });
    
          const selectedField = this.state.ContactStatus.find(
              (item) => item._id === selectedFieldID
          );
      
          if (selectedField) {
              const selectedFieldName = selectedField.Status;
          
              // Store FieldID and FieldName in 'ondata' object
              const ondata = {
                ContactStatusID: selectedFieldID,
                ContactStatusName: selectedFieldName,
              };
              console.log(ondata)
              // Set state for Contact status details
              this.setState({ ContactStatusDetails: ondata });
      
          }
        }
      };

  // save  dispositions status
  async SaveBtn(e) {
    e.preventDefault();
         this.setState({ IsLoading: true});
    this.setState({ IsButtonDisabled: true });
    
    var Final_flag = await this.FromValidations();
    if (Final_flag == true) {


      var DisplayName = document.getElementById("DisplayName").value.trim();
      // var DispositionsStatus = document.getElementById("DispositionsStatus").value.trim();
      var Note = document.getElementById("Note").value.trim();
      var NextActionID = document.getElementById("NextAction").value;
      var Email = document.getElementById("Email").value.trim();
      var ActionToID = document.getElementById("ActionTo").value.trim();
      var ActionToID2 = document.getElementById("ActionTo2").value.trim();
      var ActionToID3 = document.getElementById("ActionTo3").value.trim();
      var ContactStatusID = document.getElementById("ContactStatusID").value.trim();
      var DispositionEmailID = document.getElementById("DispositionEmailID").value.trim();
      var VoiceMailRecordingID = document.getElementById("VoiceMailRecordingID").value.trim();
        let Errors = {}
        // Validation for Action 1, 2, and 3 dropdowns
        if (ActionToID2 !== "" && ActionToID === "") {
          // If Action 2 is selected but Action 1 is not
          Errors["ActionTo"] = "Please select Action 1 before selecting Action 2.";
          // toast.error("Please select Action 1 before selecting Action 2.");
          this.setState({ IsButtonDisabled: false, IsLoading: false,Errors:Errors });
          return; // Prevent data from being saved
      }

      if (ActionToID3 !== "" && (ActionToID === "" || ActionToID2 === "")) {
          // If Action 3 is selected but Action 1 or 2 is not
          toast.error("Please select Action 1 or 2 before selecting Action 3.")
          // Errors["ActionTo2"] = "Please select Action 2 before selecting Action 3.";
          // Errors["ActionTo"] = "Please select Action 1 before selecting Action 2.";
          // toast.error("Please select Action 1 and Action 2 before selecting Action 3.");
          this.setState({ IsButtonDisabled: false, IsLoading: false,Errors:Errors });
          return; // Prevent data from being saved
      }
      // Next Action Data
      if(NextActionID == ""){
          // Store FieldID and FieldName in 'ondata' object
          const ondata = {
            NextActionID: "",
            NextActionName: "",
          };
          this.setState({ NextActionDetails: ondata });
      }else{
        const selectedField = this.state.NextAction.find(
          (item) => item.FieldID === NextActionID
        );
  
        if (selectedField) {
          const selectedFieldName = selectedField.FieldName;
      
          // Store FieldID and FieldName in 'ondata' object
          const ondata = {
            NextActionID: NextActionID,
            NextActionName: selectedFieldName,
          };
          this.setState({ NextActionDetails: ondata });
  
        }
      }
        // Action 1 Data
        if(ActionToID == ""){
          const ondata = {

            ActionToID: "",
            ActionName: "",
          };
          this.setState({ ActionDetails: ondata });
        }else{
                // Move to id
      const selectedMoveIDField = this.state.Action.find(
        (item) => item.FieldID === ActionToID
      );

      if (selectedMoveIDField) {
        const selectedFieldName = selectedMoveIDField.FieldName;
    
        // Store FieldID and FieldName in 'ondata' object
        const ondata = {
          ActionToID: ActionToID,
          ActionName: selectedFieldName,
        };
        this.setState({ ActionDetails: ondata });

      }
        }

        // Action 2
        if(ActionToID2 == ""){
          const ondata = {

            ActionToID2: "",
            ActionName: "",
          };
          this.setState({ ActionDetails2: ondata });
        }else{
                // Move to id
      const selectedMoveIDField = this.state.Action.find(
        (item) => item.FieldID === ActionToID2
      );

      if (selectedMoveIDField) {
        const selectedFieldName = selectedMoveIDField.FieldName;
    
        // Store FieldID and FieldName in 'ondata' object
        const ondata = {
          ActionToID2: ActionToID2,
          ActionName: selectedFieldName,
        };
        this.setState({ ActionDetails2: ondata });

      }
        }

        // Action 3
        if(ActionToID3 == ""){
          const ondata = {

            ActionToID3: "",
            ActionName: "",
          };
          this.setState({ ActionDetails3: ondata });
        }else{
                // Move to id
      const selectedMoveIDField = this.state.Action.find(
        (item) => item.FieldID === ActionToID3
      );

      if (selectedMoveIDField) {
        const selectedFieldName = selectedMoveIDField.FieldName;
    
        // Store FieldID and FieldName in 'ondata' object
        const ondata = {
          ActionToID3: ActionToID3,
          ActionName: selectedFieldName,
        };
        this.setState({ ActionDetails3: ondata });

      }
        }

        // Contact Status
        if(ContactStatusID == ""){
          this.setState({ ContactStatusID: "" });
          const ondata = {
            ContactStatusID: "",
            ContactStatusName: "",
          };
          // Set state for FieldID
          this.setState({ ContactStatusDetails: ondata });
        }else{
          this.setState({ ContactStatusID: ContactStatusID });
    
          const selectedField = this.state.ContactStatus.find(
              (item) => item._id === ContactStatusID
          );
      
          if (selectedField) {
              const selectedFieldName = selectedField.Status;
          
              // Store FieldID and FieldName in 'ondata' object
              const ondata = {
                ContactStatusID: ContactStatusID,
                ContactStatusName: selectedFieldName,
              };
              console.log(ondata)
              // Set state for Contact status details
              this.setState({ ContactStatusDetails: ondata });
      
          }
        }

        // Disposition Email For
        if(DispositionEmailID == ""){
          this.setState({ DispositionEmailID: "" });
          const ondata = {
            DispositionEmailID: "",
            DispositionEmailName: "",
          };
          // Set state for FieldID
          this.setState({ DispositionEmailDetails: ondata });
        }else{
          this.setState({ DispositionEmailID: DispositionEmailID });
    
          const selectedField = this.state.DispositionEmail.find(
              (item) => item._id === DispositionEmailID
          );
      
          if (selectedField) {
              const selectedFieldName = selectedField.Name;
          
              // Store FieldID and FieldName in 'ondata' object
              const ondata = {
                DispositionEmailID: DispositionEmailID,
                DispositionEmailName: selectedFieldName,
              };
              console.log(ondata)
              // Set state for Contact status details
              this.setState({ DispositionEmailDetails: ondata });
      
          }
        }

        // VoiceMail Recording for
        
        if(VoiceMailRecordingID == ""){
          this.setState({ VoiceMailRecordingID: "" });
          const ondata = {
            VoiceMailRecordingID: "",
            VoiceMailRecordingName: "",
            VoiceMailFileName: "",
            Duration:0,
          };
          // Set state for FieldID
          this.setState({ VoiceMailRecordingDetails: ondata });
        }else{
          this.setState({ VoiceMailRecordingID: VoiceMailRecordingID });
    
          const selectedField = this.state.VoiceMailRecording.find(
              (item) => item._id === VoiceMailRecordingID
          );
      
          if (selectedField) {
              const selectedFieldName = selectedField.Name;
              const selectedFileName = selectedField.FileName;
              const selectedDuration = selectedField.Duration;
          
              // Store FieldID and FieldName in 'ondata' object
              const ondata = {
                VoiceMailRecordingID: VoiceMailRecordingID,
                VoiceMailRecordingName: selectedFieldName,
                VoiceMailFileName: selectedFileName,
                Duration:selectedDuration,
              };
              console.log(ondata)
              // Set state for Contact status details
              this.setState({ VoiceMailRecordingDetails: ondata });
      
          }
        }

    if(this.state.isEditMode){
      var Data = {
        _id: this.state.dispositionId,
        DisplayName: DisplayName,
        DispositionsStatus:DisplayName,
        Note:Note,
        NextActionID: NextActionID,
        NextActionDetails:this.state.NextActionDetails,
        Type: "User",
        Role: this.state.Role,
        LastUpdatedBy:this.state.CUserID,
        LastUpdatedDate:new Date(),
        Email:Email,
        ActionToID:ActionToID,
        ActionDetails:this.state.ActionDetails,
        PageType:this.state.NavigationParams,
        ActionToID2:ActionToID2,
        ActionDetails2:this.state.ActionDetails2,
        ActionToID3:ActionToID3,
        ActionDetails3:this.state.ActionDetails3,
        ContactStatusID:ContactStatusID,
        ContactStatusDetails:this.state.ContactStatusDetails,
        DispositionEmailID:DispositionEmailID,
        DispositionEmailDetails:this.state.DispositionEmailDetails,
        VoiceMailRecordingID:VoiceMailRecordingID,
        VoiceMailRecordingDetails:this.state.VoiceMailRecordingDetails,
        ClientID:this.state.ClientID,
        UserID:this.state.UserID,
        OldDispositionsStatus:this.state.OldDispositionsStatus,
        IsCountConnection:this.state.IsCountChecked
      };
      console.log(Data,"Data")
      this.setState({ IsButtonDisabled: false });
      Axios({
        url: CommonConstants.MOL_APIURL + "/dispositionstatus/DispositionsStatusUpdate",
        method: "POST",
        data: Data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div>Disposition Status updated successfully.</div>);
          this.props.onUpdate(true)
        } else {
          this.setState({ IsButtonDisabled: false });
          toast.error(res.data.Message);
        }
      });
    }else{
      var Data = {
        DisplayName: DisplayName,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        CreatedBy: this.state.CUserID,
        CreatedDt: new Date().toISOString(),
        DisplayName: DisplayName,
        DispositionsStatus:DisplayName,
        Note:Note,
        NextActionID: NextActionID,
        NextActionDetails:this.state.NextActionDetails,
        Type: "User",
        Role: this.state.Role,
        Email:Email,
        ActionToID:ActionToID,
        ActionDetails:this.state.ActionDetails,
        PageType:this.state.NavigationParams,
        ActionToID2:ActionToID2,
        ActionDetails2:this.state.ActionDetails2,
        ActionToID3:ActionToID3,
        ActionDetails3:this.state.ActionDetails3,
        ContactStatusID:ContactStatusID,
        ContactStatusDetails:this.state.ContactStatusDetails,
        DispositionEmailID:DispositionEmailID,
        DispositionEmailDetails:this.state.DispositionEmailDetails,
        VoiceMailRecordingID:VoiceMailRecordingID,
        VoiceMailRecordingDetails:this.state.VoiceMailRecordingDetails,
        IsCountConnection:this.state.IsCountChecked
      };
      console.log(Data,"Data")
      this.setState({ IsButtonDisabled: false });
      Axios({
        url: CommonConstants.MOL_APIURL + "/dispositionstatus/DispositionsStatusAdd",
        method: "POST",
        data: Data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div>Disposition Status added successfully.</div>);
          this.props.onUpdate(true)
               this.setState({ IsLoading: false });
       
        } else {
          this.setState({ IsButtonDisabled: false });
          toast.error(res.data.Message);
               this.setState({ IsLoading: false });
        }
      });
    }
    } else {
      this.setState({ IsButtonDisabled: false });
           this.setState({ IsLoading: false });

    }

  }
  render() {
    return (
      <>
   
     { this.state.IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )
     }
        <div className="adminmain"> 
        
          {/* <div className="bodyhome"> */}
              <div className="row border-bottom mx-0">
                <div className="col">
                  <h4 className="headertitlepad xs-headertitle float-left">
                  {this.state.isEditMode ? "Edit Dispositions Status" : "Add Dispositions Status"}
                  </h4>
                </div>
              </div>

              <div class=" mb-3">
              <div className="row py-2 mx-0">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Disposition Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="DisplayName"
                        onBlur={this.HandleChangeBlur}
                        name="DisplayName"
                        placeholder="Enter disposition name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["DisplayName"]}
                      </span>
                  
                    </div>
                  </div>
                </div>

                {/* <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Disposition Status</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="DispositionsStatus"
                        onBlur={this.HandleChangeCallStatusBlur}
                        name="DispositionsStatus"
                        placeholder="Enter disposition status"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["DispositionsStatus"]}
                      </span>
                  
                    </div>
                  </div>
                </div> */}


         
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Email</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="Email"
                        name="Email"
                        placeholder="Enter Email"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Email"]}
                      </span>
                  
                    </div>
                  </div>
                </div>
                {/* Contact Status DropDown */}

                                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                        Contact Status
                    </label>
                    {
                      this.state.isEditMode ?   <div class="col-lg-8">
                      <select
                          className="form-control  m-input"
                        id="ContactStatusID"
                        name="ContactStatusID"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeContactStatusList(E);
                        }}
                        value={this.state.ContactStatusID}
                      >
                      <option value="">--select--</option>
                        {this.state.ContactStatus?.map((value) => (
                          <option value={value._id}>
                            {value.Status}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["ContactStatusID"]}
                      </span>
                    </div>  :           <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ContactStatusID"
                        name="ContactStatusID"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeContactStatusList(E);}}
                      >
                      <option value="">--select--</option>
                      {this.state.ContactStatus?.map((value) => (
                          <option value={value._id}>
                            {value.Status}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["ContactStatusID"]}
                      </span>
                    </div>
                    }
         
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                        Action 1
                    </label>
                    {
                      this.state.isEditMode ?   <div class="col-lg-8">
                      <select
                          className="form-control  m-input"
                        id="ActionTo"
                        name="ActionTo"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeActionToList(E);
                        }}
                        value={this.state.ActionToID}
                      >
                      <option value="">--select--</option>
   {this.state.Action?.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["ActionTo"]}
                      </span>
                    </div>  :           <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ActionTo"
                        name="ActionTo"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeActionToList(E);
                   
                        }}
                      >
                      <option value="">--select--</option>
                        {this.state.Action.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["ActionTo"]}
                      </span>
                    </div>
                    }
         
                  </div>
                </div>
                {/* Action 2 */}
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                        Action 2
                    </label>
                    {
                      this.state.isEditMode ?   <div class="col-lg-8">
                      <select
                          className="form-control  m-input"
                        id="ActionTo2"
                        name="ActionTo2"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeActionTo2List(E);
                        }}
                        value={this.state.ActionToID2}
                      >
                      <option value="">--select--</option>
   {this.state.Action?.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["ActionTo2"]}
                      </span>
                    </div>  :           <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ActionTo2"
                        name="ActionTo2"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeActionTo2List(E);
                         
                        }}
                      >
                      <option value="">--select--</option>
                        {this.state.Action.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["ActionTo2"]}
                      </span>
                    </div>
                    }
         
                  </div>
                </div>
                {/* Action 3 */}
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                        Action 3
                    </label>
                    {
                      this.state.isEditMode ?   <div class="col-lg-8">
                      <select
                          className="form-control  m-input"
                        id="ActionTo3"
                        name="ActionTo3"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeActionTo3List(E);
                        }}
                        value={this.state.ActionToID3}
                      >
                      <option value="">--select--</option>
   {this.state.Action?.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["ActionTo3"]}
                      </span>
                    </div>  :           <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ActionTo3"
                        name="ActionTo3"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeActionTo3List(E);
                        
                        }}
                      >
                      <option value="">--select--</option>
                        {this.state.Action.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["ActionTo3"]}
                      </span>
                    </div>
                    }
         
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                    Disposition Voicemail
                    </label>
                    {
                      this.state.isEditMode ?   <div class="col-lg-8">
                      <select
                          className="form-control  m-input"
                        id="VoiceMailRecordingID"
                        name="VoiceMailRecordingID"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeVoiceMailRecordings(E);
                        }}
                        value={this.state.VoiceMailRecordingID}
                      >
                      <option value="">--select--</option>
   {this.state.VoiceMailRecording?.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["VoiceMailRecordingID"]}
                      </span>
                    </div>  :           <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="VoiceMailRecordingID"
                        name="VoiceMailRecordingID"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeVoiceMailRecordings(E);
                        }}
                      >
                      <option value="">--select--</option>
                        {this.state.VoiceMailRecording.map((value) => (
                          <option value={value._id}>
                          {value.Name}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["VoiceMailRecordingID"]}
                      </span>
                    </div>
                    }
         
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                    Disposition Email
                    </label>
                    {
                      this.state.isEditMode ?   <div class="col-lg-8">
                      <select
                          className="form-control  m-input"
                        id="DispositionEmailID"
                        name="DispositionEmailID"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeDispositionEmailList(E);
                        }}
                        value={this.state.DispositionEmailID}
                      >
                      <option value="">--select--</option>
                      {this.state.DispositionEmail?.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["DispositionEmailID"]}
                      </span>
                    </div>  :           <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="DispositionEmailID"
                        name="DispositionEmailID"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeDispositionEmailList(E);
                        }}
                        // onChange={(E) => {
                        //   this.HandleChangeList(E);
                        //   this.HandleChangedropdownBlur();
                        // }}
                      >
                      <option value="">--select--</option>
                        {this.state.DispositionEmail.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["DispositionEmailID"]}
                      </span>
                    </div>
                    }
         
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Note</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="Note"
                        name="Note"
                        placeholder="Enter note"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Note"]}
                      </span>
                  
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                        Next Action
                    </label>
                    {
                      this.state.isEditMode ?   <div class="col-lg-8">
                      <select
                          className="form-control  m-input"
                        id="NextAction"
                        name="NextAction"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeList(E);
                        }}
                        value={this.state.NextActionID}
                      >
     <option value="">--select--</option>
   {this.state.NextAction.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["NextAction"]}
                      </span>
                    </div>  :           <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="NextAction"
                        name="NextAction"
                        autoComplete="off"
                        onChange={(E) => {
                          this.HandleChangeList(E);
                          this.HandleChangedropdownBlur();
                        }}
                      >
    <option value="">--select--</option>
                        {this.state.NextAction.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}

                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["NextAction"]}
                      </span>
                    </div>
                    }
         
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"> 
  <div className="row max-cols">
    <label className="col-lg-4 view-lable-meet">
      Count as Connection
    </label>
    <div className="col-lg-8">
    <label className="check_bx"> 
      <input
        type="checkbox"
        id="IsCountChecked"
        name="IsCountChecked"
        checked={this.state.IsCountChecked} // Default value based on state
        onChange={(e) => {
          const isChecked = e.target.checked;
          this.setState({ IsCountChecked: isChecked }); // Update state
        }}
      />
       <span className="checkmark"></span>
      <label htmlFor="IsCountChecked">
      {this.state.IsCountChecked ? "Yes" : "No"}
      </label>
      </label>

    </div>
  </div>
</div>

                </div>

            </div>

              <div class="row mb-3 mx-0">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        {/* </div> */}
        {/* <Footer /> */}
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddDispositionsStatusPage = connect(
  mapState,
  actionCreators
)(AddDispositionsStatusPage);
export { connectedAddDispositionsStatusPage as AddDispositionsStatusPage };
