import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup'; // Assuming you're using a Popup library
import Froala from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { CommonConstants, skipScriptVariable, scriptVariable } from "../../../_constants/common.constants";


const SendCustomEmailModel = ({ClientID,UserID,ColdCallerUserID,CloseSendCustomEmailModel,Email,ID,SDRAssignmentEmail,ReminderEmailID,DisconnectCall,PerformNextActionAfterMeeting,UpdateCustomEmailSendCount, ProspectDetails}) => {
    const [SignatureSC, SetSignatureSC] = useState({ data: "" });
    const [eModProfile, SeteModProfile] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState('6523d182eff732ba5ceead8c');
    const [emailSubject, setEmailSubject] = useState('');
    const [FromEmail, setFromEmail] = useState(Email)
    const [errors, setErrors] = useState({});
    useEffect(() => {
        GetVariableDropDownDetails();
        GetEmodProfiles();
    }, [UserID]);

    let config = {
        placeholderText: "Type something!",
        charCounterCount: false,
        toolbarButtons: ["bold", "italic", "underline", "insertLink", "html", "Variable"],
        imageUploadRemoteUrls: false,
        heightMin: "230px",
        heightMax: "100%",
        resize: true,
        pastePlain: true,
        attribution: false,
        pasteDeniedAttrs: ["style"],
        key:CommonConstants.EditorValidationKey,
        enter: Froala.ENTER_BR,
      };

    const VariableModelChange = (model) => {
        
        model = model.replace(/<[\/]{0,1}(p)[^><]*>/ig, "");
        SetSignatureSC({ data: model });
    };

    const GetVariableDropDownDetails = async () => {
        try {
            const VariableDropDown = await scriptVariable;
            const TempVariableDropDown = {};
            VariableDropDown.forEach((item) => {
                TempVariableDropDown["{" + item.split(" ").join("") + "}"] = item;
            });
            
            Froala.RegisterCommand("Variable", {
                title: "Advanced options",
                type: "dropdown",
                focus: false,
                undo: false,
                refreshAfterCallback: true,
                options: TempVariableDropDown,
                callback: function (cmd, val) {
                    this.html.insert(val);
                },
                refresh: function ($btn) {},
                refreshOnShow: function ($btn, $dropdown) {},
            });
        } catch (error) {
            console.error("Error in GetVariableDropDownDetails:", error);
        }
    };

    const GetEmodProfiles = async () => {
        try {
            const eModMenu = { UserID: UserID, Role: "ColdCallers" };
            const response = await Axios.post(
                `${CommonConstants.MOL_APIURL}/eMod/eModCampaignGet`,
                eModMenu
            );
            if (response.data.StatusMessage === "SUCCESS") {
                SeteModProfile(response.data.Data);
            }
        } catch (error) {
            console.error("Error fetching eMod profiles:", error);
        }
    };

    const handleSave = async () => {
        // Initialize an empty errors object
        const validationErrors = {};
    
        // Validate "From Email"
        if (!FromEmail || FromEmail.trim() === "") {
            validationErrors.FromEmail = "To Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(FromEmail)) {
            validationErrors.FromEmail = "Invalid email format.";
        }
    
        // Validate "Subject"
        if (!emailSubject || emailSubject.trim() === "") {
            validationErrors.Subject = "Subject is required.";
        }
    
        // Validate "Body"
        if (!SignatureSC.data || SignatureSC.data.trim() === "") {
            validationErrors.body = "Email body cannot be empty.";
        }
    
        // If there are validation errors, update the errors state and return early
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
    
        // If no errors, proceed with sending the email
        const payload = {
            ClientID,
            UserID,
            ColdCallerUserID,
            ToEmail: SDRAssignmentEmail,
            FromEmail: Email,
            eModProfile: selectedProfile,
            Subject: emailSubject,
            Body: SignatureSC.data,
            ProspectID:ID,
            ProspectDetails:ProspectDetails,
            ReminderEmailAccountID: ReminderEmailID,
            Role: "ColdCallers"

        };
        
        try {
            const response = await Axios.post(
                `${CommonConstants.MOL_APIURL}/twiliocalling/SendCustomEmailApi`,
                payload
            );
            if (response.data.StatusMessage === "SUCCESS") {
                toast.success("Email sent successfully!");
                // DisconnectCall();
                UpdateCustomEmailSendCount();
                PerformNextActionAfterMeeting();
                CloseSendCustomEmailModel();
    
            } else {
                toast.error(response.data.Message || "Error sending email");
            }
        } catch (error) {
            console.error("Error sending email:", error);
            toast.error("Failed to send email");
        }
    };
    

    return (
    <>
         <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup1000">
                        <div className="paddingboxTerms">
                            <div className="modal-header py-4 px-3">
                                <div className="w-100 d-flex px-3">
                                    <h5 className="mb-0">Custom Email</h5>
                                    <button className="close" onClick={CloseSendCustomEmailModel} >
                                        <span aria-hidden="true" className="la la-remove" ></span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="px-4">
                                    <div className="row mb-4">
                                        <label className="col-lg-2 col-form-label textright-vmb"> From </label>
                                        <div className="col-lg-9 d-flex">
                                            <input className="form-control m-input" type="text" id="SDRAssignmentFromEmail" value={SDRAssignmentEmail} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label className="col-lg-2 col-form-label textright-vmb"> To </label>
                                        <div className="col-lg-9 d-flex flex-column">
                                            <input className="form-control m-input" type="text" id="ToEmail" value={FromEmail} onChange={(e)=>{setFromEmail(e.target.value)}}/>
                                            <span style={{color:"red"}} className='pt-1'>{errors.FromEmail}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label className="col-lg-2 col-form-label textright-vmb"> eMod Profile </label>
                                        <div className="col-lg-9 d-flex">
                                            <select className="form-control  m-input" value={selectedProfile} onChange={(e) => setSelectedProfile(e.target.value)}>
                                                <option value='6523d182eff732ba5ceead8c'>eMod off</option>
                                                {eModProfile?.map((el) => (
                                                    <option key={el?._id} value={el?._id}>{el?.Profile}</option>
                                                )) ?? []}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label className="col-lg-2 col-form-label textright-vmb"> Subject</label>
                                        <div className="col-lg-9 d-flex flex-column">
                                            <input className="form-control m-input" type="text" value={emailSubject}
                                    onChange={(e) => setEmailSubject(e.target.value)} id="AddDispositionEmailSubject"/>
                                                                            <span className='pt-1' style={{color:"red"}}>{errors.Subject}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label className="col-lg-2 col-form-label textright-vmb"> Body</label>
                                        <div className="col-lg-9 d-flex flex-column">
                                        <div id="parent-wrapper-id" className="resizer-block">
                                                <FroalaEditor
                                                tag="textarea"
                                                config={config}
                                                model={SignatureSC.data}
                                                onModelChange={VariableModelChange}
                                            />
                                            </div>
                                            <span className='pt-1' style={{color:"red"}}>{errors.body}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={CloseSendCustomEmailModel}
                                >
                                                        <i class="la la-arrow-circle-left mr-1"></i>

                                    Cancel
                                </button>
                                <button
                                    id="SubmitStepTemplate"
                                    onClick={handleSave}
                                    className="btn btn-primary btnColor"
                                >
                                    <i className="la la-save mr-1"></i>Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
    </>
)
}

export default SendCustomEmailModel;