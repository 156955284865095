import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import DefaultObjections from '../../_components/user/DefaultObjections/DefaultObjectionsTable.js';

import { history } from "../../_helpers";



class DefaultObjectionsPage extends React.Component {
    componentDidMount() {
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    }
 //Reload page while delete perform
 UpdateFromChild = (value) => {
    if(value == true){
      this.componentDidMount()
    }
  }
AddBtn(){
    history.push('/adddefaultobjections');
}
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="headertitle lg-headertitle float-left">Default Objections</h4>
                            <div className="listing-li float-right padb-15">
                                <ul>
                                    <li>
                                    <a onClick={()=>{this.AddBtn()}} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-plus"></i>
                                        <span>Add</span>
                                    </a>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>
                        </div>

                     

                    <div class=""> 
                        <div className="row">
                            <div className="col"> 
                                    <DefaultObjections updateFromChild={this.UpdateFromChild}/> 
                            </div>
                        </div>      

                       
                    </div>

                </div> 
            </div>
            <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedDefaultObjectionsPage = connect(mapState, actionCreators)(DefaultObjectionsPage);
export { connectedDefaultObjectionsPage as DefaultObjectionsPage };