import React, { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Axios from "axios";
const Moment = require("moment");
import Select from "react-select";

import ReactDOM from 'react-dom';
import CallDailSetting from '../../user/ColdCalling/CallDialSetting';
import {GettingDialingSetDetails, GettingPhoneScriptDropDownDetails, GettingLiveAnswerDispositionSet,CallBtn } from "../../../_helpers/commonDialerFunction";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";
import { Device } from '@twilio/voice-sdk';
export default function CollapsibleTable(props) {
  const [Open, SetOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [search, setSearch] = React.useState("");
  const [sortField, setsortField] = React.useState("EventCreatedDate1");
  const [sortedColumn, setSortedColumn] = React.useState("Meeting Set Date");
  const [sortedBy, setsortedBy] = React.useState(-1); // 0 for default, 1 for ascending, -1 for descending
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [status, setStatus] = React.useState([]);
  const [meeting, setMeetingOwner] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const [HeaderList, SetHeaderlist] = React.useState([]);
  const [CheckOwner, SetCheckOwner] = React.useState(false);
  const [OwnerIDs, SetOwnerIDs] = React.useState([]);
  const [OwnerIDFinal, SetOwnerIDFinal] = React.useState([]);
  const [OwnerIDFinalRemove, SetOwnerIDFinalRemove] = React.useState([]);
  const [Role, setRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  
  
  
  
  // Dialing 
  const [checkeddata, setChecked] = React.useState({ "IsDirectPhone": true ,"IsMobilePhone": true ,"IsCompanyPhone": true });
  const [DialingSetList, SetDialingSetList] = React.useState([])
  const [DefaultDialingSetID, SetDefaultDialingSetID] = React.useState(null)
  const [PhoneScriptDetails, SetPhoneScriptDetails] = React.useState([])
  const [LiveAnswerDispositionSet, SetLiveAnswerDispositionSet] = React.useState([]);
  const [DefaultLiveAnswerDispositionID, SetLiveAnswerDispositionID] = React.useState(null)

  useEffect(() => {
    document.title = `Booked Meetings | SalesHive`;
    const fetchData = async () => {
      var userDetails = GetUserDetails();
      if (userDetails != null) {
        setClientID(userDetails.ClientID);
        setUserID(userDetails.ParentUserID);
        SetCUserID(userDetails.ChildUserID);
        setRole(userDetails.Role);
      }

      BookedmeetingListGet(
        userDetails.ClientID,
        userDetails.ParentUserID,
        props.IsApply,
        props.FilterParameters,
        userDetails.Role,
        page
      );

      OwnerDropDown(userDetails.ClientID, userDetails.Role);

      const dialingSetDetails = await GettingDialingSetDetails(
        userDetails.ClientID,
        userDetails.ParentUserID,
        userDetails.Role
      );
      if (dialingSetDetails?.data?.Status === "SUCCESS") {
        SetDialingSetList(dialingSetDetails.data.Data);
        SetDefaultDialingSetID(dialingSetDetails.data.DefaultDialingSet);
      }

      const phoneScriptDetails = await GettingPhoneScriptDropDownDetails(
        userDetails.ClientID,
        userDetails.ParentUserID,
        userDetails.Role
      );
      if (phoneScriptDetails?.data?.Status === "SUCCESS") {
        SetPhoneScriptDetails(phoneScriptDetails.data.Data);
      }

      const liveAnswerDisposition = await GettingLiveAnswerDispositionSet(
        userDetails.ClientID,
        userDetails.ParentUserID,
        userDetails.Role
      );
      if (liveAnswerDisposition?.data?.Status === "SUCCESS") {
        SetLiveAnswerDispositionID(liveAnswerDisposition.data.LiveAnswerSet);
        SetLiveAnswerDispositionSet(liveAnswerDisposition.data.Data);
      }
    };
    fetchData();
  }, [
    search,
    page,
    RowsPerPage,
    props.FilterParameters,
    props.IsApply,
    OwnerIDFinal,
    CheckOwner,
    props.UpdateYourList,
  ]);


  useEffect(() => {
    //document.getElementById("hideloding").style.display = "none";
    setPage(1)
  }, [
    props.FilterParameters,
    props.IsApply,
    //props.UpdateYourList,
  ]);

 

 
  //Get booked meeting list
  const BookedmeetingListGet = (CID, UID, IsApply, FilterParams, URole, PageNumber) => {
    SetIsLoading(true);
    var str_in = {
      page: PageNumber,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      UserID: UID,
      MeetingOwnerID: OwnerIDs,
      Checker: CheckOwner,
      IsApply: IsApply,
      BookMeetingFilterArray: FilterParams,
      Role: URole,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
      method: "POST",
      data: str_in,
    });
    rows1
      .then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
        SetIsLoading(false);
        setIsDataLoaded(true);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
        setIsDataLoaded(true);
      });
  };

  // Reset Filter
  const ResetFilter = () => {
    SetOwnerIDs([]);
    SetOwnerIDFinal([]);
    SetOwnerIDFinalRemove([])
    SetCheckOwner(false);
    SetIsLoading(false);
    setPage(1);
    document.getElementById("include_owner").checked = false
    props.ResetFilterMethod();
  };

  //  Advance Filter
  // const FilterResponder = () => {
  //   try {
  //     let OwnerDetails = document.getElementById("MeetingOwnerID1").value;
  //     let ClientCheckBox = document.getElementById("include_owner").checked;
  //     SetOwnerID(OwnerDetails);
  //     SetCheckOwner(ClientCheckBox);
  //     if (OwnerDetails == "" && CheckOwner == false) {
  //       setPage(1);
  //       SetIsLoading(false);
  //     } else if (OwnerID === OwnerDetails) {
  //       setPage(1);
  //       SetIsLoading(false);
  //     } else {
  //       setPage(1);
  //       SetIsLoading(true);
  //       // BookedmeetingListGet(ClientID, UserID, false, [], Role);
  //     }
  //   } catch (error) {
  //     toast.error("An error occurred. Please try again.");
  //     SetIsLoading(false);
  //   }
  // };
  const FilterResponder = () => {
    try {
      let ClientCheckBox = document.getElementById("include_owner").checked;
      SetCheckOwner(ClientCheckBox);
      SetOwnerIDFinal(OwnerIDs)
      setPage(1);
    } catch (error) {
      // toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  //OwnerData Drop down list
  const OwnerDropDown = (CId, URole) => {
    var InputParameters = {
      ClientID: CId,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingOwnerGet",
      method: "POST",
      data: InputParameters,
    });
    rows1
      .then((Result) => {
        const mappedOptions = Result.data?.Data.map((val) => {
          return {
            value: val.Name,
            label: `${val.Name}`,
          };
        });

        setMeetingOwner(mappedOptions);
        //setMeetingOwner(Result.data?.Data);
        setStatus(Result.data?.meetingstatus);
        setNotes(Result.data?.notesdetails);
        SetHeaderlist(Result.data?.meetingheader);
        // SetStatusData(Result.data.DataStatusGet);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  //search for record
  const RequestSearch = (Event) => {
    try {
      if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("search").value;
        if (SearchedVal == search) {
          setSearch(SearchedVal);
          setPage(1);
        } else {
          setSearch(SearchedVal);
          setPage(1);
        }
      }
    } catch (error) {
      toast.error("An error occurred while searching. Please try again.");
      SetIsLoading(false);
    }
  };

  //Delete booked meeting
  const DeleteBtn = (id) => {
    var SearchedVal = document.getElementById("search").value;
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete booked meeting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          SetIsLoading(true)
          var data = {
            _id: id,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: CUserID,
            Role: Role,
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL + "/bookedmeeting/DeleteBookedMeeting",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Booked meeting deleted successfully.",
                  "success"
                );
                if (SearchedVal === "") {
                  BookedmeetingListGet(ClientID, UserID, false, [], Role, 1);
                }
                setPage(1);
                setSearch("");
                props.updateFromChild(true);
                document.getElementById("search").value = "";
                // SetIsLoading(false)
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  //Pagination
  const HandleChangePage = (event, newPage) => {
    if (newPage == page) {
      setPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  //selection pagination
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  const Bookedstatusselect = (event, EventID, UserID, StatusIDs, EmailID) => {
    SetIsLoading(true);
    let MeetingIDs = null;
    MeetingIDs = event.target.value;
    //find the meeting status name by id
    var data = {
      ClientID: ClientID,
      MeetingStatusID: event.target.value,
      Role: Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/bookedmeeting/findBookedMeetingStatus",
      method: "POST",
      data: data,
    })
      .then((res) => {
        var data = {
          // _id: id,
          EventID: EventID,
          ClientID: ClientID,
          UserID: UserID,
          MeetingStatusID: res.data.BookedStatus[0]?._id,
          OldMeetingStatusID: StatusIDs,
          Status: res.data.BookedStatus[0]?.Status,
          Role: Role,
          StatusName: document.getElementById(`MeetingStatusID${EventID}`)
            .options[
            document.getElementById(`MeetingStatusID${EventID}`).selectedIndex
          ].textContent,
          Email: EmailID,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeeting/UpdateBookedMeetingStatusHistory",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            props.updateFromChild(true);
            BookedmeetingListGet(ClientID, UserID, false, [], Role, 1);
            toast.success(
              <div>
                Meeting <br />
                Status successfully changed.
              </div>
            );
          } else {
            toast.error(res.data.Message);
            SetIsLoading(false);
          }
        });
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  const viewpage = (id) => {
    history.push({
      pathname: "/viewbookedmeeting",
      state: { data: id, Pagename: "BookedMeeting" },
    });
  };

  const resetView = () => {
    BookedmeetingListGet();
    document.getElementById("resetview").style.display = "none";
  };

  //get sort field data
  const SortData = (FieldName) => {
    try {
      setSortedColumn(FieldName);
      SetIsLoading(true);
      const searchedVal = document.getElementById("search").value;
      const isNewSortField = FieldName !== sortField;
      let SortBy = sortedBy === 1 ? -1 : 1;

      if (isNewSortField) {
        setsortedBy(1);
        SortBy = 1;
      } else {
        setsortedBy(SortBy);
      }

      setsortField(FieldName);
      setsflag(searchedVal !== "");

      let Field
      if (FieldName == "Title") {
        Field = "JobTitle"
      } else if (FieldName == "MeetingSetDate") {
        Field = "EventCreatedDate1"
      } else if (FieldName == "MeetingDate") {
        Field = "EventStartDt"
      } else if (FieldName == "CreatedDate") {
        Field = "CreatedDt"
      } else if (FieldName == "MeetingStatus") {
        Field = "Status"
      } else if (FieldName == "Owner") {
        Field = "MeetingOwner"
      } else if (FieldName == "SendReminder") {
        Field = "IsSendMeetingReminder"
      } else if (FieldName == "QualifiedDate") {
        Field = "QulifiedDate"
      } else if (FieldName == "MeetingSetBy") {
        Field = "ResponserName"
      } else {
        Field = FieldName
      }

      var InputParameter = {
        page: page,
        rowsPerPage: RowsPerPage,
        sort: true,
        field: Field,
        sortby: SortBy,
        search: searchedVal,
        type: "User",
        ClientID: ClientID,
        UserID: UserID,
        MeetingOwnerID: OwnerIDs,
        Checker: CheckOwner,
        Role: Role,
        IsApply: props.IsApply,
        BookMeetingFilterArray: props.FilterParameters,
      };
      const UserGetList = Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
        method: "POST",
        data: InputParameter,
      });
      UserGetList.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
        SetIsLoading(false);
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });

    } catch (error) {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  const EditAccountHandle = (id) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewaccount",
        state: { data: id, Pagename: "BookedMeeting" },
      });
    }
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      // history.push({
      //   pathname: "/viewcontacts",
      //   state: { data: id, EmailData: Email, Pagename: "BookedMeeting" },
      // });
      const url = `/viewcontacts?id=${id}&email=${encodeURIComponent(Email)}&pagename=BookedMeeting`;
      window.open(url, '_blank');
    }
  };

  const handleOwnerChange = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions?.map(option => option.value) : [];
    SetOwnerIDs(selectedValues);
    SetOwnerIDFinalRemove(selectedOptions)
  };

  //dialer
 
  const OpenDialPopup = async (e, data) => {
    try{
      SetIsLoading(true);
     let ColdCallerUserID = "", UserID = "", ClientID = "";
    let Details = GetUserDetails();
    if (Details != null) {
      ColdCallerUserID = Details.ChildUserID;
      UserID = Details.ParentUserID;
      ClientID = Details.ClientID;
    }

    let RequestSdrBody = {
      UserID: UserID,
      ClientID: ClientID,
      ColdCallerUserID: ColdCallerUserID,
      Role: "BookMeeting",
      ContactList: data,
      ProspectID: data?.Prospectid,
      SelectedPhoneNumber:checkeddata
    };
    const result = await CallBtn(RequestSdrBody);
    if(result.DialSessionData.StatusMessage=="SUCCESS"){
    
    const { DialSessionData, contactData } = result;
    
    const newWindow = window.open("", "_blank", "width=1000,height=1000,resizable,scrollbars");
      if (newWindow) {
        newWindow.document.write('<div id="popup-root"></div>');
        newWindow.document.close();
  
        const cssFiles = [
          '/assets/css/bootstrap.min.css',
          '/assets/css/font-style.css',
          '/assets/css/site.css',
          '/assets/css/twilio.css',
          '/assets/css/bootstrap.bundle.min',
          'https://cdnjs.cloudflare.com/ajax/libs/froala-editor/4.0.15/css/froala_editor.pkgd.min.css',
          'https://cdnjs.cloudflare.com/ajax/libs/froala-editor/4.0.15/css/froala_style.min.css',
          'https://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js'
      ];

          cssFiles.forEach(cssFile => {
            const link = newWindow.document.createElement('link');
            link.rel = 'stylesheet';
            link.href = cssFile; // Use the individual CSS file path here
            newWindow.document.head.appendChild(link);
        });              

        const LoggedUserDetails = {
          UserID: Details.ParentUserID,
          ClientID: Details.ClientID,
          ColdCallerUserID: ColdCallerUserID,
          ColdCallersName: `${DialSessionData?.ColdCallersDetails?.ColdCallersName} ${DialSessionData?.ColdCallersDetails?.ColdCallersName.LastName}`,
        };
        SetIsLoading(false);
        ReactDOM.render(
          <CallDailSetting
            windowObject={newWindow}
            DialSessionData={DialSessionData}
            checkeddata={checkeddata}
            LoggedUserDetails={LoggedUserDetails}
            PhoneArr={DialSessionData?.PhoneArr || []}
            ReloadContactPage={ReloadContactPage}
            DialingSetList={DialingSetList}
            DefaultDialingSetID={DefaultDialingSetID}
            PhoneScriptDetails={PhoneScriptDetails}
            LiveAnswerDispositionSet={LiveAnswerDispositionSet}
            DefaultLiveAnswerDispositionID={DefaultLiveAnswerDispositionID}
          />,
          newWindow.document.getElementById("popup-root")
        );
      }
    }
    SetIsLoading(false);
  }catch (error) {
    toast.error(<div className="toastsize">{error.message}</div>);
    SetIsLoading(false);
  }
    
  }
  const ReloadContactPage = () => {
    location.reload();
  }

  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex align-items-center justify-content-end pb-3">
        <div className="d-flex mx-1">
          <div className="mx-1">
            <label className="labeltexts my-2">Owner</label>
          </div>
          <div className=" w-200px">
            <Select
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              options={meeting}
              id="MeetingOwnerID1"
              placeholder={"--Select--"}
              isMulti
              onChange={handleOwnerChange}
              value={OwnerIDFinalRemove}
            />
          </div>
        </div>
        <div className="d-flex mx-1">
          <div className="mx-1">
            <div className="boxborders">
              <label className="check_bx">
                <input id="include_owner" type="checkbox" />
                <span className="checkmark"></span>
                Open Only
              </label>
            </div>
          </div>
          <div className="">
            <button
              onClick={FilterResponder}
              className="btn btn-primary btnColor mx-2"
            >
              Search
            </button>
            <button onClick={ResetFilter} className="btn btn-secondary">
              <i className="la la-refresh"></i> Reset
            </button>
          </div>
        </div>
      </div>

      {/* <div className="borderboxstp"></div> */}

      <div className="row">
        <div className="col-sm-12" id="resetview" style={{ display: "none" }}>
          <a
            onClick={() => {
              resetView();
            }}
          >
            Reset
          </a>
        </div>
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <StyleHeader isDataLoaded={isDataLoaded} />
          <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Notes</TableCell>
                <TableCell>Action</TableCell>
                {Role == "ColdCallers" ?
                  <TableCell></TableCell>
                  : null}

                {HeaderList?.map((row) => (
                  <TableCell key={row._id} onClick={() => {
                    SortData(row.ColumnName.replace(/\s+/g, ""));
                    setSortedColumn(row.ColumnName); // Set the sorted column
                  }}>
                    {row.ColumnName}
                    <span className="shorting">
                      <ArrowUpward className={sortedBy === 1 && sortedColumn === row.ColumnName ? "active" : null} />
                      <ArrowDownward className={sortedBy === -1 && sortedColumn === row.ColumnName ? "active" : null} />
                    </span>
                  </TableCell>
                ))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                rows?.map((value1) => (
                  <React.Fragment key={value1._id}>
                    <TableRow>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            SetOpen((prev) => ({
                              ...prev,
                              [value1._id]: !prev[value1._id],
                            }))
                          }
                        >
                          {Open[value1._id] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a
                          href={`/viewbookedmeeting?ID=${value1?._id}&pagename=""`}
                          onClick={(e) => {
                            e.preventDefault();
                            viewpage(value1?._id);
                          }}
                          className="btn-eyesicon"
                          title="Edit"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                      </TableCell>
                      {Role == "ColdCallers" ?
                        <TableCell><IconButton aria-label="expand row" size="small" onClick={(e) => OpenDialPopup(e, value1)} ><i class="fa fa-phone-square col-white phoneicons"></i></IconButton></TableCell>
                        : null}
                      {HeaderList.map((row) => {
                        if (row.ColumnName == "Title") {
                          return <TableCell>{value1?.Title}</TableCell>;
                        } else if (row.ColumnName == "Meeting Date") {
                          return (
                            <TableCell>
                              {value1?.EventStartDate1 != null && value1?.EventStartDate1 !== "Invalid date"
                                ? value1?.EventStartDate1 + " " + value1?.TimeZoneName || ""
                                : ""}
                            </TableCell>
                          );
                        } else if (row?.ColumnName == "Domain") {
                          return (
                            <TableCell>
                              <a
                                href={`http://${value1?.Email?.substring(
                                  value1?.Email?.lastIndexOf("@") + 1
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}
                              >
                                <u>
                                  {" "}
                                  {value1?.Email?.substring(
                                    value1?.Email?.lastIndexOf("@") + 1
                                  )}
                                </u>
                              </a>
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Opportunity Date") {
                          return (
                            <TableCell>
                              {value1?.OpportunityDate != null
                                ? Moment(
                                  value1?.OpportunityDate
                                ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Meeting Set Date") {
                          return (
                            <TableCell>
                              {value1?.EventCreatedDt1 != null && value1?.EventCreatedDt1 != "Invalid date"
                                ? value1?.EventCreatedDt1
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Created Date") {
                          return (
                            <TableCell>
                              {value1?.CreatedDt != null
                                ? Moment(value1?.CreatedDt).format(
                                  "MM/DD/YYYY"
                                )
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "LinkedIn Url") {
                          return <TableCell>{value1?.LinkedInUrl}</TableCell>;
                        } else if (
                          row.ColumnName == "Last Status Change Date"
                        ) {
                          return (
                            <TableCell>
                              {value1?.LastStatusChangeDate != null
                                ? Moment(value1?.LastStatusChangeDate).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Industry") {
                          return <TableCell>{value1?.Industry}</TableCell>;
                        } else if (row.ColumnName == "Direct Phone") {
                          return <TableCell>{value1?.DirectPhone}</TableCell>;
                        } else if (row.ColumnName == "Meeting Set By") {
                          return (
                            <TableCell>
                              {value1?.ResponderColdCallersType == "R"
                                ? value1?.ResponserName
                                : value1?.ResponserName}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Meeting Status") {
                          return (
                            <TableCell>
                              {
                                <select
                                  onChange={(e) => {
                                    Bookedstatusselect(
                                      e,
                                      value1?._id,
                                      value1?.UserID,
                                      value1?.StatusID,
                                      value1?.Email
                                    );
                                  }}
                                  className="form-control  m-input w-auto"
                                  id={`MeetingStatusID${value1?._id}`}
                                  name="MeetingStatusID"
                                  autocomplete="off"
                                  value={value1?.StatusID}
                                >
                                  {status.map((value) => (
                                    <option value={value?._id}>
                                      {value?.Status}
                                    </option>
                                  ))}
                                </select>
                              }
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Company") {
                          return <TableCell>{value1?.Company}</TableCell>;
                        } else if (row.ColumnName == "Contact Name") {
                          return (
                            <TableCell>
                              <a
                                href={`/viewcontacts?id=${value1?.Prospectid}&email=${encodeURIComponent(value1?.Email)}&pagename=BookedMeeting`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  EditContactHandle(value1?.Prospectid, value1?.Email);
                                }}
                              >
                                <u>{value1?.ContactName}</u>
                              </a>
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Days In Stage") {
                          return (
                            <TableCell>
                              {value1?.DaysInStage &&
                                Math.floor(value1.DaysInStage)}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Company Phone") {
                          return <TableCell>{value1?.CompanyPhone}</TableCell>;
                        } else if (row.ColumnName == "Closed Won Date") {
                          return (
                            <TableCell>
                              {value1?.ClosedWonDate != null
                                ? Moment(
                                  value1?.ClosedWonDate
                                ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Unqualified Date") {
                          return (
                            <TableCell>
                              {value1?.UnqualifiedDate != null
                                ? Moment(
                                  value1?.UnqualifiedDate
                                ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Contract Value") {
                          return <TableCell>{value1?.ContractValue}</TableCell>;
                        } else if (row.ColumnName == "Email") {
                          return <TableCell>{value1?.Email}</TableCell>;
                        } else if (row.ColumnName == "Meeting Status Type") {
                          return (
                            <TableCell>{value1?.MeetingStatusType}</TableCell>
                          );
                        } else if (row.ColumnName == "Send Reminder") {
                          return (
                            <TableCell>
                              {value1?.IsSendMeetingReminder === true
                                ? "Yes"
                                : "No"}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Seniority") {
                          return <TableCell>{value1?.Seniority}</TableCell>;
                        } else if (row.ColumnName == "Meeting Source") {
                          return <TableCell>{value1?.MeetingSource}</TableCell>;
                        } else if (row.ColumnName == "Account Name") {
                          return (
                            <TableCell>
                              <a
                                href={`/viewaccount?id=${value1?.AccountNameID}&pagename=BookedMeeting`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  EditAccountHandle(value1?.AccountNameID);
                                }}
                              >
                                <u>
                                  {value1?.AccountName == undefined || value1?.AccountName == ""
                                    ? ""
                                    : value1?.AccountName}
                                </u>
                              </a>
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Owner") {
                          return <TableCell>{value1?.MeetingOwner}</TableCell>;
                        } else if (row.ColumnName == "Qualified Date") {
                          return (
                            <TableCell>
                              {value1?.QulifiedDate != null
                                ? Moment(value1?.QulifiedDate).format(
                                  "MM/DD/YYYY"
                                )
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Closed Lost Date") {
                          return (
                            <TableCell>
                              {value1?.ClosedLostDate != null
                                ? Moment(
                                  value1?.ClosedLostDate
                                ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Last Email Step") {
                          return (
                            <TableCell>
                              {value1?.LastEmailStep}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Last Email Campaign") {
                          return (
                            <TableCell>
                              {value1?.LastEmailCampaign}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Contact Source") {
                          return (
                            <TableCell>
                              {value1?.ContactSource}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Mobile Phone") {
                          return (
                            <TableCell>
                              {value1?.MobilePhone}
                            </TableCell>
                          );
                        }else if (row.ColumnName == "Reminder Email Account") {
                          return (
                            <TableCell>
                              {value1?.ReminderEmailAccount}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Direct Phone Ext") {
                          return (
                            <TableCell>{value1?.DirectPhoneExt}</TableCell>
                          );
                        } else if (row.ColumnName == "Website") {
                          return <TableCell><a href={`http://${value1?.Website}`} target="_blank" rel="noopener noreferrer"><u>{value1?.Website}</u></a></TableCell>
                        } else if (
                          row.CustomFieldID != null &&
                          row.CustomFieldID != ""
                        ) {
                          let valcust = value1.CustomField?.filter(
                            (item) => item.CustomFieldID == row?.CustomFieldID
                          );
                          if (valcust?.length > 0) {
                            return (
                              <TableCell>{valcust[0]?.Value}</TableCell>
                            );
                          } else {
                            return <TableCell></TableCell>;
                          }
                        } else {
                          return <TableCell></TableCell>;
                        }
                      })}

                      <TableCell>
                        <a
                          href={`/viewbookedmeeting?ID=${value1?._id}&pagename=""`}
                          onClick={(e) => {
                            e.preventDefault();
                            viewpage(value1?._id);
                          }}
                          className="btn-eyesicon"
                          title="Edit"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>

                        <a
                          onClick={() => {
                            DeleteBtn(value1?._id);
                          }}
                          className="btn-eyesicon"
                          title="Delete"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={10}
                      >
                        <Collapse
                          in={Open[value1?._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[value1?._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {notes.map((note) =>
                                  note?.MeetingID == value1?._id ? (
                                    <TableRow key={note._id}>
                                      <TableCell className="font-weight-bold">
                                        {note?.Title}
                                      </TableCell>
                                      <TableCell className="labeltds font-weight-bold">
                                        {Moment(
                                          new Date(
                                            note?.CreatedDate
                                          ).toDateString()
                                        ).format("MM/DD/YYYY")}
                                      </TableCell>
                                      <TableCell className="notes" scope="row">
                                        {note?.Note}
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!notes.some(
                                  (note) => note?.MeetingID == value1?._id
                                ) && (
                                    <TableRow>
                                      <TableCell colSpan={4}>
                                        No data available
                                      </TableCell>
                                    </TableRow>
                                  )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* {sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={countpage}
            onChange={HandleChangePage}
            page={page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : ( */}
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
            {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={countpage}
            onChange={HandleChangePage}
            page={page}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
      {/* )} */}
    </>
  );
}
