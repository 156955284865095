import React, { useState, useEffect } from 'react';
import Axios from "axios";
import { toast } from "react-toastify";
const moment = require("moment");
import loadingicon from "../../../images/loading.gif";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

const ArticleCategories = (searchParam) => {
    const [CategoryList, setCategoryList] = useState([]);
    const [isVisibleDetailPage, setIsVisibleDetailPage] = useState(true);
    const [isVisibleListingPage, setIsVisibleListingPage] = useState(true);
    const [CategoryId, SetCategoryId] = React.useState("");
    const [Page, SetPage] = React.useState(1);
    const [RowsPerPage, SetRowsPerPage] = React.useState(5);
    const [Search, SetSearch] = React.useState("");
    const [SortField, SetSortField] = React.useState("CreatedDate");
    const [SortedBy, SetSortedBy] = React.useState(-1);
    const [IsLoading, SetIsLoading] = React.useState(false);
    const [ArticleList, setArticleList] = useState([]);
    const [TotalCount, setTotalCount] = useState(0);
    const [TotalPage, setTotalPage] = useState(0);
    const [IsCategorySearch, setIsCategorySearch] = useState(false);
    const [IsPageChange, SetIsPageChange] = React.useState(false);
    const [IsRole, SetIsRole] = React.useState("");
    // const [open, setOpen] = React.useState(true);

    // const handleClick = () => {
    //   setOpen(!open);
    // };

    useEffect(() => {
        let isMounted = true;    
        const fetchInitialData = async () => {
            try {
                const [catList] = await Promise.all([getCategories()]);
                if (!isMounted) return;
    
                let catId = '';
                let srchText = '';
    
                if (!IsCategorySearch) {
                    setIsCategorySearch(searchParam.searchParam.isCategorySearch);
    
                    if (!searchParam.searchParam.isCategorySearch) {
                        if (searchParam.searchParam.categoryId) {
                            catId = searchParam.searchParam.categoryId;
                            srchText = '';
                        }
    
                        if (searchParam.searchParam.searchText) {
                            srchText = searchParam.searchParam.searchText;
                            catId = '';
                        }
                    } else {
                        catId = CategoryId;
                    }
                } else {
                    catId = CategoryId;
                }
    
                const page = IsPageChange ? Page : 1;
                await getArticles(catId, srchText, page);
    
                setIsVisibleDetailPage(false);
    
                const Details = await GetUserDetails();
                if (Details) {
                    SetIsRole(Details.Role);
                }
            } catch (error) {
                if (isMounted) {
                    toast.error(error.message);
                }
            }
        };
    
        fetchInitialData();
        return () => {
            isMounted = false;
        };
    }, [searchParam, Page]);

    useEffect(() => {
    }, [CategoryId, Search, ArticleList, isVisibleDetailPage, isVisibleListingPage, IsCategorySearch, IsPageChange]);

    const getCategories = async () => {
        try {
            const response = await Axios({
                url: CommonConstants.MOL_APIURL + "/knowledge_base/fetchCategories",
                method: "GET"
            });
            if (response.data && response.data.StatusMessage == "SUCCESS") {
                const categoryData = response.data.Data;
                if (categoryData.length > 0) {
                    const categoryList = categoryData.map((val) => {
                        return { value: val._id, label: val.FieldName, cid: val._id };
                    });
                    setCategoryList(categoryList);
                }
            }
            else {
                toast.error(response.data.Message);
            }
        }
        catch (error) {
            toast.error(error.message);
        }
    }

    const getArticles = async (CategoryId, Search, page) => {
        SetIsLoading(true);
        try {
            SetCategoryId(CategoryId);
            SetSearch(Search);
            SetPage(page);
            let InputParameter = {
                categoryId: CategoryId,
                page: page,
                rowsPerPage: RowsPerPage,
                sort: true,
                sortField: SortField,
                sortBy: SortedBy,
                search: Search,
            };
            const response = await Axios({
                url: CommonConstants.MOL_APIURL + "/knowledge_base/GetAllArticles",
                method: "POST",
                data: InputParameter,
            });
            if (response.data && response.data.StatusMessage == "SUCCESS") {
                const articleData = response.data.Data;
                setArticleList(articleData);
                setTotalCount(response.data.TotalArticles);
                setTotalPage(response.data.TotalPages);
                setIsVisibleListingPage(true);
                setIsVisibleDetailPage(false);
                SetIsPageChange(false);
            }
            else {
                toast.error(response.data.Message);
            }
            SetIsLoading(false);
        }
        catch (error) {
            SetIsLoading(false);
            toast.error(error.message);
        }
    }

    const handlePageChange = (event, value) => {
        SetPage(value);
        SetIsPageChange(true);
    };

    const getArticlesByCategory = async (catId) => {
        SetIsLoading(true);
        try {
            document.getElementById('searchtext').value = "";
            setIsCategorySearch(true);
            SetCategoryId(catId);
            SetSearch("");
            SetPage(1);
            await getArticles(catId, "", 1);
            setIsVisibleListingPage(true);
            setIsVisibleDetailPage(false);
            SetIsLoading(false);
        }
        catch (error) {
            SetIsLoading(false);
            toast.error(error.message);
        }
    };

    const getArticlesDetails = async (articleId) => {
        SetIsLoading(true);
        try {
            let InputParameter = {
                articleId: articleId
            };
            const response = await Axios({
                url: CommonConstants.MOL_APIURL + "/knowledge_base/GetArticleDetail",
                method: "POST",
                data: InputParameter,
            });
            if (response.data && response.data.StatusMessage == "SUCCESS") {
                const articleData = response.data.Data;
                    setArticleList(articleData);
                    setIsVisibleListingPage(false);
                    setIsVisibleDetailPage(true);
            }
            else {
                toast.error(response.data.Message);
            }
            SetIsLoading(false);
        }
        catch (error) {
            SetIsLoading(false);
            toast.error(error.message);
        }
    };

    const handleBack = async () => {
        getArticles(CategoryId, Search, Page);
        setIsVisibleListingPage(true);
        setIsVisibleDetailPage(false);
    }

    const editArticle = async (articleId) => {
        if(IsRole === "Admin"){
            history.push("/editarticle", articleId);
        }
        if(IsRole === "Client"){
            history.push("/ceditarticle", articleId);
        }
    }

    const handleDelete = async (articleId) => {
        SetIsLoading(true);
        try {
            let InputParameter = {
                articleId: articleId
            };
            const response = await Axios({
                url: CommonConstants.MOL_APIURL + "/knowledge_base/DeleteArticle",
                method: "POST",
                data: InputParameter,
            });
            if (response.data && response.data.StatusMessage == "SUCCESS") {
                toast.success(response.data.Message);
                handleBack();
            }
            else {
                toast.error(response.data.Message);
            }
            SetIsLoading(false);
        }
        catch (error) {
            SetIsLoading(false);
            toast.error(error.message);
        }
    }

    return (
        <>
            {
                IsLoading === true ? <div id="hideloding" className="loding-display">
                    <img src={loadingicon} />
                </div> : null
            }
            <div>
                {/* <div className='header-Knowledge pt-5 pb-3 mt-3'>
                    <div className='maxwidth-header'>
                        <h1>Hello. How can we help you?</h1>
                        
                        <div className='input-boxserch'>
                            <svg width='22px' viewBox="0 0 12 13"><g stroke-width="2" stroke="#999999" fill="none"><path d="M11.29 11.71l-4-4"/><circle cx="5" cy="5" r="4"/></g></svg>
                            <form>
                                <input
                                    type="text"
                                    // value={inputValue}
                                    // onChange={(e) => setInputValue(e.target.value)}
                                    class="form-control m-input" 
                                    placeholder="Search articles"
                                />
                                {/* <button type="submit">Submit</button> 
                            </form>
                        </div>
                    </div>
                </div> */}
                <div class="mt-4 max-widthcar blog-cardbox">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid md={3}>
                                <div className='list-blog p-3'>
                                    <List>
                                        <ListItem
                                            className={CategoryId == "" ? 'selected-art' : ''}
                                            disablePadding>
                                            <ListItemButton onClick={() => getArticlesByCategory("")}>
                                                <ListItemText primary="All" />
                                            </ListItemButton>
                                        </ListItem>
                                        {CategoryList?.map((row) => (
                                            <ListItem
                                                className={CategoryId != "" && CategoryId == row.cid ? 'selected-art' : ''}
                                                disablePadding>
                                                <ListItemButton
                                                    onClick={() => getArticlesByCategory(row.cid)}>
                                                    <ListItemText primary={row?.label} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}

                                        {/* <ListItemButton  onClick={handleClick}> 
                                                <ListItemText primary="Inbox" />
                                                {open ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <List component="div">
                                                <ListItemButton> 
                                                    <ListItemText primary="Starred" />
                                                </ListItemButton>
                                                <ListItemButton> 
                                                    <ListItemText primary="Starred 1" />
                                                </ListItemButton>
                                                <ListItemButton> 
                                                    <ListItemText primary="Starred 2" />
                                                </ListItemButton>
                                                <ListItemButton> 
                                                    <ListItemText primary="Starred 3" />
                                                </ListItemButton> 
                                            </List>
                                        </Collapse>  */}
                                    </List>
                                </div>
                            </Grid>
                            {ArticleList.length === 0 ? (
                                <Grid md={9} container spacing={2}>
                                    <div className="listbox w-100">
                                        <Grid item xs={12} md={12} >
                                            <Card sx={{ minWidth: 300, flexGrow: 1 }}>
                                                <p className="text-center mt-3">No data available in table</p>
                                            </Card>
                                        </Grid>
                                    </div>
                                </Grid>
                            ) : (
                                <Grid md={9} container spacing={2}>
                                    <div
                                        className="listbox w-100"
                                        id="article-list"
                                        style={{ display: isVisibleListingPage ? "block" : "none" }}
                                    >
                                        {/* <div className="w-100 button-right">  
                                            <div className="listing-li float-right">
                                                <ul>
                                                    <li className='px-0'>
                                                        <a href="javascript:void(0);" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                                        <i class="la la-icon-font-size-13 la-plus"></i>
                                                            <span>Add</span>
                                                        </a>
                                                    </li>
                                                </ul>  
                                            </div>
                                        </div> */}

                                        {/* <Grid item xs={12} md={12} >  
                                            <a className="back-btn">
                                            <svg width={'10px'} style={{marginRight: '10px'}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                            </svg>
                                             Back</a>
                                        </Grid> */}

                                        {ArticleList.map((row) => {
                                            const titleSentences = row.ArticleTitle.slice(0, 50) + (row.ArticleTitle.length > 50 ? '...' : '');
                                            const sentences = row.TextField.slice(0, 400) + (row.TextField.length > 400 ? '...' : '');
                                            return (
                                                <Grid item xs={12} md={12} >
                                                    <Card sx={{ minWidth: 300, flexGrow: 1 }}>
                                                        <CardContent >
                                                            <span className='date-text'>
                                                                {moment(
                                                                    new Date(row.CreatedDate).toDateString()
                                                                ).format("MM/DD/YYYY")}
                                                            </span>
                                                            <Typography gutterBottom variant="h5" component="div" onClick={() => getArticlesDetails(row._id)}>
                                                                {titleSentences}
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                <div dangerouslySetInnerHTML={{ __html: sentences }} />
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            );
                                        })}

                                        <div className="row px-3">
                                            <div className="col dataTables_info">
                                                <p>
                                                    Showing {(Page - 1) * RowsPerPage + 1} to{" "}
                                                    {Math.min(Page * RowsPerPage, TotalCount)} of {TotalCount} entries
                                                </p>
                                            </div>
                                            <div className="col pageright">
                                                <Pagination
                                                    count={TotalPage}
                                                    page={Page}
                                                    onChange={handlePageChange}
                                                    showFirstButton
                                                    showLastButton
                                                    color="primary"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="listbox my-3 w-100"
                                        id="article-detail"
                                        style={{ display: isVisibleDetailPage ? "block" : "none" }}
                                    >
                                        {/* <div className="w-100 button-right">  
                                            <div className="listing-li float-right">
                                                <ul>
                                                    <li className='px-0'>
                                                        <a href="javascript:void(0);" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                                        <i class="la la-icon-font-size-13 la-plus"></i>
                                                            <span>Add</span>
                                                        </a>
                                                    </li>
                                                </ul>  
                                            </div>
                                        </div> */}

                                        <Grid item xs={12} md={12}>
                                            <a className="back-btn" onClick={handleBack}>
                                                <svg width={'10px'} style={{ marginRight: '10px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                                </svg>
                                                Back</a>
                                        </Grid>

                                        {ArticleList.map((row) => {
                                            const titleSentences = row.ArticleTitle;
                                            const sentences = row.TextField;
                                            return (
                                                <Grid item xs={12} md={12} >
                                                    <Card>
                                                        <CardContent>
                                                            <div className='d-flex flex-direction-row-reverse align-items-center gap-20 mb-3'>
                                                                {IsRole === "Admin" && (
                                                                    <>
                                                                        <div className='iconsleft-bord d-flex'>
                                                                        <span className='edit-text-icon mr-3'>
                                                                            <a href='javascript:void(0);' onClick={() => editArticle(row._id)} title='Edit'>
                                                                            <svg width={'18px'} height={'18px'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                                            </svg>

                                                                            </a>
                                                                        </span>
                                                                        <span className='delete-text-icon'>
                                                                            <a href='javascript:void(0);' onClick={() => handleDelete(row._id)} title='Delete'> 
                                                                            {/* <i class="la flaticon-delete-1"></i> */}
                                                                                <svg width={'18px'} height={'18px'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                                </svg>
                                                                            </a>
                                                                        </span>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <span className='date-text'>
                                                                    {moment(
                                                                        new Date(row.CreatedDate).toDateString()
                                                                    ).format("MM/DD/YYYY")}
                                                                </span>
                                                            </div>
                                                            <Typography gutterBottom variant="h5" component="div">
                                                                {titleSentences}
                                                            </Typography>
                                                            <Typography className='article-body' variant="body2" sx={{ color: 'text.secondary' }}>
                                                                <div dangerouslySetInnerHTML={{ __html: sentences }} />
                                                                {
                                                                    row?.VideoUrl && row.VideoUrl.trim() !== "" && (
                                                                        <div>
                                                                            <iframe
                                                                                width="100%"
                                                                                height="300px"
                                                                                src={row.VideoUrl}
                                                                                title="YouTube video player"
                                                                                frameBorder="0"
                                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                allowFullScreen
                                                                            ></iframe>
                                                                        </div>
                                                                    )
                                                                }
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            );
                                        })}
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </div>
            </div>
        </>
    );
};

export default ArticleCategories;