import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import DNCHistory from "../../_components/user/DNCHistory/DNChistory";
import {
  getFormattedFirstDayOfQuarterUTC,
  getFormattedFirstDayOfQuarterUTCSecond,
  getFormattedFirstDayOfQuarterUTCIntialCurrent,
} from "../../_helpers/Utility";
import $ from "jquery";
import { toast } from "react-toastify";

class DNCHistoryPage extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date(moment());
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    this.state = {
      FromDate: getFormattedFirstDayOfQuarterUTCSecond(),
      ToDate: getFormattedFirstDayOfQuarterUTCIntialCurrent(),
    };

    this.SaveBtn = this.SaveBtn.bind(this);
    this.daterefect = React.createRef();
    this.daterefect2 = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("storage", (event) => {
      if (event.key === "clientChanged") {
        window.location.reload();
      }
    });

    // Format the date
    const formattedDate = getFormattedFirstDayOfQuarterUTC();

    // Set the value of the "from-date" input field
    document.getElementById("from-date").value = formattedDate;
    const $datepickerElement = $(this.daterefect.current);
    $datepickerElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });
    document.getElementById("to-date").value = new Date().toLocaleDateString(
      "en-US",
      { month: "2-digit", day: "2-digit", year: "numeric" }
    );
    const $datepickerElement2 = $(this.daterefect2.current);
    $datepickerElement2.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    var FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    var ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");
    this.setState({ FromDate: FromDate, ToDate: ToDate });
  }

  SaveBtn() {
    var FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    var ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");

    if (moment(FromDate, "YYYY-MM-DD").isAfter(moment(ToDate, "YYYY-MM-DD"))) {
      toast.error("Please select an appropriate date range");
    } else {
      this.setState({ FromDate: FromDate, ToDate: ToDate });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row align-items-center py-4">
              <div className="col">
                <h4 className="lg-headertitle float-left  my-0">DNC.com History</h4>
              </div>
              <div className="col">
                <div className="btn-group datepicker-hed">
                  <input id="fromtab" name="fromtab" type="hidden" value="" />
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control datepicker"
                      id="from-date"
                      name="from-date"
                      type="text"
                      ref={this.daterefect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control datepicker"
                      id="to-date"
                      name="to-date"
                      type="text"
                      ref={this.daterefect2}
                      autoComplete="off"
                    />
                  </div>
                  <button
                    id="aCompanyState"
                    className="btn btn-primary btnColor btn-radius"
                    onClick={this.SaveBtn}
                    autoComplete="off"
                  >
                    <i className="la flaticon-diagram"></i>
                    <span>Analyze</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row bg-white mx-0 pb-2">
              <div className="col">
                <DNCHistory
                  ToDate={this.state.ToDate}
                  FromDate={this.state.FromDate}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedDNCHistoryPage = connect(
  mapState,
  actionCreators
)(DNCHistoryPage);
export { connectedDNCHistoryPage as DNCHistoryPage };
