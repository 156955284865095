import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));
const SortableList = SortableContainer(({ Items }) => {
  return (
    <ul>
      {Items.map((value, Index) => (
        <SortableItem
          id={Index}
          key={`item-${Index}`}
          index={Index}
          value={value.Name}
        />
      ))}
    </ul>
  );
});
class ContactSourceSetOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: [],
      ClientID: null,
      UserID: null,
      Role:null,
      BtnDisabled:false,
      IsLoading:true
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.BackBtn = this.BackBtn.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title="Contact Source Order | SalesHive"
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.Role=Details.Role
    }
    this.ContactSourceOrderGet();
  }
  ContactSourceOrderGet() {
    var str_in = {
      Page: 1,
      RowsPerPage: 100,
      Field: "OrderBy",
      SortBy: 1,
      Type: "User",
      ClientID: this.state.ClientID,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/contactsource/ContactSourceGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      this.setState({ Items: result.data.PageData });
      this.setState({IsLoading:false})
    });
  }

  // on sort end
  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      Items: arrayMoveImmutable(this.state.Items, oldIndex, newIndex),
    });
  };

  // save button
  SaveBtn() {
    this.setState({BtnDisabled:true})
    let OrderBy = 0;
    for (let i = 0; i < this.state.Items.length; i++) {
      this.state.Items[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.SaveOrderData();
  }

  // save order data
  SaveOrderData() {
    this.setState({BtnDisabled:true})
    this.setState({IsLoading:true})
    let str_in = {
      ContactSourceList: this.state.Items,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/contactsource/ContactSourceSetOrder",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        toast.success(<div className="toastsize" >Contact source<br/>Contact source order updated successfully.</div>);
        history.push("/contactsource");
        this.setState({IsLoading:false})
      } else {
        toast.error(res.data.Message);
        this.setState({BtnDisabled:false})
        this.setState({IsLoading:false})
      }
      this.ContactSourceOrderGet();
    });
  }

  // back button
  BackBtn() {
    history.push("/contactsource");
  }

  render() {
    return (
      <>
       {
        this.state.IsLoading === true ?  <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
      }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
              <div className="row pt-4 pb-3">
                <div className="col px-3">
                  <h4 className="headertitlenop float-left px-1">
                    Contact Source Order
                  </h4>
                </div>
              </div>
            <div class="whiterow">
              <div className="row pt-4 px-4 pb-3">
                <div className="col-xl-12 offset-xl-12 pt-1 px-3 mt-1">
                  <span className="alertinfo mx-1">
                    &nbsp;<b>NOTE</b> : Please drag &amp; drop item to change
                    order of contact source and press save button.
                  </span>

                  <div class="pt-4 px-1">
                    <SortableList
                      Items={this.state.Items}
                      onSortEnd={this.OnSortEnd}
                    />
                  </div>
                </div>
              </div>
            </div>

              <div class="row py-3">
                <div class="pull-left px-3 pt-2 pb-2">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={() => {
                      this.SaveBtn();
                    }}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save order
                  </button>
                  <a
                    id="backtolist"
                    onClick={() => {
                      this.BackBtn();
                    }}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer /> 
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedContactSourceSetOrder = connect(
  mapState,
  actionCreators
)(ContactSourceSetOrder);
export { connectedContactSourceSetOrder as ContactSourceSetOrder };
