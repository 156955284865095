import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import loadingicon from "../../../images/loading.gif";
import { CommonConstants, COLORS } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import ReactApexchart from "react-apexcharts";
import { GetClientDetails } from "../../../_helpers/Utility";




export default function Contactsdetailstable({ CampaignIDforEmail,FromDates,ToDates}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CampaignName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [FromDate, SetFromDate] = React.useState("");
  const [ToDate, SetToDate] = React.useState("");
  const [CampaignID, SetCampaignID] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [Counts, SetCounts] = React.useState({});
  const [CountGraph, SetCountGraph] = React.useState([]);
  const [SendCountCard, SetSendCountCard] = React.useState(0);
  const [ReplyCountCard, SetReplyCountCard] = React.useState(0);
  const [OpenPerCountCard, SetOpenPerCountCard] = React.useState(0);
  const [ReplyPerCountCard, SetReplyPerCountCard] = React.useState(0);
  const [BouncePerCountCard, SetBouncePerCountCard] = React.useState(0);
  const [Cname, SetCname] = React.useState("");
  const [sortedColumn, setSortedColumn] = React.useState("CampaignName");

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCname(result[0].Name);
    });
    SetCampaignID(CampaignIDforEmail);
    SetFromDate(FromDates);
    SetToDate(ToDates);
    ContactReportingGet(Details.ClientID, Details.ParentUserID, Details.Role,FromDates,ToDates);
    ContactReportingExportGet(Details.ClientID, Details.ParentUserID, Details.Role,FromDates,ToDates);
    ReportingContactMatrixGraphGet(Details.ClientID, Details.ParentUserID, Details.Role,FromDates,ToDates);
  }, [Search, Page, RowsPerPage,FromDates,ToDates]);

  const ReportingContactMatrixGraphGet = (CID, UID, URole,Fromdate,Todate) => {
    var InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignIDforEmail,
      FromDate: Fromdate,
      ToDate: Todate,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/ReportingContactMatrixGraphGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((result) => {
      SetCountGraph(result.data);
    });
  };
  const AccountState = {
    series: [
      {
        name: "Open",
        color: COLORS.secondary,
        data: CountGraph.map((item) => item.Open),
    },
    {
        name: "Sent",
        color: COLORS.primary,
        data: CountGraph.map((item) => item.Sent),
    },
    {
        name: "Replies",
        color: COLORS.brown,
        data: CountGraph.map((item) => item.Reply),
    },
    {
        name: "Bounces",
        color: COLORS.emeraldGreen,
        data: CountGraph.map((item) => item.Bounce),
    },
    
    
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
            show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
        curve: 'smooth',
      },
      xaxis: {
        categories: CountGraph.map((item) => item.dateRange),
        title: {
          text: "Weeks",
        },
      },
      legend: {
        position: 'right',
        onItemClick: {
          toggleDataSeries: false
      },
    },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        intersect: false,
        x: {
            show: true,
        },
        y: {
            formatter: function(val) {
                return val;
            },
        },
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
        
          const data = CountGraph[dataPointIndex];
             
          return (
            `<div style="background-color: #fff; border: 1px solid #ccc; border-radius: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); max-width: 300px;">
            <div style="font-weight: bold;background-color:#e1e1e1;padding: 8px; margin-bottom: 8px;"><small>${data.dateRange}</small></div>
            <div style="display: flex; align-items: center;padding-inline: 8px; margin-bottom: 4px;">
                <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[0].color};"></div>
                <div><small>${AccountState.series[0].name}: ${data.Open}</small></div>
            </div>
            <div style="display: flex; align-items: center;padding-inline: 8px; margin-bottom: 4px;">
                <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[1].color};"></div>
                <div><small>${AccountState.series[1].name}: ${data.Sent}</small></div>
            </div>
            <div style="display: flex; align-items: center;padding-inline: 8px; margin-bottom: 4px;">
                <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[2].color};"></div>
                <div><small>${AccountState.series[2].name}: ${data.Reply}</small></div>
            </div>
            <div style="display: flex; align-items: center;padding-inline: 8px;">
                <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[3].color};"></div>
                <div><small>${AccountState.series[3].name}: ${data.Bounce}</small></div>
            </div>
        </div>
    `
          );
      },
  
    },
    },
  };

  // email reporting list
  const ContactReportingGet = (CID, UID, URole,Fromdate,Todate) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignIDforEmail,
      FromDate: Fromdate,
      ToDate: Todate,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/GetCampaignContactReporting",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((result) => {
      SetData(result.data?.PageData);
      SetRows(result.data?.PageData);
      SetRlen(result.data?.TotalCount);
      SetFlen(result.data?.TotalCount);
      SetCountPage(result.data?.PageCount);
     // SetCounts(result.data.Counts);
     SetSendCountCard(result.data?.FinalCount?.TotalSentMail ?? 0);
     SetReplyCountCard(result.data?.FinalCount?.TotalReply ?? 0);
     SetOpenPerCountCard(result.data?.FinalCount?.TotalOpenPercentage ?? 0);
     SetReplyPerCountCard(result.data?.FinalCount?.TotalReplyPercentage ?? 0);
     SetBouncePerCountCard(result.data?.FinalCount?.TotalBouncePercentage ?? 0);
     
      SetIsLoading(false);
    });
  };

    // email reporting list
    const ContactReportingExportGet = (CID, UID, URole,Fromdate,Todate) => {
      var InputParameters = {
        ClientID: CID,
        UserID: UID,
        Role: URole,
        CampaignID: CampaignIDforEmail,
        FromDate: Fromdate,
        ToDate: Todate,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/CampaignContactReportingExport",
        method: "POST",
        data: InputParameters,
      });
      rows1.then((result) => {
        SetExportData(result.data);
      });
    };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search: SearchedVal,
        Sort: true,
        Field: Field,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID: CampaignID,
        FromDate: FromDate,
        ToDate: ToDate,
      };
      const ApiResponse = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/GetCampaignContactReporting",
        method: "POST",
        data: InputParameter,
      });
      ApiResponse.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetCounts(Result.data.PageCount);

        SetIsLoading(false);
      });
  };
  return (
    <>
        {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
                  <div className="row colfive mx-0 px-2">
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title text-center d-block">
                          <span class="clr-sky box-main-title">{isNaN(SendCountCard) ? 0 : SendCountCard}</span>
                        </div>
                        <div class="box-inner-title xs-headertitle">
                          <string class="clr-sky">Sent</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title text-center d-block">
                          <span class="clr-sky box-main-title">{isNaN(ReplyCountCard) ? 0 : ReplyCountCard}</span>
                        </div>
                        <div class="box-inner-title xs-headertitle">
                          <string class="clr-sky">Reply</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title text-center d-block">
                          <span class="clr-sky box-main-title">  {isNaN(OpenPerCountCard) ? 0 : parseFloat(OpenPerCountCard.toFixed(0))}%</span>
                        </div>
                        <div class="box-inner-title xs-headertitle">
                          <string class="clr-sky">Open %</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title text-center d-block">
                          <span class="clr-sky box-main-title">{isNaN(ReplyPerCountCard) ? 0 : parseFloat(ReplyPerCountCard.toFixed(0))}%</span>
                        </div>
                        <div class="box-inner-title xs-headertitle">
                          <string class="clr-sky">Reply %</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div class="bg-light-sky boxcardcounter align-center">
                    <div class="d-flex">
                      <div className="contectboxes w-100">
                        <div class="line-title text-center d-block">
                          <span class="clr-sky box-main-title">{isNaN(BouncePerCountCard) ? 0 : parseFloat(BouncePerCountCard.toFixed(0))}%</span>
                        </div>
                        <div class="box-inner-title xs-headertitle">
                          <string class="clr-sky">Bounce </string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row padtop-50 mx-0">
                <div class="col-xl-12">
                  <div id="chart chart-max450">
                  <ReactApexchart
              options={AccountState.options}
              series={AccountState.series}
              type="line"
              height={450}
            />
                  </div>
                </div>
              </div>

              <div className="row mx-0 border-top border-bottom">
                <div className="col d-flex align-items-center ">
                  <h4 className="headertitle  xs-headertitle  float-left pl-0">Contact Details</h4>
                </div>
                <div className="col text-right listing-li d-flex align-items-center justify-content-end">
                  <ul className="mt-0">
                    <li>
                      <CSVDownloader
                        className="px-0"
                        data={ExportData}
                        filename={Cname + "-Contact"}
                        bom={true}
                      >
                        <a
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          //onClick={this.exportcsv}
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>Export</span>
                        </a>
                      </CSVDownloader>
                    </li>
                  </ul>
                </div>
              </div>

      <div className="row mx-0">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25 mx-0">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div class='px-3'>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                    onClick={() => {
                      SortData("CampaignName");
                    }}
                  >
                    Campaign Name
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "CampaignName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "CampaignName" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("Email");
                    }}
                  >
                    Email
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("TotalSentMail");
                    }}
                  >
                    Sent
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "TotalSentMail" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "TotalSentMail" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("TotalReply");
                    }}
                  >
                    Reply
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "TotalReply" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "TotalReply" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("TotalOpen");
                    }}
                  >
                    Open
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "TotalOpen" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "TotalOpen" ? "active" : null} />
                </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row?.CampaignName}</TableCell>
                    <TableCell>{row?.Email}</TableCell>
                    <TableCell>{row?.TotalSentMail}</TableCell>
                    <TableCell>{row?.TotalReply}</TableCell>
                    <TableCell>{row?.TotalOpen}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      </div>
      {/* {Sflag ? (
        <div class="row mx-0">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : ( */}
        <div class="row mx-0">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      {/* )} */}
    </>
  );
}
