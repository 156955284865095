import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class CAddMeetingStatusPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: null,
      UserID: null,
      Role: Details.Role,
      VariableType: [],
      VariableTypeID: null,
      SimpleMeeting:[],
      CustomVariable: {},
      IsExist: false,
      UserIDby: null,
      BtnDisabled: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
    this.HandleChangedropdownBlur = this.HandleChangedropdownBlur.bind(this);
  }

  componentDidMount() {
    document.title = "Add Meeting Statuses | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ Role: Details.Role });
      this.setState({ UserIDby: Details.ChildUserID });
    }
    this.EditMeetingStatus();
  }


  // form validation
  async FromValidations() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var status = document.getElementById("status").value.trim();
    var VariableType = document.getElementById("VariableType").value;
    var SimpleMeeting = document.getElementById("SimpleMeeting").value;
    var SimpleProbability = document.getElementById("Probability").value;
    var IsExist = await this.CheckExistStatus(status);
    if (status == "") {
      FormIsValid = false;
      Errors["status"] = "Please enter status";
    }
    if (parseInt(SimpleProbability) > 100) {
      FormIsValid = false;
      Errors["Probability"] = "Please enter a value less than or equal to 100.";
    }

    if (parseInt(SimpleProbability) < 0) {
      FormIsValid = false;
      Errors["Probability"] = "Please enter a value greater than or equal to 0.";
    }

    if (VariableType == "") {
      FormIsValid = false;
      Errors["VariableType"] = "Please select status type";
    }
    if (SimpleMeeting == "") {
      FormIsValid = false;
      Errors["SimpleMeeting"] = "Please select simple meeting status";
    }
    if (IsExist == "ExistStatus") {
      FormIsValid = false;
      Errors["status"] = "Status Already Exist";
    }

    if (IsExist == "BlankData" && status == "") {
      FormIsValid = false;
      Errors["status"] = "Please enter status";
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });

    if (Fields.status != "") {
      this.state.Errors.status = null;
      this.state.StatusAvailable = null;
      this.CheckExistStatus(e.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.status = null;
    }
  }

  // form validation
  async HandleChangeBlur(Name) {
    let Errors = { ...this.state.Errors };
    var status = document.getElementById("status").value.trim();
    var SimpleProbability = document.getElementById("Probability").value;
    var IsExist = await this.CheckExistStatus(status);

    if (Name == "StatusName") {
    if (status == "") {
      Errors["status"] = "Please enter status";
    }

    if (IsExist == "ExistStatus") {
      Errors["status"] = "Status Already Exist";
    }

    if (IsExist == "AllDone") {
      Errors["status"] = null;
    }
  }

    if (Name == "ProbabilityName") {
      if (parseInt(SimpleProbability) > 100) {
        Errors["Probability"] =
          "Please enter a value less than or equal to 100.";
      }else if (parseInt(SimpleProbability) < 0) {
        Errors["Probability"] = "Please enter a value greater than or equal to 0.";
      } else {
        Errors["Probability"] = null;
      }
    }

    this.setState({ Errors: Errors });
  }

  async HandleChangedropdownBlur(Name) {
    let Errors = { ...this.state.Errors };
    var VariableType = document.getElementById("VariableType").value;
    var SimpleMeeting = document.getElementById("SimpleMeeting").value;

    if (Name == "VariableTypeName") {
      if (VariableType == "") {
        Errors["VariableType"] = "Please select status type";
      } else {
        Errors["VariableType"] = null;
      }
    }

    if (Name == "SimpleMeetingName") {
      if (SimpleMeeting == "") {
        Errors["SimpleMeeting"] = "Please select simple meeting status";
      } else {
        Errors["SimpleMeeting"] = null;
      }
    }

    this.setState({ Errors: Errors });
  }

  // back button
  BackBtn() {
    history.push("/CMeetingStatus");
  }

  // find exits status
  async CheckExistStatus(Status) {
    if (Status != "") {
      var CheckMeetingStatus = {
        ClientID: this.state.ClientID,
        Status: Status,
      };
      var res = await Axios({
        url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusExists",
        method: "POST",
        data: CheckMeetingStatus,
      });
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data.length > 0) {
          this.setState({ StatusAvailable: res.data.Data[0].Status });
          return "ExistStatus";
        } else {
          this.setState({ IsExist: false });
          return "AllDone";
        }
      }
    } else {
      return "BlankData";
    }
  }

  //find id to record
  EditMeetingStatus() {
    var CheckStatus = {
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetingstatus/StatusTypeListGet",
      method: "POST",
      data: CheckStatus,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ VariableType: res.data?.Data });
        this.setState({ SimpleMeeting: res.data?.SimpleMeetingT });
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // save to update meeting status
  async SaveBtn(e) {
    e.preventDefault();
    this.setState({ BtnDisabled: true });
    document.getElementById("hideloding").style.display = "block";

    // this.state.Errors.Name = null;
    // this.state.StatusAvailable = null;
    // e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidations();
    if (Final_flag == true) {
      var status = document.getElementById("status").value;
      var Probability = document.getElementById("Probability").value;
      var VariableType = document.getElementById("VariableType").value;
      var SimpleMeeting = document.getElementById("SimpleMeeting").value;

      var MeetingStatusSave = {
        Status: status,
        type: "User",
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.UserIDby,
        CreatedDt: new Date(),
        IsClosedLost: false,
        IsDeleted: false,
        Probability: Probability,
        StatusTypeID: VariableType,
        SimpleMeetingID:SimpleMeeting
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusAdd",
        method: "POST",
        data: MeetingStatusSave,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Add meeting statuses
              <br />
              Meeting statuses added successfully.
            </div>
          );
          history.push("/CMeetingStatus");
        } else {
          toast.error(res.data.Message);
          this.setState({ BtnDisabled: false });
      //document.getElementById("submit").disabled = false;

        }
      });
    }else {
      this.setState({ BtnDisabled: false });
     // document.getElementById("submit").disabled = false;
    }
    document.getElementById("hideloding").style.display = "none";

  }

  render() {
    return (
      <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="adminmain mheight"> 
        <Sidebar className="" /> 
        <div className="bodyhome">
          
          <div className="row">
            <div className="col">
              <h4 className="headertitlepad lg-headertitle float-left mb-0 pl-0">
                Add Meeting Statuses
              </h4>
            </div>
          </div>

          <div class="paddcols px-3">
            <div className="row py-4 px-3 mb-3">
              <div class="col-lg-6 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet">Status</label>
                  <div class="col-lg-8">
                    <input
                      class="form-control m-input"
                      data-val="true"
                      data-val-required="Please enter status"
                      id="status"
                      name="status"
                      placeholder="Enter status"
                      type="text"
                      onBlur={() => this.HandleChangeBlur("StatusName")}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.Errors["status"]}
                    </span>
                    {/* {this.state.StatusAvailable && (
                      <span style={{ color: "red" }}>
                        Status already exist.
                      </span>
                    )} */}
                  </div>
                </div>
              </div>

              <div class="col-lg-6 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet">Status Type</label>
                  <div class="col-lg-8">
                    <select
                      className="form-control  m-input"
                      id="VariableType"
                      name="VariableType"
                      autoComplete="off"
                      onChange={() =>
                        this.HandleChangedropdownBlur("VariableTypeName")
                      }
                    >
                      {<option value="">--Select--</option>}
                      {this.state.VariableType.map((value) => (
                        <option value={value.FieldID}>
                          {value.FieldName}
                        </option>
                      ))}
                    </select>
                    <span style={{ color: "red" }}>
                      {this.state.Errors["VariableType"]}
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet">Probability</label>
                  <div class="col-lg-8">
                    <input
                      class="form-control m-input"
                      data-val="true"
                      data-val-required="Please enter Probability"
                      id="Probability"
                      name="Probability"
                      placeholder="Enter Probability"
                      type="number"
                      onBlur={() => this.HandleChangeBlur("ProbabilityName")}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.Errors["Probability"]}
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet">
                    Simple Meeting Status
                  </label>
                  <div class="col-lg-8">
                    <select
                      className="form-control  m-input"
                      id="SimpleMeeting"
                      name="SimpleMeeting"
                      autoComplete="off"
                      onChange={() =>
                        this.HandleChangedropdownBlur("SimpleMeetingName")
                      }
                      // onBlur={this.HandleChangeDropDown.bind(this, "SimpleMeeting")}
                    >
                      {<option value="">--Select--</option>}
                      {this.state.SimpleMeeting.map((value) => (
                        <option value={value.FieldID}>
                          {value.FieldName}
                        </option>
                      ))}
                    </select>
                    <span style={{ color: "red" }}>
                      {this.state.Errors["SimpleMeeting"]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div class="row mb-3 mt-3">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  disabled={this.state.BtnDisabled}
                  onClick={this.SaveBtn}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>
        </div>
      </div>
      <Footer />
    </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddMeetingStatusPage = connect(
  mapState,
  actionCreators
)(CAddMeetingStatusPage);
export { connectedCAddMeetingStatusPage as CAddMeetingStatusPage };
