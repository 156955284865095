import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CampaignCategoriesTable from '../../_components/clients/campaigncategories/campaigncategories';
import CollapsibleTable from '../../_components/usertable';

import { history } from "../../_helpers";

class CCampaignCategoriesPage extends React.Component {
    componentDidMount() {
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    }
    
    AddCampaigncategory() {
        history.push("/CAddCampaignCategories");
      }
    
      AddCampaignIncategory() {
        history.push("/CAddCampaignInCategories");
      }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle lg-headertitle pl-0 mb-0">Campaign Categories</h4> 
                        </div> 
                        <div className="col">
                            <div className="listing-li float-right padb-15 pr-0">
                                <ul>
                                <li>
                      <a
                        onClick={this.AddCampaigncategory.bind(this)}
                        className="btn btngroup m-btn m-btn--custom"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                                </li>
                                <li>
                                <a onClick={this.AddCampaignIncategory.bind(this)} className="btn btngroup m-btn m-btn--custom">
                                    <i class="la la-icon-font-size-13 la-plus"></i>
                                    <span>Add Campaign In Category</span>
                                </a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white"> 
                        <div className="row mx-0 pb-3">
                            <div className="col">
                              
                                    <CampaignCategoriesTable />
                              
                            </div>
                        </div>      

                      
                    </div>

                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCCampaignCategoriesPage = connect(mapState, actionCreators)(CCampaignCategoriesPage);
export { connectedCCampaignCategoriesPage as CCampaignCategoriesPage };