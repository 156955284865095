import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import SalesStrategistsTable from './SalesStrategistsTable'; 
import InvitesTable from './InvitesTable'; 


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs({listupdate}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  

  return (
    <div className="tabdes">   
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example">
            <Tab label="Sales strategists" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Invites" {...a11yProps(1)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
        <div className="px-3 py-0 ">
            <div className="row borderbtnm bg-white border-bottom-0">
              <div className="col padd-0">
                <h4 className="headertitlebd xs-headertitle ">Sales Strategists</h4>
              </div>
              <div className="col"></div>
            </div>
            <div className="row bg-white pb-3">
              <div className="col">
                  <SalesStrategistsTable />
              </div>
            </div>


          </div>

        </TabPanel>
        
        <TabPanel value={value} className="tagpaddnone px-3 whitebg" index={1}>  
        <div className="px-1 py-0">
        <div className="row borderbtnm bg-white border-bottom-0">
              <div className="col">
                  <h4 className="headertitlebd xs-headertitle  pl-0">Invites</h4>
              </div>
              <div className="col">  
              </div>
          </div>

          <div className="row bg-white pb-3">
            <div className="col">
                <InvitesTable listupdate={listupdate}/>
            </div>
          </div>


        </div>
         
        </TabPanel>
       
      </div>
 

    </div>
  );
}