import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { CommonConstants } from "../../_constants/common.constants";
import loadingicon from "../../images/loading.gif";

class ClickTrackReportingPage extends React.Component {
  //Initialization of States
  constructor(props) {
    super(props);

    this.state = {
      IsExist: false,
      TitleNotNull: false,
      EmailNotNull: false,
      Type: false,
      IsEmail: true,
      reset: false,
      fields: {},
      errors: {},
      ClientID: null,
      UserID: null,
      CUserID: null,
      ClientList: [],
      CampaignList: [],
      CampaignStepList: [],
      ClientError:{},
      BtnDisabled:false
    };

    // this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  //State Life Cycle
  async componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title = "Click Track Reporting | SalesHive";
    var Details = GetUserDetails();

    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
    await this.GetClinetDropDown(
      Details.Role,
      Details.ChildUserID,
      Details.ParentUserID
    );
  }

  async GetClinetDropDown(Role, CUID, PUID) {
    var ObjData = {
      Role: Role,
      ChildUserID: CUID,
      ParentUserID: PUID,
    };
    let res = await Axios({
      url: CommonConstants.MOL_APIURL + "/click_track_reporting/ClientGet",
      method: "POST",
      data: ObjData,
    });
    
    if (res.data.StatusMessage == "SUCCESS") {
      const ClientData = res.data.Data;
      const option = ClientData.map((Type) => ({
        FieldID: Type._id,
        FieldName: Type.Name,
      }));
      this.setState({ ClientList: option });
    } else {
      toast.error(res.data.Message);
    }
  }

  GetClientID() {
    
    let errors = {};
    let ClientID = document.getElementById("ClientID").value.trim();
    if (ClientID == "") {
    }else{
      this.GetcampaignDropDown(ClientID);
      errors['ClientID'] = "";
    }
    this.setState({ ClientError: errors });
    
  }
  //Get campaign Drop Down
  GetcampaignDropDown(ID) {
    
    if (ID.length <= 0) {
      document.getElementById("ClientPOCID").value = "";
      this.setState({ CampaignList: [] });
    } else {
      const InputData = {
        UserID: this.state.UserID,
        ClientID: ID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/click_track_reporting/CampaignGet",
        method: "POST",
        data: InputData,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          const ClientData = res.data.Data;
          const option = ClientData.map((Type) => ({
            FieldID: Type._id,
            FieldName: Type.Name,
          }));
          this.setState({ CampaignList: option });
          if (res.data.Data.length <= 0) {
            toast.error(
              <div className="toastsize">
                Click Track Report <br /> No campaign available
              </div>
            );
          }
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  GetCampaignID() {
    let data = document.getElementById("ClientPOCID").value;

    this.GetcampaignStepDropDown(data);
  }

  //Get campaign Step Drop Down
  GetcampaignStepDropDown(ID) {
    if (ID.length <= 0) {
      document.getElementById("ReasonUnqualifiedID").value = "";
    } else {
      const InputData = {
        UserID: this.state.UserID,
        ClientID: this.state.ClientID,
        CampaignID: ID,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/click_track_reporting/CampaignStepGet",
        method: "POST",
        data: InputData,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          if (res.data.Data.length <= 0) {
            toast.error(
              <div className="toastsize">
                Click Track Report <br /> No campaign step available
              </div>
            );
          } else {
            const ClientData = res.data.Data;
            this.setState({ CampaignStepList: ClientData });
          }
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  validateEmail(email) {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!email || regex.test(email) === false);
  }

  CheckEmail(field, e) {
    let Email = document.getElementById("Email").value;
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    let errors = {};
    if (!fields["Email"]) {
      this.setState({ EmailNotNull: false });

      errors["Email"] = "Please enter Email";
    } else {
      let Email = document.getElementById("Email").value;
      if (!this.validateEmail(Email)) {
        this.setState({ IsEmail: false });

        errors["Email"] = "Invalid email";
      } else {
        this.setState({ IsEmail: true });
        this.setState({ EmailNotNull: true });
      }
    }
    this.setState({ errors: errors });
  }

  // Whole form validation
  FormValidation() {
    let formIsValid = true;
    let errors = {};
    let ErrorClientID={};
    let ClientID = document.getElementById("ClientID").value.trim();
    let Email = document.getElementById("Email").value.trim();
    const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (Email == "") {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    } else if (regex.test(Email) == false) {
      formIsValid = false;
      errors["Email"] = "Invalid email.";
    }
    if (ClientID == "") {
      formIsValid = false;
      ErrorClientID["ClientID"] = "Please select client.";
      
      this.setState({ClientError:ErrorClientID})
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  //Client on change method
  ClientChangeMethod(){
    let errors = {};
    let ClientID = document.getElementById("ClientID").value.trim();
    if (ClientID == "") {
      errors["ClientID"] = "Please select client.";
    }else{
      errors["ClientID"] = "";
    }
    this.setState({ errors: errors });
  }

  SaveBtn() {
    document.getElementById("hideloding").style.display = "block";
    this.setState({BtnDisabled:true})
    if (this.FormValidation()) {
      let Email = document.getElementById("Email").value;
      let Campaign = document.getElementById("ClientPOCID").value;
      let CampaignStep = document.getElementById("ReasonUnqualifiedID").value;
      let ClientID = document.getElementById("ClientID").value;
      const InputData = {
        UserID: this.state.UserID,
        ClientID: ClientID,
        CampaignID: Campaign == "" ? null : Campaign,
        CampaignStepID: CampaignStep == "" ? null : CampaignStep,
        Email: Email,
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/click_track_reporting/ClickTrackReportAdd",
        method: "POST",
        data: InputData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Click Track Reporting <br />
              Click Track Reporting added successfully.
            </div>
          );
          document.getElementById("Email").value = "";
          document.getElementById("ClientPOCID").value = "";
          document.getElementById("ClientID").value = "";
          document.getElementById("ReasonUnqualifiedID").value = "";
          this.setState({ CampaignList: [] });
          this.setState({ CampaignStepList: [] });
          history.push("/clicktrackreporting");
          document.getElementById("hideloding").style.display = "none";
          this.setState({ClientError:{}})
          this.setState({ errors: {} });
        this.setState({BtnDisabled:false})
          
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})

          document.getElementById("hideloding").style.display = "none";
        }
      });
    } else {
        this.setState({BtnDisabled:false})
        document.getElementById("hideloding").style.display = "none";
    }
  }

  render() {
    return (
      <>
        <div
          id="hideloding"
          className="loding-display"
          style={{ display: "none" }}
        >
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
          
            <div className="row align-items-center py-4">
              <div className="col">
                <h4 className="headertitlenop lg-headertitle float-left">
                  Click Track Reporting
                </h4>
              </div>
            </div> 
            <div class="whiterow">
              <div className="row pt-3 pb-3 px-4">
                <div class="col-lg-6 boxsinput_group px-4">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Email</label>
                    <div class="col-lg-7">
                      <input
                        class="form-control m-input"
                        onBlur={this.CheckEmail.bind(this, "Email")}
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        placeholder="Enter email"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Email"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group px-4">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Client</label>
                    <div class="col-lg-7">
                      <select
                        class="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ClientID must be a number."
                        id="ClientID"
                        name="ClientID"
                        onChange={this.GetClientID.bind(this)}
                      >
                        <option value="">--Select--</option>
                        {this.state.ClientList.map((val) => (
                          <option value={val.FieldID}>{val.FieldName}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.ClientError["ClientID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group px-4">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Campaign</label>
                    <div class="col-lg-7">
                      <select
                        class="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ClientPOCID must be a number."
                        id="ClientPOCID"
                        name="ClientPOCID"
                        onChange={this.GetCampaignID.bind(this)}
                      >
                        <option value="">--Select--</option>
                        {this.state.CampaignList.map((val) => (
                          <option value={val.FieldID}>{val.FieldName}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group px-4">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">
                      Campaign Step
                    </label>
                    <div class="col-lg-7">
                      <select
                        class="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ReasonUnqualifiedID must be a number."
                        id="ReasonUnqualifiedID"
                        name="ReasonUnqualifiedID"
                      >
                        <option value="">--Select--</option>
                        {this.state.CampaignStepList.map((val) => (
                          <option value={val._id}>
                            {val.StepNumber}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="row pb-4 pt-4">
                <div class="col-lg-12 pull-left px-3">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                </div>
              </div>
          
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClickTrackReportingPage = connect(
  mapState,
  actionCreators
)(ClickTrackReportingPage);
export { connectedClickTrackReportingPage as ClickTrackReportingPage };
