import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import Toolbar from "@material-ui/core/Toolbar";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../_actions/user.actions";
import { clientActions } from "../../../_actions/client.action";

import {
  styled,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { UpdateUserDetails, GetUserDetails } from "../../../_helpers/Utility";

import logo from "../../../images/SalesHive.png";

import {
  BrowserRouter as Router,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import { useClickOutside } from "../../../_helpers/useClickOutside";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import ColdCalling from "../../../images/header/coldcalling.svg";
import Kpis from "../../../images/header/kpis.svg";
import Help from "../../../images/header/help.svg";
import Propects from "../../../images/header/propectsicon.svg";
import EmailsIcon from "../../../images/header/email_orange.svg";

import meeting from "../../../images/header/meeting.svg";
import settings from "../../../images/header/settings.svg";
import tools from "../../../images/header/tools.svg";
import admin from "../../../images/header/admin.svg";
import Speedscore from "../../../images/header/speedscore.svg";
import "react-toastify/dist/ReactToastify.css";
import { CommonConstants } from "../../../_constants/common.constants";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  drawer: {
    maxWidth: 220,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      // display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const ListItem = withStyles({
  root: {
    "&$selected": {
      color: "#F8BF58,'!important'",
      "& .MuiListItemIcon-root": {
        color: "#F8BF58",
      },
    },
    "&$selected:hover": {
      color: "#F8BF58",
      "& .MuiListItemIcon-root": {
        color: "#F8BF58",
      },
    },
    "&:hover": {
      color: "#F8BF58",
      "& .MuiListItemIcon-root": {
        color: "#F8BF58",
      },
    },
  },
  selected: {},
})(MuiListItem);

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const location = useLocation();
  const [aopen, asetOpen] = useState(true);
  const [mopen, msetOpen] = useState(true);
  const [vopen, vsetOpen] = useState(true);
  const [emreopen, setemreOpen] = useState(true);
  const [hopen, hsetOpen] = useState(true);
  const [topen, tsetOpen] = useState(true);
  const [seopen, sesetOpen] = useState(true);
  const [accsopen, accssetOpen] = useState(true);
  const [pmsopen, pmssetopen] = useState(true);
  const [cmpsopen, cmpssetopen] = useState(true);
  const [cmmsopen, msetsetopen] = useState(true);
  const [allcopen, allcsetopen] = useState(true);
  const [shuopen, shopen] = useState(true);
  const [aseopen, asopen] = useState(true);
  const [copen, csetOpen] = useState(true);
  const [propectsOpen, setpropectsOpen] = useState(true);
  const [seoopen, setseoOpen] = useState(true);
  const [consopen, conopen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = useRef(null);
  React.useState(false);
  const [SelectMenuItem, SetSelectMenuItem] = useState("/kpis");

  const history = useHistory();
  const dispatch = useDispatch();
  // const userDetails = useSelector((state) => state.authentication.user);
  const LoggedUserDetails = useSelector(
    (state) => state.authentication.loggerUserDetails
  );
  const ClientDropdown = useSelector((state) => state.client.clientDropdown);
  const SelectedClientDropdown = useSelector(
    (state) => state.client.selectedClientDropdown
  );
  const searchValue = useSelector((state) => state.client.searchValue);
  const IsClients = useSelector((state) => state.client.isClients);
  const filteredOptions = useSelector((state) => state.client.filteredOptions);
  const [UserRole, SetUserRole] = useState(null);

  const [openDropdown, setOpenDropdown] = useState(null);
  const [openChildDropdown, setOpenChildDropdown] = useState(null);

  const [open, setOpen] = useState(true);
  const inputFocus = useRef(null);

  const [activeIndex, setActiveIndex] = useState(-1);
  const ulRef = useRef(null);

  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    document.body.classList.toggle("openside");
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      setActiveIndex((prevIndex) =>
        Math.min(prevIndex + 1, filteredOptions.length - 1)
      );
    } else if (event.key === "ArrowUp") {
      setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (event.key === "Enter") {
      if (activeIndex >= 0 && activeIndex < filteredOptions.length) {
        const selectedOption = filteredOptions[activeIndex];
        handleOptionSelect(selectedOption.ClientID, selectedOption.Name);
      }
    }
  };

  // Effect to handle scrolling
  useLayoutEffect(() => {
    if (
      ulRef.current &&
      activeIndex >= 0 &&
      activeIndex < filteredOptions.length
    ) {
      const activeItem = ulRef.current.children[activeIndex];
      const container = ulRef.current;

      if (activeItem) {
        const scrollOffset = activeItem.offsetTop - container.offsetTop;
        const scrollTop = container.scrollTop;
        const containerHeight = container.clientHeight;

        if (scrollOffset < scrollTop) {
          container.scrollTop = scrollOffset;
        } else if (
          scrollOffset + activeItem.clientHeight >
          scrollTop + containerHeight
        ) {
          container.scrollTop =
            scrollOffset + activeItem.clientHeight - containerHeight;
        }
      }
    }
  }, [activeIndex, filteredOptions]);

  // Handle filtered options effect
  useEffect(() => {
    if (activeIndex >= filteredOptions.length) {
      setActiveIndex(filteredOptions.length - 1);
    } else if (activeIndex < 0 && filteredOptions.length > 0) {
      setActiveIndex(0);
    }
  }, [filteredOptions]);

  useEffect(() => {
    let LoggedData = JSON.parse(localStorage.getItem("LoginData"));

    if (!LoggedData) {
      history.push("/");
    } else {
      if (LoggedData.Role === "SEO") {
        let PageData = history.location.pathname;
        if (
          PageData !== "/seooverview" ||
          PageData === "/keywords" ||
          PageData === "/backlinks" ||
          PageData === "/corewebvitals" ||
          PageData === "/backlinkspipeline"
        ) {
          if(PageData === "/seooverview"){
            history.push("/seooverview");
          }else if(PageData === "/keywords"){
            history.push("/keywords");
          }else if(PageData === "/backlinks"){
            history.push("/backlinks");
          }else if(PageData === "/corewebvitals"){
            history.push("/corewebvitals");
          }else if(PageData === "/backlinkspipeline"){
            history.push("/backlinkspipeline");
          }else if(PageData === "/EditMyProfile"){
            history.push("/EditMyProfile")
          }else if(PageData === "/contentpipeline"){
            history.push("/contentpipeline");
          }else{
            history.push("/seooverview");
          }
        }
      } else if (LoggedData.Role === "Client"){
        let PageData = history.location.pathname;
        if (
          PageData !== "/seooverview" ||
          PageData === "/keywords" ||
          PageData === "/backlinks" ||
          PageData === "/corewebvitals" ||
          PageData === "/backlinkspipeline"
        ) {
          if(PageData === "/seooverview"){
            history.push("/seooverview");
          }else if(PageData === "/keywords"){
            history.push("/keywords");
          }else if(PageData === "/backlinks"){
            history.push("/backlinks");
          }else if(PageData === "/corewebvitals"){
            history.push("/corewebvitals");
          }else if(PageData === "/backlinkspipeline"){
            history.push("/backlinkspipeline");
          }else if(PageData === "/EditMyProfile"){
            history.push("/EditMyProfile")
          }else if(PageData === "/contentpipeline"){
            history.push("/contentpipeline");
          }else if(PageData === "/cphonesettings"){
            history.push("/cphonesettings");
          }else if(PageData === "/bookedmeetingcustomcolumn"){
            history.push("/bookedmeetingcustomcolumn");
          }else{
            history.push("/c"+PageData);
          }
        }
      }
      SetUserRole(LoggedData.Role);
      var SelectedPage = props.menupage;
      if (SelectedPage == undefined) {
        SetSelectMenuItem("/kpis");
      } else {
        SetSelectMenuItem(SelectedPage);
        if (
          SelectedPage == "/potentialmeeting" ||
          SelectedPage == "/bookedmeetings" ||
          SelectedPage == "/opportunities" ||
          SelectedPage == "/linkedinfollowup" ||
          SelectedPage == "/reschedules"
        ) {
          msetOpen(false);
        }
      }
    }

    GetUserDetails();
    checkLoginStatus();

  }, [SelectMenuItem, searchValue]);

  const path = location.pathname;
  const pathGroups = {
    meetings: [
      "/opportunities",
      "/linkedinfollowup",
      "/reschedules",
    ],
    potentialMeeting: ["/potentialmeeting","/addpotentialmeeting","/viewpotentialmeeting","/editpotentialmeeting"],
    bookedMeeting: ["/bookedmeetings","/addbookedmeeting","/viewbookedmeeting","/editbookedmeeting"],
    coldCalling: [
      "/ColdCallingContact",
      "/ColdCallingContactlist",
      "/callrecordings",
      "/CallHistory",
      "/coldcallingreporting",
      "/DNChistory"
    ],
    variable: [
      "/Variable",
      "/customvariableset",
      "/calltoaction",
    ],
    customVariable: ["/customvariable","/addcustomvariable","/editcustomvariable"],
    history: [
      "/emailhistory",
      "/callhistory",
      "/emailresponses",
      "/actionitems",
      "/documents",
      "/directmail",
      "/research",
      "/insights",
    ],
    campaigns: ["/campaigns","/addCampaigns","/editcampaigns"],
    emailReporting: [
      "/campaignsummary",
      "/openratereporting",
      "/clicktrackreporting",
    ],
    tools: [
      "/automator",
      "/despammer",
      "/emailvalidation",
      "/HiveAi",
      "/deliverabilitytesting",
      "/deliverabilitytracking",
      "/globalbounce",
      "/emailwarmup",
      "/ContactBulkImportRequest",
      "/ContactBulkExportRequest",
    ],
    objections: ["/usersperclient", "/objections", "/viewapiKeys"],
    accountSetting: [
      "/accountcategory",
      "/accountclientstatus",
      "/AccountReasonUnqualified",
      "/customaccountfield",
    ],
    contactSetting: [
      "/contactreasonunqualified",
      "/ClientPOC",
      "/contactcustomfield",
      "/ContactSource",
      "/contacttag",
    ],
    campaignSetting: [
      "/blacklists",
      "/EmailAccounts",
      "/CampaignCategories",
      "/sendingschedular",
      "/emailsignature",
      "/countryblacklist",
    ],
    meetingSetting: [
      "/meetingowner",
      "/meetinggoal",
      "/meetingstatuses",
      "/meetingsource",
      "/meetingreminder",
      "/meetingslacknotification",
      "/meetingcustomfield",
    ],
    potentialSetting: ["/engagementstatus"],
    phoneSettings:['/phonesettings'],
    admin: ["/clients", "/emodprofile","/sdrassignments","/addsdrassignment","/editsdrassignment","/addtwiliophonenumber","/edittwiliophonenumber",'/addaccountinfo','/editaccountinfo'],
    allClients: [
      "/clientkpis",
      "/leaderboard",
      "/clientaccount",
      "/clientcontact",
      "/clientcampaigns",
      "/clientresponses",
      "/clientmeetings",
      "/clientrescheduling",
      "/clientpotentialmeetings",
      "/clientmeetingremindererros",
      "/clientmeetingreminderdefault",
      "/clientlinkedinfollowUp",
      "/clientemailaccounts",
      "/pendinginvites",
      "/variablereporting",
      "/userperclients",
      "/clientactionitems",
      "/clientdirectmail",
      "/clientresearch",
      "/clientlogin",
      "/clientapikeys",
    ],
    salesHiveUsers: [
      "/adminuser",
      "/salesstrategists",
      "/responder",
      "/Seo",
      "/coldcallers",
      "/coldcallerteams",
    ],
    adminSettings: [
      "/clientstatuses",
      "/ResearchTeam",
      "/defaultvariables",
      "/globalbouncelist",
      "/triggerphrases",
      "/smartvariable",
      "/genericemaildomain",
      "/defaultobjections",
      "/defaulttrackingdomain",
      "/salesrep",
      "/globalblacklist",
      "/defaultcountryblacklist",
      "/coldcallerphoneburnerlogin",
      "/DefaultSlackNotification",
      "/defaultphonesettings",
    ],
    seoReporting: [
      "/corewebvitals",
      "/backlinks",
      "/keywords",
      "/seooverview",
    ],
    backlinksPipeline: ["/backlinkspipeline","/addbacklinkspipeline","/editbacklinkspipeline"],
    contentPipeline: ["/contentpipeline","/addcontentpipeline","/editcontentpipeline"],
    prospects: ["/contacts","/addcontacts","/viewcontacts","/editcontacts","/contactcustomcolumn"],
    accounts: ["/accounts", "/addaccount", "/viewaccount", "/editaccount"],
    lists: ["/list","/addlist"],
    help: ["/help", "/knowledgebase", "/articlecategories", "/addarticle"]
  };

  const getGroupNameByPath = (path) => {
    return Object.keys(pathGroups).find((group) =>
      pathGroups[group].includes(path)
    );
  };

  const groupName = getGroupNameByPath(path);

  React.useEffect(() => {
    if (groupName) {
      switch (groupName) {
        case "meetings":
        case "potentialMeeting":
        case "bookedMeeting":
          setOpenDropdown("pipeline");
          msetOpen(false);
          break;
        case "coldCalling":
          setOpenDropdown("phone");
          csetOpen(false);
          break;
        case "customVariable":
        case "variable":
          setOpenDropdown("email");
          setOpenChildDropdown("emailone");
          vsetOpen(false);
          hsetOpen(false);
          break;
        case "emailReporting":
          setOpenDropdown("email");
          setOpenChildDropdown("emailtwo");
          setemreOpen(false);
          hsetOpen(false);
          break;
        case "campaigns":
        case "history":
          setOpenDropdown("email");
          hsetOpen(false);
          break;
        case "tools":
          setOpenDropdown("tools");
          tsetOpen(false);
          break;
        case "objections":
          setOpenDropdown("settings");
          sesetOpen(false);
          break;
        case "accountSetting":
          setOpenDropdown("settings");
          setOpenChildDropdown("settingsone");
          sesetOpen(false);
          accssetOpen(false);
          break;
        case "contactSetting":
          setOpenDropdown("settings");
          setOpenChildDropdown("settingstwo");
          sesetOpen(false);
          conopen(false);
          break;
        case "campaignSetting":
          setOpenDropdown("settings");
          setOpenChildDropdown("settingsthree");
          sesetOpen(false);
          cmpssetopen(false);
          break;
        case "meetingSetting":
          setOpenDropdown("settings");
          setOpenChildDropdown("settingsfour");
          sesetOpen(false);
          msetsetopen(false);
          break;
        case "potentialSetting":
          setOpenDropdown("settings");
          setOpenChildDropdown("settingsfive");
          sesetOpen(false);
          pmssetopen(false);
          break;
          case 'phoneSettings':
            setOpenDropdown("settings");
            break;
        case "admin":
          setOpenDropdown("admin");
          asetOpen(false);
          break;
        case "allClients":
          setOpenChildDropdown("adminone");
          setOpenDropdown("admin");
          allcsetopen(false);
          asetOpen(false);
          break;
        case "salesHiveUsers":
          setOpenChildDropdown("admintwo");
          setOpenDropdown("admin");
          shopen(false);
          asetOpen(false);
          break;
        case "adminSettings":
          setOpenChildDropdown("adminthree");
          setOpenDropdown("admin");
          asopen(false);
          asetOpen(false);
          break;
        case "backlinksPipeline":
        case "contentPipeline":
        case "seoReporting":
          setOpenDropdown("seo");
          setseoOpen(false);
          break;
        case "lists":
        case "prospects":
        case "accounts":
          setOpenDropdown("CRM");
          setpropectsOpen(false);
          break;
        case "help":
          setOpenDropdown("Help");
          setpropectsOpen(false);
          break;
        default:
          break;
      }
    }
  }, [path, groupName, setOpenDropdown]);

  useEffect(() => {
    let LoggedData = JSON.parse(localStorage.getItem("LoginData"));

    if (LoggedData && !LoggedUserDetails) {
      dispatch(
        userActions.getLoggerUserDetails(
          LoggedData.ChildUserID,
          LoggedData.Role,
          LoggedData.ParentUserID
        )
      );

      dispatch(
        clientActions.getClientListForTopDropDown(
          LoggedData.Role,
          LoggedData.ChildUserID,
          LoggedData.ParentUserID
        )
      );
    }
  }, [dispatch]);

  //Code for remove localstorage on next day...
  const checkLoginStatus = () => {
    // const pathname = window.location.pathname;
    // if(pathname !== "/addresponder" && pathname !== "/addcoldcallers" && pathname !== "/addadmin" &&
    // pathname !== "/addsalesstrategists" && pathname !== "/addusersperclient" && pathname !== "/resetpassword" &&
    // pathname !== "/updateemailresponder" && pathname !== "/updateemailcoldcallers" && pathname !== "/updateemailstrategists" &&
    // pathname !== "/updateemailadmin" && pathname !== "/updateusersperclient"){

    const loginTimestamp = localStorage.getItem("loginTimestamp");

    if (!loginTimestamp) {
      // No login timestamp found, redirect to login page
      redirectToLogin();
      return;
    }

    const loginDate = new Date(loginTimestamp);
    const now = new Date();
    // Reset the time part to midnight (00:00:00) for accurate date-only comparison
    loginDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    // Calculate the difference in time (in milliseconds) and convert to days
    const timeDifference = loginDate-now ; // This will be a positive or zero value
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Compare the stored date with the current date
    if (daysDifference > 7) {
      // It's a new day, redirect to login page
      redirectToLogin();
    }
    // }
  };

  // const isDifferentDay = (date1, date2) => {
  //   return (
  //     date1.getFullYear() !== date2.getFullYear() ||
  //     date1.getMonth() !== date2.getMonth() ||
  //     date1.getDate() !== date2.getDate()
  //   );
  // };

  const redirectToLogin = () => {
    // Clear the stored login timestamp
    localStorage.removeItem("loginTimestamp");
    localStorage.removeItem("LoginData");

    // Redirect to the login page
    history.push("/login");
  };

  const handleDropdownClick = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null);
      setOpenChildDropdown(null); // Close child dropdowns when parent is closed
    } else {
      setOpenDropdown(dropdown);
      setOpenChildDropdown(null); // Reset child dropdowns when parent changes
    }
  };

  const handleChildDropdownClick = (childDropdown) => {
    if (openChildDropdown === childDropdown) {
      setOpenChildDropdown(null);
    } else {
      setOpenChildDropdown(childDropdown);
    }
  };

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const logout = () => {
    localStorage.removeItem("LoginData");
    // Clear the stored login timestamp
    localStorage.removeItem("loginTimestamp");
    localStorage.removeItem("id");
    localStorage.removeItem("DynamicColdCallingFilterID");
    history.push("/");
  };

  //Open Edit profile and Profile page

  const addShowClass = () => {
    const element = document.getElementById("id_userbox");
    if (element.classList.contains("show")) {
      element.classList.remove("show");
    } else {
      element.classList.add("show");
    }
  };

  const handleListItemClick = (event, index) => {
    SetSelectMenuItem(index);
  };

  const handleInputChange = (event) => {
    //
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredOptions = ClientDropdown.filter((option) =>
      option.Name.toLowerCase().includes(searchTerm)
    );
    dispatch(clientActions.setFilteredOptions(filteredOptions));
    // setFilteredOptions(filteredOptions);
    setActiveIndex(-1); // Reset active index on new search
  };

  const handleOptionSelect = async (option, optionName) => {
    localStorage.setItem("clientChanged", Date.now());

    dispatch(clientActions.setSearchValue(optionName));
    setSearchTerm("");
    setIsOpen(false);
    // setFilteredOptions(ClientDropdown);
    dispatch(clientActions.setFilteredOptions(ClientDropdown));
    dispatch(clientActions.setClientID(option));
    dispatch(clientActions.setSelectedClientDropdown(option));

    UpdateUserDetails(option);
    var pagename = self.location.pathname;
    var firstthree = pagename.substring(1, 4).toLowerCase();
    var firstfour = pagename.substring(1, 5).toLowerCase();
    if (firstthree === "add" || firstfour === "edit" || firstfour === "view") {
      history.push("/bookedmeetings");
    } else {
      history.go(0);
    }
    localStorage.removeItem("DynamicColdCallingFilterID");
  };

  const SidebarListItem = ({ to, children }) => (
    <ListItem
      component={NavLink}
      activeClassName="cust"
      to={to}
      button
      className={classes.nested}
    >
      {children}
    </ListItem>
  );

  // Define URL and label pairs
  const meetingSettingItems = [
    { url: "/meetingowner", label: "Meeting Owners" },
    { url: "/meetinggoal", label: "Meeting Goals" },
    { url: "/meetingstatuses", label: "Meeting Statuses" },
    { url: "/meetingsource", label: "Meeting Sources" },
    { url: "/meetingreminder", label: "Meeting Reminder" },
    { url: "/meetingslacknotification", label: "Slack Notifications" },
    { url: "/meetingcustomfield", label: "Custom Fields" }
  ];

  const allClientItems = [
    { url: "/clientkpis", label: "Kpis" },
    { url: "/leaderboard", label: "LeaderBoard" },
    { url: "/clientaccount", label: "Accounts" },
    { url: "/clientcontact", label: "Contacts" },
    { url: "/clientcampaigns", label: "Campaigns" },
    { url: "/clientresponses", label: "Responses" },
    { url: "/clientmeetingremindererros", label: "Meeting Reminder Errors" },
    { url: "/clientmeetingreminderdefault", label: "Meeting Reminder Default" },
    { url: "/clientmeetings", label: "Meetings" },
    { url: "/clientrescheduling", label: "Rescheduling" },
    { url: "/clientpotentialmeetings", label: "Potential Meetings" },
    { url: "/clientlinkedinfollowUp", label: "LinkedIn Follow Ups" },
    { url: "/clientemailaccounts", label: "Email Accounts" },
    { url: "/pendinginvites", label: "Pending Invites" },
    { url: "/variablereporting", label: "Variable Reporting" },
    { url: "/userperclients", label: "User Per Clients" },
    { url: "/clientactionitems", label: "Action Items" },
    { url: "/clientdirectmail", label: "Direct Mail" },
    { url: "/clientresearch", label: "Research" },
    { url: "/clientlogin", label: "Logins" },
    { url: "/clientapikeys", label: "API Keys" }
  ];

 
  let drawer;
  if (UserRole === "SEO") {
    drawer = (
      <div>
        <List className="py-0">
          <ListItem
            className="menuline"
            button
            onClick={() => handleDropdownClick("seo")}
          >
            <img src={Speedscore} />
            SEO
            {!seoopen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse
            //in={!seoopen}
            in={openDropdown === "seo"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/seooverview">
                  Overview
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/keywords">
                  Keywords
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/backlinks">
                  Backlinks
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/corewebvitals">
                  Core Web Vitals
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink
                  isActive={(match, location) =>
                    pathGroups.backlinksPipeline.includes(location.pathname)
                  }
                 activeClassName="cust" to="/backlinkspipeline">
                  Backlink Pipeline
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/contentpipeline">
                  Content Calendar
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    );
  } else {
    drawer = (
      <div>
        <List className="py-0">
          <ListItem button>
            <NavLink
              activeClassName="cust"
              to="/kpis"
              selected={SelectMenuItem === "/kpis"}
              onClick={(event) => handleListItemClick(event, "/kpis")}
            >
              <img src={Kpis} alt="KPIs" />
              KPIs
            </NavLink>
          </ListItem>

          <ListItem
            className="menuline"
            button
            onClick={() => handleDropdownClick("CRM")}
          >
            <img src={Propects} alt="CRM" />
            CRM
            {!propectsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openDropdown === "CRM"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <NavLink
                  isActive={(match, location) =>
                    pathGroups.prospects.includes(location.pathname)
                  }
                  activeClassName="cust"
                  to="/contacts"
                >
                  Contacts
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink
                  isActive={(match, location) =>
                    pathGroups.accounts.includes(location.pathname)
                  }
                  activeClassName="cust"
                  to="/accounts"
                >
                  Accounts
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink
                  isActive={(match, location) =>
                    pathGroups.lists.includes(location.pathname)
                  }
                  activeClassName="cust"
                  to="/list"
                >
                  Lists
                </NavLink>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className="menuline"
            button
            onClick={() => handleDropdownClick("pipeline")}
          >
            <img src={meeting} alt="Pipeline" />
            Pipeline
            {!mopen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDropdown === "pipeline"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <NavLink 
                  isActive={(match, location) =>
                    pathGroups.potentialMeeting.includes(location.pathname)
                  } 
                  activeClassName="cust" to="/potentialmeeting">
                  Potential Meetings
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink
                  isActive={(match, location) =>
                    pathGroups.bookedMeeting.includes(location.pathname)
                  } 
                 activeClassName="cust" to="/bookedmeetings">
                  Booked Meetings
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/opportunities">
                  Opportunities
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/linkedinfollowup">
                  LinkedIn Follow Ups
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/reschedules">
                  Reschedules
                </NavLink>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className="menuline"
            button
            onClick={() => handleDropdownClick("phone")}
          >
            <img src={ColdCalling} alt="Phone" />
            Phone
            {!copen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={openDropdown === "phone"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                {/* <NavLink activeClassName="cust" to="/ColdCallingContact">
                  PB Dialer
                </NavLink> */}
                
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/ColdCallingContactlist">
                    Dialer
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/coldcallingreporting">
                  Call Reporting
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/CallHistory">
                  Call History
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/DNChistory">
                DNC.com History
                </NavLink>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className="menuline"
            button
            onClick={() => handleDropdownClick("email")}
          >
            <img src={EmailsIcon} alt="Email"/>
            Email
            {!hopen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={openDropdown === "email"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <NavLink
                  isActive={(match, location) =>
                    pathGroups.campaigns.includes(location.pathname)
                  }
                  activeClassName="cust" to="/campaigns">
                  Email Campaigns
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/emailresponses">
                  Email Responses
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/emailhistory">
                  Email History
                </NavLink>
              </ListItem>
              <ListItem
                className="menuline"
                button
                onClick={() => handleChildDropdownClick("emailone")}
              >
                Email Variables
                {!vopen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openChildDropdown === "emailone"}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <NavLink activeClassName="cust" to="/Variable">
                      Variable Sets
                    </NavLink>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <NavLink 
                      isActive={(match, location) =>
                        pathGroups.customVariable.includes(location.pathname)
                      }
                      activeClassName="cust" to="/customvariable">
                      Custom Variables
                    </NavLink>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <NavLink activeClassName="cust" to="/customvariableset">
                      Custom Variable Sets
                    </NavLink>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <NavLink activeClassName="cust" to="/calltoaction">
                      Call To Action
                    </NavLink>
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                className="menuline"
                button
                onClick={() => handleChildDropdownClick("emailtwo")}
              >
                Email Reporting
                {!emreopen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openChildDropdown === "emailtwo"}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <NavLink activeClassName="cust" to="/campaignsummary">
                      Campaign Summary
                    </NavLink>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <NavLink activeClassName="cust" to="/openratereporting">
                      Open Rate Reporting
                    </NavLink>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <NavLink activeClassName="cust" to="/clicktrackreporting">
                      Click Track Reporting
                    </NavLink>
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Collapse>

          <ListItem
            className="menuline"
            button
            onClick={() => handleDropdownClick("seo")}
          >
            <img src={Speedscore} alt="SEO" />
            SEO
            {!seoopen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={openDropdown === "seo"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/seooverview">
                  Overview
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/keywords">
                  Keywords
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/backlinks">
                  Backlinks
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/corewebvitals">
                  Core Web Vitals
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink
                  isActive={(match, location) =>
                    pathGroups.backlinksPipeline.includes(location.pathname)
                  }
                  activeClassName="cust" to="/backlinkspipeline">
                  Backlink Pipeline
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink
                  isActive={(match, location) =>
                    pathGroups.contentPipeline.includes(location.pathname)
                  } 
                  activeClassName="cust" to="/contentpipeline">
                  Content Calendar
                </NavLink>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className="menuline"
            button
            onClick={() => handleDropdownClick("tools")}
          >
            <img src={tools} alt="Tools" />
            Tools
            {!topen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDropdown === "tools"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/automator">
                  Automator
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/deliverabilitytesting">
                  Deliverability Testing
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/deliverabilitytracking">
                  Deliverability Tracking
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/despammer">
                  Despammer
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/emailvalidation">
                  Email Validation
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/HiveAi">
                  Hive AI
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/globalbounce">
                  Global Bounce
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/emailwarmup">
                  Email Warm Up
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/ContactBulkImportRequest">
                  Contact Bulk Import Request
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/ContactBulkExportRequest">
                  Contact Bulk Export Request
                </NavLink>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className="menuline"
            button
            onClick={() => handleDropdownClick("settings")}
          >
            <img src={settings} alt="Settings" />
            Settings
            {!seopen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDropdown === "settings"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/objections">
                  Objections
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/usersperclient">
                  Client Users
                </NavLink>
              </ListItem>
              <ListItem
                button
                className={`${classes.nested} menuline`}
                onClick={() => handleChildDropdownClick("settingsone")}
              >
                Account Settings
                {!accsopen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openChildDropdown === "settingsone"}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <SidebarListItem to="/accountcategory">Account Categories</SidebarListItem>
                  <SidebarListItem to="/accountclientstatus">Client Status</SidebarListItem>
                  <SidebarListItem to="/AccountReasonUnqualified">Reason Unqualified</SidebarListItem>
                  <SidebarListItem to="/customaccountfield">Custom Fields</SidebarListItem>
                </List>
              </Collapse>
              <ListItem
                button
                className={`${classes.nested} menuline`}
                onClick={() => handleChildDropdownClick("settingstwo")}
              >
                Contact Settings
                {!consopen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openChildDropdown === "settingstwo"}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <SidebarListItem to="/contactreasonunqualified">Reason Unqualified</SidebarListItem>
                  <SidebarListItem to="/ClientPOC">Client POC</SidebarListItem>
                  <SidebarListItem to="/contactcustomfield">Custom Fields</SidebarListItem>
                  <SidebarListItem to="/ContactSource">Contact Source</SidebarListItem>
                  <SidebarListItem to="/contacttag">Tag</SidebarListItem>
                </List>
              </Collapse>
              <ListItem
                button
                className={`${classes.nested} menuline`}
                onClick={() => handleChildDropdownClick("settingsthree")}
              >
                Campaign Settings
                {!cmpsopen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openChildDropdown === "settingsthree"}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <SidebarListItem to="/blacklists">Blacklists</SidebarListItem>
                  <SidebarListItem to="/EmailAccounts">Email Accounts</SidebarListItem>
                  <SidebarListItem to="/CampaignCategories">Campaign Categories</SidebarListItem>
                  <SidebarListItem to="/sendingschedular">Sending Schedules</SidebarListItem>
                  <SidebarListItem to="/emailsignature">Email Signature</SidebarListItem>
                  <SidebarListItem to="/countryblacklist">Country Blacklist</SidebarListItem>
                </List>
              </Collapse>
              <ListItem
                button
                className={`${classes.nested} menuline`}
                onClick={() => handleChildDropdownClick("settingsfour")}
              >
                Meeting Settings
                {!cmmsopen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openChildDropdown === "settingsfour"}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {meetingSettingItems.map((item) => (
                    <SidebarListItem to={item.url}>{item.label}</SidebarListItem>
                  ))}
                </List>
              </Collapse>
              <ListItem
                button
                className={`${classes.nested} menuline`}
                onClick={() => handleChildDropdownClick("settingsfive")}
              >
                Potential Meeting Settings
                {!pmsopen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openChildDropdown === "settingsfive"}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                <SidebarListItem to="/engagementstatus">Engagement Status</SidebarListItem>
                </List>
              </Collapse>

              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/phonesettings">
                Phone Settings
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/viewapiKeys">
                  API Key
                </NavLink>
              </ListItem>
              <ListItem button className={classes.nested}>
                <a
                  href="https://zapier.com/apps/saleshive/integrations"
                  target="_blank"
                >
                  Integrations
                </a>
              </ListItem>
            </List>
          </Collapse>
          {UserRole != "ColdCallers" ? (
            <>
              <ListItem
                className={`${classes.nested} menuline`}
                button
                onClick={() => handleDropdownClick("admin")}
              >
                <img src={admin} />
                Admin
                {!aopen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openDropdown === "admin"}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {UserRole == "Admin" ||
                  UserRole == "User" ? (
                    <ListItem button className={classes.nested}>
                      <NavLink activeClassName="cust" to="/clients">
                        Clients
                      </NavLink>
                    </ListItem>
                  ) : null}
                  {UserRole == "Admin" || UserRole == "User" ? (
                    <ListItem button className={classes.nested}>
                      <NavLink activeClassName="cust" to="/sdrassignments">
                        SDR Assignments
                      </NavLink>
                    </ListItem>
                  ) : null}

                  {UserRole == "Admin" || UserRole == "User" ? (
                    <ListItem button className={classes.nested}>
                      <NavLink activeClassName="cust" to="/emodprofile">
                        eMod Admin
                      </NavLink>
                    </ListItem>
                  ) : null}

                  <ListItem
                    button
                    className={`${classes.nested} menuline`}
                    onClick={() => handleChildDropdownClick("adminone")}
                  >
                    All Clients
                    {!allcopen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={openChildDropdown === "adminone"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {allClientItems.map((item) => (
                        <SidebarListItem to={item.url}>{item.label}</SidebarListItem>
                      ))}
                    </List>
                  </Collapse>

                  {/* SalesHive Users */}
                  {UserRole != "Responder" ? (
                    <>
                      <ListItem
                        button
                        className={`${classes.nested} menuline`}
                        onClick={() => handleChildDropdownClick("admintwo")}
                      >
                        SalesHive Users
                        {!shuopen ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openChildDropdown === "admintwo"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {UserRole != "SalesHacker" ? (
                            <>
                                <SidebarListItem to="/adminuser">Admins</SidebarListItem>
                                <SidebarListItem to="/salesstrategists">Sales Strategists</SidebarListItem>
                                <SidebarListItem to="/responder">Responders</SidebarListItem>
                                <SidebarListItem to="/Seo">SEO</SidebarListItem>
                            </>
                          ) : null}
                          <SidebarListItem to="/coldcallers">Sales Development Reps</SidebarListItem>
                          <SidebarListItem to="/coldcallerteams">Sales Development Teams</SidebarListItem>
                        </List>
                      </Collapse>
                    </>
                  ) : null}
                  {/* Admin Settings */}
                  <ListItem
                    button
                    className={`${classes.nested} menuline`}
                    onClick={() => handleChildDropdownClick("adminthree")}
                  >
                    Admin Settings
                    {!aseopen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={openChildDropdown === "adminthree"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <SidebarListItem to="/clientstatuses">Client Statuses</SidebarListItem>
                      <SidebarListItem to="/ResearchTeam">Research Teams</SidebarListItem>
                      <SidebarListItem to="/defaultvariables">Default Variables</SidebarListItem>
                      <SidebarListItem to="/globalbouncelist">Global Bounce List</SidebarListItem>
                      <SidebarListItem to="/triggerphrases">Trigger Phrases</SidebarListItem>
                      <SidebarListItem to="/smartvariable">Smart Variables</SidebarListItem>
                      <SidebarListItem to="/genericemaildomain">Generic Email Domains</SidebarListItem>
                      <SidebarListItem to="/defaultobjections">Default Objections</SidebarListItem>
                      <SidebarListItem to="/defaulttrackingdomain">Default Tracking Domains</SidebarListItem>
                      <SidebarListItem to="/salesrep">Sales Rep</SidebarListItem>
                      <SidebarListItem to="/globalblacklist">Global Blacklist</SidebarListItem>
                      <SidebarListItem to="/defaultcountryblacklist">Default Country Blacklist</SidebarListItem>
                      <SidebarListItem to="defaultphonesettings">
  Default Phone Settings
</SidebarListItem>
                    </List>
                    {UserRole == "Responder" ? null : (
                      <List component="div" disablePadding>
                        <SidebarListItem to="/coldcallerphoneburnerlogin">Sales Development Reps Phone Burner Login</SidebarListItem>
                      </List>
                    )}
                    {UserRole == "Admin" ? (
                      <List component="div" disablePadding>
                        <SidebarListItem to="/DefaultSlackNotification">Default Slack Notification</SidebarListItem>
                      </List>
                    ) : null}
                  </Collapse>
                </List>
              </Collapse>
            </>
          ) : null}

           
          {/* <ListItem button>
            <NavLink
              activeClassName="cust"
              to="/help"
              selected={SelectMenuItem === "/help"}
              onClick={(event) => handleListItemClick(event, "/help")}
            >
              <img src={Help} alt="KPIs" />
              Help
            </NavLink>
          </ListItem> */}
          <ListItem
            className="menuline"
            button
            onClick={() => handleDropdownClick("Help")}
          >
            <img src={Help} alt="Help" />
            Help
            {!seopen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDropdown === "Help"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <NavLink activeClassName="cust" to="/help">
                  Submit a Help Ticket
                </NavLink>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <NavLink 
                  activeClassName="cust"
                  to="/articlecategories"
                  isActive={() =>
                    ["/articlecategories", "/articlelists", "/addarticle", "/editarticle"].includes(location.pathname)
                  }
                >
                  Knowledge Base
                </NavLink>
              </ListItem>
            </List>
          </Collapse>

        </List>
      </div>
    );
  }

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <div className="header_main">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <div className="col col-lg-5 px-0">
              <div className="logonavbox">
                <a
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <div className="logodivider">
                    <img src={logo} alt="logo" />
                  </div>
                </a>
              </div>
            </div>
            <div className="col col-lg-7">
              <div className="righterblock">
                {IsClients == true ? (
                  <div
                    className="dropdown dropselect border-right-drop pr-3 py-3"
                    ref={dropdownRef}
                  >
                    <div
                      className="dropdown-header"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        if (!isOpen) {
                          const timeoutId = setTimeout(() => {
                            inputFocus.current.focus();
                            const selectedOption = ulRef.current.querySelector(".selected");
                            if (selectedOption) {
                              selectedOption.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              });
                            }
                          }, 100);
                        }
                      }}
                    >
                      <div className="dropdown-header-title">
                        {searchValue ? searchValue : "Select an option"}
                      </div>
                      <div className="dropdown-header-icon">
                        {isOpen ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    </div>
                    {isOpen && (
                      <div className="dropdown-options">
                        <input
                          ref={inputFocus}
                          type="text"
                          className="dropdown-search"
                          value={searchTerm}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          placeholder="Search options"
                        />
                        <ul
                          ref={ulRef}
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {filteredOptions.map((option, index) => (
                            <li
                              className={`${
                                searchValue === option.Name ? "selected" : ""
                              } ${activeIndex === index ? "activeoption" : ""}`}
                              key={option.ClientID}
                              onClick={() =>
                                handleOptionSelect(option.ClientID, option.Name)
                              }
                            >
                              {option.Name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ) : null}

                <a
                  href="#"
                  className="m-nav__link m-dropdown__toggle py-2"
                  onClick={addShowClass}
                >
                  <span className="m-topbar__userpic">
                    <img
                      width="40px"
                      src={
                        !LoggedUserDetails || LoggedUserDetails?.length === 0
                          ? CommonConstants.Image_url + "default.png"
                          : CommonConstants.Image_url +
                            LoggedUserDetails?.ProfileImage
                      }
                    ></img>
                  </span>
                  <div className="usenav-profile">
                    <span className="card-usertitel">
                      {!LoggedUserDetails || LoggedUserDetails?.length === 0
                        ? ""
                        : LoggedUserDetails.FirstName +
                          " " +
                          LoggedUserDetails.LastName}
                    </span>
                    <Link to="/EditMyProfile">
                      {!LoggedUserDetails || LoggedUserDetails?.length === 0
                        ? ""
                        : LoggedUserDetails.Username}
                    </Link>
                  </div>
                </a>

                <div className="userdropdown" id="id_userbox">
                  <div className="bg-themehead">
                    <div className="m-card-user m-card-user--skin-dark">
                      <div className="carduser_pic">
                        <img
                          width="40px"
                          src={
                            !LoggedUserDetails ||
                            LoggedUserDetails?.length === 0
                              ? CommonConstants.Image_url + "default.png"
                              : CommonConstants.Image_url +
                                LoggedUserDetails?.ProfileImage
                          }
                        ></img>
                      </div>
                      <div className="carduser_details">
                        <span className="card-usertitel  text-white">
                          {!LoggedUserDetails || LoggedUserDetails?.length === 0
                            ? ""
                            : LoggedUserDetails.FirstName +
                              " " +
                              LoggedUserDetails.LastName}
                        </span>
                        <Link to="/EditMyProfile">
                          {!LoggedUserDetails || LoggedUserDetails?.length === 0
                            ? ""
                            : LoggedUserDetails.Username}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="bodyuserdop">
                    <ul>
                      <li>
                        <Link to="/EditMyProfile">
                          <i className="m-nav__link-icon flaticon-profile-1"></i>{" "}
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <a className="Logoutbtn btn" onClick={logout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        <nav className={classes.drawer} aria-label="mailbox folders"></nav>
      </div>

      <Drawer
        className="sidebarleft"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {drawer}
      </Drawer>
      <Main open={open}></Main>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
