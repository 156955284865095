import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Footer from "../../_components/user/footer/footer";
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import LinkedInFollowUp from '../../_components/clients/linkedInfollowup/linkedInfollowup'; 
class CLinkedInFollowUpPage extends React.Component {
  componentDidMount() {
    window.addEventListener('storage', (event) => {
            
      if (event.key === 'clientChanged') {
        window.location.reload();
      }
    });
}
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlebd pl-0 mb-0">
                    LinkedIn Follow Up
                  </h4>
                </div>
              </div>
            <div class="paddcols">
              <div className="row">
                <div className="col">
                  <div className="">
                    <LinkedInFollowUp />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCLinkedInFollowUpPage = connect(mapState, actionCreators)(CLinkedInFollowUpPage);
export { connectedCLinkedInFollowUpPage as CLinkedInFollowUpPage };