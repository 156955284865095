import React from 'react';
import Axios from "axios";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../kpis/lastdaysTabing';
import { useLocation } from 'react-router-dom';
import CollapsibleTable from "./Meetingstable"
import AccountsTable from '../kpis/Accountstable';
import ContactsDetailsTable from '../kpis/Contactsdetailstable';
import ResponseDetailsTable from '../kpis/ResponseDetailsTable';


//chart
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart  from "react-apexcharts";

import Overview from '../../../images/header/overview.svg';
import Twohours from '../../../images/header/24-hours.svg';
import MeetingOrange from '../../../images/header/meeting-orange.svg';
import AccountOrange from '../../../images/header/Account_orange.svg';
import ContactOrange from '../../../images/header/contact_orange.svg';
import EmailOrange from '../../../images/header/email_orange2.svg';
import EmailOrangeBlack from '../../../images/header/email_orange.png';
import lgUserSky from "../../../images/header/lgUserSky.svg";
import lgEmailorange from '../../../images/header/email_orange.svg';
import lgconversationSky from '../../../images/header/conversation_sky.svg';
import lgstopwatchOrange from '../../../images/header/stopwatch_orange.svg';
import lgdollarPurple from '../../../images/header/dollar_purple.svg';
import OpenEmailMessage from '../../../images/header/open-email-message.svg';
import JobInterview from '../../../images/header/job-interview.svg';
import MeetingGreen from '../../../images/header/meeting-green.svg';
import Response from '../../../images/header/response.svg';

import ReactApexchart from "react-apexcharts";
import { useEffect } from "react";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
toast.configure();
import loadingicon from "../../../images/loading.gif";
import KPIsReporting from '../../clients/kpis/KPIsReporting'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ FromDates, ToDates,IsAnalyze }) {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [ToDateContact, SetToDateContact] = React.useState("");
  const [UserID, SetUserID] = React.useState("");
  const [Role, SetRole] = React.useState("");
  const [ClientID, SetClientID] = React.useState("");
  const [FromDateContact, SetFromDateContact] = React.useState("");
  const [TotalMeetingCount, SetTotalMeetingCount] = React.useState(0);
  const [CrushRate, SetCrushRate] = React.useState(0);
  const [TotalPipeline, SetTotalPipeline] = React.useState(0);
  const [AvgMeetingPerWeek, SetAvgMeetingPerWeek] = React.useState(0);
  const [TotalMeetingList, SetTotalMeetingList] = React.useState([]);
  const [TotalGoalList, SetTotalGoalList] = React.useState([]);
  const [WeekDayList, SetWeekDayList] = React.useState([]);
  const [EmailSentPerWeek, SetEmailSentPerWeek] = React.useState(0);
  const [AvgAccountsContactedPeWeek, SetAvgAccountsContactedPeWeek] = React.useState(0);
  const [TotalEmailSent, SetTotalEmailSent] = React.useState(0);
  const [TotalOpenPer, SetTotalOpenPer] = React.useState(0);
  const [TotalMeetingL7, SetTotalMeetingL7] = React.useState(0);
  const [TotalPotenationalMeetingL7, SetTotalPotenationalMeetingL7] = React.useState(0);
  const [TotalResponse, SetTotalResponse] = React.useState(0);
  const [GraphContactSentList, SetGraphContactSentList] = React.useState([]);


  const [TotalSent, SetTotalSent] = React.useState([]);
  const [TotalResponse1, SetTotalResponse1] = React.useState([]);
  const [WeekDay, SetWeekDay] = React.useState([]);
  const [TotalMeetingList1, SetTotalMeetingList1] = React.useState([]);
  const [CUserID, SetCUserID] = React.useState(0)
  const [IsLoading1, SetIsLoading1] = React.useState(false);

  const [IsLoading, SetIsLoading] = React.useState(false);
  const [TotalActiveContact, SetTotalActiveContact] = React.useState(0);
  useEffect(() => {

    var Details = GetUserDetails();
    if (Details != null) {
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetClientID(Details.ClientID);
      SetRole(Details.Role);
      SetToDateContact(ToDates);
      SetFromDateContact(FromDates);
      if(ToDates!="" && FromDates!=""){
        GetEmailKeyMatric(Details.ClientID,Details.ParentUserID,Details.Role,FromDates, ToDates,);
        KpiEmailSentPerWeek(FromDates, ToDates, Details.ClientID, Details.Role,Details.ParentUserID)
        MeetingAnalyticStatistics(FromDates, ToDates, Details.ClientID, Details.Role)
        KpiAvgAccountConnectedPerWeek(FromDates, ToDates, Details.ClientID, Details.Role)
        ActiveContactStatistics(Details.ClientID)
      }
      setValue(location.state && location.state.data || 0)
    }
  }, [FromDates, ToDates, ClientID]);



  const KpiEmailSentPerWeek = (FromDate, ToDate, CID, role,UserID) => {
    SetIsLoading(true);
    var InputParameter = {
      FromDate: FromDate,
      ToDate: ToDate,
      ClientID: CID,
      UserID:UserID,
      Role: role
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiEmailSentPerWeek",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res?.data?.StatusMessage == "SUCCESS") {
        var totalSentWithoutSkipEmail = res?.data?.Data?.totalSentWithoutSkipEmail;
        var totalWeeks = res?.data?.Data?.totalWeeks;
        const emailSentPerWeek = totalSentWithoutSkipEmail !== 0 && totalWeeks !== 0 
        ? Math.round(totalSentWithoutSkipEmail / totalWeeks) 
        : 0;

        SetEmailSentPerWeek(emailSentPerWeek);

        SetIsLoading(false);
      } else {
        SetEmailSentPerWeek(0)
        SetIsLoading(false);

      }
    });
  }

  const KpiAvgAccountConnectedPerWeek = (FromDate, ToDate, CID, role) => {
    SetIsLoading(true);

    var InputParameter = {
      FromDate: FromDate,
      ToDate: ToDate,
      ClientID: CID,
      Role: role

    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiAvgAccountConnectedPerWeek",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res?.data?.StatusMessage == "SUCCESS") {

        var totalSentEmail = res?.data?.Data?.totalSentEmail;
        var totalWeeks = res?.data?.Data?.totalWeeks;
        const avgAccountsContactedPerWeek = totalSentEmail !== 0 && totalWeeks !== 0 
          ? Math.round(totalSentEmail / totalWeeks) 
          : 0;

          SetAvgAccountsContactedPeWeek(avgAccountsContactedPerWeek);

        SetIsLoading(false);

      } else {
        SetIsLoading(false);

      }
    });
  }

  const GetEmailKeyMatric = (CID, UID, URole, FromDate, ToDate) => {
    SetIsLoading1(true);
    var InputParameter = {
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      Fromdate: FromDate,
      Todate: ToDate,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis_email/EmailSentMatricGet",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.KpiEmailKeymatric.length > 0) {
          var lstTotalSent = [];
          var lstTotalResponse = [];
          var lstWeekDay = [];
          var objtotalmeeting = 0;
          var objTotalContact = 0;
          var objtotalReceivedEmail = 0;
          for (var i = 0; i < Res.data.KpiEmailKeymatric.length; i++) {
            var totalcontactcount = Res.data.KpiEmailKeymatric[i].TotalContact;
            objTotalContact = objTotalContact + totalcontactcount;

            var totalReceivedEmail =
              Res.data.KpiEmailKeymatric[i]?.ReceivedEmail;
            objtotalReceivedEmail = objtotalReceivedEmail + totalReceivedEmail;

            var totalmeeting = Res.data.KpiEmailKeymatric[i].TotalEvents;
            objtotalmeeting = objtotalmeeting + totalmeeting;

            var objWeekDay = Res.data.KpiEmailKeymatric[i].WeekDay;

            lstTotalSent.push(totalcontactcount);
            lstTotalResponse.push(totalReceivedEmail);
            lstWeekDay.push(objWeekDay);
          }
          SetGraphContactSentList(lstTotalSent);
          SetWeekDay(lstWeekDay);
          SetIsLoading1(false);
        } else {
         
          SetGraphContactSentList([]);
          SetWeekDay([]);
          SetIsLoading1(false);
        }
      } else {
       
        SetGraphContactSentList([]);
        SetWeekDay([]);
        SetIsLoading1(false);
      }
    });
  };

  const MeetingAnalyticStatistics = (FromDate, ToDate, CID, role) => {
    SetIsLoading(true);

    var InputParameter = {
      FromDate: FromDate,
      ToDate: ToDate,
      ClientID: CID,
      Role: role

    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/MeetingAnalyticStatistics",
      method: "POST",
      data: InputParameter,
    }).then((res) => {

      if (res?.data?.StatusMessage == "SUCCESS") {
        if (res?.data?.Data?.length > 0) {
          var TotalMeeting = 0, TotalGoal = 0, ContractValue = 0;
          var lstTotalGoal = [];
          var lstTotalEvent = [];
          var lstWeekDay = [];
          for (var i = 0; i < res.data.Data.length; i++) {
            var objTotalMeeting = res.data.Data[i].TotalEvent;
            var objTotalGoal = res.data.Data[i].TotalGoal;
            var objContractValue = res.data.Data[i].ContractValue;
            var GraphGoal = res.data.Data[i].GraphTotalGoal;
            var GraphEvent = res.data.Data[i].GraphTotalMeeting;

            lstTotalGoal.push(GraphGoal);
            lstTotalEvent.push(GraphEvent);
            lstWeekDay.push(res.data.Data[i].WeekDay)
            TotalMeeting = TotalMeeting + objTotalMeeting;
            TotalGoal = TotalGoal + objTotalGoal;
            ContractValue = ContractValue + objContractValue;
          }
          SetWeekDayList(lstWeekDay);
          SetTotalGoalList(lstTotalGoal);
          SetTotalMeetingList(lstTotalEvent);

          SetTotalMeetingCount(TotalMeeting);

          var TotalWeek = res.data.Data.length;
          if (TotalMeeting > 0 && TotalWeek > 0) {
            SetAvgMeetingPerWeek(Math.round(TotalMeeting / TotalWeek))
          }
          if (TotalMeeting > 0 && TotalGoal > 0) {
            SetCrushRate(Math.round(TotalMeeting * 100 / TotalGoal));
          }
          SetTotalPipeline(Math.trunc(ContractValue));
          SetIsLoading(false);


        } else {
          SetIsLoading(false);

        }

      } else {
        SetIsLoading(false);

      }
    });
  }

  const KpiLastSevenDayKeyMatric = () => {
  //  SetIsLoading(true);
    var InputParameter = {
      ClientID: ClientID,
      Role: Role
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiLastSevenDayKeyMatric",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {

        if (res.data.Data.CampaignList.length > 0) {
          var TotalSent = 0, TotalOpen = 0, TotalTracked = 0, TotalMeeting
          for (var i = 0; i < res.data.Data.CampaignList.length; i++) {

            var objTotalSent = res.data.Data.CampaignList[i].CampaignStepHistory != null ? res.data.Data.CampaignList[i].CampaignStepHistory.TotalSendL7 : 0
            TotalSent = TotalSent + objTotalSent;

            var objTotalOpen = res.data.Data.CampaignList[i].CampaignStepHistory != null ? res.data.Data.CampaignList[i].CampaignStepHistory.TotalOpenL7 : 0
            TotalOpen = TotalOpen + objTotalOpen;

            var objTotalTracked = res.data.Data.CampaignList[i].CampaignStepHistory != null ? res.data.Data.CampaignList[i].CampaignStepHistory.TotalTrackedL7 : 0
            TotalTracked = TotalTracked + objTotalTracked;
          }
          SetTotalEmailSent(TotalSent);
          SetTotalOpenPer(Math.round(TotalTracked > 0 ? (TotalOpen / TotalTracked) * 100 : 0))
          SetIsLoading(false);
        }
        if (res.data.Data.MeetingDetails.length > 0) {
          SetTotalMeetingL7(res.data.Data.MeetingDetails.length)
        }
        if (res.data.Data.PotentialMeetingList.length > 0) {
          SetTotalPotenationalMeetingL7(res.data.Data.PotentialMeetingList.length)
        }
        if (res.data.Data.ResponseDetails.length > 0) {
          SetTotalResponse(res.data.Data.ResponseDetails.length)
        }
        SetIsLoading(false);

      } else {
        SetIsLoading(false);

      }
    });
  }
  const ActiveContactStatistics = (CID) => {
    var InputParameter = {
      ClientID: CID,
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/kpisactivecontact",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      
      if (res?.data?.StatusMessage == "SUCCESS") {
        SetTotalActiveContact(res?.data?.TotalActiveContact)
      } else {
        SetTotalActiveContact(0)
      }
    });
  }

  charts(FusionCharts);


  const state = {
    series: [
      {
        name: "Cumulative Goal",
        color: "#360947",
        data: TotalGoalList,
      },
      {
        name: "Cumulative Booked Meetings",
        color: "#F8BF58",
        data: TotalMeetingList,
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
          show: false,  
        } 
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: WeekDayList,
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6 // Size of the marker when hovered
        }
      }
    },
  };

  const EmailStatusBarChart = {
    series: [
      {
        name: "Sent",
        color: "#F8BF58",
        data: GraphContactSentList,
      },
      
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
          show: false,  
        } 
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: WeekDay,
        title: {
          text: "Weeks",
        },
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      }, 
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6 // Size of the marker when hovered
        }
      }
      // legend: {
      //   position: "right",
      //   horizontalAlign: "bottom",
      //   offsetX: -10,
      // },
    },
  };


  const ramp_chart = {
    series: [
      {
        name: "1-3 months",
        color: "#F94144",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "3-5 months",
        color: "#90BE6D",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "5-7 months",
        color: "#F9C74F",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "7+ months",
        color: "#F3722C",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      title: {
        text: "Ramp Time by ACV",
        align: "left",
        fontSize: "22px",
      },
      chart: {
        type: "line",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,  
        } 
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
        },
      },
      xaxis: {
        type: "datetime",
        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
      },
      legend: {
        position: "top",
        offsetY: 0,
        offsetX: 0,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  const Crushchart = {
    series: [CrushRate],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,  
        } 
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#ebebeb",
            strokeWidth: "97%",
            opacity: "1",
            margin: 5, // margin is in pixels
            // dropShadow: {
            //   enabled: true,
            //   top: 2,
            //   left: 0,
            //   color: '#ccc',
            //   opacity: 1,
            //   blur: 10
            // }
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "25px", 
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },

      fill: {
        type: "colors",
        colors: "#360947",
        opacity: "1",
        // gradient: {
        //   shade: 'light',
        //   shadeIntensity: 0.4,
        //   inverseColors: false,
        //   opacityFrom: 1,
        //   opacityTo: 1,
        //   stops: [48, 46, 73, 1]
        // },

      },
      labels: ["CRUSH %"],
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      //Last 7 Day Tab
      KpiLastSevenDayKeyMatric();

    }
  };


  return (
    <div className="tabdes">
      {(IsLoading || IsLoading1) ? <><div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div></>:<></>}
      <div className=''>
        <AppBar position="static" className='mb-0'>
          <Tabs
            value={value}
            onChange={handleChange} variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab
              label="Overview"
              //icon={<img src={Overview} />}
              {...a11yProps(0)}
            >
              Overviewasdasd
            </Tab>
            <Tab
              label="Last 7 Days"
              //icon={<img src={Twohours} />}
              {...a11yProps(1)}

            />
            <Tab
              label="Meetings"
              //icon={<img src={MeetingOrange} />}
              {...a11yProps(2)}
            />
            <Tab
              label="Accounts"
              //icon={<img src={AccountOrange} />}
              {...a11yProps(3)}
            />
            <Tab
              label="Contacts"
              //icon={<img src={ContactOrange} />}
              {...a11yProps(4)}
            />
            <Tab
              label="Emails"
              //icon={<img src={EmailOrange} />}
              {...a11yProps(5)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone indev0 pt-4" index={0}>
          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="cardbox pb-3 h-100 px-3 pt-1 d-grid">
              <h3 className="smheadtext xs-headertitle xs-headertitle pb-3">CRUSH %</h3>
                <div id="chart" className="w-100">
                  <ReactApexChart height={300}
                    options={Crushchart.options}
                    series={Crushchart.series}
                    type="radialBar"
                  />
                  {/* <lable className="lablecrush">CRUSH %</lable> */}
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="cardbox h-100">
                <div className="pad-y20 py-1">
                  <h3 className="smheadtext xs-headertitle">Activity KPIs</h3>
                  <div className="d-flex pb-2 mb-2 border-bottom">
                    {/* <div className="float-left">
                      <div className="iconsboxsround">
                        <img src={lgUserSky} />
                      </div>
                    </div> */}
                    <div className="contectboxes pl-0">
                      <label>{AvgAccountsContactedPeWeek}</label>
                      <p>Avg. Accounts Contacted Per Week</p>
                    </div>
                  </div>

                  <div className="d-flex mb-2">
                    {/* <div className="float-left">
                      <div className="iconsboxsround">
                        <img src={lgEmailorange} />
                      </div>
                    </div> */}
                    <div className="contectboxes pl-0">
                      <label>{EmailSentPerWeek}</label>
                      <p>Emails Sent Per Week</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="cardbox h-100">
                <div className="pad-y20">
                  <h3 className="smheadtext xs-headertitle">Meeting KPIs</h3>
                  <div className="d-flex mb-2">
                    {/* <div className="float-left">
                      <div className="iconsboxsround">
                        <img src={lgconversationSky} />
                      </div>
                    </div> */}
                    <div className="contectboxes pl-0">
                      <label>{AvgMeetingPerWeek}</label>
                      <p>Avg. Meetings Per Week</p>
                    </div>
                  </div>

                  <div className="d-flex py-2 my-2 border-top">
                    {/* <div className="float-left">
                      <div className="iconsboxsround">
                        <img src={lgstopwatchOrange} />
                      </div>
                    </div> */}
                    <div className="contectboxes pl-0">
                      <label>{TotalMeetingCount}</label>
                      <p>Meetings Set</p>
                    </div>
                  </div>

                  {/* <div className="d-flex mb-4">
                    <div className="float-left">
                      <div className="iconsboxsround">
                        <img src={lgdollarPurple} />
                      </div>
                    </div>
                    <div className="contectboxes pl-0">
                      <label>${TotalPipeline}</label>
                      <p>Potential Pipeline Value</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div> 
            <div className="col-md-3 mb-3">
              <div className="cardbox h-auto">
                <div className="pad-y20">
                  <h3 className="smheadtext xs-headertitle">Contact KPIs</h3>
                  <div className="d-flex mb-2">
                    <div className="contectboxes pl-0">
                      <label>{TotalActiveContact}</label>
                      <p>Active Contact</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
         
            <div className="col-xs-12 mt-3">
              <div className="row">
                <div className="col-xl-6">
                <div className="cardbox">
                  <h3 className="smheadtext xs-headertitle pl-3">Weekly Goals</h3>
                  <div id="chart" className="px-3">
                    <ReactApexchart
                      options={state.options}
                      series={state.series}
                      type="line"
                      height={350}
                    />
                  </div>
                  </div>
                </div>
                <div className="col-xl-6">
                <div className="cardbox">
                  <h3 className="smheadtext xs-headertitle pl-3 py-3">Emails Sent Per Week</h3>
                  <div id="chart" className="px-3 py-3">
                  <ReactApexchart
                    options={EmailStatusBarChart.options}
                    series={EmailStatusBarChart.series}
                    type="line"
                    height={350}
                  />
                  </div>
                  </div>
                </div>
              </div> 
          </div>
          
{/* 
          <div className="row mt-3">
            <div className="col-xl-6 pt-4">
              <div className="cardbox px-3 ppeg">
                <h3 className="smheadtext xs-headertitle py-4 px-2">Ramp</h3>
                <p>
                  Companies with ACVs greater than $50K report average ramp time
                  of 6 months while those with ACVs below $5K average 4.8. That’s
                  25% longer.
                </p>
                <p>
                  You might think that insignificant, but recall that higher ACV
                  companies are hiring more senior reps. It appears that more
                  experience alone isn’t able to offset the complexities of a
                  larger ACV – with longer sales cycles, more buyers, and assorted
                  other challenges.
                </p>
              </div>
            </div>
            <div className="col-xl-6 pt-4">
              <div className="cardbox px-3 pt-3">
                <div className="maxchart-630">
                  <div id="chart">
                    <ReactApexchart
                      options={ramp_chart.options}
                      series={ramp_chart.series}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
       <div className="whiterow px-3 my-4">
            <div className="row">
              <div className="col px-0">
                <KPIsReporting FromDate={FromDates} ToDate={ToDates} IsAnalyze={IsAnalyze} />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone indev0 pt-4" index={1}>
          <div className="row colfive px-2">
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  {/* <div className="float-left">
                    <div class="bg-white-round">
                      <img src={EmailOrangeBlack} class="kpi-icon" />
                    </div>
                  </div> */}
                  <div className="contectboxes pl-0">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalEmailSent}</span>
                    </div>
                    <div class="box-inner-title xs-headertitle">
                      <string class="clr-sky">Emails Sent</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  {/* <div className="float-left">
                    <div class="bg-white-round">
                      <img src={OpenEmailMessage} class="kpi-icon" />
                    </div>
                  </div> */}
                  <div className="contectboxes pl-0">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalOpenPer}</span>
                    </div>
                    <div class="box-inner-title xs-headertitle">
                      <string class="clr-sky">Open %</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  {/* <div className="float-left">
                    <div class="bg-white-round">
                      <img src={JobInterview} class="kpi-icon" />
                    </div>
                  </div> */}
                  <div className="contectboxes pl-0">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalPotenationalMeetingL7}</span>
                    </div>
                    <div class="box-inner-title xs-headertitle">
                      <string class="clr-sky">Potential Meetings Set</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  {/* <div className="float-left">
                    <div class="bg-white-round">
                      <img src={MeetingGreen} class="kpi-icon" />
                    </div>
                  </div> */}
                  <div className="contectboxes pl-0">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalMeetingL7}</span>
                    </div>
                    <div class="box-inner-title xs-headertitle">
                      <string class="clr-sky">Meetings Set</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div class="bg-white boxcardcounter">
                <div class="d-flex">
                  {/* <div className="float-left">
                    <div class="bg-white-round">
                      <img src={Response} class="kpi-icon" />
                    </div>
                  </div> */}
                  <div className="contectboxes pl-0">
                    <div class="line-title">
                      <span class="clr-sky box-main-title">{TotalResponse}</span>
                    </div>
                    <div class="box-inner-title xs-headertitle">
                      <string class="clr-sky">Responses</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div className="row mt-3">
            <div className="col px-4">
              <LastdaysTabing />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} class="tagpaddnone indev0 pt-4" index={2}>
          <div className="row">
            <div className="col px-3"> 
                <CollapsibleTable FromDateMeeting={FromDateContact} ToDateMeeting={ToDateContact} /> 
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} class="tagpaddnone indev0 pt-4" index={3}>
          <div className="row mx-0">
            <div className="col"> 
                <AccountsTable FromDateAccount={FromDateContact} ToDateAccount={ToDateContact} /> 
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} class="tagpaddnone indev0 pt-4" index={4}> 
          <div className="row mx-0">
            <div className="col">
              <ContactsDetailsTable FromChildDatesContact={FromDateContact} ToChildDatesContact={ToDateContact} />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone indev0 pt-4" index={5}> 
          <ResponseDetailsTable FromChildDatesEmail={FromDateContact} ToChildDatesEmail={ToDateContact} /> 
        </TabPanel>
      </div>

    </div>
  );
}