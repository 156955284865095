import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

export default function CamapignCategoryTable() {
  const [Data, SetData] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CampaignINData, SetCampaignINData] = React.useState([]);
  const [CampaignINFlen, SetCampaignINFlen] = React.useState(0);
  const [CampaignINSflag, SetCampaignINSflag] = React.useState(false);
  const [CampaignINRlen, SetCampaignINRlen] = React.useState(0);
  const [CampaignINCountPage, SetCampaignINCountPage] = React.useState(100);
  const [CampaignINRows, SetCampaignINRows] = React.useState([]);
  const [CampaignINPage, SetCampaignINPage] = React.useState(1);
  const [CampaignINRowsPerPage, SetCampaignINRowsPerPage] = React.useState(100);
  const [CampaignINSearch, SetCampaignINSearch] = React.useState("");
  const [CampaignINSortField, SetCampaignINSortField] = React.useState("Name");
  const [CampaignINSortedBy, SetCampaignINSortedBy] = React.useState(1);
  const [CategoryValueOne, SetCategoryValueOne] = React.useState(null);
  const [ArrayCheck, SetArrayCheck] = React.useState([]);
  const [ArrayUnCheck, SetArrayUnCheck] = React.useState([]);
  const [ IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsSaveBtnDisabled,SetIsSaveBtnDisabled] = React.useState(false);
  const [ErroePopUpSet,SetErroePopUpSet] = React.useState(null);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [SelectedAll,SetSelectedAll] = React.useState(false);

  
  useEffect(() => {
    // var CategoryId =  document.getElementById("CategoryID").value
    document.title = "Add Campaign Category | SalesHive";
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      SetClientID(getaccountcatelist.ClientID);
      SetUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      SetRole(getaccountcatelist.Role);
    }
    CampaignCategorylistGet(
      getaccountcatelist.ClientID,
      getaccountcatelist.ParentUserID,
      getaccountcatelist.Role
    );
    // CampaignInCategorylistGet(getaccountcatelist.ClientID,getaccountcatelist.ChildUserID)
  }, [
    CampaignINPage,
    CampaignINSearch,
    CampaignINRowsPerPage,
    CampaignINSortedBy,
    CategoryValueOne,
  ]);

  //Get Category List
  const CampaignCategorylistGet = (CID, UID, role) => {
    SetIsLoading(true)

    var str_in = {
      ClientID: CID,
      UserID: UserID,
      Role: role,
    };

    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/Campaigncategory/CategoryListGet2",
      method: "POST",
      data: str_in,
    });
    Rows1.then((result) => {
      SetData(result.data.PageData);
      if (CategoryValueOne != null) {
        CampaignInCategorylistGet(CID, UID, CategoryValueOne, role);
    SetIsLoading(false)

      } else {
        CampaignInCategorylistGet(
          CID,
          UID,
          result.data.PageData[0]?._id,
          role
        );
    SetIsLoading(false)
    setIsDataLoaded(true);

      }
    });
  };

  // CampaignIn category get list
  const CampaignInCategorylistGet = async (CID, UID, CTID, role) => {
    SetIsLoading(true)
    SetCategoryValueOne(CTID);
    var str_in = {
      Page: CampaignINPage,
      RowsPerPage: CampaignINRowsPerPage,
      Sort: true,
      Field: CampaignINSortField,
      SortBy: CampaignINSortedBy,
      Search: CampaignINSearch,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      CategoryID: CTID,
      Role: role,
    };

    const Rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/Campaigncategory/CampaignInCategoryList",
      method: "POST",
      data: str_in,
    });
    ;
    SetCampaignINData(Rows1.data.PageData);
    SetCampaignINRows(Rows1.data.PageData);
    SetCampaignINRlen(Rows1.data.TotalCount);
    SetCampaignINFlen(Rows1.data.TotalCount);
    SetCampaignINCountPage(Rows1.data.PageCount);
    if (Rows1.data.PageData.length !== 0) {
      let TotalPagesize = Rows1.data.PageData.length;
      let TotalChecked = 0;
  
      var tempresult = Rows1.data.PageData.map((item) => {
        const element = document.getElementById(item._id);
        if (element) {
        if (item.CampaignInCategpryList.length !== 0 || ArrayCheck.some(ele => ele.CampaignID === item._id)) {
          document.getElementById(item._id).checked = true;
          TotalChecked = TotalChecked + 1;
          SetErroePopUpSet(TotalChecked)
        } else {
          document.getElementById(item._id).checked = false;
          
        }
      }
      });

      if (TotalPagesize === TotalChecked) {
        // document.getElementById("SelecteAll").checked = true;
        SetSelectedAll(true)
      }else{
        // document.getElementById("SelecteAll").checked = false;
        SetSelectedAll(false)

      }

      const results = await Promise.all(tempresult);
    }
    SetIsLoading(false)

  };

  //Search campaign in category search list
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var searchedVal = document.getElementById("search").value;
      if (searchedVal == CampaignINSearch) {
        SetCampaignINSearch(searchedVal);
        SetCampaignINPage(1);
      } else {
        SetCampaignINSearch(searchedVal);
        SetCampaignINPage(1);
        
      }
    }
  };

  //change display rows
  const ChangeRowSelected = (event) => {
    
    SetCampaignINRowsPerPage(Number(event.target.value));
    SetCampaignINPage(1);
    
  };

  //Handle change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == CampaignINPage) {
      SetCampaignINPage(NewPage);
    } else {
      SetCampaignINPage(NewPage);
      
    }
  };

  // campaign category in handle change
  const HandleDropdownChangeCampaignCategory = (e) => {
    SetCategoryValueOne(e.target.value);
  };

  // check box handle change
const CheckBoxAdd = async (e, ID) => {

  const isChecked = document.getElementById(ID).checked;
  const ChArr = [...ArrayCheck];
  const UnChArr = [...ArrayUnCheck];

  if (isChecked) {
    // Add to ChArr if not already present
    if (!ChArr.some(item => item.CampaignID === ID)) {
      ChArr.push({ CampaignID: ID });
    }

    // Remove from UnChArr if present
    const indexInUnChArr = UnChArr.findIndex(item => item.CampaignID === ID);
    if (indexInUnChArr !== -1) {
      UnChArr.splice(indexInUnChArr, 1);
    }

    
    if (!document.getElementById("SelecteAll").checked && CampaignINRows.every(row => ChArr.some(item => item.CampaignID === row._id))) {
      document.getElementById("SelecteAll").checked = true;
      SetSelectedAll(true)
    }
  } else {
    // Add to UnChArr if not already present
    if (!UnChArr.some(item => item.CampaignID === ID)) {
      UnChArr.push({ CampaignID: ID });
    }

    // Remove from ChArr if present
    const indexInChArr = ChArr.findIndex(item => item.CampaignID === ID);
    if (indexInChArr !== -1) {
      ChArr.splice(indexInChArr, 1);
    }

    // Uncheck Select All checkbox
    if (document.getElementById("SelecteAll").checked ) {
      document.getElementById("SelecteAll").checked = false;
      SetSelectedAll(false)
    }  }

  // Update the state
  SetArrayCheck(ChArr);
  SetArrayUnCheck(UnChArr);
};


  // get sort field data
  const SortData = async (Field) => {
    SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetCampaignINSflag(false);
      var SortField = Field;
      var SortBy;
      if (CampaignINSortedBy == 1) {
        SortBy = -1;
        SetCampaignINSortedBy(-1);
      } else {
        SortBy = 1;
        SetCampaignINSortedBy(1);
      }
      SetCampaignINSortField(Field);
      var str_in = {
        Page: CampaignINPage,
        RowsPerPage: CampaignINRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: CampaignINSortedBy,
        Search: CampaignINSearch,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        CategoryID: CategoryValueOne,
        Role: Role,
      };

      const Rows1 = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/Campaigncategory/CampaignInCategoryList",
        method: "POST",
        data: str_in,
      });
      ;
      SetCampaignINData(Rows1.data.PageData);
      SetCampaignINRows(Rows1.data.PageData);
      SetCampaignINRlen(Rows1.data.TotalCount);
      SetCampaignINFlen(Rows1.data.TotalCount);
      SetCampaignINCountPage(Rows1.data.PageCount);
      if (Rows1.data.PageData.length !== 0) {
        var tempresult = Rows1.data.PageData.map((item) => {
          if (item.CampaignInCategpryList.length !== 0) {
            document.getElementById(item._id).checked = true;
          } else {
           document.getElementById(item._id).checked = false;
          }
        });
        const results = await Promise.all(tempresult);
      }
    SetIsLoading(false)

    } else {
      SerchBox = true;
      SetCampaignINSflag(true);
      var SortField = Field;
      var SortBy;
      if (CampaignINSortedBy == 1) {
        SortBy = -1;
        SetCampaignINSortedBy(-1);
      } else {
        SortBy = 1;
        SetCampaignINSortedBy(1);
      }
      SetCampaignINSortField(Field);
      var str_in = {
        Page: CampaignINPage,
        RowsPerPage: CampaignINRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: CampaignINSortedBy,
        Search: CampaignINSearch,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        CategoryID: CategoryValueOne,
        Role: Role,
      };

      const Rows1 = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/Campaigncategory/CampaignInCategoryList",
        method: "POST",
        data: str_in,
      });
      ;
      SetCampaignINData(Rows1.data.PageData);
      SetCampaignINRows(Rows1.data.PageData);
      SetCampaignINRlen(Rows1.data.TotalCount);
      SetCampaignINFlen(Rows1.data.TotalCount);
      SetCampaignINCountPage(Rows1.data.PageCount);
      if (Rows1.data.PageData.length !== 0) {
        var tempresult = Rows1.data.PageData.map((item) => {
          if (item.CampaignInCategpryList.length !== 0) {
            document.getElementById(item._id).checked = true;
          } else {
           document.getElementById(item._id).checked = false;
          }
        });
        const results = await Promise.all(tempresult);
      }
    SetIsLoading(false)

    }
  };

  // back btn
  const BackBtn = () => {
    history.push("/CampaignCategories");
  };

  // update total campaigns save btn
  const SaveBtn = () => {
    SetIsLoading(true)
    SetIsSaveBtnDisabled(true)
    let InputParameter = {
      ClientID: ClientID,
      UserID: UserID,
      CreatedBy: CUserID,
      CategoryID: CategoryValueOne,
      CreatedDt: new Date(),
      ArrayCheck: ArrayCheck,
      ArrayUnCheck: ArrayUnCheck,
      Role: Role,
    };

    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/Campaigncategory/CampaignInCategoryUpdate",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.statusText == "OK") {
        Swal.fire({
          title: "Campaign Category",
          text: "Selected Campaign added successfully.",
          icon: "success",
        });
        SetIsSaveBtnDisabled(false)
        SetIsLoading(false)
      } else {
        SetIsSaveBtnDisabled(false)
        SetIsLoading(false)
      }
    });
  };

  //Checked all
  const CheckHandler = (e) => {
    var ChArr = ArrayCheck;
    var UnChArr = ArrayUnCheck;
    CampaignINRows?.map((val) => {
      if (e.target.checked) {
        const exists = ChArr.some(item => item.CampaignID === val?._id);
        if (!exists) {
        ChArr.push({ CampaignID: val?._id });
        }
        // Remove from UnChArr if present
        const indexInUnChArr = UnChArr.findIndex(item => item.CampaignID === val?._id);
        if (indexInUnChArr !== -1) {
          UnChArr.splice(indexInUnChArr, 1);
        }

        document.getElementById(val?._id).checked = true;
      } else if (!e.target.checked) {
        const exists = UnChArr.some(item => item.CampaignID === val?._id);
        if (!exists) {
        UnChArr.push({ CampaignID: val?._id });
        }
         // Remove from ChArr if present
         const indexInUnChArr = ChArr.findIndex(item => item.CampaignID === val?._id);
         if (indexInUnChArr !== -1) {
           ChArr.splice(indexInUnChArr, 1);
         }
        document.getElementById(val?._id).checked = false;
      }
    });
    if(e.target.checked) {
      SetSelectedAll(true)
    }else{
      SetSelectedAll(false)

    }
    SetArrayCheck(ChArr);
    SetArrayUnCheck(UnChArr);

  };
  return (
    <>
    {
      IsLoading ? <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> : <></>
    }
      
      <div className="row align-items-center ">
        <div className="col">
          <h4 className="headertitlepad xs-headertitle float-left pl-0">
            Add Campaign Category
          </h4>
        </div>
      </div>

      <div className="row pt-4 borderbtnm border-bottom border-top pb-3">
        <div class="col-lg-6 boxsinput_group">
          <div class="row max-cols">
            <label class="col-lg-4 view-lable-meet">Category Name</label>
            <div className="col-lg-8">
              <select
                className="form-control  m-input"
                name="CategoryID"
                id="CategoryID"
                defaultValue={CategoryValueOne}
                onChange={(e) => HandleDropdownChangeCampaignCategory(e)}
              >
                {Data.map((value) => (
                  <option value={value._id}>{value.Name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div class="col-lg-6 boxsinput_group">
          <div class="col-lg-12 pull-left">
            <button
              id="submit"
              onClick={SaveBtn}
              class="btn btn-primary btn-lightgreen mr-1"
              disabled={IsSaveBtnDisabled}
            >
              <i class="la la-save"></i> Save
            </button>
            <a id="backtolist" onClick={BackBtn} class="btn btn-secondary">
              <i class="la la-arrow-circle-left"></i> Back
            </a>
          </div>
        </div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={CampaignINRowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
      <Table className="table-ref" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  className="pr-0"
                  style={{ width: 50, textAlign: "center" }}
                >
                  <input
                    name="select_all"
                    value="1"
                    type="checkbox"
                    // autocomplete="off"
                    id="SelecteAll"
                    onChange={(e) => CheckHandler(e)}
                    checked={SelectedAll}
                  />
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Name");
                    }}
                  >
                    Name
                  </a>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CampaignINRows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                CampaignINRows.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell
                      className="pr-0"
                      style={{ width: 50, textAlign: "center" }}
                    >
                      <input
                        type="checkbox"
                        id={row._id}
                        onChange={(e) => CheckBoxAdd(e, row._id)}
                      />
                    </TableCell>
                    <TableCell>{row.Name}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {CampaignINSflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CampaignINCountPage}
            page={CampaignINPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing{" "}
              {CampaignINRlen == 0
                ? 0
                : (CampaignINPage - 1) * CampaignINRowsPerPage + 1}{" "}
              to{" "}
              {CampaignINPage * CampaignINRowsPerPage > CampaignINRlen
                ? CampaignINRlen
                : CampaignINPage * CampaignINRowsPerPage}{" "}
              of {CampaignINRlen} entries (filtered from {CampaignINFlen} total
              entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row pb-3">
          <div class="col dataTables_info">
            <p>
              Showing{" "}
              {CampaignINRlen == 0
                ? 0
                : (CampaignINPage - 1) * CampaignINRowsPerPage + 1}{" "}
              to{" "}
              {CampaignINPage * CampaignINRowsPerPage > CampaignINRlen
                ? CampaignINRlen
                : CampaignINPage * CampaignINRowsPerPage}{" "}
              of {CampaignINRlen} entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CampaignINCountPage}
              page={CampaignINPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
