import React from 'react'
import { connect } from 'react-redux'
import { userActions } from '../../_actions'
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar'
import ClientsLists from '../../_components/clients/lists/lists'
//import CollapsibleTable from '../_components/usertable';

class CEditClientPage extends React.Component {
  render () {
    return (
      <>
        <div className='adminmain'>
          <Sidebar className='' />
          <div className='bodyhome'>
              <div className='row'>
                <div className='col'>
                  <h4 className='headertitlepad lg-headertitle pl-0 mb-0'>Edit Client</h4>
                </div>
                <div className='col padright'></div>
              </div>

                <div class='paddcols'>
                    <div className='row'>
                        <div className='col formcontral'>
                        <div class='col-lg-6 boxsinput_group'>
                            <div class='row'>
                            <label class='col-lg-3 view-lable-meet'>Email</label>
                            <div class='col-lg-8'>
                                <input
                                type='Email'
                                class='form-control m-input'
                                id='unsubscribe'
                                name='unsubscribe'
                                placeholder=''
                                />
                            </div>
                            </div>
                        </div>

                        <div class='col-lg-6 boxsinput_group'>
                            <div class='row'>
                            <label class='col-lg-3 view-lable-meet'>Password</label>
                            <div class='col-lg-8'>
                                <input
                                type='Password'
                                class='form-control m-input'
                                id='Password'
                                name='unsubscribe'
                                placeholder=''
                                />
                            </div>
                            </div>
                        </div>

                        <div class='col-lg-6 boxsinput_group'>
                            <div class='row'>
                            <label class='col-lg-3 view-lable-meet'>First Name</label>
                            <div class='col-lg-8'>
                                <input
                                type='text'
                                class='form-control m-input'
                                id='firstname'
                                name='unsubscribe'
                                placeholder='Confirming (OWNER.COMPANY) meeting tomorrow'
                                />
                            </div>
                            </div>
                        </div>

                        <div class='col-lg-6 boxsinput_group'>
                            <div class='row'>
                            <label class='col-lg-3 view-lable-meet'>Last Name</label>
                            <div class='col-lg-8'>
                                <input
                                type='text'
                                class='form-control m-input'
                                id='LastName'
                                name='unsubscribe'
                                placeholder=' '
                                />
                            </div>
                            </div>
                        </div>

                        <div className='px-2'>
                            <div class='col-lg-12 row'>
                            <label class='check_bx'>
                                <input type='checkbox' onChange={this.rememberme} />
                                <span className='checkmark'></span>
                                Is Sent Meeting Notification
                            </label>
                            </div>
                            <div class='col-lg-12 row'>
                            <label class='check_bx'>
                                <input type='checkbox' onChange={this.rememberme} />
                                <span className='checkmark'></span>
                                Is Sent Potential Meeting Notification
                            </label>
                            </div>
                            <div class='col-lg-12 row'>
                            <label class='check_bx'>
                                <input type='checkbox' onChange={this.rememberme} />
                                <span className='checkmark'></span>
                                Is Sent Weekly Email Notification
                            </label>
                            </div>
                        </div>

                        <div class='col-lg-6 boxsinput_group'>
                            <div class='row'>
                            <label class='col-lg-3 view-lable-meet'>
                                Upload Profile Image
                            </label>
                            <div class='col-lg-8'>
                                <input type='file' />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div class='row py-4'>
                <div class='col-lg-12 pull-left'>
                    <a
                    id='submit'
                    onClick={this.savebtn}
                    class='btn btn-primary btn-lightgreen mr-1'
                    >
                    <i class='la la-save'></i> Save
                    </a>
                    <a onClick={this.canclecsv} class='btn btn-secondary'>
                    Cancel
                    </a>
                </div>
                </div>
                    
          </div>
        </div>
      </>
    )
  }
}

function mapState (state) {
  const { loggingIn } = state.authentication
  return { loggingIn }
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
}

const connectedCEditClientPage = connect(
  mapState,
  actionCreators
)(CEditClientPage)
export { connectedCEditClientPage as CEditClientPage }
