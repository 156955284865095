import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import TriggerPhrasesTabing from "../../_components/user/TriggerPhrases/TriggerPhrasesTabing";
import Popupone from "reactjs-popup";

class TriggerPhrasesPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    //    this.props.logout();

    this.state = {
      popupval: true,
      InvitePopup: false,
      //PreviousLang: [],
      //_Lang: 'en',
    };
    this.openInvitePopup = this.openInvitePopup.bind(this);
    this.closeInvitePopup = this.closeInvitePopup.bind(this);
    this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this);
  }
  componentDidMount() {
    window.addEventListener('storage', (event) => {
            
      if (event.key === 'clientChanged') {
        window.location.reload();
      }
    });
}
  // componentDidMount() {
  //     document.addEventListener('mousedown', this.closeInvitePopup);
  // }
  // componentWillUnmount() {
  //     document.removeEventListener('mousedown', this.closeInvitePopup);
  // }

  openInvitePopup() {
    this.setState({ InvitePopup: true });
  }
  closeInvitePopup() {
    this.setState({ InvitePopup: false });
  }

  SubmitInvitePopup() {
    this.setState({ InvitePopup: false });
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col padd-0">
                <h4 className="headertitlebd lg-headertitle">Trigger Phrases</h4>
              </div>
              <div className="col padright"></div>
            </div>
            <div className="col padd-0"></div>

            <div className="row row align-items-center">
              <div className="col">
                <TriggerPhrasesTabing />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedTriggerPhrasesPage = connect(
  mapState,
  actionCreators
)(TriggerPhrasesPage);
export { connectedTriggerPhrasesPage as TriggerPhrasesPage };
