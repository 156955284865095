import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { toast } from "react-toastify";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import PhoneBurnerTable from "./PhoneBurnerTable";
const Fileupload = require("../../_helpers/fileupload");
import { history } from "../../_helpers";
import loadingicon from "../../images/loading.gif";

import ProfileImageUpload from "../../_components/ProfileUpload";
const REACT_APP_ZIPCODE_BASE_URL = process.env.REACT_APP_ZIPCODE_BASE_URL;
const REACT_APP_ZIPCODE_KEY = process.env.REACT_APP_ZIPCODE_KEY;

const imgstyle = { width: "100px", height: "100px" };
class EditMyProfilePage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.childRef = React.createRef();
    this.state = {
      UserData: [],
      UserID: null,
      CUserID: null,
      Role: null,
      ClientID: null,
      Email: null,
      ShowExtraFields: true,
      CountryList: [],
      ImgFlag: false,
      FinalImg: "",
      FileImg: "",
      ImgFlag: false,
      FileImg: [],
      CountryID: null,
      Errors: {},
      ChildKey: 0,
      BtnDisabled: false,
      IsSubmit: false,
      isLoading: false,
      successMessage:null
    };
    this.ClientDropDown(
      Details.ChildUserID,
      Details.ParentUserID,
      Details.Role
    );
    this.SaveProfile = this.SaveProfile.bind(this);
  }

  //Get value of params from url
  GetValueOfParamsFromURL = () => {
    const url = new URL(window.location.href); // Get the current URL
    const params = new URLSearchParams(url.search); // Get the URL parameters
    const message = params.get('message'); // Get the value of the 'message' parameter
    this.setState({successMessage:message})
  }

  componentDidMount() {
    document.title = "My Profile | SalesHive";
    var Details = GetUserDetails();
    this.GetValueOfParamsFromURL()

    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
      this.setState({ Role: Details.Role });
    }
    this.GetUserData(Details.ChildUserID, Details.Role);
    this.GetCountryList();
    if (
      Details.Role == "Client" ||
      Details.Role == "Responder" ||
      Details.Role == "ColdCallers" ||
      Details.Role == "SalesHacker" ||
      Details.Role == "SEO"
    ) {
      this.setState({ ShowExtraFields: false });
    } else {
      this.setState({ ShowExtraFields: true });
    }
  }
  decryptPassword(encryptedPassword) {
    try {
      const decryptedPasswordBytes = CryptoJS.AES.decrypt(
        encryptedPassword.replace(/ /g, "+"),
        "my-secret-key@123"
      );
      return decryptedPasswordBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Error during password decryption:", error);
      return ""; // Return an empty string as a default value
    }
  }

    //Remove Quotes from password
    RemoveQuotes = (str) => {
      // Check if the string starts and ends with double quotes
      if (str.startsWith('"') && str.endsWith('"')) {
          // If yes, remove the first and last character
          return str.slice(1, -1);
      } else {
          // If not, return the string as it is
          return str;
      }
    }

  //Getting user data
  GetUserData(CUID, Role) {
    // document.getElementById("hideloding").style.display = "block";
    this.setState({ isLoading: true });

    const InputParameters = {
      ChildUserID: CUID,
      AccountType: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/login/GetLoginUserDetails",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ UserData: res.data.Data });
      this.setState({ FinalImg: res.data.Data.ProfileImage });
      // const encryptedPassword = userData.Password;
      const Pass = this.decryptPassword(res.data.Data.Password);

      // let Pass = JSON.parse((CryptoJS.AES.decrypt(((res.data.Data.Password).split(" ").join("+")), "my-secret-key@123")).toString(CryptoJS.enc.Utf8))
      this.setState({ Email: res.data.Data.Username });

      if (
        Role == "Client" ||
        Role == "Responder" ||
        Role == "ColdCallers" ||
        Role == "SalesHacker"
      ) {
        document.getElementById("Password").value = this.RemoveQuotes(Pass);
        document.getElementById("FirstName").value = res.data.Data.FirstName;
        document.getElementById("LastName").value = res.data.Data?.LastName;
        document.getElementById("Is2FA").checked =
          res.data.Data?.IsTwoStepVerification;
        // document.getElementById("hideloding").style.display = "none";
      } else {
        document.getElementById("Password").value = this.RemoveQuotes(Pass);
        document.getElementById("FirstName").value = res.data.Data.FirstName;
        document.getElementById("LastName").value = res.data.Data?.LastName;
        if(this.state.Role !== "SEO"){
          document.getElementById("PhoneNo").value = res.data.Data?.PhoneNo;
          document.getElementById("ZipCode").value = res.data.Data?.ZipCode;
          document.getElementById("Country").value = res.data.Data?.CountryID;
        }
        document.getElementById("Is2FA").checked =
          res.data.Data?.IsTwoStepVerification;
        this.setState({ CountryID: res.data.Data?.CountryID });
        // document.getElementById("hideloding").style.display = "none";
      }
      // document.getElementById("hideloding").style.display = "none";
      this.setState({ isLoading: false });
    });
  }

  // Get Client List
  ClientDropDown(CUID, PUID, Role) {
    var ObjData = {
      ChildUserID: CUID,
      ParentUserID: PUID,
      Role: Role,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
      method: "POST",
      data: ObjData,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        const ClientData = result.data.Data;
        const option = ClientData.map((d) => ({
          _id: d.ClientID,
          Name: d.Name,
        }));
        this.setState({ ClientList: option });
      }
    });
  }
  //Get Country list
  GetCountryList() {
    const InputParameters = {};
    Axios({
      url: CommonConstants.MOL_APIURL + "/login/GetCountryList",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ CountryList: res.data.Data });
    });
  }

  //upload
  HandleUpload(field, e) {
    e.preventDefault();
    if (
      Fileupload.checkMimeType(e.target.files[0]) &&
      Fileupload.checkFileSize(e.target.files[0])
    ) {
      var temp = URL.createObjectURL(e.target.files[0]);

      this.setState({
        FinalImg: e.target.files[0],
      });
      this.setState({ ImgFlag: true });
      this.setState({ FileImg: temp });
    } else {
    }
  }

  //First name validation
  CheckFirstName() {
    let Errors = {};
    let FirstName = document.getElementById("FirstName").value.trim();
    if (FirstName == "") {
      Errors["FirstName"] = "Please enter First Name";
      this.setState({ Errors: Errors });
      return false;
    } else {
      Errors["FirstName"] = "";
      this.setState({ Errors: Errors });
      return true;
    }
  }

  //ZipCode Validation
  async CheckZipCode() {
    let Errors = {};
    let ZipCode = document.getElementById("ZipCode").value.trim();
    console.log(REACT_APP_ZIPCODE_KEY);
    console.log("AIzaSyCT_UzNVrSHbOKUb6uu7Ct3Gc3h1ugq-Xc");
    const API_KEY = "AIzaSyCT_UzNVrSHbOKUb6uu7Ct3Gc3h1ugq-Xc"; // replace with your actual API key
    const BASE_URL = REACT_APP_ZIPCODE_BASE_URL;
    const url = `${BASE_URL}?components=postal_code:${ZipCode}&key=${API_KEY}`;

    let res = await Axios({
      url: url,
      method: "GET",
    });
    if (res.data.status == "ZERO_RESULTS") {
      Errors["ZipCode"] = "Invalid Zipcode";
      this.setState({ Errors: Errors });
      return false;
    } else {
      Errors["ZipCode"] = "";
      this.setState({ Errors: Errors });
      return true;
    }
  }

  async SaveProfile(e) {
    this.setState({ isLoading: true });

    let Image = await this.childRef.current.SubmitData();
    let ImageName = Image.FileName;
    this.setState({ FinalImg: ImageName });

    this.setState({ BtnDisabled: true });
    let Errors = {};
    document.getElementById("submit").disable = true;
    let FirstName = document.getElementById("FirstName").value.trim();
    if (FirstName != "") {
      let PhoneNo = "",
        ZipCode = "",
        Country = "",
        Password = "",
        FirstName = "",
        LastName = "",
        Is2FA = "";
      let Role = this.state.Role;
      if (
        Role == "Client" ||
        Role == "Responder" ||
        Role == "ColdCallers" ||
        Role == "SalesHacker"
      ) {
        Password = document.getElementById("Password").value;
        FirstName = document.getElementById("FirstName").value;
        LastName = document.getElementById("LastName").value;
        Is2FA = document.getElementById("Is2FA").checked;
      } else {
        Password = document.getElementById("Password").value;
        FirstName = document.getElementById("FirstName").value;
        LastName = document.getElementById("LastName").value;
        Is2FA = document.getElementById("Is2FA").checked;
        PhoneNo = document.getElementById("PhoneNo").value;
        ZipCode = document.getElementById("ZipCode").value;
        Country = document.getElementById("Country").value;
      }
      if (Role == "Admin") {
        if ((await this.CheckZipCode()) == true) {
          this.setState({ IsSubmit: true });
          if (this.state.ImgFlag == false) {
            const InputParameters = {
              FirstName: FirstName,
              LastName: LastName,
              Password: Password,
              PhoneNo: PhoneNo,
              ZipCode: ZipCode,
              Country: Country,
              TwoFA: Is2FA,
              Role: this.state.Role,
              UserID: this.state.CUserID,
              ProfileImage: this.state.FinalImg,
            };
            // User profile updated successfully
            let res = await Axios({
              url: CommonConstants.MOL_APIURL + "/login/UpdateProfile",
              method: "POST",
              data: InputParameters,
            });

            if (res.data.StatusMessage == "SUCCESS") {
              this.setState({ ChildKey: this.state.ChildKey + 1 });
              this.setState({ isLoading: false });

              toast.success("User profile updated successfully.");
            } else {
              toast.error(res.data.Message);
              this.setState({ BtnDisabled: false });
              this.setState({ isLoading: false });
            }
          }
        } else {
          Errors["ZipCode"] = "Invalid Zipcode";
          // document.getElementById("hideloding").style.display = "none";
          this.setState({ isLoading: false });

          document.getElementById("submit").disable = false;
          this.setState({ BtnDisabled: false });
        }
      } else {
        this.setState({ isLoading: true });

        this.setState({ IsSubmit: true });
        if (this.state.ImgFlag == false) {
          const InputParameters = {
            FirstName: FirstName,
            LastName: LastName,
            Password: Password,
            PhoneNo: PhoneNo,
            ZipCode: ZipCode,
            Country: Country,
            TwoFA: Is2FA,
            Role: this.state.Role,
            UserID: this.state.CUserID,
            ProfileImage: this.state.FinalImg,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: this.state.CUserID,
          };

          // User profile updated successfully
          let res = await Axios({
            url: CommonConstants.MOL_APIURL + "/login/UpdateProfile",
            method: "POST",
            data: InputParameters,
          });

          if (res.data.StatusMessage == "SUCCESS") {
            this.setState({ ChildKey: this.state.ChildKey + 1 });
            this.setState({ isLoading: false });

            toast.success("User profile updated successfully.");

          } else {
            toast.error(res.data.Message);
            this.setState({ BtnDisabled: false });
            this.setState({ isLoading: false });
          }
        }
      }

      // document.getElementById("hideloding").style.display = "none";
      document.getElementById("submit").disable = false;
      this.setState({ isLoading: false });

      this.setState({ BtnDisabled: false });
    } else {
      Errors["FirstName"] = "Please enter First Name";
      // document.getElementById("hideloding").style.display = "none";
      this.setState({ isLoading: false });
      document.getElementById("submit").disable = false;
      this.setState({ BtnDisabled: false });
      this.setState({ isLoading: false });
    }
    this.setState({ Errors: Errors });
    this.setState({ isLoading: false });
  }

  handleclearmessages = () => {
    this.setState({successMessage:null});
    history.push("/EditMyProfile");
  }

  //backBtn
  BackBtn() {
    var Details = GetUserDetails();
    let Role = Details.Role;
    if(Role === "SEO") {
      history.push("/seooverview");
    }else{
      history.push("/kpis");
    }
  }
  // savebutton
  savebtn() {
    // this.setState({ DisableBtn: true });
    // if (this.FormValidation()) {
    let Clientid = document.getElementById("clientIddropdown").value;

    if (Clientid != "") {
      var Details = GetUserDetails();
      var InputParameter = {
        UserID: Details.ParentUserID,
        ClientID: Clientid,
        ColdCallerID: Details.ChildUserID,
        redirectPage: "Account",
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerAuthCode",
        method: "POST",
        data: InputParameter,
        withCredentials: true,
      }).then((res) => {
        //
        if (res.data.StatusCode == "Valid") {
          window.location.href = res.data.Url;
        }
      });
    } else {
      alert("First select the client");
    }
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}

        <div className="adminmain">
          <Sidebar key={this.state.ChildKey} className="" />
        
          <div className="bodyhome">
            <div className="row">
              <div className="col ">
                <h4 className="headertitlepad lg-headertitle float-left py-4 ">My Profile</h4>
              </div>
              <div className="col padright"></div>
            </div>
            {this.state.successMessage === "successfully" ? (
              <div
                style={{
                  backgroundColor: "#fff",

                  color: "#34bfa3",
                  fontWeight: 400,
                  padding: "0.85rem 1.5rem",
                  borderRadius: "4px",
                  position: "relative",
                  marginBottom: "1rem",
                  border: "1px solid transparent",
                  borderColor: "#34bfa3",
                }}
              >
                {" "}
                <strong>Success!</strong> Phone Burner Authentication is done.{" "}
                <button
                  type="button"
                  style={{
                    float: "right",
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    lineHeight: 1,
                    color: "#34bfa3",
                    textShadow: "0 1px 0 #fff",
                    opacity: 0.5,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={this.handleclearmessages}
                >
                  x
                </button>
              </div>
            ) : this.state.successMessage === "deny" ? (
              <div
                style={{
                  backgroundColor: "#fff",

                  color: "#f4516c",
                  fontWeight: 400,
                  padding: "0.85rem 1.5rem",
                  borderRadius: "4px",
                  position: "relative",
                  marginBottom: "1rem",
                  border: "1px solid transparent",
                  borderColor: "#f4516c",
                }}
              >
                {" "}
                <strong>Oops!</strong> You have denied authentication!{" "}
                <button
                  type="button"
                  style={{
                    float: "right",
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    lineHeight: 1,
                    color: "#f4516c",
                    textShadow: "0 1px 0 #fff",
                    opacity: 0.5,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={this.handleclearmessages}
                >
                  x
                </button>
              </div>
            
            ) : null}
            <div class="bg-white px-0">
              <div className="row py-3 mx-0">
                <div className="col formcontral">
                  <div class="col-lg-6 boxsinput_group py-2">
                    <div class="row">
                      <label class="col-lg-4 view-lable-meet">Email</label>
                      <div class="col-lg-8">{this.state.Email}</div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group py-2">
                    <div class="row">
                      <label class="col-lg-4 view-lable-meet">Password</label>
                      <div class="col-lg-8">
                        <input type="Password" class="form-control m-input" id="Password" name="Password"readOnly={true} placeholder="" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group py-2">
                    <div class="row">
                      <label class="col-lg-4 view-lable-meet">First Name</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control m-input" id="FirstName" name="unsubscribe" placeholder="First name"onBlur={() => {this.CheckFirstName(); }}/>
                        <span style={{ color: "red" }}>
                          {this.state.Errors["FirstName"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group py-2">
                    <div class="row">
                      <label class="col-lg-4 view-lable-meet">Last Name</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control m-input" id="LastName" name="unsubscribe" placeholder="Last name" />
                      </div>
                    </div>
                  </div>
                  {this.state.ShowExtraFields == true ? (
                    <>
                      <div class="col-lg-6 boxsinput_group py-2">
                        <div class="row">
                          <label class="col-lg-4 view-lable-meet">Phone No</label>
                          <div class="col-lg-8">
                            <input type="text" class="form-control m-input" id="PhoneNo" name="PhoneNo" placeholder="Phone No."/>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group py-2">
                        <div class="row">
                          <label class="col-lg-4 view-lable-meet"> ZipCode </label>
                          <div class="col-lg-8">
                            <input type="text" class="form-control m-input" id="ZipCode" name="ZipCode" onBlur={() => { this.CheckZipCode();}}placeholder="ZipCode"/>
                            <span style={{ color: "red" }}> {this.state.Errors["ZipCode"]} </span>
                          </div>
                        </div>
                      </div>

                       <div class="col-lg-6 boxsinput_group py-2">
                        <div class="row">
                          <label class="col-lg-4 view-lable-meet">Country</label>
                          <div class="col-lg-8">
                            <select class="form-control m-input" id="Country" onChange={() => { let CountryID = document.getElementById("Country").value; this.setState({ CountryID: CountryID });}}
                              value={this.state.CountryID}
                            >
                              {this.state.CountryList.map((elem) => ( <option value={elem.CountryID}> {elem.CountryName} </option>))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <ProfileImageUpload
                    profileImage={this.state.FinalImg}
                    ref={this.childRef}
                  />
                  <div class="col-lg-6 boxsinput_group mb-2 px-4">
                    <div class="row">
                      <div class="toggleswich mt-2">
                        <input type="checkbox" class="checktogle" id="Is2FA" />
                        <b class="switch">
                          <span class="checkion"></span>
                          <span class="uncheckion"></span>
                        </b>
                        <b class="track"></b>
                      </div>

                      <label class="col-lg-8 view-lable-meet"> Is Two Step Verification </label>
                    </div>
                  </div>

                  <div class="row border-top pt-3 pb-0">
                    <div class="col-lg-12 pull-left">
                      <button
                        id="submit"
                        disabled={this.state.BtnDisabled}
                        onClick={(e) => this.SaveProfile(e)}
                        class="btn btn-primary btn-lightgreen mr-1"
                      >
                        <i class="la la-save"></i> Save
                      </button>
                      <a onClick={this.BackBtn} class="btn btn-secondary">
                        Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />
            {this.state.Role == "ColdCallers" ? <PhoneBurnerTable /> : null}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditMyProfilePage = connect(
  mapState,
  actionCreators
)(EditMyProfilePage);
export { connectedEditMyProfilePage as EditMyProfilePage };
