import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import ClientContactTabing from '../../_components/user/ClientContacts/ClientContactsTabing';
import CollapsibleTable from '../../_components/usertable';



class ClientContactPage extends React.Component {
    componentDidMount() {
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    }
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col padd-0">
                            <h4 className="headertitlebd lg-headertitle ">All Client Contact</h4>
                        </div>
                        <div className="col padright">
                             
                        </div>
                    </div> 
                    <div className="col padd-0">
                        <ClientContactTabing />
                    </div>
                </div>

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedClientContactPage = connect(mapState, actionCreators)(ClientContactPage);
export { connectedClientContactPage as ClientContactPage };