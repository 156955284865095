import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");
import parse from "html-react-parser";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Popupone from "reactjs-popup";

import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { GetClientDetails } from "../../../_helpers/Utility";
import StyleHeader from "../StickyHeader/StickyHeader";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexchart from "react-apexcharts";
import $ from "jquery";
import {
  getFirstDayOfQuarter,
  getCurrentDayOfQuarter,
} from "../../../_helpers/Utility";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const ResponceAllTable = () => {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("MsgDateTimeByTimeZone");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Open, SetOpen] = React.useState(false);
  const [ResponderCategoryList, SetResponderCategoryList] = React.useState([]);
  const [Cname, SetCname] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [OpenNotes, SetOpenNotes] = React.useState(false);
  const [NoteDetail, SetNoteDetail] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [PieChartData, SetPieChartData] = React.useState([]);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [ResponseCoutData, SetResponseCoutData] = React.useState([]);
  const [ResponseDateData, SetResponseDateData] = React.useState([]);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const daterefect = React.useRef(null);
  const daterefect2 = React.useRef(null);
  const [IncludeAllResponse, setIncludeAllResponse] = React.useState(false);

  useEffect(() => {
    document.title = `Email Responses | SalesHive`;
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetClientDetails(GetUserData.ClientID).then((result) => {
      SetCname(result[0]?.Name);
    });

    const fromdate = getFirstDayOfQuarter();
    // const previousMonthDate = new Date(fromdate);
    const previousMonthDate = new Date();

    const utcYear = previousMonthDate.getUTCFullYear();
    const utcMonth = String(previousMonthDate.getUTCMonth() + 1).padStart(
      2,
      "0"
    );
    const utcDay = String(previousMonthDate.getUTCDate()).padStart(2, "0");

    // const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;
    const formattedDate = `${utcMonth}/01/${utcYear}`;

    document.getElementById("from-date").value = formattedDate;
    const $datepickerElement = $(daterefect.current);
    $datepickerElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    document.getElementById("to-date").value = new Date().toLocaleDateString(
      "en-US",
      { month: "2-digit", day: "2-digit", year: "numeric" }
    );
    const $datepickerElement2 = $(daterefect2.current);
    $datepickerElement2.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });
    let FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    let ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");

    if (FromDate != "" && ToDate != "") {
      setFromDate(FromDate);
      setToDate(ToDate);
      SalesReplyMessageListGet(
        GetUserData.ClientID,
        GetUserData.ParentUserID,
        GetUserData.Role,
        FromDate,
        ToDate,
        Page
      );
      GetEmailResponsesGraph(
        GetUserData.ClientID,
        GetUserData.ParentUserID,
        GetUserData.Role,
        FromDate,
        ToDate
      );
      GetResponsesGraphLast90Days(
        GetUserData.ClientID,
        GetUserData.ParentUserID,
        FromDate,
        ToDate,
        GetUserData.Role
      );
      SalesResponderCategoryListGet(
        GetUserData.ClientID,
        GetUserData.ParentUserID,
        GetUserData.Role
      );
    }
  }, []);

  // for on change include box

  useEffect(() => {
    document.title = `Email Responses | SalesHive`;
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetClientDetails(GetUserData.ClientID).then((result) => {
      SetCname(result[0]?.Name);
    });

    let FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    let ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");

    if (FromDate != "" && ToDate != "") {
      if (
        moment(FromDate, "YYYY-MM-DD").isAfter(moment(ToDate, "YYYY-MM-DD"))
      ) {
        toast.error("please select appropriate date range");
      } else {
        SalesResponderCategoryListGet(
          GetUserData.ClientID,
          GetUserData.ParentUserID,
          GetUserData.Role,
          FromDate,
          ToDate
        );
        GetEmailResponsesGraph(
          GetUserData.ClientID,
          GetUserData.ParentUserID,
          GetUserData.Role,
          FromDate,
          ToDate
        );
        SalesReplyMessageListGet(
          GetUserData.ClientID,
          GetUserData.ParentUserID,
          GetUserData.Role,
          FromDate,
          ToDate,
          Page
        );
        GetResponsesGraphLast90Days(
          GetUserData.ClientID,
          GetUserData.ParentUserID,
          FromDate,
          ToDate,
          GetUserData.Role
        );
      }
    }
  }, [IncludeAllResponse, Search, Page, RowsPerPage]);

  //get SalesReply Message list
  const SalesReplyMessageListGet = async (
    CID,
    UID,
    URole,
    FromDate,
    ToDate,
    Page
  ) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IncludeAllResponse: IncludeAllResponse,
      FromDate: FromDate,
      ToDate: ToDate,
    };
    const Result = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/salesreply_message/GetSalesReplyMessagelist",
      method: "POST",
      data: InputParameter,
    });

    SetIsLoading(false);
    SetRows(Result.data.PageData);
    SetRlen(Result.data.TotalCount);
    SetFlen(Result.data.TotalCount);
    SetCountPage(Result.data.PageCount);
    document.getElementById("hideloding").style.display = "none";
    setIsDataLoaded(true);
  };

  //get SalesReply Message list
  const SalesResponderCategoryListGet = (CID, UID, URole, FromDate, ToDate) => {
    var InputParameter = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const SalesReplyMessageList = Axios({
      url:
        CommonConstants.MOL_APIURL + "/salesreply_message/GetSalesMessagelist",
      method: "POST",
      data: InputParameter,
    });
    SalesReplyMessageList.then((Result) => {
      SetResponderCategoryList(Result.data?.ResponderCategoryData);
    });
  };

  // Export details
  const Exportdetails = async (CID, UID, URole, FromDate, ToDate) => {
    let InputParameter = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IncludeAllResponse: IncludeAllResponse,
      FromDate: FromDate,
      ToDate: ToDate,
    };
    const rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/salesreply_message/SalesReplyMessageExport",
      method: "POST",
      data: InputParameter,
    });
    if (rows1.data.StatusMessage === "SUCCESS") {
      return rows1?.data;
    } else {
      return rows1?.data;
    }
  };

  const SaveBtn = () => {
    SetPage(1);
    const FromDate = moment(
      document.getElementById("from-date").value,
      "MM/DD/YYYY"
    ).format("YYYY-MM-DD");
    const ToDate = moment(
      document.getElementById("to-date").value,
      "MM/DD/YYYY"
    ).format("YYYY-MM-DD");
    if (moment(FromDate, "YYYY-MM-DD").isAfter(moment(ToDate, "YYYY-MM-DD"))) {
      toast.error("please select appropriate date range");
    } else {
      setFromDate(FromDate);
      setToDate(ToDate);
      GetResponsesGraphLast90Days(ClientID, UserID, FromDate, ToDate, Role);
      SalesReplyMessageListGet(ClientID, UserID, Role, FromDate, ToDate, 1);
      GetEmailResponsesGraph(ClientID, UserID, Role, FromDate, ToDate);
    }
  };

  const exportcsv = async () => {
    try {
      SetIsLoading(true);
      const result = await Exportdetails(
        ClientID,
        UserID,
        Role,
        fromDate,
        toDate
      );
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          // debugger
          // SetExport(data);
          const downloader = document.createElement("a");
          const csvContent =
            "data:text/csv;charset=utf-8," +
            encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute(
            "download",
            Cname + "-CampaignResponsesDetails.csv"
          );
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
          SetIsLoading(false);
        } else {
          toast.error("No data available for export.");
          SetIsLoading(false);
        }
      } else {
        toast.error("Failed to export data. Please try again.");
        SetIsLoading(false);
      }
    } catch (error) {
      SetIsLoading(false);
      toast.error("An error occurred while exporting data.");
    }
  };

  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(","));

    data.forEach((item) => {
      const values = headers.map((header) => {
        // Check if the value is a string before attempting to replace characters
        const value =
          typeof item[header] === "string"
            ? item[header]
            : String(item[header]);
        const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
        return `"${escapedValue}"`; // Enclose value in double quotes
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  }

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    document.body.scrollTop = 0;
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //get sort field data
  const SortData = (Field) => {
    document.getElementById("hideloding").style.display = "block";
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");

      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: Field,
        Search: SearchedVal,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        IncludeAllResponse: IncludeAllResponse,
        FromDate: fromDate,
        ToDate: toDate,
      };
      const SalesReplyMessageList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/salesreply_message/GetSalesReplyMessagelist",
        method: "POST",
        data: InputParameter,
      });
      SalesReplyMessageList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
 
  };

  //delete new code
  const DeleteBtn = async (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to permanently delete this email from your email account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (Result) => {
      if (Result.isConfirmed) {
        var data = {
          _id: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        const Res = await Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/salesreply_message/SalesReplyMessageDelete",
          method: "POST",
          data: data,
        });
        if (Res) {
          if ((Res.statusText = "Ok")) {
            toast.success(
              <div className="toastsize">
                "Response Message" <br /> "Response Message delete
                successfully."
              </div>
            );
            document.getElementById("hideloding").style.display = "block";
            setTimeout(async () => {
              await SalesReplyMessageListGet(
                ClientID,
                UserID,
                Role,
                fromDate,
                toDate,
                Page
              );
              await GetEmailResponsesGraph(
                ClientID,
                UserID,
                Role,
                fromDate,
                toDate
              );
              await GetResponsesGraphLast90Days(
                ClientID,
                UserID,
                fromDate,
                toDate,
                Role
              );
            }, 2000);
            // history.push("/Campaigns");
            SetPage(1);
            SetSearch("");
            document.getElementById("search").value = "";
            // props.updateFromChild(true)
          } else {
          }
        }
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //Update Responder
  const ResponderCategorySelect = (event, id) => {
    var data = {
      _id: id,
      ResponseCategoryID: event.target.value,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
      Role: Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/salesreply_message/UpdateSalesReplyCategoryDropDown",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        // window.location.reload(true);
        SalesReplyMessageListGet(
          ClientID,
          UserID,
          Role,
          fromDate,
          toDate,
          Page
        );
        GetEmailResponsesGraph(ClientID, UserID, Role, fromDate, toDate);
        toast.success(
          <div className="toastsize">
            Response Category <br /> Response category updated successfully.
          </div>
        );
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  //Open Notes
  const OpenNotesModel = (ID) => {
    var data = {
      ID: ID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/all_responses/NoteByMessageID",
      method: "POST",
      data: data,
    }).then((res) => {
      console.log(res);
      SetNoteDetail(res.data?.Data.BodyDataPlain);
      SetOpenNotes(true);
    });
  };

  //Close Notes
  const CloseNotes = () => {
    SetOpenNotes(false);
    SetNoteDetail("");
  };

  // Responses graph details
  const GetEmailResponsesGraph = (CID, UID, URole, FromDate, ToDate) => {
    document.getElementById("hideloding").style.display = "block";
    var InputParameter = {
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IncludeAllResponse: IncludeAllResponse,
      FromDate: FromDate,
      ToDate: ToDate,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/salesreply_message/GetResponsesGraphDetails",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data?.GraphResponseCategory?.length > 0) {
          // Prepare data with numeric value and custom label
          //  var formattedData = Res.data.GraphResponseCategory.map(item => ({
          //   label: `${item.label} (count: ${item.count})`,
          //   value: item.value
          // }));

          var dataStatus = {
            chart: {
              //caption: "Response Status",
              //subcaption: "For a net-worth of $1M",
              theme: "fusion",
              subCaption: " ",
              formatnumberscale: "0",
              showLegend: "1",
              showLabels: "0",
              showValues: "1",
              valuePosition: "inside",
              numbersuffix: "%",
              plothighlighteffect: "false",
              legendcaptionbold: "1",
              legendcaptionfontsize: "15",
              legendCaptionFontColor: "#000",
              showPercentValues: "0",
              showPercentInToolTip: "0",
              enableSlicing: "1",
              legendPosition: "bottom",
              legendBgColor: "#FFF",
              legendBorderColor: "#ffF",
              legendShadow: "0",
              legendItemFontSize: "14",
              legendWidth: "300",
            },
            data: Res.data.GraphResponseCategory,
          };

          SetPieChartData(dataStatus);
          document.getElementById("hideloding").style.display = "none";
        } else {
          var dataStatus = {
            chart: {
              //caption: "Response Status",
              //subcaption: "For a net-worth of $1M",
              theme: "fusion",
              subCaption: " ",
              formatnumberscale: "0",
              showLegend: "1",
              showLabels: "0",
              showValues: "1",
              valuePosition: "inside",
              numbersuffix: "%",
              plothighlighteffect: "false",
              legendcaptionbold: "1",
              legendcaptionfontsize: "15",
              legendCaptionFontColor: "#000",
              showPercentValues: "0",
              showPercentInToolTip: "0",
              enableSlicing: "1",
              legendPosition: "bottom",
              legendBgColor: "#FFF",
              legendBorderColor: "#ffF",
              legendShadow: "0",
              legendItemFontSize: "14",
              legendWidth: "300",
            },
            data: Res.data.GraphResponseCategory,
          };

          SetPieChartData(dataStatus);
          document.getElementById("hideloding").style.display = "none";
        }
      } else {
        document.getElementById("hideloding").style.display = "none";
      }

      //
    });
  };
  const GetResponsesGraphLast90Days = (CID, UID, FROMDate, TODate, URole) => {
    document.getElementById("hideloding").style.display = "block";
    var InputParameter = {
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      FromDate: FROMDate,
      ToDate: TODate,
      IncludeAllResponse: IncludeAllResponse,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/salesreply_message/GetResponsesGraphLast90DaysDetails",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data?.CountData?.length > 0) {
          SetResponseCoutData(Res.data?.CountData);
          SetResponseDateData(Res.data?.DateDate);
          document.getElementById("hideloding").style.display = "none";
        } else {
          SetResponseCoutData(Res.data?.CountData);
          SetResponseDateData(Res.data?.DateDate);
          document.getElementById("hideloding").style.display = "none";
        }
      } else {
        document.getElementById("hideloding").style.display = "none";
      }

      //
    });
  };
  const state = {
    series: [
      {
        name: "Responses",
        color: "#360947",
        data: ResponseCoutData,
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 450,
        width: 600,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 3, 4, 2],
        //dashArray: [0, 0, 5, 2],
        curve: "smooth",
      },
      title: {
        text: "Response Count By Day",
        align: "left",
        style: {
          fontFamily: "Montserrat",
        },
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.1,
        },
      },
      xaxis: {
        title: {
          text: "Date",
        },
        categories: ResponseDateData,
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6, // Size of the marker when hovered
        },
      },
    },
  };
  const HandlechangeResponse = async (e) => {
    const isChecked = e.target.checked;
    SetPage(1);
    setIncludeAllResponse(isChecked);
  };

  return (
    <div className="px-1">
      {IsLoading == true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <Popupone open={OpenNotes} modal>
        <div className="modal-black"></div>
        <div className="filterPopup bigpopupcontent largerPopup1000">
          <div className="paddingboxTerms">
            <div className="modal-header">
              <h5>Email Response</h5>
              <a
                className="close-ion"
                onClick={() => {
                  CloseNotes();
                }}
              >
                <span aria-hidden="true" class="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone p-0">
              <iframe
                srcDoc={`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
      </head>
      <body class="m-0 p-5 overflow-x-auto overflow-y-auto">
        <div class="prose">${NoteDetail}</div>
      </body>
      </html>
    `}
                className="w-full border-none"
                height="100%"
                onLoad={(e) => {
                  const iframe = e.target;
                  iframe.style.height =
                    iframe.contentWindow.document.body.scrollHeight + "px";
                }}
              />
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => {
                  CloseNotes();
                }}
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popupone>

      <div className="row align-items-center py-2">
        <div className="col px-3">
          <h4 className="headertitlebd lg-headertitle  float-left my-0">
            Email Responses
          </h4>
        </div>
        <div className="col px-3 padright">
          <div className="btn-group datepicker-hed">
            <div className="col-lg-6 timedatepikker date-input">
              <input
                className="form-control m-input"
                type="text"
                id="from-date"
                ref={daterefect}
              />
            </div>
            <div className="col-lg-6 timedatepikker date-input">
              <input
                className="form-control m-input"
                type="text"
                id="to-date"
                ref={daterefect2}
              />
            </div>
            <button
              id="aCompanyState"
              className="btn btn-primary btnColor m-btn brrb72"
              autoComplete="off"
              onClick={SaveBtn}
            >
              <i className="la flaticon-diagram"></i>
              <span> Analyze</span>
            </button>
          </div>
        </div>
        {/* <div className="col px-4">
          
        </div> */}
      </div>

      <div className="row pb-3 pt-2">
        <div className="col d-flex justify-content-end">
          <div className="mr-4 mb-2">
            <div className="boxborders">
              <label class="check_bx nowrap">
                <input
                  type="checkbox"
                  id="includeAllStatus"
                  onChange={HandlechangeResponse}
                />
                <span className="checkmark"></span>
                Include All Responses
              </label>
            </div>
          </div>
          {/* <div className='d-flex align-items-start'>
          <a href="javascript:void(0);" class="btn btn-primary btnColor"><i class="la la-search"></i>Search</a>
          <a href="javascript:void(0);" class="ml-3 btn btn-secondary"><i class="la la-refresh"></i> Reset</a>
        </div> */}
          <div className="listing-li ml-3">
            <ul className="mt-0">
              {/* <CSVDownloader
                data={ExportData}
                filename={Cname + " CampaignResponsesDetail.csv"}
                bom={true}
              > */}
              <a
                onClick={exportcsv}
                className="btn btngroup m-btn m-btn--custom"
              >
                <i class="la la-icon-font-size-13 la-download"> </i>Export
              </a>
              {/* </CSVDownloader> */}
            </ul>
          </div>
        </div>
      </div>

      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>

      <div className="row mb-2">
        <div className="col-sm-6">
          <div className="bg-white h-100 p-3">
            <h6 className="chartheading pl-1 py-0">Response Categories</h6>
            <div className="chartboxsmet">
              <ReactFusioncharts
                key={JSON.stringify(PieChartData)}
                type="pie3d"
                width="100%"
                height="100%"
                dataFormat="JSON"
                dataSource={PieChartData}
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div className="bg-white h-100 p-3">
            <div id="chart">
              <ReactApexchart
                options={state.options}
                series={state.series}
                type="line"
                height={450}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-3 mt-3">
        <div className="row padt-25 px-1">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                className="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:{" "}
              <input
                Type="search"
                id="search"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered mx-1">
          <TableContainer component={Paper}>
            <StyleHeader isDataLoaded={isDataLoaded} />
            <Table className="table-ref" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("From");
                      }}
                    >
                      From
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && SortField === "From"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && SortField === "From"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("AName");
                      }}
                    >
                      Account Name
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && SortField === "AName"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && SortField === "AName"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Subject");
                      }}
                    >
                      Subject
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && SortField === "Subject"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && SortField === "Subject"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell className="wrap">
                    <a
                      onClick={() => {
                        SortData("Snippet");
                      }}
                    >
                      Snippet
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && SortField === "Snippet"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && SortField === "Snippet"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("MsgDateTimeByTimeZone");
                      }}
                    >
                      Response Date
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 &&
                            SortField === "MsgDateTimeByTimeZone"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 &&
                            SortField === "MsgDateTimeByTimeZone"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Campaign");
                      }}
                    >
                      Campaign
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && SortField === "Campaign"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && SortField === "Campaign"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                    // onClick={() => {SortData("StepNumber");}}
                    >
                      Step Number
                      {/* <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && SortField === "StepNumber" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && SortField === "StepNumber" ? "active" : null} />
                      </span> */}
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("ResponseCategoryID");
                      }}
                    >
                      Response Category
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && SortField === "ResponseCategoryID"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 &&
                            SortField === "ResponseCategoryID"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows?.map((row) => (
                    <React.Fragment key={row._id}>
                      <TableRow>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              OpenNotesModel(row._id);
                            }}
                            aria-label="expand row"
                            size="small"
                          >
                            <i
                              class="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          </IconButton>
                        </TableCell>
                        <TableCell className="wrap">{row?.From}</TableCell>
                        <TableCell>{row?.AName}</TableCell>
                        <TableCell>{row?.Subject}</TableCell>
                        <TableCell className="wrap">
                          {row?.Snippet.split(`\n\nOn`)[0]}
                        </TableCell>
                        <TableCell>{row?.MsgDateTime}</TableCell>
                        <TableCell>{row?.Campaign}</TableCell>
                        <TableCell>
                          {row?.StepNumber +
                            "-" +
                            String.fromCharCode(64 + row.OrderBy)}
                        </TableCell>
                        <TableCell className="labeltds" scope="row">
                          <select
                            className="form-control  m-input"
                            data-val="true"
                            data-val-number="The field Status must be a number."
                            id="ResponderCategoryId"
                            name="Status"
                            defaultValue={row?.ResponseCategoryID}
                            onChange={(e) => {
                              ResponderCategorySelect(e, row?._id);
                            }}
                          >
                            {/* <option value="">--Select--</option> */}
                            {ResponderCategoryList?.map((value) => (
                              <option
                                key={value?._id}
                                value={value?._id}
                                selected={value?._id === row.ResponseCategoryID}
                              >
                                {value?.Name}
                              </option>
                            ))}
                            {/* {ResponderCategoryList?.map((value) => (
                              <option value={value?._id}>
                                {value?.Name}
                              </option>
                            ))} */}
                          </select>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              DeleteBtn(row._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-delete-1 delete-icon"></i>
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={Open[row._id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {Open[row._id] && (
                              <Box margin={1} className="innertables">
                                <Table size="small" aria-label="purchases">
                                  <TableHead></TableHead>

                                  <TableRow>
                                    <TableCell>Response</TableCell>
                                    <TableCell>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            row?.BodyData[1].BodyDataPlain,
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </Table>
                              </Box>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </TableBody>

              <div className="row"></div>
            </Table>
          </TableContainer>
        </div>
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResponceAllTable;
