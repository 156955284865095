import React, { useEffect } from "react";

export default function Child({ customClass = "table-bordered", isDataLoaded, stickyIndex = "" }) {
    let isWidthSet = false;
    useEffect(() => {
        if (isDataLoaded) {
            document.querySelector('body').addEventListener('scroll', handleScroll);
            //Left sticky TD only
            if (stickyIndex && stickyIndex !== '') {
                const tableref = document.querySelector(`.${customClass} .table-ref`);
                const table = tableref.querySelectorAll(`.${customClass} .table-ref th:nth-child(-n+${stickyIndex}), .${customClass} .table-ref td:nth-child(-n+${stickyIndex})`);
                let leftOffset = 0;
    
                table.forEach((column, index) => {
                    if (index % stickyIndex === 0) leftOffset = 0; // Reset for each row 
                    column.style.left = `${leftOffset}px`;
                    column.style.zIndex = '10';
                    column.style.position = 'sticky'
                    column.style.backgroundColor = '#ffffff';
                    leftOffset += column.offsetWidth;
                });
            }

            return () => {
                console.log("Removing event listener");
                document.querySelector('body').removeEventListener('scroll', handleScroll);
            };
        }
    }, [isDataLoaded]);

    const handleScroll = () => {
        stickyTopHeader();
    };
    const stickyTopHeader= () => {
        const table = document.querySelector(`.${customClass} .table-ref`);
        if (!table) return;

        const tableRect = table.getBoundingClientRect();
        const stickyHeader = document.querySelector(`.${customClass} .sticky-header`);

        if (tableRect.top <= 0 && tableRect.bottom >= 30) {
            stickyHeader.classList.add('visible');
            if (!isWidthSet) {
                createStickyHeader();
                isWidthSet = true;
            }
        } else {
            stickyHeader.classList.remove('visible');
            isWidthSet = false;
        }
        const scrollLeft = document.querySelector(`.${customClass} .MuiTableContainer-root`).scrollLeft;
        const clonedThead = document.querySelector(`.${customClass} #clonedHeader`);
        if (clonedThead) {
            clonedThead.style.transform = `translateX(-${scrollLeft}px)`;
        }
    }


    const handleTableScroll = () => {
        const scrollLeft = document.querySelector(`.${customClass} .MuiTableContainer-root`).scrollLeft;
        const clonedThead = document.querySelector(`.${customClass} #clonedHeader`);
        if (clonedThead) {
            clonedThead.style.transform = `translateX(-${scrollLeft}px)`;
        }

        const stickyLeft = document.querySelector(`.${customClass} .table-left-sticky`);
        // sticky left top header
        if (stickyLeft) {
            const stickyColumns = clonedThead.querySelectorAll(`.${customClass} .table-left-sticky th:nth-child(-n+${stickyIndex})`);
            let leftOffset = 0;

            stickyColumns.forEach((column, index) => {
                if (index % stickyIndex === 0) leftOffset = 0; // Reset for each row 
                column.style.left = `calc(${scrollLeft}px + ${leftOffset}px`;
                column.style.zIndex = '12';
                column.style.position = 'sticky'
                column.style.backgroundColor = '#ffffff';

                leftOffset += column.offsetWidth;
            });
        }
    };

    const createStickyHeader = () => {
        const table = document.querySelector(`.${customClass}, .table-ref`);
        if (!table) return;

        const clonedHeader = document.querySelector(`.${customClass} #clonedHeader`);
        if (clonedHeader) {
            clonedHeader.remove();
        }
        const thead = table.querySelector('thead');
        const clonedThead = thead.cloneNode(true);

        const originalThs = thead.querySelectorAll('th');
        const clonedThs = clonedThead.querySelectorAll('th');
        originalThs.forEach((originalTh, index) => {
            const width = window.getComputedStyle(originalTh).width;
            clonedThs[index].style.width = width;
        });

        const stickyTable = document.querySelector(`.${customClass} table`);
        stickyTable.style.width = `${document.querySelector(`.${customClass}`).offsetWidth}px`;

        clonedThead.id = 'clonedHeader';
        document.querySelector(`.${customClass} .sticky-header`).appendChild(clonedThead);
        document.querySelector(`.${customClass} .MuiTableContainer-root`).addEventListener('scroll', handleTableScroll);

        const tableborder = document.querySelector(`.${customClass}`)
        console.log(tableborder)
    };

    return (
        <table className="sticky-header"></table>
    );
}
