import React,{useEffect}  from 'react';
import Axios from "axios";
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <i class="fa fa-minus-circle minusl" aria-hidden="true"></i> : <i class="fa fa-plus-circle plusbl" aria-hidden="true"></i>}
          </IconButton>
        </TableCell>
        <TableCell>
          <a href="#" className="btn-eyesicon"><i class="la flaticon-eye edit-icon"></i></a>
          <a href="#" className="btn-eyesicon"><i class="la flaticon-delete-1 delete-icon"></i></a>
        </TableCell>
        <TableCell scope="row">
          <a href="#" className="btn-exchangeicon"><i class="la la-exchange"></i></a> 
        </TableCell>
        <TableCell className="labeltds" scope="row">
        jayeshrana
        </TableCell>
        <TableCell className="labeltds" scope="row">
            A_Company761
        </TableCell>
        <TableCell>8 June 2021</TableCell> 
        <TableCell>test</TableCell>
        <TableCell>12/15/2020</TableCell>
        <TableCell>
          3650542
        </TableCell>
        <TableCell>test</TableCell>
        <TableCell>testr@gmail.com</TableCell>
        <TableCell><a href="#">gmail.com</a></TableCell>
        <TableCell>Advertising - Facebook</TableCell>
        <TableCell>test owner</TableCell>
        <TableCell>12/16/2020 03:50 PM</TableCell>
        <TableCell>Yes</TableCell>
        <TableCell>12/15/2020</TableCell>
        <TableCell>12/15/2020</TableCell>
        <TableCell>12/15/2020</TableCell>
        <TableCell>Manager</TableCell>
        <TableCell>
          <a href="#" className="btn-eyesicon"><i class="la flaticon-eye edit-icon"></i></a>
          <a href="#" className="btn-eyesicon"><i class="la flaticon-delete-1 delete-icon"></i></a>
        </TableCell>


      </TableRow>
      
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} className="innertables">
              <Table size="small" aria-label="purchases">
                 <TableHead>
                </TableHead> 
                <TableRow>
                <TableCell align="center">
        </TableCell>
        <TableCell scope="row">
          
        </TableCell>
        <TableCell className="labeltds" scope="row">
         
        </TableCell>
        <TableCell className="labeltds" scope="row">
             
        </TableCell>
        <TableCell>8 June 2021</TableCell>
        <TableCell>  </TableCell>
        <TableCell> </TableCell>
        <TableCell>12/15/2020</TableCell>
        <TableCell> 
        </TableCell>
        <TableCell> </TableCell>
        <TableCell> </TableCell>
        <TableCell> </TableCell>
        <TableCell> </TableCell>
        <TableCell> </TableCell>
        <TableCell></TableCell>

                </TableRow>
              </Table>

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
    
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Userimg: PropTypes.number.isRequired,
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79), 
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

export default function AccountAllTable({CCampaignID,ListUpdate}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    CampaignAccountGet(Details.ClientID,Details.ParentUserID,Details.Role);
    
  }, [Search, Page, RowsPerPage, CCampaignID, ListUpdate]);

  //Get Keyword List
  const CampaignAccountGet = async (cID,uID,Roles) => {
    
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: cID,
      UserID: uID,
      Role: Roles,
      CampaignID:CCampaignID
    };
    const CampaignAccountList = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/campaignaccount/CampaignAccountGet",
      method: "POST",
      data: InputParameter,
    });  
    if(CampaignAccountList.data.StatusMessage == "SUCCESS"){
      SetRows(CampaignAccountList.data.PageData);
      SetRlen(CampaignAccountList.data.TotalCount);
      SetFlen(CampaignAccountList.data.TotalCount);
      SetCountPage(CampaignAccountList.data.PageCount);
    }
    
  };
 
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
    }
  };

   //change Page
   const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
   
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
    
  };

  //delete Keyword
  const DeleteBtn = async (ID) => {
    
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete an email account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        
        var InputData = {
          ID: ID,
          UserID:CUserID
        };
        Axios({
          url:CommonConstants.MOL_APIURL +"/campaignaccount/CampaignAccountDelete",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.data.StatusMessage = "SUCCESS")) {
              Swal.fire(
                "Deleted!",
                "Email account deleted successfully.",
                "success"
              );
              
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              CampaignAccountGet(ClientID,UserID,Role);
              //updateFromChild(true)
              //KeywordsGet(ClientID,UserID,Role);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  return (
    <>
      <div className="row padt-25">
          <div className="col-sm-12 col-md-6">
              <label className="textlabelte">Show 
              <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
              entries</label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">Search: <input id ="search" type="search" class="form-control form-control-sm ml-2" placeholder="" onKeyPress={RequestSearch} aria-controls="tbl_meeting" /></label>
          </div>
      </div>
    <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Title Name</TableCell>
                <TableCell>From Email</TableCell>
                <TableCell>SMTP Email</TableCell>
                <TableCell>SMTP Host</TableCell>
                <TableCell>SMTP Port</TableCell>
                <TableCell>IMAP Email</TableCell>
                <TableCell>IMAP Host</TableCell>
                <TableCell>IMAP Port</TableCell>
                <TableCell>Throttle Starting Volume</TableCell>
                <TableCell>Throttle Ending Volume</TableCell>
                <TableCell>Email Delay</TableCell>
                <TableCell>Days Till Full Throttle</TableCell>
                <TableCell>Today's Potential Volume</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {Rows.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows?.map((row) => (
                    <TableRow>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.AliasTitle}</TableCell>
                  <TableCell>{row.SMTPFromEmail}</TableCell>
                  <TableCell>{row.SMTPEmail}</TableCell>
                  <TableCell>{row.SMTPHost}</TableCell>
                  <TableCell>{row.SMTPPort}</TableCell>
                  <TableCell>{row.IMAPEmail}</TableCell>
                  <TableCell>{row.IMAPHost}</TableCell>
                  <TableCell>{row.IMAPPort}</TableCell>
                  <TableCell>{row.ThrottleStartingVolume}</TableCell>
                  <TableCell>{row.ThrottleEndingVolume}</TableCell>
                  <TableCell>{row.EmailDelay}</TableCell>
                  <TableCell>{row.DaysTillFullThrottle}</TableCell>
                  <TableCell>{row.CampaignMailSentTodaypotential?row.CampaignMailSentTodaypotential:row.CampaignMailSentToday}</TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            DeleteBtn(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    {Sflag ? (
      <div className="row">
        <Pagination
          component="div"
          count={CountPage}
          page={Page}
          onChange={HandleChangePage}
          showFirstButton
          showLastButton
        />
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries (filtered from {Flen} total entries)
          </p>
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    )}
    </>
  );
}
