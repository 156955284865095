import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import loadingicon from "../../../images/loading.gif";
import Footer from "../../../_components/user/footer/footer";

toast.configure();

//Sorted each item
const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));

//Sorted List name
const SortableList = SortableContainer(({ Items }) => {
  return (
    <ul>
      {Items?.map((Value, Index) => (
        <SortableItem
          id={Index}
          key={`item-${Index}`}
          index={Index}
          value={Value.Reason}
        />
      ))}
    </ul>
  );
});

class AccountReasonSetOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: [],
      Pager: {},
      PageOfItems: [],
      Files: [],
      List: [],
      Columns: [],
      ReSet: false,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role:null,
      IsButtonDisabled:false
    };
    this.SaveOrder = this.SaveOrder.bind(this);
    this.BackBtn = this.BackBtn.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title="Account Reason Unqualified Order | SalesHive"
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.GetAccountReasonSetOrder(Details.ClientID);
  }

//Get Set Order list
  GetAccountReasonSetOrder(CID) {
    var InputParameter = {
      Page: 1,
      RowsPerPage: 100,
      Field: "OrderBy",
      Sortby: 1,
      Type: "User",
      ClientID: CID,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_reason_unqualified/AccountReasonUnqualifiedList",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((Result) => {
      this.setState({ Items: Result.data.PageData });
      document.getElementById("hideloding").style.display = "none";
    });
  }

//Sort End
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      Items: arrayMoveImmutable(this.state.Items, oldIndex, newIndex),
    });
  };

//Add order
  SaveOrder() {
    this.setState({IsButtonDisabled:true})
    let OrderBy = 0;
    for (let i = 0; i < this.state.Items.length; i++) {
      this.state.Items[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.SaveData();
  }

//Add reason unqualified
  SaveData() {
    //this.setState({IsButtonDisabled:true});
    let str_in = {
      AccountReasonunqualifiedList: this.state.Items,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_reason_unqualified/AccountReasonUnqualifiedSetOrder",
      method: "POST",
      data: str_in,
    });
    rows1.then((Result) => {
      if (Result.data.StatusMessage == "SUCCESS") {
        toast.success(<div className="toastsize" >Account reason unqualified<br/>Account reason unqualified order updated successfully.</div>);
        history.push("/accountreasonunqualified");
      } else {
        this.setState({IsButtonDisabled:false});
        toast.error(Result.data.Message);
      }
      this.GetAccountReasonSetOrder(this.state.ClientID);
    });
  }

  BackBtn() {
    history.push("/accountreasonunqualified");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
            <Sidebar className="" />
          <div className="bodyhome">
            
            <div className="row pt-4 pb-3">
              <div className="col px-3">
                <h4 className="headertitlenop lg-headertitle float-left px-1">
                Account Reason Unqualified Order 
                </h4>
              </div>
            </div>

            <div class="whiterow">
              <div className="row pt-4 px-4 ">
                <div className="col-xl-12 offset-xl-12 px-3 pt-2">
                  <span className="alertinfo ">
                    &nbsp;<b>NOTE</b> : Please drag & drop item to change order of reason unqualified status and press save button.
                  </span>

                  <div className="py-4">
                    <SortableList
                      Items={this.state.Items}
                      onSortEnd={this.onSortEnd}
                    />
                  </div>
                </div>
              </div>
              <div id="hideloding" className="loding-display">
                <img src={loadingicon} />
              </div> 
              </div>

              <div class="row pb-4 pt-4"> 
                <div class="pull-left px-3">
                  <button
                    id="submit"
                    onClick={() => {
                      this.SaveOrder();
                    }}
                    class="btn btn-primary btn-lightgreen mr-1"
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i class="la la-save"></i> Save order
                  </button>
                  <a
                    id="backtolist"
                    onClick={() => {
                      this.BackBtn();
                    }}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAccountReasonSetOrderPage = connect(
  mapState,
  actionCreators
)(AccountReasonSetOrderPage);
export { connectedAccountReasonSetOrderPage as AccountReasonSetOrderPage };
