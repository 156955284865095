import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import ClientSidebar from '../../../_components/clients/nav-sidebar/Sidebar';

import { toast } from "react-toastify";
toast.configure();

class AddBacklinksPipeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      MeetingEmailAvailable: null,
      BacklinkDropdown: [],
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
      IsExist: false,
      IsValid: true,
      IsButtonDisabled: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
    this.datestartset = React.createRef();
    this.dateliveset = React.createRef();
    // this.pikerdatevalue = this.pikerdatevalue.bind(this);
  }
  componentDidMount() {
    document.title = `Add Backlink Pipeline | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }

    const $datestartsetElement = $(this.datestartset.current);
    $datestartsetElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    const $datelivesetElement = $(this.dateliveset.current);
    $datelivesetElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    //Notes
    var EditSample = {
      Role: this.state.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/BackLinkPipeLine/GetDropDownStatuslist",
      method: "POST",
      data: EditSample,
    })
      .then((res) => {
        this.setState({
          BacklinkDropdown: res.data.Data || [],
        });
      })
      .catch((error) => {});
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var BacklinkStatus = document
      .getElementById("BacklinkStatusID")
      .value.trim();

    if (BacklinkStatus == "") {
      FormIsValid = false;
      Errors["Status"] = "Please enter Status";
    }

    this.setState({ Errors: Errors });

    return FormIsValid;
  }

  // form validation
  HandleChangeBlur() {
    let Errors = { ...this.state.Errors };
    var BacklinkStatus = document
      .getElementById("BacklinkStatusID")
      .value.trim();

    if (BacklinkStatus == "") {
      Errors["Status"] = "Please enter Status";
    } else {
      Errors["Status"] = "";
    }
    this.setState({ Errors: Errors });
  }

  // handle change
  //   HandleChange(Field, e) {
  //     let Fields = this.state.Fields;
  //     Fields[Field] = e.target.value;
  //     this.setState({ Fields });
  //     var Email = document.getElementById("Email").value.trim();
  //     if (Email != "") {
  //       this.state.Errors.Email = null;
  //       this.state.MeetingEmailAvailable = null;
  //       this.MeetingOwnersCheckExist(Email);
  //     } else {
  //       this.state.MeetingEmailAvailable = null;
  //       this.state.Errors.Email = null;
  //     }
  //   }

  // find exits meeting owner Email
  //   async MeetingOwnersCheckExist(Email) {
  //     let regexp =
  //       /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //     let Errors = {};
  //     if (Email != "") {
  //       if (!regexp.test(Email)) {
  //         return "InvalidEmail";
  //       } else {
  //         var MeetingSource = {
  //           ClientID: this.state.ClientID,
  //           Email: Email,
  //           Role: this.state.Role,
  //         };
  //         var resdata = await Axios({
  //           url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerExists",
  //           method: "POST",
  //           data: MeetingSource,
  //         });
  //         if (resdata.data.StatusMessage == "SUCCESS") {
  //           if (resdata.data.Data.length > 0) {
  //             this.setState({ MeetingEmailAvailable: resdata.data.Data[0].Name });
  //             return "ExistEmail";
  //           } else {
  //             return "AllDone";
  //           }
  //         }
  //       }
  //     } else {
  //       return "BlankData";
  //     }
  //   }

  // back to button
  BackBtn() {
    history.push("/backlinkspipeline");
  }

  // meeting owner add
  async SaveBtn(e) {
    this.setState({ IsButtonDisabled: true });
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var BacklinkStatusIDs = document.getElementById("BacklinkStatusID").value;
      var HostWebsites = document.getElementById("HostWebsite").value;
      var DomainScores = document.getElementById("DomainScore").value;
      var TargetURLs = document.getElementById("TargetURL").value;
      var AnchorTexts = document.getElementById("AnchorText").value;
      var GoogleDocURLs = document.getElementById("GoogleDocURL").value;
      var LiveLinkURLs = document.getElementById("LiveLinkURL").value;
      var datetimepickerstarts = document.getElementById(
        "datetimepickerstart"
      ).value;
      var datetimepickerlives =
        document.getElementById("datetimepickerlive").value;
      var Checkbacklinkpipelines = document.getElementById(
        "Checkbacklinkpipeline"
      ).checked;

      var MeetingOwnerAdd = {
        ClientID: this.state.ClientID,
        BacklinkStatusID: BacklinkStatusIDs,
        HostWebsite: HostWebsites,
        DomainScore: DomainScores,
        TargetURL: TargetURLs,
        AnchorText: AnchorTexts,
        GoogleDocURL: GoogleDocURLs,
        LiveLinkURL: LiveLinkURLs,
        StartDate: datetimepickerstarts,
        LiveDate: datetimepickerlives,
        IsSubmit: Checkbacklinkpipelines,
        IsDeleted: false,
        CreatedBy: this.state.CUserID,
        UserID: this.state.UserID,
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/BackLinkPipeLine/BacklinkPipeLineAdd",
        method: "POST",
        data: MeetingOwnerAdd,
      })
        .then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(<div>Backlink Pipeline added successfully.</div>);
            history.push("/backlinkspipeline");
          } else {
            toast.error(res.data.Message);
            this.setState({ IsButtonDisabled: false });
          }
        })
        .catch((error) => {
          this.setState({ IsButtonDisabled: false });
        });
    } else {
      this.setState({ IsButtonDisabled: false });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
        {this.state.Role  === "Client" ?(<ClientSidebar className="" />):(<Sidebar className="" />)}

          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad lg-headertitle float-left">
                  Add Backlink Pipeline
                </h4>
              </div>
            </div>

            <div class="paddcols">
              <div className="row py-3 bg-white mx-0">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Status</label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactSourceID must be a number."
                        id="BacklinkStatusID"
                        name="MeetingSourceID"
                        onChange={this.HandleChangeBlur}
                      >
                        <option value="">--Select--</option>
                        {this.state.BacklinkDropdown.map((value) => (
                          <option value={value?.FieldID}>
                            {value?.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Status"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Start Date</label>
                    <div className="col-lg-8 timedatepikker date-input">
                      <input
                        className="form-control m-input"
                        type="text"
                        id="datetimepickerstart"
                        ref={this.datestartset}
                      />
                      <span style={{ color: "red" }}>
                        {/* {this.state.errors["datevalue"]} */}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Host Website</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Host Website"
                        id="HostWebsite"
                        name="HostWebsite"
                        placeholder="Enter Host Website"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Domain Score</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Domain Score"
                        id="DomainScore"
                        // onBlur={() =>
                        //   this.HandleChangeBlur("EmailDetails")}
                        name="Email"
                        placeholder="Enter Domain Score"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {/* {this.state.Errors["Email"]} */}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Target URL</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Target URL"
                        id="TargetURL"
                        name="Conference"
                        placeholder="Enter Target URL"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Anchor Text</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Anchor Text"
                        id="AnchorText"
                        // onBlur={() =>
                        //   this.HandleChangeBlur("NameDetails")}
                        name="Name"
                        placeholder="Enter Anchor Text"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {/* {this.state.Errors["Name"]} */}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Google Doc URL
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Google Doc URL"
                        id="GoogleDocURL"
                        name="LastName"
                        placeholder="Enter Google Doc URL"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                <div class="row max-cols">
                <label class="col-lg-4 view-lable-meet">Submission Status</label>
                  <input type="checkbox" id="Checkbacklinkpipeline" />
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Submission Date</label>
                    <div className="col-lg-8 timedatepikker date-input">
                      <input
                        className="form-control m-input"
                        type="text"
                        id="datetimepickerlive"
                        ref={this.dateliveset}
                      />
                      <span style={{ color: "red" }}>
                        {/* {this.state.errors["datevalue"]} */}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Live URL
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first Email"
                        id="LiveLinkURL"
                        // onBlur={() =>
                        //   this.HandleChangeBlur("EmailDetails")}
                        name="Email"
                        placeholder="Enter Live Link URL"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {/* {this.state.Errors["Email"]} */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3 mt-3">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsButtonDisabled}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddBacklinksPipeline = connect(
  mapState,
  actionCreators
)(AddBacklinksPipeline);
export { connectedAddBacklinksPipeline as AddBacklinksPipeline };
