import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../../user/Kpis/LastdaysTabing";

import UserPerClientTable from "../../../_components/clients/usersperclient/UserPerClientTable";
import InvitesTable from "../../../_components/clients/usersperclient/InvitesTable";

import ImageUploading from "react-images-uploading";
import Popup from "reactjs-popup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ listupdate }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };
  const openTermsConditionPopup = () => {
    //
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    //
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };

  document.addEventListener("mouseup", function (e) {
    //
    if (popupval) {
      setpopup(false);
    }
  });
  //const openTermsConditionPopup = false;

  return (
    <div className="tabdes">
      <div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label="Client Users" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Invites" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
          <div className='bg-white'>
          <div className="row border-bottom mx-0">
            <div className="col">
              <h4 className="headertitle xs-headertitle float-left px-0">Client Users</h4>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col">
              <UserPerClientTable />
            </div>
          </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone whitebg" index={1}>
        <div className='bg-white'>
        <div className="row border-bottom mx-0">
        <div className="col">
              <h4 className="headertitle xs-headertitle float-left px-0">Invites</h4>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col">
              <InvitesTable listupdate={listupdate} />
            </div>
          </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
