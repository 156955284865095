const home = process.env.REACT_APP_HOME;
const database = process.env.REACT_APP_DATABASE;
const notificationBackendUrl = process.env.NotificationBackendUrl;

//Google API email Authantication Start
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_KEY = process.env.REACT_APP_CLIENT_KEY
//Google API Ends

//Google Scope Start
const SCOPE=process.env.REACT_APP_SCOPE
//Google Scope End
const CallRecoingURL = process.env.CALL_RECODING_URL

// //eMod URL and token
// const eMODURl=process.env.eModAuth_URL
// const eMODAUTHTOKEN=process.env.eModAuth_Token

// redirect url start
const REDIRECT_URL= database+"/emailaccount/AuthCallback"
// redirect url end
export const CommonConstants = {
    MOL_APIURL: database,
    enckey:'secret key 123',
    home:home,
    resetpass: home +'resetpassword',
    Token_timer:3,  //minitues
    RememberMe_timer:3,  //minites
    Title:' | SalesHive', //Title of page
    responder_Invitation:home + 'addresponder',
    seo_Invitation:home + 'addseo',
    coldcallers_Invitation:home + 'addcoldcallers',
    salesstrategists_invitation:home + 'addsalesstrategists',
    usersperclient_invitation:home + 'addclientusers',
    admin_user:home+'addadmin',
    Image_url:database + '/UserProfilePic/', // image path of server
    change_Email_responder:home + 'updateemailresponder',
    change_Email_seo:home + 'updateemailseo',
    change_Email_coldcallers:home + 'updateemailcoldcallers',
    change_Email_admin:home + 'updateemailadmin',
    change_Email_salesstrategists:home + 'updateemailstrategists',
    change_Email_usersperclient:home + 'updateusersperclient',
    change_Email_timer:3, //minites
    new_admin_url:home + 'addadmin',
    new_responder_url:home + 'addresponder',
    new_coldercaller_url:home + 'addcoldcallers',
    show_rows:[15,25,50,100,200,500],  //For rows show in table
    new_sales_strategists_url:home + 'addsalesstrategists',
    CLIENT_ID:CLIENT_ID,
    CLIENT_KEY:CLIENT_KEY,
    SCOPE:SCOPE,
    REDIRECT_URL:REDIRECT_URL,
    CallRecoingURL :database + "/PhoneBurnerCallDownload/",
    CallRecordingMeetingURL : database + "/MeetingAudio/",
    EditorValidationKey :"GPD2tA3A2B1A3C2F2sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2A1E4G1A3B8C7C6==",
    NotificationBackendUrl:'https://devapiv2.saleshive.com:6002/twilioconnect/',
    VoicemailRecordingsURL:database + "/VoiceMailRecordings/",
    CallRecordingBookkedMeetingURL :database + "/BookMeetingCallRecoding/",
    // EModURL:eMODURl,
    // EModToken:eMODAUTHTOKEN
};

// custom variable set for common use
export const Variables = [
    { "name": "FirstName" },
    { "name": "LastName" },
    { "name": "Email" },
    { "name": "Title" },
    { "name": "Company" },
    { "name": "CompanyNameLong" },
    { "name": "Website" },
    { "name": "CompanyPhone" },
    { "name": "LinkedInURL" },
    { "name": "Industry" },
    { "name": "Address1" },
    { "name": "Address2" },
    { "name": "City" },
    { "name": "State" },
    { "name": "Zip" },
    { "name": "Country" },
    { "name": "ContactCategory" },
    { "name": "AccountCategory" },
    { "name": "Client.Company" },
    { "name": "Day" },
    { "name": "Time" },
    { "name": "Day" },
    {"name": "AliasName"},
    {"name": "AliasTitle"},
    {"name": "AliasEmail"},
    { "name": "Length" },
    { "name": "Action" },
    { "name": "SMART.BiggerThan" },
    { "name": "SMART.FasterThan" },
    { "name": "SMART.MoreExcitingThan" },
    { "name": "SMART.MascotCheer" },
    { "name": "DayOfWeek" },
    { "name": "NextLine" },
    { "name": "Source" },
    ];

//Route Not use for ClientID
export const IndependedClientRoute = [
    "/deliverabilitytracking",
    "/campaignsummary",
    "/automator",
    "/despammer",
    "/emailvalidation",
    "/HiveAi",
    "/globalbounce",
    "/emailwarmup",
];

export const skipVariablesStep = [
    "{FirstName}", "{LastName}", "{Email}", "{Title}", "{Company}", "{CompanyNameLong}",
    "{Website}", "{CompanyPhone}", "{LinkedInURL}", "{Industry}", "{Address1}", "{Address2}",
    "{City}", "{State}", "{Zip}", "{Country}", "{ContactCategory}", "{AccountCategory}",
    "{Client.Company}", "{AliasName}", "{AliasTitle}", "{AliasEmail}", "{SMART.BiggerThan}",
    "{SMART.FasterThan}", "{SMART.MoreExcitingThan}", "{SMART.MascotCheer}", "{DayOfWeek}",
    "{NextLine}"
  ];

  // constants.js
export const COLORS = {
    primary: '#360947',
    secondary: '#F8BF58',
    primaryGradient: 'linear-gradient(0deg, rgba(54, 9, 71, 1) 0%, rgba(0, 0, 0, 1) 100%)',

    lightGrey: '#FAFAFA',
    black: '#000000',
    white: '#ffffff',
    salmon: '#E2856E',
    lightGreen: '#119DA4',
    darkGreen: '#4A7C59',
    aqua: '#0075A2',
    magenta: '#FA198B',
    ashyPurple: '#847996',
    darkMoss: '#646536',
    red: '#BA2D0B',
    pink: '#D782BA',
    brown: '#A3320B',
    moonstone: '#58A4B0',
    skyMagenta: '#BA7BA1',
    brightGreen: '#20BF55',
    brightOrange: '#E98A15',
    brightBlue: '#5386E4',
    brightPink: '#F374AE',
    brightPurple: '#560D71',
    rust: '#BF4E30',
    teal: '#778DA9',
    rustyGrey: '#6F686D',
    lightBrown: '#846C5B',
    emeraldGreen: '#58BC82',
    darkRed: '#A31621',
  };


  // CLIENT LIMITS
  export const Client_Limits = {
    LIMIT_EMAIL_SENT_PER_MONTH_PER_CLIENT : 10000,
    LIMIT_EMAILS_EMOD_PER_MONTH_PER_CLIENT:5000,
    LIMIT_USERS_PER_CLIENT : 10,
    LIMIT_TOTAL_CONCTACTS_PER_CLIENT : 50000,
    LIMIT_ZOOM_INFO_CONTACT : 0,
    LIMIT_TYPE_ZOOM_INFO_CONTACT : ""
 }

 // constant for script variables
 export const skipPhoneScriptVariable = [
  "{FirstName}", "{LastName}", "{Email}", "{Title}", "{Company}", "{CompanyNameLong}",
  "{Website}", "{CompanyPhone}", "{LinkedInURL}", "{Industry}", "{Address1}", "{Address2}",
  "{City}", "{State}", "{Zip}", "{Country}", "{ContactCategory}", "{AccountCategory}","{AliasName}","{AliasTitle}","{AliasEmail}","{DirectPhoneExt}"
];
 export const skipScriptVariable = [
  "{FirstName}", "{LastName}", "{Email}", "{Title}", "{Company}", "{CompanyNameLong}",
  "{Website}", "{CompanyPhone}", "{LinkedInURL}", "{Industry}", "{Address1}", "{Address2}",
  "{City}", "{State}", "{Zip}", "{Country}", "{ContactCategory}", "{AccountCategory}"
];

export const scriptVariable = ['First Name','Last Name','Email','Title','Company','CompanyNameLong',
  'Website','Company Phone','LinkedIn URL','Industry','Address1','Address2',
  'City','State','Zip','Country','Contact Category','Account Category'
]
export const phoneScriptVariable = ['First Name','Last Name','Email','Title','Company','CompanyNameLong',
  'Website','Company Phone','LinkedIn URL','Industry','Address1','Address2',
  'City','State','Zip','Country','Contact Category','Account Category','Alias Name','Alias Title','Alias Email','Direct Phone Ext'
]
export const dispositionSkipScriptVariable = [
  "{FirstName}", "{LastName}", "{Email}", "{Title}", "{Company}", "{CompanyNameLong}",
  "{Website}", "{CompanyPhone}", "{LinkedInURL}", "{Industry}", "{Address1}", "{Address2}",
  "{City}", "{State}", "{Zip}", "{Country}", "{ContactCategory}", "{AccountCategory}","{SDRPhoneNumber}"
];

export const dispositionScriptVariable = ['First Name','Last Name','Email','Title','Company','CompanyNameLong',
  'Website','Company Phone','LinkedIn URL','Industry','Address1','Address2',
  'City','State','Zip','Country','Contact Category','Account Category',"SDR Phone Number"
]

export const tzCodeMap = {
  "0": { timezone: "UTC", offset: "0", offsetPerHour: "0", abbreviation: "UTC", timezoneIdentifier: "Etc/UTC" },
  "1": { timezone: "Samoa Time", offset: "-660", offsetPerHour: "-11", abbreviation: "ST", timezoneIdentifier: "Pacific/Pago_Pago" },
  "2": { timezone: "Hawaiian Time", offset: "-600", offsetPerHour: "-10", abbreviation: "HT", timezoneIdentifier: "Pacific/Honolulu" },
  "3": { timezone: "Alaskan Time", offset: "-540", offsetPerHour: "-9", abbreviation: "AKT", timezoneIdentifier: "America/Anchorage" },
  "4": { timezone: "Pacific Time", offset: "-480", offsetPerHour: "-8", abbreviation: "PT", timezoneIdentifier: "America/Los_Angeles" },
  "6": { timezone: "Alaska Aleutians West Time (observes DST)", offset: "-600", offsetPerHour: "-10", abbreviation: "AKWT", timezoneIdentifier: "America/Adak" },
  "7": { timezone: "Pacific Standard Time (no DST)", offset: "-480", offsetPerHour: "-8", abbreviation: "PST", timezoneIdentifier: "America/Phoenix" },
  "10": { timezone: "Mountain Time", offset: "-420", offsetPerHour: "-7", abbreviation: "MT", timezoneIdentifier: "America/Denver" },
  "15": { timezone: "Arizona Time", offset: "-420", offsetPerHour: "-7", abbreviation: "AT", timezoneIdentifier: "America/Phoenix" },
  "17": { timezone: "Mountain Standard Time (no DST)", offset: "-420", offsetPerHour: "-7", abbreviation: "MST", timezoneIdentifier: "America/Phoenix" },
  "20": { timezone: "Central Time", offset: "-360", offsetPerHour: "-6", abbreviation: "CT", timezoneIdentifier: "America/Chicago" },
  "25": { timezone: "Saskatchewan", offset: "-360", offsetPerHour: "-6", abbreviation: "SK", timezoneIdentifier: "America/Regina" },
  "27": { timezone: "Central Standard Time (no DST)", offset: "-360", offsetPerHour: "-6", abbreviation: "CST", timezoneIdentifier: "America/Regina" },
  "35": { timezone: "Eastern Time", offset: "-300", offsetPerHour: "-5", abbreviation: "ET", timezoneIdentifier: "America/New_York", utc: "UTC-5" },
  "37": { timezone: "Eastern Standard Time (no DST)", offset: "-300", offsetPerHour: "-5", abbreviation: "EST", timezoneIdentifier: "America/Indianapolis" },
  "40": { timezone: "Indiana Eastern Time", offset: "-300", offsetPerHour: "-5", abbreviation: "IET", timezoneIdentifier: "America/Indianapolis" },
  "47": { timezone: "Atlantic Standard Time (no DST)", offset: "-240", offsetPerHour: "-4", abbreviation: "AST", timezoneIdentifier: "America/Puerto_Rico" },
  "50": { timezone: "Atlantic Time", offset: "-240", offsetPerHour: "-4", abbreviation: "AT", timezoneIdentifier: "America/Halifax" },
  "60": { timezone: "Newfoundland Time", offset: "-210", offsetPerHour: "-3.5", abbreviation: "NT", timezoneIdentifier: "America/St_Johns" },
  "275": { timezone: "West Pacific (Guam, Saipan) Time", offset: "600", offsetPerHour: "+10", abbreviation: "WPT", timezoneIdentifier: "Pacific/Guam" },
  "361": { timezone: "Pseudo Timezone for Toll-Free Numbers", offset: "-300", offsetPerHour: "-5", abbreviation: "PTN", timezoneIdentifier: "America/Toll_Free" }
};
