import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import AutomatorTabing from '../../_components/user/Automator/AutomatorTabing';
import CollapsibleTable from '../../_components/usertable';
import Footer from '../../_components/user/footer/footer';


class AutomatorPage extends React.Component {
   componentDidMount(){
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    
    document.title = "Automator | SalesHive"
   }

    render() {
        return (
            <>
                <div className="adminmain mheight">
                <Sidebar className=""/>
                    <div className="bodyhome">
                        <div className="row align-items-center">
                            <div className="col">
                                <h4 className="headertitlenop lg-headertitle pl-0 py-4 mb-0">Automator</h4>
                            </div>
                            <div className="col padright">                          
                            </div>
                        </div> 
                        <div className="col padd-0 px-0">
                            <AutomatorTabing />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedAutomatorPage = connect(mapState, actionCreators)(AutomatorPage);
export { connectedAutomatorPage as AutomatorPage };