import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import MeetingGoal from "../../../_components/user/MeetingGoal/MeetingGoal";

import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../../_constants/common.constants";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
toast.configure();
import "react-toastify/dist/ReactToastify.css";

const Fileupload = require("../../../_helpers/fileupload");
import loadingicon from "../../../images/loading.gif";

class MeetingGoalPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Files: [],
      Reset: false,
      CsvData: [],
      Dropboxdata: [],
      Filename: "",
      ExportData: [],
      ClientName: "",
      TotalDisplayCount: false,
      TotalResult: 0,
      TotalDuplicate: 0,
      TotalError: 0,
      TotalSuccess: 0,
      CName: "",
      ListUpdate:false,
      CountGoals:0,
      CountMeetings:0,
      CountCrushs:0,
      SampleExport:[{Week:"10/15/2019",Goal:1},{Week:"9/25/2019",Goal:5}],
      ImportBox:false,
      MapBox:false,
      AddBox:false,
      CountBox:false,
      GoalValue:"not mapped",
      WeekValue:"not mapped",
      BtnAndMapDisabled:false,
      IsLoading:false
    };
    this.HandleOnDrop = this.HandleOnDrop.bind(this);
    this.HandleOnError = this.HandleOnError.bind(this);
    this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
    this.ImportCsv = this.ImportCsv.bind(this);
    this.MapCsv = this.MapCsv.bind(this);
    this.CancleCsv = this.CancleCsv.bind(this);
    this.Savecsv = this.Savecsv.bind(this);
    this.AddBtn = this.AddBtn.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
   // this.setState({IsLoading:true})
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }

    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });
    var MeetingGoalExportData = {
      ClientID: this.state.ClientID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalExport",
      method: "POST",
      data: MeetingGoalExportData,
    }).then((res) => {
      this.setState({ ExportData: res?.data?.Data });
      this.setState({ ClientName: res?.data?.ClientName?.Name, });
       this.setState({IsLoading:false})
    });

//Totalcount
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetinggoal/CountsMeetingGoal",
      method: "POST",
      data: MeetingGoalExportData,
    }).then((res) => {
      this.setState({ CountGoals: res.data?.CountGoal});
      this.setState({ CountMeetings: res.data?.CountMeeting });
      this.setState({ CountCrushs: res.data?.CountCrush });
    });
  }


  // reset csv btn
  Resetcsv() {
    this.setState({ Reset: !this.state.Reset });
  }
  // handle dropdown
  HandleOnDrop(Data, FileInfo) {
    var Filename = FileInfo.name;
    this.setState({ Files: FileInfo });
    var sep = Filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      this.setState({ Reset: true });
      this.Resetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];

      this.setState({ Filename: csvfile });

      this.setState({ CsvData: Data });

      this.setState({ Dropboxdata: Data[0].data });
      this.setState({WeekValue:this.state.Dropboxdata.findIndex((item) =>item.toLowerCase() === "week" )})
      this.setState({GoalValue:this.state.Dropboxdata.findIndex((item) =>item.toLowerCase() ==="goal")})
    }
  }

  HandleOnError(err, file, inputElem, reason) {}

  HandleOnRemoveFile(data) {}
  // import csv
  ImportCsv() {
    this.setState({ListUpdate:false,ImportBox:true,BtnAndMapDisabled:false})
    
  }
  // map csv
  MapCsv() {
    this.setState({BtnAndMapDisabled:true})
    if (this.state.CsvData.length != 0) {
      this.setState({ImportBox:true,MapBox:true,BtnAndMapDisabled:false})
      this.state.Dropboxdata.map((dddd, index) => {});
      this.Resetcsv();

    } else {
      toast.error(<div className="toastsize">Meeting Goal <br/>
        Please select file</div>)
      this.Resetcsv();
      this.setState({ImportBox:true,MapBox:false,TotalDisplayCount:false,BtnAndMapDisabled:false})


    }
  }

  // cancel csv
  CancleCsv() {
    this.Resetcsv();
    this.setState({ TotalDisplayCount: false });
    this.setState({ImportBox:false,MapBox:false,CsvData:[],BtnAndMapDisabled:false})
  }

  // save csv
  Savecsv() {
    var Week = document.getElementById("Week").value;
    var Goal = document.getElementById("Goal").value;
    if (Week === "") {
      toast.error(
        <div className="toastsize">
          Meeting Goal
          <br />
          Week is required!
        </div>
      );
    }else if(Goal===""){
      toast.error(
        <div className="toastsize">
          Meeting Goal
          <br />
          Goal is required!
        </div>
      );
    }else{
    this.setState({IsLoading:true})
    var CsvData = this.state.CsvData;
    Fileupload.getBase64(this.state.Files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var myresult = {
        fields: "Coverimg",
        path: "./Content/UploadedFiles/UserMeetingGoal",
        file: _base64,
        imageName: this.state.Filename,
        filedata: CsvData,
        Goal: Goal,
        Week: Week,
        UserID: this.state.UserID,
        ClientID: this.state.ClientID,
        CreatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetinggoal/filesave",
        method: "POST",
        data: myresult,
      }).then((res) => {
        toast.success(
          <div className="toastsize">Meeting Goal added successfully.</div>
        );
        this.setState({ListUpdate:true,IsLoading:false})
        
        this.setState({ TotalDisplayCount: true });
        this.setState({ TotalResult: res.data.Total });
        this.setState({ TotalDuplicate: res.data.TotalDuplication });
        this.setState({ TotalError: res.data.TotalError });
        this.setState({ TotalSuccess: res.data.TotalSuccess });
        this.setState({ImportBox:true,MapBox:true,CsvData:[],BtnAndMapDisabled:true})
      });
    });
    }
  }

  // meeting goal add page
  AddBtn() {
    history.push("/addmeetinggoal");
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  // meeitng owner export csv
  ExportCsv() {
    toast.success(
      <div>
        Data exported successfully.
      </div>
    )
  }

  render() {
    return (
      <>
           {
            this.state.IsLoading ? <div id="hidelod" className="loding-display">
            <img src={loadingicon} />
          </div> : <></>
          }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle float-left pl-0">Meeting Goals </h4>
              </div>
            </div>

          <div className="bg-white px-3 py-2">
            <div className="row">
              <div className="col pt-1 pb-1">
                <div className="row colfive mx-0">
                  <div className="col-xl-4">
                    <div className="bg-light-sky boxcardcounter align-center">
                      <div className="d-flex">
                        <div className="contectboxes w-100">
                          <div className="line-title d-block text-center">
                            <span className="clr-sky box-main-title">{this.state.CountGoals==undefined?0:this.state.CountGoals}</span>
                          </div>
                          <div className="box-inner-title xs-headertitle">
                            <string className="clr-sky">
                              Total goals this quarter{" "}
                            </string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="bg-light-sky boxcardcounter align-center">
                      <div className="d-flex">
                        <div className="contectboxes w-100">
                          <div className="line-title d-block text-center">
                            <span className="clr-sky box-main-title">{this.state.CountMeetings==undefined?0:this.state.CountMeetings}</span>
                          </div>
                          <div className="box-inner-title xs-headertitle">
                            <string className="clr-sky">
                              Total Meetings this quarter{" "}
                            </string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="bg-light-sky boxcardcounter align-center">
                      <div className="d-flex">
                        <div className="contectboxes w-100">
                          <div className="line-title d-block text-center">
                            <span className="clr-sky box-main-title">{this.state.CountCrushs==undefined?0:this.state.CountCrushs}%</span>
                          </div>
                          <div className="box-inner-title xs-headertitle">
                            <string className="clr-sky">Crush %</string>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="listing-li float-right">
                  <ul>
                    <li>
                      <a
                        onClick={this.ImportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-upload"></i>
                        <span>Import</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.ExportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        
                        <span>
                          <CSVDownloader className="px-0"
                            data={this.state.ExportData}
                            filename={`${this.state.CName}-MeetingGoal`}
                            bom={true}
                          >
                            <i class="la la-icon-font-size-13 la-download"> </i>
                            Export
                          </CSVDownloader>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.AddBtn}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

      {
        this.state.ImportBox ? <div className="portletbody bg-white" id="import_csv">
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Import Meeting Goal</h3>
            <span className="m-alert alert-info m-alert--outline"><i className="la la-info-circle"></i>  A sample file is available for download,
            <CSVDownloader className="px-1"
                      data={this.state.SampleExport}
                      filename={`sample`}
                      bom={true}
                    ><a>please click here</a></CSVDownloader></span>

            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={this.HandleOnDrop}
                onError={this.HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={this.HandleOnRemoveFile}
                isReset={this.state.Reset}
              >
                <span>Select .CSV file</span>
                <p> Add Meeting Goal data from csv file to bulk upload.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a onClick={this.MapCsv} className="btn btn-primary btnColor">
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={this.CancleCsv} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div> : <></>
      }
            
      {
        this.state.MapBox ? <div className="portletbody bg-white border-top-0 my-0 p-0 pb-3" id="map_attribute">
        <div className="row col-xs-12 mx-0">
          <h4 className="headertitle  xs-headertitle float-left pl-0">Map attributes</h4>
        </div>
        <div className="col-xs-12">
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="Week">
                    Week
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Week"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value = {this.state.WeekValue}
                  onChange={(e)=>{this.setState({WeekValue:e.target.value})}}
                  disabled={this.state.BtnAndMapDisabled}
                >
                  <option value="">Not Mapped</option>
                  {this.state.Dropboxdata.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="Goal">
                    Goal
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Goal"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value = {this.state.GoalValue}
                  onChange={(e)=>{this.setState({GoalValue:e.target.value})}}
                  disabled={this.state.BtnAndMapDisabled}
                >
                  <option value="">Not Mapped</option>
                  {this.state.Dropboxdata.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <button
              onClick={this.Savecsv}
              disabled={this.state.BtnAndMapDisabled}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </button>
          </div>
        </div>
      </div> : <></>
      }
            

            {this.state.TotalDisplayCount == true ? (
              <div className="portletbody bg-white p-0 my-3 py-2" id=" ">
                <div className="row col-xs-12">
                  <h4 className="headertitle  xs-headertitle float-left mb-0 py-3">Results</h4>
                </div>

                <div className="col-xs-12">
                  <div className="row colfive mx-0">
                    <div className="col-md-3">
                      <div className="boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title d-block text-center mb-2">
                              <span className="box-main-title font-primary">
                                {this.state.TotalResult}
                              </span>
                            </div>
                            <div className="box-inner-title xs-headertitle">
                              <string className="clr-sky">Total</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title d-block text-center mb-2">
                              <span className="box-main-title font-success">
                                {this.state.TotalSuccess}
                              </span>
                            </div>
                            <div className="box-inner-title xs-headertitle">
                              <string className="clr-sky">Total success</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title d-block text-center mb-2">
                              <span className="box-main-title font-danger">
                                {this.state.TotalError}
                              </span>
                            </div>
                            <div className="box-inner-title xs-headertitle">
                              <string className="clr-sky">Total error</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title d-block text-center mb-2">
                              <span className="box-main-title font-warning">
                                {this.state.TotalDuplicate}
                              </span>
                            </div>
                            <div className="box-inner-title xs-headertitle">
                              <string className="clr-sky">Total Duplicate</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="paddcols py-0">
              <div className="row">
                <div className="col">
                  <MeetingGoal listupdate={this.state.ListUpdate} updateFromChild={this.UpdateFromChild} />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedMeetingGoalPage = connect(
  mapState,
  actionCreators
)(MeetingGoalPage);
export { connectedMeetingGoalPage as MeetingGoalPage };
