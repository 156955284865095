import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import Tag from '../../_components/user/Tag/Tag';
//import CollapsibleTable from '../_components/usertable';



class TagPage extends React.Component {
    componentDidMount() {
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle float-left pl-0">Tag</h4>
                            <div className="listing-li float-right padb-15 pt-2">
                                <ul>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-download"></i>
                                        <span>Export</span>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="addtag" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-plus"></i>
                                        <span>Add</span>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="fa la-icon-font-size-13 fa-filter"></i>
                                        <span>Add Filter</span>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>

                    <div class="paddcols">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <label className="textlabelte">Show 
                                <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                                <option value="15">15</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                </select> 
                                entries</label>
                            </div>
                            <div className="col-sm-12 col-md-6 full-right">
                            <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="table-bordered">
                                    <Tag />
                                </div>
                            </div>
                        </div>      

                        <div className="row">
                            <div className="col dataTables_info">
                            <p>Showing 1 to 71 of 71 entries</p>
                            </div>
                        </div>
                    </div>

                </div>

                    

                     

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedTagPage = connect(mapState, actionCreators)(TagPage);
export { connectedTagPage as TagPage };