import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup'; // Assuming you're using a Popup library
// import Sidebar from './Sidebar'; // Adjust import as per your project structure
import { CommonConstants } from "../../../_constants/common.constants";


const CallDialAddPotentialMeetingPage = ({ID, ClientID,UserID,ColdCallerUserID,FirstName,LastName,Title,Email,Company,DirectPhone,DirectPhoneExt,CompanyPhone,LinkedInURL,ClosePotentailMeetingModel,DisconnectCall, PerformNextActionAfterMeeting}) => {
  const [fields, setFields] = useState({
    FirstName: FirstName,
    LastName: LastName,
    Title: Title,
    Company: Company,
    Email: Email,
    MeetingSourceID: '',
    MeetingOwnerID: '',
    EngagementStatusID: '',
    ClientPOCID: '',
    CompanyPhone: CompanyPhone,
    DirectPhone: DirectPhone,
    DirectPhoneExt: DirectPhoneExt ?? '',
    LinkedInURL: LinkedInURL,
    ResponderName: '',
    ClientPotentialID: '',
    MeetingSetbyID:'',
    ReminderEmailAccount:""
  });
  const [state, setState] = useState({
    ResponderName: ''
  });
  const [errors, setErrors] = useState({});
  const [IsButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [IsValid, setIsValid] = useState(false);
  const [ClientPotential, setClientPotential] = useState(false);
  let   [EmailAvailable, setEmailAvailable] = useState(null);
  const [meetingsource, setMeetingSource] = useState([])
  const [meetingaccount, setMeetingAccount] = useState([])
  const [meetingsetbyResponder, setMeetingSetByResponder] = useState([])
  const [meetingsetbyColdCallers, setMeetingSetByColdCallers] = useState([])
  const [potentialowner, setPotentialOwner] = useState([])
  const [potentialstatus, setPotentialStatus] = useState([])
  const [accountemail, setAccountEmail] = useState([])
  const [ClientData, setClientData] = useState([])

  useEffect(()=>{
    GetPotentialmeeting();
  },[])

  const GetPotentialmeeting = () => {
    try{
      let RequestBody = {
        ClientID: ClientID,
        UserID: UserID,
        Role: "ColdCallers",
      };
      const ApiResponse = Axios({
        url: CommonConstants.MOL_APIURL + "/potentialmeeting/PotentialAllStatusGet",
        method: "POST",
        data: RequestBody,
      });
      ApiResponse.then((result) => {
        
        let RArr= result.data?.meetingsetby[0]?.ResponderDetails || [];
        let CArr= result.data?.meetingsetby[0]?.ColdCallersDetails || [];
        
        RArr.forEach(RArr1 => {
          RArr1.value = "R"+ RArr1.value;
      });
      CArr.forEach(CArr1 => {
        CArr1.value = "C"+ CArr1.value;
    });
        const combinedArray = [...RArr, ...CArr];
        combinedArray.sort((a, b) => a.label.localeCompare(b.label));
        setMeetingSource(result.data?.meetingsource)
        setMeetingAccount(result.data?.meetingaccount);
        setMeetingSetByResponder(combinedArray);
        setMeetingSetByColdCallers(result.data.meetingsetby[0]?.ColdCallersDetails,);
        setPotentialOwner(result.data?.potentialowner);
        setPotentialStatus(result.data?.potentialstatus);
        setClientData(result.data?.ClientData,);

        
      })
      .catch((error) => {
        //toast.error("An error occurred while getting. Please try again.");
        setIsLoading(false)
      });
    }catch(error){
      toast.error("An error occurred while getting. Please try again.");
      setIsLoading(false)
    }
  }

  const GetCheckClientlimition = async() => {
    let ClientLimitParadata = {
      ClientID: ClientID,
      Role: "ColdCallers"
    };
    let ClientLimitParadataCall = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CheckContactsPerClientLimit",
      method: "POST",
      data: ClientLimitParadata,
    });
    
    if (ClientLimitParadataCall.data.StatusMessage === "SUCCESS") {
      if(ClientLimitParadataCall.data.Data.IsExhaustLimit === true){
      //  setState({IsContactLimintation:true})
        return true;
      }else{
      //  setState({IsContactLimintation:false})
        return false;
      }
    }else{
     // setState({IsContactLimintation:true})
      return true;
    }
  }

  const FromValidation = async () => {
    try {
    let formIsValid = true;
    let field = {...fields};
    setIsValid(false);
    let errors = {};
    let FirstName = field?.FirstName?.trim();
    let LastName = field?.LastName?.trim();
    let Title = field?.Title?.trim();
    let Company = field?.Company?.trim();
    let Email = field?.Email?.trim();
    let MeetingSourceID = field?.MeetingSourceID?.trim();
    let MeetingOwnerID = field?.MeetingOwnerID?.trim();
    let EngagementStatusID = field?.EngagementStatusID?.trim();
    let ReminderEmailAccount =field?.ReminderEmailAccount?.trim();
    let IsExist = await CheckExistPotentialMeetingEmail(Email);
    let ResponderName = field?.ResponderName?.trim();
    let CompanyPhones = field?.CompanyPhone?.trim();

    let DirectPhones = field?.DirectPhone?.trim();
    let DirectPhoneExts = field?.DirectPhoneExt?.trim();
    let MeetingSetbyID = fields?.MeetingSetbyID?.trim();

    if (isNaN(DirectPhones)) {
      formIsValid = false;
      errors["DirectPhone"] = "Invalid direct phone";
    }
    if (isNaN(DirectPhoneExts)) {
      formIsValid = false;
      errors["DirectPhoneExt"] = "Invalid direct phone ext";
    }

    if (FirstName == "") {
      formIsValid = false;
      errors["FirstName"] = "Please enter first name";
    }
    if (LastName == "") {
      formIsValid = false;
      errors["LastName"] = "Please enter last name";
    }
    if (Title == "") {
      formIsValid = false;
      errors["Title"] = "Please enter Title";
    }
    if (Company == "") {
      formIsValid = false;
      errors["Company"] = "Please enter Company";
    }
    if (Email == "") {
      formIsValid = false;
      errors["Email"] = "Please enter Email";
    }
    if (MeetingSourceID == "") {
      formIsValid = false;
      errors["MeetingSourceID"] = "Please select Meeting Source";
    }
    if (ReminderEmailAccount == "") {
      formIsValid = false;
      errors["ClientPOCID"] = "Please select reminder email account";
    }
    if (MeetingOwnerID == "") {
      formIsValid = false;
      errors["MeetingOwnerID"] = "Please select owner";
    }
    if (EngagementStatusID == "") {
      formIsValid = false;
      errors["EngagementStatusID"] = "Please select status";
    }
    if (MeetingSetbyID == "") {
      formIsValid = false;
      errors["ResponderName"] = "Please select Meeting set By";
    }

    
    if (IsExist == "InvalidEmail") {
      formIsValid = false;
      errors["Email"] = "invalid email.";
    }
    if (IsExist == "ExistEmail") {
      formIsValid = false;
      errors["Email"] = "Email Already Exist";
    }

    if (IsExist == "BlankData" && Email == "") {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }

    if (IsValid == true) {
      formIsValid = false;
      errors["Email"] = "Invalid from email.";
    }

    if (CompanyPhones?.trim() === "") {
      errors["CompanyPhone"] = null; // Blank is considered fine
    } else if (!/^\d+$/.test(CompanyPhones?.trim())) {
      errors["CompanyPhone"] = "Invalid company phone";
      formIsValid = false;
    } else {
      errors["CompanyPhone"] = null; // Valid phone number
    }

    if (ClientPotential == true) {
      let ClientPotentialID = field?.ClientPotentialID?.trim();;
      if (ClientPotentialID == "") {
        formIsValid = false;
        errors["ClientPotentialID"] = "Please select client";
      }
    }

    setErrors(errors);
    return formIsValid;
  } catch (error) {
    setIsLoading(false)
    toast.error("An unexpected error occurred. Please try again.");
  }
  }

  const handleChange = async (e) => {
    let field = e.target.name;
    let fields1 = {...fields};
    fields1[field] = e.target.value;
    setFields(fields1);
    if (fields1.Email != "") {
      errors.Email = null;
      EmailAvailable = null;
      await CheckExistPotentialMeetingEmail(e.target.value);
    } else {
      EmailAvailable = null;
      errors.Email = null;
    }
  }

  const HandleChangeBlur = async() => {
    try {
    let errors = { ...errors };
    let Email = fields?.Email?.trim();
    if (Email == "") {
      errors["Email"] = "Please enter Email";
    } else {
      let IsExist = await CheckExistPotentialMeetingEmail(Email);
      if (IsExist == "InvalidEmail") {
        errors["Email"] = "invalid email.";
      }
      if (IsExist == "ExistEmail") {
        errors["Email"] = "Email Already Exist";
      }
      if (IsExist == "AllDone") {
        errors["Email"] = null;
      }
    }

    setErrors(errors)
  } catch (error) {
    toast.error("An unexpected error occurred. Please try again.");
  }
  }

  const HandleChangedropdownBlur = async (Name) => {
    try {

    
    let error = { ...errors };
    let MeetingSourceID = fields?.MeetingSourceID?.trim();
    let MeetingOwnerID = fields?.MeetingOwnerID?.trim();
    let EngagementStatusID =fields?.EngagementStatusID?.trim();
    let ReminderEmailAccount = fields?.ReminderEmailAccount?.trim();
    let MeetingSetbyID = fields?.MeetingSetbyID?.trim();

    if (ClientPotential == true) {
      if (Name == "ClientName") {
        let ClientPotentialID =
          document.getElementById("ClientPotentialID").value;
        if (ClientPotentialID == "") {
          error["ClientPotentialID"] = "Please select client";
        } else {
          error["ClientPotentialID"] = null;
        }
      }
    }
    if (Name == "MeetingSource") {
      if (MeetingSourceID == "") {
        error["MeetingSourceID"] = "Please select Meeting Source";
      } else {
        error["MeetingSourceID"] = null;
      }
    }
    if (Name == "ReminderEmailAccount") {
      if (ReminderEmailAccount == "") {
        error["ClientPOCID"] = "Please select reminder email account";
      } else {
        error["ClientPOCID"] = null;
      }
    }
    if (Name == "MeetingOwner") {
      if (MeetingOwnerID == "") {
        error["MeetingOwnerID"] = "Please select owner";
      } else {
        error["MeetingOwnerID"] = null;
      }
    }
    if (Name == "EngagementStatus") {
      if (EngagementStatusID == "") {
        error["EngagementStatusID"] = "Please select status";
      } else {
        error["EngagementStatusID"] = null;
      }
    }
    
    if (Name == "ResponderName") {
      if (MeetingSetbyID == "") {
        error["ResponderName"] = "Please select Meeting set by";
      } else {
        error["ResponderName"] = null;
      }
    }
    setErrors(error)
          
  } catch (error) {
    setIsLoading(false)
    toast.error("An unexpected error occurred. Please try again.");
  }
  }

  const HandleChangedropdownBlurMeetingSet = async (Name,valueSet) => {
    try {

    let error = { ...errors };
    let MeetingSetbyID = fields?.MeetingSetbyID?.trim();

    if (Name == "ResponderName") {
      if (valueSet == "" ) {
        error["ResponderName"] = "Please select Meeting Set by";
      } else {
        error["ResponderName"] = null;
      }
    }
    setErrors(error)
          
  } catch (error) {
    setIsLoading(false)
    toast.error("An unexpected error occurred. Please try again.");
  }
  }

  const HandleChangeotherFieldBlur = async (Name) => {
    try {
    
    let error = { ...errors };
    let FirstName = fields?.FirstName?.trim();
    let LastName = fields?.LastName?.trim();
    let Title =fields?.Title?.trim();
    let Company = fields?.Company?.trim();
    let CompanyPhones = fields?.CompanyPhone?.trim();
    let DirectPhones = fields?.DirectPhone?.trim();
    let DirectPhoneExts = fields?.DirectPhoneExt?.trim();

    if (Name == "DirectPhone") {
      if (isNaN(DirectPhones)) {
        error["DirectPhone"] = "Invalid direct phone";
      } else {
        error["DirectPhone"] = null;
      }
    }

    if (Name == "DirectPhoneExt") {
      if (isNaN(DirectPhoneExts)) {
        error["DirectPhoneExt"] = "Invalid direct phone ext";
      } else {
        error["DirectPhoneExt"] = null;
      }
    }

    if (Name == "FirstName") {
      if (FirstName == "") {
        error["FirstName"] = "Please enter first name";
      } else {
        error["FirstName"] = null;
      }
    }
    if (Name == "LastName") {
      if (LastName == "") {
        error["LastName"] = "Please enter last name";
      } else {
        error["LastName"] = null;
      }
    }
    if (Name == "Title") {
      if (Title == "") {
        error["Title"] = "Please enter Title";
      } else {
        error["Title"] = null;
      }
    }
    if (Name == "Company") {
      if (Company == "") {
        error["Company"] = "Please enter Company";
      } else {
        error["Company"] = null;
      }
    }

    if (Name === "CompanyPhone") {
      if (CompanyPhones?.trim() === "") {
        error["CompanyPhone"] = null; // Blank is considered fine
      } else if (!/^\d+$/.test(CompanyPhones?.trim())) {
        error["CompanyPhone"] = "Invalid company phone";
      } else {
        error["CompanyPhone"] = null; // Valid phone number
      }
    }

    setErrors(error)
  } catch (error) {
    setIsLoading(false)
    toast.error("An unexpected error occurred. Please try again.");
  }
  }

    // check exit Email
    const CheckExistPotentialMeetingEmail = async (Email) => {
      try {
      let regexp =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (Email != "") {
        if (!regexp.test(Email)) {
          return "InvalidEmail";
        } else {
          // setState({ IsExist: false });
          let RequestBody = {
            ClientID: ClientID,
            Email: Email,
            Role: "ColdCallers",
          };
          let ApiResponse = await Axios({
            url: CommonConstants.MOL_APIURL +"/potentialmeeting/PotentialMeetingCheckExists",
            method: "POST",
            data: RequestBody,
          });
          if (ApiResponse.data.StatusMessage == "SUCCESS") {
            if (ApiResponse.data.Data.length > 0) {
              setEmailAvailable(ApiResponse.data.Data[0].Email );
              return "ExistEmail";
            } else {
              return "AllDone";
            }
          }
        }
      } else {
        return "BlankData";
      }
    }catch (error) {
        // Handle any unexpected errors here
        toast.error("An error occurred:", error);
      }
    }
  

  const savebtn = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    const isContactLimitations = await GetCheckClientlimition();
    const finalFlag = await FromValidation();
    
    if (finalFlag) {
      setIsLoading(true);
      const emailProspect = fields.Email.trim();

      const clientLimitParadataEmailContact = {
        EmailContact: emailProspect,
        ClientID: ClientID,
        Role: "ColdCallers",
      };

      try {
        const clientLimitResponse = await Axios.post(`${CommonConstants.MOL_APIURL}/bookedmeeting/GetContactLimitationStatus`, clientLimitParadataEmailContact);

        if (clientLimitResponse.data.Data.length > 0 || !isContactLimitations) {
          let data = {
            FirstName:fields?.FirstName,
            LastName:fields?.LastName,
            Email:fields?.Email,
            Title:fields?.Title,
            Company:fields?.Company,
            LinkedInUrl:fields?.LinkedInURL,
            DirectPhone:fields?.DirectPhone,
            DirectPhoneExt:fields?.DirectPhoneExt,
            CompanyPhone:fields?.CompanyPhone,
            EngagamentStatusID:fields?.EngagementStatusID,
            MeetingSourceID:fields?.MeetingSourceID,
            MeetingOwnerID:fields?.MeetingOwnerID,
            ResponderName:fields?.ResponderName,
            ResponderColdCallersType: fields?.MeetingSetbyID.charAt(0),
            ResponderColdCallersID: fields?.MeetingSetbyID,
            ReminderEmailAccountID: fields?.ReminderEmailAccount,
            Domain: fields?.Email.substring(fields?.Email.lastIndexOf("@") + 1),
            SalesReply_MessageID: null,
            ConversionCampaignID: null,
            ProspectLastReply: null,
            IsDeleted: false,
            ClientID: ClientID,
            UserID: UserID,
            CreatedBy: ColdCallerUserID,
            Role: "ColdCallers",
            CreatedDate: new Date(),
            LastUpdatedBy: null,
            LastUpdatedDate: null,
            ProspectPotentialMeetingID:ID || "000000000000000000000000",
          };
          setIsLoading(false)
          const response = await Axios.post(`${CommonConstants.MOL_APIURL}/potentialmeeting/PotentialMeetingAdd`, data);

          if (response.data.StatusMessage === "SUCCESS") {
            handleSuccess(response.data.data._id);
            PerformNextActionAfterMeeting();
            ClosePotentailMeetingModel();
            DisconnectCall();
          } else {
            setIsButtonDisabled(false);
            toast.error(response.data.Message);
          }
        } else {
          toast.error(<div className="toastsize">You have exceeded contact limits.</div>);
          setIsButtonDisabled(false);
        }
      } catch (error) {
        toast.error('An error occurred while saving the meeting.');
        setIsButtonDisabled(false);
      } finally {
        setIsButtonDisabled(false);
        setIsLoading(false);
      }
    } else {
      setIsButtonDisabled(false);
      setIsLoading(false);
    }
  };

  const handleSuccess = (id) => {
    const pageName = "";
    toast.success(
      <div className="toastsize">
        Potential meeting Add
        <br />
        Potential meeting added successfully.
      </div>
    );
    // Add appropriate routing logic here
  };

  return (
    <div>
      {/* Modal Popup Trigger */}
      {/* <Popup trigger={<button>Potential Meeting Add Model</button>} modal nested>
        {(close) => ( */}
           <div className="adminmain">
           <div className="bodyhome px-3 bg-white">
             <div className="row pt-2">
               <div className="col">
                 <h3 className=" float-left py-3 lg-headertitle addpotential_header">
                   Add Potential Meeting
                 </h3>
               </div>
             </div>
 
             <div className="">
               <div className="row">
                 {ClientPotential == true ? (
                   <>
                     <div className="col-lg-6 boxsinput_group px-4">
                       <div className="row max-cols d-flex align-items-center pl-2">
                         <label className="col-lg-4 view-lable-meet potential_text">
                           Client
                         </label>
                         <div className="col-lg-7">
                           <select
                             className="form-control  m-input"
                             data-val="true"
                             data-val-number="The field ContactSourceID must be a number."
                             id="ClientPotentialID"
                             name="MeetingSourceID"
                             onChange={() =>
                               HandleChangedropdownBlur("ClientName")
                             }
                           >
                             <option value="">--Select--</option>
                             {ClientData.map((value) => (
                               <option key={value._id} value={value._id}>{value.Name}</option>
                             ))}
                           </select>
                           <span style={{ color: "red" }}>
                             {errors["ClientPotentialID"]}
                           </span>
                         </div>
                       </div>
                     </div>
                     <div className="col-lg-6 boxsinput_group"></div>
                   </>
                 ) : (
                   <></>
                 )}
 
                 <div className="col-lg-6 boxsinput_group px-4">
                   <div className="row max-cols  d-flex align-items-center pl-2">
                     <label className="col-lg-4 view-lable-meet potential_text ">
                       First Name
                     </label>
                     <div className="col-lg-7">
                       <input
                         className="form-control m-input"
                         data-val="true"
                         data-val-required="Please enter first name"
                         id="FirstName"
                         name="FirstName"
                         type="text"
                         onBlur={() =>
                           HandleChangeotherFieldBlur("FirstName")
                         }
                         value={fields?.FirstName}
                         onChange={handleChange}
                       />
                       <span style={{ color: "red" }}>
                         {errors["FirstName"]}
                       </span>
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 boxsinput_group">
                   <div className="row max-cols d-flex align-items-center">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Last Name
                     </label>
                     <div className="col-lg-7">
                       <input
                         className="form-control m-input"
                         id="LastName"
                         name="LastName"
                         type="text"
                         onBlur={() =>
                           HandleChangeotherFieldBlur("LastName")
                         }
                         value={fields?.LastName}
                         onChange={handleChange}

                       />
                       <span style={{ color: "red" }}>
                         {errors["LastName"]}
                       </span>
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 boxsinput_group px-4">
                   <div className="row max-cols d-flex align-items-center pl-2">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Title
                     </label>
                     <div className="col-lg-7">
                       <input
                         className="form-control m-input"
                         id="Title"
                         name="Title"
                         type="text"
                         onBlur={() => HandleChangeotherFieldBlur("Title")}
                         onChange={handleChange}
                         value={fields?.Title}
                       />
                       <span style={{ color: "red" }}>
                         {errors["Title"]}
                       </span>
                     </div>
                   </div>
                 </div>
 
                 <div className="col-lg-6 boxsinput_group">
                   <div className="row max-cols d-flex align-items-center">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Company
                     </label>
                     <div className="col-lg-7">
                       <input
                         className="form-control m-input"
                         id="Company"
                         name="Company"
                         type="text"
                         onBlur={() =>
                           HandleChangeotherFieldBlur("Company")
                         }
                         onChange={handleChange}
                         value={fields?.Company}
                       />
                       <span style={{ color: "red" }}>
                         {errors["Company"]}
                       </span>
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 boxsinput_group px-4">
                   <div className="row max-cols d-flex align-items-center pl-2">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Email
                     </label>
                     <div className="col-lg-7">
                       <input
                         className="form-control m-input"
                         data-val="true"
                         data-val-regex="Invalid email."
                         data-val-remote="Email already exist."
                         data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                         data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                         data-val-required="Please enter email."
                         id="Email"
                         name="Email"
                         type="text"
                         onBlur={HandleChangeBlur}
                         onChange={handleChange}
                         value={fields?.Email}
                       />
 
                       <span style={{ color: "red" }}>
                         {errors["Email"]}
                       </span>
                       {/* {EmailAvailable && (
                         <span style={{ color: "red" }}>
                           Email already exist.
                         </span>
                       )} */}
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 boxsinput_group">
                   <div className="row max-cols d-flex align-items-center">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       LinkedIn URL
                     </label>
                     <div className="col-lg-7">
                       <input
                         className="form-control m-input"
                         id="LinkedInURL"
                         name="LinkedInURL"
                         type="text"
                         onChange={handleChange}
                         value={fields?.LinkedInURL}
                       />
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 boxsinput_group px-4">
                   <div className="row max-cols d-flex align-items-center pl-2">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Meeting Source
                     </label>
                     <div className="col-lg-7">
                       <select
                         className="form-control  m-input"
                         data-val="true"
                         data-val-number="The field ContactSourceID must be a number."
                         id="MeetingSourceID"
                         name="MeetingSourceID"
                        //  onChange={() =>
                        //    HandleChangedropdownBlur("MeetingSource")
                        //  }
                         onChange={handleChange}

                       >
                         <option value="">--Select--</option>
                         {meetingsource.map((value) => (
                           <option key={value._id} value={value._id}>{value.Name}</option>
                         ))}
                       </select>
                       <span style={{ color: "red" }}>
                         {errors["MeetingSourceID"]}
                       </span>
                     </div>
                   </div>
                 </div>
 
                 <div className="col-lg-6 boxsinput_group">
                   <div className="row max-cols d-flex align-items-center">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Owner
                     </label>
                     <div className="col-lg-7">
                       <select
                         className="form-control  m-input"
                         data-val="true"
                         data-val-number="The field MeetingOwnerID must be a number."
                         id="MeetingOwnerID"
                         name="MeetingOwnerID"
                        //  onChange={() =>
                        //    HandleChangedropdownBlur("MeetingOwner")
                        //  }
                        onChange={handleChange}

                       >
                         <option value="">--Select--</option>
                         {potentialowner.map((value) => (
                           <option key={value._id} value={value._id}>{value.Name}</option>
                         ))}
                       </select>
                       <span style={{ color: "red" }}>
                         {errors["MeetingOwnerID"]}
                       </span>
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 boxsinput_group px-4">
                   <div className="row max-cols d-flex align-items-center pl-2">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Reminder Email Account
                     </label>
                     <div className="col-lg-7">
                       <select
                         className="form-control  m-input"
                         data-val="true"
                         data-val-number="The field ClientPOCID must be a number."
                         id="ReminderEmailAccount"
                         name="ReminderEmailAccount"
                        //  onChange={() =>
                        //    HandleChangedropdownBlur("ReminderEmailAccount")
                        //  }
                        onChange={handleChange}

                       >
                         <option value="">--Select--</option>
                         {meetingaccount.map((value) => (
                           <option key={value._id} value={value._id}>
                             {value.SMTPFromEmail}
                           </option>
                         ))}
                       </select>
                       <span style={{ color: "red" }}>
                         {errors["ClientPOCID"]}
                       </span>
                     </div>
                   </div>
                 </div>
 
                 <div className="col-lg-6 boxsinput_group">
                   <div className="row max-cols d-flex align-items-center">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Engagement Status
                     </label>
                     <div className="col-lg-7">
                       <select
                         className="form-control  m-input"
                         data-val="true"
                         data-val-number="The field EngagementStatusID must be a number."
                         id="EngagementStatusID"
                         name="EngagementStatusID"
                        //  onChange={() =>
                        //    HandleChangedropdownBlur("EngagementStatus")
                        //  }
                        onChange={handleChange}

                       >
                         <option value="">--Select--</option>
                         {potentialstatus.map((value) => (
                           <option key={value._id} value={value._id}>{value.Status}</option>
                         ))}
                       </select>
                       <span style={{ color: "red" }}>
                         {errors["EngagementStatusID"]}
                       </span>
                     </div>
                   </div>
                 </div>
 
                 <div className="col-lg-6 boxsinput_group px-4">
                   <div className="row max-cols d-flex align-items-center pl-2">
                     <label className="col-lg-4 view-lable-meet potential_text ">
                       Direct Phone
                     </label>
                     <div className="col-lg-7">
                       <input
                         className="form-control m-input"
                         data-val="true"
                         data-val-regex="Invalid direct phone"
                         data-val-regex-pattern="^[0-9]*$"
                         id="DirectPhone"
                         name="DirectPhone"
                         type="text"
                         onBlur={() =>
                           HandleChangeotherFieldBlur("DirectPhone")
                         }
                        onChange={handleChange}
                        value={fields?.DirectPhone}
                       />
                       <span style={{ color: "red" }}>
                         {errors["DirectPhone"]}
                       </span>
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 boxsinput_group">
                   <div className="row max-cols d-flex align-items-center">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Direct Phone Ext
                     </label>
                     <div className="col-lg-7">
                       <input
                         className="form-control m-input"
                         data-val="true"
                         data-val-regex="Invalid direct phone ext"
                         data-val-regex-pattern="^[0-9]*$"
                         id="DirectPhoneExt"
                         name="DirectPhoneExt"
                         type="text"
                         onBlur={() =>
                           HandleChangeotherFieldBlur("DirectPhoneExt")
                         }
                        onChange={handleChange}
                        value={fields?.DirectPhoneExt}
                       />
                       <span style={{ color: "red" }}>
                         {errors["DirectPhoneExt"]}
                       </span>
                     </div>
                   </div>
                 </div>
 
                 <div className="col-lg-6 boxsinput_group  px-4">
                   <div className="row max-cols d-flex align-items-center pl-2">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Company Phone
                     </label>
                     <div className="col-lg-7">
                       <input
                         className="form-control m-input"
                         data-val="true"
                         data-val-regex="Invalid company phone"
                         data-val-regex-pattern="^[0-9]*$"
                         id="CompanyPhone"
                         name="CompanyPhone"
                         type="text"
                         onBlur={() =>
                           HandleChangeotherFieldBlur("CompanyPhone")
                         }
                        onChange={handleChange}
                        value={fields?.CompanyPhone}
                       />
                       <span style={{ color: "red" }}>
                         {errors["CompanyPhone"]}
                       </span>
                     </div>
                   </div>
                 </div>
 
                 <div className="col-lg-6 boxsinput_group">
                   <div className="row max-cols d-flex align-items-center">
                     <label className="col-lg-4 view-lable-meet potential_text">
                       Meeting Set By
                     </label>
                     <div className="col-lg-7">
                       <select
                         className="form-control  m-input"
                         data-val="true"
                         data-val-number="The field SalesRepID must be a number."
                         id="MeetingSetbyID"
                         name="MeetingSetbyID"
                         onChange={(e) => {
                           const selectedOption = e.target.options[e.target.selectedIndex];
                           setIsLoading(false)
                           if (selectedOption) {
                             const label = selectedOption.label;
                             // Update fields state correctly
                            setFields((prevFields) => ({
                              ...prevFields, // Spread previous fields state
                              ResponderName: label, // Update ResponderName
                              MeetingSetbyID:selectedOption.value
                            }));
                            
                           // HandleChangedropdownBlurMeetingSet("ResponderName",selectedOption.value);
                           }
                         }}
                       >
                         <option value="">--Select--</option>
                         {meetingsetbyResponder && meetingsetbyResponder.length > 0
                           ? meetingsetbyResponder.map((value) => (
                               <option key={value.value} value={value.value}>{value.label}</option>
                             ))
                           : ""}
                         {/* {meetingsetbyColdCallers &&
                         meetingsetbyColdCallers.length > 0
                           ? meetingsetbyColdCallers.map((value) => (
                               <option value={"C" + value.value}>
                                 {value.label}
                               </option>
                             ))
                           : ""} */}
                       </select>
                       <span style={{ color: "red" }}>
                         {errors["ResponderName"]}
                       </span>
                     </div>
                   </div>
                 </div>
               </div>
 
               <div className="borderbtnm mt-4 mb-4"></div>
 
               <div className="row pb-4 px-4">
                 <div className="col-lg-12 pull-left px-2">
                   <button
                     id="submit"
                     className="btn btn-primary btn-lightgreen mr-1"
                     value="Save"
                     onClick={savebtn}
                     disabled={IsButtonDisabled}
                   >
                     <i className="la la-save"></i> Save
                   </button>
                   <button
                     id="backtolist"
                     className="btn btn-secondary"
                     value="Save"
                     onClick={ClosePotentailMeetingModel}
                   >
                     <i className="la la-arrow-circle-left"></i> Cancel
                   </button>
                 </div>
               </div>
             </div>
           </div>
         </div>
        {/* )}
      </Popup> */}
    </div>
  );
};

export default CallDialAddPotentialMeetingPage;
