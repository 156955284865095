import React from "react";
import Axios from "axios";

import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import Popup from "reactjs-popup";

import { CSVReader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import $ from "jquery";

import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { CheckBox } from "@material-ui/icons";
import loadingicon from "../../images/loading.gif";

const Fileupload = require("../../_helpers/fileupload");

class CEmailValidationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Files: [],
      Reset: false,
      CsvData: [],
      Dropboxdata: [],
      Filename: "",
      ClientName: "",
      UserEmail: "",
      ShowPopup: false,
      Fields: {},
      Errors: {},
      IsButtonDisabled: false,
    };
    this.HandleOnDrop = this.HandleOnDrop.bind(this);
    this.HandleOnError = this.HandleOnError.bind(this);
    this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
    this.ImportCsv = this.ImportCsv.bind(this);
    this.MapCsv = this.MapCsv.bind(this);
    this.Savecsv = this.Savecsv.bind(this);
    this.ShowPopupHandle = this.ShowPopupHandle.bind(this);
    this.hideCSVHeaderOptions = this.hideCSVHeaderOptions.bind(this);
    this.HidePopupHandle = this.HidePopupHandle.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title = `Email Validation | SalesHive`;
    document.getElementById("map_attribute").style.display = "none";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.UserEmail = Details.Username;
    }
  }

  // reset csv btn
  Resetcsv() {
    this.setState({ Reset: false });
  }
  // handle dropdown
  HandleOnDrop(Data, FileInfo) {
    var Filename = FileInfo.name;
    this.setState({ Files: FileInfo });
    var sep = Filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      this.setState({ Reset: true });
      this.Resetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];

      this.setState({ Filename: csvfile });

      this.setState({ CsvData: Data });

      this.setState({ Dropboxdata: Data[0].data });
      document.getElementById("Email").value =
        this.state.Dropboxdata.indexOf("Email");
    }
  }

  HandleOnError(err, file, inputElem, reason) {}

  HandleOnRemoveFile(data) {
    this.setState({ CsvData: []});
  }
  // import csv
  ImportCsv() {
    document.getElementById("import_csv").style.display = "block";
  }
  // map csv
  MapCsv() {
    if (this.state.CsvData.length != 0) {
      document.getElementById("map_attribute").style.display = "block";
      this.state.Dropboxdata.map((dddd, index) => {});
    } else {
      toast.error(
        <div className="toastsize">
          Email Validation
          <br />
          Please select file
        </div>
      );
    }
    this.hideCSVHeaderOptions();
  }
  hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  ShowPopupHandle() {
    this.setState({ ShowPopup: true });
  }
  HidePopupHandle() {
    this.setState({ ShowPopup: false });
  }

  //validation

  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Email = document.getElementById("Sent_Email").value.trim();
    let IsExist = await this.EmailValidationCheck(Email);
    if (Email == "") {
      FormIsValid = false;
      Errors["Sent_Email"] = "Please enter email.";
    }

    if (IsExist == true) {
      FormIsValid = false;
      Errors["Sent_Email"] = "invalid email.";
    }

    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // find exits Email
  async EmailValidationCheck(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["Sent_Email"] = "invalid email.";
      this.setState({ Errors: Errors });
      return true;
    } else {
      return false;
    }
  }

  // handle change
  HandleEmailCheck = (Field, e) => {
    var Email = document.getElementById("Sent_Email").value.trim();
    // this.setState({ Errors: "" });
    let Errors = {};
    if (Email != "") {
      var Email = document.getElementById("Sent_Email").value.trim();
      this.EmailValidationCheck(Email);
    } else {
      Errors["Sent_Email"] = "Please enter email.";
      this.setState({ Errors: Errors });
    }
  };

  // save csv
  async Savecsv(e) {
    this.setState({ IsButtonDisabled: true });
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var ExportAll = document.getElementById("export_all").checked;
      var Email = document.getElementById("Email").value;
      var InputEmail = document.getElementById("Sent_Email").value;
      var CsvData = this.state.CsvData;
      Fileupload.getBase64(this.state.Files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/EmailValidations",
          File: _base64,
          ImageName: this.state.Filename,
          filedata: CsvData,
          Email: InputEmail,
          UserID: this.state.UserID,
          ClientID: this.state.ClientID,
          IsExportAll: ExportAll,
          MappedIndex: parseInt(Email),
          CreatedDate: new Date(),
        };
        console.log("myresult123", myresult);
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/email_validation/EmailValidationImport",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                Email Validation
                <br />
                You will get file soon on Submitted Email.
              </div>
            );
            this.HidePopupHandle();
          } else {
            toast.error(res.data.Message);
            this.setState({ IsButtonDisabled: false });
          }
        });
      });
    } else {
      this.setState({ IsButtonDisabled: false });
    }
  }

  render() {
    return (
      <>
        <div
          id="hideloding"
          style={{ display: "none" }}
          className="loding-display"
        >
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle  float-left pl-0">Email Validation</h4>
              </div>
            </div>
            <div className="bg-white portletbody mt-0" id="import_csv">
              <div className="row"> 
                <div class="col-xl-8 offset-xl-2">
                  <h3 class="uploadheadingtitle">
                    Import Email Validation Details
                  </h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={this.HandleOnDrop}
                      onError={this.HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={this.HandleOnRemoveFile}
                      isReset={this.state.Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>
                        Add Email Validation data from csv file to bulk upload.
                      </p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2">
                  <div class="alignright">
                    <a onClick={this.MapCsv} class="btn btn-primary btnColor">
                      <i class="la la-upload"></i> Upload
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="portletbody portletshadow" id="map_attribute">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div class="form-group m-form__group d-flex">
                  <div class="col-lg-2 nopadding">
                    <div>
                      <select
                        class="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="Email">
                          Email
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-1 text-center">
                    <span class="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div class="col-lg-2 nopadding">
                    <div>
                      <select
                        id="Email"
                        class="csvcontent form-control  m-input"
                        autocomplete="off"
                      >
                        <option value="not mapped">Not Mapped</option>
                        {this.state.Dropboxdata.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2"></div>
                  <div class="col-lg-2 nopadding">
                    <div>
                      <input type="checkbox" id="export_all" /> Export ALL
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <a
                    onClick={this.ShowPopupHandle}
                    class="btn btn-primary btn-lightgreen"
                  >
                    <i class="la la-save"></i>Validate
                  </a>
                </div>
                <Popup open={this.state.ShowPopup}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">Sent Email</h5>
                            <button
                              className="close"
                              onClick={this.HidePopupHandle}
                            >
                              <span
                                aria-hidden="true"
                                class="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="modal-body">
                            <div className="col-sm-6 mb-5 px-0">
                              <div class="row mb-3">
                                <label class="col-lg-5 col-form-label text-left">
                                  Email
                                </label>
                                <div class="col-lg-7">
                                  <input
                                    class="form-control m-input"
                                    id="Sent_Email"
                                    data-val="true"
                                    data-val-remote="Title already exist."
                                    data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                    data-val-required="Please enter Email "
                                    name="Username"
                                    onBlur={this.HandleEmailCheck.bind(
                                      this,
                                      "Sent_Email"
                                    )}
                                    defaultValue={this.state.UserEmail}
                                    type="text"
                                  />
                                  <span style={{ color: "red" }}>
                                    {this.state.Errors["Sent_Email"]}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-top modal-content text-right">
                            <div class="row">
                              <div class="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  class="btn btn-secondary mr-2"
                                  onClick={this.HidePopupHandle}
                                >
                                  cancel
                                </a>
                                <a
                                  id="submit"
                                  disabled={this.state.IsButtonDisabled}
                                  onClick={this.Savecsv}
                                  class="btn btn-primary btn-lightgreen mr-1"
                                >
                                  Save
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEmailValidationPage = connect(
  mapState,
  actionCreators
)(CEmailValidationPage);
export { connectedCEmailValidationPage as CEmailValidationPage };
