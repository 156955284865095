import React, { useState, useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import StarIcon from "@material-ui/icons/Star";
import { CommonConstants } from "../../../_constants/common.constants.js";
import { GetUserDetails } from "../../../_helpers/Utility.js";
import { history } from "../../../_helpers/index.js";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import StyleHeader from "../StickyHeader/StickyHeader.js";
import { toast } from "react-toastify";

export default function CollapsibleTable() {
  const urlParams = window.location.pathname;
  const NavigationParams =
    urlParams === "/defaultphonesettings" ? "Default" : "";
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  // const [dialingsetsIdForEdit, setDialingsetsIdForEdit] = useState(null);
  const [updatechild, setupdatechild] = useState(false); // State to hold data from the child
  const [PageTypeParams, SetPageTypeParams] = useState(false);

  const [AccountSIDs, SetAccountSID] = React.useState("");
  const [AuthTokens, SetAuthToken] = React.useState("");
  const [PhoneSettingsID, SetPhoneSettingsID] = React.useState(null);

  useEffect(() => {
    document.title = "General | SalesHive";
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    GeneralTabGet(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage, updatechild]);

  //dispositions status get list
  const GeneralTabGet = (CID, UID, URole) => {
    let DispositionsStatusData = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      PageType: NavigationParams,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/General/PhoneSettingGeneral",
      method: "POST",
      data: DispositionsStatusData,
    });
    ApiResponse.then((result) => {
      SetAccountSID(result.data?.PageData[0]?.AccountSID ?? "");
      SetAuthToken(result.data?.PageData[0]?.AuthToken ?? "");
      SetPhoneSettingsID(result.data.PageData[0]?._id ?? null);
      SetIsLoading(false);
      setIsDataLoaded(true);
    });
  };
  const AddBtn = () => {
    if (PhoneSettingsID) {
      toast.error(<div>Already Verifed</div>);
    } else {
      const accountSID = document.getElementById("AccountSID").value;
      const authTokenDetail = document.getElementById("AuthToken").value;
      if (accountSID === "") {
        toast.error(<div>Requried Account SID</div>);
      } else if (authTokenDetail === "") {
        toast.error(<div>Requried Auth Token</div>);
      } else {
        const phoneSettingAdd = {
          ClientID: ClientID,
          AccountSID: accountSID,
          IsDeleted: false,
          CreatedDate: new Date(),
          CreatedBy: UserID,
          AuthToken: authTokenDetail,
          UserID: UserID,
          Role: Role,
        };
        Axios({
          url: `${CommonConstants.MOL_APIURL}/General/PhoneSettingAdd`,
          method: "POST",
          data: phoneSettingAdd,
        })
          .then((res) => {
            if (res.data.StatusMessage === "SUCCESS") {
              toast.success(<div>General added successfully.</div>);
              GeneralTabGet(ClientID, UserID, Role);
            } else {
              toast.error(res.data.Message);
            }
          })
          .catch((error) => {
            console.error("Error adding phone setting:", error);
          });
      }
    }
  };

  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <>
        {" "}
        <div className="row mx-0 align-items-center border-bottom">
          <div className="col px-0">
            <h4 className="headertitle xs-headertitle float-left padx-0">
              Account Info
            </h4>
          </div>
          <div className="col"></div>
        </div>
        <div class="">
          <div className="row">
            <div className="col padd-0"></div>
          </div>
          <div class="px-3">
            <div className="table-bordered">
              <div className="row py-2 mx-0">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Account SID</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="AccountSID"
                        defaultValue={AccountSIDs}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Auth Token</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="AuthToken"
                        name="AuthToken"
                        type="text"
                        defaultValue={AuthTokens}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="listing-li float-right pt-2">
                <ul class="my-0">
                  <li>
                    <a
                      onClick={() => {
                        AddBtn();
                      }}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <span>Verify</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
