import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";


import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});



export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [sortedColumn, setSortedColumn] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [PUserID, SetPUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ChildUserID);
      SetPUserID(Details.ParentUserID);
      SetRole(Details.Role);
    }

    GlobalBlackList(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage]);

 

  // Globle black list get list
  const GlobalBlackList = (CID,UID,URole) => {
    SetIsLoading(true)
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/globaldomainblacklist/GlobalDomainBlacklistList",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);
    }).catch((error) => {
      SetIsLoading(false);
    });
  };

	    //search for record
      const RequestSearch = (Event) => {
        if (Event.key === "Enter") {
          let SearchedVal = document.getElementById("search").value;
          if (SearchedVal == Search) {
            SetSearch(SearchedVal);
            SetPage(1);
          } else {
            SetSearch(SearchedVal);
            SetPage(1);
          }
        }
      };
      
        const HandleChangePage = (Event, NewPage) => {
          if (NewPage == Page) {
            SetPage(NewPage);
          } else {
            SetPage(NewPage);
            SetPage(1);
          }
      };
      
      const ChangeRowSelected = (Event) => {
        SetRowsPerPage(Number(Event.target.value));
        SetPage(1);
      };

  // Global Domain blacklist delete
  const DeleteBtn = (id,Domain) => {  
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete Global Domain Blacklist.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons:true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          GlobalDomainBlacklistID: id,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: UserID,
          DomainName: Domain,
          UserID:UserID
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/globaldomainblacklist/GlobalDomainBlacklistDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Global Domain Blacklist deleted successfully.",
                "success"
              );
              SetSearch("")
              SetPage(1)
              document.getElementById("search").value = ""
              GlobalBlackList(ClientID,PUserID,Role);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Edit btn
  const EditBtn = (id) => {
    history.push("/editglobalblacklist", id);
  };

//get sort field data
const SortData = (Field) => {
  setSortedColumn(Field);
  SetIsLoading(true);
  const SearchedVal = document.getElementById("search").value?.trim();
  const isNewSortField = Field !== SortField;
  let SortBy = SortedBy === 1 ? -1 : 1;

  if (isNewSortField) {
    SetSortedBy(1);
    SortBy = 1;
  } else {
    SetSortedBy(SortBy);
  }

  SetSortField(Field);
  SetSflag(SearchedVal !== "");

    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Search: SearchedVal,
      Type: "User",
      ClientID: ClientID,
      UserID: PUserID,
      Role: Role,
    };
    
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/globaldomainblacklist/GlobalDomainBlacklistList",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    SetIsLoading(false)
    }).catch((error) => {
      SetIsLoading(false);
    });
};
  return (
    <>
    {
      IsLoading ? <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> : <></>
    }
    <div className='py-0'>
       <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row py-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
    <TableContainer component={Paper}>
    <StyleHeader isDataLoaded={isDataLoaded} />
    <Table className="table-ref"  aria-label="collapsible table">
        <TableHead>
          <TableRow>
          <TableCell
                      onClick={() => {
                        SortData("Domain");
                        setSortedColumn("Domain");
                      }}
                    >
                      Domain
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "Domain"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "Domain"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </TableCell> 
                    <TableCell
                      onClick={() => {
                        SortData("CreatedDt");
                        setSortedColumn("CreatedDt");
                      }}
                    >
                      Created Date
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "CreatedDt"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "CreatedDt"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </TableCell>
            <TableCell>Action</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows.length == 0 ? <><p>No data available in table</p></> :
            Rows.map((row) => (
            <TableRow key={row._id} row={row}>
              <TableCell>{row.Domain}</TableCell>
              <TableCell>{moment(new Date(row.CreatedDt).toDateString()).format(
                      "MM/DD/YYYY"
                    )}</TableCell>
                    <TableCell>
          <a onClick={()=>{EditBtn(row._id)}} className="btn-eyesicon" title="Edit"><i className="la flaticon-edit-1 edit-icon"></i></a>
          <a onClick={()=>{DeleteBtn(row._id, row.Domain)}} className="btn-eyesicon" title="Delete"><i className="la flaticon-delete-1 delete-icon"></i></a>
        </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {/* {Sflag ? (
        <div className="row mt-2">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : ( */}
        <div className="row mt-2">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      {/* )} */}
    </div>
    </>

  );
}
