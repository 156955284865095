import Axios from "axios";
import { CommonConstants } from "../../src/_constants/common.constants";
import { GetUserDetails } from "../_helpers/Utility";

export const findIndexWithSpecificConditions = (data) => {
    for (let i = 0; i < data.length; i++) {
        const item = data[i];

        // Collect phone numbers and their blacklist statuses
        const phoneConditions = [
            { phone: item.DirectPhone, isBlacklisted: item.IsDirectPhoneBlacklist },
            { phone: item.MobilePhone, isBlacklisted: item.IsMobilePhoneBlacklist },
            { phone: item.CompanyPhone, isBlacklisted: item.IsCompanyPhoneBlacklist },
            { phone: item.OtherPhone1, isBlacklisted: item.IsOtherPhone1Blacklist },
            { phone: item.OtherPhone2, isBlacklisted: item.IsOtherPhone2Blacklist }
        ];

        // Check if all phone numbers are non-empty
        const allPhonesNonNull = phoneConditions.every(condition => condition.phone);

        // Count phones that are blacklisted
        const blacklistedPhones = phoneConditions.filter(condition => condition.isBlacklisted);

        // Check if at least one phone is blacklisted and all others are either non-blacklisted or empty
        const allBlacklistFlagsCorrect = phoneConditions.every(
            condition =>
                (blacklistedPhones.includes(condition)) || // Include blacklisted phones
                (!condition.phone && !condition.isBlacklisted) // Others must be empty and not blacklisted
        );

        // Exclude cases where all phones are non-null but some blacklist flags are false
        const anyBlacklistFalse = phoneConditions.some(
            condition => condition.phone && !condition.isBlacklisted
        );

        if (allPhonesNonNull && anyBlacklistFalse) {
            continue; // Skip this entry
        }

        // Return the index if it satisfies all conditions
        if (blacklistedPhones.length > 0 && allBlacklistFlagsCorrect) {
            return i;
        }
    }

    return -1; // Return -1 if no match is found
}

export const removeContactAtIndex = (contactList, index) => {
    if (index === -1) {
        return contactList; // No match found, return the original list
    }
    return contactList.filter((_, i) => i !== index); // Remove the matched object
}

export const CheckingContactPhoneNumbers = (contacts, checkeddata) => {
    // Step 1: Check if a phone number is empty.
    const isPhoneEmpty = phone => phone === null || phone === undefined || phone.trim() === "";

    // Step 2: Filter contacts based on the rules.
    const filteredContacts = contacts.filter(contact => {
        const {
            DirectPhone,
            MobilePhone,
            CompanyPhone,
            OtherPhone1,
            OtherPhone2,
            IsDirectPhoneBlacklist,
            IsMobilePhoneBlacklist,
            IsCompanyPhoneBlacklist,
            IsOtherPhone1Blacklist,
            IsOtherPhone2Blacklist
        } = contact;

        // const { IsDirectPhone, IsMobilePhone, IsCompanyPhone, IsOtherPhone1, IsOtherPhone2 } = checkeddata;

        // Check if all phone numbers are empty.
        const allPhonesEmpty =
            isPhoneEmpty(DirectPhone) &&
            isPhoneEmpty(MobilePhone) &&
            isPhoneEmpty(CompanyPhone) &&
            isPhoneEmpty(OtherPhone1) &&
            isPhoneEmpty(OtherPhone2);

        // If all phones are empty, exclude the contact.
        if (allPhonesEmpty) return false;

        // Check for each `true` field in `checkeddata` if the associated phone number is valid and not blacklisted.
        const phones = {
            IsDirectPhone: { number: DirectPhone, blacklist: IsDirectPhoneBlacklist },
            IsMobilePhone: { number: MobilePhone, blacklist: IsMobilePhoneBlacklist },
            IsCompanyPhone: { number: CompanyPhone, blacklist: IsCompanyPhoneBlacklist },
            IsOtherPhone1: { number: OtherPhone1, blacklist: IsOtherPhone1Blacklist },
            IsOtherPhone2: { number: OtherPhone2, blacklist: IsOtherPhone2Blacklist }
        };

        const trueChecks = Object.entries(checkeddata).filter(([key, value]) => value);
        const hasValidPhone = trueChecks.some(([key]) => {
            const { number, blacklist } = phones[key];
            return !isPhoneEmpty(number) && !blacklist; // Ensure phone is not empty and not blacklisted
        });

        // Remove the contact if no valid phone number is found for the `true` checks.
        return hasValidPhone;
    });

    return filteredContacts;
};

//Getting dialing set details 
export const GettingDialingSetDetails = async (ClientID, UserID, Role) => {
    try {
        const RequestBody = { ClientID, UserID, Role };
        const response = await Axios.post(
            `${CommonConstants.MOL_APIURL}/twiliocalling/GetDialingSetList`,
            RequestBody
        );
        if (response.data?.Status === "SUCCESS") {
            return response;
        } else {
            throw new Error("Failed to fetch dialing set details");
        }
    } catch (error) {
        console.error("Error in GettingDialingSetDetails:", error);
        throw error;
    }
}

//Getting phone script details
export const GettingPhoneScriptDropDownDetails = async (ClientID, UserID, Role) => {
    try {
        const RequestBody = { ClientID, UserID, Role };
        const response = await Axios.post(
            `${CommonConstants.MOL_APIURL}/twiliocalling/GettingPhoneScriptDropDownDetails`,
            RequestBody
        );
        if (response.data?.Status === "SUCCESS") {
            return response;
        } else {
            throw new Error("Failed to fetch phone script dropdown details");
        }
    } catch (error) {
        console.error("Error in GettingPhoneScriptDropDownDetails:", error);
        throw error;
    }
}

//Getting live answer disposition details
export const GettingLiveAnswerDispositionSet = async (ClientID, UserID, Role) => {
    try {
        const RequestBody = { ClientID, UserID, Role };
        const response = await Axios.post(
            `${CommonConstants.MOL_APIURL}/twiliocalling/GettingLiveAnswerDispositionSet`,
            RequestBody
        );
        if (response.data?.Status === "SUCCESS") {
            return response;
        } else {
            throw new Error("Failed to fetch live answer disposition set details");
        }
    } catch (error) {
        console.error("Error in GettingLiveAnswerDispositionSet:", error);
        throw error;
    }
}

export const CallBtn = async (data) => {
    try {
        const { UserID, ClientID, ColdCallerUserID, Role, ProspectID, SelectedPhoneNumber } = data;
        let RequestBody = {
            ProspectID: ProspectID,
            Role
        }
        const contactResponse = await Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/GetContactDetails",
            method: "POST",
            data: RequestBody,
        });

        let contactData = contactResponse.data.Data || [];
        if (Role != "BookMeeting") {
            const index = findIndexWithSpecificConditions(contactData);
            contactData = removeContactAtIndex(contactData, index);
        }
        if (contactData.length === 0) {
            throw new Error("Your selected contact all phone numbers are blacklisted!");
        }

        // Check SDR Assign Phone Number
        const response = await Axios.post(
            `${CommonConstants.MOL_APIURL}/twiliocalling/CheckSDRAssignPhoneNumber`,
            {
                UserID,
                ClientID,
                ColdCallerUserID,
                Role,
                ContactList: contactData,
                SelectedPhoneNumber: SelectedPhoneNumber
            }
        );
        const { StatusMessage, Message } = response.data;
        if (StatusMessage === "SUCCESS") {
            let RequestBody = {
                UserID,
                ClientID,
                Role
            }
            const phoneSettingsResponse = await Axios({
                url: CommonConstants.MOL_APIURL + "/twiliocalling/phonesettingschechexsists",
                method: "POST",
                data: RequestBody,
            });

            if (phoneSettingsResponse.data.Status !== "SUCCESS") {
                throw new Error("Dialer configuration missing. Please contact your administrator.");
            }

            // Return data to the component for rendering
            return {
                DialSessionData: response.data,
                contactData,
                ColdCallerUserID,
            };
        }

        throw new Error(Message || "Unexpected error occurred.");
    } catch (error) {
        console.error(error);
        throw error;
    }
};