import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {SortableContainer,SortableElement} from "react-sortable-hoc";

import { CommonConstants } from "../../../_constants/common.constants";
import { arrayMoveImmutable } from "array-move";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import loadingicon from "../../../images/loading.gif";

toast.configure();

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));
const SortableList = SortableContainer(({ Items }) => {
  return (
    <ul>
      {Items.map((value, Index) => (
        <SortableItem
          id={Index}
          key={`item-${Index}`}
          index={Index}
          value={value.Name}
        />
      ))}
    </ul>
  );
});
class CClientPOCSetOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: [],
      ClientID: null,
      UserID: null,
      Role:null,
      BtnDisabled: false,
      IsLoading:true
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.BackBtn = this.BackBtn.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role=Details.Role
    }
    this.ClientPocSetOrderGet(Details.ClientID);
  }

  // client poc get
  ClientPocSetOrderGet(CID) {
    var str_in = {
      Page: 1,
      RowsPerPage: 100,
      Field: "OrderBy",
      SortBy: 1,
      Type: "User",
      ClientID: CID,
      Role:this.state.Role,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/clientpoc/ClientPocGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      this.setState({ Items: result.data.PageData });
              this.setState({IsLoading:false})
    });
  }

  // on sort end
  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      Items: arrayMoveImmutable(this.state.Items, oldIndex, newIndex),
    });
  };

  // save btn
  SaveBtn() {
    let OrderBy = 0;
    for (let i = 0; i < this.state.Items.length; i++) {
      this.state.Items[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.SaveSetOrderData();
  }

  // save set order data
  SaveSetOrderData() {
    this.setState({ BtnDisabled: true });
    this.setState({IsLoading:true})
    let str_in = {
      ClientpocList: this.state.Items,
      Role:this.state.Role
    };
    console.log(str_in);
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/clientpoc/ClientPocSetOrder",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        toast.success(
          "Client POC order updated successfully.",
          "Client POC order"
        );
        history.push("/CClientPOC");
        this.setState({IsLoading:false})
      } else {
        toast.error(result.data.Message);
        this.setState({ BtnDisabled: false });
        this.setState({IsLoading:false})
      }
      this.ClientPocSetOrderGet();
    });
  }

  // back button
  BackBtn() {
    history.push("/CClientPOC");
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading === true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
      }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle pl-0 mb-0">
                    Client POC Order
                  </h4>
                </div>
              </div>

            <div className="paddcols px-5">
              <div className="row pt-4">
                <div className="col-xl-12 offset-xl-12 p-0">
                  <span className="alertinfo">
                    &nbsp;<b>NOTE</b> : Please drag &amp; drop item to change
                    order of contact source and press save button.
                  </span>

                  <div className="spacupgroup">
                    <SortableList
                      Items={this.state.Items}
                      onSortEnd={this.OnSortEnd}
                    />
                  </div>
                </div>
              </div>
            
            </div>

              <div className="row py-4">
              <div className="col">
                <div className="pull-left">
                  <a
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={() => {
                      this.SaveBtn();
                    }}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save order
                  </a>
                  <a
                    id="backtolist"
                    onClick={() => {
                      this.BackBtn();
                    }}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCClientPOCSetOrderPage = connect(
  mapState,
  actionCreators
)(CClientPOCSetOrderPage);
export { connectedCClientPOCSetOrderPage as CClientPOCSetOrderPage };
