import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import Popupone from "reactjs-popup";

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
const EmailTemplete = require("../../../_helpers/email-templete");
import{GetUserDetails} from '../../../_helpers/Utility';
import loadingicon from "../../../images/loading.gif";
import Pagenations from "../../Pagenations";
const REACT_APP_ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";


const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});



export default function AccountAllTable({listupdate}) {
  
  const [Data, SetData] = useState([]);
  const [Flen, SetFlen] = useState(0);
  const [SFlag, SetFlag] = useState(false);
  const [Rlen, SetRlen] = useState(0);
  const [CountPage, SetCountPage] = useState(0);
  const [Rows, SetRows] = useState([]);
  const [Page, SetPage] = useState(1);
  const [RowsPerPage, SetRowsPerPage] = useState(100);
  const [Searched, SetSearched] = useState("");
  const [Email, SetEmail] = useState("");
  const [RID, SetRID] = useState("");
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [AlertBox, SetAlertBox] = useState(false);
  const [AID, SetAID] = React.useState("");
  const [Type, SetType] = React.useState("");
  const [InvitePopup, SetInvitePopup] = useState(false);
  const [Question, SetQuestion] = React.useState("");
  const [PopUpButton, SetPopUpButton] = React.useState("");
  const [ArchivedPopup, SetArchivedPopup] = React.useState(false);
  const [ArchivePopUp, SetArchivePopUp] = React.useState(false);
  const [CancelArchivePopupval, SetCancelArchivePopupval] =React.useState(false);
  const [Msg, SetMsg] = React.useState("");
  const [UserID, SetUserID] = React.useState("");
  const [ClientID, SetClientID] = React.useState("");
  const [Role, SetRole] = React.useState("");
const [CUserID,SetCUserID] = React.useState(0);
const [sortedColumn, setSortedColumn] = React.useState(null);
const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.getElementById("hideloding").style.display = "block";
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GETColdCallerInvite(GetUserData.ParentUserID);
  }, [Searched, Page, RowsPerPage,listupdate]);

  //serach for invite list
  const requestSearch = (Event) => {
    // if (Event.key === "Enter") {
    //   var SearchedVal = document.getElementById("search").value?.trim();
    //   SetSearched(SearchedVal);
    //   GETColdCallerInvite(UserID);
    // }
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value?.trim();
      if (SearchedVal == Searched) {
        SetSearched(SearchedVal);
        SetPage(1);
      } else {
        SetSearched(SearchedVal);
        SetPage(1);
      }
    }
  };
  
  const HandleChangePage = (event, newPage) => {
    if(newPage==Page){
      SetPage(newPage);
    }else{
      SetPage(newPage);
     
    }
  };

  const chengeRowSelected = (event) => {
    
    SetRowsPerPage(event.target.value);
    SetPage(1);
    SetCountPage(
      parseInt((Rlen + (event.target.value - 1)) / event.target.value)
    );
  };
  const openReInvitePopup = (Emaildata, ID) => {
    
    SetEmail(Emaildata);
    SetRID(ID);
    SetInvitePopup(true);
  };

  const closeReInvitePopup = () => {
    SetInvitePopup(false);
    SetEmail("");
  };

  const SubmitReInvitePopup = () => {
    var Email1 = document.getElementById("change_mail").value;
   console.log(Email1)
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var emailflg = re.test(Email1);
    var FinalEmail = CryptoJS.AES.encrypt( JSON.stringify(Email1), REACT_APP_ENCRYPTION_SALT).toString();
    let VerificationCodeChar = (Math.random() + 1).toString(36).substring(7);
    var UID = CryptoJS.AES.encrypt(JSON.stringify(CUserID),REACT_APP_ENCRYPTION_SALT).toString();
    var VC = CryptoJS.AES.encrypt(JSON.stringify(VerificationCodeChar),REACT_APP_ENCRYPTION_SALT).toString();
    var EmailData = EmailTemplete.ResponderUserEmailInvite(
      FinalEmail,
      UID,
      UID,
      VC,
      CommonConstants.coldcallers_Invitation
    );
      var str_mail = {
        Email: Email1,
        html: EmailData,
        subject: "SalesHive User Account",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/profileemail",
        method: "POST",
        data: str_mail,
      }).then((res) => {
        const InputParameters={
          VerificationCode:VerificationCodeChar,
          ID:RID
        }
        Axios({
          url: CommonConstants.MOL_APIURL + "/cold_callers_profile/InviteResend",
          method: "POST",
          data: InputParameters
        }).then((res) => {
          
          SetInvitePopup(false);
        SetRID("");
        SetEmail("");
        toast.success(<div>Sales Development Reps<br/>
            Invitation mail sent successfully.</div>)
        // SetAlertBox(true);
        })
          
      })
  
  }

  const sortdata = (field) => {
    if(field == 'Action'){
      var sortedby;
      if (SortedBy == 1) {
        sortedby = -1;
        SetSortedBy(-1);
      } else {
        sortedby = 1;
        SetSortedBy(1);
      }
    }else{
      document.getElementById("hideloding").style.display = "block";

      var searchedVal = document.getElementById("search").value?.trim();
      var serchbox;
      if (searchedVal == "") {
        serchbox = false;
        SetFlag(false);
        var sortfield = field;
        var sortedby;
        if (SortedBy == 1) {
          sortedby = -1;
          SetSortedBy(-1);
        } else {
          sortedby = 1;
          SetSortedBy(1);
        }
        SetSortField(field);
        var str_in = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Serchbox: false,
          Sort: true,
          Field: sortfield,
          Sortby: sortedby,
        UserID:UserID,
        Search:searchedVal
  
        };
        const rows1 = Axios({
          url: CommonConstants.MOL_APIURL + "/cold_callers_profile/ColdCallerInviteGet",
          method: "POST",
          data: str_in,
        });
        rows1.then((result) => {
          SetData(result.data.PageData);
          SetRows(result.data.PageData);
          SetRlen(result.data.TotalCount);
          SetFlen(result.data.TotalCount);
          SetCountPage(result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
  
  
        });
      } else {
        serchbox = true;
        SetFlag(true);
        var sortfield = field;
        var sortedby;
        if (SortedBy == 1) {
          sortedby = -1;
          SetSortedBy(-1);
        } else {
          sortedby = 1;
          SetSortedBy(1);
        }
        SetSortField(field);
        var str_in = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Search: searchedVal,
          Serchbox: serchbox,
          Sort: true,
          Field: sortfield,
          Sortby: sortedby,
          UserID:UserID
  
        };
        const rows1 = Axios({
          url: CommonConstants.MOL_APIURL + "/cold_callers_profile/ColdCallerInviteGet",
          method: "POST",
          data: str_in,
        });
        rows1.then((result) => {
          SetData(result.data.PageData);
          SetRows(result.data.PageData);
          SetRlen(result.data.TotalCount);
          SetFlen(result.data.TotalCount);
          SetCountPage(result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
  
        });
      }
    }
    
  };
  const alertboxhandleClose = () => {
    SetAlertBox(false);
  };
  const GETColdCallerInvite = (UID) => {
    var str_in = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Serchbox: false,
      Search: Searched,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      UserID:UID
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_callers_profile/ColdCallerInviteGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
      setIsDataLoaded(true);

    });
  };
  const DeleteColdCallerInvite = () => {
  
    var str_in = {
      uID: AID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/cold_callers_profile/ColdCallersProfileInviteDelete",
      method: "POST",
      data: str_in,
    }).then((res) => {
      // startpage();
    });
  };
  //delete action item 
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Sales development reps.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons:true
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputData = {
          ID: ID,
          LastUpdatedDate: new Date(),
          // LastUpdatedBy:UserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/cold_callers_profile/ColdCallersProfileInviteDelete",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Sales development reps deleted successfully.",
                "success"
              );
              // GETColdCallerInvite(ClientID,UserID);
              GETColdCallerInvite(UserID);
              SetPage(1);
              SetSearched("");
                    document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Data is safe :)", "error");
      }
    });
  };

  const archiveopen = (popuptype, id) => {
  
    SetAID(id);
    SetType(popuptype);
    if (popuptype == "delete") {
      SetMsg("you want to Delete a Cold Callers.");
      SetQuestion("Are you sure?");
      SetPopUpButton("Yes, delete it!");
      SetArchivePopUp(true);
    }
  };
  const archivesubmit = () => {
    SetArchivePopUp(false);

    if (Type == "delete") {
      SetMsg("responder Delete successfully.");
      SetQuestion("Delete");
      SetArchivedPopup(true);
      DeleteColdCallerInvite();
    }
  };
  const archivecancle = () => {
    SetArchivePopUp(false);
    openarchivepopup();
  };

  const openarchivepopup = () => {
    SetCancelArchivePopupval(true);
  };

  const canclearchivepopup = () => {
    SetCancelArchivePopupval(false);
  };

  const responderarchive = () => {
    SetArchivedPopup(false);
  };
  return (
    <div>
      <Snackbar
        className="erorrheader"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={AlertBox}
        autoHideDuration={6000}
        onClose={alertboxhandleClose}
      >
        <Alert variant="filled" className="erorrheader">
          Cold Callers
          <br /> Invitation mail send successfully.
        </Alert>
      </Snackbar>
      <Popup open={ArchivePopUp} modal>
        <div className="modal-black"></div>
        <div className="filterPopup maxpopup">
          <div className="paddingboxTerms">
            <div className="modal-content bordernone aligncentertext">
              <div class="swal2-icon swal2-warning swal2-animate-warning-icon">
                <span class="swal2-icon-text">!</span>
              </div>
              <h3>{Question}</h3>
              <p>{Msg}</p>
              <div class="swal2-actions">
                <button
                  Type="button"
                  onClick={archivecancle}
                  class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2"
                >
                  No, cancel!
                </button>
                <button
                  Type="button"
                  onClick={archivesubmit}
                  class="swal2-confirm btn btn-success m-btn m-btn--custom"
                >
                  {PopUpButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup open={CancelArchivePopupval} modal>
        <div className="modal-black"></div>
        <div className="filterPopup maxpopup">
          <div className="paddingboxTerms">
            <div className="modal-content bordernone aligncentertext">
              <div class="swal2-icon swal2-error swal2-animate-error-icon d-flex">
                <span class="swal2-x-mark">
                  <span class="swal2-x-mark-line-left"></span>
                  <span class="swal2-x-mark-line-right"></span>
                </span>
              </div>
              <h3>Cancelled</h3>
              <p>Your Data is safe :)</p>
              <div class="swal2-actions">
                {/* <button Type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
                <button
                  onClick={canclearchivepopup}
                  Type="button"
                  class="swal2-confirm btn btn-success m-btn m-btn--custom"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup open={ArchivedPopup} modal>
        <div className="modal-black"></div>
        <div className="filterPopup maxpopup">
          <div className="paddingboxTerms">
            <div className="modal-content bordernone aligncentertext">
              <div class="swal2-icon swal2-success swal2-animate-success-icon">
                <div class="swal2-success-circular-line-left"></div>
                <span class="swal2-success-line-tip"></span>
                <span class="swal2-success-line-long"></span>
                <div class="swal2-success-ring"></div>
                <div class="swal2-success-fix"></div>
                <div class="swal2-success-circular-line-right"></div>
              </div>
              <h3>{Question}</h3>
              <p>{Msg}</p>
              <div class="swal2-actions">
                {/* <button Type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
                <button
                  Type="button"
                  onClick={responderarchive}
                  class="swal2-confirm btn btn-success m-btn m-btn--custom"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div class='p-3'>
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={chengeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              Type="search"
              id="search"
              onKeyPress={requestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" aria-label="collapsible table">     
                 <TableHead>
              <TableRow>
                <TableCell onClick={() => { sortdata("Email"); setSortedColumn("Email");}}>
                    Email
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { sortdata("Action"); setSortedColumn("Action");}}>Action <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Action" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Action" ? "active" : null} />
                  </span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                Rows.length === 0 ? 
                  <p className="text-center">No data available in table</p>:
              Rows?.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>
                    <a
                      class="btn btn-sm btn-primary btnColor m-btn whitetext"
                      onClick={() => {
                        openReInvitePopup(row.Email, row._id);
                      }}
                      title="Reinvite"
                    >
                      Reinvite
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Popupone open={InvitePopup} modal>
        <div className="modal-black"></div>
        <div className="filterPopup bigpopupcontent">
          <div className="paddingboxTerms">
            <div className="modal-header">
              <h5>Send Mail</h5>
              <a className="close-ion" onClick={closeReInvitePopup}>
                <span aria-hidden="true" class="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone">
              <div class="row max-cols">
                <label class="col-lg-4 view-lable-meet">Email</label>
                <div class="col-lg-8">
                  <input
                    class="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter first name"
                    id="change_mail"
                    name="FirstName"
                    placeholder="First name"
                    Type="text"
                    value={Email}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                Type="button"
                class="btn btn-secondary"
                onClick={closeReInvitePopup}
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                id="m_emailInvite_submit"
                onClick={SubmitReInvitePopup}
                href="javascript:void(0);"
                class="btn btn-primary btnColor"
              >
                Send{" "}
              </a>
            </div>
          </div>
        </div>
      </Popupone>
      <Pagenations
         Sflag={SFlag}
         CountPage={CountPage}
         HandleChangePage={HandleChangePage}
         Rlen={Rlen}
         Page={Page}
         RowsPerPage={RowsPerPage}
         Flen={Flen}
      />
      </div>
    </div>
  );
}
