import React from "react";
import Axios from "axios";

import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import ContactTag from "../../_components/clients/contactsettings/tag/tag";

import { CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { GetClientDetails } from "../../_helpers/Utility";
class CContactTagpage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientName:"",
      Filename: "",
      ClientID:null,
      UserID:null,
      ExportData: [],
      Role:null
    };
    this.AddBtn = this.AddBtn.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.Role=Details.Role
    }
    GetClientDetails(Details.ClientID).then((result) => {
      // SetCName(result[0].Name);
      this.setState({ClientName:result[0].Name})
    });
    var str_in = {
      ClientID: this.state.ClientID,
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ ExportData: res.data.Data });
    });
  }

  // add button
  AddBtn() {
    history.push("/CAddContactTag");
  }

 //Reload page while delete perform
 UpdateFromChild = (value) => {
  if(value == true){
    this.componentDidMount()
  }
}

  // export csv button
  ExportCsv() {}

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle pl-0 mb-0">Tag</h4>
              </div>
              <div className="col"> 
                  <div className="listing-li float-right mt-1">
                    <ul>
                      <li>
                      <CSVDownloader className="px-0"
                              data={this.state.ExportData}
                              filename={`${this.state.ClientName}-ContactTag`}
                              bom={true}
                            >
                        <a
                          onClick={this.ExportCsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>
            
                              Export
                          
                          </span>
                        </a>
                        </CSVDownloader>
                      </li>
                      <li>
                        <a
                          onClick={this.AddBtn}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                    </ul>
                  </div>
              </div>
            </div>

            <div class="bg-white">
              <div className="row mx-0 pb-3">
                <div className="col">
                  <ContactTag updateFromChild={this.UpdateFromChild}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCContactTagpage = connect(mapState, actionCreators)(CContactTagpage);
export { connectedCContactTagpage as CContactTagpage };