import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import { CSVReader, CSVDownloader } from "react-papaparse";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientMeetings from "../../_components/user/ClientRescheduling/ClientRescheduling";
//import CollapsibleTable from '../_components/usertable';
import loadingi from "../../images/loading.gif";
import { toast } from "react-toastify";

import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart from "react-apexcharts";

import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";

charts(FusionCharts);

class ClientReschedulingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: null,
      ClientID: null,
      ExportData: [],
      GraphData: [],
      SourceData: {},
      TotalReschedules: 0,
      loadingState: false, 
    };
    // this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
    }
    this.GraphGet(Details.ParentUserID,Details.Role);
    this.RescheduleCount(Details.ParentUserID,Details.Role);
  }

  // Graph Get
  GraphGet(UID,RoleID) {
    const InputParameters = {
      UserID: UID,
      Role:RoleID
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/client_reschedules/GraphGet",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      this.setState({ GraphData: Res.data?.data });
      var dataSource = {
        chart: { 
          caption: "Meeting Source",
          /*start design round chart*/ 
          theme: "fusion",
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%",
          plothighlighteffect: "false",
          legendcaptionbold: "1",
          legendcaptionfontsize: "15",
          legendCaptionFontColor: "#000",
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "bottom",
         // legendCaption: "Meeting Status: ",
          legendBgColor: "#FFF",
          legendBorderColor: "#ffF",
          legendShadow: "0",
          legendItemFontSize: "14",
          legendWidth: "300", 
        },
        data: Res.data?.data,
      };
      this.setState({ SourceData: dataSource });
     // this.setState({ TotalReschedules: Res.data?.TotalmeetingCount });
    });
  }

    // Graph Get
  RescheduleCount(UID,RoleID) {
      const InputParameters = {
        UserID: UID,
        Role:RoleID
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/client_reschedules/RescheduleTotalCount",
        method: "POST",
        data: InputParameters,
      }).then((Res) => {
        this.setState({ TotalReschedules: Res.data?.TotalmeetingCount });
      });
    }


  // ExportMethod = async (UID, CID,RoleID) => {
   
  //   const InputParameters = {
  //     UserID: UID,
  //     ClientID: CID,
  //     Role:RoleID
  //   };

  //   try {
  //     const rows1 = await Axios({
  //       url: CommonConstants.MOL_APIURL + '/client_reschedules/ClientReschedulesExport',
  //       method: 'POST',
  //       data: InputParameters,
  //     });

  //     if (rows1.data.StatusMessage === 'SUCCESS') {
  //       return rows1.data;
  //     }
  //   } catch (error) {
  //     console.error('Exportdetails error:', error);
  //     throw error;
  //   }
  // };

  // exportcsv = async () => {
  //   try {
  
  //     this.setState({ loadingState: true })
  //     const result = await this.ExportMethod(this.state.UserID,this.state.ClientID, this.state.Role);

  //     if (result.StatusMessage === 'SUCCESS') {
  //       const data = result.Data;

  //       if (data.length > 0) {
  //         this.setState({ ExportData: data });

  //         const downloader = document.createElement('a');
  //         const csvContent =
  //           'data:text/csv;charset=utf-8,' +
  //           encodeURIComponent(this.convertToCSV(data));

  //         downloader.setAttribute('href', csvContent);
  //         downloader.setAttribute(
  //           'download',
  //           'AllClients-Rescheduling'
  //         );
  //         downloader.click();
  //         toast.success(
  //           <div>
  //             Data exported successfully.
  //           </div>
  //         );
  //         this.setState({ loadingState: false })
  //       } else {
  //         toast.error('No data available for export.');
  //         this.setState({ loadingState: false })
  //       }
  //     } else {
  //       toast.error('Failed to export data. Please try again.');
  //       this.setState({ loadingState: false })
  //     }
  //   } catch (error) {
  //     toast.error('An error occurred while exporting data.');
  //     this.setState({ loadingState: false })
  //   }
  // };

  // convertToCSV(data) {
  //   const headers = Object.keys(data[0]);
  //   const csvRows = [];
  //   csvRows.push(headers.join(','));

  //   data.forEach(item => {
  //     const values = headers.map(header => {
  //       const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
  //       const escapedValue = value.replace(/"/g, '""');
  //       return `"${escapedValue}"`;
  //     });
  //     csvRows.push(values.join(','));
  //   });

  //   return csvRows.join('\n');
  // }

    // //Reload page while delete perform
    // UpdateFromChild = (value) => {
    //   this.setState({ TotalReschedules: value });
    // };

  render() {
    return (
      <>
       {this.state.loadingState ? (
      <div id="hidelod" className="loding-display">
        <img src={loadingi} />
      </div>
      ) : null}
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle  pl-0">Client Rescheduling</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15">
                  <ul></ul>
                </div>
              </div>
            </div>

            <div className="w-100 whiterow">
              <div className="row">
                <div className="col">
                  <div className="bg-light-sky boxcardcounter align-center border-0">
                    <div class="line-title d-block text-center">
                      <span class="clr-sky box-main-title">
                        {this.state.TotalReschedules}
                      </span>
                    </div>
                    <p class="clr-sky">Total Reschedules</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3"> 
              <div className="col-xl-12">
                <div className="whiterow px-3">
                  <h3 className="smheadtext">Meeting Source</h3>
                  <ReactFusioncharts
                    key={JSON.stringify(this.state.SourceData)}
                    type="pie3d"
                    width="100%"
                    height="300"
                    dataFormat="JSON"
                    dataSource={this.state.SourceData}
                  />
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a
                        onClick={this.exportcsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                        
                            Export
                         
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}

            <div class=""> 

              <div className="row">
                <div className="col">
                    <ClientMeetings 
                    //updateFromChild={this.UpdateFromChild} 
                    /> 
                </div>
              </div>
 
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientReschedulingPage = connect(
  mapState,
  actionCreators
)(ClientReschedulingPage);
export { connectedClientReschedulingPage as ClientReschedulingPage };
