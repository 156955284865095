import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure();
const moment = require("moment");

import { GetUserDetails } from "../../_helpers/Utility";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import ClientSidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import AddArticle from "../../_components/user/KnowledgeBaseArticle/AddKnowledgeBaseArticle";
import EditArticle from "../../_components/user/KnowledgeBaseArticle/EditKnowledgeBaseArticle";

class AddKnowledgeBaseArticlePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ClientID: null,
      UserID: null,
      CUserID: null,
      StartDate: null,
      EndDate: null,
      exportdata: {
        Start: moment(new Date().setMonth(new Date().getMonth() - 3)).format(
          "YYYY/MM/DD"
        ),
        End: moment(new Date()).format("YYYY/MM/DD"),
      },
      pageData: null,
      articleId: props.location.state != undefined ? props.location.state : null,
      isEdit: props.location.state != undefined ? true : false,
      Role: "",
    };
  }
  componentDidMount = async () => {
    if (!this.state.isEdit) {
      document.title = `Add Articles | SalesHive`;
    }
    else {
      document.title = `Edit Articles | SalesHive`;
    }
    var Details = await GetUserDetails();
    if (Details != null) {
      this.setState({ Role: Details.Role })
    }
  }
  render() {
    const { articleId, isEdit, Role } = this.state;
    return (
      <>
        <div className="adminmain mheight">
        { Role === "Client" ? <ClientSidebar className="" /> : <Sidebar className="" /> }
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="lg-headertitle py-3 float-left">
                  {
                    isEdit === true ? "Edit Knowledge Base Article" : "Add Knowledge Base Article"
                  }
                </h4>
              </div>
              <div className="col padright">

              </div>
            </div>

            <div className="listblock">
              {
                isEdit === true ? <EditArticle articleData={articleId} /> : <AddArticle />
              }
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddKnowledgeBaseArticlePage = connect(mapState, actionCreators)(AddKnowledgeBaseArticlePage);
export { connectedAddKnowledgeBaseArticlePage as AddKnowledgeBaseArticlePage };
