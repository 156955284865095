import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import Axios from "axios";
const moment = require("moment");
import Popup from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";
const Fileupload = require("../../../_helpers/fileupload");
import Select from "react-select";
import { useLocation } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";
import loadingicon from "../../../images/loading.gif";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CSVReader } from "react-papaparse";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { Book } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Popover from '@mui/material/Popover';
import StyleHeader from "../../user/StickyHeader/StickyHeader";
import addNewFilter from '../../../images/addFilter.svg'
import existFilter from '../../../images/existFilter.svg'

toast.configure();

export default function AccountAllTable({
  AccountTypeForTab,
  AccountTypeName,
}) {
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [prospectAllSelectData, setProspectAllSelectData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [sortField, setsortField] = React.useState("ContactName");
  const [sortedBy, setsortedBy] = React.useState(1);
  const [sortedColumn, setSortedColumn] = React.useState("Contact Name");
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [searchfilter, setSearchFilter] = React.useState("");
  const [ContactSalesDevlopMenData, setContactSalesDevlopMenData] =
    React.useState([]);
  const [ContactCustomFieldData, setContactCustomFieldData] = React.useState(
    []
  );
  const [DeleteArray, SetDeleteArray] = React.useState([]);
  const [Reset, SetReset] = React.useState(false);
  const [IsResetApply, SetIsResetApply] = React.useState(false);

  
  const [ImportCsvReset, SetImportCsvReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [filenameonlynumber, setfilenameonlynumber] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [csvData2, setcsvData2] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [ContactHeader, SetContactHeader] = React.useState([]);
  
  const [FirstName, SetFirstName] = React.useState(null);
  const [LastName, SetLastName] = React.useState(null);
  const [Email, SetEmail] = React.useState(null);
  const [Title, SetTitle] = React.useState(null);
  const [Company, SetCompany] = React.useState(null);
  const [CompanyNameLong, SetCompanyNameLong] = React.useState(null);
  const [CompanyPhone, SetCompanyPhone] = React.useState(null);
  const [City, SetCity] = React.useState(null);
  const [State, SetState] = React.useState(null);
  const [ContactCategory, SetContactCategory] = React.useState(null);
  const [AccountCategory, SetAccountCategory] = React.useState(null);
  const [Website, SetWebsite] = React.useState(null);
  const [Address1, SetAddress1] = React.useState(null);
  const [Address2, SetAddress2] = React.useState(null);
  const [LinkedInURL, SetLinkedInURL] = React.useState(null);
  const [Zip, SetZip] = React.useState(null);
  const [Country, SetCountry] = React.useState(null);
  const [ValidationScore, SetValidationScore] = React.useState(null);
  const [Industry, SetIndustry] = React.useState(null);
  const [CompanyRevenue, SetCompanyRevenue] = React.useState(null);
  const [EmployeeCount, setEmployeeCount] = React.useState(null);
  const [MobilePhone, SetMobilePhone] = React.useState(null);
  const [DirectPhone, SetDirectPhone] = React.useState(null);
  const [DirectPhoneExt, SetDirectPhoneExt] = React.useState(null);
  const [OtherPhone1, SetOtherPhone1] = React.useState(null);
  const [OtherPhone2, SetOtherPhone2] = React.useState(null);
  const [ContactSource, SetContactSource] = React.useState(null);
  const [ContactOwner, SetContactOwner] = React.useState(null);
  const [ClientPOC, SetClientPOC] = React.useState(null);
  const [ReasonUnqualified, SetReasonUnqualified] = React.useState(null);
  const [AccountType, SetAccountType] = React.useState(null);
  const [IsDoNotCallContact, SetIsDoNotCallContact] = React.useState(null);
  const [SalesRep, SetSalesRep] = React.useState(null);
  const [ColdCaller, SetColdCaller] = React.useState(null);
  const [Tag, SetTag] = React.useState(null);
  const [CallNotes, SetCallNotes] = React.useState(null);
  const [ContactID, SetContactID] = React.useState(null);
  const [ListName, SetListName] = React.useState(null);
  const [ZoomID, SetZoomID] = React.useState(null);
  const [BestPhone, SetBestPhone] = React.useState(null);
  const [BestPhoneStatus, SetBestPhoneStatus] = React.useState(null);
  const [Phone, SetPhone] = React.useState(null);
  const [PhoneStatus, SetPhoneStatus] = React.useState(null);
  const [Phone2, SetPhone2] = React.useState(null);
  const [Phone2Status, SetPhone2Status] = React.useState(null);
  const [Phone3, SetPhone3] = React.useState(null);
  const [Phone3Status, SetPhone3Status] = React.useState(null);
  const [Phone4, SetPhone4] = React.useState(null);
  const [Phone4Status, SetPhone4Status] = React.useState(null);
  const [PRLLeadID, SetPRLLeadID] = React.useState(null);
  const [CampaignData, SetCampaignData] = React.useState([]);
  const [ShowPopup, SetShowPopup] = React.useState(false);
  const [ShowPopupReassignDev, SetShowPopupReassignDev] = React.useState(false);
  const [ShowPopupReassignSDR, SetShowPopupReassignSDR] = React.useState(false);
  const [ShowPopupExport, SetShowPopupExport] = React.useState(false);
  const [ShowPopupCampaign, SetShowPopupCampaign] = React.useState(false);
  const [ContactReassignData, SetContactReassignData] = React.useState([]);
  const [ContactToReassignData, SetContactToReassignData] = React.useState([]);

  const [CheckState, SetCheckState] = React.useState(false);
  const [ContactTagSelectArray, setContactTagSelectArray] = React.useState([]);
  const [ReassignFromSelectedValue, SetReassignFromSelectedValue] =
    React.useState([]);
  const [ReassignToSelectedValue, SetReassignToSelectedValue] = React.useState(
    []
  );
  const [ReassignSDRToSelectedValue, SetReassignSDRToSelectedValue] = React.useState(
    []
  );
  
  const [Total, SetTotal] = React.useState(0);
  const [isSaving, setIsSaving] = React.useState(false);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalUpdated, SetTotalUpdated] = React.useState(0);
  const [UserEmail, SetUserEmail] = React.useState("");
  const [ShowPopupImport, SetShowPopupImport] = React.useState(false);

  const [OpenAddFilter, SetOpenAddFilter] = React.useState(false);
  const [IsApply, SetIsApply] = React.useState(false);
  const [CustomColumnData, SetCustomColumnData] = React.useState([]);
  const [CsvFileName, SetCsvFileName] = React.useState("");
  const [selectedCampaign, setSelectedCampaign] = React.useState(null);
  const [FieldAttributes, SetFieldAttributes] = React.useState([
    {
      id: Math.floor(Math.random() * 1000000000),
      FieldValue: "AccountCategory",
      ColumnName: "Account Category",
      Expression: "Contains",
      FilterPropertyValue: "",
      FilterPropertyValue1: "",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      NullCondition: false,
    },
  ]);
  const [FilterParameters, SetFilterParameters] = React.useState([]);
  const [FilterName, SetFilterName] = React.useState(null);
  const [FilterID, SetFilterID] = React.useState(null);
  const [EditFieldAttributes, SetEditFieldAttributes] = React.useState([]);
  const [DeletedFilterProperty, SetDeletedFilterProperty] = React.useState([]);
  const [IsFiltered, SetIsFiltered] = React.useState(false);
  const [SaveAsBoxFilterName, SetSaveAsBoxFilterName] = React.useState(false);
  const [ErrorMessage, SetErrorMessage] = React.useState([]);
  const [ContactTags, SetContactTags] = React.useState([]);
  const [DeleteTagModel, SetDeleteTagModel] = React.useState(false);
  const [AddTagBtnDisabled, SetAddTagBtnDisabled] = React.useState(false);
  const [TableRowLength, SetTableRowLength] = React.useState(null)
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [OpenImportCsv,SetOpenImportCsv] = React.useState(false)
  const [OpenDeleteCsv,SetOpenDeleteCsv] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(null);
  const [DisabledAddFilter,SetDisabledAddFilter] = React.useState(false)
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [IsDisablesExport, setExportsToContacts] = React.useState(false);
  const [TotalCountFlag, SetTotalCountFlag] = React.useState(false);
  const [PageCountForCheck, SetPageCountForCheck] = React.useState([]);
  const [ResetBulkDelete, SetResetBulkDelete] = React.useState(false);
 const [HandleChangeArray,SetHandleChangeArray] = React.useState([]);

 const [OpenFilterModel,SetOpenFilterModel] = React.useState(false);
 const [OpenAddFilterModel,SetOpenAddFilterModel] = React.useState(false);
 const [OpenEditFilterModel,SetOpenEditFilterModel] = React.useState(false);
 const [OpenSaveAsFilterModel,SetOpenSaveAsFilterModel] = React.useState(false);
 const [Errors,SetErrors] = React.useState({});
 const [IsValidEmail, SetIsValidEmail] = React.useState(false)
 const [selectedValues, setSelectedValues] = React.useState({});
const [uploadflag,setUploadflag] = React.useState(false);

// filter apply 
const [filterdata, setfilterdata] = React.useState([]);
const [filterflen, setfilterflen] = React.useState(0);
const [filtersflag, setfiltersflag] = React.useState(false);
const [filterrlen, setfilterrlen] = React.useState(0);
const [filtercountpage, setfilterCountPage] = React.useState(0);
const [filterrows, setfilterRows] = React.useState([]);
const [filterpage, setfilterPage] = React.useState(1);
const [filterRowsPerPage, SefiltertRowsPerPage] = React.useState(100);
const [filterSearchParams,setfilterSearchParams] = React.useState('');
const [isDataLoaded, setIsDataLoaded] = React.useState(false);
const [anchorEl, setAnchorEl] = React.useState(null);
const [TotalContactLimitPerClient, SetTotalContactLimitPerClient] = React.useState(null);
const [CurrentTotalContactLimitPerClient, SetCurrentTotalContactLimitPerClient] = React.useState(null) 
const [showFull, setShowFull] = React.useState({});
const [lastSortedField, setLastSortedField] = React.useState('ContactName'); // Track the last sorted field

const CallNoteshandleToggle = (id) => {  
  setShowFull((prev) => ({
    ...prev,
    [id]: !prev[id], // Toggle the specific row by ID
  }));
};


  const settinghandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const settinghandleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
 const handleSelectChange = (fieldName, value) => {
   setSelectedValues((prevValues) => ({
     ...prevValues,
     [fieldName]: DropboxData[value],
   }));
 };
  const location = useLocation();
  const myparam = location;
  useEffect(() => {
    const defaultValues = {};
    
    ContactCustomFieldData.forEach((customfield) => {
      const defaultIndex = DropboxData.findIndex(
        (item) => item.toLowerCase() === customfield?.FieldName.toLowerCase()
      );
      if (defaultIndex !== -1) {
        defaultValues[customfield.FieldName] = DropboxData[defaultIndex];
      }
    });
    setSelectedValues(defaultValues);
  }, [ContactCustomFieldData, DropboxData,uploadflag]);

  useEffect(() => {
    document.title = "Contacts | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      setRole(Details.Role);
      SetUserEmail(Details.Username);
    }
  }, [ UserEmail]);
  

  useEffect(()=>{
  var Details = GetUserDetails();
  // Use Promise.all to wait for both requests to complete
  Promise.all([
    ContactCustomHeaderDataGet(Details.ClientID, Details.ParentUserID, Details.Role),
    ContactListGet(Details.ClientID, Details.ParentUserID, Details.Role,page,IsApply,FilterParameters),
    ContactListTotal(Details.ClientID, Details.ParentUserID, Details.Role,page,IsApply,FilterParameters)
  ]).then(() => {
    // Both requests completed, set loader to false
    SetIsLoading(false);
  }).catch((error) => {
    toast.error("An error occurred. Please try again.");
    SetIsLoading(false);
  });
    ContactInfoGetData(Details.ClientID, Details.ParentUserID, Details.Role);
    GetCustomFiledsData(Details.ClientID, Details.ParentUserID, Details.Role);
    Getfilterlist(Details.ClientID);
    GetClientDetails(Details.ClientID);
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/GetContactTags",
      method: "POST",
      data: { ClientID: Details.ClientID, UserID: Details.ParentUserID },
    }).then((res) => {
      SetContactTags(res.data.Data);
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  },[search, page, RowsPerPage]);

  const GetClientDetails = async (CID) => {
    try {
      var InputParams = {
        ClientID: CID,
      };
  
      const res = await Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ClientsDetailsGet",
        method: "POST",
        data: InputParams,
      });
  
      if (res.data.StatusMessage === "SUCCESS") {
        SetTotalContactLimitPerClient(res.data.ClientData[0].LimitTotalContactsPerClient);
        SetCurrentTotalContactLimitPerClient(res.data.ClientData[0].CurrentTotalContactsPerClient);
      } else {
        toast.error(res.data.Message);
      }
    } catch (error) {
      SetIsLoading(false);
      
    }
  };
    // form validation for export
    const FromValidation = async () => {
      let FormIsValid = true;
      let Errors = {}; 
      var Username = document.getElementById("Username").value.trim();
      let IsExistEmail = await EmailValidationCheck(Username);
      
      if (Username === "") { // Check for an empty string or a space in the name
        FormIsValid = false;
        Errors["Username"] = "Please enter email"; // Modify the error message
      }
      if (IsExistEmail == true) {
        FormIsValid = false;
        Errors["Username"] = "Invalid email!";
        // toast.error("Invalid email!")
      }
    
      SetErrors(Errors)
      return FormIsValid;
    }
      // cheack email valid for export
  const EmailValidationCheck = async (Username) => {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Username) && Username != "") {
      Errors["Username"] = "invalid email.";
      SetIsValidEmail(true)
      // this.setState({ IsValidEmail: true });
      SetErrors(Errors)
      // this.setState({errors:Errors});
      return true;
    } else {
      SetIsValidEmail(false)
      // this.setState({ IsValidEmail: false });

      return false;
    }
  };


  // Exports csv for contacts
  const ExportsToContacts = async () => {
    var FormValidationFlag = await FromValidation();

    setExportsToContacts(true)
    if(FormValidationFlag === true){
      var ExportEmails = document.getElementById("Username").value.trim();
      var ExportData = {
        UserID: UserID,
        ClientID: ClientID,
        Email: ExportEmails,
        AccountType: AccountTypeName,
        CreatedDate: new Date(),
        CreatedBy: CUserID,
        AccountTypeID: AccountTypeForTab,
        ClientIDs: null,
        IsDeleted: false,
        LastUpdatedDate: null,
        LastUpdatedBy: null,
        InProcess: false,
        IsComplete: false,
        InProcessDate: null,
        IsCompleteDate: null,
        Page: "",
        CampaignID: null,
        DynamicQuery: FilterParameters,
        Role: Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/AllClientExportAdd",
        method: "POST",
        data: ExportData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Contact
              <br />
              You will get file soon on Submitted Email.
            </div>
          );
          SetShowPopupExport(false);
          setExportsToContacts(false)
          SetErrors({})
        } else {
          toast.error(res.data.Message);
          setExportsToContacts(false)
          SetErrors({})
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
        SetErrors({})
      });
    }else{
      setExportsToContacts(false)
    }

  };
  const handleChangeEmailcheck = () => {
    var Username = document.getElementById("Username").value.trim();
    let regexp =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  let Errors = {};
  if (!regexp.test(Username) && Username != "") {
    Errors["Username"] = "invalid email.";
    SetIsValidEmail(true)
    // this.setState({ IsValidEmail: true });
    SetErrors(Errors)
    // this.setState({errors:Errors});
    return true;
  } else if (Username == ""){
    Errors["Username"] = "Please enter email.";
    SetIsValidEmail(true)
    // this.setState({ IsValidEmail: true });
    SetErrors(Errors)
  }else {
    Errors["Username"] = "";
    SetErrors(Errors)
    SetIsValidEmail(false)
    // this.setState({ IsValidEmail: false });

    return false;
  }
  }
  const handleChangeEmailcheckImport = () => {
    var UserEmail = document.getElementById("UserEmail").value.trim();
    let regexp =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  let Errors = {};
  if (!regexp.test(UserEmail) && UserEmail != "") {
    Errors["UserEmail"] = "invalid email.";
    SetIsValidEmail(true)
    // this.setState({ IsValidEmail: true });
    SetErrors(Errors)
    // this.setState({errors:Errors});
    return true;
  } else if (UserEmail == ""){
    Errors["UserEmail"] = "Please enter email.";
    SetIsValidEmail(true)
    // this.setState({ IsValidEmail: true });
    SetErrors(Errors)
  }else {
    Errors["UserEmail"] = "";
    SetErrors(Errors)
    SetIsValidEmail(false)
    // this.setState({ IsValidEmail: false });

    return false;
  }
  }

  // handle change contact tag delete
  const handleChangeContactOption = (val) => {
    var TagsArray = [];
    val?.map((item) => {
      TagsArray.push({
        ContactTagID: item.cid,
        TagName: item.label,
      });
    });

    setContactTagSelectArray(TagsArray);
  };

// from handle change sales development reps
const HandleChangeFromSaleDevlopMentReps = (e) => {
  const selectedValue = e.value;

  // Remove the selected value from the options in "To" dropdown
  var updatedOptions = ContactReassignData.filter(
    (option) => option.value !== selectedValue
  );

  // Update the options in "To" dropdown
  SetContactToReassignData(updatedOptions);
  if(ReassignFromSelectedValue &&  selectedValue === ReassignToSelectedValue.value){
    SetReassignToSelectedValue(e);
  }
  // Update the selected value in "From" dropdown
  SetReassignFromSelectedValue(e);
  
};
  

  // To handle change sales development reps
  const HandleChangeToSaleDevlopMentReps = (val) => {
    SetReassignToSelectedValue(val);
  };

  const HandleChangeToReassignSDR = (val) => {
    SetReassignSDRToSelectedValue(val);
  };

  // Reassign To SaleDevlopment
  // const ReassignToColdCallers = async() => {
  //   if( !ReassignFromSelectedValue.value){
  //     toast.error("Please select from sales development reps.")
  //   }else if (!ReassignToSelectedValue.value) {
  //     toast.error("Please select to sales development reps.");
  //   }else{
  //     SetIsLoading(true)
  //     if(IsApply === true){
  //       if(prospectAllSelectData.length > 0){
  //         var ReassignData = {
  //           ClientID:ClientID,
  //           UserID:UserID,
  //           ColdCallerFromId: ReassignFromSelectedValue.value,
  //           ColdCallerToId: ReassignToSelectedValue.value,
  //           LastUpdatedBy:CUserID,
  //           ContactFilterArray:prospectAllSelectData
  //         };
  //         Axios({
  //           url: CommonConstants.MOL_APIURL + "/contacts/ReassignToColdCallers",
  //           method: "POST",
  //           data: ReassignData,
  //         }).then((res) => {
  //           if (res.data.StatusMessage == "SUCCESS") {
  //             toast.success(
  //               <div className="toastsize">
  //                 Contacts
  //                 <br />
  //                 Sales development reps reassign successfully.
  //               </div>
  //             );
  //             ContactReassignDevPopupHideBtn();
  //             SetReassignToSelectedValue([])
  //             SetReassignToSelectedValue([])
  //             setProspectAllSelectData([])
  //             await ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
  //             await ContactListTotal(ClientID,UserID,Role,page,IsApply,FilterParameters);
  //             // document.getElementById("hideloding").style.display = "none";
  //             SetIsLoading(false)
  //           } else {
  //             toast.error(res.data.Message);
  //             setProspectAllSelectData([])
  //             // document.getElementById("hideloding").style.display = "none";
  //             SetIsLoading(false)
  //           }
  //         }).catch((error) => {
  //           toast.error("An error occurred. Please try again.");
  //           SetIsLoading(false);
  //         });
  //       }else{
  //         toast.error("Please select at least one contact from the list");
  //         return;
  //       }
  //     }else{
  //       var ReassignData = {
  //         ClientID:ClientID,
  //         UserID:UserID,
  //         ColdCallerFromId: ReassignFromSelectedValue.value,
  //         ColdCallerToId: ReassignToSelectedValue.value,
  //         LastUpdatedBy:CUserID,
  //         ContactFilterArray:[]
  //       };
  //       Axios({
  //         url: CommonConstants.MOL_APIURL + "/contacts/ReassignToColdCallers",
  //         method: "POST",
  //         data: ReassignData,
  //       }).then((res) => {
  //         if (res.data.StatusMessage == "SUCCESS") {
  //           toast.success(
  //             <div className="toastsize">
  //               Contacts
  //               <br />
  //               Sales development reps reassign successfully.
  //             </div>
  //           );
  //           ContactReassignDevPopupHideBtn();
  //           ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
  //           ContactListTotal(ClientID,UserID,Role,page,IsApply,FilterParameters);
  //           SetReassignToSelectedValue([])
  //           SetReassignToSelectedValue([])
  //           // document.getElementById("hideloding").style.display = "none";
  //           SetIsLoading(false)
  //         } else {
  //           toast.error(res.data.Message);
  //           // document.getElementById("hideloding").style.display = "none";
  //           SetIsLoading(false)
  //         }
  //       }).catch((error) => {
  //         toast.error("An error occurred. Please try again.");
  //         SetIsLoading(false);
  //       });
  //     }

  //   }


  // };
  // Change SDR
  const ReassignToColdCallers = async () => {
    if (!ReassignFromSelectedValue.value) {
      toast.error("Please select from Sales Development Reps.");
    } else if (!ReassignToSelectedValue.value) {
      toast.error("Please select to Sales Development Reps.");
    } else {
      SetIsLoading(true);
      try {
        if (IsApply === true) {
          if (prospectAllSelectData.length > 0) {
            const ReassignData = {
            ClientID:ClientID,
            UserID:UserID,
            ColdCallerFromId: ReassignFromSelectedValue.value,
            ColdCallerToId: ReassignToSelectedValue.value,
            LastUpdatedBy:CUserID,
            ContactFilterArray:prospectAllSelectData
            };
            const res = await Axios.post(
              CommonConstants.MOL_APIURL + "/contacts/ReassignToColdCallers",
              ReassignData
            );
  
            if (res.data.StatusMessage === "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Contacts
                  <br />
                  Sales Development Reps changed successfully.
                </div>
              );
              ContactReassignDevPopupHideBtn();
              SetReassignToSelectedValue([]);
              setProspectAllSelectData([]);
              document.getElementById("deleteall-btn").style.display = "none"
              document.getElementById("Select-All").style.display = "none"
              prospectAllSelectData.forEach((item) => {
                const trElement = document.getElementById(item?._id)?.closest('tr');
                if (trElement) {
                  trElement.classList.remove("highlight");
                }
              });
              // Await these calls to ensure data is fetched before hiding the loader
              await ContactListGet(ClientID, UserID, Role, page, IsApply, FilterParameters);
              await ContactListTotal(ClientID, UserID, Role, page, IsApply, FilterParameters);
            } else {
              toast.error(res.data.Message);
              setProspectAllSelectData([]);
            }
          } else {
            toast.error("Please select at least one contact from the list");
          }
        }else if(prospectAllSelectData.length > 0 ){
          const ReassignData = {
            ClientID:ClientID,
            UserID:UserID,
            ColdCallerFromId: ReassignFromSelectedValue.value,
            ColdCallerToId: ReassignToSelectedValue.value,
            LastUpdatedBy:CUserID,
            ContactFilterArray:prospectAllSelectData
            };
            const res = await Axios.post(
              CommonConstants.MOL_APIURL + "/contacts/ReassignToColdCallers",
              ReassignData
            );
  
            if (res.data.StatusMessage === "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Contacts
                  <br />
                  Sales Development Reps changed successfully.
                </div>
              );
              document.getElementById("deleteall-btn").style.display = "none"
              document.getElementById("Select-All").style.display = "none"
              ContactReassignDevPopupHideBtn();
              SetReassignToSelectedValue([]);
              setProspectAllSelectData([]);
              prospectAllSelectData.forEach((item) => {
                const trElement = document.getElementById(item?._id)?.closest('tr');
                if (trElement) {
                  trElement.classList.remove("highlight");
                }
              });
              // Await these calls to ensure data is fetched before hiding the loader
              await ContactListGet(ClientID, UserID, Role, page, IsApply, FilterParameters);
              await ContactListTotal(ClientID, UserID, Role, page, IsApply, FilterParameters);
            } else {
              toast.error(res.data.Message);
              setProspectAllSelectData([]);
            } 
        } else {
          const ReassignData = {
            ClientID:ClientID,
            UserID:UserID,
            ColdCallerFromId: ReassignFromSelectedValue.value,
            ColdCallerToId: ReassignToSelectedValue.value,
            LastUpdatedBy:CUserID,
            ContactFilterArray:[]
          };
          const res = await Axios.post(
            CommonConstants.MOL_APIURL + "/contacts/ReassignToColdCallers",
            ReassignData
          );
  
          if (res.data.StatusMessage === "SUCCESS") {
            toast.success(
              <div className="toastsize">
                Contacts
                <br />
                Sales Development Reps changed successfully.
              </div>
            );

            ContactReassignDevPopupHideBtn();
            await ContactListGet(ClientID, UserID, Role, page, IsApply, FilterParameters);
            await ContactListTotal(ClientID, UserID, Role, page, IsApply, FilterParameters);
            SetReassignToSelectedValue([]);
          } else {
            toast.error(res.data.Message);
          }
        }
      } catch (error) {
        console.log(error)
        // toast.error("An error occurred. Please try again.");
      } finally {
        SetIsLoading(false);
      }
    }
  };

  //Reassign Sales Reassign Dev
  const ReassignToSDR = async () => {
    if (!ReassignSDRToSelectedValue.value) {
      toast.error("Please select at least one SDR to Reassign it");
    } else {
      SetIsLoading(true);
      try {
        if (IsApply === true) {
          if (prospectAllSelectData.length > 0) {
            const ReassignData = {
            ClientID:ClientID,
            UserID:UserID,
            ColdCallerToId: ReassignSDRToSelectedValue.value,
            LastUpdatedBy:CUserID,
            ContactFilterArray:prospectAllSelectData
            };
            const res = await Axios.post(
              CommonConstants.MOL_APIURL + "/contacts/ReassignToSDR",
              ReassignData
            );
  
            if (res.data.StatusMessage === "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Contacts
                  <br />
                  Sales Development Reps Reassigned successfully.
                </div>
              );
              ContactReassignSDRPopupHideBtn();
              SetReassignToSelectedValue([]);
              setProspectAllSelectData([]);
              SetShowPopupReassignSDR(false)
              document.getElementById("deleteall-btn").style.display = "none"
              document.getElementById("Select-All").style.display = "none"
              prospectAllSelectData.forEach((item) => {
                const trElement = document.getElementById(item?._id)?.closest('tr');
                if (trElement) {
                  trElement.classList.remove("highlight");
                }
              });
              // Await these calls to ensure data is fetched before hiding the loader
              await ContactListGet(ClientID, UserID, Role, page, IsApply, FilterParameters);
              await ContactListTotal(ClientID, UserID, Role, page, IsApply, FilterParameters);
            } else {
              toast.error(res.data.Message);
              setProspectAllSelectData([]);
            }
          } else {
            toast.error("Please select at least one contact from the list");
          }
        }else if(prospectAllSelectData.length > 0 ){
          const ReassignData = {
            ClientID:ClientID,
            UserID:UserID,
            ColdCallerToId: ReassignSDRToSelectedValue.value,
            LastUpdatedBy:CUserID,
            ContactFilterArray:prospectAllSelectData
            };
            const res = await Axios.post(
              CommonConstants.MOL_APIURL + "/contacts/ReassignToSDR",
              ReassignData
            );
  
            if (res.data.StatusMessage === "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Contacts
                  <br />
                  Sales Development Reps Reassigned successfully.
                </div>
              );
              document.getElementById("deleteall-btn").style.display = "none"
              document.getElementById("Select-All").style.display = "none"
              ContactReassignSDRPopupHideBtn()
              SetReassignSDRToSelectedValue([]);
              setProspectAllSelectData([]);
              prospectAllSelectData.forEach((item) => {
                const trElement = document.getElementById(item?._id)?.closest('tr');
                if (trElement) {
                  trElement.classList.remove("highlight");
                }
              });
              // Await these calls to ensure data is fetched before hiding the loader
              await ContactListGet(ClientID, UserID, Role, page, IsApply, FilterParameters);
              await ContactListTotal(ClientID, UserID, Role, page, IsApply, FilterParameters);
            } else {
              toast.error(res.data.Message);
              setProspectAllSelectData([]);
            } 
        }else{
          toast.error("Please select at least one contact from the list");
        }
      } catch (error) {
        console.log(error)
    
      } finally {
        SetIsLoading(false);
      }
    }
  };
  

  // delete multiple contact tag
  const DeleteSelectContactTag = () => {
    if (ContactTagSelectArray.length == 0) {
      toast.error(<div className="toastsize">Please Select Tag.</div>);
    } else {

      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete the selected tag.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No,cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          
          SetIsLoading(true)
          var ContactTagData = {
            ClientID: ClientID,
            ContactArrays: ContactTagSelectArray,
            LastUpdatedBy: CUserID,
            LastUpdatedDate: new Date(),
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/contacts/ContactTagDeleteToPropspect",
            method: "POST",
            data: ContactTagData,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                ContactTagSelectArray.forEach((item) => {
                  const trElement = document.getElementById(item?._id)?.closest('tr');
                  if (trElement) {
                    trElement.classList.remove("highlight");
                  }
                });
                SetDeleteTagModel(false);
                Swal.fire("Deleted!", "Tag deleted successfully.", "success");
                document.getElementById("deleteall-btn").style.display = "none"
                setContactTagSelectArray([]);
                ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
                ContactListTotal(ClientID,UserID,Role,page,IsApply,FilterParameters);
                setProspectAllSelectData([])
                Axios({
                  url: CommonConstants.MOL_APIURL + "/contacts/GetContactTags",
                  method: "POST",
                  data: { ClientID: ClientID, UserID: UserID },
                }).then((res) => {
                  SetContactTags(res.data.Data);
                }).catch((error) => {
                  toast.error("An error occurred. Please try again.");
                  SetIsLoading(false);
                });
                document.getElementById("main-check").checked = false
                SetIsLoading(false)
              } else {
                ContactTagSelectArray.forEach((item) => {
                  const trElement = document.getElementById(item?._id)?.closest('tr');
                  if (trElement) {
                    trElement.classList.remove("highlight");
                  }
                });
                document.getElementById("deleteall-btn").style.display = "none"
     
                ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
                ContactListTotal(ClientID,UserID,Role,page,IsApply,FilterParameters);
                setContactTagSelectArray([]);
                 Axios({
                  url: CommonConstants.MOL_APIURL + "/contacts/GetContactTags",
                  method: "POST",
                  data: { ClientID: ClientID, UserID: UserID },
                }).then((res) => {
                  SetContactTags(res.data.Data);
                }).catch((error) => {
                  toast.error("An error occurred. Please try again.");
                  SetIsLoading(false);
                });
                SetIsLoading(false)
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
          // document.getElementById("hideloding").style.display = "none";
          SetIsLoading(false)
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false)
      });
    }
  };


  // Get All Contact Info Data
  const ContactInfoGetData = (CID, UID, Role) => {
    
    var InputParaMeter = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactDetailsGet",
      method: "POST",
      data: InputParaMeter,
    });
    rows.then((result) => {
      setContactSalesDevlopMenData(result.data?.ContactSalesDevlopMenData);
      // setContactCustomFieldData(result.data?.ContactCustomFieldData);
      const options = result.data?.ContactsReassignData?.map((val) => {
        return { value: val._id, label: val.FullName
        };
      });
      SetContactReassignData(options);
      SetCampaignData(result.data?.CampaignGetData);
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      // SetIsLoading(false);
    });
  };

  // Move to campaign list
  const CampaignListMove = () => {
  document.getElementById("Show_csv_details").style.display = "none"

    if (prospectAllSelectData.length == 0) {
      toast.error(
        <div className="toastsize">
          Move to Campaign
          <br />
          Please select contact.
        </div>
      );
    } else {
      SetShowPopupCampaign(true);
    }
  };

  // move to campaign list
  const CampaignToList = () => {
    let ValidationFilterArray = prospectAllSelectData?.filter((item) => 
      item.ValidationScore !== "F" && item.ValidationScore !== "D" && item.ValidationScore 
  );
  if(!selectedCampaign){
    toast.error(<div className="toastsize">Move Contact<br /> Please Select Campaign.</div>);
  }else{
  Swal.fire({
    title: "Are you sure?",
    text: "You want to move to campaign.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#34bfa3",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, move it!",
  }).then((result) => {
    if (result.isConfirmed) {
      SetIsLoading(true)
      var CampaignData = {
        ClientID: ClientID,
        UserID: UserID,
        CampaignID: selectedCampaign.value,
        CampaignMoveArray: ValidationFilterArray,
        CreatedDate: new Date(),
        CreatedBy: CUserID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignMove",
        method: "POST",
        data: CampaignData,
      }).then((res) => {
        if (res) {
          if ((res.statusText = "Ok")) {
            Swal.fire(
              "Moved!",
              "Contact move campaign successfully.",
              "success"
            );
            SetShowPopupCampaign(false);
            SetIsLoading(false)
            UnSelectCheckHandler();
            setSelectedCampaign(null)
          } else {
          }
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled", "Your data is safe :)", "error");
    }
  }).catch((error) => {
    toast.error("An error occurred. Please try again.");
    SetIsLoading(false);
  });

}
  };

  //  multiple delete for function
  const DeleteSelectedAll = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a contact.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No, cancel!",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsLoading(true)
        var ContactData = {
          ContactDeleteArray: prospectAllSelectData,
          LastUpdatedBy: CUserID,
          ClientID:ClientID,
          UserID:UserID
        };

        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactDeleteMultiple",
          method: "POST",
          data: ContactData,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Contact  deleted successfully.",
                "success"
              );
              ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
              ContactListTotal(ClientID,UserID,Role,page,IsApply,FilterParameters);
              document.getElementById("main-check").checked = false
              document.getElementById("deleteall-btn").style.display = "none"
              document.getElementById("Show_csv_details").style.display = "none"

              
              document.getElementById("Select-All").style.display = "block"
              document.getElementById("UnSelect").style.display = "none" 
              setProspectAllSelectData([])    
              SetIsLoading(false)
            } else {
            }
          }
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          document.getElementById("main-check").checked = false
          document.getElementById("deleteall-btn").style.display = "none"
          document.getElementById("Select-All").style.display = "block"
          document.getElementById("UnSelect").style.display = "none"  
          setProspectAllSelectData([])  
          SetIsLoading(false);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        document.getElementById("main-check").checked = false
        document.getElementById("deleteall-btn").style.display = "none"
        document.getElementById("Select-All").style.display = "block"
        document.getElementById("UnSelect").style.display = "none"  
        setProspectAllSelectData([])  
        SetIsLoading(false)
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      document.getElementById("main-check").checked = false
      document.getElementById("deleteall-btn").style.display = "none"
      document.getElementById("Select-All").style.display = "block"
      document.getElementById("UnSelect").style.display = "none"  
      setProspectAllSelectData([])  
      SetIsLoading(false);
    });
  };

  // contact add to blacklist domain
  const ContactAddToBlackList = () => {
  document.getElementById("Show_csv_details").style.display = "none"

    if (prospectAllSelectData.length == 0) {
      toast.error(
        <div className="toastsize">
          Please select at least one contacts from list.
        </div>
      );
      SetIsLoading(false)
    } else {
      SetIsLoading(true)
      var ContactData = {
        ClientID: ClientID,
        UserID:UserID,
        LastUpdatedBy: CUserID,
        ContactBlackListArray: prospectAllSelectData,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactToAddToBlackList",
        method: "POST",
        data: ContactData,
      }).then((res) => {
        if (res) {
          if ((res.data.StatusMessage == "SUCCESS")) {
            Swal.fire(
              "Blacklisted!",
              "Selected contacts blacklisted successfully.",
              "success"
            );
            setProspectAllSelectData([])
            ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
            ContactListTotal(ClientID,UserID,Role,page,IsApply,FilterParameters);
            UnSelectCheckHandler()
            SetIsLoading(false)
          } else if(res.data.StatusMessage == "EXITSBLACKLIST") {
            toast.error(<div dangerouslySetInnerHTML={{ __html: res.data.Message }} />)
            ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
            ContactListTotal(ClientID,UserID,Role,page,IsApply,FilterParameters);
            UnSelectCheckHandler()
            SetIsLoading(false)
          }else if(res.data.StatusMessage == "NotExitsEmail"){
            toast.error(<div dangerouslySetInnerHTML={{ __html: res.data.Message }} />)
            ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
            ContactListTotal(ClientID,UserID,Role,page,IsApply,FilterParameters);
            UnSelectCheckHandler()
            SetIsLoading(false)
          }else{
            toast.error("Something Went Wrong !")
            ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
            ContactListTotal(ClientID,UserID,Role,page,IsApply,FilterParameters);
            UnSelectCheckHandler()
            SetIsLoading(false)
          }
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    }
  };

  // changes row selected
  const ChangeRowSelected = async (event) => {
    SetIsLoading(true)
    CloseCsvDetails()
    if(IsApply === true){
      
      const NewRowPerPage = Number(event.target.value)
      setfilterPage(1)
      SefiltertRowsPerPage(Number(event.target.value));

      // new getlist data 
      var listids = null;
      if (location.state && location.state.data) {
        listids = location.state.data;
      }

      var str_in_1 = {
        Page: 1,
        RowsPerPage: NewRowPerPage,
        Sort: true,
        Field: sortField,
        SortBy: sortedBy,
        Search: filterSearchParams,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        AccountType: AccountTypeForTab,
        IsApply: true,
        FilterPropertiesArray: FilterParameters,
        IsTotalCount: TotalCountFlag,
        Role: Role,
        ListID: listids
      };
  
      var str_in_2 = {
        Page: 1,
        RowsPerPage: NewRowPerPage,
        Sort: true,
        Field: sortField,
        SortBy: sortedBy,
        Search: filterSearchParams,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        AccountType: AccountTypeForTab,
        IsApply: true,
        FilterPropertiesArray: FilterParameters,
        Role: Role,
        IsTotalCount: !TotalCountFlag,
        ListID: listids
      };
  
      try {
        const [result1, result2] = await Promise.all([
          Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
            method: "POST",
            data: str_in_1,
          }),
          Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
            method: "POST",
            data: str_in_2,
          })
        ]);
  
        if (result1.data?.StatusMessage === "SUCCESS") {
          setdata(result1.data?.PageData);
          setRows(result1.data?.PageData);
          SetTableRowLength(result1.data?.PageData?.length);
        } else {
          const errorMessage = result1.data?.Message || "Unknown error";
          toast.error(errorMessage);
        }
  
        if (result2.data?.StatusMessage === "SUCCESS") {
          setfilterrlen(result2.data?.TotalCount);
          setfilterflen(result2.data?.TotalCount);
          setfilterCountPage(result2.data?.PageCount);
        } else {
          const errorMessage = result2.data?.Message || "Unknown error";
          toast.error(errorMessage);
        }
  
      } catch (error) {
        toast.error(`Error: ${error?.message}`);
      } finally {
        SetIsLoading(false);
      }

    }else{
    SetIsLoading(true)
    setPage(1);
    SetRowsPerPage(Number(event.target.value));
  }
  };

  const CloseCsvDetails = () => {
    // Hide the element with id "Show_csv_details"
    const csvDetailsElement = document.getElementById("Show_csv_details");
    if (csvDetailsElement) {
      csvDetailsElement.style.display = "none";
    }
  }
  // search details
  const RequestSearch = async (event) => {
    
    if(IsApply === true){
      if (event.key === "Enter") {
        CloseCsvDetails()
        var searchedValFilter = document.getElementById("search").value;
        setfilterPage(1)
        setfilterSearchParams(searchedValFilter);
        // new getlist data 
        var listids = null;
        if (location.state && location.state.data) {
          listids = location.state.data;
        }
  
        var str_in_1 = {
          Page: 1,
          RowsPerPage: 100,
          Sort: true,
          Field: sortField,
          SortBy: sortedBy,
          Search: searchedValFilter,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          AccountType: AccountTypeForTab,
          IsApply: true,
          FilterPropertiesArray: FilterParameters,
          IsTotalCount: TotalCountFlag,
          Role: Role,
          ListID: listids
        };
    
        var str_in_2 = {
          Page: 1,
          RowsPerPage: 100,
          Sort: true,
          Field: sortField,
          SortBy: sortedBy,
          Search: searchedValFilter,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          AccountType: AccountTypeForTab,
          IsApply: true,
          FilterPropertiesArray: FilterParameters,
          Role: Role,
          IsTotalCount: !TotalCountFlag,
          ListID: listids
        };
    
        try {
          const [result1, result2] = await Promise.all([
            Axios({
              url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
              method: "POST",
              data: str_in_1,
            }),
            Axios({
              url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
              method: "POST",
              data: str_in_2,
            })
          ]);
    
          if (result1.data?.StatusMessage === "SUCCESS") {
            setdata(result1.data?.PageData);
            setRows(result1.data?.PageData);
            SetTableRowLength(result1.data?.PageData?.length);
            setProspectAllSelectData([]);
            document.getElementById("main-check").checked = false;
            document.getElementById("deleteall-btn").style.display = "none"
            document.getElementById("Select-All").style.display = "block"
            document.getElementById("UnSelect").style.display = "none"  
          } else {
            const errorMessage = result1.data?.Message || "Unknown error";
            toast.error(errorMessage);
          }
    
          if (result2.data?.StatusMessage === "SUCCESS") {
            setfilterrlen(result2.data?.TotalCount);
            setfilterflen(result2.data?.TotalCount);
            setfilterCountPage(result2.data?.PageCount);
          } else {
            const errorMessage = result2.data?.Message || "Unknown error";
            toast.error(errorMessage);
          }
    
        } catch (error) {
          toast.error(`Error: ${error?.message}`);
        } finally {
          SetIsLoading(false);
        }
      }
     
    }else{
      if (event.key === "Enter") {
        CloseCsvDetails()
        var searchedVal = document.getElementById("search").value;
        setSearch(searchedVal);
        setPage(1); 
        SetIsLoading(false);
        setProspectAllSelectData([]);
        document.getElementById("main-check").checked = false;
        document.getElementById("deleteall-btn").style.display = "none"
        document.getElementById("Select-All").style.display = "block"
        document.getElementById("UnSelect").style.display = "none"
      }
    }

  };

  // change page
  const HandleChangePage = async (Event, NewPage) => {
    SetIsLoading(true)
    CloseCsvDetails()
    document.body.scrollTop = 0;
    if(IsApply=== true){
      setfilterPage(NewPage);
      const result = await ContactListGet(ClientID,UserID,Role,NewPage,true,FilterParameters);
      if (result !== null) {
          const PerPageCheckCount = result.data?.PageData?.filter((item) =>
              prospectAllSelectData.some((paitem) => item._id === paitem._id)
          );
          const finalLengthforperpage = result.data?.PageData.length;
          const Pagecheckcount = PerPageCheckCount.length
          if(finalLengthforperpage == Pagecheckcount){
            document.getElementById("main-check").checked = true;
          }else{
            document.getElementById("main-check").checked = false;
          }
      }

      SetIsLoading(false)
    }else if(NewPage == page){
      SetIsLoading(true)
      setPage(NewPage);
      SetIsLoading(false)
    }else{

      const result = await ContactListGet(ClientID,UserID,Role,NewPage,IsApply,FilterParameters);
      if (result !== null) {
          const PerPageCheckCount = result.data?.PageData?.filter((item) =>
              prospectAllSelectData.some((paitem) => item._id === paitem._id)
          );
          const finalLengthforperpage = result.data?.PageData.length;
          const Pagecheckcount = PerPageCheckCount.length
          if(finalLengthforperpage == Pagecheckcount){
            document.getElementById("main-check").checked = true;
          }else{
            document.getElementById("main-check").checked = false;
          }
      }
      setPage(NewPage);
      SetIsLoading(false)
    }
  };
  const ContactCustomHeaderDataGet = async (CID, UID, Role) => {
    SetIsLoading(true);
    var InputParaMeter = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
  
    return Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactCustomHeaderData",
      method: "POST",
      data: InputParaMeter,
    })
      .then((result) => {
        if (result.data?.StatusMessage === "SUCCESS") {
          SetContactHeader(result.data?.Data);
          return result; // Return the result to be used in Promise.all
        } else {
          const errorMessage =
            result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
            toast.error(errorMessage);
        }
      })
      .catch((error) => {
        // Handle the error that occurred during the API request
  
        // Display error message using toast.error
        toast.error(`Error: ${error?.message}`);
  
        // You can also set an error state or show an error message to the user
        // For example: SetErrorState(true);
  
        throw error; // Rethrow the error to be caught by the caller
      });
  };

  // contact get
  const ContactListGet = async (CID, UID, Role,currentPage,isApplyfiterparam,fiterpropertiesparam) => {
    try {
      if(isApplyfiterparam === true){
        var listids = null;
        if (location.state && location.state.data) {
          listids = location.state.data;
        }
        SetIsLoading(true)
        var str_in = {
          Page: currentPage,
          RowsPerPage:filterRowsPerPage,
          Sort: true,
          Field: sortField,
          SortBy: sortedBy,
          Search: filterSearchParams,
          Type: "User",
          ClientID: CID,
          UserID: UID,
          AccountType: AccountTypeForTab,
          IsApply: isApplyfiterparam,
          FilterPropertiesArray: fiterpropertiesparam,
          IsTotalCount:TotalCountFlag,
          Role:Role,
          ListID:listids
        };
    
        return Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
          method: "POST",
          data: str_in,
        }).then((result) => {
          
          if(result.data?.StatusMessage === "SUCCESS"){
       
              setdata(result.data?.PageData);
              setRows(result.data?.PageData);
              SetTableRowLength(result.data?.PageData?.length)
              return result; // Return the result to be used in Promise.all
  
          }else{
            const errorMessage =
            result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
            toast.error(errorMessage);
          }
       
        }).catch((error) => {
          // Handle the error that occurred during the API request
    
          // Display error message using toast.error
          toast.error(`Error: ${error?.message}`);
    
          // You can also set an error state or show an error message to the user
          // For example: SetErrorState(true);
    
          throw error; // Rethrow the error to be caught by the caller
        });
      }else{
        var listids = null;
        if (location.state && location.state.data) {
          listids = location.state.data;
        }
        // SetIsLoading(true)
        var str_in = {
          Page: currentPage,
          RowsPerPage: RowsPerPage,
          Sort: true,
          Field: sortField,
          SortBy: sortedBy,
          Search: search,
          Type: "User",
          ClientID: CID,
          UserID: UID,
          AccountType: AccountTypeForTab,
          IsApply: isApplyfiterparam,
          FilterPropertiesArray: fiterpropertiesparam,
          IsTotalCount:TotalCountFlag,
          Role:Role,
          ListID:listids
        };
    
        return Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
          method: "POST",
          data: str_in,
        }).then((result) => {
          
          if(result.data?.StatusMessage === "SUCCESS"){
       
              setdata(result.data?.PageData);
              setRows(result.data?.PageData);
              SetTableRowLength(result.data?.PageData?.length)
              return result; // Return the result to be used in Promise.all
  
          }else{
            const errorMessage =
            result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
            toast.error(errorMessage);
          }
       
        }).catch((error) => {
          // Handle the error that occurred during the API request
    
          // Display error message using toast.error
          toast.error(`Error: ${error?.message}`);
    
          // You can also set an error state or show an error message to the user
          // For example: SetErrorState(true);
    
          throw error; // Rethrow the error to be caught by the caller
        });
      }
    
    } catch (error) {
      toast.error(`Error: ${error?.message}`);
  
    }
 
  };
  const ContactListTotal = async (CID, UID, Role,cpage,isFilterApplyFlag,FilterParams) => {
 try{
  if(isFilterApplyFlag === true){
    var listids = null;
    if (location.state && location.state.data) {
      listids = location.state.data;
    }
    SetIsLoading(true)
    var str_in = {
      Page: cpage,
      RowsPerPage: filterRowsPerPage,
      Sort: true,
      Field: sortField,
      SortBy: sortedBy,
      Search: filterSearchParams,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      AccountType: AccountTypeForTab,
      IsApply: isFilterApplyFlag,
      FilterPropertiesArray: FilterParams,
      Role: Role,
      IsTotalCount:!TotalCountFlag,
      ListID:listids
    };
    return Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
      method: "POST",
      data: str_in,
    }).then((result) => {
      if(result.data?.StatusMessage === "SUCCESS"){
        setfilterrlen(result.data?.TotalCount);
        setfilterflen(result.data?.TotalCount);
        setfilterCountPage(result.data?.PageCount);
        return result; // Return the result to be used in Promise.all
      }else{
        const errorMessage =
        result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
        toast.error(errorMessage);
      }
    }).catch((error) => {
      // Display error message using toast.error
      toast.error(`Error: ${error?.message}`);
  
      throw error; 
    });
  }else{
    var listids = null;
    if (location.state && location.state.data) {
      listids = location.state.data;
    }
    // SetIsLoading(true)
    var str_in = {
      Page: cpage,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: sortField,
      SortBy: sortedBy,
      Search: search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      AccountType: AccountTypeForTab,
      IsApply: isFilterApplyFlag,
      FilterPropertiesArray: FilterParams,
      Role: Role,
      IsTotalCount:!TotalCountFlag,
      ListID:listids
    };
  
    return Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
      method: "POST",
      data: str_in,
    }).then((result) => {
      if(result.data?.StatusMessage === "SUCCESS"){
        setrlen(result.data?.TotalCount);
        setflen(result.data?.TotalCount);
        setCountPage(result.data?.PageCount);
        setIsDataLoaded(true);
        return result; // Return the result to be used in Promise.all
      }else{
        const errorMessage =
        result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
        toast.error(errorMessage);
        setIsDataLoaded(true);
      }
    }).catch((error) => {
      // Display error message using toast.error
      toast.error(`Error: ${error?.message}`);
  
      throw error; 
    });
  }

 }catch{
  toast.error(`Error: ${error?.message}`);
 }
  };

  // contact get after filter apply
    // const ContactListGetFilter = (CID, UID, Role,filter,isApply,pageforfilter,rowperpagefilter,searchparams) => {
    //   SetIsLoading(true)
    //   var listids = null;
    //   if (location.state && location.state.data) {
    //     listids = location.state.data;
    //   }

    //   var str_in = {
    //     Page: pageforfilter,
    //     RowsPerPage: rowperpagefilter,
    //     Sort: true,
    //     Field: sortField,
    //     SortBy: sortedBy,
    //     Search: searchparams,
    //     Type: "User",
    //     ClientID: CID,
    //     UserID: UID,
    //     AccountType: AccountTypeForTab,
    //     IsApply: isApply,
    //     FilterPropertiesArray: filter,
    //     Role: Role,
    //     IsTotalCount:TotalCountFlag,
    //     ListID:listids
    //   };
  
    //   const rows1 = Axios({
    //     url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
    //     method: "POST",
    //     data: str_in,
    //   });
    //   rows1.then((result) => {
    //     setdata(result.data?.PageData);
    //     setRows(result.data?.PageData);
    //     SetTableRowLength(result.data?.PageData?.length)

    //     setrlen(result.data?.TotalCount);
    //     setflen(result.data?.TotalCount);
    //     setCountPage(result.data?.PageCount);
    //     SetIsLoading(false)
        
    //   }).catch((error) => {
    //     toast.error("An error occurred. Please try again.");
    //     SetIsLoading(false);
    //   });

    // };
  // contact btn
  const DeleteBtn = (id) => {
    CloseCsvDetails();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a contact.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No, cancel!",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsLoading(true)
        var data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          CampaignID:null,
          ClientID:ClientID,
          UserID:UserID
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Contact  deleted successfully.",
                "success"
              );
              ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters);
              ContactListTotal(ClientID, UserID,Role,page,IsApply,FilterParameters);
              SetIsLoading(false)
            } else {
            }
          }
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        }).finally(() => {
          // Always hide loader, regardless of success or failure
          SetIsLoading(false);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };

  const ViewBtn = (id, emailid) => {
    history.push({
      pathname: "/cviewcontacts",
      state: { data: id, EmailData: emailid },
    });
  };
  const ViewAccountPage = (id) => {
    history.push({
      pathname: "/cviewaccounts",
      state: { data: id, PageName:"MainContactPage" },
    });
  };

  const ContactAddTagBtn = () => {
    document.getElementById("Show_csv_details").style.display = "none";
    if (prospectAllSelectData.length == 0) {
      toast.error(
        <div className="toastsize">
          Please select at least one contacts from list.
        </div>
      );
    } else {
      SetShowPopup(true);
    }
  }
  // import csv
  const ContactImportCsv = async (e) => {

    var InputParams = {
      ClientID: ClientID,
      Role:Role
    };
    const res = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CheckContactsPerClientLimit",
      method: "POST",
      data: InputParams,
    });
    if (res.data.StatusMessage === "SUCCESS") {
      if(res.data.Data.IsExhaustLimit === true){
        toast.error(<div className="toastsize">Contact <br/>You have reached your limit for contacts.</div>)
      }else{
        document.getElementById("Show_csv_details").style.display = "none"
        e.preventDefault();
        document.getElementById("import_csv").style.display = "block";
        document.getElementById("delete_csv").style.display = "none"
      }
    } else {
      // toast.error(res.data.Message);
      console.log(res.data.Message)
    }
    // document.getElementById("Show_csv_details").style.display = "none"
    // e.preventDefault();
    // document.getElementById("import_csv").style.display = "block";
    // document.getElementById("delete_csv").style.display = "none"
  };


  // reset function
  const ResetCsv = () => {
    SetReset(true);
  };
  // Import Csv reset
  const ImportResetCsv = () => {
    SetImportCsvReset(true);
  };
  // handle on drop
  const ImprotCsvHandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
   
    setfiles(fileInfo);
  
    var sep = filename.split(".");
    SetCsvFileName(sep.slice(0, -1).join("."))
    if (sep[sep.length-1] != "csv") {
        toast.error( <div className="toastsize">
          Saleshive contacts  <br />
         Invalid file uploaded
        </div>)
      ImportResetCsv()
    } else {
      var csvfile = sep.slice(0, -1).join(".") + new Date().getTime() + "." + sep[sep.length-1];
      var csvfilenumber = new Date().getTime() + "." + sep[sep.length-1];
      setfilename(csvfile);
      setfilenameonlynumber(csvfilenumber);
      
      // setcsvData(data);
      const filteredData = data.filter(
        (row) => !row.data.every((item) => item.trim() === "")
      );
    setcsvData2(filteredData)
    SetDropboxData(filteredData[0].data);
    }
  };
  const HandleOnDrop = (data, fileInfo) => {
    
    var filename = fileInfo.name;
  

    setfiles(fileInfo);
    var sep = filename.split(".");
    SetCsvFileName(sep.slice(0, -1).join("."))
    
    if (sep[sep.length-1] != "csv") {
      toast.error( <div className="toastsize">
      Saleshive contacts  <br />
     Invalid file uploaded
    </div>)
SetResetBulkDelete(!ResetBulkDelete)

    } else {
      
      var csvfile = sep.slice(0, -1).join(".") + new Date().getTime() + "." + sep[sep.length-1];

      var csvfilenumber = new Date().getTime() + "." + sep[sep.length-1];
      setfilename(csvfile);
      setfilenameonlynumber(csvfilenumber);
      setcsvData(data);
      SetDropboxData(data[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  // map csv
  const ContactMapCsv = async () => {
    if (csvData2.length != 0) {
        var TotalCsvCount = csvData2.filter((item) => item.data?.filter((element) =>  element?.trim().length > 0  ).length > 0).length - 1;
        var LimitTotalContactsPerClientVar, CurrentTotalContactsPerClientVar
        var InputParams = {
          ClientID: ClientID,
        };
    
        const res = await Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ClientsDetailsGet",
          method: "POST",
          data: InputParams,
        });
    
        if (res.data.StatusMessage === "SUCCESS") {
          LimitTotalContactsPerClientVar = (res.data.ClientData[0]?.LimitTotalContactsPerClient !== undefined && res.data.ClientData[0]?.LimitTotalContactsPerClient !== null) ? res.data.ClientData[0]?.LimitTotalContactsPerClient : parseInt(Client_Limits.LIMIT_TOTAL_CONCTACTS_PER_CLIENT);
          CurrentTotalContactsPerClientVar = res.data.ClientData[0].CurrentTotalContactsPerClient;
        } else {
          console.log(res.data.Message)
        }

        // check limit condition for contacts
        if(LimitTotalContactsPerClientVar !== -1 && CurrentTotalContactsPerClientVar < LimitTotalContactsPerClientVar){
          if(LimitTotalContactsPerClientVar !== -1 && (TotalCsvCount + CurrentTotalContactsPerClientVar > LimitTotalContactsPerClientVar)){
            toast.error(<div className="toastsize">Contact <br/>Please upgrade your limits or update your CSV to comply with the remain limit.</div>)
          }else{
            setUploadflag(!uploadflag);
            document.getElementById("map_attribute").style.display = "block";
            DropboxData.map((dddd, index) => {});
            SetImportCsvReset(!ImportCsvReset)   
          }
        }else if (LimitTotalContactsPerClientVar !== -1 && (CurrentTotalContactsPerClientVar >= LimitTotalContactsPerClientVar)){
          toast.error(<div className="toastsize">Contact <br/>You have reached your limit for contacts.</div>)
        }else{
        setUploadflag(!uploadflag);
        document.getElementById("map_attribute").style.display = "block";
        DropboxData.map((dddd, index) => {});
        SetImportCsvReset(!ImportCsvReset) 
      }
 
  } else {
    toast.error("Please select a file.")
    SetImportCsvReset(!ImportCsvReset) 
  }
  hideCSVHeaderOptions(); 
  };

    // map csv
    const BulkDeleteContactBtn = () => {
      if (csvData.length !== 0) {
        SetResetBulkDelete(!ResetBulkDelete);
        document.getElementById("map_attribute_domain_blacklist").style.display = "block";
      } else {
        toast.error("Please select a file.")
        SetResetBulkDelete(!ResetBulkDelete);
      }
      hideCSVHeaderOptions();
    };
    
    

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  const handleSelectChangeImportMapping = (e) => {
    const { value, id: Name } = e.target;
    if (Name == "First Name") {
      SetFirstName(value);
    } else if (Name == "Last Name") {
      SetLastName(value);
    } else if (Name == "Email") {
      SetEmail(value);
    } else if (Name == "Title") {
      SetTitle(value);
    } else if (Name == "Company") {
      SetCompany(value);
    } else if (Name == "CompanyNameLong") {
      SetCompanyNameLong(value);
    } else if (Name == "Website") {
      SetWebsite(value);
    } else if (Name == "CompanyPhone") {
      SetCompanyPhone(value);
    } else if (Name == "DirectPhone") {
      SetDirectPhone(value);
    } else if (Name == "DirectPhoneExt") {
      SetDirectPhoneExt(value);
    } else if (Name == "LinkedInURL") {
      SetLinkedInURL(value);
    } else if (Name == "Industry") {
      SetIndustry(value);
    } else if (Name == "Address1") {
      SetAddress1(value);
    } else if (Name == "Address2") {
      SetAddress2(value);
    } else if (Name == "City") {
      SetCity(value);
    } else if (Name == "State") {
      SetState(value);
    } else if (Name == "Zip") {
      SetZip(value);
    } else if (Name == "Country") {
      SetCountry(value);
    } else if (Name == "ContactCategory") {
      SetContactCategory(value);
    } else if (Name == "AccountCategory") {
      SetAccountCategory(value);
    } else if (Name == "ValidationScore") {
      SetValidationScore(value);
    } else if (Name == "ListName") {
      SetListName(value);
    } else if (Name == "ContactSource") {
      SetContactSource(value);
    } else if (Name == "ContactOwner") {
      SetContactOwner(value);
    } else if (Name == "ClientPOC") {
      SetClientPOC(value);
    } else if (Name == "ReasonUnqualified") {
      SetReasonUnqualified(value);
    } else if (Name == "AccountType") {
      SetAccountType(value);
    } else if (Name == "CompanyRevenue") {
      SetCompanyRevenue(value);
    } else if (Name == "EmployeeCount") {
      setEmployeeCount(value);
    } else if (Name == "IsDoNotCallContact") {
      SetIsDoNotCallContact(value);
    } else if (Name == "SalesRep") {
      SetSalesRep(value);
    } else if (Name == "ColdCaller") {
      SetColdCaller(value);
    } else if (Name == "Tag") {
      SetTag(value);
    } else if (Name == "CallNotes") {
      SetCallNotes(value);
    } else if (Name == "OtherPhone1") {
      SetOtherPhone1(value);
    } else if (Name == "OtherPhone2") {
      SetOtherPhone2(value);
    } else if (Name == "ContactID") {
      SetContactID(value);
    }else if (Name == "ZoomID") {
      SetZoomID(value);
    }else if (Name == "BestPhone") {
      SetBestPhone(value);
    }else if (Name == "BestPhoneStatus") {
      SetBestPhoneStatus(value);
    }else if (Name == "Phone") {
      SetPhone(value);
    }else if (Name == "PhoneStatus") {
      SetPhoneStatus(value);
    }else if (Name == "Phone2") {
      SetPhone2(value);
    }else if (Name == "Phone2Status") {
      SetPhone2Status(value);
    }else if (Name == "Phone3") {
      SetPhone3(value);
    }else if (Name == "Phone3Status") {
      SetPhone3Status(value);
    }else if (Name == "Phone4") {
      SetPhone4(value);
    }else if (Name == "Phone4Status") {
      SetPhone4Status(value);
    }else if (Name == "PRLLeadID") {

      SetPRLLeadID(value);
    }
    hideCSVHeaderOptions();
  }

  // $("select").on("change", function () {
  //   var $this = $(this);
  //   var value = $this.val();
  //   console.log(value,"value123")
  //   var Name = $(this).attr("id");
  //   console.log(Name,"Name123")

  //   if (Name == "First Name") {
  //     SetFirstName(value);
  //   } else if (Name == "Last Name") {
  //     SetLastName(value);
  //   } else if (Name == "Email") {
  //     SetEmail(value);
  //   } else if (Name == "Title") {
  //     SetTitle(value);
  //   } else if (Name == "Company") {
  //     SetCompany(value);
  //   } else if (Name == "CompanyNameLong") {
  //     SetCompanyNameLong(value);
  //   } else if (Name == "Website") {
  //     SetWebsite(value);
  //   } else if (Name == "CompanyPhone") {
  //     SetCompanyPhone(value);
  //   } else if (Name == "DirectPhone") {
  //     SetDirectPhone(value);
  //   } else if (Name == "DirectPhoneExt") {
  //     SetDirectPhoneExt(value);
  //   } else if (Name == "LinkedInURL") {
  //     SetLinkedInURL(value);
  //   } else if (Name == "Industry") {
  //     SetIndustry(value);
  //   } else if (Name == "Address1") {
  //     SetAddress1(value);
  //   } else if (Name == "Address2") {
  //     SetAddress2(value);
  //   } else if (Name == "City") {
  //     SetCity(value);
  //   } else if (Name == "State") {
  //     SetState(value);
  //   } else if (Name == "Zip") {
  //     SetZip(value);
  //   } else if (Name == "Country") {
  //     SetCountry(value);
  //   } else if (Name == "ContactCategory") {
  //     SetContactCategory(value);
  //   } else if (Name == "AccountCategory") {
  //     SetAccountCategory(value);
  //   } else if (Name == "ValidationScore") {
  //     SetValidationScore(value);
  //   } else if (Name == "ListName") {
  //     SetListName(value);
  //   } else if (Name == "ContactSource") {
  //     SetContactSource(value);
  //   } else if (Name == "ContactOwner") {
  //     SetContactOwner(value);
  //   } else if (Name == "ClientPOC") {
  //     SetClientPOC(value);
  //   } else if (Name == "ReasonUnqualified") {
  //     SetReasonUnqualified(value);
  //   } else if (Name == "AccountType") {
  //     SetAccountType(value);
  //   } else if (Name == "CompanyRevenue") {
  //     SetCompanyRevenue(value);
  //   } else if (Name == "EmployeeCount") {
  //     setEmployeeCount(value);
  //   } else if (Name == "IsDoNotCallContact") {
  //     SetIsDoNotCallContact(value);
  //   } else if (Name == "SalesRep") {
  //     SetSalesRep(value);
  //   } else if (Name == "ColdCaller") {
  //     SetColdCaller(value);
  //   } else if (Name == "Tag") {
  //     SetTag(value);
  //   } else if (Name == "CallNotes") {
  //     SetCallNotes(value);
  //   } else if (Name == "OtherPhone1") {
  //     SetOtherPhone1(value);
  //   } else if (Name == "OtherPhone2") {
  //     SetOtherPhone2(value);
  //   } else if (Name == "ContactID") {
  //     SetContactID(value);
  //   }else if (Name == "ZoomID") {
  //     SetZoomID(value);
  //   }else if (Name == "BestPhone") {
  //     SetBestPhone(value);
  //   }else if (Name == "BestPhoneStatus") {
  //     SetBestPhoneStatus(value);
  //   }else if (Name == "Phone") {
  //     SetPhone(value);
  //   }else if (Name == "PhoneStatus") {
  //     SetPhoneStatus(value);
  //   }else if (Name == "Phone2") {
  //     SetPhone2(value);
  //   }else if (Name == "Phone2Status") {
  //     SetPhone2Status(value);
  //   }else if (Name == "Phone3") {
  //     SetPhone3(value);
  //   }else if (Name == "Phone3Status") {
  //     SetPhone3Status(value);
  //   }else if (Name == "Phone4") {
  //     SetPhone4(value);
  //   }else if (Name == "Phone4Status") {
  //     SetPhone4Status(value);
  //   }else if (Name == "PRLLeadID") {
  //     SetPRLLeadID(value);
  //   }

  //   hideCSVHeaderOptions();
  // });

  // cancel button import
  const CancleContactBtn = () => {
    SetImportCsvReset(!ImportCsvReset) 
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csv").style.display = "none"
  };
      // form validation for import
      const FromValidationImport = async () => {
        let FormIsValid = true;
        let Errors = {}; 
        var UserEmailImport = document.getElementById("UserEmail").value.trim();
        let IsExistEmail = await EmailValidationCheckImport(UserEmailImport);
        
        if (UserEmailImport === "") { // Check for an empty string or a space in the name
          FormIsValid = false;
          Errors["UserEmail"] = "Please enter email"; // Modify the error message
        }
        if (IsExistEmail == true) {
          FormIsValid = false;
          Errors["UserEmail"] = "Invalid email!";
          // toast.error("Invalid email!")
        }
      
        SetErrors(Errors)
        return FormIsValid;
      }
        // cheack email valid for export
    const EmailValidationCheckImport = async (UserEmailImport) => {
      let regexp =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      let Errors = {};
      if (!regexp.test(UserEmailImport) && UserEmailImport != "") {
        Errors["UserEmail"] = "invalid email.";
        SetIsValidEmail(true)
        // this.setState({ IsValidEmail: true });
        SetErrors(Errors)
        // this.setState({errors:Errors});
        return true;
      } else {
        SetIsValidEmail(false)
        // this.setState({ IsValidEmail: false });
  
        return false;
      }
    };
  // import for email 100+csv
  const ContactSaveCsvAddBulk = async() => {
    SetIsLoading(true)
    setIsSaving(true)
    var FormValidationFlagImport = await FromValidationImport();

    if(FormValidationFlagImport === true){
      var OriginalFileName = files.name;
      var onlyname = OriginalFileName.substring(
        0,
        OriginalFileName.lastIndexOf(".csv")
      );
  
      var UserEmail = document.getElementById("UserEmail").value;
      var saveAllContacts = [];
      var FirstName = parseInt(document.getElementById("First Name").value);
      if (FirstName >= 0) {
        var ObjFirstName = {
          FieldAlias: "First Name",
          Fieldname: "FirstName",
          Customfield: null,
          index: FirstName,
        };
        saveAllContacts.push(ObjFirstName);
      }
      var LastName = parseInt(document.getElementById("Last Name").value);
      if (LastName >= 0) {
        var ObjLastName = {
          FieldAlias: "Last Name",
          Fieldname: "LastName",
          Customfield: null,
          index: LastName,
        };
        saveAllContacts.push(ObjLastName);
      }
  
      var Email = parseInt(document.getElementById("Email").value);
      if (Email >= 0) {
        var ObjEmail = {
          FieldAlias: "Email",
          Fieldname: "Email",
          Customfield: null,
          index: Email,
        };
        saveAllContacts.push(ObjEmail);
      }
      var Title = parseInt(document.getElementById("Title").value);
      if (Title >= 0) {
        var ObjTitle = {
          FieldAlias: "Title",
          Fieldname: "Title",
          Customfield: null,
          index: Title,
        };
        saveAllContacts.push(ObjTitle);
      }
      var Company = parseInt(document.getElementById("Company").value);
      if (Company >= 0) {
        var ObjCompany = {
          FieldAlias: "Company",
          Fieldname: "Company",
          Customfield: null,
          index: Company,
        };
        saveAllContacts.push(ObjCompany);
      }
      var CompanyNameLong = document.getElementById("CompanyNameLong").value;
      if (CompanyNameLong >= 0) {
        var ObjCompanyNameLong = {
          FieldAlias: "Company Name Long",
          Fieldname: "CompanyNameLong",
          Customfield: null,
          index: Company,
        };
        saveAllContacts.push(ObjCompanyNameLong);
      }
      var Website = parseInt(document.getElementById("Website").value);
      if (Website >= 0) {
        var ObjWebsite = {
          FieldAlias: "Website",
          Fieldname: "Website",
          Customfield: null,
          index: Website,
        };
        saveAllContacts.push(ObjWebsite);
      }
      var CompanyPhone = parseInt(document.getElementById("CompanyPhone").value);
      if (CompanyPhone >= 0) {
        var ObjCompanyPhone = {
          FieldAlias: "Company Phone",
          Fieldname: "CompanyPhone",
          Customfield: null,
          index: CompanyPhone,
        };
        saveAllContacts.push(ObjCompanyPhone);
      }
      var DirectPhone = parseInt(document.getElementById("DirectPhone").value);
      if (DirectPhone >= 0) {
        var ObjDirectPhone = {
          FieldAlias: "Direct Phone",
          Fieldname: "DirectPhone",
          Customfield: null,
          index: DirectPhone,
        };
        saveAllContacts.push(ObjDirectPhone);
      }
  
      var DirectPhoneExt = parseInt(
        document.getElementById("DirectPhoneExt").value
      );
      if (DirectPhoneExt >= 0) {
        var ObjDirectPhoneExt = {
          FieldAlias: "Direct Phone Ext",
          Fieldname: "DirectPhoneExt",
          Customfield: null,
          index: DirectPhoneExt,
        };
        saveAllContacts.push(ObjDirectPhoneExt);
      }
      var LinkedInURL = parseInt(document.getElementById("LinkedInURL").value);
      if (LinkedInURL >= 0) {
        var ObjLinkedInURL = {
          FieldAlias: "LinkedIn URL",
          Fieldname: "LinkedInURL",
          Customfield: null,
          index: LinkedInURL,
        };
        saveAllContacts.push(ObjLinkedInURL);
      }
      var Industry = parseInt(document.getElementById("Industry").value);
      if (Industry >= 0) {
        var ObjIndustry = {
          FieldAlias: "Industry",
          Fieldname: "Industry",
          Customfield: null,
          index: Industry,
        };
        saveAllContacts.push(ObjIndustry);
      }
  
      var Address1 = parseInt(document.getElementById("Address1").value);
      if (Address1 >= 0) {
        var ObjAddress1 = {
          FieldAlias: "Address 1",
          Fieldname: "Address1",
          Customfield: null,
          index: Address1,
        };
        saveAllContacts.push(ObjAddress1);
      }
  
      var Address2 = parseInt(document.getElementById("Address2").value);
      if (Address2 >= 0) {
        var ObjAddress2 = {
          FieldAlias: "Address 2",
          Fieldname: "Address2",
          Customfield: null,
          index: Address2,
        };
        saveAllContacts.push(ObjAddress2);
      }
  
      var City = parseInt(document.getElementById("City").value);
      if (City >= 0) {
        var ObjCity = {
          FieldAlias: "City",
          Fieldname: "City",
          Customfield: null,
          index: City,
        };
  
        saveAllContacts.push(ObjCity);
      }
      var State = parseInt(document.getElementById("State").value);
      if (State >= 0) {
        var ObjState = {
          FieldAlias: "State",
          Fieldname: "State",
          Customfield: null,
          index: State,
        };
        saveAllContacts.push(ObjState);
      }
  
      var Zip = parseInt(document.getElementById("Zip").value);
      if (Zip >= 0) {
        var ObjZip = {
          FieldAlias: "Zip",
          Fieldname: "Zip",
          Customfield: null,
          index: Zip,
        };
        saveAllContacts.push(ObjZip);
      }
  
      var Country = parseInt(document.getElementById("Country").value);
      if (Country >= 0) {
        var ObjCountry = {
          FieldAlias: "Country",
          Fieldname: "Country",
          Customfield: null,
          index: Country,
        };
        saveAllContacts.push(ObjCountry);
      }
  
      var ContactCategory = parseInt(
        document.getElementById("ContactCategory").value
      );
      if (ContactCategory >= 0) {
        var ObjContactCategory = {
          FieldAlias: "Contact Category",
          Fieldname: "ContactCategory",
          Customfield: null,
          index: ContactCategory,
        };
        saveAllContacts.push(ObjContactCategory);
      }
      var AccountCategory = parseInt(
        document.getElementById("AccountCategory").value
      );
      if (AccountCategory >= 0) {
        var ObjAccountCategory = {
          FieldAlias: "Account Category",
          Fieldname: "AccountCategory",
          Customfield: null,
          index: AccountCategory,
        };
        saveAllContacts.push(ObjAccountCategory);
      }
  
      var ValidationScore = parseInt(
        document.getElementById("ValidationScore").value
      );
      if (ValidationScore >= 0) {
        var ObjValidationScore = {
          FieldAlias: "Validation Score",
          Fieldname: "ValidationScore",
          Customfield: null,
          index: ValidationScore,
        };
        saveAllContacts.push(ObjValidationScore);
      }
      var ListName = document.getElementById("ListName").value;
      if (ListName != "") {
        var ObjListName = {
          FieldAlias: "List Name",
          Fieldname: "ListName",
          Customfield: null,
          index: ListName,
        };
        saveAllContacts.push(ObjListName);
      }
  
      var ContactSource = parseInt(
        document.getElementById("ContactSource").value
      );
      if (ContactSource >= 0) {
        var ObjContactSource = {
          FieldAlias: "Contact Source",
          Fieldname: "ContactSource",
          Customfield: null,
          index: ContactSource,
        };
        saveAllContacts.push(ObjContactSource);
      }
      var ContactOwner = parseInt(document.getElementById("ContactOwner").value);
      if (ContactOwner >= 0) {
        var ObjContactOwner = {
          FieldAlias: "Contact Owner",
          Fieldname: "ContactOwner",
          Customfield: null,
          index: ContactOwner,
        };
        saveAllContacts.push(ObjContactOwner);
      }
      var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
      if (ClientPOC >= 0) {
        var ObjClientPOC = {
          FieldAlias: "Client POC",
          Fieldname: "ClientPOC",
          Customfield: null,
          index: ClientPOC,
        };
        saveAllContacts.push(ObjClientPOC);
      }
      var ReasonUnqualified = parseInt(
        document.getElementById("ReasonUnqualified").value
      );
      if (ReasonUnqualified >= 0) {
        var ObjReasonUnqualified = {
          FieldAlias: "Reason Unqualified",
          Fieldname: "ReasonUnqualified",
          Customfield: null,
          index: ReasonUnqualified,
        };
        saveAllContacts.push(ObjReasonUnqualified);
      }
      var AccountType = parseInt(document.getElementById("AccountType").value);
      if (AccountType >= 0) {
        var ObjAccountType = {
          FieldAlias: "Account Type",
          Fieldname: "AccountType",
          Customfield: null,
          index: AccountType,
        };
        saveAllContacts.push(ObjAccountType);
      }
      var CompanyRevenue = parseInt(
        document.getElementById("CompanyRevenue").value
      );
      if (CompanyRevenue >= 0) {
        var ObjCompanyRevenue = {
          FieldAlias: "Company Revenue",
          Fieldname: "CompanyRevenue",
          Customfield: null,
          index: CompanyRevenue,
        };
        saveAllContacts.push(ObjCompanyRevenue);
      }
      var EmployeeCount = parseInt(
        document.getElementById("EmployeeCount").value
      );
      if (EmployeeCount >= 0) {
        var ObjEmployeeCount = {
          FieldAlias: "Employee Count",
          Fieldname: "EmployeeCount",
          Customfield: null,
          index: EmployeeCount,
        };
        saveAllContacts.push(ObjEmployeeCount);
      }
  
      var MobilePhone = parseInt(document.getElementById("MobilePhone").value);
      if (MobilePhone >= 0) {
        var ObjMobilePhone = {
          FieldAlias: "Mobile Phone",
          Fieldname: "MobilePhone",
          Customfield: null,
          index: MobilePhone,
        };
        saveAllContacts.push(ObjMobilePhone);
      }
  
      var IsDoNotCallContact = parseInt(
        document.getElementById("IsDoNotCallContact").value
      );
      if (IsDoNotCallContact >= 0) {
        var ObjIsDoNotCallContact = {
          FieldAlias: "Do not call contact",
          Fieldname: "IsDoNotCallContact",
          Customfield: null,
          index: IsDoNotCallContact,
        };
        saveAllContacts.push(ObjIsDoNotCallContact);
      }
  
      var SalesRep = parseInt(document.getElementById("SalesRep").value);
      if (SalesRep >= 0) {
        var ObjSalesRep = {
          FieldAlias: "Sales Rep",
          Fieldname: "SalesRep",
          Customfield: null,
          index: SalesRep,
        };
        saveAllContacts.push(ObjSalesRep);
      }
  
      var ColdCaller = parseInt(document.getElementById("ColdCaller").value);
      if (ColdCaller >= 0) {
        var ObjColdCaller = {
          FieldAlias: "Sales Development Reps",
          Fieldname: "ColdCaller",
          Customfield: null,
          index: ColdCaller,
        };
        saveAllContacts.push(ObjColdCaller);
      }
  
      var Tag = parseInt(document.getElementById("Tag").value);
      if (Tag >= 0) {
        var ObjTag = {
          FieldAlias: "Tag",
          Fieldname: "Tag",
          Customfield: null,
          index: Tag,
        };
        saveAllContacts.push(ObjTag);
      }
  
      var CallNotes = parseInt(document.getElementById("CallNotes").value);
      if (CallNotes >= 0) {
        var ObjCallNotes = {
          FieldAlias: "Call Notes",
          Fieldname: "CallNotes",
          Customfield: null,
          index: CallNotes,
        };
        saveAllContacts.push(ObjCallNotes);
      }
  
      var OtherPhone1 = parseInt(document.getElementById("OtherPhone1").value);
      if (OtherPhone1 >= 0) {
        var ObjOtherPhone1 = {
          FieldAlias: "Other Phone 1",
          Fieldname: "OtherPhone1",
          Customfield: null,
          index: OtherPhone1,
        };
        saveAllContacts.push(ObjOtherPhone1);
      }
      var OtherPhone2 = parseInt(document.getElementById("OtherPhone2").value);
      if (OtherPhone2 >= 0) {
        var ObjOtherPhone2 = {
          FieldAlias: "Other Phone 2",
          Fieldname: "OtherPhone2",
          Customfield: null,
          index: OtherPhone2,
        };
        saveAllContacts.push(ObjOtherPhone2);
      }
  
      var ContactID = parseInt(document.getElementById("ContactID").value);
      if (ContactID >= 0) {
        var ObjContactID = {
          FieldAlias: "ContactID",
          Fieldname: "ContactID",
          Customfield: null,
          index: ContactID,
        };
        saveAllContacts.push(ObjContactID);
      }
      var ZoomID = parseInt(document.getElementById("ZoomID").value);
      if (ZoomID >= 0) {
        var ObjZoomID = {
          FieldAlias: "Zoom ID",
          Fieldname: "ZoomID",
          Customfield: null,
          index: ZoomID,
        };
        saveAllContacts.push(ObjZoomID);
      }
      var BestPhone = parseInt(document.getElementById("BestPhone").value);
      if (BestPhone >= 0) {
        var ObjBestPhone = {
          FieldAlias: "Best Phone",
          Fieldname: "BestPhone",
          Customfield: null,
          index: BestPhone,
        };
        saveAllContacts.push(ObjBestPhone);
      }
      var BestPhoneStatus = parseInt(document.getElementById("BestPhoneStatus").value);
      if (BestPhoneStatus >= 0) {
        var ObjBestPhoneStatus = {
          FieldAlias: "Best Phone Status",
          Fieldname: "BestPhoneStatus",
          Customfield: null,
          index: BestPhoneStatus,
        };
        saveAllContacts.push(ObjBestPhoneStatus);
      }
      var Phone = parseInt(document.getElementById("Phone").value);
      if (Phone >= 0) {
        var ObjPhone = {
          FieldAlias: "Phone",
          Fieldname: "Phone",
          Customfield: null,
          index: Phone,
        };
        saveAllContacts.push(ObjPhone);
      }
      var PhoneStatus = parseInt(document.getElementById("PhoneStatus").value);
      if (PhoneStatus >= 0) {
        var ObjPhoneStatus = {
          FieldAlias: "Phone Status",
          Fieldname: "PhoneStatus",
          Customfield: null,
          index: PhoneStatus,
        };
        saveAllContacts.push(ObjPhoneStatus);
      }
      var Phone2 = parseInt(document.getElementById("Phone2").value);
      if (Phone2 >= 0) {
        var ObjPhone2 = {
          FieldAlias: "Phone 2",
          Fieldname: "Phone2",
          Customfield: null,
          index: Phone2,
        };
        saveAllContacts.push(ObjPhone2);
      }
      var Phone2Status = parseInt(document.getElementById("Phone2Status").value);
      if (Phone2Status >= 0) {
        var ObjPhone2Status = {
          FieldAlias: "Phone2 Status",
          Fieldname: "Phone2Status",
          Customfield: null,
          index: Phone2Status,
        };
        saveAllContacts.push(ObjPhone2Status);
      }
      var Phone3 = parseInt(document.getElementById("Phone3").value);
      if (Phone3 >= 0) {
        var ObjPhone3 = {
          FieldAlias: "Phone 3",
          Fieldname: "Phone3",
          Customfield: null,
          index: Phone3,
        };
        saveAllContacts.push(ObjPhone3);
      }
      var Phone3Status = parseInt(document.getElementById("Phone3Status").value);
      if (Phone3Status >= 0) {
        var ObjPhone3Status = {
          FieldAlias: "Phone3 Status",
          Fieldname: "Phone3Status",
          Customfield: null,
          index: Phone3Status,
        };
        saveAllContacts.push(ObjPhone3Status);
      }
       var Phone4 = parseInt(document.getElementById("Phone4").value);
      if (Phone4 >= 0) {
        var ObjPhone4 = {
          FieldAlias: "Phone 4",
          Fieldname: "Phone4",
          Customfield: null,
          index: Phone4,
        };
        saveAllContacts.push(ObjPhone4);
      }
      var Phone4Status = parseInt(document.getElementById("Phone4Status").value);
      if (Phone4Status >= 0) {
        var ObjPhone4Status = {
          FieldAlias: "Phone4 Status",
          Fieldname: "Phone4Status",
          Customfield: null,
          index: Phone4Status,
        };
        saveAllContacts.push(ObjPhone4Status);
      }
      var PRLLeadID = parseInt(document.getElementById("PRLLeadID").value);
      if (PRLLeadID >= 0) {
        var ObjPRLLeadID = {
          FieldAlias: "PRL Lead ID (SalesForce ID)",
          Fieldname: "PRLLeadID",
          Customfield: null,
          index: PRLLeadID,
        };
        saveAllContacts.push(ObjPRLLeadID);
      }

      {
        ContactCustomFieldData?.map((customfieldvalue) =>{
          var CustomFieldValuesIndex = parseInt(document.getElementById(customfieldvalue?.FieldName).value);
          if (CustomFieldValuesIndex >= 0) {
            var ObjCustomFieldValuesIndex = {
              FieldAlias: customfieldvalue?.FieldName,
              Fieldname: customfieldvalue?.FieldName.split(" ").join(""),
              Customfield: true,
              index: CustomFieldValuesIndex,
            };
            saveAllContacts.push(ObjCustomFieldValuesIndex);
          }
        })
      }
      var IsSkipUpdateExistContacts = document.getElementById(
        "IsUpdateExistContacts"
      ).checked;
      // if (IsSkipUpdateExistContacts == false) {
      //   IsSkipUpdateExistContacts = true;
      // } else {
      //   IsSkipUpdateExistContacts = false;
      // }
      var IsUpdateExistContacts = document.getElementById(
        "IsUpdateExistContacts"
      ).checked;
      if (IsUpdateExistContacts == false) {
        IsUpdateExistContacts = true;
      } else {
        IsUpdateExistContacts = false;
      }
      var IsInsertNullValues =
        document.getElementById("IsInsertNullValues").checked;
      var IsDoNotupdatecompanyname = document.getElementById(
        "IsDoNotupdatecompanyname"
      ).checked;
  
      var csvdata = csvData2;
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];
        var ObjData = {
          Fields: "Coverimg",
          Filepath: "./Content/UploadedFiles/SalesReplyContact",
          File: _base64,
          ImageName: filenameonlynumber,
          FileData: csvdata,
          MappingFiled: JSON.stringify(saveAllContacts),
          Email: UserEmail,
          ClientID: ClientID,
          UserID: UserID,
          CreatedBy: CUserID,
          CreatedDate: new Date(),
          IsSkipUpdateExistContacts: IsSkipUpdateExistContacts,
          IsInsertNullValues: IsInsertNullValues,
          IsUpdateExistContacts: IsUpdateExistContacts,
          IsDoNotupdatecompanyname: IsDoNotupdatecompanyname,
          OriginalFileName: OriginalFileName,
          ListName: onlyname,
          CampaignID: null,
  
        };
  
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactBulkUploadImport",
          method: "POST",
          data: ObjData,
        }).then((res) => {
          document.getElementById("IsInsertNullValues").checked = false;
          document.getElementById("IsUpdateExistContacts").checked=false;
          document.getElementById("IsDoNotupdatecompanyname").checked=false;
  
          if (res.data.StatusMessage == "SUCCESS") {
            SetShowPopupImport(false);
            toast.success("Contact You will get file soon on Submitted Email.");
            SetIsLoading(false)
            document.getElementById("import_csv").style.display = "none"
            setIsSaving(false)
            document.getElementById("map_attribute").style.display = "none";
            SetErrors({})
          } else {
            toast.error(res.data.Message);
            SetErrors({})
            setIsSaving(false)
            SetIsLoading(false)
          }
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
          SetErrors({})
          setIsSaving(false)
        });
      });
    }else{
      setIsSaving(false)
      SetIsLoading(false)
    }
 
  };

  // import black list csv 100-below
  const ContactSaveCsv = async () => {
    if (csvData.length >= 100 || csvData2.length>=100) {
      SetShowPopupImport(true);
      toast.success( <div className="toastsize">
     SalesHive Contacts
      <br />
      Your list upload has more than 100 contacts. You will receive an import confirmation report via email.
    </div>);
      SetIsLoading(false)
    } else {
      SetIsLoading(true)
      var saveAllContacts = [];
      var FirstName = parseInt(document.getElementById("First Name").value);
      if (FirstName >= 0) {
        var ObjFirstName = {
          FieldAlias: "First Name",
          Fieldname: "FirstName",
          Customfield: null,
          index: FirstName,
        };
        saveAllContacts.push(ObjFirstName);
      }
      var LastName = parseInt(document.getElementById("Last Name").value);
      if (LastName >= 0) {
        var ObjLastName = {
          FieldAlias: "Last Name",
          Fieldname: "LastName",
          Customfield: null,
          index: LastName,
        };
        saveAllContacts.push(ObjLastName);
      }
      var Email = parseInt(document.getElementById("Email").value);
      if (Email >= 0) {
        var ObjEmail = {
          FieldAlias: "Email",
          Fieldname: "Email",
          Customfield: null,
          index: Email,
        };
        saveAllContacts.push(ObjEmail);
      }
      var Title = parseInt(document.getElementById("Title").value);
      if (Title >= 0) {
        var ObjTitle = {
          FieldAlias: "Title",
          Fieldname: "Title",
          Customfield: null,
          index: Title,
        };
        saveAllContacts.push(ObjTitle);
      }
      var Company = parseInt(document.getElementById("Company").value);
      if (Company >= 0) {
        var ObjCompany = {
          FieldAlias: "Company",
          Fieldname: "Company",
          Customfield: null,
          index: Company,
        };
        saveAllContacts.push(ObjCompany);
      }
      var CompanyNameLong =document.getElementById("CompanyNameLong").value;
      if (CompanyNameLong >= 0) {
        var ObjCompanyNameLong = {
          FieldAlias: "Company Name Long",
          Fieldname: "CompanyNameLong",
          Customfield: null,
          index: Company,
        };
        saveAllContacts.push(ObjCompanyNameLong);
      }
      var Website = parseInt(document.getElementById("Website").value);
      if (Website >= 0) {
        var ObjWebsite = {
          FieldAlias: "Website",
          Fieldname: "Website",
          Customfield: null,
          index: Website,
        };
        saveAllContacts.push(ObjWebsite);
      }
      var CompanyPhone = parseInt(
        document.getElementById("CompanyPhone").value
      );
      if (CompanyPhone >= 0) {
        var ObjCompanyPhone = {
          FieldAlias: "Company Phone",
          Fieldname: "CompanyPhone",
          Customfield: null,
          index: CompanyPhone,
        };
        saveAllContacts.push(ObjCompanyPhone);
      }
      var DirectPhone = parseInt(document.getElementById("DirectPhone").value);
      if (DirectPhone >= 0) {
        var ObjDirectPhone = {
          FieldAlias: "Direct Phone",
          Fieldname: "DirectPhone",
          Customfield: null,
          index: DirectPhone,
        };
        saveAllContacts.push(ObjDirectPhone);
      }

      var DirectPhoneExt = parseInt(
        document.getElementById("DirectPhoneExt").value
      );
      if (DirectPhoneExt >= 0) {
        var ObjDirectPhoneExt = {
          FieldAlias: "Direct Phone Ext",
          Fieldname: "DirectPhoneExt",
          Customfield: null,
          index: DirectPhoneExt,
        };
        saveAllContacts.push(ObjDirectPhoneExt);
      }
      var LinkedInURL = parseInt(document.getElementById("LinkedInURL").value);
      if (LinkedInURL >= 0) {
        var ObjLinkedInURL = {
          FieldAlias: "LinkedIn URL",
          Fieldname: "LinkedInURL",
          Customfield: null,
          index: LinkedInURL,
        };
        saveAllContacts.push(ObjLinkedInURL);
      }
      var Industry = parseInt(document.getElementById("Industry").value);
      if (Industry >= 0) {
        var ObjIndustry = {
          FieldAlias: "Industry",
          Fieldname: "Industry",
          Customfield: null,
          index: Industry,
        };
        saveAllContacts.push(ObjIndustry);
      }

      var Address1 = parseInt(document.getElementById("Address1").value);
      if (Address1 >= 0) {
        var ObjAddress1 = {
          FieldAlias: "Address 1",
          Fieldname: "Address1",
          Customfield: null,
          index: Address1,
        };
        saveAllContacts.push(ObjAddress1);
      }

      var Address2 = parseInt(document.getElementById("Address2").value);
      if (Address2 >= 0) {
        var ObjAddress2 = {
          FieldAlias: "Address 2",
          Fieldname: "Address2",
          Customfield: null,
          index: Address2,
        };
        saveAllContacts.push(ObjAddress2);
      }

      var City = parseInt(document.getElementById("City").value);
      if (City >= 0) {
        var ObjCity = {
          FieldAlias: "City",
          Fieldname: "City",
          Customfield: null,
          index: City,
        };

        saveAllContacts.push(ObjCity);
      }
      var State = parseInt(document.getElementById("State").value);
      if (State >= 0) {
        var ObjState = {
          FieldAlias: "State",
          Fieldname: "State",
          Customfield: null,
          index: State,
        };
        saveAllContacts.push(ObjState);
      }

      var Zip = parseInt(document.getElementById("Zip").value);
      if (Zip >= 0) {
        var ObjZip = {
          FieldAlias: "Zip",
          Fieldname: "Zip",
          Customfield: null,
          index: Zip,
        };
        saveAllContacts.push(ObjZip);
      }

      var Country = parseInt(document.getElementById("Country").value);
      if (Country >= 0) {
        var ObjCountry = {
          FieldAlias: "Country",
          Fieldname: "Country",
          Customfield: null,
          index: Country,
        };
        saveAllContacts.push(ObjCountry);
      }

      var ContactCategory = parseInt(
        document.getElementById("ContactCategory").value
      );
      if (ContactCategory >= 0) {
        var ObjContactCategory = {
          FieldAlias: "Contact Category",
          Fieldname: "ContactCategory",
          Customfield: null,
          index: ContactCategory,
        };
        saveAllContacts.push(ObjContactCategory);
      }
      var AccountCategory = parseInt(
        document.getElementById("AccountCategory").value
      );
      if (AccountCategory >= 0) {
        var ObjAccountCategory = {
          FieldAlias: "Account Category",
          Fieldname: "AccountCategory",
          Customfield: null,
          index: AccountCategory,
        };
        saveAllContacts.push(ObjAccountCategory);
      }

      var ValidationScore = parseInt(
        document.getElementById("ValidationScore").value
      );
      if (ValidationScore >= 0) {
        var ObjValidationScore = {
          FieldAlias: "Validation Score",
          Fieldname: "ValidationScore",
          Customfield: null,
          index: ValidationScore,
        };
        saveAllContacts.push(ObjValidationScore);
      }
      var ListName = document.getElementById("ListName").value;
      if (ListName != "") {
        var ObjListName = {
          FieldAlias: "List Name",
          Fieldname: "ListName",
          Customfield: null,
          index: ListName,
        };
        saveAllContacts.push(ObjListName);
      }

      var ContactSource = parseInt(
        document.getElementById("ContactSource").value
      );
      if (ContactSource >= 0) {
        var ObjContactSource = {
          FieldAlias: "Contact Source",
          Fieldname: "ContactSource",
          Customfield: null,
          index: ContactSource,
        };
        saveAllContacts.push(ObjContactSource);
      }
      var ContactOwner = parseInt(
        document.getElementById("ContactOwner").value
      );
      if (ContactOwner >= 0) {
        var ObjContactOwner = {
          FieldAlias: "Contact Owner",
          Fieldname: "ContactOwner",
          Customfield: null,
          index: ContactOwner,
        };
        saveAllContacts.push(ObjContactOwner);
      }
      var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
      if (ClientPOC >= 0) {
        var ObjClientPOC = {
          FieldAlias: "Client POC",
          Fieldname: "ClientPOC",
          Customfield: null,
          index: ClientPOC,
        };
        saveAllContacts.push(ObjClientPOC);
      }
      var ReasonUnqualified = parseInt(
        document.getElementById("ReasonUnqualified").value
      );
      if (ReasonUnqualified >= 0) {
        var ObjReasonUnqualified = {
          FieldAlias: "Reason Unqualified",
          Fieldname: "ReasonUnqualified",
          Customfield: null,
          index: ReasonUnqualified,
        };
        saveAllContacts.push(ObjReasonUnqualified);
      }
      var AccountType = parseInt(document.getElementById("AccountType").value);
      if (AccountType >= 0) {
        var ObjAccountType = {
          FieldAlias: "Account Type",
          Fieldname: "AccountType",
          Customfield: null,
          index: AccountType,
        };
        saveAllContacts.push(ObjAccountType);
      }
      var CompanyRevenue = parseInt(
        document.getElementById("CompanyRevenue").value
      );
      if (CompanyRevenue >= 0) {
        var ObjCompanyRevenue = {
          FieldAlias: "Company Revenue",
          Fieldname: "CompanyRevenue",
          Customfield: null,
          index: CompanyRevenue,
        };
        saveAllContacts.push(ObjCompanyRevenue);
      }
      var EmployeeCount = parseInt(
        document.getElementById("EmployeeCount").value
      );
      if (EmployeeCount >= 0) {
        var ObjEmployeeCount = {
          FieldAlias: "Employee Count",
          Fieldname: "EmployeeCount",
          Customfield: null,
          index: EmployeeCount,
        };
        saveAllContacts.push(ObjEmployeeCount);
      }

      var MobilePhone = parseInt(document.getElementById("MobilePhone").value);
      if (MobilePhone >= 0) {
        var ObjMobilePhone = {
          FieldAlias: "Mobile Phone",
          Fieldname: "MobilePhone",
          Customfield: null,
          index: MobilePhone,
        };
        saveAllContacts.push(ObjMobilePhone);
      }

      var IsDoNotCallContact = parseInt(
        document.getElementById("IsDoNotCallContact").value
      );
      if (IsDoNotCallContact >= 0) {
        var ObjIsDoNotCallContact = {
          FieldAlias: "Do not call contact",
          Fieldname: "IsDoNotCallContact",
          Customfield: null,
          index: IsDoNotCallContact,
        };
        saveAllContacts.push(ObjIsDoNotCallContact);
      }

      var SalesRep = parseInt(document.getElementById("SalesRep").value);
      if (SalesRep >= 0) {
        var ObjSalesRep = {
          FieldAlias: "Sales Rep",
          Fieldname: "SalesRep",
          Customfield: null,
          index: SalesRep,
        };
        saveAllContacts.push(ObjSalesRep);
      }

      var ColdCaller = parseInt(document.getElementById("ColdCaller").value);
      if (ColdCaller >= 0) {
        var ObjColdCaller = {
          FieldAlias: "Sales Development Reps",
          Fieldname: "ColdCaller",
          Customfield: null,
          index: ColdCaller,
        };
        saveAllContacts.push(ObjColdCaller);
      }

      var Tag = parseInt(document.getElementById("Tag").value);
      if (Tag >= 0) {
        var ObjTag = {
          FieldAlias: "Tag",
          Fieldname: "Tag",
          Customfield: null,
          index: Tag,
        };
        saveAllContacts.push(ObjTag);
      }

      var CallNotes = parseInt(document.getElementById("CallNotes").value);
      if (CallNotes >= 0) {
        var ObjCallNotes = {
          FieldAlias: "Call Notes",
          Fieldname: "CallNotes",
          Customfield: null,
          index: CallNotes,
        };
        saveAllContacts.push(ObjCallNotes);
      }

      var OtherPhone1 = parseInt(document.getElementById("OtherPhone1").value);
      if (OtherPhone1 >= 0) {
        var ObjOtherPhone1 = {
          FieldAlias: "Other Phone 1",
          Fieldname: "OtherPhone1",
          Customfield: null,
          index: OtherPhone1,
        };
        saveAllContacts.push(ObjOtherPhone1);
      }
      var OtherPhone2 = parseInt(document.getElementById("OtherPhone2").value);
      if (OtherPhone2 >= 0) {
        var ObjOtherPhone2 = {
          FieldAlias: "Other Phone 2",
          Fieldname: "OtherPhone2",
          Customfield: null,
          index: OtherPhone2,
        };
        saveAllContacts.push(ObjOtherPhone2);
      }

      var ContactID = parseInt(document.getElementById("ContactID").value);
      if (ContactID >= 0) {
        var ObjContactID = {
          FieldAlias: "ContactID",
          Fieldname: "ContactID",
          Customfield: null,
          index: ContactID,
        };
        saveAllContacts.push(ObjContactID);
      }
      var ZoomID = parseInt(document.getElementById("ZoomID").value);
      if (ZoomID >= 0) {
        var ObjZoomID = {
          FieldAlias: "Zoom ID",
          Fieldname: "ZoomID",
          Customfield: null,
          index: ZoomID,
        };
        saveAllContacts.push(ObjZoomID);
      }
      var BestPhone = parseInt(document.getElementById("BestPhone").value);
      if (BestPhone >= 0) {
        var ObjBestPhone = {
          FieldAlias: "Best Phone",
          Fieldname: "BestPhone",
          Customfield: null,
          index: BestPhone,
        };
        saveAllContacts.push(ObjBestPhone);
      }
      var BestPhoneStatus = parseInt(document.getElementById("BestPhoneStatus").value);
      if (BestPhoneStatus >= 0) {
        var ObjBestPhoneStatus = {
          FieldAlias: "Best Phone Status",
          Fieldname: "BestPhoneStatus",
          Customfield: null,
          index: BestPhoneStatus,
        };
        saveAllContacts.push(ObjBestPhoneStatus);
      }
      var Phone = parseInt(document.getElementById("Phone").value);
      if (Phone >= 0) {
        var ObjPhone = {
          FieldAlias: "Phone",
          Fieldname: "Phone",
          Customfield: null,
          index: Phone,
        };
        saveAllContacts.push(ObjPhone);
      }
      var PhoneStatus = parseInt(document.getElementById("PhoneStatus").value);
      if (PhoneStatus >= 0) {
        var ObjPhoneStatus = {
          FieldAlias: "Phone Status",
          Fieldname: "PhoneStatus",
          Customfield: null,
          index: PhoneStatus,
        };
        saveAllContacts.push(ObjPhoneStatus);
      }
      var Phone2 = parseInt(document.getElementById("Phone2").value);
      if (Phone2 >= 0) {
        var ObjPhone2 = {
          FieldAlias: "Phone 2",
          Fieldname: "Phone2",
          Customfield: null,
          index: Phone2,
        };
        saveAllContacts.push(ObjPhone2);
      }
      var Phone2Status = parseInt(document.getElementById("Phone2Status").value);
      if (Phone2Status >= 0) {
        var ObjPhone2Status = {
          FieldAlias: "Phone2 Status",
          Fieldname: "Phone2Status",
          Customfield: null,
          index: Phone2Status,
        };
        saveAllContacts.push(ObjPhone2Status);
      }
      var Phone3 = parseInt(document.getElementById("Phone3").value);
      if (Phone3 >= 0) {
        var ObjPhone3 = {
          FieldAlias: "Phone 3",
          Fieldname: "Phone3",
          Customfield: null,
          index: Phone3,
        };
        saveAllContacts.push(ObjPhone3);
      }
      var Phone3Status = parseInt(document.getElementById("Phone3Status").value);
      if (Phone3Status >= 0) {
        var ObjPhone3Status = {
          FieldAlias: "Phone3 Status",
          Fieldname: "Phone3Status",
          Customfield: null,
          index: Phone3Status,
        };
        saveAllContacts.push(ObjPhone3Status);
      }
       var Phone4 = parseInt(document.getElementById("Phone4").value);
      if (Phone4 >= 0) {
        var ObjPhone4 = {
          FieldAlias: "Phone 4",
          Fieldname: "Phone4",
          Customfield: null,
          index: Phone4,
        };
        saveAllContacts.push(ObjPhone4);
      }
      var Phone4Status = parseInt(document.getElementById("Phone4Status").value);
      if (Phone4Status >= 0) {
        var ObjPhone4Status = {
          FieldAlias: "Phone4 Status",
          Fieldname: "Phone4Status",
          Customfield: null,
          index: Phone4Status,
        };
        saveAllContacts.push(ObjPhone4Status);
      }
      var PRLLeadID = parseInt(document.getElementById("PRLLeadID").value);
      if (PRLLeadID >= 0) {
        var ObjPRLLeadID = {
          FieldAlias: "PRL Lead ID (SalesForce ID)",
          Fieldname: "PRLLeadID",
          Customfield: null,
          index: PRLLeadID,
        };
        saveAllContacts.push(ObjPRLLeadID);
      }

      {
        ContactCustomFieldData?.map((customfieldvalue) =>{
          var CustomFieldValuesIndex = parseInt(document.getElementById(customfieldvalue?.FieldName).value);
          if (CustomFieldValuesIndex >= 0) {
            var ObjCustomFieldValuesIndex = {
              FieldAlias: customfieldvalue?.FieldName,
              Fieldname: customfieldvalue?.FieldName.split(" ").join(""),
              Customfield: true,
              index: CustomFieldValuesIndex,
            };
            saveAllContacts.push(ObjCustomFieldValuesIndex);
          }
        })
      }
      var IsUpdateExistContacts = document.getElementById(
        "IsUpdateExistContacts"
      ).checked;
      if (IsUpdateExistContacts == false) {
        IsUpdateExistContacts = true;
      } else {
        IsUpdateExistContacts = false;
      }
      var IsInsertNullValues =
        document.getElementById("IsInsertNullValues").checked;
      var IsDoNotupdatecompanyname = document.getElementById(
        "IsDoNotupdatecompanyname"
      ).checked;

      var csvdata = csvData2;
      var csvdata2 = csvData.length;

      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var ObjData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/SalesReplyContact",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          saveAllContacts: saveAllContacts,
          ClientID: ClientID,
          UserID: UserID,
          CreatedBy: CUserID,
          CreatedDate: new Date(),
          IsUpdateExistContacts: IsUpdateExistContacts,
          IsInsertNullValues: IsInsertNullValues,
          IsDoNotupdatecompanyname: IsDoNotupdatecompanyname,
          IsCsvFileName:CsvFileName
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactImport",
          method: "POST",
          data: ObjData,
        }).then(async (res) => {
          document.getElementById("IsInsertNullValues").checked = false;
          document.getElementById("IsUpdateExistContacts").checked=false;
          document.getElementById("IsDoNotupdatecompanyname").checked=false;
  
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success("Contacts Save Imports");
            SetImportCsvReset(!ImportCsvReset) 
            SetTotal(res.data.TotalContacts);
            SetTotalSucess(res.data.TotalSuccess);
            SetTotalError(res.data.TotalError);
            SetTotalDuplicate(res.data.TotalDuplicate);
            SetTotalUpdated(res.data.TotalUpdated);
            document.getElementById("Show_csv_details").style.display = "block";
            document.getElementById("map_attribute").style.display = "none";
          await ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters)
         await  ContactListTotal(ClientID, UserID,Role,page,IsApply,FilterParameters);
            document.getElementById("import_csv").style.display = "none"
            setcsvData2([])
            GetClientDetails(ClientID)
            // document.getElementById("hideloding").style.display = "none";
            SetIsLoading(false)
          }else{
            SetImportCsvReset(!ImportCsvReset) 
            toast.error("Error")
            // document.getElementById("hideloding").style.display = "none";
            SetIsLoading(false)
          }
        }).catch((error) => {
          SetImportCsvReset(!ImportCsvReset) 
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
      });
      SetIsLoading(true)
    }
  };
  // Bulk delete contact csv
  const DeleteBulkContactCsv = () => {
    SetIsLoading(true)
    var Email = document.getElementById("Email").value;


    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var BulkDeleteContactData = {
        Fields: "Coverimg",
        Path: "./Content/UploadedFiles/UserContacts",
        File: _base64,
        ImageName: filename,
        FileData: csvData,
        Email: Email,
        ClientID:ClientID,
        UserID:CUserID
      };
   
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactBulkDelete",
        method: "POST",
        data: BulkDeleteContactData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Contact deleted successfully.");
          SetResetBulkDelete(!ResetBulkDelete) 
          setcsvData([])
          document.getElementById("delete_csv").style.display = "none"
          document.getElementById(
            "map_attribute_domain_blacklist"
          ).style.display = "none";
          ContactListGet(ClientID,UserID,Role,page,IsApply,FilterParameters)
          ContactListTotal(ClientID, UserID, Role,page,IsApply,FilterParameters);
          GetClientDetails(ClientID)
          SetIsLoading(false)
        }else {
          SetResetBulkDelete(!ResetBulkDelete) 
          setcsvData([])
          toast.error(res.data.Message);
          SetIsLoading(false)
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetResetBulkDelete(!ResetBulkDelete) 
        SetIsLoading(false);
      });
    });
  };


  const CancelBulkContactBtn = () => {
    setcsvData([])
    SetResetBulkDelete(!ResetBulkDelete) 
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("map_attribute_domain_blacklist").style.display = "none";
    document.getElementById("delete_csv").style.display = "none"
    document.getElementById("import_csv").style.display = "none"

  };
  const ContactBulkDeleteCsv = (e) => {
  document.getElementById("Show_csv_details").style.display = "none"

    e.preventDefault()
    document.getElementById("delete_csv").style.display = "block"
    document.getElementById("import_csv").style.display = "none"

  };

  const addcontactpage = async () => {
            var InputParams = {
              ClientID: ClientID,
              Role:Role
            };
            const res = await Axios({
              url: CommonConstants.MOL_APIURL + "/contacts/CheckContactsPerClientLimit",
              method: "POST",
              data: InputParams,
            });
            if (res.data.StatusMessage === "SUCCESS") {
              if(res.data.Data.IsExhaustLimit === true){
                toast.error(<div className="toastsize">Contact <br/>You have reached your limit for contacts.</div>)
              }else{
                document.getElementById("Show_csv_details").style.display = "none"
                history.push({
                  pathname: "/caddcontacts",
                  state: { data: AccountTypeForTab, Pagename: "" },
                });
              }
            } else {
              console.log(res.data.Message)
            }
  };

  // When Empty array add display toatst function
  const ContactDeleteTagBtn = () => {
    document.getElementById("Show_csv_details").style.display = "none"
    SetDeleteTagModel(true);
  };

  // When Empty array add display toatst function
  const ContactReassignDevBtn = () => {
    if(IsApply === true){
      if(prospectAllSelectData.length === 0){
        document.getElementById("Show_csv_details").style.display = "none"
        toast.error("Please select at least one contact from the list");
        return;
      }else{
        document.getElementById("Show_csv_details").style.display = "none"
        SetShowPopupReassignDev(true);
        SetReassignFromSelectedValue([]);
      }
    }else{
      document.getElementById("Show_csv_details").style.display = "none"
      SetShowPopupReassignDev(true);
      SetReassignFromSelectedValue([]);
    }

  };

    // When Empty array add display toatst function
    const ContactReassignSDR = () => {
      if(prospectAllSelectData.length === 0){
        document.getElementById("Show_csv_details").style.display = "none"
        toast.error("Please select at least one contact from the list");
        return;
      }else{
        if(IsApply === true){
          if(prospectAllSelectData.length === 0){
            document.getElementById("Show_csv_details").style.display = "none"
            toast.error("Please select at least one contact from the list");
            return;
          }else{
            document.getElementById("Show_csv_details").style.display = "none"
            SetShowPopupReassignSDR(true);
            SetReassignFromSelectedValue([]);
          }
        }else{
          document.getElementById("Show_csv_details").style.display = "none"
          SetShowPopupReassignSDR(true);
          SetReassignFromSelectedValue([]);
        }
      }

  
    };
  const ContactReassignDevPopupHideBtn = () => {
    SetShowPopupReassignDev(false);
  };
  const ContactReassignSDRPopupHideBtn = () => {
    SetShowPopupReassignSDR(false);
  };
  const ContacAddHidePopup = () => {
    SetShowPopup(false);
  };

  // Add contact tag
  // const ContactTagAdd = () => {
  //   // SetAddTagBtnDisabled(true);
  //   if (prospectAllSelectData.length == 0) {
  //     toast.error(
  //       <div className="toastsize">
  //         Please select atleast one contacts from list.
  //       </div>
  //     );
  //     SetAddTagBtnDisabled(false);
  //   } else {
  //     SetShowPopup(true);
  //     var Tag = document.getElementById("TagAdd").value;
  //     if (!Tag) {
  //       document.getElementById("tag_err").style.display = "block";
  //       SetAddTagBtnDisabled(false);
  //     } else {
        
  //       SetIsLoading(true)
  //       var ContactTagData = {
  //         ClientID: ClientID,
  //         UserID: UserID,
  //         Tag: Tag,
  //         ContactTagAArray: prospectAllSelectData,
  //       };
  
  //       Axios({
  //         url: CommonConstants.MOL_APIURL + "/contacts/ContactTagAddToProspect",
  //         method: "POST",
  //         data: ContactTagData,
  //       }).then((res) => {
  //         console.log(res,"res")
  //         console.log(res.data,"res.data")

  //         if (res.data.StatusMessage === "SUCCESS") {
  //           document.getElementById("main-check").checked = false
  //           SetShowPopup(false);
  //           UnSelectCheckHandler();
           
  //           toast.success(
  //             <div>
  //               Contact<br /> Tag added successfully.
  //             </div>
  //           );
  //           console.log("suc")

  //           ContactListGet(ClientID,UserID,Role,1,IsApply,FilterParameters)
  //           ContactListTotal(ClientID, UserID, Role,1,IsApply,FilterParameters);
  //           ContactInfoGetData(ClientID,UserID,Role)
  //           setProspectAllSelectData([])
  //           SetIsLoading(false)
  //           trElement.classList.remove("highlight");
  //         } else {
  //           toast.error(res.data.Message);
  //           SetShowPopup(false);
  //           SetAddTagBtnDisabled(false);
  //           // document.getElementById("hideloding").style.display = "none";
  //           SetIsLoading(false)
  //           console.log("suc")
  //         }
  //       }).catch((error) => {
  //         toast.error("An error occurred. Please try again.");
  //         SetIsLoading(false);
  //       });
  //     }
  //   }
  // };

  // const ContactTagAdd = async () => {
  //   if (prospectAllSelectData.length == 0) {
  //     toast.error(
  //       <div className="toastsize">
  //         Please select at least one contact from the list.
  //       </div>
  //     );
  //     SetAddTagBtnDisabled(false);
  //   } else {
  //     SetShowPopup(true);
  //     const Tag = document.getElementById("TagAdd").value;
  //     if (!Tag) {
  //       document.getElementById("tag_err").style.display = "block";
  //       SetAddTagBtnDisabled(false);
  //     } else {
  //       SetIsLoading(true);
  //       const ContactTagData = {
  //        ClientID: ClientID,
  //        UserID: UserID,
  //        Tag: Tag,
  //       ContactTagAArray: prospectAllSelectData,
  //       };
  
  //       try {
  //         const res = await Axios({
  //           url: CommonConstants.MOL_APIURL + "/contacts/ContactTagAddToProspect",
  //           method: "POST",
  //           data: ContactTagData,
  //         });
  
  //         console.log(res, "res");
  //         console.log(res.data, "res.data");
  
  //         if (res.data.StatusMessage === "SUCCESS") {
  //           document.getElementById("main-check").checked = false;
  //           SetShowPopup(false);
  //           UnSelectCheckHandler();
  //           toast.success(
  //             <div>
  //               Contact<br />Tag added successfully.
  //             </div>
  //           );
  //           prospectAllSelectData.map((item)=>{
  //             const trElement = document.getElementById(item?._id).closest('tr');
  //             if(trElement){ 
  //               trElement.classList.remove("highlight");
  //             }
  //           })
  //           setProspectAllSelectData([]);
  //           ContactInfoGetData(ClientID, UserID, Role);
  //           await ContactListGet(ClientID, UserID, Role, 1, IsApply, FilterParameters);
  //           await ContactListTotal(ClientID, UserID, Role, 1, IsApply, FilterParameters);
  //           SetIsLoading(false);
  //         } else {
  //           toast.error(res.data.Message);
  //           SetShowPopup(false);
  //           SetAddTagBtnDisabled(false);
  //           SetIsLoading(false);
  //           console.log("error");
  //         }
  //       } catch (error) {
  //         // toast.error("An error occurred. Please try again.");
  //         SetIsLoading(false);
  //         console.error("API error:", error);
  //       }
  //     }
  //   }
  // };
  const ContactTagAdd = async () => {
    if (prospectAllSelectData.length === 0) {
      toast.error(
        <div className="toastsize">
          Please select at least one contact from the list.
        </div>
      );
      SetAddTagBtnDisabled(false);
    } else {
      SetShowPopup(true);
      const Tag = document.getElementById("TagAdd").value;
      if (!Tag) {
        document.getElementById("tag_err").style.display = "block";
        SetAddTagBtnDisabled(false);
      } else {
        SetIsLoading(true);
        const ContactTagData = {
          ClientID: ClientID,
          UserID: UserID,
          Tag: Tag,
          ContactTagAArray: prospectAllSelectData,
        };
  
        try {
          const res = await Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/ContactTagAddToProspect",
            method: "POST",
            data: ContactTagData,
          });
  
          if (res.data.StatusMessage === "SUCCESS") {
            document.getElementById("main-check").checked = false;
            SetShowPopup(false);
            UnSelectCheckHandler();
            toast.success(
              <div>
                Contact<br />Tag added successfully.
              </div>
            );
  
            prospectAllSelectData.forEach((item) => {
              const trElement = document.getElementById(item?._id)?.closest('tr');
              if (trElement) {
                trElement.classList.remove("highlight");
              }
            });
            
            setProspectAllSelectData([]);
            setProspectAllSelectData([]);
            // Fetch updated contact data
            await ContactListGet(ClientID, UserID, Role, 1, IsApply, FilterParameters);
            await ContactListTotal(ClientID, UserID, Role, 1, IsApply, FilterParameters);
            await Axios({
              url: CommonConstants.MOL_APIURL + "/contacts/GetContactTags",
              method: "POST",
              data: { ClientID: ClientID, UserID: UserID },
            }).then((res) => {
              SetContactTags(res.data.Data);
            }).catch((error) => {
              toast.error("An error occurred. Please try again.");
              SetIsLoading(false);
            });
            SetIsLoading(false);
          } else {
            toast.error(res.data.Message);
          }
        } catch (error) {
          toast.error("An error occurred. Please try again.");
          console.error("API error:", error);
        } finally {
          // This block executes whether or not an error occurs, ensuring the loader is hidden
          SetIsLoading(false);
          SetAddTagBtnDisabled(false);
          SetShowPopup(false);
        }
      }
    }
  };
  
    const ProspectCheckHandler = (e, row) => { 
      document.getElementById("Show_csv_details").style.display = "none"
      const trElement = e.target.closest('tr');
        // Extract only the _id and email from the row
        const { _id, Email, Tagname,ValidationScore} = row;
        const newRow = { _id, Email, Tagname ,ValidationScore};
      if (e.target.checked) {  
        trElement.classList.add("highlight");
        setProspectAllSelectData(prevData => {
          if (!prevData.find(item => item._id === newRow._id)) {
            const updatedData = [...prevData, newRow];
            updateLengthAndDisplay(updatedData);
            return updatedData;
          }
          return prevData;
        });
      } else {
        setProspectAllSelectData(prevData => {
          trElement.classList.remove("highlight"); 
          const updatedData = prevData.filter(item => item._id !== newRow._id);
          updateLengthAndDisplay(updatedData);
          return updatedData;
        });
      }
    };

    const updateLengthAndDisplay = (updatedData) => {
      const updatedLength = updatedData?.length;
      var selectcount = data?.length;
      
      const PerPageCheckCountS = data?.filter((item) =>
      updatedData.some((paitem) => item._id === paitem._id)
  );

      // Update the display of "deleteall-btn" button based on the length of prospectAllSelectData
      if (updatedLength > 0) {
        document.getElementById("deleteall-btn").style.display = "block";
        // document.getElementById("main-check").checked = true;
      } else {
        document.getElementById("deleteall-btn").style.display = "none";
        // document.getElementById("main-check").checked = false;
      }
      const SPagecheckcount = PerPageCheckCountS.length
      if ( SPagecheckcount === selectcount) {
        document.getElementById("main-check").checked = true;
        document.getElementById("Select-All").style.display = "none";
        document.getElementById("UnSelect").style.display = "block";
      } else {
        
        document.getElementById("main-check").checked = false;
        document.getElementById("Select-All").style.display = "block";
        document.getElementById("UnSelect").style.display = "none";
      }
    };
    

  // all check handler
  const CheckHandler = (e) => { 
    document.getElementById("Show_csv_details").style.display = "none"
    rows?.map((val) => {
      if (e.target.checked) {
        document.getElementById(val?._id).checked = true;  
        const trElement = document.getElementById(val?._id).closest('tr');
        if(trElement){ 
          trElement.classList.add("highlight");
        }  
      } else if (!e.target.checked) {
        document.getElementById(val?._id).checked = false;
        const trElement = document.getElementById(val?._id).closest('tr');
        if(trElement){ 
          trElement.classList.remove("highlight");
        }  
      }
    });

    var countss
    if (e.target.checked) {
      // Select all rows
      const selectedRows = rows.filter((val) => !prospectAllSelectData.some((item) => item._id === val._id));
      const updatedArray = [...prospectAllSelectData, ...selectedRows];
      countss == selectedRows
      SetPageCountForCheck(updatedArray)
      setProspectAllSelectData(updatedArray);
      document.getElementById("deleteall-btn").style.display = "block";
      document.getElementById("Select-All").style.display = "none";
      document.getElementById("UnSelect").style.display = "block";
    } else {
      // Select all rows
      const selectedRows = prospectAllSelectData.filter((val) => !rows.some((item) => item._id === val._id));
      setProspectAllSelectData(selectedRows);
      document.getElementById("deleteall-btn").style.display = "none";
      document.getElementById("Select-All").style.display = "block";
      document.getElementById("UnSelect").style.display = "none";
    }
  };

  
  const SelectCheckHandler = async () => {
    document.getElementById("main-check").checked = true;
    try {
      var listids = null;
      if (location.state && location.state.data) {
        listids = location.state.data;
      }
      // ContactListGet(ClientID,UserID,Role,page)
    var SearchParmas =  document.getElementById("search").value;
      // ContactListTotal(ClientID, UserID, Role,page,IsApply,FilterParameters);
      document.getElementById("Show_csv_details").style.display = "none"
       SetIsLoading(true); // Show the loader
      
      // Your other logic here...
      const Data = {
        ClientID: ClientID,
        AccountType: AccountTypeForTab,
        IsApply:IsApply,
        FilterPropertiesArray:FilterParameters,
        ListID:listids,
        Search:SearchParmas
      };
  
      const result = await Axios.post(
        CommonConstants.MOL_APIURL + "/contacts/GetContactSelectAll",
        Data
      );
  
      setProspectAllSelectData(result.data?.Data);
      // SetContactDataAll(result.data?.Data);
  
      // Update UI elements
      document.getElementById("deleteall-btn").style.display = "block";
      document.getElementById("UnSelect").style.display = "block";
      document.getElementById("Select-All").style.display = "none";
      document.getElementById("main-check").checked = true;

      SetIsLoading(false); // Hide the loader
    } catch (error) {
      var Method =
        "Getting Error contact delete delete User log(Repository(ContactDeleteSelect))";
      LogData.LogFileData(error, Method);
      SetIsLoading(false); // Hide the loader in case of an error
    }
  };
  
  // all check handler
  const UnSelectCheckHandler = () => {
    document.getElementById("deleteall-btn").style.display = "none";
    document.getElementById("Select-All").style.display = "block";
    document.getElementById("UnSelect").style.display = "none";
    document.getElementById("main-check").checked = false;
    SetIsResetApply(false)
    setProspectAllSelectData([])
  };

  //get sort field data
  // const SortData = async (Field) => {
  //   var listids = null;
  //   if (location.state && location.state.data) {
  //     listids = location.state.data;
  //   }
  //   SetIsLoading(true);
  //   var searchedVal = document.getElementById("search").value;
  //   var SerchBox;
  
  //   try {
  //     if (searchedVal === "") {
  //       SerchBox = false;
  //       setsflag(false);
  //       var SortField = Field;
  //       var SortBy;
  //       if (sortedBy === -1) {
  //         SortBy = 1;
  //         setsortedBy(1);
  //       } else {
  //         SortBy = -1;
  //         setsortedBy(-1);
  //       }
  //       setsortField(Field);
  
  //       var InputParameter = {
  //         Page: page,
  //         RowsPerPage: RowsPerPage,
  //         SerchBox: false,
  //         Archive: false,
  //         Sort: true,
  //         Field: SortField,
  //         SortBy: SortBy,
  //         Type: "User",
  //         ClientID: ClientID,
  //         Search: search,
  //         UserID: UserID,
  //         Role: Role,
  //         AccountType: AccountTypeForTab,
  //         IsApply: IsApply,
  //         FilterPropertiesArray: FilterParameters,
  //         IsTotalCount: TotalCountFlag,
  //         ListID:listids
  //       };
  
  //       const ContactGetList = await Axios({
  //         url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
  //         method: "POST",
  //         data: InputParameter,
  //       });
  
  //       setRows(ContactGetList.data.PageData);
  //       // setrlen(ContactGetList.data.TotalCount);
  //       // setflen(ContactGetList.data.TotalCount);
  //       // setCountPage(ContactGetList.data.PageCount);
  //     } else {
  //       SerchBox = true;
  //       setsflag(true);
  //       var SortField = Field;
  //       var SortBy;
  //       if (sortedBy === 1) {
  //         SortBy = -1;
  //         setsortedBy(-1);
  //       } else {
  //         SortBy = 1;
  //         setsortedBy(1);
  //       }
  //       setsortField(Field);
  
  //       var InputParameter = {
  //         Page: page,
  //         RowsPerPage: RowsPerPage,
  //         SerchBox: false,
  //         Archive: false,
  //         Sort: true,
  //         Field: SortField,
  //         SortBy: SortBy,
  //         Type: "User",
  //         Search: search,
  //         ClientID: ClientID,
  //         UserID: UserID,
  //         Role: Role,
  //         AccountType: AccountTypeForTab,
  //         IsApply: IsApply,
  //         FilterPropertiesArray: FilterParameters,
  //         IsTotalCount: TotalCountFlag,
  //         ListID:listids
  //       };
  
  //       const ContactGetList = await Axios({
  //         url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
  //         method: "POST",
  //         data: InputParameter,
  //       });
  
  //       setRows(ContactGetList.data.PageData);
  //       // setrlen(ContactGetList.data.TotalCount);
  //       // setflen(ContactGetList.data.TotalCount);
  //       // setCountPage(ContactGetList.data.PageCount);
  
  //       // document.getElementById("hideloding").style.display = "none";
  //     }
  
  //     SetIsLoading(false);
  //   } catch (error) {
  //     toast.error("An error occurred. Please try again.");
  //     SetIsLoading(false);
     
  //   }
  // };
  const SortData = async (Field) => {
    CloseCsvDetails();
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value;
    const isSearchEmpty = SearchedVal === "";

    let newSortValue;

    // Determine the new sort value
    if (lastSortedField === Field) {
        // If the same field is clicked, toggle the sort order
        newSortValue = sortedBy === 1 ? -1 : 1; // Toggle between -1 and 1
    } else {
        // If a new field is clicked, default to ascending
        newSortValue = 1;
    }

    // Set the sort value and the current sorted field
    setsortedBy(newSortValue);
    setsortField(Field);
    setLastSortedField(Field); // Update the last sorted field

    const InputParameter = {
        Page: page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: !isSearchEmpty,
        Sort: true,
        Field: Field,
        SortBy: newSortValue,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        AccountType: AccountTypeForTab,
        IsApply: IsApply,
        FilterPropertiesArray: FilterParameters,
        IsTotalCount: TotalCountFlag,
        ListID: location.state && location.state.data ? location.state.data : null, // Get list ids from location
    };

    try {
        const ContactGetList = await Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
            method: "POST",
            data: InputParameter,
        });
        setRows(ContactGetList.data.PageData);
    } catch (error) {
       console.log("error")
    } finally {
        SetIsLoading(false);
    }
};
  

  let contactoptions = ContactTags?.map((val) => {
    return { value: val?.Tag, label: val?.Tag, cid: val?._id };
  });
  // contact tags method 2
  let ContactTagsArr = ContactTags?.map((val) => {
    return { value: val?.ContactTagID, label: val?.Tag, cid: val?.ContactTagID };
  });

//OpenFilter Model 
const OpenFilterModelMethod = () => {
  SetOpenFilterModel(true)
}

//Close Filter Model
const CloseFilterModelMethod = () => {
  SetOpenFilterModel(false)
  SetOpenAddFilter(false)
  
}

//Open AddFilterModel
const OpenAddFilterModelMethod = () => {
  SetOpenAddFilterModel(true)
  CloseFilterModelMethod()
}

//Close Add Filter Model method
const CloseAddFilterModelMethod = () => {
  SetOpenAddFilterModel(false)
  CloseFilterModelMethod();



}

//Open Edit Filter Model
const OpenEditFilterModelMethod = () => {
  
  CloseFilterModelMethod();
  if(FilterName.length>0){
    SetOpenEditFilterModel(true);
    SetIsLoading(true)
    var InputParameters = {
      ClientID: ClientID,
    };

    const rows3 = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/GetContactFilterDropDown",
      method: "POST",
      data: InputParameters,
    });
    rows3.then((result) => {
      SetFilterName(result.data.Data);
      if (result.data.Data.length > 0) {
        const ObjectFilterID = result.data.Data[0]._id
        SetFilterID(result.data.Data[0]._id)
        // Getfilterpropertylist(result.data.Data[0]._id);
        var InputParameters = {
          ClientID: ClientID,
          FilterID: ObjectFilterID,
          IsDeleted: false,
        };
        const rows4 = Axios({
          url:
            CommonConstants.MOL_APIURL + "/contacts/GetContactEditFilterProperties",
          method: "POST",
          data: InputParameters,
        });
        rows4.then((result) => {
          let FilteredArr = [];
          result?.data?.Data?.forEach((el, i) => {
            el.id = i;
            el.IsSaved = true;
            if (
              el.FilterPropertyName == "LastSendDate" ||
              el.FilterPropertyName == "LastValidationDate"
            ) {
              el.IsDate = true;
            } else {
              if (el.FieldType == "Text") {
                el.IsCustom = true;
              } else {
                el.IsCustom = false;
              }
              el.IsDate = false;
            }
            if (el.FilterPropertyName == "IsBlacklisted") {
              el.IsBool = true;
            } else {
              el.IsBool = false;
            }
            if (
              el.FilterPropertyName == "CallCount" ||
              el.FilterPropertyName == "TotalSent" ||
              el.FilterPropertyName == "TotalHardBounce" ||
              el.FilterPropertyName == "TotalOpen" ||
              el.FilterPropertyName == "TotalReply" ||
              el.FilterPropertyName == "HoneyScore" ||
              el.FilterPropertyName == "TotalSoftBounce"
            ) {
              el.IsNum = true;
            } else {
              el.IsNum = false;
            }
            if (el.FilterPropertyName == "ContactTag") {
              el.IsSelect = true;
              el.Expression = "EqualTo";
    
              let ContactTagIds = el.FilterPropertyValue1?.split(",");
              let ArrayObj = [];
    
              ContactTagIds.forEach((elem) => {
                let Indexed = ContactTags?.findIndex((v) => v.ContactTagID == elem);
                ArrayObj.push({
                  label: ContactTags[Indexed]?.Tag,
                  value: ContactTags[Indexed]?.ContactTagID,
                  cid: ContactTags[Indexed]?.ContactTagID,
                });
              });
              el.DefaultSelectIDs = ArrayObj;
            } else {
              el.IsSelect = false;
              el.DefaultSelectIDs = [];
            }
            if (
              el.ExpressionName == "IsNotBlank" ||
              el.ExpressionName == "IsBlank"
            ) {
              el.NullCondition = true;
            } else {
              el.NullCondition = false;
            }
            FilteredArr.push(el);
          });
 
          SetEditFieldAttributes(FilteredArr);
          SetIsLoading(false);
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
        // SetIsLoading(false);
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
    // Getfilterlist(ClientID);
  }else{

    toast.error(
      <div className="toastsize">
        Filter
        <br />
        No filter available
      </div>
    );
  }
}

//Close Edit Filter Model method
const CloseEditFilterModelMethod = () => {
  SetOpenEditFilterModel(false);
  CloseFilterModelMethod();
  

}

//Open Save As Filter Model
const OpenSaveAsFilterModelMethod = () => {
  SetOpenSaveAsFilterModel(true);
  CloseFilterModelMethod();
}

//Close Save As Filter Model
const CloseSaveAsFilterModelMethod = () => {
  SetOpenSaveAsFilterModel(false)
  CloseFilterModelMethod();
  


}

//Close all models
const CloseAllModels = () => {
  CloseFilterModelMethod();
  CloseSaveAsFilterModelMethod();
  CloseEditFilterModelMethod();
  CloseAddFilterModelMethod();
}

  //Get Custom fields
  const GetCustomFiledsData = (CID, UID, Role) => {
    const InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/GetCustomFields",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      
      let Arr = [
        {
          FieldValue: "AccountName",
          ColumnName: "Account Name",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "AccountCategory",
          ColumnName: "Account Category",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "AccountType",
          ColumnName: "AccountType",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Address1",
          ColumnName: "Address1",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Address2",
          ColumnName: "Address2",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "BestPhone",
          ColumnName: "Best Phone",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "BestPhoneStatus",
          ColumnName: "Best Phone Status",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "CallCount",
          ColumnName: "Call Count",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "City",
          ColumnName: "City",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ClientPOC",
          ColumnName: "Client POC",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Company",
          ColumnName: "Company",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "CompanyPhone",
          ColumnName: "Company Phone",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactCategory",
          ColumnName: "Contact Category",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactOwner",
          ColumnName: "Contact Owner",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactSource",
          ColumnName: "Contact Source",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactStatus",
          ColumnName: "Contact Status",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Country",
          ColumnName: "Country",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalSent",
          ColumnName: "Deliveries",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "LastCallResult",
          ColumnName: "Disposition Status",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "HoneyScore",
          ColumnName: "Honey Score",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Email",
          ColumnName: "Email",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "FirstName",
          ColumnName: "First Name",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalHardBounce",
          ColumnName: "Hard Bounce",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Industry",
          ColumnName: "Industry",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "IsBlacklisted",
          ColumnName: "Is Blacklisted",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "LastName",
          ColumnName: "Last Name",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "LastSendDate",
          ColumnName: "Last Send Date",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "LastValidationDate",
          ColumnName: "Last Validation Date",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "LinkedInURL",
          ColumnName: "LinkedIn URL",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ListName",
          ColumnName: "List Name",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "MobilePhone",
          ColumnName: "Mobile Phone",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalOpen",
          ColumnName: "Open",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Phone1",
          ColumnName: "Phone",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Phone1Status",
          ColumnName: "Phone Status",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Phone2",
          ColumnName: "Phone2",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Phone2Status",
          ColumnName: "Phone2 Status",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Phone3",
          ColumnName: "Phone3",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Phone3Status",
          ColumnName: "Phone3 Status",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Phone4",
          ColumnName: "Phone4",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Phone4Status",
          ColumnName: "Phone4 Status",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "PRLLeadID",
          ColumnName: "External ID",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ReasonUnqualified",
          ColumnName: "Reason Unqualified",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalReply",
          ColumnName: "Replies",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ColdCallersName",
          ColumnName: "Sales Development Reps",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "SalesRep",
          ColumnName: "Sales Rep",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "TotalSoftBounce",
          ColumnName: "Soft Bounce",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "State",
          ColumnName: "State",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ContactTag",
          ColumnName: "Tag",
          Expression: "EqualTo",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Title",
          ColumnName: "Title",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ValidationScore",
          ColumnName: "Validation Score",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Website",
          ColumnName: "Website",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Zip",
          ColumnName: "Zip",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
      ];
      res.data.Data.forEach((el) => {
        
        Arr.push({
          FieldValue: el.FieldName,
          ColumnName: el.FieldName,
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
          FieldFor: "Text",
          ContactTagIds: "",
          IsCustom: true,
          IsSelect: false,
          IsBool: false,
          CustomFieldID:el?._id
        });
      });
      let ChangedArr = [];
      Arr.forEach((el, i) => {
        if (
          el.FieldValue == "LastSendDate" ||
          el.FieldValue == "LastValidationDate"
        ) {
          ChangedArr.push({
            id: i,
            FieldValue: el.FieldValue,
            ColumnName: el.ColumnName,
            Expression: "EqualTo",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            FieldFor: "",
            ContactTagIds: "",
            IsCustom: false,
            IsDate: true,
            NullCondition: false,
            IsNum: false,
            IsSelect: false,
            IsBool: false,
          });
        } else {
          if (el.IsCustom == true) {
            ChangedArr.push({
              id: i,
              FieldValue: el.FieldValue,
              ColumnName: el.ColumnName,
              Expression: "Contains",
              FilterPropertyValue: "",
              FilterPropertyValue1: "",
              FieldFor: "Text",
              ContactTagIds: "",
              IsCustom: true,
              IsDate: false,
              NullCondition: false,
              IsNum: false,
              IsSelect: false,
              IsBool: false,
              CustomFieldID:el?.CustomFieldID
            });
          } else {
            if (el.ColumnName == "Tag") {
              ChangedArr.push({
                id: i,
                FieldValue: el.FieldValue,
                ColumnName: el.ColumnName,
                Expression: "EqualTo",
                FilterPropertyValue: "",
                FilterPropertyValue1: "",
                FieldFor: "",
                ContactTagIds: "",
                IsCustom: false,
                IsDate: false,
                NullCondition: false,
                IsNum: false,
                IsSelect: false,
                IsBool: false,
              });
            } else {
              ChangedArr.push({
                id: i,
                FieldValue: el.FieldValue,
                ColumnName: el.ColumnName,
                Expression: "Contains",
                FilterPropertyValue: "",
                FilterPropertyValue1: "",
                FieldFor: "",
                ContactTagIds: "",
                IsCustom: false,
                IsDate: false,
                NullCondition: false,
                IsNum: false,
                IsSelect: false,
                IsBool: false,
              });
            }
          }
        }
      });
      
      let SortedArr = ChangedArr.sort(function (a, b) {
        return a.ColumnName.toLowerCase().localeCompare(
          b.ColumnName.toLowerCase()
        );
      });
      
      SetCustomColumnData(SortedArr);
      setContactCustomFieldData(res.data.Data);
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    });
  };

  //Column handled Dropdown Change
  const ColumnhandledDropdownChange = (id, Index) => {
    
    let ColumnName = document.getElementById("ColumnName" + id).value;

    let FieldAttributesDatas = [];
    FieldAttributes.forEach((el) => {
      FieldAttributesDatas.push(el);
    });
    let indexed = CustomColumnData.findIndex((v) => v.FieldValue === ColumnName);
    FieldAttributesDatas[Index].FieldValue = ColumnName;
    FieldAttributesDatas[Index].IsCustom = CustomColumnData[indexed].IsCustom;
    FieldAttributesDatas[Index].IsDate = CustomColumnData[indexed].IsDate;
    if (ColumnName == "IsBlacklisted") {
      FieldAttributesDatas[Index].IsBool = true;
    } else {
      FieldAttributesDatas[Index].IsBool = false;
    }
    if ( ColumnName == "CallCount" ||ColumnName == "TotalSent" 
      ||ColumnName == "TotalHardBounce" ||ColumnName == "TotalOpen" 
      ||ColumnName == "TotalReply" ||ColumnName == "TotalSoftBounce" || 
      ColumnName == "HoneyScore") {
      FieldAttributesDatas[Index].IsNum = true;
    } else {
      FieldAttributesDatas[Index].IsNum = false;
    }
    if (ColumnName == "ContactTag") {
      FieldAttributesDatas[Index].IsSelect = true;
      FieldAttributesDatas[Index].Expression = "EqualTo";
    } else {
      FieldAttributesDatas[Index].IsSelect = false;
    }
    if (CustomColumnData[indexed].IsCustom == true) {
      FieldAttributesDatas[Index].FieldFor = "Text";
    } else {
      FieldAttributesDatas[Index].FieldFor = "";
    }
    FieldAttributesDatas[Index].FilterPropertyValue = "";
    FieldAttributesDatas[Index].FilterPropertyValue1 = "";
    FieldAttributesDatas[Index].Expression = "Contains";
    document.getElementById("expression" + id).value = "Contains";
    document.getElementById("searchvalue" + id).value = "";
    FieldAttributesDatas[Index].NullCondition = false;
    // document.getElementById("EndsWith" + id).value = "";
    
    SetFieldAttributes(FieldAttributesDatas);
  };

  // Expression Handler drop down change
  const ExpressionHandler = (id) => {
    let StateArr = [];
    FieldAttributes.forEach((el) => {
      StateArr.push(el);
    });
    let ColumnName = document.getElementById("ColumnName" + id).value;
    let ExpressionName = document.getElementById("expression" + id).value;
    let indexed = StateArr.findIndex((v) => v.id === id);
    if (indexed == -1) {
      StateArr.push({
        id: id,
        ColumnName: ColumnName,
        Expression: ExpressionName,
      });
    } else {
      StateArr[indexed].ColumnName = ColumnName;
      StateArr[indexed].Expression = ExpressionName;
    }
    if (ExpressionName == "IsNotBlank" || ExpressionName == "IsBlank") {
      StateArr[indexed].NullCondition = true;
      document.getElementById("searchvalue" + id).value = "";
    } else {
      StateArr[indexed].NullCondition = false;
    }

    SetFieldAttributes(StateArr);
  };

  //Select box handler
  const FilterhandleChangeContactOption = (val, id) => {
    let Arr = [];
    FieldAttributes.forEach((el) => {
      Arr.push(el);
    });
    let CidArr = [];
    val?.forEach((elem) => {
      CidArr.push(elem.cid);
    });

    let indexed = Arr.findIndex((v) => v.id === id);
    Arr[indexed].ContactTagIds = CidArr.toString();

    document.getElementById("searchvalue" + id).value = CidArr.toString();
    SetFieldAttributes(Arr);
  };

  //Add filter property
  const handleClick = () => {
    var StateArray = [];
    FieldAttributes.forEach((el) => {
      StateArray.push(el);
    });
    StateArray.push({
      id: Math.floor(Math.random() * 1000000000),
      FieldValue: "AccountCategory",
      ColumnName: "Account Category",
      Expression: "Contains",
      FilterPropertyValue: "",
      FilterPropertyValue1: "",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      NullCondition: false,
    });
    SetFieldAttributes(StateArray);
  };

  //remove filter property
  const handleRemoveClick = (id) => {
    var countArray2 = [];
    FieldAttributes.map((element) => {
      if (element.id != id) {
        countArray2.push(element);
      }
    });
    SetFieldAttributes(countArray2);
  };

  // filter validation
  const FilterValidation = async() => {
    let ValidationArr = [];
    FieldAttributes.map((newvalue) => {
      if (newvalue.IsBool == false) {
        var searchedVal1 = document.getElementById(
          "searchvalue" + newvalue.id
        ).value;

        if (searchedVal1.trim() == "") {
          ValidationArr.push(false);
        } else {
          ValidationArr.push(true);
        }
        var expressionval = document.getElementById(
          "expression" + newvalue.id
        ).value;

        if (expressionval == "between") {
          let FilterPropertyValue1 = document.getElementById(
            "searchvalue" + newvalue.id
          ).value;
          let FilterPropertyValue2 = document.getElementById(
            "EndsWith" + newvalue.id
          ).value;
          if(newvalue.IsNum){
            if (FilterPropertyValue2.trim() == "" || parseFloat(FilterPropertyValue1.trim()) > parseFloat(FilterPropertyValue2.trim())) {
              ValidationArr.push(false);
            } else {
              ValidationArr.push(true);
            }
          }else{
            if (FilterPropertyValue2.trim() == "") {
              ValidationArr.push(false);
            } else {
              ValidationArr.push(true);
            }
          }
        }
      }
    });

    return ValidationArr.includes(false);
  };

  //Apply Filter
  // const filterSearch = async () => {
  //   SetIsLoading(true)


  //   if ((await FilterValidation()) == false) {
  //     if (FieldAttributes.length <= 0) {
  //       toast.error(<div className="toastsize"> Filter <br /> Please add filter property at least one</div>);
  //       SetIsLoading(false)
  //     } else {
      
  //       SetIsLoading(true)
  //       var ContactFilterSecArray = [];
  //       FieldAttributes.map((newvalue) => {
  //         var searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value.trim();
  //         var expressionval = document.getElementById("expression" + newvalue.id).value.trim();
  //         var ColumnName = document.getElementById( "ColumnName" + newvalue.id).value;

  //         let MatchObject = CustomColumnData.find(item => item.FieldValue == ColumnName)
          
  //         if (expressionval == "between") {
  //           var searchedVal2 = document.getElementById("EndsWith" + newvalue.id).value;
  //           ContactFilterSecArray.push({
  //             ColumnName: ColumnName,
  //             expression: expressionval,
  //             searchvalue: searchedVal1,
  //             searchvalue1: searchedVal2,
  //             FieldType: newvalue.FieldFor,
  //             IsCustom:newvalue.IsCustom
  //           });
  //         } else {
  //           ContactFilterSecArray.push({
  //             ColumnName: ColumnName,
  //             expression: expressionval,
  //             searchvalue: searchedVal1,
  //             searchvalue1: searchedVal2,
  //             FieldType: newvalue.FieldFor,
  //             IsCustom:newvalue.IsCustom,
  //             CustomFieldID:MatchObject?.CustomFieldID
  //           });
  //         }
  //       });

  //        // Update states and call API sequentially
  //       //  setPage(1); // Ensure this runs first
  //       //  SetFilterParameters(ContactFilterSecArray);
  //       //  SetIsApply(true);
  //       // SetIsLoading(false)
  //       // setPage(1)
  //       // SetFilterParameters(ContactFilterSecArray);
  //       // SetIsApply(true);
  //       SetIsFiltered(true);
  //       CloseAllModels();

        
  //      // Group state updates using ReactDOM.unstable_batchedUpdates
  //      ReactDOM.unstable_batchedUpdates(() => {
  //       setPage(1);
  //       SetFilterParameters(ContactFilterSecArray);
  //       SetIsApply(true);
  //   });

  //   // Call ContactListGet with the new states
  //   await ContactListGet(ClientID, UserID, Role, 1, true, ContactFilterSecArray);
  //       // ContactListGetFilter(ClientID, UserID, Role,ContactFilterSecArray,true,1,RowsPerPage,"")
  //       // SetFieldAttributes([]);
  //       // SetFieldAttributes([
  //       //   {
  //       //     id: Math.floor(Math.random() * 1000000000),
  //       //     FieldValue: "AccountCategory",
  //       //     ColumnName: "Account Category",
  //       //     Expression: "Contains",
  //       //     FilterPropertyValue: "",
  //       //     FilterPropertyValue1: "",
  //       //     IsCustom: false,
  //       //     IsDate: false,
  //       //     IsBool: false,
  //       //     NullCondition: false,
  //       //   },
  //       // ]);

  //       toast.success(<div className="toastsize">Filter <br />Filter Apply SuccessFully.</div>);
  //     }
  //   } else {
  //     toast.error(<div className="toastsize">Filter<br />Property value can not be an empty</div>);
  //     SetIsLoading(false)
  //   }
  // };
  const filterSearch = async () => {
    SetIsLoading(true);

    if ((await FilterValidation()) == false) {
        if (FieldAttributes.length <= 0) {
            toast.error(<div className="toastsize"> Filter <br /> Please add filter property at least one</div>);
            SetIsLoading(false);
        } else {
       var ContactFilterSecArray = [];
        FieldAttributes.map((newvalue) => {
          
          var searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value.trim();
          var expressionval = document.getElementById("expression" + newvalue.id).value.trim();
          var ColumnName = document.getElementById( "ColumnName" + newvalue.id).value;

          let MatchObject = CustomColumnData.find(item => item.FieldValue == ColumnName)
          
          if (expressionval == "between") {
            var searchedVal2 = document.getElementById("EndsWith" + newvalue.id).value;
            ContactFilterSecArray.push({
              ColumnName: ColumnName,
              expression: expressionval,
              searchvalue: searchedVal1,
              searchvalue1: searchedVal2,
              FieldType: newvalue.FieldFor,
              IsCustom:newvalue.IsCustom
            });
          } else {
            ContactFilterSecArray.push({
              ColumnName: ColumnName,
              expression: expressionval,
              searchvalue: searchedVal1,
              searchvalue1: searchedVal2,
              FieldType: newvalue.FieldFor,
              IsCustom:newvalue.IsCustom,
              CustomFieldID:MatchObject?.CustomFieldID
            });
          }
        });
        SetFilterParameters(ContactFilterSecArray)
        SetIsApply(true)
        setfilterPage(1)
            // Call ContactListGet with the new states
            try {
                await ContactListGet(ClientID, UserID, Role, 1, true, ContactFilterSecArray);
                await ContactListTotal(ClientID,UserID,Role,1,true,ContactFilterSecArray);
                SetIsResetApply(true)
                SetIsLoading(false);
                toast.success(<div className="toastsize">Filter <br />Filter Apply Successfully.</div>);
            } catch (error) {
                toast.error("An error occurred. Please try again.");
                SetIsLoading(false);
            }

            CloseAllModels();
        }
    } else {
        toast.error(<div className="toastsize">Filter<br />Property value can not be empty or invalid range</div>);
        SetIsLoading(false);
    }
};

  //Reset Filter
  const ResetFilter = async () => {
    // Reset search and filter parameters
    document.getElementById("search").value = "";
    setSearch("");
    setfilterSearchParams("")
    SetIsApply(false);
    SetIsResetApply(false)
    SetFilterParameters([]);
    setProspectAllSelectData([]);
    setPage(1)
    SetRowsPerPage(100)
    SefiltertRowsPerPage(100)
    // Determine list IDs
    let listids = null;
    if (location.state && location.state.data) {
      listids = location.state.data;
    }
  
    // Prepare the first API call payload
    let str_in = {
      Page: 1,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: sortField,
      SortBy: sortedBy,
      Search: search,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      AccountType: AccountTypeForTab,
      IsApply: false,
      FilterPropertiesArray: [],
      IsTotalCount: TotalCountFlag,
      Role: Role,
      ListID: listids,
    };
  
    try {
      SetIsLoading(true);
  
      // Make the first API call
      let result1 = await Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
        method: "POST",
        data: str_in,
      });
  
      if (result1.data?.StatusMessage === "SUCCESS") {
        setdata(result1.data?.PageData);
        setRows(result1.data?.PageData);
        SetTableRowLength(result1.data?.PageData?.length);
      } else {
        const errorMessage = result1?.data?.Message || "Unknown error";
        toast.error(errorMessage);
      }
  
      // Prepare the second API call payload
      str_in.IsTotalCount = !TotalCountFlag;
  
      // Make the second API call
      let result2 = await Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactGet",
        method: "POST",
        data: str_in,
      });
  
      if (result2.data?.StatusMessage === "SUCCESS") {
        setrlen(result2.data?.TotalCount);
        setflen(result2.data?.TotalCount);
        setCountPage(result2.data?.PageCount);
      } else {
        const errorMessage = result2?.data?.Message || "Unknown error";
        toast.error(errorMessage);
      }
  
    } catch (error) {
      toast.error(`Error: ${error?.message}`);
    } finally {
      SetIsLoading(false);
    }
  };
  

  //Check filter name exists or not
  const CheckFilterName = async () => {
    let FilterName = document.getElementById("FilterName").value.trim();
    let flag = false;
    const InputParameters = {
      ClientID: ClientID,
      FilterName: FilterName,
    };
    let res = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CheckContactFilterName",
      method: "POST",
      data: InputParameters,
    });
    if (res.data.StatusMessage == "SUCCESS") {
      if (res.data.Data <= 0) {
        flag = true;
      } else {
        flag = false;
      }
    } else {
      toast.error("Something went wrong in filter name exists api");
    }

    return flag;
  };

  // Save Filter Property
  const addfilterContactfilter = async () => {
    SetIsLoading(true)
    SetDisabledAddFilter(true)
    let FilterName = document.getElementById("FilterName").value.trim();
    if (FilterName == "") {
      toast.error(<div className="toastsize"> Filter<br />Please enter filter name</div>);
      SetDisabledAddFilter(false)
      SetIsLoading(false)
    } else {
      if ((await CheckFilterName()) == false) {
        toast.error(<div className="toastsize"> Filter<br />Filter value already exist</div>);
        SetDisabledAddFilter(false)
        SetIsLoading(false)
      } else {
        if ((await FilterValidation()) == false) {
          if (FieldAttributes.length <= 0) {
            toast.error(<div className="toastsize">Filter<br />Please add filter property at least one</div>);
            SetDisabledAddFilter(false)
            SetIsLoading(false)
          } else {
            var ContactFilterSecArray = [];

            FieldAttributes.map((newvalue) => {
              var searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value;
              var expressionval = document.getElementById("expression" + newvalue.id).value;
              var Cloumnval = document.getElementById("ColumnName" + newvalue.id).value;
              let MatchObject = CustomColumnData.find(item => item.FieldValue == Cloumnval)
          
              if (expressionval == "between") {
                var searchedVal2 = document.getElementById("EndsWith" + newvalue.id).value;
                ContactFilterSecArray.push({
                  ColumnName: Cloumnval,
                  expression: expressionval,
                  searchvalue: searchedVal1,
                  searchvalue1: searchedVal2,
                  FieldType: newvalue.FieldFor,
                });
              } else {
                ContactFilterSecArray.push({
                  ColumnName: Cloumnval,
                  expression: expressionval,
                  searchvalue: searchedVal1,
                  searchvalue1: null,
                  FieldType: newvalue.FieldFor,
                  CustomFieldID: MatchObject?.CustomFieldID,
                  IsCustom: MatchObject?.IsCustom,
                });
              }
            });

            const InputParameters = {
              ClientID: ClientID,
              FilterName: FilterName,
              FilterParameters: ContactFilterSecArray,
              CreatedDate: new Date(),
              UserID: UserID,
            };

            let res = await Axios({
              url: CommonConstants.MOL_APIURL + "/contacts/SaveContactFilter",
              method: "POST",
              data: InputParameters,
            });

            if (res.data.StatusMessage == "SUCCESS") {
              SetFilterParameters(ContactFilterSecArray)
              SetIsApply(true)
              setfilterPage(1)  

              // Call ContactListGet with the new states
            await ContactListGet(ClientID, UserID, Role, 1, true, ContactFilterSecArray);
            await ContactListTotal(ClientID,UserID,Role,1,true,ContactFilterSecArray);
              // SetFilterParameters([]);
              SetOpenAddFilter(false);
              SetIsResetApply(true)
              CloseAllModels();
              SetFieldAttributes([
                {
                  id: Math.floor(Math.random() * 1000000000),
                  FieldValue: "AccountCategory",
                  ColumnName: "Account Category",
                  Expression: "Contains",
                  FilterPropertyValue: "",
                  FilterPropertyValue1: "",
                  IsCustom: false,
                  IsDate: false,
                  IsBool: false,
                  NullCondition: false,
                },
              ]);
              // Getfilterlist(ClientID);
              var InputParametersFilter = {
                ClientID: ClientID,
              };
          
              const rows3 = Axios({
                url: CommonConstants.MOL_APIURL + "/contacts/GetContactFilterDropDown",
                method: "POST",
                data: InputParametersFilter,
              });
              rows3.then((result) => {
                SetFilterName(result.data.Data);
                if (result.data.Data.length > 0) {

                    const ObjectFilterID = result.data.Data[0]._id
                    SetFilterID(result.data.Data[0]._id)
                    // Getfilterpropertylist(result.data.Data[0]._id);
                    var InputParameters = {
                      ClientID: ClientID,
                      FilterID: ObjectFilterID,
                      IsDeleted: false,
                    };
                    const rows4 = Axios({
                      url:
                        CommonConstants.MOL_APIURL + "/contacts/GetContactEditFilterProperties",
                      method: "POST",
                      data: InputParameters,
                    });
                    rows4.then((result) => {
                      let FilteredArr = [];
                      result?.data?.Data?.forEach((el, i) => {
                        el.id = i;
                        el.IsSaved = true;
                        if (
                          el.FilterPropertyName == "LastSendDate" ||
                          el.FilterPropertyName == "LastValidationDate"
                        ) {
                          el.IsDate = true;
                        } else {
                          if (el.FieldType == "Text") {
                            el.IsCustom = true;
                          } else {
                            el.IsCustom = false;
                          }
                          el.IsDate = false;
                        }
                        if (el.FilterPropertyName == "IsBlacklisted") {
                          el.IsBool = true;
                        } else {
                          el.IsBool = false;
                        }
                        if (
                          el.FilterPropertyName == "CallCount" ||
                          el.FilterPropertyName == "TotalSent" ||
                          el.FilterPropertyName == "TotalHardBounce" ||
                          el.FilterPropertyName == "TotalOpen" ||
                          el.FilterPropertyName == "TotalReply" ||
                          el.FilterPropertyName == "HoneyScore" ||
                          el.FilterPropertyName == "TotalSoftBounce"
                        ) {
                          el.IsNum = true;
                        } else {
                          el.IsNum = false;
                        }
                        if (el.FilterPropertyName == "ContactTag") {
                          el.IsSelect = true;
                          el.Expression = "EqualTo";
                
                          let ContactTagIds = el.FilterPropertyValue1?.split(",");
                          let ArrayObj = [];
                
                          ContactTagIds.forEach((elem) => {
                            let Indexed = ContactTags?.findIndex((v) => v.ContactTagID == elem);
                            ArrayObj.push({
                              label: ContactTags[Indexed]?.Tag,
                              value: ContactTags[Indexed]?.ContactTagID,
                              cid: ContactTags[Indexed]?.ContactTagID,
                            });
                          });
                          el.DefaultSelectIDs = ArrayObj;
                        } else {
                          el.IsSelect = false;
                          el.DefaultSelectIDs = [];
                        }
                        if (
                          el.ExpressionName == "IsNotBlank" ||
                          el.ExpressionName == "IsBlank"
                        ) {
                          el.NullCondition = true;
                        } else {
                          el.NullCondition = false;
                        }
                        FilteredArr.push(el);
                      });
             
                      SetEditFieldAttributes(FilteredArr);
                      SetIsLoading(false);
                    }).catch((error) => {
                      toast.error("An error occurred. Please try again.");
                      SetIsLoading(false);
                    });

                }
              }).catch((error) => {
                toast.error("An error occurred. Please try again.");
                SetIsLoading(false);
              });
              toast.success( <div className="toastsize"> Filter<br /> Filter Added SuccessFully.</div>);
              SetIsLoading(false)
            } else {
              toast.error("Error in save filter");
              SetDisabledAddFilter(false)
              SetIsLoading(false)
            }
          }
        } else {
          toast.error(<div className="toastsize">Filter<br /> Property value can not be an empty or invalid range</div>);
          SetDisabledAddFilter(false)
          SetIsLoading(false)
        }
      }
    }
  };

  // Get Filter List
  const Getfilterlist = (CID) => {
  

    if(IsApply === true || OpenEditFilterModel === true){
      SetIsLoading(true);
      var InputParameters = {
        ClientID: CID,
      };
  
      const rows3 = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/GetContactFilterDropDown",
        method: "POST",
        data: InputParameters,
      });
      rows3.then((result) => {
        SetFilterName(result.data.Data);
        if (result.data.Data.length > 0) {
          Getfilterpropertylist(result.data.Data[0]._id);
          SetIsLoading(false);
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    }else{
      var InputParameters = {
        ClientID: CID,
      };
  
      const rows3 = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/GetContactFilterDropDown",
        method: "POST",
        data: InputParameters,
      });
      rows3.then((result) => {
        SetFilterName(result.data.Data);
        if (result.data.Data.length > 0) {
          Getfilterpropertylist(result.data.Data[0]._id);
        }
        
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    }

  };
  const handleExistingfilter=()=>{
        
    if(FilterName.length>0){
      
      setIsPopupOpen(true);
    }else{

      toast.error(
        <div className="toastsize">
          Filter
          <br />
          No filter available
        </div>
      );
    }
  }

  const closePopup = () => {
    // Set the state to close the Popup
    setIsPopupOpen(false);
  };
  //Edit Book Meeting Filter Change
  const handleChangeFilter = () => {
    var filterdropoid = document.getElementById("FilterID").value;
    Getfilterpropertylist(filterdropoid);
  };

  // Get FilteProperty list with dropdown
  const Getfilterpropertylist = (filterid) => {
   
    SetFilterID(filterid);
    if(IsApply === true || OpenEditFilterModel === true){
      SetIsLoading(true);
      var InputParameters = {
        ClientID: ClientID,
        FilterID: filterid,
        IsDeleted: false,
      };
      const rows4 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/contacts/GetContactEditFilterProperties",
        method: "POST",
        data: InputParameters,
      });
      rows4.then((result) => {
        let FilteredArr = [];
        result?.data?.Data?.forEach((el, i) => {
          el.id = i;
          el.IsSaved = true;
          if (
            el.FilterPropertyName == "LastSendDate" ||
            el.FilterPropertyName == "LastValidationDate"
          ) {
            el.IsDate = true;
          } else {
            if (el.FieldType == "Text") {
              el.IsCustom = true;
            } else {
              el.IsCustom = false;
            }
            el.IsDate = false;
          }
          if (el.FilterPropertyName == "IsBlacklisted") {
            el.IsBool = true;
          } else {
            el.IsBool = false;
          }
          if (
            el.FilterPropertyName == "CallCount" ||
            el.FilterPropertyName == "TotalSent" ||
            el.FilterPropertyName == "TotalHardBounce" ||
            el.FilterPropertyName == "TotalOpen" ||
            el.FilterPropertyName == "TotalReply" ||
            el.FilterPropertyName == "HoneyScore" ||
            el.FilterPropertyName == "TotalSoftBounce"
          ) {
            el.IsNum = true;
          } else {
            el.IsNum = false;
          }
          if (el.FilterPropertyName == "ContactTag") {
            el.IsSelect = true;
            el.Expression = "EqualTo";
  
            let ContactTagIds = el.FilterPropertyValue1?.split(",");
            let ArrayObj = [];
  
            ContactTagIds.forEach((elem) => {
              let Indexed = ContactTags?.findIndex((v) => v.ContactTagID == elem);
              ArrayObj.push({
                label: ContactTags[Indexed]?.Tag,
                value: ContactTags[Indexed]?.ContactTagID,
                cid: ContactTags[Indexed]?.ContactTagID,
              });
            });
            el.DefaultSelectIDs = ArrayObj;
          } else {
            el.IsSelect = false;
            el.DefaultSelectIDs = [];
          }
          if (
            el.ExpressionName == "IsNotBlank" ||
            el.ExpressionName == "IsBlank"
          ) {
            el.NullCondition = true;
          } else {
            el.NullCondition = false;
          }
          FilteredArr.push(el);
        });
        SetIsLoading(false);
        SetEditFieldAttributes(FilteredArr);
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    }else{
      var InputParameters = {
        ClientID: ClientID,
        FilterID: filterid,
        IsDeleted: false,
      };
      const rows4 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/contacts/GetContactEditFilterProperties",
        method: "POST",
        data: InputParameters,
      });
      rows4.then((result) => {
        let FilteredArr = [];
        result?.data?.Data?.forEach((el, i) => {
          el.id = i;
          el.IsSaved = true;
          if (
            el.FilterPropertyName == "LastSendDate" ||
            el.FilterPropertyName == "LastValidationDate"
          ) {
            el.IsDate = true;
          } else {
            if (el.FieldType == "Text") {
              el.IsCustom = true;
            } else {
              el.IsCustom = false;
            }
            el.IsDate = false;
          }
          if (el.FilterPropertyName == "IsBlacklisted") {
            el.IsBool = true;
          } else {
            el.IsBool = false;
          }
          if (
            el.FilterPropertyName == "CallCount" ||
            el.FilterPropertyName == "TotalSent" ||
            el.FilterPropertyName == "TotalHardBounce" ||
            el.FilterPropertyName == "TotalOpen" ||
            el.FilterPropertyName == "TotalReply" ||
            el.FilterPropertyName == "HoneyScore" ||
            el.FilterPropertyName == "TotalSoftBounce"
          ) {
            el.IsNum = true;
          } else {
            el.IsNum = false;
          }
          if (el.FilterPropertyName == "ContactTag") {
            el.IsSelect = true;
            el.Expression = "EqualTo";
  
            let ContactTagIds = el.FilterPropertyValue1?.split(",");
            let ArrayObj = [];
  
            ContactTagIds.forEach((elem) => {
              let Indexed = ContactTags?.findIndex((v) => v.ContactTagID == elem);
              ArrayObj.push({
                label: ContactTags[Indexed]?.Tag,
                value: ContactTags[Indexed]?.ContactTagID,
                cid: ContactTags[Indexed]?.ContactTagID,
              });
            });
            el.DefaultSelectIDs = ArrayObj;
          } else {
            el.IsSelect = false;
            el.DefaultSelectIDs = [];
          }
          if (
            el.ExpressionName == "IsNotBlank" ||
            el.ExpressionName == "IsBlank"
          ) {
            el.NullCondition = true;
          } else {
            el.NullCondition = false;
          }
          FilteredArr.push(el);
        });
        // SetIsLoading(false);
        SetEditFieldAttributes(FilteredArr);
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
    }

  };

  //Edit column name
  const EditColumnName = (ID) => {
    
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });

    let FilterPropertyName = document.getElementById("EditColumnName" + ID).value;
    let indexed = SavedData.findIndex((v) => v.id === ID);
    let Indexed = CustomColumnData.findIndex( (v) => v.FieldValue === FilterPropertyName);

    SavedData[indexed].FilterPropertyName = FilterPropertyName;
    SavedData[indexed].Expression = "Contains";
    SavedData[indexed].ExpressionName = "Contains";

    if (
      SavedData[indexed].FilterPropertyName == "OpportunityDate" ||
      SavedData[indexed].FilterPropertyName == "UnqualifiedDate" ||
      SavedData[indexed].FilterPropertyName == "EventSetDate" ||
      SavedData[indexed].FilterPropertyName == "EventStartDate" ||
      SavedData[indexed].FilterPropertyName == "LastStatusChangeDate" ||
      SavedData[indexed].FilterPropertyName == "CreatedDt" ||
      SavedData[indexed].FilterPropertyName == "ClosedWonDate" ||
      SavedData[indexed].FilterPropertyName == "ClosedLostDate" ||
      SavedData[indexed].FilterPropertyName == "QulifiedDate"
    ) {
      SavedData[indexed].IsDate = true;
    } else {
      SavedData[indexed].IsDate = false;

      if (SavedData[indexed].IsCustom == true) {
        SavedData[indexed].IsCustom = true;
      } else {
        SavedData[indexed].IsCustom = false;
      }
      if (SavedData[indexed].FilterPropertyName == "IsBlacklisted") {
        SavedData[indexed].IsBool = true;
      } else {
        SavedData[indexed].IsBool = false;
      }
      if (
        SavedData[indexed].FilterPropertyName == "CallCount" ||
        SavedData[indexed].FilterPropertyName == "TotalSent" ||
        SavedData[indexed].FilterPropertyName == "TotalHardBounce" ||
        SavedData[indexed].FilterPropertyName == "TotalOpen" ||
        SavedData[indexed].FilterPropertyName == "TotalReply" ||
        SavedData[indexed].FilterPropertyName == "HoneyScore" ||
        SavedData[indexed].FilterPropertyName == "TotalSoftBounce"
      ) {
        SavedData[indexed].IsNum = true;
        SavedData[indexed].Expression = "Equals";
        SavedData[indexed].ExpressionName = "Equals";
      } else {
        SavedData[indexed].IsNum = false;
      }
      if (SavedData[indexed].FilterPropertyName == "ContactTag") {
        SavedData[indexed].IsSelect = true;
        SavedData[indexed].Expression = "EqualTo";
        SavedData[indexed].ExpressionName = "EqualTo";
      } else {
        SavedData[indexed].IsSelect = false;
      }
      if (
        SavedData[indexed].FilterPropertyName == "LastSendDate" ||
        SavedData[indexed].FilterPropertyName == "LastValidationDate"
      ) {
        SavedData[indexed].IsDate = true;
      } else {
        SavedData[indexed].IsDate = false;
      }
      if (CustomColumnData[Indexed].IsCustom == true) {
        SavedData[indexed].FieldFor = "Text";
      } else {
        SavedData[indexed].FieldFor = "";
      }
    }
    SavedData[indexed].FilterPropertyValue = "";
    SavedData[indexed].FilterPropertyValue1 = "";
    document.getElementById("Editexpression" + ID).value = "Contains";
    document.getElementById("Editsearchvalue" + ID).value = "";
    SavedData[indexed].NullCondition = false;
    SetEditFieldAttributes(SavedData);
  };

  //Edit Expression value
  const EditExpressValue = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let ExpressionName = document.getElementById("Editexpression" + ID).value;

    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].ExpressionName = ExpressionName;
    if (ExpressionName == "IsNotBlank" || ExpressionName == "IsBlank") {
      document.getElementById("Editsearchvalue" + ID).value = "";
      SavedData[indexed].NullCondition = true;
    } else {
      SavedData[indexed].NullCondition = false;
    }

    SetEditFieldAttributes(SavedData);
  };

  //Edit FilterPropertyValue1
  const EditFilterPropertyValue1 = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });

    let FilterPropertyValue1 = document.getElementById("Editsearchvalue" + ID).value.trim();
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue1 = FilterPropertyValue1;
    SetEditFieldAttributes(SavedData);
  };

  //Edit FilterPropertyValue2
  const EditFilterPropertyValue2 = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let FilterPropertyValue2 = document.getElementById("EditEndsWith" + ID).value.trim();
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue2 = FilterPropertyValue2;

    SetEditFieldAttributes(SavedData);
  };

  //Edit Select contact tags
  const EditFilterhandleChangeContactOption = (val, id) => {
    let Arr = [];

    EditFieldAttributes.forEach((el) => {
      Arr.push(el);
    });
    let CidArr = [];

    val?.forEach((elem) => {
      CidArr.push(elem.cid);
    });

    let indexed = Arr.findIndex((v) => v.id === id);
    Arr[indexed].ContactTagIds = CidArr.toString();
    Arr[indexed].FilterPropertyValue1 = CidArr.toString();

    document.getElementById("Editsearchvalue" + id).value = CidArr.toString();

    SetEditFieldAttributes(Arr);
  };

  //Add new filter property
  const handleeditPropertyClick = () => {
    var StateArray = [];
    EditFieldAttributes.forEach((el) => {
      StateArray.push(el);
    });
    StateArray.push({
      id: Math.floor(Math.random() * 1000000000),
      FilterPropertyName: "AccountCategory",
      ColumnName: "Account Category",
      ExpressionName: "Contains",
      FilterPropertyValue1: "",
      FilterPropertyValue2: "",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      NullCondition: false,
    });

    SetEditFieldAttributes(StateArray);
  };

  // //Remove filter property
  const handleEditRemoveClick = (id) => {

    let SavedData = [];
    EditFieldAttributes.forEach((el) => {
      SavedData.push(el);
    });
    if (SavedData.length <= 1) {
      toast.error(
        <div>
          Filter
          <br />
          Required filter property at least one
        </div>
      );
    } else {
      let DeletedArr = [];
      DeletedFilterProperty.forEach((elem) => {
        DeletedArr.push(elem);
      });

      const FilterPropertyID = SavedData.findIndex((item) => item.id == id);
      if (SavedData[FilterPropertyID].IsSaved == true) {
        DeletedArr.push(SavedData[FilterPropertyID]._id);
        SetDeletedFilterProperty(DeletedArr);
      }
      const FilteredData = SavedData.filter((item) => item.id !== id);
      SetEditFieldAttributes(FilteredData);
    }
  };

  // filter validation
  const EditFilterValidation = () => {
    let ValidationArr = [];

    EditFieldAttributes.map((newvalue) => {
      if (newvalue.IsBool == false) {
        var searchedVal1 = document.getElementById("Editsearchvalue" + newvalue.id).value;

        if (searchedVal1.trim() == "") {
          ValidationArr.push(false);
        } else {
          ValidationArr.push(true);
        }
        var expressionval = document.getElementById("Editexpression" + newvalue.id).value;

        if (expressionval.trim() == "between") {
          let FilterPropertyValue1 = document.getElementById("Editsearchvalue" + newvalue.id).value;
          let FilterPropertyValue2 = document.getElementById("EditEndsWith" + newvalue.id).value;
          if(newvalue.IsNum){
            if (FilterPropertyValue2.trim() == "" || parseFloat(FilterPropertyValue1.trim()) > parseFloat(FilterPropertyValue2.trim())) {
              ValidationArr.push(false);
            } else {
              ValidationArr.push(true);
            }
          }else{
            if (FilterPropertyValue2.trim() == "") {
              ValidationArr.push(false);
            } else {
              ValidationArr.push(true);
            }
          }
        }
      }
    });

    return ValidationArr.includes(false);
  };

  //Apply Edit Filter
  const ApplyEditFilter = async() => {
    SetIsLoading(true)
    if (EditFilterValidation() == false) {
      var ContactFilterSecArray = [];
      EditFieldAttributes.map((el) => {
        
      let MatchObject = CustomColumnData.find(item => item.FieldValue == el.FilterPropertyName)

        if (el.ExpressionName == "between") {
          ContactFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1,
            searchvalue1: el.FilterPropertyValue2,
            CustomFieldID:MatchObject?.CustomFieldID,
            IsCustom : MatchObject.IsCustom
          });
        } else {
          ContactFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1,
            searchvalue1: null,
            CustomFieldID:MatchObject?.CustomFieldID,
            IsCustom : MatchObject?.IsCustom
          });
        }
      });
              SetFilterParameters(ContactFilterSecArray)
              SetIsApply(true)
              setfilterPage(1)
          try {
            await ContactListGet(ClientID, UserID, Role, 1, true, ContactFilterSecArray);
            await ContactListTotal(ClientID,UserID,Role,1,true,ContactFilterSecArray);
            SetIsResetApply(true)
            SetOpenEditFilterModel(false);
            toast.success(<div className="toastsize"> Filter <br />Filter Apply Successfully.</div>);
          SetIsLoading(false)
          } catch (error) {
            toast.error("An error occurred. Please try again.");
            SetIsLoading(false);
          }
                  
                 



    } else {
      toast.error(<div className="toastsize">Filter<br />Property value can not be an empty or invalid range</div>);
      SetIsLoading(false)
    }
  };

  //Update Filter validation
  const UpdateFilterValidation = () => {
    let Flags = [];
    EditFieldAttributes.forEach((el) => {
      var searchedVal1 = document.getElementById("Editsearchvalue" + el.id).value;

      if (searchedVal1.trim() == "") {
        Flags.push(false);
      } else {
        Flags.push(true);
      }
      if (el.ExpressionName == "between") {
        let FilterPropertyValue1 = document.getElementById("Editsearchvalue" + el.id).value;
        let FilterPropertyValue2 = document.getElementById("EditEndsWith" + el.id).value;
        if(el.IsNum){
          if (FilterPropertyValue2.trim() == "" || parseFloat(FilterPropertyValue1.trim()) > parseFloat(FilterPropertyValue2.trim())) {
            Flags.push(false);
          } else {
            Flags.push(true);
          }
        }else{
          if (FilterPropertyValue2.trim() == "") {
            Flags.push(false);
          } else {
            Flags.push(true);
          }
        }
      }
    });

    return Flags.includes(false);
  };

  //Update and apply filter
  const editapplyfilterSearch = async () => {
    SetIsLoading(true)
    if (UpdateFilterValidation() == false) {
      const InputParameters = {
        ClientID: ClientID,
        FilterParameters: EditFieldAttributes,
        LastUpdatedDate: new Date(),
        UserID: UserID,
        FilterID: FilterID,
        DeletedFilterProperty: DeletedFilterProperty,
      };

      let res = await Axios({
        url:CommonConstants.MOL_APIURL +"/contacts/UpdateContactFilterProperties",
        method: "POST",
        data: InputParameters,
      });

      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(<div className="toastsize">Filter <br />Filter Updated SuccessFully.</div>);
        var ContactFilterSecArray = [];
        EditFieldAttributes.map((el) => {
          let MatchObject = CustomColumnData.find(item => item.FieldValue == el.FilterPropertyName)
          if (el.ExpressionName == "between") {
            ContactFilterSecArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1,
              searchvalue1: el.FilterPropertyValue2,
              FieldType: el.FieldFor,
              IsCustom : MatchObject?.IsCustom,
              CustomFieldID:MatchObject?.CustomFieldID
            });
          } else {
            ContactFilterSecArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1,
              searchvalue1: null,
              FieldType: el.FieldFor,
              IsCustom : MatchObject?.IsCustom,
              CustomFieldID:MatchObject?.CustomFieldID
            });
          }
        });
        SetFilterParameters(ContactFilterSecArray)
        SetIsApply(true)
        setfilterPage(1)
            // Call ContactListGet with the new states
            try {
                await ContactListGet(ClientID, UserID, Role, 1, true, ContactFilterSecArray);
                await ContactListTotal(ClientID,UserID,Role,1,true,ContactFilterSecArray);
                SetIsResetApply(true)
                SetOpenEditFilterModel(false);
                SetIsLoading(false);
            } catch (error) {
                toast.error("An error occurred. Please try again.");
                SetIsLoading(false);
            }

    

      } else {
        toast.error("Error in save filter");
    SetIsLoading(false)

      }
    } else {
      toast.error(<div className="toastsize"> Filter<br /> Property value can not be an empty or invalid range</div>);
    SetIsLoading(false)

    }
  };

  //Open save as model box
  const OpenSaveAsModel = () => {
    SetSaveAsBoxFilterName(true);
  };

  //Save As filter data
  const SaveAsFilterMethod = async () => {
    SetIsLoading(true)
    if (UpdateFilterValidation() == false) {
      let SaveAsFilterNames = document.getElementById("SaveAsFilterName").value;
      if (SaveAsFilterNames.trim() == "") {
        SetErrorMessage("Please enter filter name");
        SetIsLoading(false)
      } else {
        if ((await CheckSaveAsFilterName()) == true) {
          let ContactFilterSecArray = [];
          EditFieldAttributes.map((newvalue) => {
            let searchedVal1 = document.getElementById("Editsearchvalue" + newvalue.id).value.trim();
            let expressionval = document.getElementById("Editexpression" + newvalue.id).value;
            let Cloumnval = document.getElementById("EditColumnName" + newvalue.id).value;
            if (expressionval == "between") {
              let searchedVal2 = document.getElementById("EditEndsWith" + newvalue.id).value.trim();
              ContactFilterSecArray.push({
                ColumnName: Cloumnval,
                expression: expressionval,
                searchvalue: searchedVal1,
                searchvalue1: searchedVal2,
                FieldType: newvalue.FieldFor,
              });
            } else {
              ContactFilterSecArray.push({
                ColumnName: Cloumnval,
                expression: expressionval,
                searchvalue: searchedVal1,
                searchvalue1: null,
                FieldType: newvalue.FieldFor,
              });
            }
          });
          const InputParameters = {
            ClientID: ClientID,
            FilterName: SaveAsFilterNames,
            FilterParameters: ContactFilterSecArray,
            CreatedDate: new Date(),
            UserID: UserID,
          };

          let res = await Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/SaveContactFilter",
            method: "POST",
            data: InputParameters,
          });
          if (res.data.StatusMessage == "SUCCESS") {
            SetFilterParameters(ContactFilterSecArray);

            SetIsApply(true);
            SetFilterParameters([]);
            SetOpenAddFilter(false);
            SetFieldAttributes([
              {
                id: Math.floor(Math.random() * 1000000000),
                FieldValue: "AccountCategory",
                ColumnName: "Account Category",
                Expression: "Contains",
                FilterPropertyValue: "",
                FilterPropertyValue1: "",
                IsCustom: false,
                IsDate: false,
                IsBool: false,
                NullCondition: false,
              },
            ]);
            var InputParametersFilter = {
              ClientID: ClientID,
            };
        
            const rows3 = Axios({
              url: CommonConstants.MOL_APIURL + "/contacts/GetContactFilterDropDown",
              method: "POST",
              data: InputParametersFilter,
            });
            rows3.then((result) => {
              SetFilterName(result.data.Data);
              if (result.data.Data.length > 0) {

                  const ObjectFilterID = result.data.Data[0]._id
                  SetFilterID(result.data.Data[0]._id)
                  // Getfilterpropertylist(result.data.Data[0]._id);
                  var InputParameters = {
                    ClientID: ClientID,
                    FilterID: ObjectFilterID,
                    IsDeleted: false,
                  };
                  const rows4 = Axios({
                    url:
                      CommonConstants.MOL_APIURL + "/contacts/GetContactEditFilterProperties",
                    method: "POST",
                    data: InputParameters,
                  });
                  rows4.then((result) => {
                    let FilteredArr = [];
                    result?.data?.Data?.forEach((el, i) => {
                      el.id = i;
                      el.IsSaved = true;
                      if (
                        el.FilterPropertyName == "LastSendDate" ||
                        el.FilterPropertyName == "LastValidationDate"
                      ) {
                        el.IsDate = true;
                      } else {
                        if (el.FieldType == "Text") {
                          el.IsCustom = true;
                        } else {
                          el.IsCustom = false;
                        }
                        el.IsDate = false;
                      }
                      if (el.FilterPropertyName == "IsBlacklisted") {
                        el.IsBool = true;
                      } else {
                        el.IsBool = false;
                      }
                      if (
                        el.FilterPropertyName == "CallCount" ||
                        el.FilterPropertyName == "TotalSent" ||
                        el.FilterPropertyName == "TotalHardBounce" ||
                        el.FilterPropertyName == "TotalOpen" ||
                        el.FilterPropertyName == "TotalReply" ||
                        el.FilterPropertyName == "HoneyScore" ||
                        el.FilterPropertyName == "TotalSoftBounce"
                      ) {
                        el.IsNum = true;
                      } else {
                        el.IsNum = false;
                      }
                      if (el.FilterPropertyName == "ContactTag") {
                        el.IsSelect = true;
                        el.Expression = "EqualTo";
              
                        let ContactTagIds = el.FilterPropertyValue1?.split(",");
                        let ArrayObj = [];
              
                        ContactTagIds.forEach((elem) => {
                          let Indexed = ContactTags?.findIndex((v) => v.ContactTagID == elem);
                          ArrayObj.push({
                            label: ContactTags[Indexed]?.Tag,
                            value: ContactTags[Indexed]?.ContactTagID,
                            cid: ContactTags[Indexed]?.ContactTagID,
                          });
                        });
                        el.DefaultSelectIDs = ArrayObj;
                      } else {
                        el.IsSelect = false;
                        el.DefaultSelectIDs = [];
                      }
                      if (
                        el.ExpressionName == "IsNotBlank" ||
                        el.ExpressionName == "IsBlank"
                      ) {
                        el.NullCondition = true;
                      } else {
                        el.NullCondition = false;
                      }
                      FilteredArr.push(el);
                    });
           
                    SetEditFieldAttributes(FilteredArr);
                    SetIsLoading(false);
                  }).catch((error) => {
                    toast.error("An error occurred. Please try again.");
                    SetIsLoading(false);
                  });

              }
            }).catch((error) => {
              toast.error("An error occurred. Please try again.");
              SetIsLoading(false);
            });
            // Getfilterlist(ClientID);
            SetIsLoading(false)
            toast.success(<div className="toastsize">Filter<br /> Filter Updated SuccessFully.</div>);
          } else {
            toast.error("Something went wrong filter save as");
            SetIsLoading(false)
          }
          SetSaveAsBoxFilterName(false);
          SetOpenAddFilter(false);
        } else {
          SetErrorMessage("Contact filter already exist.");
          SetIsLoading(false)
        }
      }
    } else {
      toast.error(<div className="toastsize">Filter<br />Property value can not be an empty or invalid range</div>);
      SetIsLoading(false)
    }
  };

  //close edit filter
  const CloseEditFilter = () => {
    SetEditFieldAttributes([]);
    SetFieldAttributes([]);
    SetFieldAttributes([
      {
        id: Math.floor(Math.random() * 1000000000),
        FieldValue: "AccountCategory",
        ColumnName: "Account Category",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        IsCustom: false,
        IsDate: false,
        IsBool: false,
        NullCondition: false,
      },
    ]);
    SetIsApply(false);
    Getfilterlist(ClientID);
    SetOpenAddFilter(false);
  };

  //Check filter name exists or not
  const CheckSaveAsFilterName = async () => {
    let FilterName = document.getElementById("SaveAsFilterName").value.trim();
    let flag = false;
    if (FilterName == "") {
      SetErrorMessage("Please enter filter name");
      flag = false;
    } else {
      const InputParameters = {
        ClientID: ClientID,
        FilterName: FilterName,
      };

      let res = await Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CheckContactFilterName",
        method: "POST",
        data: InputParameters,
      });

      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data <= 0) {
          flag = true;
          SetErrorMessage(null);
        } else {
          SetErrorMessage("Contact filter already exist.");
          flag = false;
        }
      } else {
        toast.error("Something went wrong in filter name exists api");
      }
    }
    return flag;
  };

const ContactValidateEmailCheck = async () => {
  document.getElementById("Show_csv_details").style.display = "none"
  if (prospectAllSelectData.length === 0) {
    toast.error("Please select at least one contact from the list");
    return;
  }
  try {
    SetIsLoading(true);
    const InputParaMeter = {
      ContactValidateArray: prospectAllSelectData,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };

    const response = await Axios.post(
      CommonConstants.MOL_APIURL + "/contacts/ContactValidateCheck",
      InputParaMeter
    );

    if (response.data.StatusMessage === "SUCCESS") {
      toast.success("Saleshive contact will be validated soon.");
      prospectAllSelectData.forEach((item) => {
        const trElement = document.getElementById(item?._id)?.closest('tr');
        if (trElement) {
          trElement.classList.remove("highlight");
        }
      });
      ContactListGet(ClientID, UserID, Role,page,IsApply,FilterParameters);
      ContactListTotal(ClientID, UserID, Role,page,IsApply,FilterParameters);
      setProspectAllSelectData([]);
      document.getElementById("deleteall-btn").style.display = "none"
      document.getElementById("Select-All").style.display = "block";
        document.getElementById("UnSelect").style.display = "none";

      document.getElementById("main-check").checked = false
    } else {
      toast.error("Not validated");
    }
  } catch (error) {
    // Handle errors here

  } finally {
    SetIsLoading(false);
  }
};

  return ( 
    <div>
      <Popup open={ShowPopupImport}>
        <div>
          <div className="modal-black"></div>
          <div className="filterPopup largerPopup">
            <div className="paddingboxTerms">
              <div className="modal-header py-4 px-3">
                <div className="w-100 d-flex px-3">
                  <h5 className="mb-0">Email</h5>
                  <button
                    className="close"
                    onClick={() => {
                    SetShowPopupImport(false);
                    SetErrors({});
                    }}
                  >
                    <span aria-hidden="true" className="la la-remove"></span>
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="modal-body">
                  <div className="col-sm-8 mb-5 px-0">
                    <div className="row mb-3">
                      <label className="col-lg-5 col-form-label text-left">
                        Email
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="UserEmail"
                          data-val="true"
                          data-val-remote="Title already exist."
                          data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                          data-val-required="Please enter Email "
                          name="UserEmail"
                          defaultValue={UserEmail}
                          onBlur={handleChangeEmailcheckImport}
                                  type="text"
                                />
                                 <span style={{ color: "red" }}>
                                {Errors["UserEmail"]}
                                </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-top modal-content text-right">
                  <div className="row">
                    <div className="col-lg-12 pull-right">
                      <a
                        id="backtolist"
                        className="btn btn-secondary mr-2"
                        onClick={() => {
                          SetShowPopupImport(false);
                          SetErrors({});
                          }}
                      >
                        cancel
                      </a>
                      <button
                        id="submit"
                        type="button"
                        onClick={() => { ContactSaveCsvAddBulk(); }}
                        disabled={isSaving}
                        className="btn btn-primary btn-lightgreen mr-1"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <Popup open={OpenAddFilter}>
      <div>
        <div className="modal-black"></div>
        <div className="filterPopup rounded-0 ">
          <div className="paddingboxTerms">
            <div className="modal-header pb-0 border-bottom-0 px-3">
              <div className="w-100 d-flex align-items-center">
                <h5 className="mb-0 font-weight-bolder">Filter</h5>
                <button className="close" onClick={() => SetOpenAddFilter(false)}>
                  <span aria-hidden="true" className="la la-remove" ></span>
                </button>
              </div>
            </div>
            <div className="modal-body p-2">
  <div className="">
    <div className="row shadowcard border my-2 mx-2 py-4">
      {/* Add New Filter Section */}
      <div className="col-xl-6 allcentertext py-4 border-right px-1">
        
        {/* <i className="la la-filter iconsfonts"></i> */}
        <img src={addNewFilter} className='w-42px'/>
        <div className='py-3'>
        <p className="description-text text-black  mb-0 ">Add a new filter to refine your search results.</p>
        </div>
        <button className="btn btn-md btn-primary btnColor" onClick={OpenAddFilterModelMethod}>
          {/* <span className="Mobile_button"> */}
             Add New Filter 
             {/* </span> */}
        </button>
      
      </div>

      {/* Select from Existing Filter Section */}
      <div className="col-xl-6  allcentertext py-4 px-1">
    
        {/* <i className="la la-filter iconsfonts"></i> */}
        <img src={existFilter} className='w-42px'/>

        <div className='py-3'>
        <p className="description-text text-black  mb-0">Select from your existing filters for your results.</p>
        </div>
        <button className="btn btn-md btn-primary btnColor" onClick={OpenEditFilterModelMethod}>
          Select from existing Filter
        </button>
       
      </div>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
    </Popup>

    {/* Opeb Add Filter Model */}
    <Popup   open={OpenAddFilterModel} closeOnDocumentClick={false} onClose={CloseAddFilterModelMethod}>
    
                        <div>
                          <div className="modal-black"></div>
                          <div className="filterPopup largerPopup1000">
                            <div className="paddingboxTerms">
                              <div className="modal-header py-4 px-3">
                                <div className="w-100 d-fOpenAddFilterlex px-3 d-flex">
                                  <h5 className="mb-0"> New Filter</h5>
                                  <button
                                    className="close"
                                    onClick={() => {
                                      CloseAddFilterModelMethod();
                                      SetFieldAttributes([]);
                                      SetOpenAddFilter(false);
                                      // this.setState({FieldAttributes:[{id: 0, ColumnName: 'AccountName', Expression: 'ContainTo'}]})
                                    }}
                                  >
                                    <span aria-hidden="true" className="la la-remove"></span>
                                  </button>
                                </div>
                              </div>
                              <div className="p-3">
                                <div className="modal-body">
                                  <div className="col-sm-6 mb-4 px-0">
                                    <div className="row mb-2">
                                      <label className="col-lg-5 col-form-label text-left">Filter Name</label>
                                      <div className="col-lg-7">
                                        <input
                                          className="form-control m-input"
                                          data-val-required="Please enter title"
                                          id="FilterName"
                                          name="FilterName"
                                          placeholder="Filter Name"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="table-bordered">
                                    <table
                                      className="table  table-hover"
                                      id="tab_logic"
                                    > 
                                        <Table
                                          size="small"
                                          aria-label="a dense table"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell> Property{" "}</TableCell>
                                              <TableCell align="right">{" "} </TableCell>
                                              <TableCell align="right"></TableCell>
                                              <TableCell align="right"></TableCell>
                                              <TableCell align="right"></TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {FieldAttributes.map(
                                              (itemcount, i) => (
                                                <TableRow sx={{"&:last-child td, &:last-child th":{ border: 0 },}}>
                                                  <TableCell align="right">
                                                    <select  className="form-control m-input"
                                                      id={"ColumnName" +itemcount.id}
                                                      onChange={() => {ColumnhandledDropdownChange(itemcount.id,i );}}
                                                      value={itemcount.FieldValue}
                                                      autocomplete="off"
                                                    >
                                                      {CustomColumnData.map(
                                                        (el,i) => (
                                                          <option key={i} value={el.FieldValue }>{" "}{ el.ColumnName}{" "}</option>
                                                        )
                                                      )}
                                                    </select>
                                                  </TableCell>
                                                  <TableCell>
                                                    {itemcount.IsCustom ==
                                                    true ? (
                                                      <select className="form-control m-input" id={ "ColumnName" +itemcount.id}
                                                        // onChange={()=>{ColumnhandledDropdownChange(itemcount.id)}}
                                                        // value={selectedvalue}
                                                        autocomplete="off"
                                                      >
                                                        <option id="text" value="Text">{" "}Text </option>
                                                      </select>
                                                    ) : null}
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    {itemcount.IsSelect ==
                                                    true ? (
                                                      <select  id={ "expression" +itemcount.id}
                                                        // onChange={()=>{this.EditExpressValue(itemcount.id)}}
                                                        className="form-control m-input"
                                                      >
                                                        <option  id="EqualTo" value="EqualTo"> Equals</option>
                                                      </select>
                                                    ) : itemcount.IsBool ==
                                                      true ? (
                                                      <div>
                                                        <select id={"expression" +itemcount.id } onChange={() => { ExpressionHandler(itemcount.id); }} className="form-control m-input">
                                                          <option id="true" value="True">Yes</option>
                                                          <option id="false" value="False"> No</option>
                                                        </select>
                                                        <input type="hidden" readOnly id={ "searchvalue" +itemcount.id} value="y" className="form-control m-input required"/>
                                                      </div>
                                                    ) : itemcount.IsDate == true ||itemcount.IsNum == true ? (
                                                      <select id={"expression" +itemcount.id} className="form-control m-input" onChange={() => { ExpressionHandler(itemcount.id);}}>
                                                        <option id="Equals" value="Equals">Equals</option>
                                                        <option id="Lessthan" value="LessThan">{" "}Less Than{" "}</option>
                                                        <option id="LessThanEqualTo" value="LessThanEqualTo" >Less Than Equal To</option>
                                                        <option id="greaterthan" value="GreaterThan">Greater Than</option>
                                                        <option id="greaterthanequalto" value="GreaterThanEqaulTo">Greater Than Equal To</option>
                                                        <option id="between" value="between">Between</option>
                                                        <option id="IsNotBlank" value="IsNotBlank">Is Not Blank</option>
                                                        <option id="IsBlank" value="IsBlank">Is Blank</option>
                                                      </select>
                                                    ) : (
                                                      <select id={"expression" +itemcount.id} onChange={() => {ExpressionHandler(itemcount.id);}} className="form-control m-input">
                                                        <option id="Contains" value="Contains">Contains</option>
                                                        <option id="EqualTo" value="EqualTo">Equals</option>
                                                        <option id="IsNotBlank" value="IsNotBlank">Is Not Blank</option>
                                                        <option id="IsBlank" value="IsBlank" >Is Blank</option>
                                                      </select>
                                                    )}
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    {itemcount.IsSelect == true ? (
                                                      <>
                                                        <Select closeMenuOnSelect={false} hideSelectedOptions={ false} options={contactoptions} id="ContactTagID"
                                                          isMulti
                                                          onChange={(val) =>FilterhandleChangeContactOption(val,itemcount.id)}
                                                        />
                                                        <input type="hidden" readOnly id={"searchvalue" +itemcount.id}className="form-control m-input required"/>
                                                      </>
                                                    ) : itemcount.IsBool == true || itemcount.NullCondition == true ? (
                                                      <input  type="hidden" hidden={true} readOnly id={"searchvalue" + itemcount.id} value="y" className="form-control m-input required"/>
                                                    ) : itemcount.IsDate == true ? (itemcount.Expression == "between" ? (
                                                        <>
                                                          <input type="date" id={"searchvalue" +itemcount.id} className="form-control m-input required"/>
                                                          <input type="date" id={"EndsWith" +itemcount.id} className="form-control m-input required"/>
                                                        </>
                                                      ) : (
                                                        <input type="date" id={"searchvalue" +itemcount.id} className="form-control m-input required"/>
                                                      )
                                                    ) : itemcount.IsNum ==true ? (itemcount.Expression =="between" ? (
                                                        <>
                                                          <input type="text" id={ "searchvalue" + itemcount.id} className="form-control m-input required"/>
                                                          <input type="text" id={ "EndsWith" +itemcount.id} className="form-control m-input required"/>
                                                        </>
                                                      ) : (
                                                        <input type="text" id={"searchvalue" +itemcount.id} className="form-control m-input required"/>
                                                      )
                                                    ) : (
                                                      <input type="text" id={"searchvalue" +itemcount.id } className="form-control m-input required"/>
                                                    )}
                                                  </TableCell>

                                                  <TableCell align="right"></TableCell>
                                                  <TableCell align="left">
                                                    <button type="button" title="Delete" id={"delete" +itemcount.id} className="btn btngroup" onClick={() => { handleRemoveClick( itemcount.id);}}>
                                                      <i className="la flaticon-delete-1"></i>
                                                    </button>
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                       
                                    </table>
                                  </div>
                                  <div className="row mb-5 pb-5">
                                    <div className="col-sm-12 text-left mb-5 pb-5">
                                      <button type="button" className="btn btngroup" value="ADD Row" onClick={handleClick}>
                                        <i className="la la-plus la-icon-font-size-13"></i>{" "}Add Property
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="border-top modal-content text-right px-4">
                                  <div className="row py-2 pb-4 px-1">
                                    <div className="col-lg-12 pull-right">
                                      <a id="backtolist" className="btn btn-secondary mr-2"
                                        onClick={() => {
                                      CloseAddFilterModelMethod();
                                          
                                          // close();
                                          // this.setState({FieldAttributes:[]});
                                          // this.setState({OpenAddFilter:false});
                                          // this.setState({FieldAttributes:[{id: 0, ColumnName: 'AccountName', Expression: 'ContainTo'}]})
                                        }}
                                      >Cancel </a>
                                      <button id="submit" className="btn btn-primary btn-lightgreen mr-1"
                                        onClick={() => {filterSearch();
                                          // this.closeTermsConditionModal;
                                        }} value="Apply">Apply</button>
                                      <button id="submit" className="btn btn-primary btn-lightgreen mr-1" onClick={() => {addfilterContactfilter();}} disabled={DisabledAddFilter} value="Save" ><i className="la la-save"></i>{" "} Save</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      
    </Popup>

    {/* Open Edit Filter Model */}
    <Popup   open={OpenEditFilterModel} closeOnDocumentClick={false} onClose={CloseEditFilterModelMethod}>
                     
                        <div>
                          <div className="modal-black"></div>
                          <div className="filterPopup largerPopup1000">
                            <div className="paddingboxTerms">
                              <div className="modal-header py-4 px-3">
                                <div className="w-100 d-flex px-3">
                                  <h5 className="mb-0"> Edit Filter</h5>
                                  <button className="close" onClick={CloseEditFilterModelMethod}><span aria-hidden="true" className="la la-remove" ></span></button>
                                </div>
                              </div>
                              <div className="p-3">
                                <div className="modal-body">
                                  <div className="col-sm-6 mb-5 px-0">
                                    <div className="row mb-3">
                                      <label className="col-lg-5 col-form-label text-left">Filter Name</label>
                                      <div className="col-lg-7">
                                        <select className="form-control m-input m--hide" id="FilterID" name="FilterID" onChange={() => {handleChangeFilter();}}>
                                          {FilterName && FilterName.map(
                                              (valuefilter) => (
                                                <option key={valuefilter._id} value={valuefilter._id}>{ valuefilter.FilterName}</option>
                                              )
                                            )}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="table-bordered"> 
                                      <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell> Property{" "}</TableCell>
                                            <TableCell align="right">{" "}</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {EditFieldAttributes.map(
                                            (itemcount, i) => (
                                              <TableRow sx={{"&:last-child td, &:last-child th": { border: 0 }}}>
                                                <TableCell align="right">
                                                  <select className="form-control m-input" id={"EditColumnName" +itemcount.id}
                                                    onChange={() => {EditColumnName(itemcount.id,i);}}
                                                    value={itemcount.FilterPropertyName}
                                                    autocomplete="off"
                                                  >
                                                    {CustomColumnData.map((el) => (
                                                        <option value={el.FieldValue}>{el.ColumnName}</option>
                                                      )
                                                    )}
                                                  </select>
                                                </TableCell>
                                                <TableCell>
                                                  {itemcount.IsCustom == true ? (
                                                    <select className="form-control m-input" id={"EditColumnName" +itemcount.id}
                                                      // onChange={()=>{ColumnhandledDropdownChange(itemcount.id)}}
                                                      // value={selectedvalue}
                                                      autocomplete="off"
                                                    >
                                                      <option id="text" value="Text">Text</option>
                                                    </select>
                                                  ) : null}
                                                </TableCell>
                                                <TableCell align="right">
                                                  {itemcount.IsSelect ==true ? (
                                                    <select id={"Editexpression" +itemcount.id}
                                                      // onChange={()=>{EditExpressValue(itemcount.id)}}
                                                      className="form-control m-input"
                                                    >
                                                      <option id="EqualTo" value="EqualTo">Equals</option>
                                                    </select>
                                                  ) : itemcount.IsBool ==true ? (
                                                    <div>
                                                      <select id={"Editexpression" +itemcount.id} onChange={() => {EditExpressValue(itemcount.id);}} className="form-control m-input"
                                                      >
                                                        <option id="true" value="True">Yes</option>
                                                        <option id="false"value="False"> No</option>
                                                      </select>
                                                      <input type="hidden" hidden readOnly id={"Editsearchvalue" +itemcount.id} value="y"className="form-control m-input required"/>
                                                    </div>
                                                  ) : itemcount.IsDate == true || itemcount.IsNum ==true ? (
                                                    <select id={ "Editexpression" +itemcount.id} className="form-control m-input" onChange={() => { EditExpressValue(itemcount.id );}}
                                                      value={itemcount.ExpressionName}
                                                    >
                                                      <option id="Equals" value="Equals"> Equals</option>
                                                      <option id="Lessthan" value="LessThan"> Less Than</option>
                                                      <option id="LessThanEqualTo" value="LessThanEqualTo"> Less Than Equal To</option>
                                                      <option id="greaterthan" value="GreaterThan"> Greater Than</option>
                                                      <option id="greaterthanequalto" value="GreaterThanEqaulTo">Greater Than Eqaul To </option>
                                                      <option id="between" value="between"> Between</option>
                                                      <option id="IsNotBlank" value="IsNotBlank">Is Not Blank</option>
                                                      <option id="IsBlank" value="IsBlank">Is Blank</option>
                                                    </select>
                                                  ) : (
                                                    <select id={"Editexpression" +itemcount.id}
                                                      onChange={() => {EditExpressValue(itemcount.id);}}
                                                      value={itemcount.ExpressionName}
                                                      className="form-control m-input"
                                                    >
                                                      <option id="Contains" value="Contains">Contains</option>
                                                      <option id="EqualTo" value="EqualTo">Equals</option>
                                                      <option id="IsNotBlank" value="IsNotBlank"> Is Not Blank</option>
                                                      <option id="IsBlank" value="IsBlank">Is Blank </option>
                                                    </select>
                                                  )}
                                                </TableCell>
                                                <TableCell align="right">{itemcount.IsSelect ==true ? (
                                                    <>
                                                      <Select closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        options={ContactTagsArr}
                                                        defaultValue={itemcount.DefaultSelectIDs}
                                                        id="ContactTagID"
                                                        isMulti
                                                        onChange={(val) => EditFilterhandleChangeContactOption( val,itemcount.id)}
                                                      />
                                                      <input type="hidden" hidden readOnly id={"Editsearchvalue" +itemcount.id} className="form-control m-input required" value={itemcount.FilterPropertyValue1 }/>
                                                    </>
                                                  ) : itemcount.IsBool == true ||itemcount.NullCondition ==true ? (
                                                    <input type="hidden"  hidden={true} readOnly id={ "Editsearchvalue" +itemcount.id} value="y" className="form-control m-input required"/>
                                                  ) : itemcount.IsDate ==true ? (itemcount.ExpressionName =="between" ? (
                                                      <>
                                                        <input type="date" id={"Editsearchvalue" +itemcount.id} value={ itemcount.FilterPropertyValue1 } className="form-control m-input required" onChange={() =>EditFilterPropertyValue1( itemcount.id)}/>
                                                        <input type="date" id={"EditEndsWith" +itemcount.id}value={itemcount.FilterPropertyValue2} className="form-control m-input required" onChange={() =>EditFilterPropertyValue2(itemcount.id)}/>
                                                      </>
                                                    ) : (
                                                      <input type="date" id={"Editsearchvalue" + itemcount.id } value={ itemcount.FilterPropertyValue1} onChange={() => EditFilterPropertyValue1(itemcount.id )} className="form-control m-input required"/>
                                                    )
                                                  ) : itemcount.IsNum ==true ? (itemcount.ExpressionName =="between" ? (
                                                      <>
                                                        <input type="text" id={ "Editsearchvalue" + itemcount.id} value={itemcount.FilterPropertyValue1} onChange={() => EditFilterPropertyValue1(itemcount.id)} className="form-control m-input required"/>
                                                        <input type="text" id={"EditEndsWith" +itemcount.id} onChange={() =>EditFilterPropertyValue2(itemcount.id) }value={itemcount.FilterPropertyValue2} className="form-control m-input required"/>
                                                      </>
                                                    ) : (
                                                      <input type="text" id={"Editsearchvalue" +itemcount.id } onChange={() => EditFilterPropertyValue1(itemcount.id) } value={itemcount.FilterPropertyValue1 }className="form-control m-input required" />
                                                    )
                                                  ) : (
                                                    <input type="text" id={"Editsearchvalue" + itemcount.id} onChange={() => EditFilterPropertyValue1( itemcount.id )} value={itemcount.FilterPropertyValue1} className="form-control m-input required" />
                                                  )}
                                                </TableCell>

                                                <TableCell align="right">
                                                  <button type="button" id={ "delete" +itemcount.id} className="btn btngroup" onClick={() => { handleEditRemoveClick(itemcount.id);}}>
                                                    <i className="la flaticon-delete-1"></i>
                                                  </button>
                                                </TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table> 
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-12 text-left">
                                      <button type="button" className="btn btngroup" onClick={() => { handleeditPropertyClick();}} >
                                        <i className="la la-plus la-icon-font-size-13"></i>
                                        Add Property{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="border-top modal-content text-right">
                                  <div className="row">
                                    <div className="col-lg-12 pull-right">
                                      <a id="backtolist" className="btn btn-secondary mr-2"onClick={CloseEditFilterModelMethod} >cancel</a>
                                      <button
                                        id="submit"
                                        className="btn btn-primary btn-lightgreen mr-1"
                                        onClick={() => {
                                          ApplyEditFilter();
                                          // this.closeTermsConditionModal;
                                        }}
                                      >
                                        Apply
                                      </button>
                                      <a
                                        id="submit"
                                        className="btn btn-primary btn-lightgreen mr-1"
                                        onClick={() => {
                                          editapplyfilterSearch();
                                        }}
                                      >
                                        <i className="la la-save"></i>{" "}
                                        Save
                                      </a>
                                      <a
                                        id="submit"
                                        className="btn btn-primary btn-lightgreen mr-1"
                                        onClick={() => {
                                          // OpenSaveAsModel();
                                          OpenSaveAsFilterModelMethod()
                                        }}
                                      >
                                        <i className="la la-save"></i>{" "}
                                        Save As
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      
    </Popup>

    {/* Open Save as Filter Model */}
    <Popup open={OpenSaveAsFilterModel}>
                      <div>
                        <div className="modal-black"></div>
                        <div className="filterPopup largerPopup1000">
                          <div className="paddingboxTerms">
                            <div className="modal-header py-4 px-3">
                              <div className="w-100 d-flex px-3">
                                <h5 className="mb-0">Filter</h5>
                                <button
                                  className="close"
                                  onClick={CloseSaveAsFilterModelMethod}
                                >
                                  <span
                                    aria-hidden="true"
                                    className="la la-remove"
                                  ></span>
                                </button>
                              </div>
                            </div>
                            <div className="p-3">
                              <div className="modal-body">
                                <div className="col-sm-6 mb-5 px-0">
                                  <div className="row mb-3">
                                    <label className="col-lg-5 col-form-label text-left">
                                      Filter
                                    </label>
                                    <div className="col-lg-7">
                                      <input
                                        type="text"
                                        onBlur={() => {
                                          CheckSaveAsFilterName();
                                        }}
                                        placeholder="Enter filter name"
                                        className="form-control m-input required"
                                        id="SaveAsFilterName"
                                      />
                                      <span className="font-danger">
                                        {ErrorMessage}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="border-top modal-content text-right">
                                <div className="row">
                                  <div className="col-lg-12 pull-right">
                                    <a
                                      id="backtolist"
                                      className="btn btn-secondary mr-2"
                                      onClick={() => {
                                        CloseSaveAsFilterModelMethod();
                                        SetErrorMessage(null);
                                      }}
                                    >
                                      cancel
                                    </a>

                                    <a
                                      id="submit"
                                      className="btn btn-primary btn-lightgreen mr-1"
                                      onClick={() => {
                                        SaveAsFilterMethod();
                                      }}
                                    >
                                      <i className="la la-save"></i>{" "}
                                      Save
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popup> 

      {
        IsLoading === true ? <>
        <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
        </> : <></>
      }

      <div className="row border-bottom mx-0 align-items-center">
        <div className="col">
          <h4 className="headertitle xs-headertitle float-left">{AccountTypeName}</h4>
          <div className="listing-li float-right padb-15 iconbox-pad">

          <div className="settingdrop">
                <ul className="">
                    <li>
                        <a aria-describedby={id} variant="contained" onClick={settinghandleClick}
                          className="btn btngroup m-btn m-btn--custom"
                        >
                          <i className="la la-icon-font-size-13 flaticon-settings"></i>
                        </a>
                    </li>
                  </ul>
              </div>
              <Popover id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={settinghandleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
          <div className="dropsettings" >
                {/* <label className="w-100 mb-2 settingtitel">Settings</label> */}

            <ul className="mt-0">
              <li>
                {/* <Tooltip title="Add"> */}
                  <a
                    onClick={() => {
                      addcontactpage(null);
                    }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la la-icon-font-size-13 la-plus"></i>
                    Add
                  </a>
                {/* </Tooltip> */}
              </li>
              <li>
                {/* <Tooltip title="Import"> */}
                  <a
                    onClick={ContactImportCsv}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la la-icon-font-size-13 la-upload"></i>
                    Import
                  </a>
                {/* </Tooltip> */}
              </li>
              <li>
                {/* <Tooltip title="Export"> */}
                  <a
     onClick={() => {
    SetShowPopupExport(true);

    // Hide the element with id "Show_csv_details"
    const csvDetailsElement = document.getElementById("Show_csv_details");
    if (csvDetailsElement) {
      csvDetailsElement.style.display = "none";
    }
  }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la la-icon-font-size-13 la-download"></i>
                    Export
                  </a>
                {/* </Tooltip> */}
              </li>
              <Popup open={ShowPopupExport}>
       
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Email</h5>
                          <button
                            className="close"
                            onClick={() => {
  SetShowPopupExport(false);
  SetErrors({});
}}
                          >
                            <span
                              aria-hidden="true"
                              className="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="p-3">
                        <div className="modal-body">
                          <div className="col-sm-6 mb-5 px-0">
                            <div className="row mb-3">
                              <label className="col-lg-5 col-form-label text-left">
                                Email
                              </label>
                              <div className="col-lg-7">
                                <input
                                  className="form-control m-input"
                                  id="Username"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter Email "
                                  name="Username"
                                  defaultValue={UserEmail}
                                  onBlur={handleChangeEmailcheck}
                                  type="text"
                                />
                                 <span style={{ color: "red" }}>
                                {Errors["Username"]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-top modal-content text-right">
                          <div className="row">
                            <div className="col-lg-12 pull-right">
                              <a
                                id="backtolist"
                                className="btn btn-secondary mr-2"
                                onClick={() => {
  SetShowPopupExport(false);
  SetErrors({});
}}
                              >
                                cancel
                              </a>
                              <button
                                disabled={IsDisablesExport}
                                id="submit"
                                onClick={() => ExportsToContacts()}
                                className="btn btn-primary btn-lightgreen mr-1"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
              <li>
                {/* <Tooltip title="Validate"> */}
                  <a
                    onClick={ContactValidateEmailCheck}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="fa la-icon-font-size-13 fa-check-square-o"></i>
                    Validate
                  </a>
                {/* </Tooltip> */}
              </li>
              <li>
                {/* <Tooltip title="Bulk Delete"> */}
                  <a
                    onClick={ContactBulkDeleteCsv}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la flaticon-delete-1 la-icon-font-size-13"></i>
                    Bulk Delete
                  </a>
                {/* </Tooltip> */}
              </li>
              <li>
                {/* <Tooltip title="Add to Blacklist"> */}
                  <a
                    onClick={ContactAddToBlackList}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="fa la-icon-font-size-13 fa-ban"></i>
                    Add to Blacklist
                  </a>
                {/* </Tooltip> */}
              </li>
              <li>
                {/* <Tooltip title="Add Filter"> */}
                  <button
                    className="btn btngroup m-btn m-btn--custom"
                    onClick={() => {
                      SetOpenAddFilter(true);
                      SetDisabledAddFilter(false)

                      // SetIsApply(false);
                      SetFieldAttributes([
                        {
                          id: Math.floor(Math.random() * 1000000000),
                          FieldValue: "AccountCategory",
                          ColumnName: "Account Category",
                          Expression: "Contains",
                          FilterPropertyValue: "",
                          FilterPropertyValue1: "",
                          IsCustom: false,
                          IsDate: false,
                          IsBool: false,
                          NullCondition: false,
                        },
                      ]);
                          // Hide the element with id "Show_csv_details"
                        const csvDetailsElement = document.getElementById("Show_csv_details");
                        if (csvDetailsElement) {
                          csvDetailsElement.style.display = "none";
                        }
                    }}
                  >
                    <i className="fa la-icon-font-size-13 fa-filter"></i>
                    Add Filter
                    {/* <span>Add Filter</span> */}
                  </button>
                {/* </Tooltip> */}
               
              </li>
              <li>
                {/* <Tooltip title="Add to Campaign"> */}
                  <a
                    onClick={CampaignListMove}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la flaticon-add la-icon-font-size-13"></i>
                    Add to Campaign
                  </a>
                {/* </Tooltip> */}
              </li>
              <Popup open={ShowPopupCampaign}>
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup longerPopup">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Move to campaign</h5>
                          <button
                            className="close"
                            onClick={() => SetShowPopupCampaign(false)}
                          >
                            <span
                              aria-hidden="true"
                              className="la la-remove"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div className="p-3">
                        <div className="modal-body">
                          <div className="col-sm-6 mb-5 px-0">
                            <div className="row mb-3">
                              <label className="col-lg-5 col-form-label text-left">
                                Campaign
                              </label>
                              <div className="col-lg-7">
                                     {/* <Select
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          options={CampaignData}
                          name="CampaignID"
                          id="CampaignID"
                          placeholder="Select Campaign"
                          // onChange={(e) => this.h2Change(e)}
                          // value={this.state.defaultTagArray && this.state.defaultTagArray[0] ? this.state.defaultTagArray : null}
                        /> */}
                        <Select
        className="m-input"
        name="CampaignID"
        id="CampaignID"
                        
        options={CampaignData.map((value) => ({ value: value._id, label: value.Name }))}
        placeholder="Select Campaign"
       
        value={selectedCampaign}
        onChange={(selectedOption) => setSelectedCampaign(selectedOption)}
        isSearchable
        noOptionsMessage={() => 'No result found'}
      />
                                {/* <select
                                  className="form-control  m-input"
                                  name="CampaignID"
                                  isMulti
                                  options
                                  id="CampaignID"
                                  placeholder="Select Campaign"
                                >
                                  {CampaignData.map((value) => (
                                    <option value={value._id}>
                                      {value.Name}
                                    </option>
                                  ))}
                                </select> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-top modal-content text-right">
                          <div className="row">
                            <div className="col-lg-12 pull-right">
                              <a
                                id="backtolist"
                                className="btn btn-secondary mr-2"
                                onClick={() => {
                                  SetShowPopupCampaign(false);
                                }}
                              >
                                Cancel
                              </a>
                              <a
                                id="submit"
                                onClick={CampaignToList}
                                className="btn btn-primary btn-lightgreen mr-1"
                              >
                                <i className="la la-save mr-2"></i>
                                Move
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
              <li>
                {/* <Tooltip title="Custom Column"> */}
                  <a
                   onClick={() => {
                        history.push("/ccontactcustomcolumn");
                      }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="la flaticon-interface-8 la-icon-font-size-13"></i>
                    Custom Column
                  </a>
                {/* </Tooltip> */}
              </li>
              <li>
                {/* <Tooltip title="Add Tag"> */}
                  <a
                    onClick={() => {
                      ContactAddTagBtn();
                    }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="fa la-icon-font-size-13 fa-tag"></i>
                    Add Tag
                  </a>
                {/* </Tooltip> */}
                <Popup open={ShowPopup}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">Tag</h5>
                            <button
                              className="close"
                              onClick={() => SetShowPopup(false)}
                            >
                              <span
                                aria-hidden="true"
                                className="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="modal-body">
                            <div className="col-sm-6 px-0">
                              <div className="row mb-3">
                                <label className="col-lg-5 col-form-label text-left px-5">
                                  Tag
                                </label>
                                <div className="col-lg-7">
                                  <input
                                    className="form-control m-input"
                                    id="TagAdd"
                                    data-val="true"
                                    data-val-remote="Title already exist."
                                    data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                    data-val-required="Please enter tag"
                                    name="Tag"
                                    placeholder="Enter tag"
                                    type="text"
                                  />
                                  <p
                                    id="tag_err"
                                    className="errorinput"
                                    style={{ display: "none" }}
                                  >
                                    please enter tag
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-top modal-content text-right pt-3">
                            <div className="row">
                              <div className="col-lg-12 pull-right">
                                <button
                                  id="backtolist"
                                  className="btn btn-secondary mr-2"
                                  onClick={() => {
                                    SetShowPopup(false);
                                  }}
                                >
                                  cancel
                                </button>
                                <button
                                  disabled={AddTagBtnDisabled}
                                  id="submit"
                                  onClick={ContactTagAdd}
                                  className="btn btn-primary btn-lightgreen mr-1"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </li>
              <li>
                {/* <Tooltip title="Delete Tag"> */}
                  <a
                    className="btn btngroup m-btn m-btn--custom"
                    onClick={() => {
                    ContactDeleteTagBtn();
                    }}
                  >
                    <i className="fa la-icon-font-size-13 fa-tag text-danger"></i>
                    Delete Tag
                  </a>
                {/* </Tooltip> */}

                <Popup open={DeleteTagModel}>
             
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">Delete Tag</h5>
                            <button
                              className="close"
                              onClick={() => {
                                SetDeleteTagModel(false);
                              }}
                            >
                              <span
                                aria-hidden="true"
                                className="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="modal-body">
                            <div className="col-sm-6 mb-5 px-0">
                              <div className="row mb-3">
                                <label className="col-lg-5 col-form-label text-left">
                                  Tag
                                </label>
                                <div className="col-lg-7">
                                  <Select
                                    placeholder="Select Tag"
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={contactoptions}
                                    id="ContactTagID"
                                    isMulti
                                    onChange={(val) =>
                                      handleChangeContactOption(val)
                                    }
                                    noOptionsMessage={() => 'No result found'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-top modal-content text-right pt-3">
                            <div className="row">
                              <div className="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  className="btn btn-secondary mr-2"
                                  onClick={() => {
                                    SetDeleteTagModel(false);
                                  }}
                                >
                                  Cancel
                                </a>
                                <a
                                  id="submit"
                                  onClick={DeleteSelectContactTag}
                                  className="btn btn-primary btn-lightgreen mr-1"
                                >
                                  <i className="la la-save mr-2"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </li>

              <li>
                {/* <Tooltip title="Reassign Sales Development Reps"> */}
                  <a
                    onClick={() => {
                      ContactReassignDevBtn();
                    }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="fa la-icon-font-size-13 fa fa-exchange"></i>
                    SDR Swap
                  </a>
                {/* </Tooltip> */}
                <Popup open={ShowPopupReassignDev}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup longerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">SDR Swap</h5>
                            <button
                              className="close"
                              onClick={() => {
                                ContactReassignDevPopupHideBtn();
                              }}
                            >
                              <span
                                aria-hidden="true"
                                className="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="modal-body">
                            <div className="col-sm-10 mb-4 px-0">
                              <div className="row mb-3">
                                <label className="col-lg-4 text-left reassign-form">
                                  From
                                </label>
                                <div className="col-lg-8">
                                  <Select
                                    // closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={[{ label: "Unassigned", value: "unassigned" }, ...ContactReassignData]}
                                    value={ReassignFromSelectedValue}
                                    id="FromRe"
                                    placeholder={
                                      "Select Sales Development Reps"
                                    }
                                    onChange={(e) =>
                                      HandleChangeFromSaleDevlopMentReps(e)
                                    }
                                    noOptionsMessage={() => 'No result found'}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-10 mb-3 px-0">
                              <div className="row mb-3">
                                <label className="col-lg-4 reassign-form text-left">
                                  To
                                </label>
                                <div className="col-lg-8">
                                  <Select
                                    // closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    placeholder={
                                      "Select Sales Development Reps"
                                    }
                                    options={ContactToReassignData}
                                    onChange={(val) =>
                                      HandleChangeToSaleDevlopMentReps(val)
                                    }
                                    noOptionsMessage={() => 'No result found'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-top modal-content text-right pt-3">
                            <div className="row">
                              <div className="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  className="btn btn-secondary mr-2"
                                  onClick={() => {
                                    ContactReassignDevPopupHideBtn();
                                  }}
                                >
                                  Cancel
                                </a>
                                <a
                                  id="submit"
                                  onClick={() => ReassignToColdCallers()}
                                  className="btn btn-primary btn-lightgreen mr-1"
                                >
                                  <i className="la la-save mr-1"></i>
                                  Swap
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </li>

              {/* To Reassign Sales Developer reps */}
              <li>
                {/* <Tooltip title="Reassign Sales Development Reps"> */}
                  <a
                    onClick={() => {
                      ContactReassignSDR();
                    }}
                    className="btn btngroup m-btn m-btn--custom"
                  >
                    <i className="fa la-icon-font-size-13 fa fa-exchange"></i>
                    Reassign Contacts
                  </a>
                {/* </Tooltip> */}
                <Popup open={ShowPopupReassignSDR}>
                  <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup longerPopup">
                      <div className="paddingboxTerms">
                        <div className="modal-header py-4 px-3">
                          <div className="w-100 d-flex px-3">
                            <h5 className="mb-0">Reassign Contacts</h5>
                            <button
                              className="close"
                              onClick={() => {
                                ContactReassignSDRPopupHideBtn();
                              }}
                            >
                              <span
                                aria-hidden="true"
                                className="la la-remove"
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="modal-body">
                       
                            <div className="col-sm-10 mb-3 px-0">
                              <div className="row mb-3">
                                <label className="col-lg-4 reassign-form text-left">
                                  To
                                </label>
                                <div className="col-lg-8">
                                  <Select
                                    // closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    placeholder={
                                      "Select Sales Development Reps"
                                    }
                                    options={ContactReassignData}
                                    onChange={(val) =>
                                      HandleChangeToReassignSDR(val)
                                    }
                                    noOptionsMessage={() => 'No result found'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-top modal-content text-right pt-3">
                            <div className="row">
                              <div className="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  className="btn btn-secondary mr-2"
                                  onClick={() => {
                                    ContactReassignSDRPopupHideBtn();
                                  }}
                                >
                                  Cancel
                                </a>
                                <a
                                  id="submit"
                                  onClick={() => ReassignToSDR()}
                                  className="btn btn-primary btn-lightgreen mr-1"
                                >
                                  <i className="la la-save mr-1"></i>
                                  Reassign
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </li>
            </ul>
          </div>
          </Popover>
          </div>
        </div>
      </div>
      {/* Import Csv for Black list*/}

          <div className="border-bottom portletbody px-0" id="import_csv" style={{display:"none"}}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Import Contact Details</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={ImprotCsvHandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={ImportCsvReset}
              >
                <span>Select .CSV file</span>
                <p>Add Contact data from csv file to bulk upload.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright mt-0">
              <a onClick={ContactMapCsv} className="btn btn-primary btnColor">
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={CancleContactBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>



      {/* Mapped div */}
      <div
        className="portletbody p-0 map_attribute my-0"
        id="map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12 border-bottom mx-0 mb-3 py-2">
          <h4 className="headertitle xs-headertitle float-left pl-0 mb-0">Map attributes</h4>
        </div>
        <div className="row mx-0 border-bottom">
        <div className="col-12" id="divContactSelection">
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn1"
                  disabled="disabled"
                >
                  <option selected="" value="First Name">
                    First Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="First Name"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={ FirstName == null ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "first name" ||
                                                                          item.toLowerCase() === "firstname" || item.toLowerCase() === "first" || item.toLowerCase() === "first_name"
                                                                      )
                                                                      : FirstName
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn2"
                  disabled="disabled"
                >
                  <option selected="" value="Last Name">
                    Last Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Last Name"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={LastName == null ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "last name" ||
                                                                          item.toLowerCase() === "lastname" || item.toLowerCase() === "last" || item.toLowerCase() === "last_name"
                                                                      )
                                                                      : LastName
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn3"
                  disabled="disabled"
                >
                  <option selected="" value="Email">
                    Email
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Email"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Email == null ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "email" || item.toLowerCase() === "email address" || item.toLowerCase() === "email account" || item.toLowerCase() === "emailaccount" || item.toLowerCase() === "emailaddress" 
                                                                      )
                                                                      : Email
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn4"
                  disabled="disabled"
                >
                  <option selected="" value="Title">
                    Title
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Title"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Title == null ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "title" || item.toLowerCase() === "job title" || item.toLowerCase() === "people title" || item.toLowerCase() === "jobtitle" || item.toLowerCase() === "job_title"
                                                                      )
                                                                      : Title
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn5"
                  disabled="disabled"
                >
                  <option selected="" value="Company">
                    Company
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Company"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Company == null ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "company" || item.toLowerCase() === "company name" || item.toLowerCase() === "business name" || item.toLowerCase() === "companyname" || item.toLowerCase() === "businessname" 
                                                                      )
                                                                      : Company
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn6"
                  disabled="disabled"
                >
                  <option selected="" value="CompanyNameLong">
                    Company Name Long
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyNameLong"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyNameLong == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "company name long" ||
                                                                          item.toLowerCase() === "companynamelong"
                                                                      )
                                                                      : CompanyNameLong
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn11"
                  disabled="disabled"
                >
                  <option selected="" value="Website">
                    Website
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Website"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Website == null ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "website" ||
                                                                        item.toLowerCase() === "url" ||
                                                                        item.toLowerCase() === "company website" ||
                                                                        item.toLowerCase() === "company url" ||
                                                                        item.toLowerCase() === "domain"  ||
                                                                        item.toLowerCase() === "company domain" ||
                                                                        item.toLowerCase() === "site" 
                                                                      )
                                                                      : Website
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn6"
                  disabled="disabled"
                >
                  <option selected="" value="CompanyPhone">
                    Company Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyPhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyPhone == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "company phone" ||
                                                                          item.toLowerCase() === "companyphone" ||
                                                                          item.toLowerCase() === "business phone" ||
                                                                          item.toLowerCase() === "businessphone"
                                                                      )
                                                                      : CompanyPhone
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="DirectPhone">
                    Direct Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="DirectPhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    DirectPhone == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "direct phone" ||
                                                                          item.toLowerCase() === "directphone" || item.toLowerCase() === "direct_phone" || item.toLowerCase() === "person phone" || item.toLowerCase() === "person_phone" || item.toLowerCase() === "personphone"
                                                                      )
                                                                      : DirectPhone
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="DirectPhoneExt">
                    Direct Phone Ext
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="DirectPhoneExt"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    DirectPhoneExt == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "direct phone ext" ||
                                                                          item.toLowerCase() === "directphoneext"
                                                                      )
                                                                      : DirectPhoneExt
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn14"
                  disabled="disabled"
                >
                  <option selected="" value="LinkedInURL">
                    LinkedIn URL
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="LinkedInURL"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    LinkedInURL == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "linkedinurl" ||
                                                                          item.toLowerCase() === "linkedin url" ||
                                                                          item.toLowerCase() === "linkedin"  ||
                                                                          item.toLowerCase() === "person linkedin url" ||
                                                                         item.toLowerCase() === "linkedin profile" ||
                                                                         item.toLowerCase() === "linkedin profile url"
                                                                      )
                                                                      : LinkedInURL
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn18"
                  disabled="disabled"
                >
                  <option selected="" value="Industry">
                    Industry
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Industry"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Industry == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "industry"
                                                                       
                                                                      )
                                                                      : Industry
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn12"
                  disabled="disabled"
                >
                  <option selected="" value="Address1">
                    Address 1
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Address1"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Address1 == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "address 1" ||
                                                                          item.toLowerCase() === "address1"
                                                                       
                                                                      )
                                                                      : Address1
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn13"
                  disabled="disabled"
                >
                  <option selected="" value="Address2">
                    Address 2
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Address2"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Address2 == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "address 2" ||
                                                                          item.toLowerCase() === "address2"
                                                                       
                                                                      )
                                                                      : Address2
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn7"
                  disabled="disabled"
                >
                  <option selected="" value="City">
                    City
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="City"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={City == null ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "city" 
                                                                       
                                                                      )
                                                                      : City
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn8"
                  disabled="disabled"
                >
                  <option selected="" value="State">
                    State
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="State"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={State == null ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "state" 
                                                                       
                                                                      )
                                                                      : State
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn15"
                  disabled="disabled"
                >
                  <option selected="" value="Zip">
                    Zip
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Zip"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Zip == null ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "zip" 
                                                                       
                                                                      )
                                                                      : Zip
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn16"
                  disabled="disabled"
                >
                  <option selected="" value="Country">
                    Country
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Country"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Country == null ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "country" 
                                                                       
                                                                      )
                                                                      : Country
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn9"
                  disabled="disabled"
                >
                  <option selected="" value="ContactCategory">
                    Contact Category
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ContactCategory"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ContactCategory == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "contact category" ||
                                                                          item.toLowerCase() === "contactcategory" 
                                                                       
                                                                      )
                                                                      : ContactCategory
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn10"
                  disabled="disabled"
                >
                  <option selected="" value="AccountCategory">
                    Account Category
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="AccountCategory"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountCategory == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "account category" ||
                                                                          item.toLowerCase() === "accountcategory" 
                                                                       
                                                                      )
                                                                      : AccountCategory
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn17"
                  disabled="disabled"
                >
                  <option selected="" value="ValidationScore">
                    Validation Score
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ValidationScore"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ValidationScore == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "validation score" ||
                                                                          item.toLowerCase() === "validationscore" 
                                                                       
                                                                      )
                                                                      : ValidationScore
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn17"
                  disabled="disabled"
                >
                  <option selected="" value="ListName">
                    List Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <input
                  type="text"
                  id="ListName"
                  className="form-control  m-input"
                  value={filename}
                />
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="ContactSource">
                    Contact Source
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ContactSource"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ContactSource == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "contact source" ||
                                                                          item.toLowerCase() === "contactsource" 
                                                                       
                                                                      )
                                                                      : ContactSource
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="ContactOwner">
                    Contact Owner
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ContactOwner"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ContactOwner == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "contact owner" ||
                                                                          item.toLowerCase() === "contactowner" 
                                                                       
                                                                      )
                                                                      : ContactOwner
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="ClientPOC">
                    Client POC
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ClientPOC"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ClientPOC == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "client poc" ||
                                                                          item.toLowerCase() === "clientpoc" 
                                                                       
                                                                      )
                                                                      : ClientPOC
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="ReasonUnqualified">
                    Reason Unqualified
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ReasonUnqualified"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ReasonUnqualified == null
                      ?   DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "reason unqualified" ||
                                                                          item.toLowerCase() === "reasonunqualified" 
                                                                       
                                                                      )
                                                                      : ReasonUnqualified
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="AccountType">
                    Account Type
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="AccountType"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AccountType == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "accounttype" ||
                                                                          item.toLowerCase() === "account type" 
                                                                       
                                                                      )
                                                                      : AccountType
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn19"
                  disabled="disabled"
                >
                  <option selected="" value="CompanyRevenue">
                    Company Revenue
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyRevenue"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyRevenue == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "companyrevenue" ||
                                                                          item.toLowerCase() === "company revenue" 
                                                                       
                                                                      )
                                                                      : CompanyRevenue
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn20"
                  disabled="disabled"
                >
                  <option selected="" value="EmployeeCount">
                    Employee Count
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="EmployeeCount"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    EmployeeCount == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "employeecount" ||
                                                                          item.toLowerCase() === "employee count" 
                                                                       
                                                                      )
                                                                      : EmployeeCount
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="MobilePhone">
                    Mobile Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="MobilePhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    MobilePhone == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "mobile phone" ||
                                                                          item.toLowerCase() === "mobilephone"  ||
                                                                          item.toLowerCase() === "mobile" ||
                                                                          item.toLowerCase() === "cell" ||
                                                                          item.toLowerCase() === "cell phone"
                                                                       
                                                                      )
                                                                      : MobilePhone
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="IsDoNotCallContact">
                    Do not call contact
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="IsDoNotCallContact"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    IsDoNotCallContact == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "donotcallcontact" ||
                                                                          item.toLowerCase() === "do not call contact" 
                                                                       
                                                                      )
                                                                      : IsDoNotCallContact
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="SalesRep">
                    Sales Rep
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="SalesRep"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    SalesRep == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "salesrep" ||
                                                                          item.toLowerCase() === "sales rep" 
                                                                       
                                                                      )
                                                                      : SalesRep
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="ColdCaller">
                    Sales Development Reps
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ColdCaller"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ColdCaller == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "sales development reps" ||
                                                                          item.toLowerCase() === "salesdevelopmentreps" 
                                                                       
                                                                      )
                                                                      : ColdCaller
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="Tag">
                    Tag
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Tag"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Tag == null ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "tag" 
                                                                       
                                                                      )
                                                                      : Tag
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="CallNotes">
                    Call Notes
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CallNotes"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CallNotes == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "callnotes" ||

                                                                          item.toLowerCase() === "call notes"
                                                                      )
                                                                      : CallNotes
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="OtherPhone1">
                    OtherPhone 1
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="OtherPhone1"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    OtherPhone1 == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "otherphone1" ||

                                                                          item.toLowerCase() === "other phone 1"
                                                                      )
                                                                      : OtherPhone1
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="OtherPhone2">
                    OtherPhone 2
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="OtherPhone2"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    OtherPhone2 == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "otherphone2" ||

                                                                          item.toLowerCase() === "other phone 2"
                                                                      )
                                                                      : OtherPhone2
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="ContactID">
                    ContactID
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ContactID"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ContactID == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "contactid" ||

                                                                          item.toLowerCase() === "contact id"
                                                                      )
                                                                      : ContactID
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="ZoomID">
                  Zoom ID
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ZoomID"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ZoomID == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "zoom id" ||
                                                                          item.toLowerCase() === "zoomid"
                                                                      )
                                                                      : ZoomID
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="BestPhone">
                  Best Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="BestPhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    BestPhone == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "best phone" ||
                                                                          item.toLowerCase() === "bestphone"
                                                                      )
                                                                      : BestPhone
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="BestPhoneStatus">
                  Best Phone Status
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="BestPhoneStatus"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    BestPhoneStatus == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "best phone status" ||
                                                                          item.toLowerCase() === "bestphonestatus"
                                                                      )
                                                                      : BestPhoneStatus
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="Phone">
                  Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Phone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Phone == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "phone" 
                                                                      )
                                                                      : Phone
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="PhoneStatus">
              Phone Status
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="PhoneStatus"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    PhoneStatus == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "phone status" ||
                                                                          item.toLowerCase() === "phonestatus"
                                                                      )
                                                                      : PhoneStatus
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="Phone2">
                  Phone2
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Phone2"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Phone2 == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "phone2"
                                                                      )
                                                                      : Phone2
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="Phone2Status">
              Phone2 Status
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Phone2Status"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Phone2Status == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "phone2 status" ||
                                                                          item.toLowerCase() === "phones2tatus"
                                                                      )
                                                                      : Phone2Status
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="Phone3">
                  Phone3
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Phone3"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Phone3 == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "phone3"
                                                                      )
                                                                      : Phone3
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group row mx-0">
         
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="Phone3Status">
                  Phone3 Status
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Phone3Status"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Phone3Status == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "phone3 status" ||
                                                                          item.toLowerCase() === "phone3status"
                                                                      )
                                                                      : Phone3Status
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="Phone4">
              Phone4
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Phone4"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Phone4 == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "phone4" 
                                                                      )
                                                                      : Phone4
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
           
          </div>
          <div className="form-group m-form__group row mx-0">
      
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="Phone4Status">
                  Phone4 Status
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Phone4Status"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Phone4Status == null
                      ? DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "phone4 status" ||
                                                                          item.toLowerCase() === "phone4status"
                                                                      )
                                                                      : Phone4Status
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="PRLLeadID">
                  External ID
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="PRLLeadID"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    PRLLeadID == null
                      ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "prl lead id (salesforce id)" ||
                                                                          item.toLowerCase() === "prlleadid(salesforceid)" ||
                                                                          item.toLowerCase() === "external id" ||
                                                                          item.toLowerCase() === "externalid" 
                                                                      )
                                                                      : PRLLeadID
                                                                  }
                                                                  onChange={handleSelectChangeImportMapping}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{(dbdata.toLowerCase() === "prl lead id (salesforce id)" || dbdata.toLowerCase() === "prlleadid(salesforceid)" || dbdata.toLowerCase() === "external id" || dbdata.toLowerCase() === "externalid") ? "External ID" : dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

   <div className="form-group m-form__group row mx-0">
  {
    ContactCustomFieldData.map((customfield, index) => {
      return (
        <>

          <div className="col-lg-2 nopadding mb-4">
            <div>
              <select
                className="dbcontent form-control  m-input"
                id="dbColumn23"
                disabled="disabled"
              >
                <option selected="" value={customfield?.FieldName}>
                  {customfield?.FieldName}
                </option>
              </select>
            </div>
          </div>

          <div className="col-lg-1 text-center">
            <span className="fa fa-arrows-h fa-2x "></span>
          </div>

          <div className="col-lg-2 nopadding mb-4">
            <div>
              <select
                id={customfield?.FieldName}
                className="csvcontent form-control  m-input"
                autocomplete="off"
                value={
                  selectedValues[customfield.FieldName]
                    ? DropboxData.findIndex(
                        (item) =>
                          item.toLowerCase() ==
                          selectedValues[customfield.FieldName].toLowerCase()
                      )
                    : ""
                }
                onChange={(e) =>
                  handleSelectChange(customfield.FieldName, e.target.value)
                }
              >
                <option>Not Mapped</option>
                {DropboxData.map((dbdata, index) => (
                  <option value={index}>{dbdata}</option>
                ))}
              </select>
            </div>
          </div>
          {index % 2 === 0 && <div className="col-lg-1"></div>}

        </>
      );
    })
  }
</div>

    
          <div className="form-group m-form__group row px-4">
            <div className="toggleswich mr-2">
              <input
                type="checkbox"
                className="checktogle"
                id="IsUpdateExistContacts"
              />
              <b className="switch">
                <span className="checkion"></span>
                <span className="uncheckion"></span>
              </b>
              <b className="track"></b>
            </div>
            <label> Skip updating existing contacts</label>
          </div>
          <div className="form-group m-form__group row px-4">
            <div className="toggleswich mr-2">
              <input
                type="checkbox"
                className="checktogle"
                id="IsInsertNullValues"
              />
              <b className="switch">
                <span className="checkion"></span>
                <span className="uncheckion"></span>
              </b>
              <b className="track"></b>
            </div>
            <label> Insert Null Values</label>
          </div>
          <div className="form-group m-form__group row border-bottom px-4 pb-3 pb-3 mb-0">
            <div className="toggleswich mr-2">
              <input
                type="checkbox"
                className="checktogle"
                id="IsDoNotupdatecompanyname"
              />
              <b className="switch">
                <span className="checkion"></span>
                <span className="uncheckion"></span>
              </b>
              <b className="track"></b>
            </div>
            <label>Do Not Update Company Name</label>
          </div>
         
          <div className="form-group m-form__group py-3 mb-0 px-2">
            <a
              onClick={ContactSaveCsv}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </a>
          </div> 
        </div>
        </div>

      </div>

      {/* Total Details for csv */}
      <div
        className="portletbody p-0 my-0 border-bottom p-3 py-4"
        id="Show_csv_details"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12 mx-0">
          <h4 className="headertitle xs-headertitle float-left mb-0 p-0 pb-4">Results</h4>
        </div>

        <div className="col-xs-12 mx-0">
          <div className="row colfive mx-0">
            <div className="col-md-3 mb-3">
              <div className=" boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2 text-center d-block">
                      <span className="box-main-title font-primary">
                        {Total}
                      </span>
                    </div>
                    <div className="box-inner-title xs-headertitle">
                      <string className="clr-sky">Total</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className=" boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2 text-center d-block">
                      <span className="box-main-title font-success">
                        {TotalSucess}
                      </span>
                    </div>
                    <div className="box-inner-title xs-headertitle">
                      <string className="clr-sky">Total success</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className=" boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2 text-center d-block">
                      <span className="box-main-title font-danger">
                        {TotalError}
                      </span>
                    </div>
                    <div className="box-inner-title xs-headertitle">
                      <string className="clr-sky">Total error</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className=" boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2 text-center d-block">
                      <span className="box-main-title font-warning">
                        {TotalDuplicate}
                      </span>
                    </div>
                    <div className="box-inner-title xs-headertitle">
                      <string className="clr-sky">Total Duplicate</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className=" boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2 text-center d-block">
                      <span className="box-main-title font-warning">
                        {TotalUpdated}
                      </span>
                    </div>
                    <div className="box-inner-title xs-headertitle">
                      <string className="clr-sky">Total Updated</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bulk Csv for */}

          <div className="portletbody bg-white border-left-0 border-right-0 border-top-0 p-0 py-4 my-0" id="delete_csv" style={{display:"none"}} >
        <div className="row">
          <div className="col-xl-8 offset-xl-2 pb-3">
            <h3 className="uploadheadingtitle">Bulk Delete Contact</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={ResetBulkDelete}
              >
                <span>Select .CSV file</span>
                <p>Add contact data from csv file to bulk delete.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a
                onClick={BulkDeleteContactBtn}
                className="btn btn-primary btnColor"
              >
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={CancelBulkContactBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>


      {/* Mapped div */}
      <div
        className="portletbody border-bottom p-0 my-0 map_attribute"
        id="map_attribute_domain_blacklist"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12 mx-0">
          <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
        </div>
        <div className="mx-m-1"> 
        <div className="col-xs-12 px-3">
          <div className="form-group m-form__group row mx-0">
          <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn3"
                  disabled="disabled"
                >
                  <option selected="" value="Email">
                    Email
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Email"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Email == null ?  DropboxData.findIndex(
                                                                        (item) =>
                                                                          item.toLowerCase() === "email" 
                                                                      )
                                                                      : Email
                                                                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          <div className="col-xs-12 pt-2 mb-3">
            <a
              onClick={DeleteBulkContactCsv}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </a>
          </div>
        </div>
        </div> 
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6"></div>
        <div className="col-sm-12 col-md-6">
          <div className="listing-text listing-li float-right padb-15">
            <ul className="mt-2">
              <li>
                <a
                  id="UnSelect"
                  defaultValue={CheckState}
                  onClick={(e) => UnSelectCheckHandler(e)}
                  style={{ display: "none" }}
                  className="mt-2 px-2"
                >
                  Unselect All
                </a>
              </li>
              {IsResetApply == true ? (
                <li>
                  <a
                    onClick={() => {
                      ResetFilter();
                    }}
                  >
                    {" "}
                    Reset
                  </a>
                </li>
              ) : null}

              <li>
                <a
                  id="Select-All"
                  className="mr-5"
                  defaultValue={CheckState}
                  onClick={(e) => SelectCheckHandler(e)}
                >
                  Select All
                </a>
              </li>
              <li>
                <a
                  onClick={DeleteSelectedAll}
                  className="btn btn-danger m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air text-white"
                  id="deleteall-btn"
                  style={{ display: "none" }}
                >
                  <i className="la flaticon-delete-1  text-white"></i>
                  <span> Delete All</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row px-4">
        <div className="col-sm-12 col-md-6">
        {
          IsApply === true ? <>
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={filterRowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
          </> : <>
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
          </>
        }

        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="px-4">
        <div className="table-bordered call-notes-width">
        <TableContainer component={Paper}> 
              <StyleHeader isDataLoaded={isDataLoaded} />
              <Table className="table-ref" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className="p-1 text-center" align="center">
                    <input
                      type="checkbox"
                      id="main-check"
                      onChange={(e) => CheckHandler(e)}  
                    />
                  </TableCell>
       
                  {ContactHeader?.map((row) => (
                    <TableCell
                      className={row.ColumnName.replace(/\s+/g, "-")}
                      key={row._id}
                      onClick={() => {
                      if (row.CustomFieldID) {
                        // Optionally handle clicks when CustomFieldID has a value
                      } else {
                        // Default sort and column handling
                        SortData(row.ColumnName.replace(/\s+/g, ""));
                        setSortedColumn(row.ColumnName);
                      }
    }}
                    >
                      {row.ColumnName === "Last Call Result" 
  ? "Disposition Status" 
  : row.ColumnName === "PRL Lead ID (SalesForce ID)"
  ? "External ID" 
  : row.ColumnName}
  {!row.CustomFieldID && (
      <span className="shorting">
        <ArrowUpward
          className={sortedBy === 1 && sortedColumn === row.ColumnName ? "active" : null}
        />
        <ArrowDownward
          className={sortedBy === -1 && sortedColumn === row.ColumnName ? "active" : null}
        />
      </span>
    )}
                    </TableCell>
                  ))}

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length == 0 ? (
                  <p>No data available in table</p>
                ) : (
                  rows?.map((value1) => {
                    const hasNotes = value1?.CallNotes;
                    const isFullShown = showFull[value1._id]; 
                    const slicedNotes = hasNotes ? value1.CallNotes.slice(0, 200) : '';        
                    const isMoreSevanty = hasNotes && value1.CallNotes.length > 200;        
                    return(
                    <>
                      <TableRow key={value1._id}>
                        <TableCell>
                          <a
                            href={`/viewcontacts?id=${value1?._id}&email=${encodeURIComponent(value1?.Email)}&pagename=`}
                            onClick={(e) => {
                                e.preventDefault();
                                ViewBtn(value1?._id, value1?.Email);
                            }}
                            className="btn-eyesicon"
                            title="View"
                          >
                            <i className="la flaticon-eye edit-icon"></i>
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          <input
                      type="checkbox"
                      id={value1?._id}
                      onChange={(e) => ProspectCheckHandler(e, value1)}
                    
                      checked={prospectAllSelectData.some(item => item._id === value1?._id)}
                          />
                        </TableCell>

                    {ContactHeader.map((row) => {
                      if (row.ColumnName == "Contact Name") {
                        return <TableCell><a
                        href={`/viewcontacts?id=${value1?._id}&email=${encodeURIComponent(value1?.Email)}&pagename=`}
                        onClick={(e) => {
                            e.preventDefault();
                            ViewBtn(value1?._id, value1?.Email);
                        }}
                  //  onClick={() => {
                  //         ViewBtn(value1?._id, value1.Email);
                  //       }}
                      >
                      {/* <u>{value1?.ConatactName}</u> */}
                      <u>
  {value1?.ContactName &&
  value1?.ContactName.toLowerCase() ===
    `${value1?.FirstName} ${value1?.LastName}`.toLowerCase()
    ? value1?.ContactName
    : `${value1?.FirstName} ${value1?.LastName}`}
</u>
                      </a></TableCell>;
                      } else if (row.ColumnName == "Account Name") {
                        return <TableCell>             <a
                        href={`/viewaccount?id=${value1?.Accountname?.SalesReplyAccountID}&pagename=MainContactPage`}
                        onClick={(e) => {
                          e.preventDefault();
                          ViewAccountPage(value1?.Accountname?.SalesReplyAccountID);
                        }}
                      // onClick={() => {
                      //   ViewAccountPage(value1?.Accountname?.SalesReplyAccountID);
                      //}}
                            className="btn-eyesicon"
                            title="View"
                          > <u>{value1?.Accountname?.SalesReplyAccountName}</u></a></TableCell>;
                      } else if (row.ColumnName == "Email") {
                        return <TableCell>{value1?.Email}</TableCell>;
                      } else if (row.ColumnName == "Title") {
                        return <TableCell className="wrap">{value1?.Title}</TableCell>;
                      } else if (row.ColumnName == "Validation Score") {
                        return <TableCell>{value1?.ValidationScore}</TableCell>;
                      } else if (row.ColumnName == "Last Validation Date") {
                        return (
                          <TableCell>
                          {
                            value1?.LastValidationDate == null ? "" : moment.utc(
                              new Date(value1?.LastValidationDate)
                            ).format("MM/DD/YYYY")
                          }
    
                          </TableCell>
                        );
                      } else if (row.ColumnName == "Company") {
                        return <TableCell>{value1?.Company}</TableCell>;
                      } else if (row.ColumnName == "Website") {
                        return <TableCell><a    href={`http://${value1?.Website}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}><u>{value1?.Website}</u></a></TableCell>;
                      } else if (row.ColumnName == "LinkedIn URL") {
                        return <TableCell className="wrap">{value1?.LinkedInURL}</TableCell>;
                      } else if (row.ColumnName == "Industry") {
                        return <TableCell>{value1?.Industry}</TableCell>;
                      } else if (row.ColumnName == "Address 1") {
                        return <TableCell>{value1?.Address1}</TableCell>;
                      } else if (row.ColumnName == "Address 2") {
                        return <TableCell>{value1?.Address2}</TableCell>;
                      } else if (row.ColumnName == "City") {
                        return <TableCell>{value1?.City}</TableCell>;
                      } else if (row.ColumnName == "State") {
                        return <TableCell>{value1?.State}</TableCell>;
                      } else if (row.ColumnName == "Company Phone") {
                        return <TableCell>{value1?.CompanyPhone}</TableCell>;
                      } else if (row.ColumnName == "Zip") {
                        return <TableCell>{value1?.Zip}</TableCell>;
                      } else if (row.ColumnName == "Country") {
                        return <TableCell>{value1?.Country}</TableCell>;
                      } else if (row.ColumnName == "Contact Category") {
                        return <TableCell>{value1?.ContactCategory}</TableCell>;
                      } else if (row.ColumnName == "Account Category") {
                        return <TableCell>{value1?.AccountCategory}</TableCell>;
                      } else if (row.ColumnName == "List Name") {
                        return <TableCell className="wrap">{value1?.ListName}</TableCell>;
                      } else if (row.ColumnName == "Deliveries") {
                        return <TableCell>{value1?.ContactEmailSend?.Delivers == null || undefined || "" ? 0 : value1?.ContactEmailSend?.Delivers}</TableCell>;
                      } else if (row.ColumnName == "Opens") {
                        return <TableCell>{value1?.ContactEmailSend?.Opens == null || undefined || "" ? 0 : value1?.ContactEmailSend?.Opens}</TableCell>;
                      } else if (row.ColumnName == "Replies") {
                        return <TableCell>{value1?.ContactEmailSend?.Replies == null || undefined || "" ? 0 : value1?.ContactEmailSend?.Replies}</TableCell>;
                      } else if (row.ColumnName == "Soft Bounces") {
                        return <TableCell>{value1?.ContactEmailSend?.SoftBounce == null || undefined || "" ? 0 : value1?.ContactEmailSend?.SoftBounce}</TableCell>;
                      } else if (row.ColumnName == "Hard Bounces") {
                        return <TableCell>{value1?.ContactEmailSend?.HardBounce == null || undefined || "" ? 0 : value1?.ContactEmailSend?.HardBounce}</TableCell>;
                      } else if (row.ColumnName == "Contact Status") {
                        {/* const status = value1?.ProspectStatusDetails?.Status == null || undefined || "" ? ""  ""; */}
                        return <TableCell>{value1.ProspectStatusDetails !== undefined?value1.ProspectStatusDetails.length > 0?value1?.ProspectStatusDetails[0]?.Status == "" || null || undefined ? "" : value1?.ProspectStatusDetails[0]?.Status:"":""}</TableCell>;
                      } else if (row.ColumnName == "Contact Source") {
                        return <TableCell>{value1?.ContactSourceDetails?.Source}</TableCell>;
                      } else if (row.ColumnName == "Contact Owner") {
                        return <TableCell>{value1?.ContactOwnerDetails?.Owner}</TableCell>;
                      } else if (row.ColumnName == "Client POC") {
                        return <TableCell>{value1?.ContactClientPOCDetails?.ClientPOCName}</TableCell>;
                      } else if (row.ColumnName == "Reason Unqualified") {
                        return <TableCell>{value1?.ContactReasonUnqualifiedDetails?.Reason}</TableCell>;
                      } else if (row.ColumnName == "Account Type") {
                        return <TableCell>{value1?.AccountTypeName}</TableCell>;
                      } else if (row.ColumnName == "Direct Phone") {
                        return <TableCell>{value1?.DirectPhone}</TableCell>;
                      } else if (row.ColumnName == "Company Phone") {
                        return <TableCell>{value1?.CompanyPhone}</TableCell>;
                      } else if (row.ColumnName === "Company Revenue") {
  return (
    <TableCell>
      {
        value1?.Accountname?.CompanyRevenue === null || value1?.Accountname?.CompanyRevenue === undefined || value1?.Accountname?.CompanyRevenue === "" 
        ? 0 
        : value1?.Accountname?.CompanyRevenue
      }
    </TableCell>
  );
} else if (row.ColumnName === "Employee Count") {
  return (
    <TableCell>
      {
        value1?.Accountname?.CompanyEmployeeCount === null || value1?.Accountname?.CompanyEmployeeCount === undefined || value1?.Accountname?.CompanyEmployeeCount === "" 
        ? 0 
        : value1?.Accountname?.CompanyEmployeeCount
      }
    </TableCell>
  );
}
 else if (row.ColumnName == "Mobile Phone") {
                        return <TableCell>{value1?.MobilePhone}</TableCell>;
                      } else if (row.ColumnName == "Ankita test") {
                        return <TableCell>{value1?.Ankitatest}</TableCell>;
                      } else if (row.ColumnName == "Call Count") {
                        return <TableCell>{value1?.CallCount == null || undefined || "" ? 0 : value1?.CallCount}</TableCell>;
                      } else if (row.ColumnName == "Last Call Result") {
                        return <TableCell>{value1?.LastCallResult}</TableCell>;
                      } else if (row.ColumnName == "Last Call Date") {
                        return (
                          <TableCell>
                          {value1?.LastCallDate == null ? "" : moment.utc(
                              new Date(value1?.LastCallDate)
                            ).format("MM/DD/YYYY") 
                          }

                          </TableCell>
                        );
                      } else if (row.ColumnName == "Call Notes") { 
                        return  <TableCell className="call-notes-td">
                        {(hasNotes && isMoreSevanty) ? (
                          isFullShown ? (
                            <span>
                              {value1.CallNotes} 
                              <button className="notebtn-hide" onClick={() => CallNoteshandleToggle(value1._id)}>Less</button>
                            </span>
                          ) : (
                            <span>
                              {slicedNotes}
                              {value1.CallNotes.length > 200 && '...'}
                              <button className="notebtn-show" onClick={() => CallNoteshandleToggle(value1._id)}>More</button>
                            </span>
                          )
                        ) : (
                          <span>{value1.CallNotes}</span>
                        )}
                      </TableCell>;
                      } else if (row.ColumnName == "Contacted Date") {
                        return (
                          <TableCell>{
                            value1?.CampaignDetails?.LastEmailSendDate == null ||  value1?.CampaignDetails?.LastEmailSendDate == undefined ? "" : moment.utc(
                              new Date(value1?.CampaignDetails?.LastEmailSendDate)
                            ).format("MM/DD/YYYY")
                          }
                            {/* {moment.utc(
                              new Date(value1?.LastEmailSentDate[0]?.MailSentDate)
                            ).format("MM/DD/YYYY")
                               == null ? "" :  moment.utc(
                              new Date(value1?.LastEmailSentDate[0]?.MailSentDate)
                            ).format("MM/DD/YYYY")} */}
                          </TableCell>
                        );
                      } else if (row.ColumnName == "Do Not Call Contact") {
                        return (
                          <TableCell>
                            {value1?.IsDoNotCallContact == true ? "Yes" : "No"}
                          </TableCell>
                        );
                      } else if (row.ColumnName == "Last Email Campaign") {
                        return <TableCell>
                        {value1?.CampaignDetails?.Name === "" || value1?.CampaignDetails?.Name === null
                          ? value1?.CampaignCurrentDetails?.Name === undefined || value1?.CampaignCurrentDetails?.Name === null || value1?.CampaignCurrentDetails?.Name === ""
                            ? ""
                            : value1?.CampaignCurrentDetails?.Name
                          : value1?.CampaignDetails?.Name}
                      </TableCell>;
                      } else if (row.ColumnName == "Last Email Step") {
                        return <TableCell>{value1?.CampaignDetails?.LastEmailStep}</TableCell>;
                      } else if (row.ColumnName == "Sales Rep") {
                        return <TableCell>{value1?.SalesRep}</TableCell>;
                      } else if (row.ColumnName == "Direct Phone Ext") {
                        return <TableCell>{value1?.DirectPhoneExt}</TableCell>;
                      } else if (row.ColumnName == "Tag") { 
                        const tagNames = value1?.Tagname && value1.Tagname.length > 0
    ? value1.Tagname
        .filter(tag => tag.TagName) // Ensure TagName exists
        .map(tag => tag.TagName)
        .join(", ")
    : "";
                        return (
                          <TableCell>{tagNames}</TableCell>
                        );
                      } else if (row.ColumnName == "Sales Development Reps") {
                        return (
                          <>
                          {/* <TableCell>{value1?.coldCallers[0]?.ColdCallersName} {value1?.coldCallers[0]?.LastName}</TableCell> */}
                          <TableCell>{value1?.ContactColdCallerDetails?.ColdCallingName}</TableCell>
                          
                          </>
                        );
                      } else if (row.ColumnName == "Other Phone 2") {
                        return <TableCell>{value1?.OtherPhone2}</TableCell>;
                      } else if (row.ColumnName == "Other Phone 1") {
                        return <TableCell>{value1?.OtherPhone1}</TableCell>;
                      }else if (row?.ColumnName == "Zoom ID") {
                        return <TableCell>{value1?.ZoomID}</TableCell>;
                      }
                      else if (row?.ColumnName == "Best Phone") {
                        return <TableCell>{value1?.BestPhone}</TableCell>;
                      }
                      else if (row?.ColumnName == "Best Phone Status") {
                        return <TableCell>{value1?.BestPhoneStatus}</TableCell>;
                      }else if (row?.ColumnName == "Phone") {
                        return <TableCell>{value1?.Phone1}</TableCell>;
                      }else if (row?.ColumnName == "Phone Status") {
                        return <TableCell>{value1?.Phone1Status}</TableCell>;
                      } else if (row?.ColumnName == "Phone2") {
                        return <TableCell>{value1?.Phone2}</TableCell>;
                      }else if (row?.ColumnName == "Phone2 Status") {
                        return <TableCell>{value1?.Phone2Status}</TableCell>;
                      } else if (row?.ColumnName == "Phone3") {
                        return <TableCell>{value1?.Phone3}</TableCell>;
                      } else if (row?.ColumnName == "Phone3 Status") {
                        return <TableCell>{value1?.Phone3Status}</TableCell>;
                      }else if (row?.ColumnName == "Phone4") {
                        return <TableCell>{value1?.Phone4}</TableCell>;
                      }else if (row?.ColumnName == "Phone4 Status") {
                        return <TableCell>{value1?.Phone4Status}</TableCell>;
                      }else if (row?.ColumnName == "PRL Lead ID (SalesForce ID)") {
                        return <TableCell>{value1?.PRLLeadID}</TableCell>;
                      }  else if (row?.ColumnName == "Honey Score") {
                        return <TableCell>{value1?.HoneyScore}</TableCell>;
                      }else if (
                        row.CustomFieldID != null &&
                        row.CustomFieldID != ""
                      ) {
                        let valueCustomField = Array.isArray(value1.CustomFieldDetails) ? value1.CustomFieldDetails.filter((item) => item?.CustomFieldID === row?.CustomFieldID) : [];
         
                        if(valueCustomField?.length > 0){
         
                          return <TableCell>{valueCustomField[0]?.Value == "empty" || "" || null ? "" : valueCustomField[0]?.Value}</TableCell>;
                          
                        }else{
                          return <TableCell></TableCell>;
                        }
                    
                      }  else{
                        return <TableCell></TableCell>;
                      }
                    })}

                        <TableCell>
                          <a
                            onClick={() => {
                              ViewBtn(value1?._id, value1?.Email);
                            }}
                            className="btn-eyesicon"
                            title="View"
                          >
                            <i className="la flaticon-eye edit-icon"></i>
                          </a>
                          <a
                            onClick={() => {
                              DeleteBtn(value1?._id);
                            }}
                            className="btn-eyesicon"
                            title="Delete"
                          >
                            <i className="la flaticon-delete-1 delete-icon"></i>
                          </a>
                        </TableCell>
                      </TableRow>
                    </>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
<>
{
      IsApply == true ?  (<>
      {filtersflag ? (
          <div className="row">
            <Pagination
              component="div"
              count={filtercountpage}
              page={filterpage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
            <div className="col dataTables_info">
              <p>
                Showing {filterrlen == 0 ? 0 : (filterpage - 1) * filterRowsPerPage + 1} to{" "}
                {filterpage * filterRowsPerPage > filterrlen ? filterrlen : filterpage * filterRowsPerPage} of{" "}
                {filterrlen} entries (filtered from {filterflen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col dataTables_info">
              <p>
                Showing  {filterrlen == 0 ? 0 : (filterpage - 1) * filterRowsPerPage + 1} to{" "}
                {filterpage * filterRowsPerPage > filterrlen ? filterrlen : filterpage * filterRowsPerPage} of{" "}
                {filterrlen} entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={filtercountpage}
                page={filterpage}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </>): (
        <div>
      {sflag ? (
          <div className="row pb-3">
            <Pagination
              component="div"
              count={countpage}
              page={page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
            <div className="col dataTables_info">
              <p>
                Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
                {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of{" "}
                {rlen} entries (filtered from {flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div className="row pb-3">
            <div className="col dataTables_info">
              <p>
                Showing  {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
                {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of{" "}
                {rlen} entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={countpage}
                page={page}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
      )
    }
</>
      </div>
    </div>
  );
}
