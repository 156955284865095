import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  IconButton,
  Collapse,
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
           <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
           <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

function Notes({ UserID, ClientID, Role, AccountID, Domain, ChildUserID }) {
  const [Page, setPage] = useState(1);
  const [RowsPerPage, setRowsPerPage] = useState(100);
  const [Search, setSearch] = useState("");
  const [SortField, setSortField] = useState("CreatedDate");
  const [SortedBy, setSortedBy] = useState(-1);
  const [Sflag, setSflag] = useState(false);
  const [Data, setData] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Rlen, setRlen] = useState(0);
  const [Flen, setFlen] = useState(0);
  const [CountPage, setCountPage] = useState(0);
  const [ShowNotePopup, setShowNotePopup] = useState(false);
  const [TitleAvailableNotes, setTitleAvailableNotes] = useState(null);
  const [Fields, setFields] = useState({});
  const [Errors, setErrors] = useState({});
  const [PageType, setPageType] = useState("");
  const [ids, setids] = useState("");
  const [IsButtonDisabledNoteSave, setIsButtonDisabledNoteSave] =
    useState(false);
  const [IsButtonDisabledNoteUpdate, setIsButtonDisabledNoteUpdate] =
    useState(false);
  const [OldTitleUpdate, setOldTitleUpdate] = useState("");
  const [NotesUpdatModel, setNotesUpdatModel] = useState(false);
  const [sortedColumn, setSortedColumn] = React.useState('CreatedDate');

  useEffect(() => {
    // Fetch initial data
    NotesDetails(Domain, Search, Page, RowsPerPage);
  }, [Domain, Search, Page, RowsPerPage, AccountID, ClientID]);

  // contact note get
  const NotesDetails = (ContactList, Search, Page, RowPage) => {
    if (
      ContactList?.trim() !== "" &&
      AccountID?.trim() !== "" &&
      ClientID?.trim() !== ""
    ) {
      var AccountNoteData = {
        Page: parseInt(Page),
        ClientID: ClientID,
        SalesReplyAccountID: AccountID,
        Domain: ContactList,
        RowsPerPage: parseInt(RowPage),
        Sort: true,
        Field: SortField,
        Role: Role,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
        method: "POST",
        data: AccountNoteData,
      }).then((responsenote) => {
        if(responsenote.data.StatusMessage === "SUCCESS"){
          setData(responsenote.data?.PageData);
        setRows(responsenote.data?.PageData);
        setRlen(responsenote.data?.TotalCount);
        setFlen(responsenote.data?.TotalCount);
        setCountPage(responsenote.data?.PageCount);
        }else{
          toast.error(responsenote.data.Message)
        }
        
      });
    }
  };

  // call note contact change display rows
  const ChangeRowSelected = (event) => {
    setPage(1);
    setRowsPerPage(Number(event.target.value));
    var SearchedVal = document.getElementById("NotesSearch").value;
    var AccountNoteData = {
      Page: 1,
      ClientID: ClientID,
      SalesReplyAccountID: AccountID,
      Domain: Domain,
      RowsPerPage: Number(event.target.value),
      Sort: true,
      Field: SortField,
      Role: Role,
      SortBy: SortedBy,
      Search: SearchedVal,
      Type: "User",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
      method: "POST",
      data: AccountNoteData,
    }).then((responsenote) => {
      if(responsenote.data.StatusMessage === "SUCCESS"){
        setData(responsenote.data?.PageData);
      setRows(responsenote.data?.PageData);
      setRlen(responsenote.data?.TotalCount);
      setFlen(responsenote.data?.TotalCount);
      setCountPage(responsenote.data?.PageCount);
      }else{
        toast.error(responsenote.data.Message)
      }
    });
  };

  // search for record contact note
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("NotesSearch").value;
      setSearch(SearchedVal);
      var AccountNoteData = {
        Page: Page,
        ClientID: ClientID,
        SalesReplyAccountID: AccountID,
        Domain: Domain,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        Role: Role,
        SortBy: SortedBy,
        Search: SearchedVal,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
        method: "POST",
        data: AccountNoteData,
      }).then((responsenote) => {
        if(responsenote.data.StatusMessage === "SUCCESS"){
          setData(responsenote.data?.PageData);
        setRows(responsenote.data?.PageData);
        setRlen(responsenote.data?.TotalCount);
        setFlen(responsenote.data?.TotalCount);
        setCountPage(responsenote.data?.PageCount);
        }else{
          toast.error(responsenote.data.Message)
        }
      });
    }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    setPage(NewPage);
    var SearchedVal = document.getElementById("NotesSearch").value;
    if (NewPage == Page) {
      setPage(NewPage);
    } else {
      var AccountNoteData = {
        Page: Page,
        ClientID: ClientID,
        SalesReplyAccountID: AccountID,
        Domain: Domain,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        Role: Role,
        SortBy: SortedBy,
        Search: SearchedVal,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetSalesReplyAccountNotes",
        method: "POST",
        data: AccountNoteData,
      }).then((responsenote) => {
        if(responsenote.data.StatusMessage === "SUCCESS"){
          setData(responsenote.data?.PageData);
        setRows(responsenote.data?.PageData);
        setRlen(responsenote.data?.TotalCount);
        setFlen(responsenote.data?.TotalCount);
        setCountPage(responsenote.data?.PageCount);
        }else{
          toast.error(responsenote.data.Message)
        }
      });
    }
  };

  const Sorting = (Field) => {
    setSortField(Field)
      setSortedColumn(Field);
      const isNewSortField = Field !== SortField;
      let SortBy = SortedBy === 1 ? -1 : 1;
    
      if (isNewSortField) {
        setSortedBy(1);
        SortBy = 1;
      } else {
        setSortedBy(SortBy);
      }
    
      setSortField(Field);
   
    NotesDetails(Domain, Search, Page, RowsPerPage);
  };

  // save contact note
  const AccountNoteSave = async (e) => {
    setIsButtonDisabledNoteSave(true);
    var Final_flag = await fromValidationNotes();
    if (Final_flag == true) {
      var Title = document.getElementById("Title22").value;
      var Notes = document.getElementById("Notes22").value;
      var AccountNoteData = {
        ClientID: ClientID,
        UserID: ChildUserID,
        SalesReplyAccountID: AccountID,
        Title: Title,
        Note: Notes,
        CreatedBy: ChildUserID,
        Role: Role,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AddSalesReplyAccountNotes",
        method: "POST",
        data: AccountNoteData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Account note add successfully.", "Add Note ");
          setShowNotePopup(false);
          setIsButtonDisabledNoteSave(false);
          var SearchedVal = document.getElementById("NotesSearch").value;
          setSearch(SearchedVal);
          var AccountNoteData = {
            Page: Page,
            ClientID: ClientID,
            SalesReplyAccountID: AccountID,
            Domain: Domain,
            RowsPerPage: RowsPerPage,
            Sort: true,
            Field: SortField,
            Role: Role,
            SortBy: SortedBy,
            Search: SearchedVal,
            Type: "User",
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/accounts/GetSalesReplyAccountNotes",
            method: "POST",
            data: AccountNoteData,
          }).then((responsenote) => {
            setData(responsenote.data?.PageData);
            setRows(responsenote.data?.PageData);
            setRlen(responsenote.data?.TotalCount);
            setFlen(responsenote.data?.TotalCount);
            setCountPage(responsenote.data?.PageCount);
          });
        } else {
          toast.error(res.data.Message);
          setIsButtonDisabledNoteSave(false);
        }
      });
    } else {
      setIsButtonDisabledNoteSave(false);
      //document.getElementById("submitSave").disabled = false;
    }
  };

  const CheckExistAccountTitle = async (Title, PageType) => {
    var str_in = {
      ClientID: ClientID,
      Title: Title,
      Role: Role,
      Type: PageType,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyAccountExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
          let Errors = {};
          setTitleAvailableNotes(resdata.data.Data[0].Title);
          Errors["Notes22"] = "";
          setErrors(Errors);
          return true;

      } else {
        return false;
      }
    }
  };

  //form validation
  const fromValidationNotes = async () => {
    let formIsValid = true;
    let Errors = {};
    var Title22 = document.getElementById("Title22").value.trim();
    var Notes22 = document.getElementById("Notes22").value.trim();
    var IsExist = await CheckExistAccountTitle(Title22, "Account");
    if (Title22 == "") {
      formIsValid = false;
      Errors["Title22"] = "Please enter title";
    }
    if (Notes22 == "") {
      formIsValid = false;
      Errors["Notes22"] = "Please enter notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    setErrors(Errors);

    return formIsValid;
  };

  const fromValidationNote = async () => {
    debugger
    let formIsValid = true;

    let Errors1 = {};

    var Title1 = document.getElementById("Title1").value.trim();
    var Notes = document.getElementById("Notes").value.trim();
    var IsExist = await CheckExistAccountTitle1(Title1, PageType);
    if (Title1 == "") {
      formIsValid = false;
      Errors1["Title22"] = "Please Enter Title";
    }
    if (Notes == "") {
      formIsValid = false;
      Errors1["Notes"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    setErrors(Errors1);
    // this.setState({ Errors1: Errors1 });

    return formIsValid;
  };


  const handleChangeNotes = (e, field) => {
    console.log(e);
    console.log(field);
    const { value } = e.target;
    const updatedFields = { ...Fields, [field]: value };

    setFields(updatedFields);

    if (updatedFields.Title !== "") {
      // Assuming Errors and TitleAvailableNotes are state variables
      setErrors((prevErrors) => ({ ...prevErrors, Title: null }));
      setTitleAvailableNotes(null);
      CheckExistAccountTitle1(value, PageType);
    } else {
      setTitleAvailableNotes(null);
      setErrors((prevErrors) => ({ ...prevErrors, Title: null }));
    }
  };

  const CheckExistAccountTitle1 = async (Title, PageT) => {
    debugger
    var str_in = {
      ClientID: ClientID,
      Title: Title,
      Role: Role,
      Type: PageT,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyAccountExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (OldTitleUpdate?.toLowerCase() == Title?.toLowerCase()) {
          return false;
        } else {
          let Errors1 = {};
          setTitleAvailableNotes(resdata.data.Data[0].Title);
          Errors1["Title1"] = "";
          setErrors(Errors1);
          return true;
        }
      } else {
        return false;
      }
    }else{
      toast.error(resdata.data.Message)
    }
  };
  // find id by record contact edit
  const AccountNoteEditBtn = (id, PageName) => {
      setNotesUpdatModel(true)
    setPageType(PageName);
    setids(id);

    var AccountNoteEdit = {
      _id: id,
      Role: Role,
      Type: PageName,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesreplyStatusNotesByID",
      method: "POST",
      data: AccountNoteEdit,
    }).then((res) => {
      if(res.data.StatusMessage === "SUCCESS"){
        setOldTitleUpdate(res.data.objData[0]?.Title);
        //   this.setState({ OldTitleUpdate: res.data[0]?.Title });
        document.getElementById("Title1").value = res.data.objData[0]?.Title;
        document.getElementById("Notes").value = res.data.objData[0]?.Note;
      }else{
        toast.error(res.data.Message)
      }
     
    });
  };

  //  contact note update
  const AccountNoteUpdate = async (e) => {
    setIsButtonDisabledNoteUpdate(true);
    var FinalValidation = await fromValidationNote();
    if (FinalValidation == true) {
      var Title = document.getElementById("Title1").value;
      var Notes = document.getElementById("Notes").value;

      var AccountNoteUpdateData = {
        _id: ids,
        Title: Title,
        Note: Notes,
        Pagetype: PageType,
        Role: Role,
        LastUpdatedBy: ChildUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyAccountUpdate",
        method: "POST",
        data: AccountNoteUpdateData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Note Status update successfully.",
            "Update Account Status"
          );
          var SearchedVal = document.getElementById("NotesSearch").value;
          setSearch(SearchedVal);
          var AccountNoteData = {
            Page: Page,
            ClientID: ClientID,
            SalesReplyAccountID: AccountID,
            Domain: Domain,
            RowsPerPage: RowsPerPage,
            Sort: true,
            Field: SortField,
            Role: Role,
            SortBy: SortedBy,
            Search: SearchedVal,
            Type: "User",
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/accounts/GetSalesReplyAccountNotes",
            method: "POST",
            data: AccountNoteData,
          }).then((responsenote) => {
            setData(responsenote.data?.PageData);
            setRows(responsenote.data?.PageData);
            setRlen(responsenote.data?.TotalCount);
            setFlen(responsenote.data?.TotalCount);
            setCountPage(responsenote.data?.PageCount);
            setNotesUpdatModel(false)
          });
        } else {
          toast.error(res.data.Message);
          setIsButtonDisabledNoteUpdate(true);
        }
      });
    } else {
      setIsButtonDisabledNoteUpdate(true);
      // document.getElementById("submitupdate").disabled = false;
    }
  };

  //delete contact note id
  const ProspectNoteDelete = (id, PageName) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var PNoteDelete = {
          _id: id,
          Type: PageName,
          LastUpdatedDt: new Date(),
          Role: Role,
          LastUpdatedBy: ChildUserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/DeleteSalesReplyAccount",
          method: "POST",
          data: PNoteDelete,
        }).then((res) => {
          if (res.data.StatusMessage === "SUCCESS") {
           
              Swal.fire("Deleted!", "Note deleted successfully.", "success");
              //
              var SearchedVal = document.getElementById("NotesSearch").value;
              setSearch(SearchedVal);
              var AccountNoteData = {
                Page: Page,
                ClientID: ClientID,
                SalesReplyAccountID: AccountID,
                Domain: Domain,
                RowsPerPage: RowsPerPage,
                Sort: true,
                Field: SortField,
                Role: Role,
                SortBy: SortedBy,
                Search: SearchedVal,
                Type: "User",
              };
              Axios({
                url:
                  CommonConstants.MOL_APIURL +
                  "/accounts/GetSalesReplyAccountNotes",
                method: "POST",
                data: AccountNoteData,
              }).then((responsenote) => {
                if(responsenote.data.StatusMessage === "SUCCESS"){
                  setData(responsenote.data?.PageData);
                  setRows(responsenote.data?.PageData);
                  setRlen(responsenote.data?.TotalCount);
                  setFlen(responsenote.data?.TotalCount);
                  setCountPage(responsenote.data?.PageCount);
                }else{
                  toast.error(responsenote.data.Message)
                }
                
              });
           
          }else{
            toast.error(res.data.Message)
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //close popup
  const ClosePopUp = () => {
    setShowNotePopup(false);
    setErrors({});
    setTitleAvailableNotes(null);
    
  };
    //close popup
    const CloseEditPopUp = () => {
      setNotesUpdatModel(false);
      setErrors({});
      setTitleAvailableNotes(null);
      
    };

  return (
    <>
      <div className="row">
        <div className="col">
          <h3 className="xs-headertitle py-3 mb-0">Notes</h3>
        </div>
        <div className="col py-4 text-right">
          <button
            onClick={() => {
              setShowNotePopup(true);
            }}
            className="btn btngroup m-btn "
          >
            <i class="la la-plus la-icon-font-size-13"></i>
            <span className="Mobile_button">Add</span>
          </button>
          <Popup open={ShowNotePopup}>
            <div>
              <div className="modal-black"></div>
              <div className="filterPopup largerPopup">
                <div className="paddingboxTerms">
                  <div className="modal-header py-3 px-3">
                    <h4 className="mb-0">Note</h4>
                  </div>
                  <div className="modal-body p-5">
                    <div class="row mb-3">
                      <label class="col-lg-2 col-form-label text-left">
                        Title
                      </label>
                      <div class="col-lg-10">
                        <input
                          class="form-control m-input"
                          data-val="true"
                          data-val-remote="Title already exist."
                          data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                          data-val-required="Please enter title"
                          id="Title22"
                          name="TitleNote"
                          placeholder="Enter title"
                          onBlur={(e) => handleChangeNotes(e, "Title22")}
                          type="text"
                        />
                        <span style={{ color: "red" }}>
                          {Errors["Title22"]}
                        </span>
                        {TitleAvailableNotes && (
                          <span style={{ color: "red" }}>
                            Title already exist.
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-lg-2 col-form-label text-left">
                        Note
                      </label>
                      <div class="col-lg-10">
                        <textarea
                          class="form-control m-input minheight390"
                          data-val="true"
                          data-val-remote="Title already exist."
                          data-val-required="Please enter title"
                          id="Notes22"
                          name="NotesPNote"
                          placeholder="Enter note"
                          type="text"
                        >
                          {" "}
                        </textarea>
                        <span style={{ color: "red" }}>
                          {Errors["Notes22"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="modal-content bordernone text-right">
                    <div class="row">
                      <div class="col-lg-12 pull-right">
                        <a
                          id="backtolist"
                          class="btn btn-secondary mr-2"
                          onClick={ClosePopUp}
                        >
                          cancel
                        </a>
                        <button
                          id="submitSave"
                          class="btn btn-primary btn-lightgreen mr-1"
                          value="Save"
                          onClick={AccountNoteSave}
                          disabled={IsButtonDisabledNoteSave}
                        >
                          <i class="la la-save"></i> Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
          <Popup
                          open={NotesUpdatModel}
                        >
                            <div>
                              <div className="modal-black"></div>
                              <div className="filterPopup largerPopup">
                                <div className="paddingboxTerms">
                                  <div className="modal-header py-3 px-3">
                                    <h4 className="mb-0">Note</h4>
                                  </div>
                                  <div className="modal-body p-5">
                                    <div class="row mb-3">
                                      <label class="col-lg-2 col-form-label text-left">
                                        Title
                                      </label>
                                      <div class="col-lg-10">
                                        <input
                                          class="form-control m-input"
                                          data-val="true"
                                          data-val-remote="Title already exist."
                                          data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                          data-val-required="Please enter title"
                                          id="Title1"
                                          name="TitleEditNote"
                                          placeholder="Enter title"
                                          type="text"
                                          onBlur={(e) =>
                                            handleChangeNotes(e, "Title1")
                                          }
                                        />
                                        <span style={{ color: "red" }}>
                                          {Errors["Title22"]}
                                        </span>
                                        {TitleAvailableNotes && (
                                          <span style={{ color: "red" }}>
                                            Title already exist.
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div class="row">
                                      <label class="col-lg-2 col-form-label text-left">
                                        Note
                                      </label>
                                      <div class="col-lg-10">
                                        <textarea
                                          class="form-control m-input minheight390"
                                          data-val="true"
                                          data-val-remote="Title already exist."
                                          data-val-required="Please enter title"
                                          id="Notes"
                                          name="NotesEditPNotes"
                                          placeholder="Enter note"
                                          type="text"
                                        >
                                          {" "}
                                        </textarea>
                                        <span style={{ color: "red" }}>
                                          {Errors["Notes"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-content bordernone text-right">
                                    <div class="row">
                                      <div class="col-lg-12 pull-right">
                                        <a
                                          id="backtolist"
                                          class="btn btn-secondary mr-2"
                                          onClick={CloseEditPopUp}
                                        >
                                          cancel
                                        </a>
                                        <a
                                          id="submitupdate"
                                          class="btn btn-primary btn-lightgreen mr-1"
                                          href="javascript:void(0);"
                                          value="Save"
                                          disabled={IsButtonDisabledNoteUpdate}
                                          onClick={AccountNoteUpdate}
                                        >
                                          <i class="la la-save"></i> Save
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          
            </Popup>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              aria-controls="tbl_meeting"
              onChange={ChangeRowSelected}
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="NotesSearch"
              onKeyPress={(event) => RequestSearch(event)}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => {Sorting("Title");}}>
                    Title
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Title"} />
                </TableCell>
                <TableCell onClick={() => {Sorting("Note");}}>
                    Notes
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Note"} />
                </TableCell>
                <TableCell onClick={() => {Sorting("PageName");}}>
                    Type
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"PageName"} />
                </TableCell>
                <TableCell onClick={() => {Sorting("CreatedDate");}}>
                    Created Date
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"CreatedDate"} />
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row?.Title}</TableCell>
                    <TableCell>{row?.Note}</TableCell>
                    <TableCell>{row?.PageName}</TableCell>

                    <TableCell>
                      {moment(new Date(row?.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          AccountNoteEditBtn(row._id, row.PageName);
                        }}
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                        
                      </a>
                      <a
                        className="btn-eyesicon"
                        onClick={() => {
                          ProspectNoteDelete(row._id, row.PageName);
                        }}
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
     
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      
    </>
  );
}

export default Notes;
