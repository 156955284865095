import React, { useEffect, useState, useRef } from 'react';
import Axios from "axios";
import moment from 'moment';
const momentTimeZone = require('moment-timezone');

import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants, tzCodeMap } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Device } from '@twilio/voice-sdk';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Popup from 'reactjs-popup';
import CallDialAddBookedMeeting from './CallDialAddBookedMeeting.js';
import CallDialAddPotentialMeetingPage from './CallDialAddPotentialMeeting.js';
import Popover from '@mui/material/Popover';
import SendCustomEmailModel from './SendCustomEmailModel.js';

const GetTimeFromTZCode = (tzCode) => {
    const timezoneInfo = tzCodeMap[tzCode ?? "0"];
     // Display current date and time based on TZCode
     const currentTime = momentTimeZone.utc().tz(timezoneInfo.timezoneIdentifier).format('hh:mm A');
     const currentDate = momentTimeZone.utc().tz(timezoneInfo.timezoneIdentifier).format('MM/DD/YYYY');
     return {
        timezoneIdentifier: timezoneInfo.timezoneIdentifier,
        abbreviation: timezoneInfo.abbreviation,
        currentTime: currentTime,
        currentDate:currentDate
     }
}


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

toast.configure();

const MicIcon = <svg width="15" height="15" viewBox="0 0 0.45 0.45" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M0.244 0.374v0.02H0.3v0.037H0.15v-0.037h0.056v-0.02A0.15 0.15 0 0 1 0.075 0.225v-0.037h0.037v0.037a0.112 0.112 0 1 0 0.225 0v-0.037h0.037v0.037a0.15 0.15 0 0 1 -0.131 0.149M0.225 0.019a0.075 0.075 0 0 1 0.075 0.075V0.225a0.075 0.075 0 1 1 -0.15 0V0.094a0.075 0.075 0 0 1 0.075 -0.075m0 0.037a0.037 0.037 0 0 0 -0.037 0.037V0.225a0.037 0.037 0 1 0 0.075 0V0.094a0.037 0.037 0 0 0 -0.037 -0.037" /></svg>;

const MuteIcon = <svg width="15" height="15" viewBox="1.803 1.2 14.394 16.8" class="icon" xmlns="http://www.w3.org/2000/svg"><path d="m7.728 11.102-.852.852A3.6 3.6 0 0 1 6 9.6V4.8a3.6 3.6 0 1 1 7.2 0v.832l-1.2 1.2V4.8a2.4 2.4 0 1 0-4.8 0v4.8c0 .569.198 1.092.528 1.502m.974.724A2.4 2.4 0 0 0 12 9.6V8.528l1.2-1.2V9.6a3.6 3.6 0 0 1-5.394 3.122zm-2.798 2.798.865-.864A4.2 4.2 0 0 0 9 14.4h1.2a4.2 4.2 0 0 0 4.2-4.2v-.6a.6.6 0 1 1 1.2 0v.6a5.4 5.4 0 0 1-5.4 5.4v1.2h1.2a.6.6 0 1 1 0 1.2H7.8a.6.6 0 1 1 0-1.2H9v-1.2c-1.152 0-2.22-.36-3.096-.976m-.902-.794A5.38 5.38 0 0 1 3.6 10.2v-.6a.6.6 0 0 1 1.2 0v.6c0 1.066.397 2.04 1.051 2.78z" /><path d="M2.826 16.108a.6.6 0 0 1-.852-.845l13.2-13.285a.6.6 0 0 1 .852.845z" /></svg>;

const BackIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 0.975 0.975"><path d="M.911.431H.289C.272.431.265.41.276.399l.18-.18a.027.027 0 0 0 0-.039L.415.139a.027.027 0 0 0-.039 0l-.329.33a.027.027 0 0 0 0 .039l.328.328a.027.027 0 0 0 .039 0L.453.797a.027.027 0 0 0 0-.039l-.18-.18C.262.565.269.544.286.544h.623C.924.544.937.533.937.518V.462c0-.015-.011-.03-.026-.03" /></svg>;

const BoxIcon = <svg width="15" height="15" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M96 448q-14 0-23-9t-9-23V96q0-14 9-23t23-9h320q14 0 23 9t9 23v320q0 14-9 23t-23 9zm304-48V112H112v288z" /></svg>

const TransferIcon = <svg width="15" height="15" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 16.5H6m-.5-8H19M16 13l3.5 3.5L16 20m-7-8L5.5 8.5 9 5" stroke="#121923" stroke-width="1.2" /></svg>

export default function CallDailSetting({ windowObject, DialSessionData, checkeddata, LoggedUserDetails, PhoneArr, ReloadContactPage, DialingSetList, DefaultDialingSetID, PhoneScriptDetails, LiveAnswerDispositionSet, DefaultLiveAnswerDispositionID, DefaultPhoneScript}) {

    // States for managing timezone and current time display
    const [TimeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [CurrentTime, setCurrentTime] = useState(
        new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
    );

    // Complete time details
    const [TimeDetails, SetTimeDetails] = useState({
        timezoneIdentifier:"",
        abbreviation:"",
        currentTime:"",
        currentDate:""
    })

    const [DialingSetListing, SetDialingSetListing] = useState(DialingSetList || [])

    // Client, user, and cold caller data states
    const [clientID, SetClientID] = React.useState("");
    const [userID, SetUserID] = React.useState("");
    const [coldCallerUserID, SetColdCallerUserID] = React.useState("");

    // Contact list and record-related states
    const [ContactList, SetContactList] = React.useState([]);
    const [TotalRecord, SetTotalRecord] = React.useState(0);
    const [StartCallingCount, SetStartCallingCount] = React.useState(1);
    const [VoicemailCount, SetVoicemailCount] = useState(0);
    const [IsAnswerBy, SetIsAnswerBy] = useState(0);
    const [EmailCount, SetEmailCount] = useState(0);
    const [TalksCount, SetTalksCount] = useState(0);
    const [ToNumber, setToNumber] = useState("")

    // Step management states
    const [Step1, setStep1] = useState(true);
    const [Step2, setStep2] = useState(false);
    const [Step3, setStep3] = useState(false);
    const [Step4, setStep4] = useState(false);
    const [Step5, setStep5] = useState(false);
    const [LastStep, setLastStep] = useState(false);
    const [IsMiceAccess, setIsMiceAccess] = useState(false);

    // Button states for dialing, recording, and other actions
    const [StartDialignbtn, setStartDialignbtn] = useState(false);
    const [StartRecordingbtn, setStartRecordingbtn] = useState(false);
    const [Recordingbtn, setRecordingbtn] = useState(false);
    const [PauseDialingbtn, setPauseDialingbtn] = useState(false);
    const [EndDialingbtn, setEndDialing] = useState(false);
    const [CallingInfoPanel, setCallingInfoPanel] = useState(false);
    const [LiveAnswerBtn, setLiveAnswerBtn] = useState(false);
    const [IsItComeFromBackButton, SetIsItComeFromBackButton] = useState(false)
    const [AllStatusBtnDisabled, SetAllStatusBtnDisabled] = useState(true);

    // Phone number-related states
    const [DirectPhone, setDirectPhone] = useState("");
    const [MobilePhone, setMobilePhone] = useState("");
    const [CompanyPhone, setCompanyPhone] = useState("");
    const [OtherPhone1, setOtherPhone1] = useState("");
    const [OtherPhone2, setOtherPhone2] = useState("");

    // Contact details
    const [ID, setID] = useState("");
    const [CallNotes, setCallNotes] = useState("");
    const [selectedTab, setSelectedTab] = useState('one');

    // Email account Details
    const [EmailAccountDetails] = useState({
        AliasName: DialSessionData?.AliasName?.trim(),
        AliasEmail: DialSessionData?.AliasEmail,
        AliasTitle: DialSessionData?.AliasTitle
    })

    // Form details state object
    const [FormDetails, SetFormDetails] = useState({
        FirstName: "",
        ID: "",
        LastName: "",
        Email: "",
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Zip: "",
        Country: "",
        Title: "",
        Company: "",
        Website: "",
        Industry: "",
        LinkedIn: "",
        SalesRep: "",
        DirectPhoneExt: "",
        HoneyScore: "",
        DirectPhone: "",
        MobilePhone: "",
        CompanyPhone: "",
        OtherPhone1: "",
        OtherPhone2: "",
        coldcallers: {}
    });

    // Twilio device and session management
    const [IsStepOpen2, setIsStepOpen2] = useState(true);
    const [CallerID, SetCallerID] = useState("");
    const [token, SetToken] = useState("");
    const [device, setDevice] = useState(null);
    const [DeviceReady, setDeviceReady] = useState(false);
    const [Called, setCalled] = useState(null);
    const [IsMuted, SetIsMuted] = useState(false); // State for mute and unmute call

    // Loader and indicator states for different phone number types
    const [IsLoderDirectPhone, setIsLoderDirectPhone] = useState(false);
    const [IsLoderMobilePhone, setIsLoderMobilePhone] = useState(false);
    const [IsLoderCompanyPhone, setIsLoderCompanyPhone] = useState(false);
    const [IsLoderOtherPhone1, setIsLoderOtherPhone1] = useState(false);
    const [IsLoderOtherPhone2, setIsLoderOtherPhone2] = useState(false);

    // Green tick indicators for phone number validation
    const [IsGreenTickDirectPhone, setIsGreenTickDirectPhone] = useState(false);
    const [IsGreenTickLoderMobilePhone, setIsGreenTickMobilePhone] = useState(false);
    const [IsGreenTickLoderCompanyPhone, setIsGreenTickCompanyPhone] = useState(false);
    const [IsGreenTickLoderOtherPhone1, setIsGreenTickOtherPhone1] = useState(false);
    const [IsGreenTickLoderOtherPhone2, setIsGreenTickOtherPhone2] = useState(false);

    // All DNC Phone numbers
    const [IsGlobalDNCDirectPhone, setIsGlobalDNCDirectPhone] = useState(false);
    const [IsGlobalDNCMobilePhone, setIsGlobalDNCMobilePhone] = useState(false);
    const [IsGlobalDNCCompanyPhone, setIsGlobalDNCCompanyPhone] = useState(false);
    const [IsGlobalDNCOtherPhone1, setIsGlobalDNCOtherPhone1] = useState(false);
    const [IsGlobalDNCOtherPhone2, setIsGlobalDNCOtherPhone2] = useState(false);

    // All Do not Call today numbers
    const [DirectPhoneDoNotCallToday, setDirectPhoneDoNotCallToday] = useState("");
    const [MobilePhoneDoNotCallToday, setMobilePhoneDoNotCallToday] = useState("");
    const [CompanyPhoneDoNotCallToday, setCompanyPhoneDoNotCallToday] = useState("");
    const [OtherPhone1DoNotCallToday, setOtherPhone1DoNotCallToday] = useState("");
    const [OtherPhone2DoNotCallToday, setOtherPhone2DoNotCallToday] = useState("");

    // IsBlacklist
    const [IsDirectPhoneBlacklist, setIsDirectPhoneBlacklist] = useState(false);
    const [IsMobilePhoneBlacklist, setIsMobilePhoneBlacklist] = useState(false);
    const [IsCompanyPhoneBlacklist, setIsCompanyPhoneBlacklist] = useState(false);
    const [IsOtherPhone1Blacklist, setIsOtherPhone1Blacklist] = useState(false);
    const [IsOtherPhone2Blacklist, setIsOtherPhone2Blacklist] = useState(false);

    // TimeZone area Code
    const [MobilePhoneTZCode, SetMobilePhoneTZCode] = useState("0");
    const [DirectPhoneTZCode, SetDirectPhoneTZCode] = useState("0");
    const [CompanyPhoneTZCode, SetCompanyPhoneTZCode] = useState("0");
    const [OtherPhone1TZCode, SetOtherPhone1TZCode] = useState("0");
    const [OtherPhone2TZCode, SetOtherPhone2TZCode] = useState("0");

    // Fetching Current call phone type [MobilePhone, CompanyPhone, DirectPhone, OtherPhone1, OtherPhone2]
    const [CurrentPhoneType, SetCurrentPhoneType] = useState(null)

    const [CurrentPhoneTZCode, SetCurrentPhoneTZCode] = useState("0")


    const [IsAllDNC, setIsAllDNC] = useState(false)


    // Contact handling states
    const [IsContactLoopBreak, setIsContactLoopBreak] = useState(false);
    const IsContactLoopBreakRef = useRef(IsContactLoopBreak);
    const [IsDialcolorbtn, setIsDialcolorbtn] = useState(false);
    const [IsCallnextcontact, setIsCallnextcontact] = useState(false);
    const [IsCallcontact, setIsCallcontact] = useState(false);
    const contactIdRef = useRef(null);
    const [visibleDivs, setVisibleDivs] = useState({});
    const [CallState, SetCallState] = useState(null)

    // Call-related states
    const [TotalCalls, SetTotalCalls] = useState(0);
    const [CallingNumbers, SetCallingNumbers] = useState([]);
    const [CurrentPhoneID, SetCurrentPhoneID] = useState(null);
    const [DialContactName, SetDialContactName] = useState(null)

    const [ProspectDetails] = useState({
        ColdCallerUserID: LoggedUserDetails?.ColdCallerUserID,
        ClientID: LoggedUserDetails?.ClientID,
        UserID: LoggedUserDetails?.UserID,
        ColdCallersName: LoggedUserDetails?.ColdCallersName
    });
    const [AddNotes, SetAddNotes] = useState("");
    const [CallSid, SetCallSid] = useState("");
    const CallSidRef = useRef(CallSid);
    const [DialingSetID, SetDialingSetID] = useState(DefaultDialingSetID);
    const [PhoneScriptID, SetPhoneScriptID] = useState(DefaultPhoneScript);
    const [DialingStatusOrder, SetDialingStatusOrder] = useState([]);
    const [LiveAnswerDispositionSetID, SetLiveAnswerDispositionSetID] = useState(DefaultLiveAnswerDispositionID);
    const [LiveAnswerStatusOrder, SetLiveAnswerStatusOrder] = useState([]);
    const [PhoneScriptData, SetPhoneScriptData] = useState([]);
    const [PhoneScriptHtml, SetPhoneScriptHtml] = useState("");
    const [DurationsInSec, SetDurationsInSec] = useState(0);
    const [startTime, setStartTime] = useState(null); // Start time of the call
    const [elapsedTime, setElapsedTime] = useState(0); // Elapsed time in seconds
    const [isPaused, setIsPaused] = useState(false); // Pause status
    const [CallSettled, setCallSettled] = useState(false);
    const [IsCallActive, SetIsCallActive] = useState(false)
    const [IsLiveAnswer, SetIsLiveAnswer] = useState(false);
    const [IsEndButton, SetIsEndButton] = useState(false);
    const [OpenPotentailMeetingModel, SetOpenPotentailMeetingModel] = useState(false);
    const [OpenBookMeetingModel, SetOpenBookMeetingModel] = useState(false);
    const [errors, SetErrors] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [NextActionName, SetNextActionName] = React.useState("")
    const [ContactIndex, SetContactIndex] = React.useState("")
    const [ShowDialpad, SetShowDialpad] = React.useState(false);
    const [ConFerenceName, setConFerenceName] = useState("");
    const ConFerenceNameRef = useRef(ConFerenceName);
    const [VoicemailDuratin, SetVoicemailDuratin] = useState(0);
    const VoicemailDuratinRef = useRef(VoicemailDuratin);

    const [IsCallDisconnect, setIsCallDisconnect] = useState(false);
    const IsCallDisconnectRef = useRef(IsCallDisconnect);

    const [StatusAction, SetStatusAction] = useState('');
    const StatusActionRef = useRef(StatusAction);
    const [OpenSendCustomEmailModel, SetOpenSendCustomEmailModel] = useState(false)
    const [PageRoleName, SetPageRoleName] = React.useState("")
    const [ClientName, SetClientName] = React.useState("")

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    };


    const currentPhoneIDRef = useRef(null); // Add a reference to hold the current phone ID

    useEffect(() => {
        const interval = setInterval(() => {
        
            // Get timezone and current time using the derived TZCode
            const { timezoneIdentifier, currentTime, currentDate,  abbreviation} = GetTimeFromTZCode(CurrentPhoneTZCode);
            SetTimeDetails({
                timezoneIdentifier:timezoneIdentifier,
                abbreviation:abbreviation,
                currentTime:currentTime,
                currentDate:currentDate
            })
    
            // Update state variables
            setCurrentTime(currentTime);
            setTimeZone(timezoneIdentifier);
        }, 1000); // Update every second
    
    
        // Cleanup the interval on component unmount or when dependencies change
        return () => clearInterval(interval);
    }, [CurrentPhoneType,CurrentPhoneTZCode]);
    
    
    useEffect(() => {
        const handleDocumentClick = () => setVisibleDivs({});
        document.addEventListener('click', handleDocumentClick);

        // Prevent closing when clicking inside specific content divs
        const contentDivs = document.querySelectorAll('.content-div');
        contentDivs.forEach((div) => {
            div.addEventListener('click', (event) => event.stopPropagation());
        });

        // Cleanup on component unmount
        return () => {
            document.removeEventListener('click', handleDocumentClick);
            contentDivs.forEach((div) => {
                div.removeEventListener('click', (event) => event.stopPropagation());
            });
        };
    }, []);

    // Toggle the visibility of divs based on divId
    const toggleDiv = (divId) => {
        setVisibleDivs((prevVisibleDivs) => ({
            ...prevVisibleDivs,
            [divId]: !prevVisibleDivs[divId],
        }));
    };

    // Timer logic
    useEffect(() => {
        let interval = null;

        if (!isPaused && startTime) {
            // Timer is running, set the interval to update every second
            interval = setInterval(() => {
                const currentTime = new Date();
                const timeElapsed = Math.floor((currentTime - startTime) / 1000); // Time in seconds
                setElapsedTime(timeElapsed);
                SetDurationsInSec(timeElapsed); // Update displayed duration
            }, 1000);
        }

        // Clear interval when paused or component unmounted
        return () => clearInterval(interval);
    }, [isPaused, startTime]);

    // Initialize data from DialSessionData safely
    useEffect(() => {
        try {
            SetClientID(DialSessionData.ClientID);
            SetUserID(DialSessionData.UserID);
            SetColdCallerUserID(DialSessionData.ColdCallerUserID);
            SetContactList(DialSessionData.ContactList);
            SetTotalRecord(DialSessionData.ContactList.length);
            SetCallerID(DialSessionData.CallerID);
            SetToken(DialSessionData.token);
            SetPageRoleName(DialSessionData.Role)
            SetClientName(DialSessionData.ClientName)

            // Safely remove a specific contact from the list
            const index = DialSessionData.ContactList.findIndex((obj) => obj.ID === '2');
            if (index !== -1) {
                DialSessionData.ContactList.splice(index, 1);
            }
        } catch (error) {
            console.error('Error initializing session data:', error); // Log errors silently
        }
    }, [DialSessionData]);

    // Initialize Twilio device and manage call events
    useEffect(() => {
        if (!token) return;

        try {
            const newDevice = new Device(token, { debug: true, allowIncomingWhileBusy: true });
            newDevice.register();
            setDevice(newDevice);

            newDevice.on('registered', () => {
                setIsStepOpen2(true);

            });

            newDevice.on('connect', (connection) => {

            });

            newDevice.on('disconnect', () => {

            });

            newDevice.on('error', (error) => {
                windowObject.close();
                console.error('Twilio Device error:', error); // Log Twilio device errors
                toast.error(<div className='toastsize'>Dialer configuration missing. Please contact your administrator to resolve this issue.</div>)
            });

            return () => {
                newDevice.destroy(); // Clean up device on component unmount
                setDevice(null);
            };
        } catch (error) {
            windowObject.close();
            console.error('Error initializing Twilio device:', error); // Handle Twilio initialization errors
        }
    }, [token]);

    // Handle the contact call process safely
    useEffect(() => {
        if (IsCallcontact && device) {
            try {

                ContactCall(device); // Assuming ContactCall initiates the call
                setIsCallcontact(false); // Reset flag after call initiation
            } catch (error) {
                console.error('Error making contact call:', error); // Handle errors in contact call
            }
        }
    }, [IsCallcontact, device]);

    // Effect to track changes in currentPhoneID and update refs accordingly
    useEffect(() => {
        if (CurrentPhoneID) {
            currentPhoneIDRef.current = CurrentPhoneID; // Sync ref with the state
        }
    }, [CurrentPhoneID]);

    // useEffect(() => {
    //     // Generate the token when the component mounts
    //    // GenrateRefreshTokenAfterProspect();

    //     // Set an interval to regenerate the token every 30 minutes
    //     const tokenInterval = setInterval(() => {
    //        // GenrateRefreshTokenAfterProspect();
    //     }, 30 * 60 * 1000); // 30 minutes in milliseconds

    //     // Cleanup interval on component unmount to avoid memory leaks
    //     return () => clearInterval(tokenInterval);
    // }, [LoggedUserDetails, CallerID]); // Depend on logged-in user and CallerID



    // useEffect(() => {
    //     const ClientID = DialSessionData.ClientID;
    //     // url=url+ ClientID
    //     var url = CommonConstants.NotificationBackendUrl + ClientID + "/" + DialSessionData.ColdCallerUserID
    //     const eventSource = new EventSource(url);

    //     eventSource.onmessage = (event) => {
    //         const data = JSON.parse(event.data);
    //         if (data.StatusMessage == "in-progress") {
    //             SetTalksCount(TalksCount + 1);

    //             SetCallState('Accepted')
    //         }
    //     };

    //     eventSource.onerror = (error) => {
    //         eventSource.close();
    //     };

    //     return () => {
    //         eventSource.close();
    //     };
    // }, [TalksCount]);

    //Genrate refresh token after every prospect dial
    const GenrateRefreshTokenAfterProspect = async () => {
        if (CallerID && CallerID != "") {
            try {
                // Prepare the request body for the API call
                const RequestBody = {
                    ColdCallerUserID: LoggedUserDetails?.ColdCallerUserID,
                    ClientID: LoggedUserDetails?.ClientID,
                    UserID: LoggedUserDetails?.UserID,
                    PhoneNumber: CallerID, // Use the updated CallerID from the state
                    Role: "ColdCaller"
                };

                // API call to change CallerID and get a new token
                const Result = await Axios.post(CommonConstants.MOL_APIURL + "/twiliocalling/GetAccessTokenOnChangeCallerID", RequestBody);
                // Check if the API call was successful
                if (Result.data.StatusMessage === "SUCCESS") {
                    // Set the new token in the state
                    SetToken(Result.data.token);
                }
            } catch (err) {
                // Log any errors that occur during the API call
                console.error('Error changing Caller ID:', err);
            }
        }

    }



    // ================== Dial Session Setttings (STEP - 1) - START =======================


    //Method to handle phone handler
    const PhoneScriptHandler = (e) => {
        const value = e.target?.value?.trim() || ''; // Get trimmed value or an empty string if undefined

        if (!value) {
            let error = { ...errors };
            error['phonescriptID'] = "Please select phone script"; // Set an appropriate error message
            SetErrors(error);
        } else {
            // Clear the error if there's a valid input
            let error = { ...errors };
            error['phonescriptID'] = "";
            SetErrors(error);
        }

        SetPhoneScriptID(e.target?.value); // Set the original value, with or without whitespace
    };

    // Method to handle Caller ID input change
    const ChangeCallerID = (e) => {
        SetCallerID(e.target.value); // Update the CallerID state with the new value
    };

    //Method to Handler Dialing Set  
    const DialingSetHandler = async (e) => {
        SetDialingSetID(e.target.value)
    }

    //Method to handle Live answer disposition
    const LiveAnswerDispositionHandler = (e) => {
        SetLiveAnswerDispositionSetID(e.target.value)
    }

    // Method to getting Dialing set order details
    const GettingDialingSetOrderDetails = async (ID, Role) => {
        // Prepare the request body for the API call
        const RequestBody = {
            ID,
            Role
        };

        try {
            // API call to change CallerID and get a new token
            const ApiResponses = await Axios.post(
                CommonConstants.MOL_APIURL + "/twiliocalling/GetStatusOrderDetails",
                RequestBody
            );

            // Check if the API call was successful
            if (ApiResponses.data.StatusMessage === "SUCCESS") {

                SetDialingStatusOrder(ApiResponses.data?.Data)
            }
        } catch (err) {
            // Log any errors that occur during the API call
            console.error('Error changing Caller ID:', err);
        }
    }

    // Method to getting live answer order details
    const GettingLiveAnswerOrderDetails = async (ID, Role) => {
        // Prepare the request body for the API call
        const RequestBody = {
            ID,
            Role
        };

        try {
            const ApiResponses = await Axios.post(
                CommonConstants.MOL_APIURL + "/twiliocalling/GetLiveAnswerStatusOrderDetails",
                RequestBody
            );

            // Check if the API call was successful
            if (ApiResponses.data.StatusMessage === "SUCCESS") {

                SetLiveAnswerStatusOrder(ApiResponses.data?.Data)
            }
        } catch (err) {
            // Log any errors that occur during the API call
            console.error('Error changing live answer order details:', err);
        }
    }

    // Method to getting phone script details
    const GettingPhoneScriptDetails = async (ID, Role) => {
        // Prepare the request body for the API call
        const RequestBody = { ID, Role };

        try {
            const ApiResponses = await Axios.post(
                CommonConstants.MOL_APIURL + "/twiliocalling/GetPhoneScript",
                RequestBody
            );

            // Check if the API call was successful
            if (ApiResponses.data.StatusMessage === "SUCCESS") {
                SetPhoneScriptData(ApiResponses.data?.Data)
            }
        } catch (err) {
            // Log any errors that occur during the API call
            console.error('Error changing live answer order details:', err);
        }
    }

    // Method to handle the "Continue" button click
    const ContinueBtn = async () => {
        // Prepare the request body for the API call
        if (!PhoneScriptID?.trim()) {
            let error = { ...errors }
            error['phonescriptID'] = "Please select phone script";
            SetErrors(error)

        } else {
            const RequestBody = {
                ColdCallerUserID: LoggedUserDetails?.ColdCallerUserID,
                ClientID: LoggedUserDetails?.ClientID,
                UserID: LoggedUserDetails?.UserID,
                PhoneNumber: CallerID, // Use the updated CallerID from the state
                Role: "ColdCaller"
            };
            if (CallerID && CallerID != "") {
                try {
                    // API call to change CallerID and get a new token
                    const Result = await Axios.post(
                        CommonConstants.MOL_APIURL + "/twiliocalling/GetAccessTokenOnChangeCallerID",
                        RequestBody
                    );

                    // Check if the API call was successful
                    if (Result.data.StatusMessage === "SUCCESS") {
                        await GettingDialingSetOrderDetails(DialingSetID, "ColdCaller")
                        await GettingLiveAnswerOrderDetails(LiveAnswerDispositionSetID, "ColdCaller")
                        if (PhoneScriptID != "") {
                            await GettingPhoneScriptDetails(PhoneScriptID, "ColdCaller")
                        }

                        // Set the new token in the state
                        SetToken(Result.data.token);

                        // Update the UI steps and enable the calling panel
                        setStep1(false);
                        setCallingInfoPanel(true);
                        const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
                        if (permissionStatus.state === 'granted') {
                            setDeviceReady(true); // Mark the device as ready for calls
                            setStep2(false); // Move to the next step
                            setStartDialignbtn(true); // Enable the start dialing button
                            setStep3(true); // Proceed to step 3
                            setIsMiceAccess(true); // Set microphone access state
                        } else {
                            setStep2(true);
                        }

                    }
                } catch (err) {
                    // Log any errors that occur during the API call
                    console.error('Error changing Caller ID:', err);
                }
            }
        }


    };

    // ================== Dial Session Setttings (STEP - 1) - END =======================


    // Function to request microphone access in the browser
    const handleMicPermission = () => {
        // Request microphone access using the MediaDevices API
        windowObject.navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {


                // If the step 2 is open, proceed to the next steps
                if (IsStepOpen2) {
                    setDeviceReady(true); // Mark the device as ready for calls
                    setStep2(false); // Move to the next step
                    setStartDialignbtn(true); // Enable the start dialing button
                    setStep3(true); // Proceed to step 3
                    setIsMiceAccess(true); // Set microphone access state
                }
            })
            .catch((error) => {
                // Handle errors when microphone access is denied
                console.error('Microphone access denied in the new window:', error);
                setIsMiceAccess(false); // Set microphone access state to false
            });
    };

    // Function to handle the "Start Dialing" button click
    const handleStartDialignBtn = () => {
        // Check if the device is ready and available for making calls
        if (!DeviceReady || !device) {
            console.error('Device is not ready for calls');
            return; // Exit the function if the device is not ready
        }

        // Update the UI steps and button states
        setStep3(false); // Move past step 3
        setStep4(true); // Proceed to step 4
        SetShowDialpad(true)
        setStartDialignbtn(false); // Disable the start dialing button
        setEndDialing(true); // Enable the end dialing button
        setPauseDialingbtn(true); // Enable the pause dialing button
        handleStartCall();
        // Start the contact call process
        ContactCall(device);
    };

    // Method for Mute and unmute call
    const HandleMuteToggle = () => {
        if (Called) {
            Called.mute(!IsMuted);
            SetIsMuted(!IsMuted);
        }
    };

    //Method for Hold and unHold Call
    const HoldCallButton = async (isHeld) => {
        try {
            const RequestBody = {
                ClientID: LoggedUserDetails.ClientID,
                UserID: LoggedUserDetails.UserID,
                Role: "ColdCaller",
                CallSid: CallSid,
                Hold: isHeld,
                ConFerenceName: ConFerenceNameRef.current
            }
            const ApiResponses = await Axios.post(
                CommonConstants.MOL_APIURL + "/twiliocalling/holdtwiliocall",
                RequestBody
            );


            // // Check if the API call was successful
            // if (ApiResponses.data.StatusMessage === "SUCCESS") {
            //     SetPhoneScriptData(ApiResponses.data?.Data)
            // }
        } catch (err) {

        }
    }

    // Function to initiate contact calls
    async function ContactCall(device) {
        // Check if there are any contacts to call
        if (ContactList.length > 0) {
            for (let i = 0; i < ContactList.length; i++) {
                // Break the loop if the flag is set to true
                if (IsContactLoopBreakRef.current) {
                    break;
                }

                // Reset UI states for dialing
                setIsDialcolorbtn(false);
                setIsGreenTickDirectPhone(false);
                setIsGreenTickMobilePhone(false);
                setIsGreenTickCompanyPhone(false);
                setIsGreenTickOtherPhone1(false);
                setIsGreenTickOtherPhone2(false);

                // Reset DNC PHone number state
                setIsGlobalDNCDirectPhone(false)
                setIsGlobalDNCMobilePhone(false)
                setIsGlobalDNCCompanyPhone(false)
                setIsGlobalDNCOtherPhone1(false)
                setIsGlobalDNCOtherPhone2(false)

                // Reset All Blacklist flags
                setIsDirectPhoneBlacklist(false)
                setIsMobilePhoneBlacklist(false)
                setIsCompanyPhoneBlacklist(false)
                setIsOtherPhone1Blacklist(false)
                setIsOtherPhone2Blacklist(false)


                // Get the current contact item
                let item = ContactList[i];

                let lstPhone = [];
                setID(item._id)
                SetPhoneScriptHtml(item)
                // Local variables for DNC state
                let hasDNCFlag = {
                    directPhone: false,
                    mobilePhone: false,
                    companyPhone: false,
                    otherPhone1: false,
                    otherPhone2: false,
                };



                // Collect phone numbers based on user selections
                setIsDirectPhoneBlacklist(item?.IsDirectPhoneBlacklist);
                setIsMobilePhoneBlacklist(item?.IsMobilePhoneBlacklist);
                setIsCompanyPhoneBlacklist(item?.IsCompanyPhoneBlacklist);
                setIsOtherPhone1Blacklist(item?.IsOtherPhone1Blacklist);
                setIsOtherPhone2Blacklist(item?.IsOtherPhone2Blacklist);
                SetMobilePhoneTZCode(item?.MobilePhoneTZCode)
                SetDirectPhoneTZCode(item?.DirectPhoneTZCode)
                SetCompanyPhoneTZCode(item?.CompanyPhoneTZCode)
                SetOtherPhone1TZCode(item?.OtherPhone1TZCode)
                SetOtherPhone2TZCode(item?.OtherPhone2TZCode)
             
                if (checkeddata.IsDirectPhone && item?.DirectPhone?.trim() &&  (PageRoleName === "BookMeeting" ||  !item?.IsDirectPhoneBlacklist )) {
                    setDirectPhone(item.DirectPhone);
                    lstPhone.push(createPhoneObj("DirectPhone", item.DirectPhone, true, item.IsGlobalDNCDirectPhone, item.Email,item?.DirectPhoneTZCode));
                    hasDNCFlag.directPhone = item.IsGlobalDNCDirectPhone;
                    if (item.IsGlobalDNCDirectPhone) {
                        setIsGlobalDNCDirectPhone(true)
                    }
                    setDirectPhoneDoNotCallToday(item?.DirectPhoneDoNotCallToday)

                } else {
                    setDirectPhone("");
                    setDirectPhoneDoNotCallToday("")
                }

                if (checkeddata.IsMobilePhone && item?.MobilePhone?.trim() &&  (PageRoleName === "BookMeeting" || !item?.IsMobilePhoneBlacklist) ) {
                    setMobilePhone(item.MobilePhone);
                    lstPhone.push(createPhoneObj("MobilePhone", item.MobilePhone, false, item.IsGlobalDNCMobilePhone, item.Email, item?.MobilePhoneTZCode));
                    hasDNCFlag.mobilePhone = item.IsGlobalDNCMobilePhone;
                    if (item.IsGlobalDNCMobilePhone) {
                        setIsGlobalDNCMobilePhone(true)
                    }
                    setMobilePhoneDoNotCallToday(item?.MobilePhoneDoNotCallToday)
                } else {
                    setMobilePhone("");
                    setMobilePhoneDoNotCallToday("");
                }

                if (checkeddata.IsCompanyPhone && item?.CompanyPhone?.trim() && (PageRoleName === "BookMeeting" || !item?.IsCompanyPhoneBlacklist)) {
                    setCompanyPhone(item.CompanyPhone);
                    lstPhone.push(createPhoneObj("CompanyPhone", item.CompanyPhone, false, item.IsGlobalDNCCompanyPhone, item.Email, item?.CompanyPhoneTZCode));
                    hasDNCFlag.companyPhone = item.IsGlobalDNCCompanyPhone;
                    if (item.IsGlobalDNCCompanyPhone) {
                        setIsGlobalDNCCompanyPhone(true)
                    }
                    setCompanyPhoneDoNotCallToday(item?.CompanyPhoneDoNotCallToday)

                } else {
                    setCompanyPhone("");
                    setCompanyPhoneDoNotCallToday("")
                }

                if (checkeddata.IsOtherPhone1 && item?.OtherPhone1?.trim() && !item?.IsOtherPhone1Blacklist) {
                    setOtherPhone1(item.OtherPhone1);
                    lstPhone.push(createPhoneObj("OtherPhone1", item.OtherPhone1, false, item.IsGlobalDNCOtherPhone1, item.Email, item?.OtherPhone1TZCode));
                    hasDNCFlag.otherPhone1 = item.IsGlobalDNCOtherPhone1;
                    if (item.IsGlobalDNCOtherPhone1) {
                        setIsGlobalDNCOtherPhone1(true)
                    }
                    setOtherPhone1DoNotCallToday(item?.OtherPhone1DoNotCallToday)
                } else {
                    setOtherPhone1("");
                    setOtherPhone1DoNotCallToday("")

                }

                if (checkeddata.IsOtherPhone2 && item?.OtherPhone2?.trim() && !item.IsOtherPhone2Blacklist) {
                    setOtherPhone2(item.OtherPhone2);
                    lstPhone.push(createPhoneObj("OtherPhone2", item.OtherPhone2, false, item.IsGlobalDNCOtherPhone2, item.Email, item?.OtherPhone2TZCode));
                    hasDNCFlag.otherPhone2 = item.IsGlobalDNCOtherPhone2;

                    if (item.IsGlobalDNCOtherPhone2) {
                        setIsGlobalDNCOtherPhone2(true)
                    }
                    setOtherPhone2DoNotCallToday(item?.OtherPhone2DoNotCallToday)

                } else {
                    setOtherPhone2("");
                    setOtherPhone2DoNotCallToday("")
                }
                // Check if all phone numbers are on the DNC list

                const isAllDNC = Object.values(hasDNCFlag).every(flag => flag === true);
                if (isAllDNC) {
                    setIsAllDNC(true);
                } else {
                    setIsAllDNC(false);

                }

                if (lstPhone?.length > 0) {
                    // Set the list of calling numbers
                    SetCallingNumbers(lstPhone);

                    // Prepare detailed contact information
                    const DetailsObj = createDetailsObj(item);
                    SetFormDetails(DetailsObj);


                    // Process call notes and prepare for displaying
                    const callNotes = processCallNotes(item?.CallNotes);
                    setCallNotes(callNotes);

                    // Dial each collected phone number

                    for (let j = 0; j < lstPhone.length; j++) {
                        if (lstPhone[j]?.IsDNC == false) {
                            if (IsContactLoopBreakRef.current) {
                                break;
                            }

                            SetDialContactName(lstPhone[j]?.Name)
                            const currentPhone = lstPhone[j];
                            SetCurrentPhoneID(currentPhone.PhoneID);
                            SetCurrentPhoneType(currentPhone.Name)
                            await DialbtnDisable(currentPhone.Name);
                            if (isPaused == false) {

                                await StartDial(device, currentPhone.Phone, item?._id, item, currentPhone.TZCode);
                            }
                        }
                        // setStep5(true)
                    }

                }

            }

            // Set the last step state if no contacts were found
            if (ContactList.length === 0 && lstPhone.length == 0) {
                setLastStep(true);
                setStep4(false);
                setStep5(false);
            }
        } else {
            // Handle case where there are no contacts to call
            setLastStep(true);
            setStep4(false);
            setStep5(false);
        }
    }


    // Helper function to create phone number objects
    function createPhoneObj(name, phone, isCalled, IsDNC, Email,TZCode) {
        return {
            Name: name,
            Phone: phone,
            IsCalled: isCalled,
            IsDNC: IsDNC || false,
            PhoneID: Math.ceil(Math.random() * 10000),
            Email: Email,
            TZCode
        };
    }

    // Helper function to create detailed contact information object
    function createDetailsObj(item) {
        return {
            FirstName: item?.FirstName,
            ID: item?._id,
            LastName: item?.LastName,
            Email: item?.Email,
            Address1: item?.Address1,
            Address2: item?.Address2,
            City: item?.City,
            State: item?.State,
            Zip: item?.Zip,
            Country: item?.Country,
            Title: item?.Title,
            Company: item?.Company,
            Website: item?.Website,
            Industry: item?.Industry,
            LinkedInURL: item?.LinkedInURL,
            SalesRep: item?.SalesRep,
            DirectPhoneExt: item?.DirectPhoneExt,
            HoneyScore: item?.HoneyScore,
            DirectPhone: item?.DirectPhone,
            CompanyPhone: item?.CompanyPhone,
            MobilePhone: item?.MobilePhone,
            OtherPhone1: item?.OtherPhone1,
            OtherPhone2: item?.OtherPhone2,
            coldcallers: item?.coldcallers
        };
    }

    // Function to process call notes and format them for display
    function processCallNotes(callNotes) {
        let result = '';
        if (callNotes) {
            const strlist = callNotes.split('--').filter(Boolean);
            strlist.forEach(datanote => {
                if (datanote.trim()) {
                    const isDateValid = checkDate(datanote.trim());
                    const formattedNote = isDateValid ? `<br>--${datanote}` : `--${datanote}`;
                    result += formattedNote;
                }
            });
        }
        return callNotes;
    }

    // Function to check if a string contains a valid date
    function checkDate(stDatetime) {
        let hasDate = false;

        try {
            stDatetime = stDatetime.split(" ")[0];
            const inputText = stDatetime.split(" ");
            inputText.forEach(text => {
                if (!isNaN(Date.parse(text))) {
                    hasDate = true;
                }
            });
        } catch (err) {
            console.error('Error checking date:', err);
        }

        return hasDate;
    }


    async function handleEndDialignBtn() {
        try {
            setIsCallDisconnect(true)
            IsCallDisconnectRef.current = true
            finalizeCallingProcess();
            Called.disconnect();
            // Incrementing the count of calling attempts to track how many times calls have been made
            if (TotalRecord > StartCallingCount) {
                SetStartCallingCount(prev => prev + 1); // Increment the calling count
            }
            setIsPaused(true)
            setStep5(false);
            setIsAllDNC(false);
            // Resetting the contact list and user interface states
            // Disconnecting all ongoing calls and cleaning up the device resources

            device.disconnectAll(); // Disconnect all active calls
            device.destroy(); // Destroy the device to free up resources
            CallDisconnectBackendSide(ConFerenceNameRef.current, CallSidRef.current, StatusActionRef.current)



        } catch (error) {
            // Handling any errors that occur during the process of ending the dialing
            console.error("Error ending dialing process:", error); // Logging the error message
        }
    }

    // Function to start the call and timer
    const handleStartCall = () => {
        setStartTime(new Date()); // Set the start time to the current time
        setIsPaused(false); // Start the timer

    };
    // Function to pause the timer
    const handlePauseTimer = () => {
        setIsPaused(true); // Pause the timer
        setPauseDialingbtn(false); // Update button to show resume
        setCallSettled(false)
    };

    // Function to resume the timer
    const handleResumeTimer = () => {
        setStartTime(new Date() - elapsedTime * 1000); // Adjust start time to account for time already elapsed
        setIsPaused(false); // Resume the timer
        setPauseDialingbtn(true); // Update button to show pause
        setCallSettled(false)
    };


    // Function to handle end timer
    const handleEndTimer = () => {
        // setIsCallActive(false); // Stop the call
        setIsPaused(true); // Ensure the timer is not paused
    };

    // Start Dial method for calling a particular phone number
    async function StartDial(device, phoneNumber, prospectId, item, tzCode) {
        SetCallState(null)
        SetCurrentPhoneTZCode(tzCode);
        // if (isPaused == false) {
        return new Promise(async (resolve, reject) => {
            try {
                let objcontactdetails = {
                    ClientID: item.ClientID,
                    ProspectID: item._id,
                    AccountName: item.AccountName,
                    City: item.City,
                    Company: item.Company,
                    ConatactName: item.ConatactName,
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    Email: item.Email,
                    DirectPhone: item.DirectPhone,
                    CompanyPhone: item.CompanyPhone,
                    MobilePhone: item.MobilePhone,
                    OtherPhone1: item.OtherPhone1,
                    OtherPhone2: item.OtherPhone2,
                    LastCallResult: item.LastCallResult,

                }
                SetIsAnswerBy(0)
                IsCallDisconnectRef.current = false
                let conferenceName = `conference_${new Date().toISOString().slice(0, -1).replace(/:/g, '-').replace(/\./g, '-')}`;
                let To = "";
                if (phoneNumber.length === 10) {
                    To = `+1${phoneNumber}`
                } else if (phoneNumber.slice(0, 2) === "91" && phoneNumber.length === 12) {
                    To = `+${phoneNumber}`;
                } else {
                    To = `+1${phoneNumber}`;
                }
                // Constructing parameters for the call
                const params = {
                    To: To,               // Phone number to call
                    CallerID: CallerID,   // ID of the caller
                    ...ProspectDetails,    // Spread the prospect details
                    ProspectID: prospectId, // ID of the prospect being called
                    conferenceName: conferenceName,
                    ColdCallerUserID: item?.coldcallers != null ? item?.coldcallers?.ColdCallersUserID : coldCallerUserID,
                    prospectdetails: JSON.stringify(objcontactdetails)

                };

                // Attempt to connect the device for calling
                device.connect({
                    params,
                    rtcConstraints: {
                        audio: true, // Enable audio for the call
                    },
                }).then((call) => {
                    // If connection is successful, update the states
                    setIsContactLoopBreak(false);
                    setStep5(true);
                    setConFerenceName(conferenceName)
                    ConFerenceNameRef.current = conferenceName
                    // setIsCallnextcontact(false);
                    IsContactLoopBreakRef.current = false; // Update reference to state
                    setCalled(call);
                    setToNumber(phoneNumber)
                    SetCallState('Connected')
                    SetIsItComeFromBackButton(false)
                    SetIsCallActive(true);
                    // Event listener for when the call is accepted
                    call.on('accept', async () => {

                        SetCallState('Accepted')
                        setLiveAnswerBtn(true)
                        SetIsCallActive(true);
                        SetAllStatusBtnDisabled(false)
                        handleResumeTimer();
                        // Increment the total call count
                        SetTotalCalls((prev) => prev + 1);
                        SetCallSid(call?.parameters?.CallSid);
                        CallSidRef.current = call?.parameters?.CallSid
                        // Save access token details using an API call
                        await SaveAccessTokenDetails(
                            ProspectDetails?.UserID,            // User ID of the prospect
                            prospectId,                         // ID of the prospect
                            ProspectDetails?.ColdCallerUserID,  // Cold caller user ID
                            new Date(),                         // Current date and time
                            ProspectDetails?.ClientID,          // Client ID of the prospect
                            call?.parameters?.CallSid,          // Call SID
                            CallerID,                           // Caller ID
                            token,                                // Authentication token
                            PhoneScriptID
                        );


                    });

                    // Event listener for when the call is canceled
                    call.on("cancel", () => {
                        SetIsCallActive(false);
                        resolve(call); // Resolve the promise on call cancellation
                    });

                    // Event listener for when the call is disconnected
                    call.on('disconnect', async () => {

                        if (IsCallDisconnectRef.current) {
                            SetCallState('Disconnected')
                            SetIsCallActive(false);
                            setLiveAnswerBtn(false)
                            SetAllStatusBtnDisabled(true)

                            // handlePauseTimer();
                            // Update CallSid state
                            const milliseconds = (VoicemailDuratinRef.current * 1000) + 1000;
                            //await new Promise(resolveTimeout => setTimeout(resolveTimeout, milliseconds));
                            resolve(call); // Resolve the promise on call disconnection
                        }
                    });

                    // Event listener for call errors
                    call.on("error", (error) => {
                        let errorCode = error?.code;
                        handleTwilioError(errorCode);
                        SetIsCallActive(false);


                        resolve(call); // Resolve the promise on call error
                    });

                    // Event listener for when the call is rejected
                    call.on('reject', () => {
                        // handlePauseTimer();
                        SetCallState('Rejected')
                        SetIsCallActive(false);


                        resolve(call); // Resolve the promise on call rejection
                    });
                }).catch((error) => {
                    SetIsCallActive(false);
                    reject("error"); // Reject the promise on connection error
                });

            } catch (error) {
                console.error(error)
                SetIsCallActive(false);

                reject(error); // Reject the promise on unexpected error
            }
        });
        // }
    }


    // Twilio error code and messages
    const handleTwilioError = (errorCode) => {
        const errorMessages = {
            "31000": "An unexpected error occurred while connecting the call. Please try again.",
            "31001": "Call failed due to a network issue. Please check your internet connection.",
            "31002": "Failed to connect to the Twilio network. Retrying might help.",
            "31003": "Call couldn't be completed due to a protocol mismatch. Please contact support.",
            "31005": "The call was disconnected unexpectedly. Please try again.",
            "31008": "The call was disconnected due to a loss of connection. Please check your network.",
            "31009": "Call rejected by the receiving party. The number may be busy or unavailable.",
            "31050": "Call failed due to a timeout. Please try again.",
            "31204": "We're unable to complete this call due to a configuration issue. Please contact support.",
            "31400": "An issue occurred with the call setup. Please verify the call settings or try again.",
            "31451": "The destination is currently unreachable. Please check the number and try again.",
            "31481": "Call disconnected due to a registration error. Please try reconnecting.",
            "51007": "This call is restricted due to international call settings. Please contact support for assistance.",
            "52000": "Call could not connect due to an internal issue. Please try again later.",
            "53000": "Network error occurred during the call. Please verify your internet connection and try again.",
            "53001": "Call failed due to a poor connection. Try connecting to a stronger network.",
            "53400": "An error occurred with the call setup. Please try again or contact support if the issue persists.",
            "53405": "Call could not connect due to incompatible media settings. Please check your device or network.",
            "53407": "Call disconnected due to network congestion. Please try again on a stronger connection.",
            "53600": "Call failed due to security settings. Please contact support if you need assistance.",
            "54007": "The call was disconnected due to a WebSocket error. Please check your connection and try again.",
        };

        const message = errorMessages[errorCode] || "An unknown error occurred. Please try again or contact support.";
        console.error(message);
        toast.error(message);
    };


    // Twilio Dialpad
    const DialPadValueHandler = (value) => {
        Called.sendDigits(value); // Sends number as DTMF tone
    }



    // Function to manually dial a phone number
    async function ManualDialBtn(phoneNumber, name, TZCode) {
        try {
            setIsPaused(false); // Resume the timer
            setPauseDialingbtn(true); // Update button to show pause
            SetDialContactName(name);
            SetIsCallActive(true)
            // Set states that don't need to be immediately used in this function
            setStep5(true);
            setIsCallnextcontact(false);
            setIsDialcolorbtn(false); // Reset button color state

            // Disable the dial button for the current number
            await DialbtnDisable(name);

            // Start dialing and handle call output

            // Create a copy of the phone list and get the current index
            let contactPhoneList = [...CallingNumbers];
            let contactIndex = contactPhoneList.findIndex(el => el.Phone === phoneNumber);

            if (contactIndex !== -1) {
                // Mark the current contact's phone as called
                contactPhoneList[contactIndex].IsCalled = true;

                // Update the current phone ID
                SetCurrentPhoneID(contactPhoneList[contactIndex].PhoneID);
                currentPhoneIDRef.current = contactPhoneList[contactIndex].PhoneID;

                // Update the calling numbers list immutably
                SetCallingNumbers(prevNumbers => {
                    let updatedList = [...prevNumbers];
                    updatedList[contactIndex] = { ...updatedList[contactIndex], IsCalled: true };
                    return updatedList;
                });

                await StartDial(device, phoneNumber, FormDetails?.ID, FormDetails, TZCode);




            } else {
                throw new Error("Phone number not found in the contact list");
            }

            // // Show a green tick if dialing was successful
            // if (callOutput) {
            //     // Optionally show a green tick here if necessary
            //     // await GreentickDisplay(name);
            // } else {
            //     throw new Error("Dialing failed");
            // }
        } catch (error) {
            console.error("Error in ManualDialBtn:", error); // Log the specific error
            toast.error("Error dialing the number. Please try again.");
        }
    }

    //Close Potential Meeting model
    const ClosePotentailMeetingModel = () => {
        SetOpenPotentailMeetingModel(false)
        PerformNextActionAfterMeeting();
    }
    //Close Book Meeting model
    const CloseBookMeetingModel = () => {
        SetOpenBookMeetingModel(false);
        PerformNextActionAfterMeeting();
    }

    //Close Send Custom Email model
    const CloseSendCustomEmailModel = () => {
        // 
        SetOpenSendCustomEmailModel(false);
        PerformNextActionAfterMeeting();
        // DisconnectCall("Send Custom Email", CallSid)
    }

    // Update Email count 
    const UpdateCustomEmailSendCount = () => {
        SetEmailCount(EmailCount + 1)
    }

    // End Dial Method Button
    const EndDialDispositionButton = () => {
        SetIsEndButton(true)
        SetIsLiveAnswer(false)
        SetCallState("Disconnected")
        setIsCallDisconnect(true)
        // IsCallDisconnectRef.current = true
        Called.disconnect();
        // Incrementing the count of calling attempts to track how many times calls have been made
        // if (TotalRecord > StartCallingCount) {
        //     SetStartCallingCount(prev => prev + 1); // Increment the calling count
        // }

        CallDisconnectBackendSide(ConFerenceNameRef.current, CallSidRef.current, StatusActionRef.current)


    }

    // Send back to End dial disposition buttons
    const SendBackToEndDialButtons = () => {
        SetIsEndButton(false)
        SetIsLiveAnswer(false)
        setIsCallnextcontact(false)
        setStep5(true)
        SetIsItComeFromBackButton(true)
    }

    const DisconnectCall = async (action, CallSid) => {
        // Disconnect the current call session
        setIsCallDisconnect(true)
        IsCallDisconnectRef.current = true

        Called.disconnect();
        CallDisconnectBackendSide(ConFerenceName, CallSid, action);
    }

    const CallDisconnectBackendSide = async (conferenceName, CallSid, action) => {
        try {
            let RequestBody = {
                conferenceName,
                CallSid,
                ClientID: ProspectDetails?.ClientID,
                UserID: ProspectDetails?.UserID,
                Role: "ColdCallers",
                action
            };
            const Result = await Axios.post(CommonConstants.MOL_APIURL + "/twiliocalling/endConference", RequestBody);
            return Result.data.StatusMessage === "SUCCESS"; // Return boolean status
        } catch (err) {
            console.error("Error saving access token details:", err); // Error handling for failed request
            return false; // Return false on error
        }
    }

    // Status Button method called when processing the result of a call
    async function handleStatusBtn(OrderBy, DisplayName, CallStatus, NextActionName, MoveToDetails, ActionName1, ActionName2, ActionName3, VoiceMailRecordingID, DispositionEmailID, Duration, IsCountConnection, ContactStatusDetails) {
        try {
           const {timezoneIdentifier,abbreviation, currentTime, currentDate} = TimeDetails;
            setCallSettled(true)
            // handlePauseTimer();
            // Handle the "Call next contact" CallStatus
            if (CallStatus === "Call next contact") {
                // handlePauseTimer();
                await handleNextContactCall();
                return; // Exit early to avoid further execution
            }
            if (CallStatus == "Live Answer") {
                setStep5(false);
                SetIsLiveAnswer(true);
                if (IsItComeFromBackButton === false) {
                    let { date, time, timeZone } = getCurrentDateTimeWithTimezone();
                    let result = AppendCallNotes(currentDate, currentTime, abbreviation, ProspectDetails?.ColdCallersName, "", CallStatus);
                    setCallNotes(result);
                }
            } else {
                setStep5(true);
                SetIsEndButton(false);
                SetIsLiveAnswer(false);
                setCallNotes(DisplayName); // Save the call status
                let contactPhoneList = [...CallingNumbers]; // Copy phone list
                let IsAnswerBy = 0;
                if (IsCountConnection) {
                    SetTalksCount(TalksCount + 1);
                    IsAnswerBy = 1;
                    SetIsAnswerBy(1)
                }
                let contactIndex = contactPhoneList.findIndex(el => el.PhoneID === currentPhoneIDRef.current);
                SetContactIndex(contactIndex)
                await GreentickDisplay(DialContactName);
                SetNextActionName(NextActionName)
                if (ActionName1 === "Set Potential Meeting" || ActionName2 === "Set Potential Meeting" || ActionName3 === "Set Potential Meeting") {
                    SetOpenPotentailMeetingModel(true)

                } else if (ActionName1 === "Set Meeting" || ActionName2 === "Set Meeting" || ActionName3 === "Set Meeting") {
                    SetOpenBookMeetingModel(true)
                } else if (ActionName1 === "Send Custom Email" || ActionName2 === "Send Custom Email" || ActionName3 === "Send Custom Email") {
                    SetOpenSendCustomEmailModel(true)
                }

                // Disconnect call
                let CommonActionName = "";

                if (ActionName1 && ActionName1 != "") {
                    if (ActionName1 == "Send Voicemail") {
                        CommonActionName = ActionName1;
                    }
                    if (ActionName2 && ActionName2 != "") {
                        if (ActionName2 == "Send Voicemail") {
                            CommonActionName = ActionName1;
                        }
                        if (ActionName3 && ActionName3 != "") {
                            if (ActionName2 == "Send Voicemail") {
                                CommonActionName = ActionName1;
                            }
                        }
                    }
                }

                if (CommonActionName == "Send Voicemail") {
                    SetVoicemailDuratin(Duration)
                    VoicemailDuratinRef.current = Duration;
                    SetStatusAction(CommonActionName)
                    StatusActionRef.current = CommonActionName;
                }
                else {
                    SetVoicemailDuratin(0)
                    VoicemailDuratinRef.current = 0;
                    SetStatusAction('')
                    StatusActionRef.current = ''
                }

                DisconnectCall(CommonActionName, Called?.parameters?.CallSid);

                // Reset loaders and loop states
                resetLoaderStates();

                // Prepare to update call notes and session history

                // let { date, time, timeZone } = getCurrentDateTimeWithTimezone();
                const {timezoneIdentifier,abbreviation, currentTime, currentDate} = TimeDetails;
                let result = AppendCallNotes(currentDate, currentTime, abbreviation, ProspectDetails?.ColdCallersName, contactPhoneList[contactIndex]?.Phone, CallStatus);
                setCallNotes(result);
                let CallSid = Called?.parameters?.CallSid;
                const customParameters = Object.fromEntries(Called?.customParameters);

                await UpdateOrAddDialSessionHistory(customParameters?.ClientID, CallSid, customParameters?.ProspectID, customParameters?.ColdCallerUserID, customParameters?.To, CallStatus, result, customParameters?.UserID, IsAnswerBy);
                if (ActionName1 && ActionName1 != "") {
                    ActionAfterStatus(ActionName1, contactPhoneList[contactIndex]?.Email, contactPhoneList[contactIndex]?.Phone, DispositionEmailID, FormDetails?.ID, VoiceMailRecordingID);
                    if (ActionName2 && ActionName2 != "") {
                        ActionAfterStatus(ActionName2, contactPhoneList[contactIndex]?.Email, contactPhoneList[contactIndex]?.Phone, DispositionEmailID, FormDetails?.ID, VoiceMailRecordingID);
                        if (ActionName3 && ActionName3 != "") {
                            ActionAfterStatus(ActionName3, contactPhoneList[contactIndex]?.Email, contactPhoneList[contactIndex]?.Phone, DispositionEmailID, FormDetails?.ID, VoiceMailRecordingID);
                        }
                    }
                }

                if (ContactStatusDetails && ContactStatusDetails?.ContactStatusName != "") {
                    UpdateContactStatus(contactPhoneList[contactIndex]?.Email, ContactStatusDetails?.ContactStatusID, ContactStatusDetails?.ContactStatusName, customParameters?.ColdCallerUserID, customParameters?.ClientID, new Date(), "ColdCallers");
                }

                if (
                    ActionName1 !== "Set Potential Meeting" &&
                    ActionName2 !== "Set Potential Meeting" &&
                    ActionName3 !== "Set Potential Meeting" &&
                    ActionName1 !== "Send Custom Email" &&
                    ActionName2 !== "Send Custom Email" &&
                    ActionName3 !== "Send Custom Email" &&
                    ActionName1 !== "Set Meeting" &&
                    ActionName2 !== "Set Meeting" &&
                    ActionName3 !== "Set Meeting"
                ) {
                    if (!isPaused) {  // Simplified check for `isPaused == false`
                        // Proceed to the next contact number
                        if (NextActionName === "Next Number") {
                            await handleNextContact(contactIndex);
                        } else if (NextActionName === "Ask Me") {
                            setIsCallnextcontact(true);
                        } else if (NextActionName === "Next Contact") {
                            await handleNextContactCall();
                        }
                    }
                    //  else {
                    //     setIsCallnextcontact(true);
                    // }
                }



            }
        } catch (error) {
            console.error("Error in handleStatusBtn:", error); // Log the error
            toast.error("Error processing call CallStatus. Please check the logs.");
        }
    }

    // Perform Next Action After Meeting Popups
    const PerformNextActionAfterMeeting = async () => {
        if (NextActionName == "Next Number") {
            await handleNextContact(ContactIndex);
        } else if (NextActionName == "Ask Me") {
            setIsCallnextcontact(true)
        } else if (NextActionName == "Next Contact") {
            await handleNextContactCall();
        }
    }

    // Function to handle the transition to the next contact
    async function handleNextContact(contactIndex) {
        if (contactIndex !== -1) {
            const nextPhone = await DialNextContactNumber(CurrentPhoneID);

            if (nextPhone === -1 && TotalRecord < StartCallingCount) {
                await handleEndDialignBtn();
            } else if (nextPhone === -1) {
                // setIsCallnextcontact(true); // No more contacts to dial
            } else {
                const milliseconds = (VoicemailDuratinRef.current * 1000) + 1000;
                //  await new Promise(resolveTimeout => setTimeout(resolveTimeout, milliseconds));
                await ManualDialBtn(nextPhone.Phone, nextPhone?.Name, nextPhone?.TZCode); // Dial the next phone
                SetCurrentPhoneID(nextPhone?.PhoneID); // Update current phone ID
                setIsCallnextcontact(false);
                setIsCallcontact(true);

            }
        }
    }

    // Function to handle transitioning to the next contact when "Call next contact" is selected
    async function handleNextContactCall() {
        if (TotalRecord > StartCallingCount) {
            SetStartCallingCount(prev => prev + 1); // Increment the calling count
        }


        SetAddNotes("");
        const ID = contactIdRef.current.value;
        const index = ContactList.findIndex(obj => obj._id === ID);

        // Remove the current contact from the list safely using state updater
        const updatedList = [...ContactList];
        if (index !== -1) {
            updatedList.splice(index, 1);
            SetContactList(updatedList); // Use state setter
        }

        IsContactLoopBreakRef.current = false; // Reset loop break reference
        setIsCallnextcontact(false);
        setIsCallcontact(true);

        // If no contacts remain, finalize the process
        if (updatedList.length === 0) {
            finalizeCallingProcess();
            handleEndTimer();
        }
    }

    // Function to finalize the calling process when all contacts have been processed
    function finalizeCallingProcess() {
        SetContactList([]); // Clear the contact list
        setLastStep(true); // Move to the last step
        setStep4(false);
        setStep5(false);
        SetShowDialpad(false)
        setIsAllDNC(false);
        SetIsLiveAnswer(false);


    }

    // DialNextContactNumber method to find and dial the next phone number
    async function DialNextContactNumber(currentPhoneID) {
        SetAddNotes("");

        // Copy the calling numbers to avoid mutating state directly
        const phoneArr = CallingNumbers.map(phone => ({ ...phone }));
        const currentIndex = phoneArr.findIndex(el => el.PhoneID === currentPhoneID);
        if (currentIndex === -1) {
            console.warn("Current phone ID not found in the list.");
            return -1;
        }

        // Find the next phone that hasn't been called yet
        for (let i = currentIndex + 1; i < phoneArr.length; i++) {
            const phone = phoneArr[i];
            if (phone?.IsDNC == false && phone?.IsCalled === false) {
                phoneArr[i].IsCalled = true; // Update the copied array
                SetCallingNumbers(phoneArr); // Update the state
                return phone; // Return the next phone to dial
            }
        }

        const IsAllCalled = phoneArr.some(item => item.IsCalled === true);

        // If no next phone is found, try to find the previous phone
        if (IsAllCalled == false) {
            for (let i = currentIndex - 1; i >= 0; i--) {
                const phone = phoneArr[i];
                if (phone?.IsDNC == false && phone?.IsCalled === false) {
                    phoneArr[i].IsCalled = true; // Update the copied array
                    SetCallingNumbers(phoneArr); // Update the state
                    return phone; // Return the previous phone to dial
                }
            }
        }


        // If no phone is available, call handleNextContactCall and return -1
        await handleNextContactCall();
        return -1;
    }


    //Function to display a green tick based on the type of phone called

    async function GreentickDisplay(Name) {
        try {

            switch (Name) {
                case "DirectPhone":
                    setIsGreenTickDirectPhone(true);
                    break;
                case "MobilePhone":
                    setIsGreenTickMobilePhone(true);
                    break;
                case "CompanyPhone":
                    setIsGreenTickCompanyPhone(true);
                    break;
                case "OtherPhone1":
                    setIsGreenTickOtherPhone1(true);
                    break;
                case "OtherPhone2":
                    setIsGreenTickOtherPhone2(true);
                    break;
                default:
                    throw new Error("Invalid phone type provided"); // Handle invalid phone type
            }
        } catch (error) {
            console.error("Error in GreentickDisplay:", error); // Log the error
            toast.error("Error displaying green tick."); // Notify user of the error
        }
    }

    // Function to disable the dial button for the specified phone type
    async function DialbtnDisable(Name) {
        // Define an object to hold loader states
        const loaderStates = {
            DirectPhone: setIsLoderDirectPhone,
            MobilePhone: setIsLoderMobilePhone,
            CompanyPhone: setIsLoderCompanyPhone,
            OtherPhone1: setIsLoderOtherPhone1,
            OtherPhone2: setIsLoderOtherPhone2,
        };

        // Reset all loaders to false
        Object.values(loaderStates).forEach(setLoader => setLoader(false));

        // Set the loader for the specified phone name to true, if it exists
        if (loaderStates[Name]) {
            loaderStates[Name](true);
        }
    }


    // Function to handle the call done button
    async function handleCallDoneBtn() {
        try {
            if (device) {
                device.disconnectAll(); // Disconnect all active calls
                device.destroy(); // Fully destroy the Twilio Device instance

            }
            ReloadContactPage();
            setLastStep(false); // Update the last step state
            windowObject.close(); // Close the window
        } catch (error) {
            console.error("Error in handleCallDoneBtn:", error); // Log the error
            toast.error("Error completing the call."); // Notify user of the error
        }
    }

    // Helper function to reset loader states
    function resetLoaderStates() {
        setIsLoderCompanyPhone(false);
        setIsLoderMobilePhone(false);
        setIsLoderDirectPhone(false);
        setIsLoderOtherPhone2(false);
        setIsLoderOtherPhone1(false);
        setIsDialcolorbtn(true); // Reset dial button color
        setIsContactLoopBreak(false); // Reset loop break state
        IsContactLoopBreakRef.current = true; // Set loop break reference
        setIsCallnextcontact(false); // Reset call next contact state
    }

    // Function to append call notes
    const AppendCallNotes = (date, time, timeZone, username, phoneNumber, status) => {
        // Generates a formatted log entry
        function generateLogEntry(date, time, timeZone, username, phoneNumber, status) {
            return `-- ${date} @ ${time} ${timeZone} by ${username} - ${ClientName} -- ${phoneNumber} -- ${status}`;
        }

        // Validates the date format (MM/DD/YYYY)
        function checkDate(dateString) {
            const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // Matches MM/DD/YYYY
            return datePattern.test(dateString);
        }

        let result = '';
        //const strlist = CallNotes.split('--').filter(Boolean);
        const strlist = "".split('--').filter(Boolean);;
        // Add the new note at the top of the result
        result += generateLogEntry(date, time, timeZone, username, phoneNumber, status) + "\n";

        strlist.forEach(datanote => {
            if (datanote?.trim() !== "") {
                let Objdatematch = "";
                let Objstringmatch = "";
                const Isdatevalid = checkDate(datanote?.trim());

                // Extract date, time, and phone number from datanote
                const regex = /(\d{2}\/\d{2}\/\d{4}) @ (\d{1,2}:\d{2} (AM|PM)) ([A-Z]{3}) by ([\w\s]+) -- ?(\(?\d{3}\)? ?\d{3}-?\d{4})? -- ?(.*)?/;
                const match = datanote.match(regex);

                if (match) {
                    // Extracting matching groups
                    const date = match[1];
                    const time = match[2];
                    const timezone = match[4];
                    const phoneNumber = match[6] || ''; // Default to empty string if no phone number
                    const status = match[7] || ''; // Default to empty string if no status

                    // Generate the log entry using the captured groups
                    Objstringmatch = generateLogEntry(date, time, timezone, username, phoneNumber, status);
                } else {
                    Objstringmatch = "--" + datanote.trim(); // Fallback for non-matching entries
                }

                // Determine whether the entry should have a line break based on date validity
                if (Isdatevalid) {
                    Objdatematch = "<br>" + Objstringmatch;
                } else {
                    Objdatematch = Objstringmatch; // No line break if the date is invalid
                }

                result += Objdatematch + "\n"; // Append the result
            }
        });
        result += CallNotes;
        return result;
    }

    // Function to save call notes
    const SaveNotes = () => {
        if (AddNotes?.trim() !== "") {
            // let { date, time, timeZone } = getCurrentDateTimeWithTimezone();
            const {timezoneIdentifier,abbreviation, currentTime, currentDate} = TimeDetails;
            let result = AppendCallNotes(currentDate, currentTime, abbreviation, ProspectDetails?.ColdCallersName, '', AddNotes); // Pass AddNotes as the username
            setCallNotes(result);
            SetAddNotes("");
        } else {
            console.error("AddNotes cannot be empty"); // Error handling for empty notes
        }
    }

    //Function to handle add notes with (Shift+Enter)
    const HandleShiftEnter = (e) => {
        // Check if both Shift and Enter are pressed
        if (e.shiftKey && e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior (newline)
            SaveNotes();
        }
    }

    // Function to get current date, time, and timezone
    const getCurrentDateTimeWithTimezone = (timeZone = 'Asia/Kolkata') => {
        const now = new Date();

        // Format the date and time
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            timeZone: timeZone,
            timeZoneName: 'short', // Abbreviation (e.g., "IST")
        };

        // Create a formatted date string
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(now);

        // Extract date, time, and time zone parts separately
        const date = `${parts.find(part => part.type === 'month').value}/${parts.find(part => part.type === 'day').value}/${parts.find(part => part.type === 'year').value}`;
        const time = `${parts.find(part => part.type === 'hour').value}:${parts.find(part => part.type === 'minute').value} ${parts.find(part => part.type === 'dayPeriod').value}`;
        const timeZoneName = parts.find(part => part.type === 'timeZoneName').value?.split('+')[0] || ""; // Handling potential undefined

        return {
            date: date,
            time: time,
            timeZone: timeZoneName
        };
    }

    // Function to save access token details
    const SaveAccessTokenDetails = async (UserID, ProspectID, ColdCallerUserID, CreatedDate, ClientID, CallSid, CallerID, Token, PhoneScriptID) => {
        try {
            let RequestBody = {
                UserID,
                ProspectID,
                ColdCallerUserID,
                CreatedDate,
                ClientID,
                CallSid,
                CallerID,
                TwilioToken: Token,
                PhoneScriptID: PhoneScriptID,
                Role: "ColdCallers"
            };
            const Result = await Axios.post(CommonConstants.MOL_APIURL + "/twiliocalling/DialSessionSave", RequestBody);
            return Result.data.StatusMessage === "SUCCESS"; // Return boolean status
        } catch (err) {
            console.error("Error saving access token details:", err); // Error handling for failed request
            return false; // Return false on error
        }
    }

    // Function to add or update dial session history details
    const UpdateOrAddDialSessionHistory = async (ClientID, CallSid, ColdCallerContactID, ColdCallersUserID, To, Status, Notes, UserID, IsAnswerBy) => {
        try {
            let RequestBody = {
                ClientID,
                CallSid,
                ColdCallerContactID,
                ColdCallersUserID,
                To,
                Status,
                Notes,
                Role: "ColdCallers",
                // Email: FormDetails?.Email || "",
                // CompanyPhone: FormDetails?.CompanyPhone || "",
                // DirectPhone: FormDetails?.DirectPhone || "",
                // MobilePhone: FormDetails?.MobilePhone || "",
                // OtherPhone1: FormDetails?.OtherPhone1 || "",
                // OtherPhone2: FormDetails?.OtherPhone2 || "",
                PhoneScriptID: PhoneScriptID,
                UserID,
                ContactDetails: FormDetails,
                ConFerenceName: ConFerenceNameRef.current,
                Connected: IsAnswerBy
            };
            const Result = await Axios.post(CommonConstants.MOL_APIURL + "/twiliocalling/UpdateDialSessionDetails", RequestBody);
            return Result.data.StatusMessage === "SUCCESS"; // Return boolean status
        } catch (err) {
            console.error("Error updating dial session history:", err); // Error handling for failed request
            return false; // Return false on error
        }
    }

    // Function to format duraction 
    const DurationFormatter = (totalSeconds) => {
        // Check if totalSeconds is NaN or falsy, default to 0
        if (isNaN(totalSeconds) || totalSeconds == null || totalSeconds === undefined) {
            totalSeconds = 0;
        }

        totalSeconds = Math.round(totalSeconds); // Round to the nearest whole second

        const hours = Math.floor(totalSeconds / 3600); // Calculate hours
        const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
        const seconds = totalSeconds % 60; // Calculate remaining seconds

        if (hours > 0) {
            return `${String(hours).padStart(2, '0')} hrs ${String(minutes).padStart(2, '0')} min ${String(seconds).padStart(2, '0')} sec`;
        } else if (minutes > 0) {
            return `${String(minutes).padStart(2, '0')} min ${String(seconds).padStart(2, '0')} sec`;
        } else {
            return `${seconds}s`; // Only display seconds if it's less than 60 seconds
        }
    }


    // Function for Action 1st, 2nd and 3rd
    const ActionAfterStatus = async (actionType, Email, PhoneNumber, DispositionEmailID, ProspectID, VoiceMailRecordingID) => {
        try {
            let RequestBody = {
                UserID: LoggedUserDetails?.UserID,
                CreatedDate: new Date(),
                CreatedBy: LoggedUserDetails?.ColdCallerUserID,
                ClientID: LoggedUserDetails?.ClientID,
                PhoneNumber: PhoneNumber,
                CallingNumbers: CallingNumbers,
                Email: Email,
                Domain: extractDomainFromEmail(Email),
                DispositionEmailID: DispositionEmailID,
                ProspectID: ProspectID,
                ProspectDetails: FormDetails,
                Action: actionType,
                ReminderEmailAccountID: DialSessionData?.SDRAssignmentDetails?.ReminderEmailAccountID,
                conferenceName: ConFerenceName,
                VoiceMailRecordingID: VoiceMailRecordingID,
                Role: "ColdCallers",
                CallerID: CallerID
            }
            let API_URL = "";
            if (actionType === "Blacklist Contact - Client Level") {
                API_URL = `${CommonConstants.MOL_APIURL}/twiliocalling/BlacklistContactSave`;
            } else if (actionType === "Blacklist Domain - Client Level") {
                API_URL = `${CommonConstants.MOL_APIURL}/twiliocalling/BlacklistDomainSave`;
            } else if (actionType === "Black List Phone Number - Client Level") {
                API_URL = `${CommonConstants.MOL_APIURL}/twiliocalling/BlacklistPhoneNumberSave`;
            } else if (actionType === "DNC For All Clients") {
                API_URL = `${CommonConstants.MOL_APIURL}/twiliocalling/GlobalContactBlacklistAdd`;
            } else if (actionType === "Blacklist Phone Number for All Clients") {
                API_URL = `${CommonConstants.MOL_APIURL}/twiliocalling/GlobalPhoneNumberBlacklistSave`;
            } else if (actionType === "Blacklist Email and Phone for All Clients") {
                API_URL = `${CommonConstants.MOL_APIURL}/twiliocalling/GlobalContactAndPhoneBlacklist`;
            } else if (actionType === "Send Email" || actionType === "Send Email Template") {
                SetEmailCount(EmailCount + 1);
                API_URL = `${CommonConstants.MOL_APIURL}/twiliocalling/SendDispositionEmail`;
            } else if (actionType === "Send Voicemail") {
                SetVoicemailCount(VoicemailCount + 1);
                API_URL = `${CommonConstants.MOL_APIURL}/twiliocalling/sendvoicemail`;
            }
            if (actionType != "") {
                const Result = await Axios.post(API_URL, RequestBody);
                return Result.data.StatusMessage === "SUCCESS";
            }
        } catch (err) {
            console.error(err)
        }
    }

    // Function for Update Contact Status
    const UpdateContactStatus = async (Email, ContactStatusID, Status, UserID, ClientID, LastUpdatedDate, Role) => {
        try {
            let RequestBody = {
                Email,
                ContactStatusID,
                Status,
                UserID,
                ClientID,
                LastUpdatedDate,
                Role
            }
            const Result = await Axios.post(`${CommonConstants.MOL_APIURL}/twiliocalling/UpdateContactStatus`, RequestBody);
            return Result.data.StatusMessage === "SUCCESS";
        } catch (err) {
            console.error(err)
        }
    }

    const extractDomainFromEmail = (email) => {
        // Check if the email contains '@' symbol
        if (email && email.includes('@')) {
            // Split the email by '@' and return the domain part (second element)
            return email.split('@')[1];
        } else {
            // Return null or an error message if '@' is not found
            return ""; // Or throw an error if needed
        }
    }

    // Function to pause dialing
    const PauseDialing = () => {
        // if(IsCallActive == false){
        handlePauseTimer();
        // }
    };

    // Function to resume dialing
    const ResumeDialing = async () => {
        setIsPaused(false); // Resume the timer
        setPauseDialingbtn(true); // Update button to show pause

        if (IsCallActive == false) {
            handleResumeTimer();
            let contactPhoneList = [...CallingNumbers]; // Copy phone list
            let contactIndex = contactPhoneList.findIndex(el => el.PhoneID === currentPhoneIDRef.current);
            let NextActionName = "Next Number";
            // Proceed to the next contact number
            if (NextActionName == "Next Number") {
                await handleNextContact(contactIndex);
            } else if (NextActionName == "Ask Me") {
                setIsCallnextcontact(true)
            } else if (NextActionName == "Next Contact") {
                await handleNextContactCall();
            }
        }

    };

    // Function for Back Live answer
    const BackLiveAnswerDispositionButton = () => {
        setStep5(true);
        SetIsLiveAnswer(false);
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        var isValidLength = false

        if (name == "DirectPhone") {
            isValidLength = value.length === 0
            if (isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: null,
                }));
            }
        }
        else if (name == "MobilePhone") {
            isValidLength = value.length === 0
            if (isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: null
                }));

            }

        }
        else if (name == "CompanyPhone") {
            isValidLength = value.length === 0
            if (isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: null,
                }));
            }

        }
        else if (name == "OtherPhone1") {

            isValidLength = value.length === 0
            if (isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: null,
                }));
            }
        }
        else if (name == "OtherPhone2") {
            isValidLength = value.length === 0
            if (isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: null,
                }));
            }
        }

        if (!isValidLength) {
            SetFormDetails((prevDetails) => ({
                ...prevDetails,
                [name]: value,
            }));
        }



    };

    const handleBlur = (e) => {

        const { name, value } = e.target;
        const isValidLength = value.length >= 9 && value.length <= 12;
        if (name == "DirectPhone") {
            if (!isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: DirectPhone, // Dynamically update the field based on its name
                }));
            }
        }
        else if (name == "MobilePhone") {
            if (!isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: MobilePhone, // Dynamically update the field based on its name
                }));

            }

        }
        else if (name == "CompanyPhone") {
            if (!isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: CompanyPhone, // Dynamically update the field based on its name
                }));
            }

        }
        else if (name == "OtherPhone1") {
            if (!isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: OtherPhone1, // Dynamically update the field based on its name
                }));
            }
        }
        else if (name == "OtherPhone2") {
            if (!isValidLength) {
                SetFormDetails((prevDetails) => ({
                    ...prevDetails,
                    [name]: OtherPhone2, // Dynamically update the field based on its name
                }));
            }
        }

    }

    return (
        <>

            {/* start main header */}
            <header>
                <div className="container content-space-between">
                    <div className="logo">
                        <img src="https://appv2.saleshive.com/static/media/SalesHive.a8f9582b.png" alt="logo" />
                    </div>
                </div>
            </header>
            {/* end main header */}

            {/* start first header */}
            {Step1 ? (
                <div className='steped step-one'>
                    <h1>Dial Session Settings</h1>
                    <p>There are {TotalRecord} contacts to be called.</p>
                    <div className="container border-all mt-1 px-3 mb-4">
                        <ul className="settings-list">
                            <li className="settings-item">
                                <label className="settings-label" htmlFor="voicemail-recording">Phone Script
                                    <button id="toggleButton1" onClick={() => toggleDiv('contentDiv1')} className="settings-info">
                                        <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#1C274C"></path>
                                        </svg>
                                    </button>
                                    <div id="contentDiv1" className="content-div hover-tip" style={{ display: visibleDivs.contentDiv1 ? 'block' : 'none' }}>
                                        <p>The selected script will only  cted.</p>
                                    </div>
                                </label>
                                <div className="w-100">
                                    <select className="settings-input" id="PhoneScriptDetails" value={PhoneScriptID} onChange={PhoneScriptHandler}>
                                        <option value="">None</option>
                                        {
                                            PhoneScriptDetails?.map((el) => (
                                                <option key={el?._id} value={el?._id}>{el?.ScriptName}</option>
                                            ))
                                        }
                                    </select>
                                    <span style={{ color: "red" }}>{errors.phonescriptID}</span>
                                </div>
                            </li>
                            <li className="settings-item">
                                <label className="settings-label" htmlFor="voicemail-recording">Voicemail recording
                                    <button id="toggleButton1" onClick={() => toggleDiv('contentDiv2')} className="settings-info">
                                        <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#1C274C"></path>
                                        </svg>
                                    </button>
                                    <div id="contentDiv2" className="content-div hover-tip" style={{ display: visibleDivs.contentDiv2 ? 'block' : 'none' }}>
                                        <p>The selected voicemail will only  cted.</p>
                                    </div>
                                </label>
                                <div className="w-100">
                                    <select className="settings-input" id="voicemail-recording">
                                        <option value="Reactivation Promo">Yes</option>
                                        <option value="Reactivation Promo">No</option>
                                    </select>
                                </div>
                            </li>
                            <li className="settings-item">
                                <label className="settings-label" htmlFor="caller-id">Caller ID
                                    <button id="toggleButton1" onClick={() => toggleDiv('contentDiv3')} className="settings-info">
                                        <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#1C274C"></path>
                                        </svg>
                                    </button>
                                    <div id="contentDiv3" className="content-div hover-tip" style={{ display: visibleDivs.contentDiv3 ? 'block' : 'none' }}>
                                        <p>The selected voicemail will only  cted.</p>
                                    </div>
                                </label>
                                <div className="w-100">
                                    <select className="settings-input" id="CallerID" value={CallerID} onChange={ChangeCallerID}>
                                        {
                                            PhoneArr?.map((el) => (
                                                <option key={el} value={el}>{el}</option>
                                            ))
                                        }
                                    </select>

                                </div>
                            </li>
                            <li className="settings-item">
                                <label className="settings-label" htmlFor="voicemail-recording">Dialing Dispositions
                                    <button id="toggleButton1" onClick={() => toggleDiv('contentDiv4')} className="settings-info">
                                        <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#1C274C"></path>
                                        </svg>
                                    </button>
                                    <div id="contentDiv4" className="content-div hover-tip" style={{ display: visibleDivs.contentDiv4 ? 'block' : 'none' }}>
                                        <p>The selected dialing dispositions .</p>
                                    </div>
                                </label>
                                <div className="w-100">
                                    <select className="settings-input" id="DialingSetID" value={DialingSetID} onChange={DialingSetHandler}>
                                        {
                                            DialingSetListing?.map((el) => (
                                                <option key={el?._id} value={el?._id}>{el?.Name}</option>
                                            ))
                                        }

                                    </select>
                                </div>
                            </li>
                            <li className="settings-item">
                                <label className="settings-label" htmlFor="voicemail-recording">Live Answer Dispositions
                                    <button id="toggleButton1" onClick={() => toggleDiv('contentDiv5')} className="settings-info">
                                        <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#1C274C"></path>
                                        </svg>
                                    </button>
                                    <div id="contentDiv5" className="content-div hover-tip" style={{ display: visibleDivs.contentDiv5 ? 'block' : 'none' }}>
                                        <p>The selected live answer dispositions.</p>
                                    </div>
                                </label>
                                <div className="w-100">
                                    <select className="settings-input" id="LiveAnswerDispositionSet" value={LiveAnswerDispositionSetID} onChange={LiveAnswerDispositionHandler}>
                                        {
                                            LiveAnswerDispositionSet?.map((el) => (
                                                <option key={el?._id} value={el?._id}>{el?.Name}</option>
                                            ))
                                        }

                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="container mt-1">
                        <button className="button w-auto" onClick={() => { ContinueBtn({ token }) }}>Continue</button>
                    </div>
                    <p className='preline-tes'>By using this dialer to make calls and/or leave messages, I agree that I will follow all applicable local, state, national and international laws, rules, and regulations, including those governing call recording and telemarketing. I also agree to abide by the <a href="">terms of service</a>.</p>
                </div>
            ) : (
                <></>
            )}

            {/* end first header */}

            {/* start info header */}
            {Step2 || Step3 || Step4 ? (<>
                <div className='info-nav'>

                    {CallingInfoPanel ? (
                        <div className="info">
                            <div className="info-item">
                                <div className="info-label">Caller ID</div>
                                <div className="info-value">{!StartDialignbtn ? CallerID : "Not Connected"}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Duration</div>
                                <div className="info-value">{DurationFormatter(DurationsInSec)}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Contacts</div>
                                <div className="info-value">{StartCallingCount} of {TotalRecord}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Calls</div>
                                <div className="info-value">{TotalCalls}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Talks</div>
                                <div className="info-value">{TalksCount}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">E-mails</div>
                                <div className="info-value">{EmailCount}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Voicemails</div>
                                <div className="info-value">{VoicemailCount}</div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="info-navtwo">
                    <div className="container">
                        <div className="d-flex flex-center mt-1 mb-1 flex-wrap w-100 mt-3 mb-2">

                            {
                                ShowDialpad && <button className='dia-btn-mute mr-2' aria-describedby={id} type="button" onClick={handleClick}>
                                    <svg fill="#000000" width="15px" height="15px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM5 9a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM5 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" /></svg>
                                    {/* Dialpad */}
                                </button>
                            }

                            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}>
                                <div class="dial-grid-container">
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('1')}>1</div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('2')}>2<p>ABC</p></div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('3')}>3<p>DEF</p></div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('4')}>4<p>GHI</p></div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('5')}>5<p>JKL</p></div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('6')}>6<p>MNO</p></div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('7')}>7<p>PQRS</p></div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('8')}>8<p>TUV</p></div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('9')}>9<p>WXYZ</p></div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('*')}>✱</div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('0')}>0<p>+</p></div>
                                    <div class="dial-grid-item" onClick={() => DialPadValueHandler('#')}>#</div>
                                </div>
                            </Popover>


                            {StartRecordingbtn ? (
                                <button className="recording startrecording">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={15}
                                        height={15}
                                        viewBox="-3 0 28 28"
                                    >
                                        <title>{"play"}</title>
                                        <path
                                            fill="#000"
                                            fillRule="evenodd"
                                            d="M21.415 12.554 2.418.311C1.291-.296 0-.233 0 1.946v24.108c0 1.992 1.385 2.306 2.418 1.635l18.997-12.243a2.076 2.076 0 0 0 0-2.892"
                                        />
                                    </svg>
                                    Start Recording
                                </button>
                            ) : (
                                <></>
                            )}

                            {Recordingbtn ? (
                                <button className="recording mr-2">
                                    <svg fill="#fff" width="15px" height="15px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="127" cy="129" r="81" fill-rule="evenodd" />
                                    </svg>
                                    Recording
                                </button>
                            ) : (
                                <></>
                            )}

                            {/* Code for mute and unmute call */}
                            {
                                Called && (<button className={`dia-btn-mute mr-2 ${IsMuted ? 'mute' : 'unmute'}`}
                                    onClick={HandleMuteToggle}>
                                    {IsMuted ? MuteIcon : MuteIcon}
                                </button>)
                            }

                            {EndDialingbtn ? (PauseDialingbtn ? (
                                <button className="dia-btn btn-left-rd mr-2" onClick={PauseDialing}>
                                    <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.163 3.819C5 4.139 5 4.559 5 5.4v13.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.581.164h.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .656-.656c.163-.32.163-.74.163-1.581V5.4c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656C8.861 3 8.441 3 7.6 3h-.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656zm9 0C14 4.139 14 4.559 14 5.4v13.2c0 .84 0 1.26.164 1.581a1.5 1.5 0 0 0 .655.655c.32.164.74.164 1.581.164h.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .655-.656c.164-.32.164-.74.164-1.581V5.4c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656C17.861 3 17.441 3 16.6 3h-.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.655.656z" /></svg>
                                    Pause Dialing</button>
                            ) : (
                                <button className="dia-btn btn-left-rd mr-2" onClick={ResumeDialing}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.16 3.82C5 4.14 5 4.56 5 5.4v13.2c0 .84 0 1.26.16 1.58a1.5 1.5 0 0 0 .66.66c.32.16.74.16 1.58.16h.2c.84 0 1.26 0 1.58-.16a1.5 1.5 0 0 0 .66-.66c.16-.32.16-.74.16-1.58V5.4c0-.84 0-1.26-.16-1.58a1.5 1.5 0 0 0-.66-.66C8.86 3 8.44 3 7.6 3h-.2c-.84 0-1.26 0-1.58.16a1.5 1.5 0 0 0-.66.66zm9 0C14 4.14 14 4.56 14 5.4v13.2c0 .84 0 1.26.16 1.58a1.5 1.5 0 0 0 .66.66c.32.16.74.16 1.58.16h.2c.84 0 1.26 0 1.58-.16a1.5 1.5 0 0 0 .66-.66c.16-.32.16-.74.16-1.58V5.4c0-.84 0-1.26-.16-1.58a1.5 1.5 0 0 0-.66-.66C17.86 3 17.44 3 16.6 3h-.2c-.84 0-1.26 0-1.58.16a1.5 1.5 0 0 0-.66.66z" fill="#000" />
                                    </svg>
                                    Resume Dialing
                                </button>

                            )) : <></>}
                            {EndDialingbtn ? (
                                <button className="dia-btn end-dia" onClick={() => { handleEndDialignBtn() }}>

                                    <svg width="15px" height="15px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1" y="1" width="14" height="14" />
                                    </svg>
                                    End Dialing
                                </button>
                            ) : (
                                <></>
                            )}

                            {StartDialignbtn && IsMiceAccess ? (
                                <button className="recording startrecording" onClick={() => { handleStartDialignBtn() }}>
                                    {/* <div className="recording startrecording mr-2"> */}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={15}
                                        height={15}
                                        viewBox="-3 0 28 28"
                                    >
                                        <title>{"play"}</title>
                                        <path
                                            fill="#000"
                                            fillRule="evenodd"
                                            d="M21.415 12.554 2.418.311C1.291-.296 0-.233 0 1.946v24.108c0 1.992 1.385 2.306 2.418 1.635l18.997-12.243a2.076 2.076 0 0 0 0-2.892"
                                        />
                                    </svg>
                                    Start Dialing
                                    {/* </div> */}
                                </button>
                            ) : (
                                <></>
                            )}

                        </div>
                    </div>
                </div>
            </>
            ) : (
                <></>
            )}
            {/* end info header */}

            {/* start step two header */}
            {Step2 ? (
                <div className='steped step-two'>
                    <div className="container">
                        <div>
                            <h3 className='mb-1 pt-2'>Connect to your dial session</h3>
                            <div className="connect-section">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={80}
                                    height={80}
                                    fill="#360947"
                                    className="icon glyph"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M20 18h-.07a1 1 0 0 1-.93-1.07l.36-5A7.38 7.38 0 0 0 6.6 6.35a7.31 7.31 0 0 0-2 5.56l.36 5a1 1 0 0 1-2 .14l-.36-5a9.38 9.38 0 1 1 18.72 0l-.36 5A1 1 0 0 1 20 18Z"
                                        style={{
                                            fill: "#360947",
                                        }}
                                    />
                                    <path
                                        d="M17 11h-1a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h1a4 4 0 0 0 4-4v-3a4 4 0 0 0-4-4ZM8 11H7a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h1a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2Z"
                                        style={{
                                            fill: "#360947",
                                        }}
                                    />
                                </svg>
                                <h3>Use your browser's headset and microphone to connect</h3>
                                <p className="connect-text">The dialer needs access to your microphone to continue. Click to grant access and start calling <b> {DialSessionData?.ContactList[0]?.FirstName || ""} {DialSessionData?.ContactList[0]?.LastName || ""}</b></p>
                                <button className="button w-auto mt-2" onClick={() => { handleMicPermission(token) }}>Grant Mic Access</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {/* end step two header */}

            {/* start step three header */}
            {Step3 ? (
                <div className='steped step-three'>
                    <div className="container">
                        <div className="connect-section">
                            {!IsMiceAccess ? (
                                <>
                                    <div className='card-error'>
                                        <h3 className='mb-1'>We were unable to initialize your session.</h3>
                                        <p>Please Contact support and provided them the error code below.</p>
                                        <a>See this article for a possible solution.</a>
                                        <p>Error #0</p>
                                    </div>
                                </>
                            )
                                : (
                                    <>
                                        <img className='gif-minus' src='/assets/images/check-animation.gif' width="140px" alt="Connected" />
                                        <h3 className='mb-1'>Connected and ready to begin Dialing.</h3>
                                        <label>Click <b>"Start Dialing"</b> above to begin.</label><br /><br />
                                        <small>As the dialer calls each person, their contact information will be displayed. <br /> The first contact that will be called is <b>{DialSessionData?.ContactList[0]?.FirstName || ""} {DialSessionData?.ContactList[0]?.LastName || ""}</b></small>
                                    </>
                                )}

                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {/* end step three header */}

            {/* start step fore header */}
            {Step4 ? (
                <div className='steped step-fore'>
                    <div className="container flex-form px-2">
                        <div className='d-flex mb-3 w-100'>
                            <div className='col w-100 px-0'>
                                <div className='cardboxwhite align-items'>

                                    {/* <div>
                                        <div className="select-option">
                                            <select>
                                                <option>Email Contact Details</option>
                                                <option>Google Search</option>
                                            </select>
                                        </div>
                                    </div> */}

                                    <div className="d-flex">
                                        <div className="phone-list">
                                            {DirectPhone && FormDetails?.DirectPhone != "" && IsGlobalDNCDirectPhone == false && (PageRoleName === "BookMeeting" || IsDirectPhoneBlacklist == false)  && DirectPhoneDoNotCallToday != '1' ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickDirectPhone ? <img src='/assets/images/check-green.svg' width="15px" /> : ""} Direct Phone</span>
                                                    <div className='phone-number'>
                                                        <input type='text' id="DirectPhone" name="DirectPhone" onChange={handleInputChange} onBlur={handleBlur} value={FormDetails?.DirectPhone} />
                                                        {IsGlobalDNCDirectPhone ? <button className="phone-status">DNC</button> : <button className={IsDialcolorbtn && !IsGreenTickDirectPhone ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn && !IsGreenTickDirectPhone ? () => ManualDialBtn(FormDetails?.DirectPhone, "DirectPhone", DirectPhoneTZCode) : null}>
                                                            {IsLoderDirectPhone ? "" : "Dial"}
                                                            {IsLoderDirectPhone ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}
                                                        </button>}


                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {MobilePhone && FormDetails?.MobilePhone != "" && IsGlobalDNCMobilePhone == false && (PageRoleName === "BookMeeting" || IsMobilePhoneBlacklist == false)  && MobilePhoneDoNotCallToday != '1' ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickLoderMobilePhone ? <img src='/assets/images/check-green.svg' width="15px" /> : ""} Mobile Phone</span>
                                                    <div className='phone-number'>
                                                        <input type='text' id="MobilePhone" name="MobilePhone" onChange={handleInputChange} onBlur={handleBlur} value={FormDetails?.MobilePhone} />
                                                        {IsGlobalDNCMobilePhone ? <button className="phone-status">DNC</button> :
                                                            <button className={IsDialcolorbtn && !IsGreenTickLoderMobilePhone ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn && !IsGreenTickLoderMobilePhone ? () => ManualDialBtn(FormDetails?.MobilePhone, "MobilePhone", MobilePhoneTZCode) : null}>
                                                                {IsLoderMobilePhone ? "" : "Dial"}
                                                                {IsLoderMobilePhone ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}
                                                            </button>}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {CompanyPhone && FormDetails?.CompanyPhone != "" && IsGlobalDNCCompanyPhone == false && (PageRoleName === "BookMeeting" || IsCompanyPhoneBlacklist == false)  && CompanyPhoneDoNotCallToday != '1' ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickLoderCompanyPhone ? <img src='/assets/images/check-green.svg' width="15px" /> : ""} Company Phone</span>
                                                    <div className='phone-number'>
                                                        <input type='text' id="CompanyPhone" name="CompanyPhone" onChange={handleInputChange} onBlur={handleBlur} value={FormDetails?.CompanyPhone} />
                                                        {IsGlobalDNCCompanyPhone ? <button className="phone-status">DNC</button> :
                                                            <button className={IsDialcolorbtn && !IsGreenTickLoderCompanyPhone ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn && !IsGreenTickLoderCompanyPhone ? () => ManualDialBtn(FormDetails?.CompanyPhone, "CompanyPhone", CompanyPhoneTZCode) : null}>{IsLoderCompanyPhone ? "" : "Dial"}
                                                                {IsLoderCompanyPhone ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}
                                                            </button>}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {OtherPhone1 && FormDetails?.OtherPhone1 != "" && IsGlobalDNCOtherPhone1 == false && IsOtherPhone1Blacklist == false && OtherPhone1DoNotCallToday != '1' ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickLoderOtherPhone1 ? <img src='/assets/images/check-green.svg' width="15px" /> : ""} Other Phone 1</span>
                                                    <div className='phone-number'>
                                                        <input type='text' id="OtherPhone1" name="OtherPhone1" onChange={handleInputChange} onBlur={handleBlur} value={FormDetails?.OtherPhone1} />
                                                        {IsGlobalDNCOtherPhone1 ? <button className="phone-status">DNC</button> :
                                                            <button className={IsDialcolorbtn && !IsGreenTickLoderOtherPhone1 ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn && !IsGreenTickLoderOtherPhone1 ? () => ManualDialBtn(FormDetails?.OtherPhone1, "OtherPhone1", OtherPhone1TZCode) : null}>{IsLoderOtherPhone1 ? "" : "Dial"}
                                                                {IsLoderOtherPhone1 ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}
                                                            </button>}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {OtherPhone2 && FormDetails?.OtherPhone2 != "" && IsGlobalDNCOtherPhone2 == false && IsOtherPhone2Blacklist == false && OtherPhone2DoNotCallToday != '1' ? (
                                                <div className="phone-item">
                                                    <span className="phone-label">{IsGreenTickLoderOtherPhone2 ? <img src='/assets/images/check-green.svg' width="15px" /> : ""} Other Phone 2</span>
                                                    <div className='phone-number'>
                                                        <input type='text' id="OtherPhone2" name="OtherPhone2" onChange={handleInputChange} onBlur={handleBlur} value={FormDetails?.OtherPhone2} />
                                                        {IsGlobalDNCOtherPhone2 ? <button className="phone-status">DNC</button> :
                                                            <button className={IsDialcolorbtn && !IsGreenTickLoderOtherPhone2 ? "phone-status light-action-btn" : "phone-status"} onClick={IsDialcolorbtn && !IsGreenTickLoderOtherPhone2 ? () => ManualDialBtn(FormDetails?.OtherPhone2, "OtherPhone2", OtherPhone2TZCode) : null}>{IsLoderOtherPhone2 ? "" : "Dial"}
                                                                {IsLoderOtherPhone2 ? <img className='gif-minus' src='/assets/images/three-dots.svg' width="22px" /> : ""}
                                                            </button>}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>


                                        {/* <div>
                                            <button className="add-item"> + </button>
                                        </div> */}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container flex-form">
                        <div className='row w-100 fore-row d-flex mb-3'>
                            <div className='col'>
                                <div className='cardboxwhite'>

                                    <div className="location-header">
                                        <svg width="12px" height="12px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m 8 0 c -3.3125 0 -6 2.6875 -6 6 c 0.007812 0.710938 0.136719 1.414062 0.386719 2.078125 l -0.015625 -0.003906 c 0.636718 1.988281 3.78125 5.082031 5.625 6.929687 h 0.003906 v -0.003906 c 1.507812 -1.507812 3.878906 -3.925781 5.046875 -5.753906 c 0.261719 -0.414063 0.46875 -0.808594 0.585937 -1.171875 l -0.019531 0.003906 c 0.25 -0.664063 0.382813 -1.367187 0.386719 -2.078125 c 0 -3.3125 -2.683594 -6 -6 -6 z m 0 3.691406 c 1.273438 0 2.308594 1.035156 2.308594 2.308594 s -1.035156 2.308594 -2.308594 2.308594 c -1.273438 -0.003906 -2.304688 -1.035156 -2.304688 -2.308594 c -0.003906 -1.273438 1.03125 -2.304688 2.304688 -2.308594 z m 0 0" fill="#2e3436" />
                                        </svg> Located in <span>{TimeZone}</span> where the local time is <span>{CurrentTime}</span>
                                    </div>
                                    <input type="hidden" id='hdncontactid' ref={contactIdRef} value={ID} />
                                    <div className="form-group">
                                        <div className="form-item">
                                            <label className="form-label" htmlFor="firstName">First Name</label>
                                            <input type="text" id="firstName" name="FirstName" onChange={handleInputChange} value={FormDetails?.FirstName} className="form-input" />
                                        </div>
                                        <div className="form-item">
                                            <label className="form-label" htmlFor="lastName">Last Name</label>
                                            <input type="text" id="lastName" name="LastName" onChange={handleInputChange} value={FormDetails?.LastName} className="form-input" />
                                        </div>
                                    </div>
                                    <div className="form-group w-100">
                                        <div className="form-item w-100">
                                            <label className="form-label" htmlFor="email">Email</label>
                                            <input type="email" name="Email" onChange={handleInputChange} value={FormDetails?.Email} className="full-width" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-item w-100">
                                            <label className="form-label" htmlFor="Address1">Address </label>
                                            <input type="text" name="Address1" onChange={handleInputChange} value={FormDetails?.Address1} className="full-width" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-item">
                                            <label className="form-label" htmlFor="city">City</label>
                                            <input type="text" name="City" onChange={handleInputChange} value={FormDetails?.City} />
                                        </div>
                                        <div className="form-item">
                                            <label className="form-label" htmlFor="state">State</label>
                                            <input type="text" name="State" onChange={handleInputChange} value={FormDetails?.State} />
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <select>
                                            <option>Bad Data</option>
                                            <option>Meeting Set</option>
                                            <option>DNC</option>
                                        </select>
                                        <input type="text" value={FormDetails?.Zip} />
                                    </div> */}
                                    {/* <div className="form-group">
                                        <textarea placeholder="Description here"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Click to add tags" className="full-width" />
                                    </div> */}
                                    <div className="note-section">
                                        <label className="form-label" htmlFor="city">Note</label>
                                        <div className='d-flex gap-10 align-items-baseline'>
                                            <textarea placeholder="Add a note" value={AddNotes} onChange={(e) => SetAddNotes(e.target.value)} onKeyDown={HandleShiftEnter}></textarea>
                                            <button className='button w-auto' onClick={SaveNotes}>Add</button>
                                        </div>
                                        <div className="note">
                                            <div className="note-header">
                                                <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11.8107L14.4697 13.5303C14.7626 13.8232 15.2374 13.8232 15.5303 13.5303C15.8232 13.2374 15.8232 12.7626 15.5303 12.4697L12.5303 9.46967C12.3897 9.32902 12.1989 9.25 12 9.25C11.8011 9.25 11.6103 9.32902 11.4697 9.46967L8.46967 12.4697C8.17678 12.7626 8.17678 13.2374 8.46967 13.5303C8.76256 13.8232 9.23744 13.8232 9.53033 13.5303L11.25 11.8107V17C11.25 17.4142 11.5858 17.75 12 17.75ZM8 7.75C7.58579 7.75 7.25 7.41421 7.25 7C7.25 6.58579 7.58579 6.25 8 6.25H16C16.4142 6.25 16.75 6.58579 16.75 7C16.75 7.41421 16.4142 7.75 16 7.75H8Z" fill="#000" />
                                                </svg>
                                            </div>
                                            <div className="note-content">
                                                <div dangerouslySetInnerHTML={{ __html: CallNotes }} />
                                            </div>
                                        </div>

                                        {/* <div className="note">
        <div className="note-header">
            <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11.8107L14.4697 13.5303C14.7626 13.8232 15.2374 13.8232 15.5303 13.5303C15.8232 13.2374 15.8232 12.7626 15.5303 12.4697L12.5303 9.46967C12.3897 9.32902 12.1989 9.25 12 9.25C11.8011 9.25 11.6103 9.32902 11.4697 9.46967L8.46967 12.4697C8.17678 12.7626 8.17678 13.2374 8.46967 13.5303C8.76256 13.8232 9.23744 13.8232 9.53033 13.5303L11.25 11.8107V17C11.25 17.4142 11.5858 17.75 12 17.75ZM8 7.75C7.58579 7.75 7.25 7.41421 7.25 7C7.25 6.58579 7.58579 6.25 8 6.25H16C16.4142 6.25 16.75 6.58579 16.75 7C16.75 7.41421 16.4142 7.75 16 7.75H8Z" fill="#000" />
            </svg>
        </div>
        <div className="note-content">
            <h3>TESTING T. <span>Aug 30, 2024, 4:36 AM</span></h3>
            -- 08/27/2024 @ 3:34 AM MDT by TESTING TESTING -- (601) 401-8752 -- Bad Data<br />
            -- 08/27/2024 @ 3:33 AM MDT by TESTING TESTING -- Hi Paiul
        </div>
    </div> */}
                                    </div>

                                </div>
                            </div>
                            <div className='col'>
                                <div className=''>


                                    <div className="warpper">
                                        <Box className='tabdes' sx={{ width: '100%' }}>
                                            <Box>
                                                <Tabs value={value} className="tabs" onChange={handleChange} aria-label="basic tabs example">
                                                    <Tab label="Custom Fields" {...a11yProps(0)} />

                                                    {/* Conditionally render the Phone Script tab if PhoneScriptData has length > 0 */}
                                                    {PhoneScriptData?.length > 0 ? <Tab label="Phone Script" {...a11yProps(1)} /> : null}

                                                    {/* Conditionally set the index for Activity based on PhoneScriptData */}
                                                    <Tab label="Activity" {...a11yProps(PhoneScriptData?.length > 0 ? 2 : 1)} />
                                                </Tabs>
                                            </Box>
                                            <CustomTabPanel value={value} className='panels' index={0}>
                                                <CustomFieldsTab handleInputChange={handleInputChange} FormDetails={FormDetails} />
                                            </CustomTabPanel>
                                            {/* Conditionally render CustomTabPanel for "Phone Script" based on PhoneScriptData */}
                                            {PhoneScriptData?.length > 0 && (
                                                <CustomTabPanel value={value} className="panels" index={1}>
                                                    <PhoneScriptTab PhoneScriptData={PhoneScriptData} ContactDetails={PhoneScriptHtml} EmailAccountDetails={EmailAccountDetails} />
                                                </CustomTabPanel>
                                            )}
                                            {/* Adjust the index for "Activity" based on PhoneScriptData length */}
                                            <CustomTabPanel value={value} className="panels" index={PhoneScriptData?.length > 0 ? 2 : 1}>
                                                <ActivityTab
                                                    ProspectID={FormDetails?.ID}
                                                    UserID={LoggedUserDetails?.UserID}
                                                    ClientID={LoggedUserDetails?.ClientID}
                                                    CreatedBy={LoggedUserDetails?.ColdCallerUserID}
                                                />
                                            </CustomTabPanel>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {/* end step fore header */}
            {!LastStep && Step5 || IsAllDNC ? (
                <div className='foter-fix'>
                    <BottomCallIndicator CallState={CallState} ToNumber={ToNumber} />
                    <div className='button-group'>
                        {/* <CallDialAddBookedMeeting {...FormDetails} {...LoggedUserDetails}/> */}
                        <Popup open={OpenPotentailMeetingModel} modal onClick={ClosePotentailMeetingModel}>
                            <CallDialAddPotentialMeetingPage {...FormDetails} {...LoggedUserDetails} ClosePotentailMeetingModel={ClosePotentailMeetingModel} DisconnectCall={DisconnectCall} PerformNextActionAfterMeeting={PerformNextActionAfterMeeting} />
                        </Popup>
                        <Popup open={OpenBookMeetingModel} modal onClick={CloseBookMeetingModel}>
                            <CallDialAddBookedMeeting {...FormDetails} {...LoggedUserDetails} CloseBookMeetingModel={CloseBookMeetingModel} DisconnectCall={DisconnectCall} PerformNextActionAfterMeeting={PerformNextActionAfterMeeting} CallSid={CallSid}/>
                        </Popup>
                        <Popup open={OpenSendCustomEmailModel} modal onClick={CloseSendCustomEmailModel}>
                            <SendCustomEmailModel {...FormDetails} {...LoggedUserDetails} SDRAssignmentEmail={DialSessionData?.ReminderEmail} ReminderEmailID={DialSessionData?.SDRAssignmentDetails?.ReminderEmailAccountID} CloseSendCustomEmailModel={CloseSendCustomEmailModel} DisconnectCall={DisconnectCall} PerformNextActionAfterMeeting={PerformNextActionAfterMeeting} UpdateCustomEmailSendCount={UpdateCustomEmailSendCount} ProspectDetails={FormDetails} />
                        </Popup>

                        {/* If IsAllDNC is true, only show the "Call next contact" button */}
                        {/* {(isPaused && CallSettled && !IsCallnextcontact) ? <>
                            <button disabled={!LiveAnswerBtn} className="green-btn" onClick={() => { handleStatusBtn(0, "Live Answer", "Live Answer", "", "") }}>Live Answer</button>

                            {DialingStatusOrder.map((el) => (
                                <StatusButton
                                    key={el?.OrderBy}
                                    {...el}
                                    handleStatusBtn={handleStatusBtn}
                                    disabled={AllStatusBtnDisabled}
                                />
                            ))}</> : <>
                            {IsAllDNC ? (
                                <button onClick={() => { handleStatusBtn(0, "Call next contact", "Call next contact", "", "") }}>Call next contact</button>
                            ) : (
                                !IsCallnextcontact ? (
                                    <>

                                        <button disabled={!LiveAnswerBtn} className="green-btn" onClick={() => { handleStatusBtn(0, "Live Answer", "Live Answer", "", "") }}>Live Answer</button>

                                        {DialingStatusOrder.map((el) => (
                                            <StatusButton
                                                key={el?.OrderBy}
                                                {...el}
                                                handleStatusBtn={handleStatusBtn}
                                                disabled={AllStatusBtnDisabled}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <button onClick={() => { handleStatusBtn(0, "Call next contact", "Call next contact", "", "") }}>Call next contact</button>
                                )
                            )}
                        </>
                        } */}

                        {(isPaused && CallSettled) ? <></> : <>
                            {IsAllDNC ? (
                                <button onClick={() => { handleStatusBtn(0, "Call next contact", "Call next contact", "", "") }}>Call next contact</button>
                            ) : (
                                !IsCallnextcontact ? (
                                    <>

                                        <button disabled={!LiveAnswerBtn} className="green-btn" onClick={() => { handleStatusBtn(0, "Live Answer", "Live Answer", "", "") }}>Live Answer</button>

                                        {DialingStatusOrder.map((el) => (
                                            <StatusButton
                                                key={el?.OrderBy}
                                                {...el}
                                                handleStatusBtn={handleStatusBtn}
                                                disabled={AllStatusBtnDisabled}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <button onClick={() => { handleStatusBtn(0, "Call next contact", "Call next contact", "", "") }}>Call next contact</button>
                                )
                            )}
                        </>
                        }

                    </div>
                </div>
            ) : null}


            {/* Live answer if user click on live answer */}
            {/* {
                (!isPaused) ?
                    (!LastStep && !isPaused && CallSettled && IsLiveAnswer && !IsEndButton) ?
                        <><div className='foter-fix'>
                            <BottomCallIndicator CallState={CallState} ToNumber={ToNumber} />
                            <div className='button-group'>
                                <LiveAnswerStaticButtons EndDialDispositionButton={EndDialDispositionButton} SendBackToEndDialButtons={SendBackToEndDialButtons} HoldCallButton={HoldCallButton} disabled={AllStatusBtnDisabled} />
                            </div>
                        </div>
                        </> :
                        (IsEndButton && !IsCallnextcontact) ?
                            <>
                                <div className='foter-fix'>
                                    <BottomCallIndicator CallState={CallState} ToNumber={ToNumber} />
                                    <div className='button-group'>
                                        {LiveAnswerStatusOrder.map((el) => (<LiveAnswerButton key={el?.OrderBy} {...el} handleStatusBtn={handleStatusBtn} disabled={AllStatusBtnDisabled} />))}
                                    </div>
                                </div>
                            </> :
                            (IsEndButton && IsCallnextcontact) ?
                                <>
                                    <button onClick={() => { handleStatusBtn(0, "Call next contact", "Call next contact", "", "") }}>Call next contact</button>
                                </> :
                                <></>
                    :

                    (!LastStep && CallSettled && IsLiveAnswer && !IsEndButton) ?
                        <><div className='foter-fix'>
                            <BottomCallIndicator CallState={CallState} ToNumber={ToNumber} />
                            <div className='button-group'>
                                <LiveAnswerStaticButtons EndDialDispositionButton={EndDialDispositionButton} SendBackToEndDialButtons={SendBackToEndDialButtons} HoldCallButton={HoldCallButton} disabled={AllStatusBtnDisabled} />
                            </div>
                        </div>
                        </> :
                        (IsEndButton && !IsCallnextcontact) ?
                            <>
                                <div className='foter-fix'>
                                    <BottomCallIndicator CallState={CallState} ToNumber={ToNumber} />
                                    <div className='button-group'>
                                        {LiveAnswerStatusOrder.map((el) => (<LiveAnswerButton key={el?.OrderBy} {...el} handleStatusBtn={handleStatusBtn} disabled={AllStatusBtnDisabled} />))}
                                    </div>
                                </div>
                            </> :
                            (IsEndButton && IsCallnextcontact) ?
                                <>
                                    <button onClick={() => { handleStatusBtn(0, "Call next contact", "Call next contact", "", "") }}>Call next contact</button>
                                </> :
                                (IsEndButton && !IsLiveAnswer && IsCallnextcontact) ?
                                    <>
                                        <button onClick={() => { handleStatusBtn(0, "Call next contact", "Call next contact", "", "") }}>Call next contact</button>
                                    </> :
                                    <> </>

            } */}
            {
                (!LastStep && isPaused && CallSettled) ? <></> : <>
                    {IsLiveAnswer ? (<>
                        <div className='foter-fix'>
                            <BottomCallIndicator CallState={CallState} ToNumber={ToNumber} />
                            <div className='button-group'>
                                <LiveAnswerStaticButtons EndDialDispositionButton={EndDialDispositionButton} SendBackToEndDialButtons={SendBackToEndDialButtons} HoldCallButton={HoldCallButton} disabled={AllStatusBtnDisabled} />
                            </div>
                        </div>
                    </>) : IsEndButton ? <>{!IsCallnextcontact ? (
                        <div className='foter-fix'>
                            <BottomCallIndicator CallState={CallState} ToNumber={ToNumber} />
                            <div className='button-group'>
                                {LiveAnswerStatusOrder.map((el) => (<LiveAnswerButton key={el?.OrderBy} {...el} handleStatusBtn={handleStatusBtn} disabled={AllStatusBtnDisabled} />))}
                                
                            </div>
                        </div>
                    ) : (<button onClick={() => { handleStatusBtn(0, "Call next contact", "Call next contact", "", "") }}>Call next contact</button>)}</> : <></>}
                </>
            }

            {
                (!LastStep && isPaused && CallSettled) ? <>
                    <div className='foter-fix'>
                        <div className='button-group'>
                           
                            <button onClick={() => { handleStatusBtn(0, "Call next contact", "Call next contact", "", "") }}>Call next contact</button>
                        </div>
                    </div>
                </> : <></>
            }


            {LastStep ? (
                <div className='steped step-six'>
                    <div className="container">
                        <div className="timeboxmain">
                            <h3>Way to go!</h3>
                            <img className='maintime' src="/assets/images/timeicon.svg" width="66px" />
                            <p>You saved an estimated</p>
                            <h4>{DurationFormatter(DurationsInSec)}</h4>

                            <div className="d-flex time-flex">
                                <div className="time-item">
                                    <img src="/assets/images/duration.svg" width="30px" />
                                    <span className="light">Duration</span>
                                    <h5>{DurationFormatter(DurationsInSec)}</h5>
                                </div>
                                <div className="time-item">
                                    <img src="/assets/images/user-icons.svg" width="30px" />
                                    <span className="light">Contacts</span>
                                    <h5>{StartCallingCount}</h5>
                                </div>
                                <div className="time-item">
                                    <img src="/assets/images/telephone-icon.svg" width="28px" />
                                    <span className="light">Calls</span>
                                    <h5>{TotalCalls}</h5>
                                </div>
                                <div className="time-item">
                                    <img src="/assets/images/talks.svg" width="30px" />
                                    <span className="light">Talks</span>
                                    <h5>{TalksCount}</h5>
                                </div><div className="time-item">
                                    <img src="/assets/images/E-mails.svg" width="30px" />
                                    <span className="light">E-mails</span>
                                    <h5>{EmailCount}</h5>
                                </div>
                                <div className="time-item">
                                    <img src="/assets/images/Voicemalls.svg" width="30px" />
                                    <span className="light">Voicemails</span>
                                    <h5>{VoicemailCount}</h5>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container mt-1">
                        {/* <button className="button-border w-auto float-left">View Details</button> */}
                        <button className="button w-auto float-right" onClick={() => { handleCallDoneBtn() }} >Done</button>
                    </div>
                </div>
            ) : (<></>)}
        </>
    );
}


// Status Button Component
const StatusButton = ({ OrderBy, DisplayName, CallStatus, NextActionName, MoveToDetails, ActionName1, ActionName2, ActionName3, VoiceMailRecordingID, DispositionEmailID, Duration, IsCountConnection, ContactStatusDetails, handleStatusBtn, disabled }) => {
    return (
        <button disabled={disabled} onClick={() => handleStatusBtn(OrderBy, DisplayName, CallStatus, NextActionName, MoveToDetails, ActionName1, ActionName2, ActionName3, VoiceMailRecordingID, DispositionEmailID, Duration, IsCountConnection, ContactStatusDetails)}>{DisplayName}</button>
    );
};

// Live Answer Buttons [Call End,Transfer call, Hold Call , Go Back]
const LiveAnswerStaticButtons = ({ EndDialDispositionButton, SendBackToEndDialButtons, HoldCallButton }) => {
    const [isHeld, setIsHeld] = useState(false); // State to track hold status
    const [loading, setLoading] = useState(false); // Optional: Loading state for the button

    const toggleHoldButton = () => {
        setIsHeld(!isHeld);
        HoldCallButton(!isHeld)
    }
    return (
        <>
            <button className="red-btn" onClick={EndDialDispositionButton}>{BoxIcon} End call</button>
            <button>{TransferIcon} Transfer call</button>
            {/* <button onClick={HoldCallButton}>Hold call</button> */}
            <button onClick={toggleHoldButton} disabled={loading}>
                {loading ? 'Processing...' : isHeld ? 'Unhold Call' : 'Hold Call'}
            </button>
            <button onClick={SendBackToEndDialButtons}>{BackIcon} Go Back</button>

        </>
    )
}

// Live Answer Button Component Dispostions {End Call}
const LiveAnswerButton = ({ OrderBy, DisplayName, CallStatus, NextActionName, MoveToDetails, ActionName1, ActionName2, ActionName3, VoiceMailRecordingID, DispositionEmailID, Duration, IsCountConnection, ContactStatusDetails, handleStatusBtn, disabled }) => {
    return (
        <button disabled={disabled} onClick={() => handleStatusBtn(OrderBy, DisplayName, CallStatus, NextActionName, MoveToDetails, ActionName1, ActionName2, ActionName3, VoiceMailRecordingID, DispositionEmailID, Duration, IsCountConnection, ContactStatusDetails)}>{DisplayName}</button>
    );
}

// Activity Tab
const ActivityTab = ({ ProspectID, UserID, ClientID, CreatedBy }) => {
    const [DialerActivity, SetDialerActivity] = useState([]);
    const [DateFilter, SetDateFilter] = useState("Last7Days");
    const [ActivityDetails, SetActivityDetails] = useState([]);
    const [ActivityName, SetActivityName] = useState("");

    useEffect(() => {
        GetDialerActivity();
    }, [ProspectID]);

    useEffect(() => {
        GetActivityTableDetails()
    }, [DateFilter])

    // Getting Dialer Activity Details
    const GetDialerActivity = async () => {
        try {
            const ApiResponses = await Axios.post(CommonConstants.MOL_APIURL + "/twiliocalling/GetDialerActivity", {});
            // Check if the API call was successful
            if (ApiResponses.data.StatusMessage === "SUCCESS") {
                SetDialerActivity(ApiResponses.data?.Data)
                SetActivityName(ApiResponses.data?.Data[0]?.FieldName)
            }
        } catch (err) {
            // Log any errors that occur during the API call
            console.error('Error while getting dialer activity:', err);
        }
    }

    // Get Activity table details
    const GetActivityTableDetails = async () => {
        try {
            const RequestBody = {
                ClientID: ClientID,
                ProspectID: ProspectID,
                DateFilter: DateFilter
            }
            const ApiResponses = await Axios.post(CommonConstants.MOL_APIURL + "/twiliocalling/GetCallActivity", RequestBody);
            // Check if the API call was successful
            if (ApiResponses.data.StatusMessage === "SUCCESS") {
                SetActivityDetails(ApiResponses.data?.Data)
            }
        } catch (err) {
            console.err("Error while fetching activity details")
        }
    }

    //Add Activity details in table
    const AddActivityDetails = async () => {
        try {
            let RequestBody = {
                ClientID, UserID, ProspectID, CreatedBy, ActivityName
            }
            const ApiResponses = await Axios.post(CommonConstants.MOL_APIURL + "/twiliocalling/SaveCallActivity", RequestBody);
            // Check if the API call was successful
            if (ApiResponses.data.StatusMessage === "SUCCESS") {
                await GetActivityTableDetails();
            }
        } catch (err) {
            console.error("Error while adding activity details")
        }
    }

    return (
        <>
            <div className="filter">
                <select onChange={(e) => { SetDateFilter(e.target.value) }}>
                    <option value="Last7Days">Last 7 days</option>
                    <option value="Last30Days">Last 30 days</option>
                    <option value="Last3Months">Last 3 Months</option>
                    <option value="Last6Months">Last 6 Months</option>
                    <option value="Last12Months">Last 12 Months</option>
                </select>
            </div>
            <div className="filter">
                <select onChange={(e) => { SetActivityName(e?.target?.value) }} value={ActivityName}>
                    {
                        DialerActivity?.map((el) => (
                            <option key={el?.FieldID} value={el?.FieldName}>{el?.FieldName}</option>
                        ))
                    }
                </select>

                <button className="log-activity" onClick={AddActivityDetails}>+ Log Activity</button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Activity</th>
                        <th>Date/Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ActivityDetails?.map((el) => (
                            <tr>
                                <td>You called {el?.FirstName} {el?.LastName}</td>
                                <td>{moment(el?.CreatedDate).format('MMM DD, YYYY hh:mm a')}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

// Custom Fields Tab
const CustomFieldsTab = ({ handleInputChange, FormDetails }) => {
    const fields = [
        { id: "address1", label: "Address 1", name: "Address1" },
        { id: "address2", label: "Address 2", name: "Address2" },
        { id: "city", label: "City", name: "City" },
        { id: "state", label: "State", name: "State" },
        { id: "zip", label: "Zip", name: "Zip" },
        { id: "country", label: "Country", name: "Country" },
        { id: "ext", label: "Ext.", name: "DirectPhoneExt" },
        { id: "company", label: "Company", name: "Company" },
        { id: "website", label: "Website", name: "Website" },
        { id: "title", label: "Title", name: "Title" },
        { id: "industry", label: "Industry", name: "Industry" },
        { id: "linkedin", label: "LinkedIn", name: "LinkedInURL" },
        { id: "clientrep", label: "Client Rep", name: "SalesRep" },
        { id: "honeyscore", label: "Honey Score", name: "HoneyScore" },
    ];

    return (
        <>
            {fields.map(({ id, label, name }) => (
                <div className="form-group" key={id}>
                    <label htmlFor={id}>{label}</label>
                    <input
                        type="text"
                        id={id}
                        name={name}
                        onChange={handleInputChange}
                        value={FormDetails?.[name] || ""}
                    />
                </div>
            ))}
        </>
    );
};

//Phone Script Tab
const PhoneScriptTab = ({ PhoneScriptData, ContactDetails, EmailAccountDetails }) => {
    const replaceVariables = (script, variables) => {
        return script.replace(/{(.*?)}/g, (match) => variables[match] || match);
    };

    const PhoneScriptVariableData = () => {
        if (PhoneScriptData.length === 0) return "";

        let strPhoneScriptData = PhoneScriptData[0].ScriptBody;

        const contactDetailsMap = {
            "{FirstName}": ContactDetails?.FirstName || "",
            "{LastName}": ContactDetails?.LastName || "",
            "{Email}": ContactDetails?.Email || "",
            "{Title}": ContactDetails?.Title || "",
            "{Company}": ContactDetails?.Company || "",
            "{CompanyNameLong}": ContactDetails?.CompanyNameLong || "",
            "{Website}": ContactDetails?.Website || "",
            "{CompanyPhone}": ContactDetails?.CompanyPhone || "",
            "{LinkedInURL}": ContactDetails?.LinkedInURL || "",
            "{Industry}": ContactDetails?.Industry || "",
            "{Address1}": ContactDetails?.Address1 || "",
            "{Address2}": ContactDetails?.Address2 || "",
            "{City}": ContactDetails?.City || "",
            "{State}": ContactDetails?.State || "",
            "{Zip}": ContactDetails?.Zip || "",
            "{Country}": ContactDetails?.Country || "",
            "{ContactCategory}": ContactDetails?.ContactCategory || "",
            "{AccountCategory}": ContactDetails?.AccountCategory || "",
            "{DirectPhoneExt}": ContactDetails?.DirectPhoneExt || "",
        };

        const emailDetailsMap = {
            "{AliasName}": EmailAccountDetails?.AliasName || "",
            "{AliasTitle}": EmailAccountDetails?.AliasTitle || "",
            "{AliasEmail}": EmailAccountDetails?.AliasEmail || "",
        };

        // Merge contact and email details maps
        const variablesMap = { ...contactDetailsMap, ...emailDetailsMap };

        try {
            return replaceVariables(strPhoneScriptData, variablesMap);
        } catch (error) {
            console.error("Error processing phone script:", error);
            return strPhoneScriptData;
        }
    };

    return (
        <div
            className="phone-script-main"
            dangerouslySetInnerHTML={{ __html: PhoneScriptVariableData() }}
        />
    );
};

// Bottom Call Indicator
const BottomCallIndicator = ({ CallState, ToNumber }) => {
    const callMessages = {
        Connected: { message: `Dialing ${ToNumber}`, style: "alert-warning" },
        Accepted: { message: `Connected to ${ToNumber}`, style: "alert-success" },
        EndCallButtonPressed: { message: "Your call has ended.", style: "alert-danger" },
        Disconnected: { message: "Your call has ended.", style: "alert-danger" },
    };

    const callIndicator = callMessages[CallState];

    return callIndicator ? (
        <div className={`call-dailling-alert alert ${callIndicator.style}`} role="alert">
            {callIndicator.message}
        </div>
    ) : null;
};
