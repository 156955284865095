import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
const Moment = require("moment");
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";


import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import contactlistloadingicon from "../../../images/loading.gif";
import IsAddListLoding from "../../../images/loading.gif";


export default function AccountAllTable({ handClose, CampaignId, onDataFromContact,updateFromChild }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ContactListData, SetContactListData] = React.useState([]);
  const [sortedColumn, setSortedColumn] = React.useState("Name");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoadingAddSa,SetIsLoadingAddSa] = React.useState(true)

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ListGet(GetUserData.ClientID, GetUserData.ParentUserID, GetUserData.Role);

  }, [Search, Page, RowsPerPage, handClose]);

  //get contact list
  const ListGet = (CID, UID, Role) => {
    SetIsLoadingAddSa(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const listDatas = Axios({
      url: CommonConstants.MOL_APIURL + "/list/ListGet",
      method: "POST",
      data: InputParameter,
    });
    listDatas.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoadingAddSa(false)
    });

  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);

    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);

    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);

  };

  // all check handler
  const CheckHandler = (e) => {
    {
      Rows.map((item) => {
        if (e.target.checked) {
          document.getElementById(item.ListID).checked = true;
        } else if (!e.target.checked) {
          document.getElementById(item.ListID).checked = false;
        }
      })
    }

  }

  //Add list contact
  const CheckBoxAdd = (e, ID) => {

    if (e.target.checked) {
      ContactListData.push({ ListID: ID });

    } else if (!e.target.checked) {
      var newArray = []
      const array = ContactListData.filter(function (val) {
        return val.ListID !== ID
      })

      SetContactListData(array)
      newArray.push({ ListID: ID });
      SetContactListData(newArray);

    }
  };



  //Add and Update list contact with multiple check
  const SaveListBtn = () => {

    let IsMoveExistToCurrentCampaign = document.getElementById("IsMoveExistToCurrentCampaign").checked;
    if (ContactListData.length > 0) {
      SetIsLoadingAddSa(true)
      var data = {
        CheckedArrayList: ContactListData,
        CampaignID: CampaignId,
        UserID: UserID,
        ClientID: ClientID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        IsMoveExistToCurrentCampaign: IsMoveExistToCurrentCampaign
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/prospect_campaign/CampaginAddToListsFromContact",
        method: "POST",
        data: data,
      }).then((Res) => {
        console.log("Res.data", Res.data)
        if (Res.data?.StatusMessage == "SUCCESS") {
          toast.success(`Added ${Res.data?.DataSuccess} contact successfully`);
          onDataFromContact(false);
          updateFromChild(CampaignId)
          SetIsLoadingAddSa(false)
        } else {
          toast.error(Res.data.Message);
          onDataFromContact(false);
          updateFromChild(CampaignId)
          SetIsLoadingAddSa(false)
        }
      }).catch((error) => {
        toast.error("An error occurred while saving.");
      })
        .finally(() => {
          // Step 3: Set loading back to false when the request is complete
        
          SetIsLoadingAddSa(false)
        });

    } else {
      toast.error("Please select at least one contacts from list.")
      SetIsLoadingAddSa(false)
    }

  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoadingAddSa(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }

    SetSortField(Field);
    SetSflag(SearchedVal !== "");

      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: Field,
        SortBy: SortBy,
        Search: SearchedVal,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
    
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/list/ListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoadingAddSa(false)
      });

  };


  const popupclose = () => {
    onDataFromContact(false)
  }
  return (
    <div>

      <div className="row">

        <div className="col">
          <label class="d-flex">
            <div class="toggleswich mr-2">
              <input
                type="checkbox"
                class="checktogle"
                id="IsMoveExistToCurrentCampaign"

              />
              <b class="switch">
                <span class="checkion"></span>
                <span class="uncheckion"></span>
              </b>
              <b class="track"></b>
            </div>
            Move existing contacts
            to current Campaign
          </label>
        </div>
      </div>
      {
      IsLoadingAddSa ?   <div id="gfdgfg" className="loding-display">
        <img src={IsAddListLoding} />
      </div> : null}
      <div className="row pb-3 mt-1">
        <div className="col-sm-12 col-md-6 d-flex">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <input name="select_all_contacts" type="checkbox" onChange={(e) =>
                    CheckHandler(e)
                  } />
                </TableCell>
                <TableCell
                    className="px-0"
                    onClick={() => {
                      SortData("Name");
                    }}
                  >
                    Name
                    <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Name"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Name"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                    className="px-0"
                    onClick={() => {
                      SortData("TotalCount");
                    }}
                  >
                    Total Contact
                    <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "TotalCount"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "TotalCount"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                    className="px-0"
                    onClick={() => {
                      SortData("CreatedDate");
                    }}
                  >
                    Created Date
                    <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "CreatedDate"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "CreatedDate"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>


              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? <p>No data available in table</p> : Rows.map((row) => (

                <TableRow>
                  <TableCell>
                    <input
                      type="checkbox"
                      id={row.ListID}
                      onChange={(e) =>
                        CheckBoxAdd(e, row.ListID)
                      }


                    />
                  </TableCell>
                  <TableCell>{row?.Name}</TableCell>
                  <TableCell>{row?.TotalProspect}</TableCell>
                  <TableCell>
                    {Moment(new Date(row.CreatedDate).toDateString()).format(
                      "MM/DD/YYYY"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
        <div className="row pb-5 mb-4">
          <div className="col dataTables_info d-flex ">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>


      <div className="modal-footer px-0">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={popupclose}
        >
          Cancel
        </button>
        <button

          type="button"
          id="popup-close"
          className="btn btn-primary btn-lightgreen"
          onClick={SaveListBtn}
        >
          <i className="la la-save"></i> Save
        </button>
      </div>
    </div>
  );
}
