import React from "react";
import Axios from "axios";

import ContactCustomfield from "../../../_components/user/ContactSettings/CustomField/CustomField";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { connect } from "react-redux";
import { CSVDownloader } from "react-papaparse";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();


class ContactCustomfieldPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientName:"",
      FileName: "",
      ExportData: [],
      Role:null
    };
    this.AddBtn = this.AddBtn.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.Role=Details.Role
    }
    var ContactCustomFieldData = {
      ClientID: this.state.ClientID,
      Role:this.state.Role
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/contactcustom/ContactCustomFieldExport",
      method: "POST",
      data: ContactCustomFieldData,
    }).then((res) => {
      this.setState({ ExportData: res.data.Data });
      this.setState({ClientName:res.data.ClientName.Name});
    });
  }

  //  add button
  AddBtn() {
    history.push("/addcontactcustomfield");
  }

 //Reload page while delete perform
 UpdateFromChild = (value) => {
  if(value == true){
    this.componentDidMount()
  }
}

  // contact custom field export csv
  ExportCsv() {
    toast.success(<div>Data exported successfully.</div>);

  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col px-3 pt-4 pb-3">
                <h4 className="headertitlenop lg-headertitle float-left">Custom Contact Fields</h4> 
              </div> 
              <div className="col"> 
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                    <CSVDownloader className="px-0"
                            data={this.state.ExportData}
                            filename={`${this.state.ClientName}-CustomContactField`}
                            bom={true}
                          >
                      <a onClick={this.ExportCsv} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air" > 
                        <i class="la la-icon-font-size-13 la-download"></i> 
                           <span>Export</span>  
                      </a>
                      </CSVDownloader>
                    </li>
                    <li>
                      <button
                        onClick={this.AddBtn}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols px-0">
              <div className="row">
                <div className="col">
                  <ContactCustomfield updateFromChild={this.UpdateFromChild}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />  
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedContactCustomfieldPage = connect(
  mapState,
  actionCreators
)(ContactCustomfieldPage);
export { connectedContactCustomfieldPage as ContactCustomfieldPage };
