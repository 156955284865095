import React, { useEffect } from 'react';
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { toast } from "react-toastify";
const moment = require('moment');
import Select from "react-select";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import ColdCallingContact from '../../_components/user/ColdCalling/ColdCallingContact';
import ColdCallingContactProperty from '../../_components/user/ColdCalling/ColdCallingContactProperty';

import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import Popup from "reactjs-popup";


class ColdCallingContactPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {


            OpenAddFilter: false,
            OpenEditFilter: false,
            ColdContactFilterList: [],
            IsDefault: true,
            SelectedFilterID: null,
            LastCallResultDropDown: [
                { value: "Never Been Called", label: "Never Been Called" },
                { value: "Vociemail 1", label: "Vociemail 1" },
                { value: "Vociemail 2", label: "Vociemail 2" },
                { value: "Email Only No Voicemail", label: "Email Only No Voicemail" },
                { value: "Not Available", label: "Not Available" },
                { value: "Leave No Message / Email", label: "Leave No Message / Email" },
                { value: "Asked for Info /Follow Up Later", label: "Asked for Info /Follow Up Later" },
                { value: "Not Interested", label: "Not Interested" },
                { value: "Not The Right Person", label: "Not The Right Person" },
            ]
        };

    }
    componentDidMount() {
            window.addEventListener('storage', (event) => {
                    
              if (event.key === 'clientChanged') {
                window.location.reload();
              }
            });
        
        document.title = "Cold Calling Contacts | SalesHive";
        var Details = GetUserDetails();
        if (Details != null) {
            this.setState({ ClientID: Details.ClientID });
            this.setState({ UserID: Details.ChildUserID });
        }
      //  this.ColdCallingContactFilterGet(Details.ClientID, Details.ChildUserID);
    }
    // Getting Filter Dropdown
    async ColdCallingContactFilterGet(ClientID, UserID) {
        const InputParameter = {
            ClientID: ClientID,
            UserID: UserID
        }

        const Result = await Axios({
            url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingContactFilterGetDropDown",
            method: "POST",
            data: InputParameter,
        });

        let ResultData = Result.data.Data;
        ResultData.forEach(async (elem) => {

            if (elem.FilterName == "Default") {
                this.setState({ SelectedFilterID: elem.ColdCallingContactFilterID });

            }
        })

        this.setState({
            ColdContactFilterList: Result.data.Data,
        });
        this.GetDefaultFilterProperties(this.state.SelectedFilterID)

    }

    // Get Default filter properties 
    async GetDefaultFilterProperties(FilterID) {

        const InputParameters = {
            FilterID: FilterID
        }

        const Row = await Axios({
            url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/GetColdCallingContactFilterProperty",
            method: "POST",
            data: InputParameters,
        });

    }

    //Filter Name drop down handler
    ColdCallingDropDownHandler() {
        let FilterDropDownName = document.getElementById('FilterDropDown').value;
        if (FilterDropDownName == 0) {
            toast.error(<div className="toastsize">Please select filter.</div>);
            this.setState({ SelectedFilterID: FilterDropDownName });
        } else {

            let indexed = this.state.ColdContactFilterList.findIndex(v => v.ColdCallingContactFilterID == FilterDropDownName);

            this.setState({ IsDefault: this.state.ColdContactFilterList[indexed].IsDefault });
            this.setState({ SelectedFilterID: FilterDropDownName })
            this.GetDefaultFilterProperties(FilterDropDownName)
        }

    }

    //Open Add Filter
    OpenAddFilterModel() {
        this.setState({ OpenAddFilter: true })
    }

    //Open Edit Filter
    OpenEditFilterModel() {
        this.setState({ OpenEditFilter: true })
    }

    //Close Filter Models
    CloseFilterModels() {
        this.setState({ OpenAddFilter: false })
        this.setState({ OpenEditFilter: false })
    }

    // Get custom column 
    GetCustomColumn() {
        let Arr = [
            { FieldValue: "AccountCategory", ColumnName: "Account Category", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "AccountName", ColumnName: "Account Name", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "AccountType", ColumnName: "Account Type", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Address1", ColumnName: "Address1", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Address2", ColumnName: "Address2", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "CallCount", ColumnName: "Call Count", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "City", ColumnName: "City", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ClientPOC", ColumnName: "Client POC", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Company", ColumnName: "Company", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "CompanyPhone", ColumnName: "Company Phone", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ContactCategory", ColumnName: "Contact Category", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ContactOwner", ColumnName: "Contact Owner", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ContactSource", ColumnName: "Contact Source", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ContactStatus", ColumnName: "Contact Status", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Country", ColumnName: "Country", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "CreatedDate", ColumnName: "Created Date", Expression: "EqualTo", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "DaysSinceLastCall", ColumnName: "Days Since Last Call", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "TotalSent", ColumnName: "Deliveries", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "DirectPhone", ColumnName: "Direct Phone", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "DirectPhoneExt", ColumnName: "Direct Phone Ext", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Email", ColumnName: "Email", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "TotalHardBounce", ColumnName: "Total Hard Bounce", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Industry", ColumnName: "Industry", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "IsBlacklisted", ColumnName: "IsBlacklisted", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "LastCallDate", ColumnName: "Last Call Date", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "LastCallResult", ColumnName: "Last Call Result", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "LastSendDate", ColumnName: "Last Send Date", Expression: "EqualTo", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "LastValidationDate", ColumnName: "Last Validation Date", Expression: "EqualTo", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "LinkedInURL", ColumnName: "LinkedIn URL", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ListName", ColumnName: "List Name", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "MobilePhone", ColumnName: "Mobile Phone", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Name", ColumnName: "Name", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "TotalOpen", ColumnName: "Open", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ReasonUnqualified", ColumnName: "Reason Unqualified", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "TotalReply", ColumnName: "Replies", Expression: "EqualTo", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ColdCallersName", ColumnName: "Sales Development Reps", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ClientRep", ColumnName: "Sales Rep", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "TotalSoftBounce", ColumnName: "Soft Bounce", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "State", ColumnName: "State", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ContactTag", ColumnName: "Tag", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "TimeZone", ColumnName: "Time Zone", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Title", ColumnName: "Title", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "TotalCalls", ColumnName: "Total Calls", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "ValidationScore", ColumnName: "Validation Score", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Website", ColumnName: "Website", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "Zip", ColumnName: "Zip", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
            { FieldValue: "FollowUpDate", ColumnName: "Follow Up Date", Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "" },
        ];

        let ChangedArr = [];
        Arr.forEach((el, i) => {
            if (el.FieldValue == "CreatedDate" || el.FieldValue == "LastCallDate" || el.FieldValue == "FollowUpDate" || el.FieldValue == "LastSendDate" || el.FieldValue == "LastValidationDate") {
                ChangedArr.push({ id: i, FieldValue: el.FieldValue, ColumnName: el.ColumnName, Expression: "EqualTo", FilterPropertyValue: "", FilterPropertyValue1: "", FieldFor: "", ContactTagIds: "", IsCustom: false, IsDate: true, NullCondition: false, IsNum: false, IsTag: false, IsBool: false, IsTimeZone: false })
            } else {
                if (el.IsCustom == true) {
                    ChangedArr.push({ id: i, FieldValue: el.FieldValue, ColumnName: el.ColumnName, Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "", FieldFor: "Text", ContactTagIds: "", IsCustom: true, IsDate: false, NullCondition: false, IsNum: false, IsTag: false, IsBool: false, IsTimeZone: false })
                } else {
                    if (el.FieldValue == "ContactTag") {
                        ChangedArr.push({ id: i, FieldValue: el.FieldValue, ColumnName: el.ColumnName, Expression: "EqualTo", FilterPropertyValue: "", FilterPropertyValue1: "", FieldFor: "", ContactTagIds: "", IsCustom: false, IsDate: false, NullCondition: false, IsNum: false, IsTag: true, IsBool: false, IsTimeZone: false })

                    } else if (el.FieldValue == "TimeZone") {

                        ChangedArr.push({ id: i, FieldValue: el.FieldValue, ColumnName: el.ColumnName, Expression: "Contains", FilterPropertyValue: "", FilterPropertyValue1: "", FieldFor: "", ContactTagIds: "", IsCustom: false, IsDate: false, NullCondition: false, IsNum: false, IsTag: false, IsBool: false, IsTimeZone: true })
                    }

                }
            }
        });
    }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                    <Sidebar className="" /> 
                    <div className="bodyhome"> 
                        {/* <div className="row">
                            <div class="col">
                            <h4 class="headertitlebd px-0 py-4 mt-0">Cold Calling Contacts </h4>
                            </div>
                        </div> 
{/* 
                            <div className="row"> 
                                <div className="col"> 
                                    <div class="paddcols pt-0"> */}
                                        <ColdCallingContact /> 
                                     {/*</div>
                                </div> 
                            </div> */}
                    </div>
                </div>
                <Footer /> 
               
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedColdCallingContactPage = connect(mapState, actionCreators)(ColdCallingContactPage);
export { connectedColdCallingContactPage as ColdCallingContactPage };