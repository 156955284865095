import React from "react";
import { connect } from "react-redux";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import MeetingStatuses from "../../../_components/user/MeetingStatuses/MeetingStatuses";

import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";

class MeetingStatusesPage extends React.Component {
  componentDidMount() {
    window.addEventListener('storage', (event) => {
            
      if (event.key === 'clientChanged') {
        window.location.reload();
      }
    });
}
  // add button
  AddBtn() {
    history.push("/addmeetingstatuses");
  }

  // set order meeting status button
  MeetingStatusSetOrder() {
    history.push("/meetingstatusesorder");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle float-left">Meeting Statuses</h4>
              </div> 
              <div className="col">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a
                        onClick={this.MeetingStatusSetOrder.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 flaticon-settings"></i>
                        <span>Set Order</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.AddBtn.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="paddcols">
              <div className="row">
                <div className="col">
                  <MeetingStatuses />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer /> 
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedMeetingStatusesPage = connect(
  mapState,
  actionCreators
)(MeetingStatusesPage);
export { connectedMeetingStatusesPage as MeetingStatusesPage };
