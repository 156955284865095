import React, { useEffect } from 'react';
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import StyleHeader from "../StickyHeader/StickyHeader";

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
        <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
        <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}


export default function AccountAllTable({ ToDate, FromDate }) {
  // export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [SalesRepList, SetSalesRepList] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("PSTCreatedDate");
  const [SortValue, SetSortValue] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ColdCallersID, SetColdCallersID] = React.useState(null);
  const [FromDates, SetFromDates] = React.useState(FromDate);
  const [ToDates, SetToDates] = React.useState(ToDate);
  const [IsIncludeAllStatus, SetIsIncludeAllStatus] = React.useState(true);
  const [LessThan30Sec, SetLessThan30Sec] = React.useState(true);
  const [HasRecording, SetHasRecording] = React.useState(false);
  const [CallResultValue,SetCallResultValue] = React.useState("");
  const [Open, SetOpen] = React.useState(false);
  const [IsLoading,SetIsLoading]= React.useState(true);
  const [CUserID,SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("PSTCreatedDate");
  const [Export, SetExport] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");
  const [CallResult] = React.useState([
    { label: 'DNC', value: 'DNC' },
    { label: 'DNC All', value: 'DNC All' },
    { label: 'DNC for Client', value: 'DNC for Client' },
    { label: 'DNC for All Clients', value: 'DNC for All Clients' },
    { label: 'Never Been Called', value: 'Never Been Called' },
    { label: 'Call Back Later', value: 'Call Back Later' },
    { label: 'Called', value: 'Called' },
    { label: 'Voicemail #1', value: 'Voicemail #1' },
    { label: 'Voicemail 1', value: 'Voicemail 1' },
    { label: 'Voicemail #2', value: 'Voicemail #2' },
    { label: 'Voicemail 2', value: 'Voicemail 2' },
    { label: 'Voicemail #3', value: 'Voicemail #3' },
    { label: 'Voicemail 3', value: 'Voicemail 3' },
    { label: 'Email Only No Voicemail', value: 'Email Only No VoicEmail'},
    { label: 'Not Available', value: 'Not Available' },
    { label: 'Leave No Message / Email', value: 'Leave No Message / Email'},
    { label: 'Asked for Info', value: 'Asked for Info'},
    { label: 'Asked for Info / Follow Up Later', value: 'Asked for Info / Follow Up Later'},
    { label: 'Not Interested / Handled Internally', value: 'Not Interested / Handled Internally' },
    { label: 'Not Interested', value: 'Not Interested' },
    { label: 'Not The Right Person', value: 'Not The Right Person' },
    { label: 'Bad Data / Bad Number', value: 'Bad Data / Bad Number' },
    { label: 'Bad Data', value: 'Bad Data' },
    { label: 'Prospect Hang Up', value: 'Prospect Hang Up' },
    { label: 'Registry Complete + Gen Info', value: 'Registry Complete + Gen Info' },
    { label: 'Send Email Template', value: 'Send Email Template' },
    { label: 'Busy Signal', value: 'Busy Signal' },
    { label: 'Did Not Answer', value: 'Did Not Answer' },
    { label: 'Potential Meeting', value: 'Potential Meeting' },
    { label: 'Send Custom Email', value: 'Send Custom Email' },

  ])
  const [currentAudio, setCurrentAudio] = React.useState(null);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [ContactTag, SetContactTag] = React.useState([]);
  const [TagID, SetTagID] = React.useState(null);
  useEffect(() => {
    document.title = "Call History | SalesHive"
    const Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
      GetColdCallingHistory(Details.ClientID, Details.ParentUserID, Details.Role, FromDate, ToDate);
    }
  }, [FromDate, ToDate, SortValue, SortField, Search, RowsPerPage, Page, ColdCallersID,LessThan30Sec, IsIncludeAllStatus,HasRecording,CallResultValue,TagID]);

  useEffect(()=>{
    const Details = GetUserDetails();
    if (Details != null) {
      GetSalesRep(Details.ParentUserID,Details.ClientID);
    }
  },[])

  useEffect(()=>{
    document.title = "Call History | SalesHive"
    const Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
      ExportHistory(Details.ClientID, Details.ParentUserID, Details.Role,ToDate,FromDate)
    }
  },[FromDate, ToDate,ColdCallersID,LessThan30Sec, IsIncludeAllStatus,HasRecording,CallResultValue])



  // Get SalesRep
  const GetSalesRep = (UID,CID) => {
    const InputParameter = {
      UserID: UID,
      ClientID:CID
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_history/SalesDevelopmentRepsGet",
      method: "POST",
      data: InputParameter
    }).then((Res) => {
      if (Res.data.StatusMessage = "SUCCESS") {
        SetSalesRepList(Res.data.Data);
        SetContactTag(Res.data.TagData)
      }
    });
  }

  //get Cold calling recording  list
  const GetColdCallingHistory = (CID, UID, URole, FromDate, ToDate) => {
    SetIsLoading(true)
    if(FromDate?.trim() != "" && ToDate?.trim() != ""){
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortValue,
        Search: Search,
        Type: "User",
        ClientID: CID == "" ? ClientID : CID,
        UserID: UID == "" ? UserID : UID,
        Role: URole == "" ? Role : URole,
        IncludeAllStatus: IsIncludeAllStatus,
        LessThan30Sec: LessThan30Sec,
        HasRecording: HasRecording,
        ToDate: ToDate,
        FromDate: FromDate,
        ColdCallersID: ColdCallersID,
        CallResultValue: CallResultValue,
        TagID:TagID
  
      };
      const Row = Axios({
        url: CommonConstants.MOL_APIURL + "/cold_calling_history/CallHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      Row.then((Result) => {
        if (Result.data.StatusMessage = "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false)
          setIsDataLoaded(true);
  
        }else{
          SetIsLoading(false)
          setIsDataLoaded(true);
  
        }
      });
    }
   
  };

   //Export CSV
   const ExportCsv = () => {
    toast.success(
      <div className="toastsize">
        Call History <br />
        Data exported successfully.
      </div>
    );
  };

  //Export Data
  const ExportHistory = (ClientID,UserID,Role,toDate,fromDate) => {
    SetIsLoading(true)
    if(fromDate?.trim() != "" && toDate?.trim() != ""){
    var InputParameter = {
      ClientID: ClientID,
      UserID: UserID,
      ToDate: toDate,
      FromDate: fromDate,
      Role: Role,
      ColdCallersID: ColdCallersID,
      IncludeAllStatus: IsIncludeAllStatus,
      LessThan30Sec: LessThan30Sec,
      HasRecording: HasRecording,
      CallResultValue: CallResultValue,
      TagID:TagID
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_history/CallHistoryCSV",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      if (Result.data.StatusMessage = "SUCCESS") {
          SetExport(Result.data.Data)
          SetClientName(Result.data.ClientName?.Name)
      }
    SetIsLoading(false)

    })
  }
  };
  

  const SortData = (Field) => {
    SetIsLoading(true);
    const isNewSortField = Field !== SortField;
    let SortBy = SortValue === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortValue(1);
    } else {
      SetSortValue(SortBy);
    }
    SetSortField(Field);
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal?.trim());
      SetPage(1)

    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

    //Search Box
    const SearchData = () => {
      let IncludeAllStatus = document.getElementById('includeAllStatus').checked;
      let lessthan30s = document.getElementById('lessthan30s').checked;
      let hasRecording = document.getElementById('hasRecording').checked;
      let ColdCallersID = document.getElementById('ColdCallersID').value;
      let CallResultValue = document.getElementById('CallResultValue').value;
      let TagID = document.getElementById('TagID').value;
      SetIsIncludeAllStatus(IncludeAllStatus);
      SetLessThan30Sec(lessthan30s);
      SetHasRecording(hasRecording);
      SetColdCallersID(ColdCallersID);
      SetTagID(TagID);
      SetCallResultValue(CallResultValue);
      SetPage(1);

    }
  
    const Reset=()=>{
      SetIsIncludeAllStatus(false);
      SetLessThan30Sec(false);
      SetHasRecording(false);
      SetColdCallersID(null);
      SetCallResultValue("");
      SetTagID(null);
      SetPage(1);
      SetSearch(""); 
      document.getElementById('includeAllStatus').checked =false;
      document.getElementById('lessthan30s').checked = false;
      document.getElementById('ColdCallersID').value = "";
      document.getElementById('hasRecording').checked=false;
      document.getElementById('CallResultValue').value ="";
      document.getElementById('TagID').value ="";
    }

    const handlePlay = (audio) => {
      if (currentAudio && currentAudio !== audio) {
        currentAudio.pause();
        currentAudio.currentTime = 0; // Optional: reset to the beginning
      }
      setCurrentAudio(audio);
    };

  return (
    <>
    {
      IsLoading === true ?
      <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> :<></>
    }
    <div className="row px-3">
        {/* <div className="col col-lg-4"> */}
          
        {/* </div> */}
      </div>
      <div class='boxborders p-3'>
      <div className="row pb-4 px-3 justify-content-end">
        <div className="mr-4 mb-2">
          <div className="boxborders">
            <label class="check_bx nowrap">
              <input type="checkbox" id="includeAllStatus" defaultChecked /><span className="checkmark"></span>
              Include All Call Results
            </label>
          </div>
        </div>
        <div className="mr-4 mb-2">
          <div className="boxborders">
            <label class="check_bx nowrap">
              <input type="checkbox" id="lessthan30s" defaultChecked /><span className="checkmark"></span>
              Include less than 30secs
            </label>
          </div>
        </div>
        <div className="mb-2">
          <div className="boxborders">
            <label class="check_bx nowrap">
              <input type="checkbox" id="hasRecording" /><span className="checkmark"></span>
              Has Recording
            </label>
          </div>
        </div>
        </div>
      <div className="row mb-4 pb-4 px-2">
        <div class="col d-flex px-2">
        <label class="mt-2 mr-2 nowrap">Tag</label>
          <select class="form-control  m-input mr-3" data-val="true" data-val-number="The field TagID must be a number."
            id="TagID" name="TagID">
            <option value="">--Select--</option>
            {ContactTag.map((row) => (
              <option value={row._id} key={row._id}> {row.Tag}</option>

            ))};

          </select> 
          <label class="mt-2 mr-2 nowrap"> Sales Development Reps</label>
          <select class="form-control  m-input mr-3" data-val="true" data-val-number="The field ColdCallersID must be a number."
            id="ColdCallersID" name="ColdCallersID">
            <option value="">--Select--</option>
            {SalesRepList.map((row) => (
              <option value={row._id} key={row._id}> {row.ColdCallersName} {row.LastName}</option>

            ))};

          </select> 
          <label class="mt-2 mr-2 nowrap"> Call Result</label>
          <select class="form-control  m-input mr-3" data-val="true" data-val-number="The field ColdCallersID must be a number."
            id="CallResultValue" name="CallResultValue">
            <option value="">--Select--</option>
            {CallResult.map((row) => (
              <option value={row.value} key={row.value}> {row.label}</option>

            ))};

          </select> 
          <div className='d-flex align-items-start'>
          <a href="javascript:void(0);" class="btn btn-primary btnColor" onClick={SearchData}><i class="la la-search"></i>Search</a>
          <a href="javascript:void(0);" class="ml-3 btn btn-secondary" onClick={Reset}><i class="la la-refresh"></i> Reset</a>
          </div>

          <div className="listing-li ml-2">
            {console.log(Export)}
            <ul className='mt-0'>
              <li>
              <CSVDownloader
              className="px-0"
              data={Export}
              filename={`${ClientName}-Cold_call_history`}
              bom={true}
            >
                <a onClick={ExportCsv} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-icon-font-size-13 la-download"></i>Export
                </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
     
      </div>
      <div className="row px-3 ">
        <div className="col-sm-12 col-md-6 pl-0">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Call Notes</TableCell>
                
                <TableCell 
                // onClick={()=>{SortData("FirstName"); setSortedColumn("FirstName");}}
                  >First Name</TableCell>
                <TableCell 
                // onClick={()=>{SortData("LastName"); setSortedColumn("LastName");}}
                >Last Name</TableCell>
                <TableCell 
                // onClick={()=>{SortData("Title"); setSortedColumn("Title");}}
                >Title</TableCell>
                <TableCell 
                // onClick={()=>{SortData("Company"); setSortedColumn("Company");}}
                >Company</TableCell>
                <TableCell 
                // onClick={()=>{SortData("Email"); setSortedColumn("Email");}}
                >Email
                {/* <SortingIcons SortedBy={SortValue} sortedColumn={sortedColumn}  ColumnName={"Email"} /> */}
                </TableCell>
                <TableCell 
                // onClick={()=>{SortData("DirectPhone"); setSortedColumn("DirectPhone");}}
                >Direct Phone
                  {/* <SortingIcons SortedBy={SortValue} sortedColumn={sortedColumn}  ColumnName={"DirectPhone"} /> */}
                  </TableCell>
                <TableCell 
                // onClick={()=>{SortData("CompanyPhone"); setSortedColumn("CompanyPhone");}}
                >Company Phone
                {/* <SortingIcons SortedBy={SortValue} sortedColumn={sortedColumn}  ColumnName={"CompanyPhone"} /> */}
                </TableCell>
                <TableCell 
                // onClick={()=>{SortData("Industry"); setSortedColumn("Industry");}}
                >Industry
                  {/* <SortingIcons SortedBy={SortValue} sortedColumn={sortedColumn}  ColumnName={"Industry"} /> */}
                </TableCell>
                <TableCell 
                // onClick={()=>{SortData("Phone"); setSortedColumn("Phone");}}
                >Phone 
                {/* <SortingIcons SortedBy={SortValue} sortedColumn={sortedColumn}  ColumnName={"Phone"} /> */}
                </TableCell>
                <TableCell 
                // onClick={()=>{SortData("ColdCallersName"); setSortedColumn("ColdCallersName");}}
                >Sales Development Reps</TableCell>
                <TableCell>Duration 
                  {/* <SortingIcons SortedBy={SortValue} sortedColumn={sortedColumn}  ColumnName={"Duration"} /> */}
                </TableCell>
                <TableCell onClick={()=>{SortData("PSTCreatedDate"); setSortedColumn("PSTCreatedDate");}}>Call Date / Time <SortingIcons SortedBy={SortValue} sortedColumn={sortedColumn}  ColumnName={"PSTCreatedDate"} /></TableCell>
                <TableCell onClick={()=>{SortData("Status"); setSortedColumn("Status");}}>Result<SortingIcons SortedBy={SortValue} sortedColumn={sortedColumn}  ColumnName={"Status"} /></TableCell>
                <TableCell onClick={()=>{SortData("PlabackUrl"); setSortedColumn("PlabackUrl");}}>Recording <SortingIcons SortedBy={SortValue} sortedColumn={sortedColumn}  ColumnName={"PlabackUrl"} /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (Rows?.map((row) => (
                <React.Fragment key={row._id}>
                  <TableRow  row={row} >
                    <TableCell><IconButton aria-label="expand row" size="small" onClick={() => SetOpen((prev) => ({ ...prev, [row._id]: !prev[row._id], }))}>
                      {Open[row._id] ? (<i className="fa fa-minus-circle minusl" aria-hidden="true"></i>) : (<i className="fa fa-plus-circle plusbl" aria-hidden="true"></i>)}</IconButton>
                    </TableCell>

                    <TableCell>{row.FirstName}</TableCell>
                    <TableCell>{row.LastName}</TableCell>
                    <TableCell>{row.Title}</TableCell>
                    <TableCell>{row.Company}</TableCell>
                    <TableCell>{row.Email}</TableCell>
                    <TableCell>{row.DirectPhone}</TableCell>
                    <TableCell>{row.CompanyPhone}</TableCell>
                    <TableCell>{row.Industry}</TableCell>
                    <TableCell>{row.Phone}</TableCell>
                    <TableCell>{row.ColdCallersName}</TableCell>
                    <TableCell>{row.Duration1}</TableCell>
                    <TableCell>{row.PSTCreatedDate + " PT"}</TableCell>
                    <TableCell>{row.Status}</TableCell>
                    <TableCell>
                      {
                        row.RecordingFileName && <audio controls style={{ outline: 'none', width: '300px' }}  onPlay={(e) => handlePlay(e.target)} type='audio/mp3'>
                        <source src={`${CommonConstants.CallRecoingURL}${row.RecordingFileName}.mp3`} />
                      </audio>
                      }
                  
                  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={15}
                    >
                      <Collapse
                        in={Open[row._id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {Open[row._id] && (
                          <Box margin={1} className="innertables">
                            {!row.CallNotes ? "No notes available" : row.CallNotes}
                          </Box>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )))}

            </TableBody>
          </Table>
        </TableContainer>
      </div>
      
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
        </div>
    </>
  );
}
