import React, { useEffect } from "react";
const moment = require("moment");
import Axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails, GetClientDetails } from "../../../_helpers/Utility";
import { CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Paper from "@material-ui/core/Paper";
import StyleHeader from "../StickyHeader/StickyHeader";

toast.configure();


const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
          <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
          <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [AccountTypeD1, SetAccountTypeD1] = React.useState([]);
  const [HideInactiveAccount, SetHideInactiveAccount] = React.useState(true);
  const [ExportData, SetExportData] = React.useState([]);
  const [ListDData, SetListDData] = React.useState([]);
  const [HeaderDate, SetHeaderDate] = React.useState([]);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState("Name");
  const [ClientName, SetClientName] = React.useState("")
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetClientName(result[0].Name)
    });
    OpenRateReportingClientGet(Details?.ParentUserID);
   
  }, [Search, Page, RowsPerPage]);


  // Client open rate account get
  const OpenRateReportingClientGet = async(Uid) => {
    SetIsLoading(true)
    let InputParameterData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: Uid,
    };
    const rows1 = await Axios({url:CommonConstants.MOL_APIURL +"/open_rate_reporting/OpenRateReportingClientsGet",method: "POST",data: InputParameterData,});
   if( rows1.data.StatusMessage=="SUCCESS"){

     SetData(rows1.data.PageData);
     SetRows(rows1.data.PageData);
     SetRlen(rows1.data.TotalCount);
     SetFlen(rows1.data.TotalCount);
     SetCountPage(rows1.data.PageCount);
     SetHeaderDate(rows1.data.HeaderDates);
     SetIsLoading(false)
     setIsDataLoaded(true);
    }else{
      SetData([]);
     SetRows([]);
     SetRlen(0);
     SetFlen(0);
     SetCountPage(0);
     SetHeaderDate([]);
     SetIsLoading(false)
     setIsDataLoaded(true);
    }

    
  };
  // search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };
  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  // Export csv
  const ExportCsv = async() => {
    SetIsLoading(true)
     setIsDataLoaded(false);
    let InputData = {
      UserID: UserID,
    };
   const Response=await Axios({
                          url:CommonConstants.MOL_APIURL +"/open_rate_reporting/OpenRateReportingClientsExports",
                          method: "POST",
                          data: InputData,
                        });

  if(Response.data.StatusMessage=="SUCCESS"){
      if(Response.data.Data.length > 0){
      const downloader = document.createElement("a");
        const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(Response.data.Data));
        downloader.setAttribute("href", csvContent);
        downloader.setAttribute("download",ClientName+"-OpenRateReporting_Clients");
        downloader.click();
      toast.success(<div>Data exported successfully.</div>);
      SetIsLoading(false)
     setIsDataLoaded(true);
      }else{
        toast.error(<div>No data to export.</div>);
        SetIsLoading(false)
     setIsDataLoaded(true);

      }
    }else{
      toast.error(<div>An Error occurred. Please try again</div>);
      SetIsLoading(false)
     setIsDataLoaded(true);

    }
  };

  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
        const values = headers.map(header => {
            // Check if the value is a string before attempting to replace characters
            const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
            const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
            return `"${escapedValue}"`; // Enclose value in double quotes
        });
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
}
  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //get sort field data
  const SortData = async(Field) => {
    SetIsLoading(true)
    setSortedColumn(Field)
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");

    let InputParameterData = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: SearchedVal || "",
        Type: "User",
        UserID: UserID,
      };

      const rows1 = await Axios({
        url:CommonConstants.MOL_APIURL +"/open_rate_reporting/OpenRateReportingClientsGet",
        method: "POST",
        data: InputParameterData,
      });
      if( rows1.data.StatusMessage=="SUCCESS"){

        SetData(rows1.data.PageData);
        SetRows(rows1.data.PageData);
        SetRlen(rows1.data.TotalCount);
        SetFlen(rows1.data.TotalCount);
        SetCountPage(rows1.data.PageCount);
        SetHeaderDate(rows1.data.HeaderDates);
        SetIsLoading(false)
        setIsDataLoaded(true);
       }else{
         SetData([]);
        SetRows([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetHeaderDate([]);
        SetIsLoading(false)
        setIsDataLoaded(true);
       }
   
    
  };

  return (
    <>
      <div className="row py-3 align-items-center border-bottom mx-0">
        {
          IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
        }
        
        <div className="col">
          <h4 className="headertitlenop xs-headertitle float-left">Clients</h4>
        </div>
        <div className="col">
          <div className="listing-li float-right">
            <ul class='my-0'>
              <li>
               
                  <a
                    onClick={ExportCsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>
                  </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row padt-25 px-0 mx-0">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              class="form-control form-control-sm ml-2"
              placeholder=""
              onKeyPress={RequestSearch}
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div class='px-3'>
      <div className="table-bordered">
      <TableContainer component={Paper}>
      <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell  onClick={() => {SortData("Name");}}>
                  Clients
                  <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Name"} />
              </TableCell>
              <TableCell  onClick={() => { SortData("TrackDomain");}}>
                  Open Tracking Domain
                  <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"TrackDomain"} />
              </TableCell>
              <TableCell  onClick={() => {SortData("BCCEmail");}}>
                  BBC Email
                  <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"BCCEmail"} />
              </TableCell>

              {HeaderDate.map((item) => {
                return (
                  <TableCell>
                    {/* <a
                      onClick={() => {
                        SortData("");
                      }}
                    > */}
                      {item}
                    {/* </a> */}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.length === 0 ? (
              <p className="text-center">No data available in table</p>
            ) : (
              Rows.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.TrackDomain}</TableCell>
                  <TableCell>{row.BCCEmail}</TableCell>
                  <TableCell>{Math.round(row?.TrackDay1OpenRate)}%</TableCell>
                  <TableCell>{Math.round(row?.TrackDay2OpenRate)}%</TableCell>
                  <TableCell>{Math.round(row?.TrackDay3OpenRate)}%</TableCell>
                  <TableCell>{Math.round(row?.TrackDay4OpenRate)}%</TableCell>
                  <TableCell>{Math.round(row?.TrackDay5OpenRate)}%</TableCell>
                  <TableCell>{Math.round(row?.TrackDay6OpenRate)}%</TableCell>
                  <TableCell>{Math.round(row?.TrackDay7OpenRate)}%</TableCell>
                  <TableCell>{Math.round(row?.TrackDay8OpenRate)}%</TableCell>
                  <TableCell>{Math.round(row?.TrackDay9OpenRate)}%</TableCell>
                  <TableCell>{Math.round(row?.TrackDay10OpenRate)}%</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      </div>

        <div class="row mx-0 pb-2">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </>
  );
}
