import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageUploading from "react-images-uploading";

const Fileupload = require("../../_helpers/fileupload");
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import DeliverabilityTesting from "../../_components/user/DeliverabilityTesting/DeliverabilityTesting";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Popup from "reactjs-popup";
import Footer from "../../_components/user/footer/footer";
import Swal from "sweetalert2";

import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";

toast.configure();

class DeliverabilityTestingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      imagesone: [],
      maxNumber: 69,
      ClientID: "",
      UserID: "",
      CUserID: "",
      Pager: {},
      PageOfItems: [],
      Files: [],
      FilesBlukDelete: [],
      List: [],
      Columns: [],
      Reset: false,
      CsvData: [],
      CsvDataBlukDelete: [],
      RawDropDownData: [],
      DropBoxData: [],
      DropBoxData2: [],
      DropBoxDataBlukDelete1: [],
      DropBoxDataBlukDelete2: [],
      FileName: "",
      FileNameBlukDelete: "",
      ShowPopupForExport: false,
      ExportData: [],
      CName: "",
      TotalDisplayCount: false,
      TotalResult: 0,
      TotalDuplicate: 0,
      TotalError: 0,
      TotalSuccess: 0,
      Role: null,
      ResetBlukDelete: false,
      ListUpdate: false,
      ListUpdateBluk: false,
      Subject: null,
      Message: null,
      SubjectIndex: -1,
      MessageIndex: -1,
      OpenSendTest: false,
      IsSubmitting:false
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeone = this.onChangeone.bind(this);
    this.SendTest = this.SendTest.bind(this);
    this.AddDeliverabilityTesting = this.AddDeliverabilityTesting.bind(this);
    this.HandleOnDrop = this.HandleOnDrop.bind(this);
    this.HandleOnError = this.HandleOnError.bind(this);
    this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
    this.ImportCsv = this.ImportCsv.bind(this);
    this.MapCsv = this.MapCsv.bind(this);
    this.CancleCsv = this.CancleCsv.bind(this);
    this.SaveCsv = this.SaveCsv.bind(this);
    this.SaveCsvBlukDelete = this.SaveCsvBlukDelete.bind(this);
    this.HandleOnDropBlukDelete = this.HandleOnDropBlukDelete.bind(this);
    this.HandleOnErrorBlukDelete = this.HandleOnErrorBlukDelete.bind(this);
    this.HandleOnRemoveFileBlukDelete =
      this.HandleOnRemoveFileBlukDelete.bind(this);
    this.ImportCsvBlukDelete = this.ImportCsvBlukDelete.bind(this);
    this.MapCsvBlukDelete = this.MapCsvBlukDelete.bind(this);
    this.CancleCsvBlukDelete = this.CancleCsvBlukDelete.bind(this);
  }
  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csvBlukDelete").style.display = "none";
    document.getElementById("map_attributeBlukDelete").style.display = "none";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
  }
  //Swal Onclic
  SendTestBtn() {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Sending Schedules.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
  }
  //Reload data
  ReloadPage() {
    this.setState({ ListUpdate: true });
  }

  //import csv
  //reset csv
  ResetCsv() {
    this.setState({ Reset: !this.state.Reset });
  }

  //change dropdown
  HandleOnDrop(Data, FileInfo) {
    debugger
    var FileName = FileInfo.name;
    this.setState({ Files: FileInfo });
    var sep = FileName.split(".");
    if (sep[sep.length-1]!= "csv") {
      this.setState({ Reset: true });
      this.ResetCsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      this.setState({ FileName: csvfile });
      this.setState({ CsvData: Data });
      this.setState({ RawDropDownData: Data[0].data });
      console.log(Data[0].data.indexOf("Subject"))
      this.setState({ DropBoxData: Data[0].data });
      this.setState({ DropBoxData2: Data[0].data });
    }
  }

  HandleOnError(Err, File, InputElem, Reason) {}

  HandleOnRemoveFile(Data) {}

  //Suject drop down handler
  SubjectDropDownHandler() {
    var SubjectsIndex = document.getElementById("SubjectImport").value;
    let MainState = this.state.RawDropDownData;
    this.setState({ SubjectIndex: MainState.indexOf(SubjectsIndex) });
    this.setState({ Subject: SubjectsIndex });
    const filteredItems = MainState.filter((item) => item !== SubjectsIndex);

    this.setState({ DropBoxData2: filteredItems });
  }

  //Message drop down handler
  MessageDropDownHandler() {
    var MessagesIndex = document.getElementById("MessageImport").value;
    let MainState = this.state.RawDropDownData;
    this.setState({ MessageIndex: MainState.indexOf(MessagesIndex) });
    this.setState({ Message: MessagesIndex });
    const filteredItems = MainState.filter((item) => item !== MessagesIndex);

    this.setState({ DropBoxData: filteredItems });
  }

  //import csv
  ImportCsv() {
    this.setState({ ListUpdate: false });
    document.getElementById("import_csv").style.display = "block";
    document.getElementById("import_csvBlukDelete").style.display = "none";
    document.getElementById("map_attributeBlukDelete").style.display = "none";
    this.setState({ TotalDisplayCount: false });
    this.setState({ Reset: false });
  }

  //map csv
  MapCsv() {
    if (this.state.CsvData.length != 0) {
      document.getElementById("map_attribute").style.display = "block";
      document.getElementById("ImportCsvBtn").disabled = false;
      document.getElementById("SubjectImport").disabled = false;
      document.getElementById("MessageImport").disabled = false;
      this.setState({ TotalDisplayCount: false });
      this.state.DropBoxData.map((dddd, index) => {
        this.ResetCsv();
      });
    } else {
      toast.error(
        <div className="toastsize">
          Deliverability Testing <br />
          Please select file
        </div>
      );
      this.ResetCsv();
    }
  }



  //cancle Csv
  CancleCsv() {
    this.ResetCsv();
    this.setState({ TotalDisplayCount: false });
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    this.setState({ DropBoxData: [] });
    this.setState({ IsMapDivDisabled: false });
  }

  //add Deliverability Testing
  SaveCsv(e) {
    e.preventDefault();
    this.setState({IsSubmitting:true})
    var Subjects = document.getElementById("SubjectImport").value;
    var Messages = document.getElementById("MessageImport").value;
    if (Subjects === null) {
      toast.error(
        <div className="toastsize">
          Deliverability Testing <br />
          Subject is required!
        </div>
      );
      this.setState({IsSubmitting:false})
    } else if (Messages === "not mapped") {
      toast.error(
        <div className="toastsize">
          Deliverability Testing <br />
          Message is required!
        </div>
      );
      this.setState({IsSubmitting:false})
    } else {
      Fileupload.getBase64(this.state.Files, (Result) => {
        var RreglarString = Result;
        var Splirs = RreglarString.split("base64,");
        var Base64 = Splirs[1];
        var InputParameter = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DeliverabilityTesting",
          File: Base64,
          ImageName: this.state.FileName,
          FileData: this.state.CsvData,
          Subject: this.state.SubjectIndex,
          Message: this.state.MessageIndex,
          ClientID: this.state.ClientID,
          UserID: this.state.UserID,
          CreatedDate:new Date()
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL + "/DeliverAbilityTesting/ImportFileCsv",
          method: "POST",
          data: InputParameter,
        }).then((Res) => {
          if (Res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                Deliverability Testing <br /> Deliverability testing data added
                successfully.
              </div>
            );
            // history.push("/list");
            // document.getElementById("hidelod").style.display = "none";
            this.setState({ IsMapDivDisabled: true });
            this.setState({ ListUpdate: true });
            this.setState({ TotalDisplayCount: true });
            this.setState({ TotalResult: Res.data.Total });
            this.setState({ TotalDuplicate: Res.data.TotalDuplication });
            this.setState({ TotalError: Res.data.TotalError });
            this.setState({ TotalSuccess: Res.data.TotalSuccess });
            document.getElementById("map_attribute").style.display = "block";
            document.getElementById("import_csv").style.display = "block";
            document.getElementById("SubjectImport").disabled = true;
            document.getElementById("MessageImport").disabled = true;
            document.getElementById("ImportCsvBtn").disabled = true;
            this.setState({ CsvData: [] });
            this.setState({IsSubmitting:false})
            // this.ReloadPage();
          } else {
            toast.error(Res.data.Message);
            this.setState({IsSubmitting:false})
          }
        });
      });
    }
  }

  //Bluk Delete
  //reset csv
  ResetCsvBlukDelete() {
    this.setState({ ResetBlukDelete: true });
  }

  //change dropdown Bluk Delete
  HandleOnDropBlukDelete(Data, FileInfo) {
    var FileName = FileInfo.name;
    this.setState({ FilesBlukDelete: FileInfo });

    var sep = FileName.split(".");
    if (HandleOnDrop!= "csv") {
      this.ResetCsvBlukDelete();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      this.setState({ FileNameBlukDelete: csvfile });
      this.setState({ CsvDataBlukDelete: Data });
      this.setState({ RawDropDownData: Data[0].data });
      this.setState({ DropBoxDataBlukDelete1: Data[0].data });
      this.setState({ DropBoxDataBlukDelete2: Data[0].data });
    }
  }

  //map csv Bluk Delete
  MapCsvBlukDelete() {
    if (this.state.CsvDataBlukDelete.length != 0) {
      document.getElementById("map_attributeBlukDelete").style.display =
        "block";
      this.state.DropBoxDataBlukDelete1.map((dddd, index) => {
        this.ResetCsvBlukDelete();
      });
    } else {
      toast.error(<div className="toastsize">Deliverability Testing <br/>Please select file</div>);
      document.getElementById("map_attributeBlukDelete").style.display ="none";
      this.ResetCsvBlukDelete();
    }
  }

    //Suject drop down handler
    SubjectDropDownHandlerDelete() {
      var SubjectsIndex = document.getElementById("SubjectBlukDelete").value;
      let MainState = this.state.RawDropDownData;
      this.setState({ SubjectIndex: MainState.indexOf(SubjectsIndex) });
      this.setState({ Subject: SubjectsIndex });
      const filteredItems = MainState.filter((item) => item !== SubjectsIndex);
  
      this.setState({ DropBoxDataBlukDelete2: filteredItems });
    }
  
    //Message drop down handler
    MessageDropDownHandlerDelete() {
      var MessagesIndex = document.getElementById("MessageBlukDelete").value;
      let MainState = this.state.RawDropDownData;
      this.setState({ MessageIndex: MainState.indexOf(MessagesIndex) });
      this.setState({ Message: MessagesIndex });
      const filteredItems = MainState.filter((item) => item !== MessagesIndex);
  
      this.setState({ DropBoxDataBlukDelete1: filteredItems });
    }

  HandleOnErrorBlukDelete(Err, File, InputElem, Reason) {}

  HandleOnRemoveFileBlukDelete(Data) {}

  //import csv
  ImportCsvBlukDelete() {
    document.getElementById("import_csvBlukDelete").style.display = "block";
    document.getElementById("map_attributeBlukDelete").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    this.setState({ ResetBlukDelete: false });
    this.setState({ TotalDisplayCount: false });
  }

  //cancle Csv
  CancleCsvBlukDelete() {
    this.ResetCsvBlukDelete();
    document.getElementById("import_csvBlukDelete").style.display = "none";
    document.getElementById("map_attributeBlukDelete").style.display = "none";
  }

  SaveCsvBlukDelete() {
    //
    var Subjects = document.getElementById("SubjectBlukDelete").value;
    var Messages = document.getElementById("MessageBlukDelete").value;
    Fileupload.getBase64(this.state.FilesBlukDelete, (Result) => {
      var RreglarString = Result;
      var Splirs = RreglarString.split("base64,");
      var Base64 = Splirs[1];
      var InputParameter = {
        Fields: "Coverimg",
        Path: "./Content/UploadedFiles/DeliverabilityTesting",
        File: Base64,
        ImageName: this.state.FileNameBlukDelete,
        FileData: this.state.CsvDataBlukDelete,
        Subject: this.state.SubjectIndex,
        Message: this.state.MessageIndex,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/DeliverAbilityTesting/DeliverabilityTestingBulkDelete",
        method: "POST",
        data: InputParameter,
      }).then((Res) => {
        if (Res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Deliverability testing data deleted successfully.
            </div>
          );
          this.setState({ ListUpdateBluk: true });
          document.getElementById("import_csvBlukDelete").style.display =
            "none";
          document.getElementById("map_attributeBlukDelete").style.display =
            "none";
            this.setState({ CsvDataBlukDelete: [] });
            this.setState({ RawDropDownData: [] });
            this.setState({ DropBoxDataBlukDelete1: [] });
            this.setState({ DropBoxDataBlukDelete2: [] });
        } else {
          toast.error(Res.data.Message);
        }
      });
    });
  }

  //Send test
  onChange(imageList, addUpdateIndex) {
    // data for submit

    // setImages(imageList);
    this.setState({ images: imageList });
  }
  onChangeone(imageList, addUpdateIndex) {
    // data for submit

    // setImages(imageList);
    this.setState({ imagesone: imageList });
  }

  SendTest() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let Email = document.getElementById("txtsendemail").value.trim();
    if (Email == "") {
      toast.error(<div className="toastsize">Please enter email.</div>);
    } else if (!emailRegex.test(Email)) {
      toast.error(<div className="toastsize">Please enter valid email.</div>);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to send email to test Deliverability Testing .",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No,cancel",
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, send it!",
        reverseButtons: true,
        allowOutsideClick:false
      }).then((Result) => {
        if (Result.isConfirmed) {
          if (Email.length > 0) {
            var data = {
              TestEmail: Email,
              ClientID: this.state.ClientID,
              UserID: this.state.UserID,
              CreatedDate: new Date(),
              CreatedBy: this.state.CUserID,
            };
          }
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/DeliverAbilityTesting/DeliverAbilityTestingSendTestAdd",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              Swal.fire(
                "Sending!",
                "Deliverability testing mails will be start soon.",
                "success"
              );

              history.push("/deliverabilitytesting");
              this.setState({ OpenSendTest: false });
            } else if (Result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
          });
        } else {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
  }

  //Add function
  AddDeliverabilityTesting = () => {
    history.push("/adddeliverabilitytesting");
  };

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col ">
                <h4 className="headertitlenop lg-headertitle float-left py-4 mb-0">
                  Deliverability Testing
                </h4>
              </div>
                {/* <div className="listing-li float-right padb-15"></div>
              </div>
            </div>
            <div className="row px-4"> */}
              <div className="col pt-1">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li className="px-0">
                      <a
                        onClick={() => {
                          this.setState({ OpenSendTest: true });
                        }}
                        className="btn btngroup m-btn "
                      >
                        <i class="la la-send la-icon-font-size-13"></i>
                        <span>Send Test</span>
                      </a>

                      <Popup open={this.state.OpenSendTest}>
                        <div>
                          <div className="modal-black"></div>
                          <div className="filterPopup largerPopup900">
                            <div className="paddingboxTerms">
                              <div className="modal-header py-4">
                                <div className="w-100 d-flex px-2">
                                  <h5 className="mb-0">Send Test </h5>
                                  <button
                                    className="close"
                                    onClick={() => {
                                      this.setState({ OpenSendTest: false });
                                    }}
                                  >
                                    <span
                                      aria-hidden="true"
                                      class="la la-remove"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                              <div className="p-0">
                                <div className="modal-body pb-4">
                                  <div class="col-lg-12 row">
                                    <label class="col-lg-3 col-form-label">
                                      Testing Email Address
                                    </label>
                                    <div class="col-lg-9">
                                      <input
                                        class="form-control m-input"
                                        data-val="true"
                                        data-val-required="Please enter email"
                                        id="txtsendemail"
                                        name="Email"
                                        placeholder="Enter email"
                                        type="text"
                                        autocomplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer border-none">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => {
                                      this.setState({ OpenSendTest: false });
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <a
                                    href="javascript:void(0);"
                                    class="btn btn-primary btn-lightgreen"
                                    onClick={() => {
                                      this.SendTest();
                                    }}
                                  >
                                    <i class="la la-save"></i> Send Test
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popup>
                    </li>
                    <li className="px-1">
                      <a
                        onClick={this.ImportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-upload"></i>
                        <span>Import</span>
                      </a>
                    </li>
                    <li className="px-0">
                      <a
                        onClick={this.AddDeliverabilityTesting}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                    <li className="px-1">
                      <a
                        onClick={this.ImportCsvBlukDelete}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span>Bulk Delete</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              {/* import csv */}
              <div className="portletbody bg-white border-bottom-0" id="import_csv">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">
                      Import Deliverability Testing Details
                    </h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={this.HandleOnDrop}
                        onError={this.HandleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.HandleOnRemoveFile}
                        isReset={this.state.Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>
                          Add Deliverability Testing data from csv file to bulk
                          upload.
                        </p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={this.MapCsv}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a onClick={this.CancleCsv} className="btn btn-secondary">
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="portletbody bg-white border-bottom-0 p-0 pb-3 my-0" id="map_attribute" style={{  pointerEvents: this.state.IsMapDivDisabled ? 'none' : 'auto'}}>
                <div className="row col-xs-12 mx-0">
                <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12">
                  <div className="form-group m-form__group d-flex">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                          style={{cursor: this.state.IsMapDivDisabled ? 'not-allowed' : 'auto'}}
                        >
                          <option selected="" value="SubjectImport">
                            Subject
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="SubjectImport"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          onChange={() => {
                            this.SubjectDropDownHandler();
                          }}
                          value={this.state.Subject === null 
                            ?  this.state.DropBoxData.findIndex((item) =>
                            new RegExp("^\\s*Subject\\s*$", "i").test(item.trim().toLowerCase())
                          )
                            :this.state.Subject}
                          style={{cursor: this.state.IsMapDivDisabled ? 'not-allowed' : 'auto'}}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {this.state.DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                          id="ImportCsvMessage"
                        >
                          <option selected="" value="Message">
                            Message
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x"></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="MessageImport"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          onChange={() => {
                            this.MessageDropDownHandler();
                          }}
                          value={this.state.Message === null 
                            ?  this.state.DropBoxData.findIndex((item) =>
                            new RegExp("^\\s*Message\\s*$", "i").test(item.trim().toLowerCase())
                          )
                            :this.state.Message}
                          style={{cursor: this.state.IsMapDivDisabled ? 'not-allowed' : 'auto'}}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {this.state.DropBoxData2.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      id="ImportCsvBtn"
                      disabled={this.state.IsSubmitting}
                      onClick={this.SaveCsv}
                      className="btn btn-primary btn-lightgreen"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div className="portletbody bg-white border-bottom-0 p-0 pb-3 my-0 pt-4" id="import_csvBlukDelete">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">
                      Bulk Delete Deliverability Testing
                    </h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={this.HandleOnDropBlukDelete}
                        onError={this.HandleOnErrorBlukDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.HandleOnRemoveFileBlukDelete}
                        isReset={this.state.ResetBlukDelete}
                      >
                        <h6>Select .CSV file</h6>
                        <p>
                          Add deliverability testing data from csv file to bulk
                          delete.
                        </p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={this.MapCsvBlukDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={this.CancleCsvBlukDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="portletbody bg-white border-bottom-0 p-0 pb-3 my-0" id="map_attributeBlukDelete">
                <div className="row col-xs-12 mx-0">
                <h4 className="headertitle xs-headertitle float-left pl-0 mb-0">Map attributes</h4>
                </div>
                <div className="col-xs-12">
                  <div className="form-group m-form__group d-flex">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Subject">
                            Subject
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="SubjectBlukDelete"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          onChange={() => {
                            this.SubjectDropDownHandlerDelete();
                          }}
                          value={this.state.Subject}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {this.state.DropBoxDataBlukDelete1.map(
                            (dbdata, index) => (
                              <option value={dbdata}>{dbdata}</option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Message">
                            Message
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x"></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="MessageBlukDelete"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          onChange={() => {
                            this.MessageDropDownHandlerDelete();
                          }}
                          value={this.state.Message}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {this.state.DropBoxDataBlukDelete2.map(
                            (dbdata, index) => (
                              <option value={dbdata}>{dbdata}</option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <a
                      onClick={this.SaveCsvBlukDelete}
                      className="btn btn-primary btn-lightgreen"
                    >
                      <i className="la la-save"></i>Save
                    </a>
                  </div>
                </div>
              </div>

              {/* Count */}
              {this.state.TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom-0 bg-white p-3 py-4" id=" ">
                  <div className="row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left mb-0 p-0 pb-4">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div class="row colfive mx-0">
                      <div class="col-md-3">
                        <div class="boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2 d-block text-center">
                                <span class="box-main-title font-primary">
                                  {this.state.TotalResult}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2 d-block text-center">
                                <span class="box-main-title font-success">
                                  {this.state.TotalSuccess}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2 d-block text-center">
                                <span class="box-main-title font-danger">
                                  {this.state.TotalError}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2 d-block text-center">
                                <span class="box-main-title font-warning">
                                  {this.state.TotalDuplicate}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div class="paddcols pt-0">
              <div className="row">
                <div className="col">
                  <div className="">
                    <DeliverabilityTesting
                      listupdate={this.state.ListUpdate}
                      listUpdateBluks={this.state.ListUpdateBluk}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedDeliverabilityTestingPage = connect(
  mapState,
  actionCreators
)(DeliverabilityTestingPage);
export { connectedDeliverabilityTestingPage as DeliverabilityTestingPage };
