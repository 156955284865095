import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import ColdCallingContact from '../../_components/clients/coldcalling/coldcallingcontact';
//import CollapsibleTable from '../_components/usertable';



class CColdCallingContactPage extends React.Component {
   
    componentDidMount() {
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    }
    render() {
        return (
            <>
                <div className="adminmain"> 
                <Sidebar className=""/> 
                <div className="bodyhome">

                <div class="paddcols">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlepad lg-headertitle pl-0 mb-0">Cold Calling Contact</h4>
                            <div className="listing-li float-right padb-15">
                                <ul>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col"> 
                            <div className="listing-li float-right padb-15 iconbox-pad">
                                <ul> 
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                    <i class="la la-icon-font-size-13 la-plus"></i>
                                    </a>
                                    </li>
                                  
                                </ul>
                            </div>
                        </div>
                        </div>
                    
                    <div className="row mt-4 mb-5">
                    <div className="col"> 
                            <div className="listing-li float-right padb-15 iconbox-pad">
                                <ul> 
                                    <li>
                                    <select class="selectboxdef">
                                        <option value="">--Select Filter--</option>
                                        <option value="182">Default</option>
                                        <option value="217">ggg</option>
                                        <option value="201">New Default</option>
                                        <option value="216">new one</option>
                                        <option value="219">not default</option>
                                        <option value="221">please chek filter</option>
                                        <option value="222">Set Last Call Result</option>
                                        <option value="218">test new </option>
                                        <option value="208">Time </option>
                                    </select>
                                    </li>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                    <i class="la la-icon-font-size-13 la-plus"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-upload"></i>
                                    </a>
                                    </li>
                                     {/*<li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-download"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-arrows-h"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                    <i class="la flaticon-delete-1"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                    <i class="fa la-icon-font-size-13 fa-ban"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                    <i class="fa la-icon-font-size-13 fa-filter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                    <i class="la flaticon-interface-8"></i>
                                    </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>       
                    
                        

                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label className="textlabelte">Show 
                            <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select> 
                            entries</label> 
                        </div>
                        <div className="col-sm-12 col-md-6 full-right">
                            <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                        </div>
                    </div>                   

                    <div className="row">
                        <div className="col">
                            <div className="table-bordered">
                                <ColdCallingContact />
                            </div>
                        </div>
                    </div>   

                    <div className="row">
                        <div className="col dataTables_info">
                        <p>Showing 1 to 71 of 71 entries</p>
                        </div>
                    </div>

                    </div>
                </div>

                    

                     

                </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCColdCallingContactPage = connect(mapState, actionCreators)(CColdCallingContactPage);
export { connectedCColdCallingContactPage as CColdCallingContactPage };