import React, { useEffect } from 'react';
const Moment = require("moment");
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Paper from '@material-ui/core/Paper';
import Axios from "axios";
import loadingicon from "../../../images/loading.gif";
import Tooltip  from '@mui/material/Tooltip';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { toast } from "react-toastify";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import StyleHeader from "../StickyHeader/StickyHeader";


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


export default function AllClientCampaignTable({ ObjClientCampaigns }) {
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ClientName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [sortedColumn, setSortedColumn] = React.useState("ClientName");
  const [IncludeArchiveCampaign, SetIncludeArchiveCampaign] = React.useState(false);
  const [IncludeInActiveCampaign, SetIncludeInActiveCampaign] = React.useState(false);
  const [AllClientCampaignData, SetAllClientCampaignData] = React.useState([]);
  const [Rlen, SetRlen] = React.useState(0);
  const [Flen, SetFlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [UserID, SetUserID] = React.useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);


  useEffect(() => {
    document.title = `All Campaign | SalesHive`;
    var UserDetails = GetUserDetails();
    if (UserDetails != null) {
      SetUserID(UserDetails.ParentUserID);
    }
    GetCampaignsList(UserDetails.ParentUserID);
  }, [Search, Page, RowsPerPage,IncludeArchiveCampaign,IncludeInActiveCampaign]);
  
  useEffect(() => {
    var UserDetails = GetUserDetails();
    if (UserDetails != null) {
      if (ObjClientCampaigns.IsAnalyze) {
        GetCampaignsList(UserDetails.ParentUserID);
      }
    }
  }, [ObjClientCampaigns.FromDate,ObjClientCampaigns.ToDate, ObjClientCampaigns.IsAnalyze])
  
  const GetCampaignsList = (UID) => {
    
    SetIsLoading(true)
    var requestData = {
      UserID: UID,
      FromDate: ObjClientCampaigns.FromDate,
      ToDate: ObjClientCampaigns.ToDate,
      IncludeArchiveCampaign: IncludeArchiveCampaign,
      IncludeInActiveCampaign: IncludeInActiveCampaign,
      Page: Page,
      RowsPerPage: RowsPerPage,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/AllClientCampaignGet",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        if (response.data.PageData.length > 0) {
          SetAllClientCampaignData(response.data.PageData);
          SetRlen(response.data.TotalCount);
          SetFlen(response.data.TotalCount);
          SetCountPage(response.data.PageCount);
          SetIsLoading(false)
          setIsDataLoaded(true);

        } else {
          SetAllClientCampaignData([])
          SetRlen(0)
          SetFlen(0)
          SetCountPage(0)
          SetIsLoading(false)
          setIsDataLoaded(true);

        }
      } else {
        SetAllClientCampaignData([])
        SetRlen(0)
        SetFlen(0)
        SetCountPage(0)
        SetIsLoading(false)
      }
    }).catch(error => {
      console.error('Error fetching Get All Client Campaign Get:', error);
      setIsDataLoaded(true);

    });
  };
  

  const CampaignExport= (UserID) => {
    SetIsLoading(true)
    var archivechecked = document.getElementById("include_archive").checked;
    var activechecked = document.getElementById("include_inactive").checked;
    SetIsLoading(true)
    var InputParameter = {
      UserID: UserID,
      IncludeArchiveCampaign: archivechecked,
      IncludeInActiveCampaign: activechecked,
      FromDate: ObjClientCampaigns.FromDate,
      ToDate: ObjClientCampaigns.ToDate,
    }
    Axios({
      url: CommonConstants.MOL_APIURL + '/campaign/AllClientCampaignExport',
      method: 'POST',
      data: InputParameter
    }).then((Result) => {
      if (Result.data.StatusMessage = "SUCCESS") {
        var url = Result.data.PageData;
        const a = document.createElement('a');
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);
        toast.success(<div className="toastsize">Campaigns  <br />  Data exported successfully.</div>);
        SetIsLoading(false)
      }
      else {
        SetIsLoading(false)
      }
    }).catch(error => {
      console.error('Error fetching export All Client Campaign Export', error);
      SetIsLoading(false)
    });
  }

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
    
    }
  };

  //push to edit page
  const EditCampaign = async (CampaignID) => {

    history.push({ pathname: "/editcampaigns", state: { data: CampaignID } })
  }

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    //document.getElementById("hideloding").style.display = "block";
  };

  const SubmitHandle = async () => {

    var archivechecked = document.getElementById("include_archive").checked;
    var activechecked = document.getElementById("include_inactive").checked;
    SetIncludeArchiveCampaign(archivechecked)
    SetIncludeInActiveCampaign(activechecked)
  }


  const SortData = (Field) => {
    try {
      setSortedColumn(Field);
      SetIsLoading(true);
      const SearchedVal = document.getElementById("search").value?.trim();
      const isNewSortField = Field !== SortField;
      let SortBy = SortedBy === 1 ? -1 : 1;
    
      if (isNewSortField) {
        SetSortedBy(1);
        SortBy = 1;
      } else {
        SetSortedBy(SortBy);
      }
    
      SetSortField(Field);
      // SetSflag(SearchedVal !== "");
      const requestData = {
        UserID: UserID,
        FromDate: ObjClientCampaigns.FromDate,
        ToDate: ObjClientCampaigns.ToDate,
        IncludeArchiveCampaign: IncludeArchiveCampaign,
        IncludeInActiveCampaign: IncludeInActiveCampaign,
        Page: Page,
        RowsPerPage: RowsPerPage,
        Field: Field,
        SortBy: SortBy,
        Search: SearchedVal,
      };
  
      Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/AllClientCampaignGet",
        method: "POST",
        data: requestData,
      })
        .then((response) => {
          if (response.data.StatusMessage === "SUCCESS") {
            if (response.data.PageData.length > 0) {
              SetAllClientCampaignData(response.data.PageData);
              SetRlen(response.data.TotalCount);
              SetFlen(response.data.TotalCount);
              SetCountPage(response.data.PageCount);
            } else {
              SetAllClientCampaignData([]);
              SetRlen(0);
              SetFlen(0);
              SetCountPage(0);
            }
          } else {
            SetAllClientCampaignData([]);
            SetRlen(0);
            SetFlen(0);
            SetCountPage(0);
          }
          SetIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching Get All Client Campaign Get:", error);
         // toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
    } catch (error) {
    //  toast.error("An error occurred while searching. Please try again.");
      SetIsLoading(false);
    }
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

     
      <div className="row mb-3 pb-2 px-2">
        <div className="col padright pr-3 ">
          <label class="check_bx floatrighter mr-3">
            <input id="include_archive" type="checkbox" />
            <span className="checkmark"></span>
            Include archived campaigns
          </label>
          <label class="check_bx floatrighter mr-3">
            <input id="include_inactive" type="checkbox" />
            <span className="checkmark"></span>
            Include inactive campaigns
          </label>
          <button type="submit" id="submit" class="btn btn-primary btnColor m-btn float-left" onClick={SubmitHandle}>
            <span>
              <i class="la flaticon-search"></i>
              <span>Search</span>
            </span>
          </button>

        </div>
        <div className="col-auto">
          <div className="listing-li float-right pb-3">
            <ul>
              <li>
                <a
                  onClick={() => CampaignExport(UserID)}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i className="la la-icon-font-size-13 la-download"></i>
                  <span>

                    Export

                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row px-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" onKeyPress={RequestSearch} id="search" /></label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref"  aria-label="collapsible table">
          <TableHead>
                <TableRow>
                  <TableCell className="w-250" onClick={() => { SortData("ClientName"); setSortedColumn("ClientName"); }}>
                    Client Name
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClientName" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClientName" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("CampaignName"); setSortedColumn("CampaignName"); }}>
                    Name
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "CampaignName" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "CampaignName" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("TotalContact"); setSortedColumn("TotalContact"); }}>
                    Total Contacts
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "TotalContact" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "TotalContact" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("SentCount"); setSortedColumn("SentCount"); }}>
                    Sent
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "SentCount" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "SentCount" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("OpenCount"); setSortedColumn("OpenCount"); }}>
                    Open %
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "OpenCount" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "OpenCount" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("OpenL1"); setSortedColumn("OpenL1"); }}>
                    Open % L1
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "OpenL1" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "OpenL1" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("OpenL7"); setSortedColumn("OpenL7"); }}>
                    Open % L7
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "OpenL7" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "OpenL7" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("OpenL30"); setSortedColumn("OpenL30"); }}>
                    Open % L30
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "OpenL30" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "OpenL30" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("ReplyCount"); setSortedColumn("ReplyCount"); }}>
                    Reply %
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "ReplyCount" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "ReplyCount" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("BounceRate"); setSortedColumn("BounceRate"); }}>
                    Bounce %
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "BounceRate" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "BounceRate" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("SentL1"); setSortedColumn("SentL1"); }}>
                    Sent L1
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "SentL1" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "SentL1" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("SentL7"); setSortedColumn("SentL7"); }}>
                    Sent L7
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "SentL7" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "SentL7" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("Meeting"); setSortedColumn("Meeting"); }}>
                    Meetings
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "Meeting" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "Meeting" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("Uncontacted"); setSortedColumn("Uncontacted"); }}>
                    Uncontacted
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "Uncontacted" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "Uncontacted" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("ReplyBehavior"); setSortedColumn("ReplyBehavior"); }}>
                    Reply Behavior
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "ReplyBehavior" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "ReplyBehavior" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("IsIgnoreBlacklisted"); setSortedColumn("IsIgnoreBlacklisted"); }}>
                    Ignore Blacklisted
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsIgnoreBlacklisted" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsIgnoreBlacklisted" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("EmailOpenTracking"); setSortedColumn("EmailOpenTracking"); }}>
                    Email Open Tracking
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "EmailOpenTracking" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "EmailOpenTracking" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("IsSendonWeekends"); setSortedColumn("IsSendonWeekends"); }}>
                    Send on Weekends
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsSendonWeekends" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsSendonWeekends" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("IsTrackClick"); setSortedColumn("IsTrackClick"); }}>
                    Track Click
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsTrackClick" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsTrackClick" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("Priority"); setSortedColumn("Priority"); }}>
                    Priority
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "Priority" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "Priority" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("EmodProfiles"); setSortedColumn("EmodProfiles"); }}>
                    eMod Profile
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "EmodProfiles" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "EmodProfiles" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("IsSubjectPrompt"); setSortedColumn("IsSubjectPrompt"); }}>
                    Subject Prompt
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsSubjectPrompt" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsSubjectPrompt" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("MailSentDate"); setSortedColumn("MailSentDate"); }}>
                    Mail Sent Date
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "MailSentDate" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "MailSentDate" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
              {AllClientCampaignData?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                AllClientCampaignData.map((row) => (
                  <TableRow>
                    <TableCell>{row?.ClientName}</TableCell>
                    <TableCell><a href="" onClick={() => { EditCampaign(row?.CampaignID) }}><u>{row.CampaignName}</u></a></TableCell>
                    <TableCell>{row?.TotalContact}</TableCell>
                    <TableCell>{row?.SentCount}</TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Opens: {row?.OpenCount}</span><br />
                      <span>Tracked: {row?.TrackingLinkSentCount}</span><br />
                      <span>Open %: {row?.OpenRate + "%"}</span>
                    </>} placement="right">
                      <span>{row?.OpenRate+ "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Opens:{row?.OpenL1}</span><br />
                      <span>Tracked:{row?.TrackingLinkL1}</span><br />
                      <span>Open %:{row?.OpenRateLastOne + "%"}</span>
                    </>} placement="right">
                      <span>{row?.OpenRateLastOne + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Opens: {row.OpenL7}</span><br />
                      <span>Tracked: {row.TrackingLinkL7}</span><br />
                      <span>Open %: {row.OpenRateLastWeek + "%"}</span>
                    </>} placement="right">
                      <span>{row.OpenRateLastWeek + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Opens: {row.OpenL30}</span><br />
                      <span>Tracked: {row.TrackingLinkL30}</span><br />
                      <span>Open %: {row.OpenRateLastMonth + "%"}</span>
                    </>} placement="right">
                      <span>{row.OpenRateLastMonth + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Replies: {row?.ReplyCount}</span><br />
                      <span>Sent: {row?.SentCount}</span><br />
                      <span>Reply %: {row.ReplyRate+ "%"}</span>
                    </>} placement="right">
                      <span>{row.ReplyRate + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell><Tooltip title={<>
                      <span>Soft Bounce: {row.SoftBounceCount }</span><br />
                      <span>Hard Bounce: {row.HardBounceCount }</span><br />
                      <span>Total Bounce: {row.TotalBounceCount}</span><br />
                      <span>Sent: {row.SentCount}</span><br />
                      <span>Bounce %: {row.BounceRate+ "%"}</span>
                    </>} placement="right">
                      <span>{row.BounceRate + "%"}</span>
                    </Tooltip></TableCell>
                    <TableCell>{row?.SentL1}</TableCell>
                    <TableCell>{row?.SentL7}</TableCell>
                    <TableCell>{row?.Meeting}</TableCell>
                    <TableCell>{row?.Uncontacted}</TableCell>
                    <TableCell>{row?.ReplyBehavior}</TableCell>
                    <TableCell>{row?.IsIgnoreBlacklisted == true ? "Yes" : "No"}</TableCell>
                    <TableCell>{row?.EmailOpenTracking}</TableCell>
                    <TableCell>{row?.IsSendonWeekends == true ? "Yes" : "No"}</TableCell>
                    <TableCell>{row?.IsTrackClick == true ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.Priority}</TableCell>
                    <TableCell>{row?.EmodProfiles}</TableCell>
                    <TableCell>{row?.IsSubjectPrompt  == true ? "Yes" : "No"}</TableCell>
                    <TableCell>{row?.MailSentDate != null? Moment(row?.MailSentDate).format("MM/DD/YYYY"): ""}</TableCell>
                    <TableCell><a
                      onClick={() => {
                        EditCampaign(row.CampaignID);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a></TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
}
