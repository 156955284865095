import React from "react";
import { connect } from "react-redux";

import Footer from "../../_components/user/footer/footer";
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import MeetingStatusTable from '../../_components/clients/meetingsources/meetingsources';
import { userActions } from "../../_actions";
import { history } from "../../_helpers";

class CMeetingSourcePage extends React.Component {
  componentDidMount() {
    window.addEventListener('storage', (event) => {
            
      if (event.key === 'clientChanged') {
        window.location.reload();
      }
    });
}
  AddBtn() {
    history.push("/CAddMeetingSource");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle float-left pl-0 mb-0 ">Meeting Source</h4>
              </div> 
              <div className="col">
                <div className="listing-li float-right padb-15 pt-1">
                  <ul>
                    <li>
                      <a
                        onClick={this.AddBtn.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-white">
              <div className="row mx-0 pb-3">
                <div className="col">
                  <MeetingStatusTable />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCMeetingSourcePage = connect(mapState, actionCreators)(CMeetingSourcePage);
export { connectedCMeetingSourcePage as CMeetingSourcePage };