import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();
class EditAccountInfoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientList: [],
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
      Fields: {},
      Errors: {},
      ClientNameID: null,
      IsLoading: false,
      IsExist: false,
      IsValid: true,
      IsButtonDisabled: false,
      AccountSID: "",
      AuthToken: "",
      IsButtonDisabled: false,
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }

  componentDidMount() {
    document.title = `Edit Account Info | SalesHive`;
    let Details = GetUserDetails();
    if (Details) {
      this.setState({
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        CUserID: Details.ChildUserID,
        Role: Details.Role,
      });
      this.getClientList(
        Details.Role,
        Details.ChildUserID,
        Details.ParentUserID
      );
    }
  }

  // Fetching client with id list for client drop down
  getClientList = async (Role) => {
    try {
      this.setState({ IsLoading: true });
      let RequestBody = {
        ID: this.props.location.state,
        Role: Role,
      };
      const response = await Axios.post(
        CommonConstants.MOL_APIURL + "/General/AccountInfoByID",
        RequestBody
      );
      if (response.data?.StatusMessage === "SUCCESS") {
        debugger
        this.setState({ ClientList: response.data?.Data });
        this.setState({
          AccountSID: response.data?.Data[0]?.AccountSID,
          AuthToken: response.data?.Data[0]?.AuthToken,
        });
        this.setState({ IsLoading: false });
      } else {
        this.setState({ IsLoading: false });
      }
    } catch (err) {
      console.error(err);
      this.setState({ IsLoading: false });
    }
  };

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    let AccountSID = document.getElementById("AccountSID").value.trim();
    let AuthTokenDetail = document.getElementById("AuthToken").value.trim();

    if (AccountSID == "") {
      Errors["AccountSID"] = "Please enter account SID";
      FormIsValid = false;
    } else {
      Errors["AccountSID"] = null;
    }


    if (AuthTokenDetail == "") {
      Errors["AuthToken"] = "Please enter auth token";
      FormIsValid = false;
    } else {
      Errors["AuthToken"] = null;
    }

    this.setState({ Errors: Errors });

    return FormIsValid;
  }

  // form validation
  async HandleChangeBlur(NameEmail) {
    let Errors = { ...this.state.Errors };
    let AccountSID = document.getElementById("AccountSID").value.trim();
    let AuthTokenDetail = document.getElementById("AuthToken").value.trim();

    if (NameEmail == "AccountSIDDetails") {
      if (AccountSID == "") {
        Errors["AccountSID"] = "Please enter account SID";
      } else {
        Errors["AccountSID"] = null;
      }
    }

    if (NameEmail == "AuthTokenDetails") {
      if (AuthTokenDetail == "") {
        Errors["AuthToken"] = "Please enter auth token";
      } else {
        Errors["AuthToken"] = null;
      }
    }

    this.setState({ Errors: Errors });
  }


  // back to button
  BackBtn() {
    history.push("/sdrassignments", { tab: 0 });
  }

  // meeting owner add
  async SaveBtn(e) {
    this.setState({ IsButtonDisabled: true });
    let Final_flag = await this.FromValidation();

    if (Final_flag == true) {
      let AccountSID = document.getElementById("AccountSID").value;
      let AuthTokenDetail = document.getElementById("AuthToken").value;
      let PhoneSettingAdd = {
        ID: this.props.location.state,
        AccountSID: AccountSID,
        IsDeleted: false,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: this.state.CUserID,
        AuthToken: AuthTokenDetail,
        ClientName:this.state.ClientList[0].ClientName,
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/General/PhoneSettingEdit",
        method: "POST",
        data: PhoneSettingAdd,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div>Account info edit successfully.</div>);
          history.push("/sdrassignments", { tab: 0 });
        } else {
          toast.error(res.data.Message);
          this.setState({ IsButtonDisabled: false });
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
    }
  }

  // Back button functionality
  BackBtn = () => {
    history.push("/sdrassignments", { tab: 0 });
  };

  render() {
    return (
      <>
        {this.state.IsLoading === true ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        <div className="adminmain mheight">
          <Sidebar />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad lg-headertitle float-left">
                  Edit Account Info
                </h4>
              </div>
            </div>

            <div className="bg-white p-3 mb-3">
              <div className="row py-2">
                {/* Client select field */}
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Client</label>
                    <div className="col-lg-8">
                      <select
                        className="form-control m-input"
                        id="ClientNameID"
                        disabled
                      >
                        {this.state.ClientList.map((client) => (
                          <option
                            key={client?.ClientID}
                            value={client?.ClientID}
                          >
                            {client?.ClientName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Account SID</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter account SID"
                        id="AccountSID"
                        onBlur={() =>
                          this.HandleChangeBlur("AccountSIDDetails")
                        }
                        name="Account SID"
                        defaultValue={this.state.AccountSID}
                        placeholder="Enter account sid"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["AccountSID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Auth Token</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter auth token"
                        id="AuthToken"
                        onBlur={() => this.HandleChangeBlur("AuthTokenDetails")}
                        name="AuthToken"
                        defaultValue={this.state.AuthToken}
                        placeholder="Enter Auth Token"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["AuthToken"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div class="row mb-3 mt-3">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsButtonDisabled}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i className="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditAccountInfoPage = connect(
  mapState,
  actionCreators
)(EditAccountInfoPage);
export { connectedEditAccountInfoPage as EditAccountInfoPage };
