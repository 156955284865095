import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
const moment = require("moment");
import { toast } from "react-toastify";
toast.configure();
import Axios from "axios";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import "react-toastify/dist/ReactToastify.css";

import loadingicon from "../../images/loading.gif";
import Kpis from "../../images/menuicon/kpis.svg";
import CRM from "../../images/menuicon/crm.svg";
import EmailsIcon from "../../images/menuicon/mail.svg";
import ColdCalling from "../../images/menuicon/phone.svg";
import Settings from "../../images/menuicon/setting.svg";
import Others from "../../images/menuicon/others.svg";

import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import ClientSidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
// import ArticleCategories from "../../_components/user/ArticleCategories/ArticleCategories";
import ArticleLists from "../../_components/user/ArticleLists/ArticleLists";
import { history } from "../../_helpers";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";

class ArticleCategoriesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientID: null,
      UserID: null,
      CUserID: null,
      StartDate: null,
      EndDate: null,
      exportdata: {
        Start: moment(new Date().setMonth(new Date().getMonth() - 3)).format(
          "YYYY/MM/DD"
        ),
        End: moment(new Date()).format("YYYY/MM/DD"),
      },
      pageData: null,
      inputValue: "",
      showArticleLists: false,
      obj: {},
      iconObj: [
        { icon: Kpis, label: "KPIs" },
        { icon: CRM, label: "CRM" },
        { icon: EmailsIcon, label: "EMAIL" },
        { icon: ColdCalling, label: "PHONE" },
        { icon: Settings, label: "SETTINGS" },
        { icon: Others, label: "OTHERS" },
      ],
      categoryList: [],
      isLoading: false,
      CRole : "",
    };
  }
  componentDidMount = async () => {
    document.title = `Articles | SalesHive`;
    this.getCategories();
    var Details = await GetUserDetails();
    if (Details != null) {
      this.setState({ CRole: Details.Role })
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ showArticleLists: false, inputValue: "" });
      document.getElementById('searchtext').value = "";
    }
  }
  getCategories = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await Axios({
        url: CommonConstants.MOL_APIURL + "/knowledge_base/fetchCategories",
        method: "GET",
      });
      if (response.data && response.data.StatusMessage === "SUCCESS") {
        const categoryData = response.data.Data;
        if (categoryData.length > 0) {
          const categoryList = categoryData.map((val) => {
            return { value: val._id, label: val.FieldName, cid: val._id };
          });
          this.setState({ categoryList, showArticleLists: false });
        }
      } else {
        toast.error(response.data.Message);
        this.setState({ showArticleLists: false });
      }
    } catch (error) {
      toast.error(error.message);
      this.setState({ showArticleLists: false });
    } finally {
      this.setState({ isLoading: false });
    }
  }
  openAddArticlePage = async () => {
    var Details = await GetUserDetails();
    if(Details.Role === "Admin"){
      history.push("/addarticle");
    }
    else if(Details.Role === "Client"){
      history.push("/caddarticle");
    }
  }
  setInputValue = (value) => {
    // this.setState({ inputValue: value });
  }
  handleSubmit = (Event) => {
    if (Event.key == "Enter") {
      const inputValue = document.getElementById('searchtext').value;
      // const { inputValue } = this.state;
      const objval = {
        searchText: inputValue,
        categoryId: ""
      };
      this.setState({
        inputValue: inputValue,
        showArticleLists: true,
        obj: objval
      });
    }
  }
  getArticles = (id) => {
    document.getElementById('searchtext').value = "";
    const objval = {
      categoryId: id,
      searchText: ""
    }
    this.setState({
      showArticleLists: true,
      inputValue: "",
      obj: objval
    });
  }
  render() { 
    const { inputValue, showArticleLists, isLoading, iconObj, categoryList, obj, CRole } = this.state;
    var objsrch = {};
    if (obj) {
      objsrch.searchText = obj.searchText != undefined ? obj.searchText : '';
      objsrch.categoryId = obj.categoryId != undefined ? obj.categoryId : '';
    }
    objsrch.isCategorySearch = false;

    return (
      <>
        <div className="adminmain mheight">
        { CRole === "Client" ? <ClientSidebar className="" /> : <Sidebar className="" /> }
          <div className="bodyhome">
            <div className="listblock">

              <div className='header-Knowledge pt-3'>
                <div className="max-widthcard py-3">
                  <div className='maxwidth-header'>
                    <h1>Hello! How can we help?</h1> 
                  </div>
                    <div className="maxwidth-header d-flex gap-10">
                      <div className='input-boxserch'>
                          <svg width='22px' viewBox="0 0 12 13"><g stroke-width="2" stroke="#999999" fill="none"><path d="M11.29 11.71l-4-4" /><circle cx="5" cy="5" r="4" /></g></svg>
                            <input
                              id="searchtext"
                              type="text"
                              // value={inputValue}
                              // onChange={(e) => this.setInputValue(e.target.value)}
                              onKeyPress={this.handleSubmit}
                              class="form-control m-input"
                              placeholder="Search articles"
                            />
                      </div> 
                      {CRole === "Admin" && (
                        <div className="listing-li float-right padb-15">
                          <ul className="mt-0">
                            <li>
                              <a href="javascript:void(0);" onClick={this.openAddArticlePage} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air" title="Add Article">
                                <i class="la la-icon-font-size-13 la-plus"></i>
                                {/* <span>Add</span> */}
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                </div>
              </div>

          
              {
                  isLoading === true ? <div id="hideloding" className="loding-display">
                      <img src={loadingicon} />
                  </div> : null
              }
              {!showArticleLists &&
                // <ArticleCategories/>
                <div>
                  <div class="mt-4 mb-4 max-widthcard">
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid className='grid-boxcard' container spacing={2}>
                        {categoryList?.map((row) => (
                          <Grid xs={6} md={4}>
                            <a onClick={() => {
                              this.getArticles(row.cid);
                            }} className="btn-eyesicon" >
                              <div>
                                {iconObj?.map((icn, index) =>
                                  row?.label === icn?.label ? (
                                    <div className="tile-img-container"><img key={index} src={icn.icon} alt={row?.label} /> <span class="tile-img-background"></span></div>
                                  ) : null
                                )}
                                <h4 className='lable-box'>
                                  {row?.label}
                                </h4>
                                {/* <p>Articles & Resources for Client Users</p> */}

                                {/* <div class="go-corner" href="#">
                                  <div class="go-arrow">
                                   View More →
                                  </div>
                                </div> */}
                              </div>
                            </a>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </div>
                </div>
              }
              {showArticleLists && <ArticleLists searchParam={objsrch} />}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedArticleCategoriesPage = connect(mapState, actionCreators)(ArticleCategoriesPage);
export { connectedArticleCategoriesPage as ArticleCategoriesPage };
