import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import { CSVReader, CSVDownloader } from "react-papaparse";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../../../_components/user/Kpis/LastdaysTabing";

import SubjectTable from "./SubjectTable";
import GreetingTable from "./GreetingTable";
import OpenerTable from "./OpenerTable";
import FUOpenerTable from "./FUOpenerTable";
import CTATable from "./CTATable";
import CloserTable from "./CloserTable";
import OptOutTable from "./OptOutTable";
import DayTable from "./DayTable";
import TimeTable from "./TimeTable";
import LengthTable from "./LengthTable";
import ActionTable from "./ActionTable";
import DisclaimerTable from "./DisclaimerTable";

import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import $ from "jquery";

import { TrendingUpTwoTone } from "@material-ui/icons";
import Tooltip from '@mui/material/Tooltip'
const Fileupload = require("../../../_helpers/fileupload");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [UserID, SetUserID] = React.useState("");
  const [ClientID, SetClientID] = React.useState("");
  const [Errors, SetErrors] = React.useState([]);
  const [StatusAvailable, SetStatusAvailable] = React.useState(null);
  const [Fields, SetFields] = React.useState([]);
  const [IsExist, SetIsExist] = React.useState(false);
  const [ClientName, SetClientName] = React.useState("");
  const [Reset, SetReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [CName, SetCName] = React.useState("");
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalErrors, SetTotalErrors] = React.useState(0);
  const [Total, SetTotal] = React.useState(0);
  const [ListUpdate, SetListUpdated] = React.useState(false);
  const [ReloadEffect, SetReloadEffect] = React.useState(false);
  const [HotReload, SetHotReload] = React.useState(false);

  //Subject
  const [SubjectAdd, SetSubjectAdd] = React.useState(false);
  const [SubjectExport, SetSubjectExport] = React.useState([]);

  //Greeting
  const [GreetingAdd, SetGreetingAdd] = React.useState(false);
  const [GreetingExport, SetGreetingExport] = React.useState([]);

  //Opener
  const [OpenerAdd, SetOpenerAdd] = React.useState(false);
  const [OpenerExport, SetOpenerExport] = React.useState([]);

  //FUOpener
  const [FUOpenerAdd, SetFUOpenerAdd] = React.useState(false);
  const [FUOpenerExport, SetFUOpenerExport] = React.useState([]);

  //CTA
  const [CTAAdd, SetCTAAdd] = React.useState(false);
  const [CTAExport, SetCTAExport] = React.useState([]);

  //Closer
  const [CloserAdd, SetCloserAdd] = React.useState(false);
  const [CloserExport, SetCloserExport] = React.useState([]);

  //OptOut
  const [OptOutAdd, SetOptOutAdd] = React.useState(false);
  const [OptOutExport, SetOptOutExport] = React.useState([]);

  //Day
  const [DayAdd, SetDayAdd] = React.useState(false);
  const [DayExport, SetDayExport] = React.useState([]);

  //Time
  const [TimeAdd, SetTimeAdd] = React.useState(false);
  const [TimeExport, SetTimeExport] = React.useState([]);

  //Length
  const [LengthAdd, SetLengthAdd] = React.useState(false);
  const [LengthExport, SetLengthExport] = React.useState([]);

  //Action
  const [ActionAdd, SetActionAdd] = React.useState(false);
  const [ActionExport, SetActionExport] = React.useState([]);

  // Disclaimer
  const [DisclaimerAdd, SetDisclaimerAdd] = React.useState(false);
  const [DisclaimerExport, SetDisclaimerExport] = React.useState([]);
  //Btn disabled
  const [BtnDisabled, SetBtnDisabled] = React.useState(false);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [Subject, SetSubject] = React.useState(null);
  const [Greeting, SetGreeting] = React.useState(null);
  const [Opener, SetOpener] = React.useState(null);
  const [FUOpener, SetFUOpener] = React.useState(null);
  const [CTA, SetCTA] = React.useState(null);
  const [Closer, SetCloser] = React.useState(null);
  const [OtpOut, SetOptOut] = React.useState(null);

  const [Day, SetDay] = React.useState(null);
  const [Time, SetTime] = React.useState(null);
  const [Length, SetLength] = React.useState(null);
  const [Action, SetAction] = React.useState(null);
  const [Disclaimer, SetDisclaimer] = React.useState(null);

  const [IsDisabled,SetIsDisabled] = React.useState(false);



  const handleChange = (event, newValue) => {
    setValue(newValue);
    SetTotalDisplayCount(false);
    SetErrors([]);
    SetStatusAvailable(null), SetIsExist(false);
    SetSubjectAdd(false);
    SetGreetingAdd(false);
    SetOpenerAdd(false);
    SetFUOpenerAdd(false);
    SetCTAAdd(false);
    SetCloserAdd(false);
    SetOptOutAdd(false);
    SetDayAdd(false);
    SetTimeAdd(false);
    SetLengthAdd(false);
    SetActionAdd(false);
    SetDisclaimerAdd(false);
    
    SetBtnDisabled(false);
  };

  useEffect(() => {
    document.title = "Default Variables | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
    }
    ExportSubjectMethod(Details.ClientID, Details.ParentUserID);
    ExportGreetingMethod(Details.ClientID, Details.ParentUserID);
    ExportOpenerMethod(Details.ClientID, Details.ParentUserID);
    ExportFUOpenerMethod(Details.ClientID, Details.ParentUserID);
    ExportCTAMethod(Details.ClientID, Details.ParentUserID);
    ExportCloserMethod(Details.ClientID, Details.ParentUserID);
    ExportOptOutMethod(Details.ClientID, Details.ParentUserID);
    ExportDayMethod(Details.ClientID, Details.ParentUserID);
    ExportTimeMethod(Details.ClientID, Details.ParentUserID);
    ExportLengthMethod(Details.ClientID, Details.ParentUserID);
    ExportActionMethod(Details.ClientID, Details.ParentUserID);
    ExportDisclaimerMethod(Details.ClientID, Details.ParentUserID);
  }, [ReloadEffect, HotReload]);

  //Reload page while delete perform
  const UpdateFromChild = async (value) => {

    if (value == true) {
 
      SetReloadEffect(!ReloadEffect);
    }
  };

  //Comman method for cleanup csv and reset csv
  const CsvCleanUp = (prams) => {
  if (prams === true) {
      SetReset(!Reset);
    } else {
      SetReset(!Reset);
      setcsvData([]);
      SetDropboxData([]);
    }
  };

  //=================Subject Methods ==================
  //Export subject Data
  const ExportSubjectMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "Subject",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetSubjectExport(Res.data.Data);
    });
  };
  const ExportSubjectCsv = () => {
    toast.success(
      <div className="toastsize">
        Subject
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const FormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    let Exist = await CheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {

      SetIsExist(true);
      SetIsDisabled(false)

    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const HandleChange = async (field) => {
    const Name = document.getElementById("Name").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await CheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const CheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "Subject",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        SetIsDisabled(false)
        return true;
      } else {
        SetIsExist(false);
        SetIsDisabled(false)
        return false;
      }
    }
  };
  const SaveBtn = async () => {
   
    SetIsDisabled(true)
    if ((await FormValidation()) && IsExist == false) {
      SetIsLoading(true)
      const Name = document.getElementById("Name").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "Subject",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Subject
              <br />
              Subject added successfully.
            </div>
          );
          SetSubjectAdd(false);
          SetListUpdated(!ListUpdate);
          SetErrors({});
          SetHotReload(!HotReload);
          SetIsDisabled(false)
          SetIsLoading(false)
            
          history.push("/defaultvariables");
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
      SetIsDisabled(false)
    }
 
  };
  //show import
  const ShowSubjectImport = () => {
    document.getElementById("import_csvSubject").style.display = "block";
    document.getElementById("map_attributeSubject").style.display = "none";
    SetBtnDisabled(false);
    CsvCleanUp(true);
  };
  const CancleSubjectBtn = () => {
    document.getElementById("import_csvSubject").style.display = "none";
    document.getElementById("map_attributeSubject").style.display = "none";
    SetTotalDisplayCount(false);
    SetBtnDisabled(false);
    CsvCleanUp(true);
  };
  const HandleOnDropSubject = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      CsvCleanUp(true);
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      setfilename(csvfile);
      setcsvData(data);
      SetDropboxData(data[0].data);
    }
  };

  const HandleOnErrorSubject = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileSubject = (data) => {};

  const ImportBlackListCsvSubject = () => {
    document.getElementById("import_csvSubject").style.display = "block";
  };
  const SubjectMapCsvBtn = () => {
    SetTotalDisplayCount(false);
    document.getElementById("Subject").disabled = false;
    SetBtnDisabled(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeSubject").style.display = "block";
      DropboxData.map((dddd, index) => {});
      CsvCleanUp(true);
    } else {
      toast.error(
        <div>
          Subject
          <br />
          Please select file
        </div>
      );
      document.getElementById("map_attributeSubject").style.display ="none";
      document.getElementById("Subject").disabled = false;
      SetTotalDisplayCount(false);
      SetBtnDisabled(false);

      CsvCleanUp(false);
    }
    hideCSVHeaderOptions();
  };
  // import Subject csv
  const SubjectListSaveCsv = (FieldName) => {
    SetIsLoading(true); // Show the loader
    SetBtnDisabled(true);
    var Subject = document.getElementById("Subject").value.trim();
    if (Subject != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];
        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Subject,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvSubject").style.display = "block";
            document.getElementById("map_attributeSubject").style.display ="block";
              document.getElementById("Subject").disabled = true;
            SetBtnDisabled(true)
            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
  
           SetHotReload(!HotReload);
             SetListUpdated(!ListUpdate);
          await UpdateFromChild(true);
            toast.success(
              <div className="toastsize">
                Subject <br />
                Subject added successfully.
              </div>
            );
            history.push("/defaultvariables");
            CsvCleanUp(false);
            SetIsLoading(false)
          } else {
            toast.error(res.data.Message);
            SetIsLoading(false)

          }
        }).catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // This will execute regardless of success or error
          SetIsLoading(false); // Hide the loader
        });
      });
    } else {
          // Handle Subject not mapped
    SetBtnDisabled(false);
    SetIsLoading(false); // Hide the loader
      toast.error(
        <div className="toastsize">
          Subject
          <br />
          Subject not mapped!
        </div>
      );

    }
  };
  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };
  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "Subject") {
      SetSubject(value);
    }else if(Name == "Greeting"){
      SetGreeting(value)
    }else if(Name == "Greeting"){
      SetGreeting(value)
    }else if(Name == "Opener"){
      SetOpener(value)
    }else if(Name == "FUOpener"){
      SetFUOpener(value)
    }else if(Name == "Greeting"){
      SetGreeting(value)
    }else if(Name == "CTA"){
      SetCTA(value)
    }else if(Name == "Closer"){
      SetCloser(value)
    }else if(Name == "OptOut"){
      SetOptOut(value)
    }else if(Name == "Day"){
      SetCloser(value)
    }else if(Name == "Time"){
      SetCloser(value)
    }else if(Name == "Length"){
      SetLength(value)
    }else if(Name == "Action"){
      SetAction(value)
    }

    hideCSVHeaderOptions();
  });
  //show bulk delete
  const ShowSubjectDelete = () => {
    document.getElementById("import_csvBlukDeleteSubject").style.display =
      "block";
    document.getElementById("map_attributeBlukDeleteSubject").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelSubjectDelete = () => {
    document.getElementById("import_csvBlukDeleteSubject").style.display =
      "none";
    document.getElementById("map_attributeBlukDeleteSubject").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropSubjectDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      CsvCleanUp(true);
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorSubjectDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileSubjectDelete = (data) => {};
  const ImportBlackListCsvSubjectDelete = () => {
    document.getElementById("import_csvBlukDeleteSubject").style.display =
      "block";
  };
  const SubjectMapCsvBtnDelete = () => {
    SetTotalDisplayCount(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteSubject").style.display =
        "block";

      DropboxData.map((dddd, index) => {});
      CsvCleanUp(true);
    } else {
      toast.error(
        <div>
          Subject
          <br />
          Please select file
        </div>
      );
      document.getElementById("map_attributeBlukDeleteSubject").style.display =
        "none";
      CsvCleanUp(false);
      hideCSVHeaderOptions()
    }
  };
  // import Subject csv
  const SaveCsvSubjectBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Subject = document.getElementById("SubjectBox").value.trim();
    if (Subject != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Subject,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete",
          method: "POST",
          data: InputParameters,
        }).then(async (res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvSubject").style.display = "none";
            document.getElementById("map_attributeSubject").style.display =
              "none";
            document.getElementById(
              "map_attributeBlukDeleteSubject"
            ).style.display = "none";
            document.getElementById(
              "import_csvBlukDeleteSubject"
            ).style.display = "none";
            toast.success(
              <div className="toastsise">
                Subject <br />
                Subject deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            CsvCleanUp(false);
            SetBtnDisabled(false);

          await  UpdateFromChild(true)
          SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Subject
          <br />
          Subject not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //==============Greeting methods==================

  //Export greeting Data
  const ExportGreetingMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "Greeting",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetGreetingExport(Res.data.Data);
    });
  };
  const ExportGreetingCsv = () => {
    toast.success(
      <div className="toastsize">
        Greeting
        <br />
        Data exported successfully.
      </div>
    );
  };

  // handle change
  const GreetingHandleChange = async (field) => {
    const Name = document.getElementById("GreetingName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await GreetingCheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const GreetingCheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "Greeting",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  // form validation
  const GreetingFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("GreetingName").value.trim();
    let Exist = await GreetingCheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  const GreetingSaveBtn = async () => {
    SetIsDisabled(true)
    if ((await GreetingFormValidation()) && IsExist == false) {
      SetIsLoading(true)
  
      const Name = document.getElementById("GreetingName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "Greeting",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Greeting
              <br />
              Greeting added successfully.
            </div>
          );
          SetGreetingAdd(false);
          SetListUpdated(!ListUpdate);
          SetHotReload(!HotReload);
          SetIsDisabled(false)
          history.push("/defaultvariables");
          SetIsLoading(false)
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }

  };

  //show import
  const Greetingresetcsv = () => {
    CsvCleanUp(false);
  };
  const ShowGreetingImport = () => {
    document.getElementById("import_csvGreeting").style.display = "block";
    document.getElementById("map_attributeGreeting").style.display = "none";
    CsvCleanUp(true);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleGreetingBtn = () => {
    document.getElementById("import_csvGreeting").style.display = "none";
    document.getElementById("map_attributeGreeting").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropGreeting = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Greetingresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }

  };

  const HandleOnErrorGreeting = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileGreeting = (data) => {};

  const ImportBlackListCsvGreeting = () => {
    document.getElementById("import_csvGreeting").style.display = "block";
  };
  const GreetingMapCsvBtn = () => {
    document.getElementById("Greeting").disabled=false
      SetBtnDisabled(false);
      SetTotalDisplayCount(false);
    if (csvData.length != 0) {
      document.getElementById("map_attributeGreeting").style.display = "block";
      document.getElementById("Greeting").disabled=false
      SetBtnDisabled(false);
      SetTotalDisplayCount(false);

      DropboxData.map((dddd, index) => {});
      CsvCleanUp(true);
    } else {
      toast.error(
        <div className="toastsize">
          Greeting <br />
          Please select file
        </div>
      );
      document.getElementById("Greeting").disabled=false
      document.getElementById("map_attributeGreeting").style.display = "none";
      CsvCleanUp(false);
      SetBtnDisabled(false);
      SetTotalDisplayCount(false);

      SetIsLoading(false);
    }
    hideCSVHeaderOptions();
  };
  // import Greeting csv
  const GreetingListSaveCsv = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Greeting = document.getElementById("Greeting").value.trim();
    if (Greeting != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Greeting,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvGreeting").style.display =
              "block";
            document.getElementById("map_attributeGreeting").style.display =
              "block";
              document.getElementById("Greeting").disabled=true
            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            CsvCleanUp(false);
            SetListUpdated(!ListUpdate);
            // SetBtnDisabled(false);
  
            await  UpdateFromChild(true)
            SetIsLoading(false)
            toast.success(
              <div className="toastsize">
                Greeting <br />
                Greeting added successfully.
              </div>
            );
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Greeting <br />
          Greeting not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };
  //show bulk delete
  const ShowGreetingDelete = () => {
    document.getElementById("import_csvBlukDelete").style.display = "block";
    document.getElementById("map_attributeBlukDelete").style.display = "none";
    CsvCleanUp(true);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelGreetingDelete = () => {
    document.getElementById("import_csvBlukDelete").style.display = "none";
    document.getElementById("map_attributeBlukDelete").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropGreetingDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      CsvCleanUp(true);

      Greetingresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorGreetingDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileGreetingDelete = (data) => {};
  const ImportBlackListCsvGreetingDelete = () => {
    document.getElementById("import_csvBlukDelete").style.display = "block";
  };
  const GreetingMapCsvBtnDelete = () => {
    SetTotalDisplayCount(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDelete").style.display =
        "block";
      DropboxData.map((dddd, index) => {});
      CsvCleanUp(true);
    } else {
      toast.error(
        <div className="toastsize">
          Greeting <br />
          Please select file
        </div>
      );
      document.getElementById("map_attributeBlukDelete").style.display =
        "none";
    SetTotalDisplayCount(false);

      CsvCleanUp(false);
    }
  };
  // import Greeting csv
  const SaveCsvGreetingBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Greeting = document.getElementById("GreetingBox").value.trim();
    if (Greeting != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Greeting,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvGreeting").style.display =
              "none";
            document.getElementById("map_attributeGreeting").style.display =
              "none";
            document.getElementById("map_attributeBlukDelete").style.display =
              "none";
            document.getElementById("import_csvBlukDelete").style.display =
              "none";
            toast.success(
              <div className="toastsise">
                Greeting <br />
                Greeting deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            CsvCleanUp(false);
            SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)

            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Greeting <br />
          Greeting not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //==============Opener=================
  //Opener methods
  const ExportOpenerMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "Opener",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetOpenerExport(Res.data.Data);
    });
  };
  const ExportOpenerCsv = () => {
    toast.success(
      <div className="toastsize">
        Opener
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const OpenerFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("OpenerName").value.trim();
    let Exist = await OpenerCheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const OpenerHandleChange = async (field) => {
    const Name = document.getElementById("OpenerName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await OpenerCheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const OpenerCheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "Opener",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  const OpenerSaveBtn = async () => {
    SetIsDisabled(true)

    if ((await OpenerFormValidation()) && IsExist == false) {
      SetIsLoading(true)

      const Name = document.getElementById("OpenerName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "Opener",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Opener
              <br />
              Opener added successfully.
            </div>
          );
          SetOpenerAdd(false);
          SetListUpdated(!ListUpdate);
          SetHotReload(!HotReload);
          SetIsDisabled(false)
          history.push("/defaultvariables");
          SetIsLoading(false)
          SetIsDisabled(false)
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }

  };
  //show import
  const Openerresetcsv = () => {
    CsvCleanUp(true);
  };
  const ShowOpenerImport = () => {
    document.getElementById("import_csvOpener").style.display = "block";
    document.getElementById("map_attributeOpener").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleOpenerBtn = () => {
    document.getElementById("import_csvOpener").style.display = "none";
    document.getElementById("map_attributeOpener").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropOpener = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Openerresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorOpener = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileOpener = (data) => {};

  const ImportBlackListCsvOpener = () => {
    document.getElementById("import_csvOpener").style.display = "block";
  };
  const OpenerMapCsvBtn = () => {
    document.getElementById("Opener").disabled = false
    SetBtnDisabled(false);
    SetTotalDisplayCount(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeOpener").style.display = "block";
      DropboxData.map((dddd, index) => {});
      Openerresetcsv();
    } else {
      toast.error(
        <div>
          Opener <br />
          Please select file
        </div>
      );
      document.getElementById("Opener").disabled = false
      CsvCleanUp(false);
      SetTotalDisplayCount(false);

      document.getElementById("map_attributeOpener").style.display = "none";
    }
    hideCSVHeaderOptions()
  };
  // import Opener csv
  const OpenerListSaveCsv = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Opener = document.getElementById("Opener").value.trim();
    if (Opener != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Opener,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("Opener").disabled = true
            document.getElementById("import_csvOpener").style.display = "block";
            document.getElementById("map_attributeOpener").style.display ="block";
            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            SetListUpdated(!ListUpdate);
            CsvCleanUp(false);
            // SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Opener <br />
          Opener not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };
  //show bulk delete
  const ShowOpenerDelete = () => {
    document.getElementById("import_csvBlukDeleteOpener").style.display =
      "block";
    document.getElementById("map_attributeBlukDeleteOpener").style.display =
      "none";
    SetBtnDisabled(false);
    SetIsLoading(false)
    CsvCleanUp(false);
  };
  //Hide Bulk Delete
  const CancelOpenerDelete = () => {
    document.getElementById("import_csvBlukDeleteOpener").style.display =
      "none";
    document.getElementById("map_attributeBlukDeleteOpener").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropOpenerDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Openerresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorOpenerDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileOpenerDelete = (data) => {};
  const ImportBlackListCsvOpenerDelete = () => {
    document.getElementById("import_csvBlukDeleteOpener").style.display =
      "block";
  };
  const OpenerMapCsvBtnDelete = () => {
    SetTotalDisplayCount(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteOpener").style.display =
        "block";
      Openerresetcsv();
      DropboxData.map((dddd, index) => {});
    } else {
      toast.error(
        <div className="toastsise">
          Opener <br />
          Opener not mapped!
        </div>
      );
    SetTotalDisplayCount(false);
     
    }
  };
  // import Opener csv
  const SaveCsvOpenerBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Opener = document.getElementById("OpenerBox").value.trim();
    if (Opener != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Opener,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvOpener").style.display = "none";
            document.getElementById("map_attributeOpener").style.display =
              "none";
            document.getElementById(
              "map_attributeBlukDeleteOpener"
            ).style.display = "none";
            document.getElementById(
              "import_csvBlukDeleteOpener"
            ).style.display = "none";
            toast.success(
              <div className="toastsise">
                Opener <br />
                Opener deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)

            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            CsvCleanUp(false);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Opener <br />
          Opener not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //=============FUOpener methods================
  const ExportFUOpenerMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "FUOpener",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetFUOpenerExport(Res.data.Data);
    });
  };
  const ExportFUOpenerCsv = () => {
    toast.success(
      <div className="toastsize">
        FUOpener
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const FUOpenerFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("FUOpenerName").value.trim();
    let Exist = await FUOpenerCheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const FUOpenerHandleChange = async (field) => {
    const Name = document.getElementById("FUOpenerName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await FUOpenerCheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const FUOpenerCheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "FUOpener",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  const FUOpenerSaveBtn = async () => {
    SetIsDisabled(true)

    if ((await FUOpenerFormValidation()) && IsExist == false) {
      SetIsLoading(true)

      const Name = document.getElementById("FUOpenerName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "FUOpener",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              FUOpener
              <br />
              FUOpener added successfully.
            </div>
          );
          SetFUOpenerAdd(false);
          SetListUpdated(!ListUpdate);

          SetHotReload(!HotReload);
          SetIsDisabled(false)
          history.push("/defaultvariables");
          SetIsLoading(false)
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }
  };

  //show import
  const FUOpenerresetcsv = () => {
    CsvCleanUp(true);
  };
  const ShowFUOpenerImport = () => {
    document.getElementById("import_csvFUOpener").style.display = "block";
    document.getElementById("map_attributeFUOpener").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleFUOpenerBtn = () => {
    document.getElementById("import_csvFUOpener").style.display = "none";
    document.getElementById("map_attributeFUOpener").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropFUOpener = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      FUOpenerresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorFUOpener = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileFUOpener = (data) => {};

  const ImportBlackListCsvFUOpener = () => {
    document.getElementById("import_csvFUOpener").style.display = "block";
  };
  const FUOpenerMapCsvBtn = () => {
    SetTotalDisplayCount(false);

    document.getElementById("FUOpener").disabled=false
    SetBtnDisabled(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeFUOpener").style.display = "block";
      DropboxData.map((dddd, index) => {});
      FUOpenerresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          FUOpener <br />
          Please select file
        </div>
      );
    SetTotalDisplayCount(false);

      document.getElementById("FUOpener").disabled=false
      document.getElementById("map_attributeFUOpener").style.display = "none";
    SetBtnDisabled(false);

      CsvCleanUp(false);
    }
    hideCSVHeaderOptions()
  };
  // import FUOpener csv
  const FUOpenerListSaveCsv = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var FUOpener = document.getElementById("FUOpener").value.trim();
    if (FUOpener != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: FUOpener,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvFUOpener").style.display =
              "block";
            document.getElementById("map_attributeFUOpener").style.display =
              "block";
      document.getElementById("FUOpener").disabled=true

            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            CsvCleanUp(false)


            SetListUpdated(!ListUpdate);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          FUOpener <br />
          FUOpener not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };
  //show bulk delete
  const ShowFUOpenerDelete = () => {
    document.getElementById("import_csvBlukDeleteFUOpener").style.display =
      "block";
    document.getElementById("map_attributeBlukDeleteFUOpener").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelFUOpenerDelete = () => {
    document.getElementById("import_csvBlukDeleteFUOpener").style.display =
      "none";
    document.getElementById("map_attributeBlukDeleteFUOpener").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropFUOpenerDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      FUOpenerresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorFUOpenerDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileFUOpenerDelete = (data) => {};
  const ImportBlackListCsvFUOpenerDelete = () => {
    document.getElementById("import_csvBlukDeleteFUOpener").style.display =
      "block";
  };
  const FUOpenerMapCsvBtnDelete = () => {
    SetTotalDisplayCount(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteFUOpener").style.display =
        "block";
      DropboxData.map((dddd, index) => {});
      FUOpenerresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          FUOpener <br />
          Please select file
        </div>
      );
      CsvCleanUp(false);
    SetTotalDisplayCount(false);
    document.getElementById("map_attributeBlukDeleteFUOpener").style.display =
    "none";

    }
  };
  // import FUOpener csv
  const SaveCsvFUOpenerBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var FUOpener = document.getElementById("FUOpenerBox").value.trim();
    if (FUOpener != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: FUOpener,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvFUOpener").style.display =
              "none";
            document.getElementById("map_attributeFUOpener").style.display =
              "none";
            document.getElementById(
              "map_attributeBlukDeleteFUOpener"
            ).style.display = "none";
            document.getElementById(
              "import_csvBlukDeleteFUOpener"
            ).style.display = "none";
            toast.success(
              <div className="toastsise">
                FUOpener <br />
                FUOpener deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            CsvCleanUp(false);
            SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)

            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          FUOpener <br />
          FUOpener not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //=====================CTA methods==================
  const ExportCTAMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "CTA",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetCTAExport(Res.data.Data);
    });
  };
  const ExportCTACsv = () => {
    toast.success(
      <div className="toastsize">
        CTA
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const CTAFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("CTAName").value.trim();
    let Exist = await CTACheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const CTAHandleChange = async (field) => {
    const Name = document.getElementById("CTAName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await CTACheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const CTACheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "CTA",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  const CTASaveBtn = async () => {

    SetIsDisabled(true)
    if ((await CTAFormValidation()) && IsExist == false) {
      SetIsLoading(true)

      const Name = document.getElementById("CTAName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "CTA",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              CTA
              <br />
              CTA added successfully.
            </div>
          );
          SetCTAAdd(false);
          SetListUpdated(!ListUpdate);
          SetHotReload(!HotReload);
          SetIsDisabled(false)
          SetIsLoading(false)

          history.push("/defaultvariables");
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }
  };
  //show import
  const CTAresetcsv = () => {
    CsvCleanUp(true);
  };
  const ShowCTAImport = () => {
    document.getElementById("import_csvCTA").style.display = "block";
    document.getElementById("map_attributeCTA").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleCTABtn = () => {
    document.getElementById("import_csvCTA").style.display = "none";
    document.getElementById("map_attributeCTA").style.display = "none";
    document.getElementById("CTA").disabled=false
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropCTA = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      CTAresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorCTA = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileCTA = (data) => {};

  const ImportBlackListCsvCTA = () => {
    document.getElementById("import_csvCTA").style.display = "block";
  };
  const CTAMapCsvBtn = () => {
    SetTotalDisplayCount(false);
    SetBtnDisabled(false);
    document.getElementById("CTA").disabled=false
    if (csvData.length != 0) {
      document.getElementById("map_attributeCTA").style.display = "block";
      SetBtnDisabled(false);
    document.getElementById("CTA").disabled=false
      DropboxData.map((dddd, index) => {});
      CTAresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          CTA
          <br />
          Please select file
        </div>
      );
    SetBtnDisabled(false);
    document.getElementById("CTA").disabled=false
    document.getElementById("map_attributeCTA").style.display = "none";
    SetTotalDisplayCount(false);

      CsvCleanUp(false);
    }
    hideCSVHeaderOptions()
  };
  // import CTA csv
  const CTAListSaveCsv = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var CTA = document.getElementById("CTA").value.trim();
    if (CTA != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: CTA,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvCTA").style.display = "block";
            document.getElementById("map_attributeCTA").style.display = "block";
            document.getElementById("CTA").disabled=true
            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            CsvCleanUp(false);
    
            SetListUpdated(!ListUpdate);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          CTA <br />
          CTA not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };
  //show bulk delete
  const ShowCTADelete = () => {
    document.getElementById("import_csvBlukDeleteCTA").style.display = "block";
    document.getElementById("map_attributeBlukDeleteCTA").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelCTADelete = () => {
    document.getElementById("import_csvBlukDeleteCTA").style.display = "none";
    document.getElementById("map_attributeBlukDeleteCTA").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropCTADelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      CTAresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorCTADelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileCTADelete = (data) => {};
  const ImportBlackListCsvCTADelete = () => {
    document.getElementById("import_csvBlukDeleteCTA").style.display = "block";
  };
  const CTAMapCsvBtnDelete = () => {
    SetTotalDisplayCount(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteCTA").style.display =
        "block";
      DropboxData.map((dddd, index) => {});
      CTAresetcsv();
    } else {
      toast.error(
        <div className="toastsise">
          CTA <br />
          CTA not mapped!
        </div>
      );
      document.getElementById("map_attributeBlukDeleteCTA").style.display = "none";
      CsvCleanUp(false);
    }
  };
  // import CTA csv
  const SaveCsvCTABlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var CTA = document.getElementById("CTABox").value.trim();
    if (CTA != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: CTA,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvCTA").style.display = "none";
            document.getElementById("map_attributeCTA").style.display = "none";
            document.getElementById(
              "map_attributeBlukDeleteCTA"
            ).style.display = "none";
            document.getElementById("import_csvBlukDeleteCTA").style.display =
              "none";
            toast.success(
              <div className="toastsise">
                CTA <br />
                CTA deleted successfully.
              </div>
            );
            CsvCleanUp(false)

            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            SetBtnDisabled(false);
            await  UpdateFromChild(true)
          SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          CTA <br />
          CTA not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //=================Closer methods======================
  const ExportCloserMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "Closer",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetCloserExport(Res.data.Data);
    });
  };
  const ExportCloserCsv = () => {
    toast.success(
      <div className="toastsize">
        Closer
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const CloserFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("CloserName").value.trim();
    let Exist = await CloserCheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const CloserHandleChange = async (field) => {
    const Name = document.getElementById("CloserName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await CloserCheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const CloserCheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "Closer",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  const CloserSaveBtn = async () => {

    SetIsDisabled(true)
    if ((await CloserFormValidation()) && IsExist == false) {
      SetIsLoading(true)
      const Name = document.getElementById("CloserName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "Closer",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Closer
              <br />
              Closer added successfully.
            </div>
          );
          SetCloserAdd(false);
          SetListUpdated(!ListUpdate);
          SetHotReload(!HotReload);
          SetIsDisabled(false)
          SetIsLoading(false)

          history.push("/defaultvariables");
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }

  };
  //show import
  const Closerresetcsv = () => {
    CsvCleanUp(true);
  };
  const ShowCloserImport = () => {
    document.getElementById("import_csvCloser").style.display = "block";
    document.getElementById("map_attributeCloser").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleCloserBtn = () => {
    document.getElementById("import_csvCloser").style.display = "none";
    document.getElementById("map_attributeCloser").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropCloser = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Closerresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorCloser = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileCloser = (data) => {};

  const ImportBlackListCsvCloser = () => {
    document.getElementById("import_csvCloser").style.display = "block";
  };
  const CloserMapCsvBtn = () => {
    document.getElementById("map_attributeCloser").style.display = "block";
    document.getElementById("Closer").disabled = false;
    SetTotalDisplayCount(false);

    if (csvData.length != 0) {
      document.getElementById("Closer").disabled = false;
      document.getElementById("map_attributeCloser").style.display = "block";
      DropboxData.map((dddd, index) => {});
      Closerresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          {" "}
          Closer <br />
          Please select file
        </div>
      );
      document.getElementById("Closer").disabled = false;
      document.getElementById("map_attributeCloser").style.display = "none";
      SetBtnDisabled(false);

      CsvCleanUp(false);
    }
    hideCSVHeaderOptions()
  };
  // import Closer csv
  const CloserListSaveCsv = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Closer = document.getElementById("Closer").value.trim();
    if (Closer != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Closer,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvCloser").style.display = "block";
            document.getElementById("map_attributeCloser").style.display =
              "block";
              document.getElementById("Closer").disabled = true;
        
            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            CsvCleanUp(false);
            SetListUpdated(!ListUpdate);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Closer <br />
          Closer not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };
  //show bulk delete
  const ShowCloserDelete = () => {
    document.getElementById("import_csvBlukDeleteCloser").style.display =
      "block";
    document.getElementById("map_attributeBlukDeleteCloser").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelCloserDelete = () => {
    document.getElementById("import_csvBlukDeleteCloser").style.display =
      "none";
    document.getElementById("map_attributeBlukDeleteCloser").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropCloserDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      SetReset(!Reset);

      Closerresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorCloserDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileCloserDelete = (data) => {};
  const ImportBlackListCsvCloserDelete = () => {
    document.getElementById("import_csvBlukDeleteCloser").style.display =
      "block";
  };
  const CloserMapCsvBtnDelete = () => {
    SetTotalDisplayCount(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteCloser").style.display =
        "block";
      DropboxData.map((dddd, index) => {});
      Closerresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Closer <br />
          Please select file
        </div>
      );
      CsvCleanUp(false);
    }
  };
  // import Closer csv
  const SaveCsvCloserBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Closer = document.getElementById("CloserBox").value.trim();
    if (Closer != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Closer,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvCloser").style.display = "none";
            document.getElementById("map_attributeCloser").style.display =
              "none";
            document.getElementById(
              "map_attributeBlukDeleteCloser"
            ).style.display = "none";
            document.getElementById(
              "import_csvBlukDeleteCloser"
            ).style.display = "none";
            toast.success(
              <div className="toastsise">
                Closer <br />
                Closer deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            CsvCleanUp(false);
            SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)

            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Closer <br />
          Closer not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //==============OptOut methods=================
  const ExportOptOutMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "OptOut",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetOptOutExport(Res.data.Data);
    });
  };
  const ExportOptOutCsv = () => {
    toast.success(
      <div className="toastsize">
        OptOut
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const OptOutFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("OptOutName").value.trim();
    let Exist = await OptOutCheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const OptOutHandleChange = async (field) => {
    const Name = document.getElementById("OptOutName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await OptOutCheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const OptOutCheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "OptOut",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  const OptOutSaveBtn = async () => {

    SetIsDisabled(true)
    if ((await OptOutFormValidation()) && IsExist == false) {
      SetIsLoading(true)

      const Name = document.getElementById("OptOutName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "OptOut",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              OptOut
              <br />
              OptOut added successfully.
            </div>
          );
          SetOptOutAdd(false);
          SetListUpdated(!ListUpdate);
          SetIsDisabled(false)
          SetHotReload(!HotReload);
          SetIsLoading(false)

          history.push("/defaultvariables");
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }
  };
  //show import
  const OptOutresetcsv = () => {
    CsvCleanUp(true);
  };
  const ShowOptOutImport = () => {
    document.getElementById("import_csvOptOut").style.display = "block";
    document.getElementById("map_attributeOptOut").style.display = "none";
    document.getElementById("OptOut").disabled=false

    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleOptOutBtn = () => {
    document.getElementById("import_csvOptOut").style.display = "none";
    document.getElementById("map_attributeOptOut").style.display = "none";
    document.getElementById("OptOut").disabled=false
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropOptOut = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      OptOutresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorOptOut = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileOptOut = (data) => {};

  const ImportBlackListCsvOptOut = () => {
    document.getElementById("import_csvOptOut").style.display = "block";
  };
  const OptOutMapCsvBtn = () => {
    document.getElementById("OptOut").disabled=false
    SetTotalDisplayCount(false);
    SetBtnDisabled(false);

    if (csvData.length != 0) {
      document.getElementById("map_attributeOptOut").style.display = "block";
      DropboxData.map((dddd, index) => {});
      OptOutresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          OptOut
          <br />
          Please select file
        </div>
      );
    document.getElementById("OptOut").disabled=false
    SetBtnDisabled(false);
    document.getElementById("map_attributeOptOut").style.display = "none";

      CsvCleanUp(false);
    }
    hideCSVHeaderOptions()
  };
  // import OptOut csv
  const OptOutListSaveCsv = (FieldName) => {
    var OptOut = document.getElementById("OptOut").value.trim();
    if (OptOut != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: OptOut,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvOptOut").style.display = "block";
            document.getElementById("map_attributeOptOut").style.display =
              "block";
    document.getElementById("OptOut").disabled=true
    SetBtnDisabled(true);

            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            CsvCleanUp(false);
            SetListUpdated(!ListUpdate);
            await  UpdateFromChild(true)
          SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          OptOut <br />
          OptOut not mapped!
        </div>
      );
    }
  };
  //show bulk delete
  const ShowOptOutDelete = () => {
    document.getElementById("import_csvBlukDeleteOptOut").style.display =
      "block";
    document.getElementById("map_attributeBlukDeleteOptOut").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelOptOutDelete = () => {
    document.getElementById("import_csvBlukDeleteOptOut").style.display =
      "none";
    document.getElementById("map_attributeBlukDeleteOptOut").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropOptOutDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      OptOutresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorOptOutDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileOptOutDelete = (data) => {};
  const ImportBlackListCsvOptOutDelete = () => {
    document.getElementById("import_csvBlukDeleteOptOut").style.display =
      "block";
  };
  const OptOutMapCsvBtnDelete = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteOptOut").style.display =
        "block";
      DropboxData.map((dddd, index) => {});
      OptOutresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          OptOut
          <br />
          Please select file
        </div>
      );
      CsvCleanUp(false);
    }
  };
  // import OptOut csv
  const SaveCsvOptOutBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var OptOut = document.getElementById("OptOutBox").value.trim();
    if (OptOut != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: OptOut,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvOptOut").style.display = "none";
            document.getElementById("map_attributeOptOut").style.display =
              "none";
            document.getElementById(
              "map_attributeBlukDeleteOptOut"
            ).style.display = "none";
            document.getElementById(
              "import_csvBlukDeleteOptOut"
            ).style.display = "none";
            toast.success(
              <div className="toastsise">
                OptOut <br />
                OptOut deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          OptOut <br />
          OptOut not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //=================Day Methods==============
  const ExportDayMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "Day",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/defaultvariables/DefaultVariablesExport1",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetDayExport(Res.data.Data);
    });
  };
  const ExportDayCsv = () => {
    toast.success(
      <div className="toastsize">
        Day
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const DayFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("DayName").value.trim();
    let Exist = await DayCheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const DayHandleChange = async (field) => {
    const Name = document.getElementById("DayName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await DayCheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const DayCheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "Day",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists1",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  const DaySaveBtn = async () => {

    SetIsDisabled(true)
    if ((await DayFormValidation()) && IsExist == false) {
      SetIsLoading(true)

      const Name = document.getElementById("DayName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "Day",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd1",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Day
              <br />
              Day added successfully.
            </div>
          );
          SetDayAdd(false);
          SetListUpdated(!ListUpdate);
          SetHotReload(!HotReload);
          SetIsLoading(false)
          SetIsDisabled(false)
          history.push("/defaultvariables");
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }
  };
  //show import
  const Dayresetcsv = () => {
    CsvCleanUp(true);
  };
  const ShowDayImport = () => {
    document.getElementById("import_csvDay").style.display = "block";
    document.getElementById("map_attributeDay").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleDayBtn = () => {
    document.getElementById("import_csvDay").style.display = "none";
    document.getElementById("map_attributeDay").style.display = "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropDay = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Dayresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorDay = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileDay = (data) => {};

  const ImportBlackListCsvDay = () => {
    document.getElementById("import_csvDay").style.display = "block";
  };
  const DayMapCsvBtn = () => {
    SetBtnDisabled(false);
    SetTotalDisplayCount(false);
    document.getElementById("Day").disabled=false

    if (csvData.length != 0) {
      document.getElementById("map_attributeDay").style.display = "block";
      DropboxData.map((dddd, index) => {});
      Dayresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Day <br />
          Please select file
        </div>
      );
      document.getElementById("Day").disabled=false
      document.getElementById("map_attributeDay").style.display = "none";
      SetBtnDisabled(false);
      CsvCleanUp(false);
    }
  };
  // import Day csv
  const DayListSaveCsv = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Day = document.getElementById("Day").value.trim();
    if (Day != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Day,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile1",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvDay").style.display = "block";
            document.getElementById("map_attributeDay").style.display = "block";
      document.getElementById("Day").disabled=true

            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            CsvCleanUp(false);
            SetListUpdated(!ListUpdate);
            // SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Day <br />
          Day not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };
  //show bulk delete
  const ShowDayDelete = () => {
    document.getElementById("import_csvBlukDeleteDay").style.display = "block";
    document.getElementById("map_attributeBlukDeleteDay").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelDayDelete = () => {
    document.getElementById("import_csvBlukDeleteDay").style.display = "none";
    document.getElementById("map_attributeBlukDeleteDay").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropDayDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      SetReset(!Reset);

      Dayresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorDayDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileDayDelete = (data) => {};
  const ImportBlackListCsvDayDelete = () => {
    document.getElementById("import_csvBlukDeleteDay").style.display = "block";
  };
  const DayMapCsvBtnDelete = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteDay").style.display =
        "block";
      DropboxData.map((dddd, index) => {});
      Dayresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Day <br />
          Please select file
        </div>
      );
      CsvCleanUp(false);
    }
    hideCSVHeaderOptions()
  };
  // import Day csv
  const SaveCsvDayBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Day = document.getElementById("DayBox").value.trim();
    if (Day != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Day,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete1",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvDay").style.display = "none";
            document.getElementById("map_attributeDay").style.display = "none";
            document.getElementById(
              "map_attributeBlukDeleteDay"
            ).style.display = "none";
            document.getElementById("import_csvBlukDeleteDay").style.display =
              "none";
            toast.success(
              <div className="toastsise">
                Day <br />
                Day deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            SetBtnDisabled(false);
            await  UpdateFromChild(true)
          SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Day <br />
          Day not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //===========Time Methods=================
  const ExportTimeMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "Time",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/defaultvariables/DefaultVariablesExport1",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetTimeExport(Res.data.Data);
    });
  };
  const ExportTimeCsv = () => {
    toast.success(
      <div className="toastsize">
        Time
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const TimeFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("TimeName").value.trim();
    let Exist = await TimeCheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const TimeHandleChange = async (field) => {
    const Name = document.getElementById("TimeName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await TimeCheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const TimeCheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "Time",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists1",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  const TimeSaveBtn = async () => {
    SetIsDisabled(true)

    if ((await TimeFormValidation()) && IsExist == false) {
      SetIsLoading(true)
 
      const Name = document.getElementById("TimeName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "Time",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd1",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Time
              <br />
              Time added successfully.
            </div>
          );
          SetTimeAdd(false);
          SetListUpdated(!ListUpdate);
          SetHotReload(!HotReload);
          SetIsDisabled(false)
          SetIsLoading(false)

          history.push("/defaultvariables");
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }
  };
  //show import
  const Timeresetcsv = () => {
    CsvCleanUp(true);
  };
  const ShowTimeImport = () => {
    document.getElementById("import_csvTime").style.display = "block";
    document.getElementById("map_attributeTime").style.display = "none";
    SetBtnDisabled(false);

      CsvCleanUp(false);
      document.getElementById("Time").disabled =false;
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleTimeBtn = () => {
    document.getElementById("import_csvTime").style.display = "none";
    document.getElementById("map_attributeTime").style.display = "none";
    document.getElementById("Time").disabled =false;

    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropTime = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Timeresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorTime = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileTime = (data) => {};

  const ImportBlackListCsvTime = () => {
    document.getElementById("import_csvTime").style.display = "block";
  };
  const TimeMapCsvBtn = () => {
    SetTotalDisplayCount(false);

    document.getElementById("Time").disabled =false;
    SetBtnDisabled(false)
    if (csvData.length != 0) {
      document.getElementById("map_attributeTime").style.display = "block";
      DropboxData.map((dddd, index) => {});
      Timeresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Time <br />
          Please select file
        </div>
      );
      document.getElementById("Time").disabled =false;
      document.getElementById("map_attributeTime").style.display = "none";
    SetBtnDisabled(false);

      CsvCleanUp(false);
    }
    hideCSVHeaderOptions()
  };
  // import Time csv
  const TimeListSaveCsv = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Time = document.getElementById("Time").value.trim();
    if (Time != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Time,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile1",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvTime").style.display = "block";
            document.getElementById("map_attributeTime").style.display = "block";
            document.getElementById("Time").disabled =true;

            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            CsvCleanUp(false)

            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            SetListUpdated(!ListUpdate);
            // SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Time <br />
          Time not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };
  //show bulk delete
  const ShowTimeDelete = () => {
    document.getElementById("import_csvBlukDeleteTime").style.display = "block";
    document.getElementById("map_attributeBlukDeleteTime").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelTimeDelete = () => {
    document.getElementById("import_csvBlukDeleteTime").style.display = "none";
    document.getElementById("map_attributeBlukDeleteTime").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropTimeDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Timeresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorTimeDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileTimeDelete = (data) => {};
  const ImportBlackListCsvTimeDelete = () => {
    document.getElementById("import_csvBlukDeleteTime").style.display = "block";
  };
  const TimeMapCsvBtnDelete = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteTime").style.display =
        "block";
      DropboxData.map((dddd, index) => {});
      Timeresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Time <br />
          Please select file
        </div>
      );
      CsvCleanUp(false);
    }
  };
  // import Time csv
  const SaveCsvTimeBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Time = document.getElementById("TimeBox").value.trim();
    if (Time != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Time,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete1",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvTime").style.display = "none";
            document.getElementById("map_attributeTime").style.display = "none";
            document.getElementById(
              "map_attributeBlukDeleteTime"
            ).style.display = "none";
            document.getElementById("import_csvBlukDeleteTime").style.display =
              "none";
            toast.success(
              <div className="toastsise">
                Time <br />
                Time deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            SetBtnDisabled(false);
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Time <br />
          Time not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //==================Length Methods=====================
  const ExportLengthMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "Length",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/defaultvariables/DefaultVariablesExport1",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetLengthExport(Res.data.Data);
    });
  };
  const ExportLengthCsv = () => {
    toast.success(
      <div className="toastsize">
        Length
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const LengthFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("LengthName").value.trim();
    let Exist = await LengthCheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const LengthHandleChange = async (field) => {
    const Name = document.getElementById("LengthName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await LengthCheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const LengthCheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "Length",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists1",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  const LengthSaveBtn = async () => {
    SetIsDisabled(true)

    if ((await LengthFormValidation()) && IsExist == false) {
      SetIsLoading(true)

      const Name = document.getElementById("LengthName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "Length",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd1",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Length
              <br />
              Length added successfully.
            </div>
          );
          SetLengthAdd(false);
          SetListUpdated(!ListUpdate);
          SetHotReload(!HotReload);
          SetIsDisabled(false)
          SetIsLoading(false)

          history.push("/defaultvariables");
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }
  };
  //show import
  const Lengthresetcsv = () => {
    CsvCleanUp(true);
  };
  const ShowLengthImport = () => {
    document.getElementById("import_csvLength").style.display = "block";
    document.getElementById("map_attributeLength").style.display = "none";
    document.getElementById("Length").disabled =false;

    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleLengthBtn = () => {
    document.getElementById("import_csvLength").style.display = "none";
    document.getElementById("map_attributeLength").style.display = "none";
    document.getElementById("Length").disabled =false;
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropLength = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Lengthresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorLength = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileLength = (data) => {};

  const ImportBlackListCsvLength = () => {
    document.getElementById("import_csvLength").style.display = "block";
  };
  const LengthMapCsvBtn = () => {
    SetTotalDisplayCount(false);
    document.getElementById("Length").disabled =false;
    SetBtnDisabled(false)
    if (csvData.length != 0) {
      document.getElementById("map_attributeLength").style.display = "block";
      DropboxData.map((dddd, index) => {});
      Lengthresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Length <br />
          Please select file
        </div>
      );
      document.getElementById("Length").disabled =false;
      document.getElementById("map_attributeLength").style.display = "none";
      SetBtnDisabled(false)

      CsvCleanUp(false);
    }
    hideCSVHeaderOptions(true)
  };
  // import Length csv
  const LengthListSaveCsv = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Length = document.getElementById("Length").value.trim();
    if (Length != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Length,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile1",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvLength").style.display = "block";
            document.getElementById("map_attributeLength").style.display ="block";
            document.getElementById("Length").disabled =true;
            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            CsvCleanUp(false)

            SetListUpdated(!ListUpdate);
            // SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Length <br />
          Length not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };
  //show bulk delete
  const ShowLengthDelete = () => {
    document.getElementById("import_csvBlukDeleteLength").style.display =
      "block";
    document.getElementById("map_attributeBlukDeleteLength").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelLengthDelete = () => {
    document.getElementById("import_csvBlukDeleteLength").style.display =
      "none";
    document.getElementById("map_attributeBlukDeleteLength").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropLengthDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Lengthresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorLengthDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileLengthDelete = (data) => {};
  const ImportBlackListCsvLengthDelete = () => {
    document.getElementById("import_csvBlukDeleteLength").style.display =
      "block";
  };
  const LengthMapCsvBtnDelete = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteLength").style.display =
        "block";
      DropboxData.map((dddd, index) => {});
      Lengthresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Length <br />
          Please select file
        </div>
      );
      CsvCleanUp(false);
    }
  };
  // import Length csv
  const SaveCsvLengthBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Length = document.getElementById("LengthBox").value.trim();
    if (Length != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Length,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete1",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvLength").style.display = "none";
            document.getElementById("map_attributeLength").style.display =
              "none";
            document.getElementById(
              "map_attributeBlukDeleteLength"
            ).style.display = "none";
            document.getElementById(
              "import_csvBlukDeleteLength"
            ).style.display = "none";
            toast.success(
              <div className="toastsise">
                Length <br />
                Length deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Length <br />
          Length not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

  //=================Action Methods===============
  const ExportActionMethod = (CID, UID) => {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
      FieldName: "Action",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/defaultvariables/DefaultVariablesExport1",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName);
      SetActionExport(Res.data.Data);
    });
  };
  const ExportActionCsv = () => {
    toast.success(
      <div className="toastsize">
        Action
        <br />
        Data exported successfully.
      </div>
    );
  };
  // form validation
  const ActionFormValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("ActionName").value.trim();
    let Exist = await ActionCheckDefaultVariableExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter value";
      SetIsDisabled(false)
    }
    if (StatusAvailable != null || Exist == true) {
      FormIsValid = false;
      SetIsDisabled(false)
    }
    if (Exist == true) {
      SetIsExist(true);
      SetIsDisabled(false)
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // handle change
  const ActionHandleChange = async (field) => {
    const Name = document.getElementById("ActionName").value.trim();
    let Field = Fields;
    Field[field] = Name;
    SetFields(Field);
    if (Fields.Objection != "") {
      Errors.Objection = null;
      SetStatusAvailable(null);
      await ActionCheckDefaultVariableExist(Name);
    } else {
      SetStatusAvailable(null);
      Errors.Name = null;
    }
  };
  //check exist Status
  const ActionCheckDefaultVariableExist = async (Name) => {
    var Inputparameter = {
      UserID: UserID,
      FeatureValue: Name,
      FieldName: "Action",
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists1",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  const ActionSaveBtn = async () => {
   
    SetIsDisabled(true)
    if ((await ActionFormValidation()) && IsExist == false) {
      SetIsLoading(true)

      const Name = document.getElementById("ActionName").value.trim();
      const InputParameter = {
        ClientID: ClientID,
        UserID: UserID,
        FeatureValue: Name,
        FeatureFieldID: "Action",
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd1",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Action
              <br />
              Action added successfully.
            </div>
          );
          SetActionAdd(false);
          SetListUpdated(!ListUpdate);
          SetHotReload(!HotReload);
          SetIsDisabled(false)
          SetIsLoading(false)

          history.push("/defaultvariables");
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false)
          SetIsDisabled(false)
        }
      });
    }

  };
  //show import
  const Actionresetcsv = () => {
    CsvCleanUp(true);
  };
  const ShowActionImport = () => {
    document.getElementById("import_csvAction").style.display = "block";
    document.getElementById("map_attributeAction").style.display = "none";
    document.getElementById("Action").disabled =false;
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const CancleActionBtn = () => {
    document.getElementById("import_csvAction").style.display = "none";
    document.getElementById("map_attributeAction").style.display = "none";
    document.getElementById("Action").disabled =false;
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  const HandleOnDropAction = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Actionresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorAction = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileAction = (data) => {};

  const ImportBlackListCsvAction = () => {
    document.getElementById("import_csvAction").style.display = "block";
  };
  const ActionMapCsvBtn = () => {
    document.getElementById("Action").disabled =false;
    SetTotalDisplayCount(false);
    SetBtnDisabled(false)
    console.log(csvData.length)
    if (csvData.length != 0) {
      document.getElementById("map_attributeAction").style.display = "block";
      DropboxData.map((dddd, index) => {});
      Actionresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Action <br />
          Please select file
        </div>
      );
    document.getElementById("Action").disabled =false;
    document.getElementById("map_attributeAction").style.display = "none";


      CsvCleanUp(false);
    }
    hideCSVHeaderOptions()
  };
  // import Action csv
  const ActionListSaveCsv = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Action = document.getElementById("Action").value.trim();
    if (Action != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Action,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableImportFile1",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvAction").style.display = "block";
            document.getElementById("map_attributeAction").style.display ="block";
            document.getElementById("Action").disabled =true;
          
            SetTotalDisplayCount(true);
            SetTotalSuccess(res.data.Totalsuccess);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetErrors(0);
            SetTotal(res.data.Total);
            SetHotReload(!HotReload);
            CsvCleanUp(false)
            SetListUpdated(!ListUpdate);
            // SetBtnDisabled(false);
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Action <br />
          Action not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };
  //show bulk delete
  const ShowActionDelete = () => {
    document.getElementById("import_csvBlukDeleteAction").style.display =
      "block";
    document.getElementById("map_attributeBlukDeleteAction").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };
  //Hide Bulk Delete
  const CancelActionDelete = () => {
    document.getElementById("import_csvBlukDeleteAction").style.display =
      "none";
    document.getElementById("map_attributeBlukDeleteAction").style.display =
      "none";
    CsvCleanUp(false);
    SetBtnDisabled(false);
    SetIsLoading(false)
  };

  const HandleOnDropActionDelete = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      Actionresetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  const HandleOnErrorActionDelete = (err, file, inputElem, reason) => {};

  const HandleOnRemoveFileActionDelete = (data) => {};
  const ImportBlackListCsvActionDelete = () => {
    document.getElementById("import_csvBlukDeleteAction").style.display =
      "block";
  };
  const ActionMapCsvBtnDelete = () => {
    if (csvData.length != 0) {
      document.getElementById("map_attributeBlukDeleteAction").style.display =
        "block";
      DropboxData.map((dddd, index) => {});
      Actionresetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Action <br />
          Please select file
        </div>
      );
      CsvCleanUp(false);
    }
  };
  // import Action csv
  const SaveCsvActionBlukDelete = (FieldName) => {
    SetBtnDisabled(true);
    SetIsLoading(true)
    var Action = document.getElementById("ActionBox").value.trim();
    if (Action != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParameters = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/DefaultVariable",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          Greeting: Action,
          ClientID: ClientID,
          FieldName: FieldName,
          UserID: UserID,
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/defaultvariables/DefaultVariableBulkDelete1",
          method: "POST",
          data: InputParameters,
        }).then(async(res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById("import_csvAction").style.display = "none";
            document.getElementById("map_attributeAction").style.display =
              "none";
            document.getElementById(
              "map_attributeBlukDeleteAction"
            ).style.display = "none";
            document.getElementById(
              "import_csvBlukDeleteAction"
            ).style.display = "none";
            toast.success(
              <div className="toastsise">
                Action <br />
                Action deleted successfully.
              </div>
            );
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            SetBtnDisabled(false);
            await  UpdateFromChild(true)
            SetIsLoading(false)
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetBtnDisabled(false);
            SetIsLoading(false)
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsise">
          Action <br />
          Action not mapped!
        </div>
      );
      SetBtnDisabled(false);
      SetIsLoading(false)
    }
  };

    //=================Disclaimer Methods===============
    const ExportDisclaimerMethod = (CID, UID) => {
      const InputParameters = {
        UserID: UID,
        ClientID: CID,
        FieldName: "Disclaimer",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/defaultvariables/DefaultVariablesExport",
        method: "POST",
        data: InputParameters,
      }).then((Res) => {
        SetClientName(Res.data.ClientName);
        SetDisclaimerExport(Res.data.Data);
      });
    };
    const ExportDisclaimerCsv = () => {
      toast.success(
        <div className="toastsize">
          Disclaimer
          <br />
          Data exported successfully.
        </div>
      );
    };
    // form validation
    const DisclaimerFormValidation = async () => {
      let FormIsValid = true;
      let Errors = {};
      var Name = document.getElementById("DisclaimerName").value.trim();
      let Exist = await DisclaimerCheckDefaultVariableExist(Name);
      if (Name.length <= 0) {
        FormIsValid = false;
        Errors["Name"] = "Please enter value";
        SetIsDisabled(false)
      }
      if (StatusAvailable != null || Exist == true) {
        FormIsValid = false;
        SetIsDisabled(false)
      }
      if (Exist == true) {
        SetIsExist(true);
        SetIsDisabled(false)
      }
      SetErrors(Errors);
      return FormIsValid;
    };
    // handle change
    const DisclaimerHandleChange = async (field) => {
      const Name = document.getElementById("DisclaimerName").value.trim();
      let Field = Fields;
      Field[field] = Name;
      SetFields(Field);
      if (Fields.Objection != "") {
        Errors.Objection = null;
        SetStatusAvailable(null);
        await DisclaimerCheckDefaultVariableExist(Name);
      } else {
        SetStatusAvailable(null);
        Errors.Name = null;
      }
    };
    //check exist Status
    const DisclaimerCheckDefaultVariableExist = async (Name) => {
      var Inputparameter = {
        UserID: UserID,
        FeatureValue: Name,
        FieldName: "Disclaimer",
      };
      let resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariableExists",
        method: "POST",
        data: Inputparameter,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          SetStatusAvailable(resdata.data.Data[0]?.FeatureValue);
          SetIsExist(true);
          return true;
        } else {
          SetIsExist(false);
          return false;
        }
      }
    };
    const DisclaimerSaveBtn = async () => {
     
      SetIsDisabled(true)
      if ((await DisclaimerFormValidation()) && IsExist == false) {
        SetIsLoading(true)
  
        const Name = document.getElementById("DisclaimerName").value.trim();
        const InputParameter = {
          ClientID: ClientID,
          UserID: UserID,
          FeatureValue: Name,
          FeatureFieldID: "Disclaimer",
          CreatedDate: new Date(),
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/defaultvariables/DefaultVariablesAdd",
          method: "POST",
          data: InputParameter,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                Disclaimer
                <br />
                Disclaimer added successfully.
              </div>
            );
            SetDisclaimerAdd(false);
            SetListUpdated(!ListUpdate);
            SetHotReload(!HotReload);
            SetIsDisabled(false)
            SetIsLoading(false)
  
            history.push("/defaultvariables");
          } else {
            toast.error(res.data.Message);
            SetIsLoading(false)
            SetIsDisabled(false)
          }
        });
      }
  
    };
    //show import
    const Disclaimerresetcsv = () => {
      CsvCleanUp(true);
    };
    const ShowDisclaimerImport = () => {
      document.getElementById("import_csvDisclaimer").style.display = "block";
      document.getElementById("map_attributeDisclaimer").style.display = "none";
      document.getElementById("Disclaimer").disabled =false;
      CsvCleanUp(false);
      SetBtnDisabled(false);
      SetIsLoading(false)
    };
    const CancleDisclaimerBtn = () => {
      document.getElementById("import_csvDisclaimer").style.display = "none";
      document.getElementById("map_attributeDisclaimer").style.display = "none";
      document.getElementById("Disclaimer").disabled =false;
      CsvCleanUp(false);
      SetBtnDisabled(false);
      SetIsLoading(false)
    };
    const HandleOnDropDisclaimer = (data, fileInfo) => {
      var filename = fileInfo.name;
      setfiles(fileInfo);
      var sep = filename.split(".");
      if (sep[sep.length-1]!= "csv") {
        Disclaimerresetcsv();
      } else {
        var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
        const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
        setfilename(csvfile);
  
        setcsvData(filteredData);
  
        SetDropboxData(filteredData[0].data);
      }
    };
  
    const HandleOnErrorDisclaimer = (err, file, inputElem, reason) => {};
  
    const HandleOnRemoveFileDisclaimer = (data) => {};
  
    const ImportBlackListCsvDisclaimer = () => {
      document.getElementById("import_csvDisclaimer").style.display = "block";
    };
    const DisclaimerMapCsvBtn = () => {
      document.getElementById("Disclaimer").disabled =false;
      SetTotalDisplayCount(false);
      SetBtnDisabled(false)
      console.log(csvData.length)
      if (csvData.length != 0) {
        document.getElementById("map_attributeDisclaimer").style.display = "block";
        DropboxData.map((dddd, index) => {});
        Disclaimerresetcsv();
      } else {
        toast.error(
          <div className="toastsize">
            Disclaimer <br />
            Please select file
          </div>
        );
      document.getElementById("Disclaimer").disabled =false;
      document.getElementById("map_attributeDisclaimer").style.display = "none";
  
  
        CsvCleanUp(false);
      }
      hideCSVHeaderOptions()
    };
    // import Disclaimer csv
    const DisclaimerListSaveCsv = (FieldName) => {
      SetBtnDisabled(true);
      SetIsLoading(true)
      var Disclaimer = document.getElementById("Disclaimer").value.trim();
      if (Disclaimer != "not mapped") {
        var csvdata = csvData;
        Fileupload.getBase64(files, (result) => {
          var rs = result;
          var splirs = rs.split("base64,");
          var _base64 = splirs[1];
  
          var InputParameters = {
            Fields: "Coverimg",
            Path: "./Content/UploadedFiles/DefaultVariable",
            File: _base64,
            ImageName: filename,
            FileData: csvdata,
            Greeting: Disclaimer,
            ClientID: ClientID,
            FieldName: FieldName,
            UserID: UserID,
            CreatedDate: new Date(),
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/defaultvariables/DefaultVariableImportFile",
            method: "POST",
            data: InputParameters,
          }).then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              document.getElementById("import_csvDisclaimer").style.display = "block";
              document.getElementById("map_attributeDisclaimer").style.display ="block";
              document.getElementById("Disclaimer").disabled =true;
            
              SetTotalDisplayCount(true);
              SetTotalSuccess(res.data.Totalsuccess);
              SetTotalDuplicate(res.data.TotalDuplication);
              SetErrors(0);
              SetTotal(res.data.Total);
              SetHotReload(!HotReload);
              CsvCleanUp(false)
              SetListUpdated(!ListUpdate);
              // SetBtnDisabled(false);
              SetIsLoading(false)
              history.push("/defaultvariables");
            } else {
              toast.error(res.data.Message);
              SetBtnDisabled(false);
              SetIsLoading(false)
            }
          });
        });
      } else {
        toast.error(
          <div className="toastsise">
            Disclaimer <br />
            Disclaimer not mapped!
          </div>
        );
        SetBtnDisabled(false);
        SetIsLoading(false)
      }
    };
    //show bulk delete
    const ShowDisclaimerDelete = () => {
      document.getElementById("import_csvBlukDeleteDisclaimer").style.display =
        "block";
      document.getElementById("map_attributeBlukDeleteDisclaimer").style.display =
        "none";
      CsvCleanUp(false);
      SetBtnDisabled(false);
      SetIsLoading(false)
    };
    //Hide Bulk Delete
    const CancelDisclaimerDelete = () => {
      document.getElementById("import_csvBlukDeleteDisclaimer").style.display =
        "none";
      document.getElementById("map_attributeBlukDeleteDisclaimer").style.display =
        "none";
      CsvCleanUp(false);
      SetBtnDisabled(false);
      SetIsLoading(false)
    };
  
    const HandleOnDropDisclaimerDelete = (data, fileInfo) => {
      var filename = fileInfo.name;
      setfiles(fileInfo);
      var sep = filename.split(".");
      if (sep[sep.length-1]!= "csv") {
        Disclaimerresetcsv();
      } else {
        var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
        const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
        setfilename(csvfile);
  
        setcsvData(filteredData);
  
        SetDropboxData(filteredData[0].data);
      }
    };
  
    const HandleOnErrorDisclaimerDelete = (err, file, inputElem, reason) => {};
  
    const HandleOnRemoveFileDisclaimerDelete = (data) => {};
    const ImportBlackListCsvDisclaimerDelete = () => {
      document.getElementById("import_csvBlukDeleteDisclaimer").style.display =
        "block";
    };
    const DisclaimerMapCsvBtnDelete = () => {
      if (csvData.length != 0) {
        document.getElementById("map_attributeBlukDeleteDisclaimer").style.display =
          "block";
        DropboxData.map((dddd, index) => {});
        Disclaimerresetcsv();
      } else {
        toast.error(
          <div className="toastsize">
            Disclaimer <br />
            Please select file
          </div>
        );
        CsvCleanUp(false);
      }
    };
    // import Disclaimer csv
    const SaveCsvDisclaimerBlukDelete = (FieldName) => {
      SetBtnDisabled(true);
      SetIsLoading(true)
      var Disclaimer = document.getElementById("DisclaimerBox").value.trim();
      if (Disclaimer != "not mapped") {
        var csvdata = csvData;
        Fileupload.getBase64(files, (result) => {
          var rs = result;
          var splirs = rs.split("base64,");
          var _base64 = splirs[1];
  
          var InputParameters = {
            Fields: "Coverimg",
            Path: "./Content/UploadedFiles/DefaultVariable",
            File: _base64,
            ImageName: filename,
            FileData: csvdata,
            Greeting: Disclaimer,
            ClientID: ClientID,
            FieldName: FieldName,
            UserID: UserID,
            CreatedDate: new Date(),
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/defaultvariables/DefaultVariableBulkDelete1",
            method: "POST",
            data: InputParameters,
          }).then(async(res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              document.getElementById("import_csvDisclaimer").style.display = "none";
              document.getElementById("map_attributeDisclaimer").style.display =
                "none";
              document.getElementById(
                "map_attributeBlukDeleteDisclaimer"
              ).style.display = "none";
              document.getElementById(
                "import_csvBlukDeleteDisclaimer"
              ).style.display = "none";
              toast.success(
                <div className="toastsise">
                  Disclaimer <br />
                  Disclaimer deleted successfully.
                </div>
              );
              SetListUpdated(!ListUpdate);
              SetHotReload(!HotReload);
              SetBtnDisabled(false);
              await  UpdateFromChild(true)
              SetIsLoading(false)
              history.push("/defaultvariables");
            } else {
              toast.error(res.data.Message);
              SetBtnDisabled(false);
              SetIsLoading(false)
            }
          });
        });
      } else {
        toast.error(
          <div className="toastsise">
            Disclaimer <br />
            Disclaimer not mapped!
          </div>
        );
        SetBtnDisabled(false);
        SetIsLoading(false)
      }
    };
  
  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="tabdes">
        <div>
          <AppBar position="static">
            <Tabs 
              value={value}
              onChange={handleChange} variant="scrollable"
              aria-label="simple tabs example"
            >
              <Tab label="Subject" {...a11yProps(0)}>
                Overviewasdasd
              </Tab>
              <Tab label="Greeting" {...a11yProps(1)} />
              <Tab label="Opener" {...a11yProps(2)} />
              <Tab label="FUOpener" {...a11yProps(3)} />
              <Tab label="CTA" {...a11yProps(4)} />
              <Tab label="Closer" {...a11yProps(5)} />
              <Tab label="OptOut" {...a11yProps(6)} />
              <Tab label="Day" {...a11yProps(7)} />
              <Tab label="Time" {...a11yProps(8)} />
              <Tab label="Length" {...a11yProps(9)} />
              <Tab label="Action" {...a11yProps(10)} />
              <Tab label="Disclaimer" {...a11yProps(11)} /> 

            </Tabs>
          </AppBar>

          <TabPanel value={value} className="tagpaddnone whitebg inpt-0 bg-white" index={0}>
            <div className="tabmainerinner pt-0 pb-2 px-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd xs-headertitle">Subject</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetSubjectAdd(true);
                            SetTotalDisplayCount(false);
                            CancelSubjectDelete();
                            CancleSubjectBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowSubjectImport();
                            CancelSubjectDelete();
                            SetSubjectAdd(false);
                            SetErrors({});
                            SetTotalDisplayCount(false);
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <Tooltip title="Export"><li> 
                      <CSVDownloader className="px-0"
                            data={SubjectExport}
                            filename={`${ClientName}-Subject`}
                            // bom={true}
                          ><a
                          onClick={() => {
                            ExportSubjectCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetTotalDisplayCount(false);
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          
                            <i className="la la-icon-font-size-13 la-download"></i> 
                        </a></CSVDownloader> 
                      </li></Tooltip>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowSubjectDelete();
                            SetTotalDisplayCount(false);
                            SetTotalDisplayCount(false);
                            CancleSubjectBtn();
                            SetSubjectAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom "
                id="import_csvSubject"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import Subject</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropSubject}
                        onError={HandleOnErrorSubject}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileSubject}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add Subject data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={SubjectMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancleSubjectBtn}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody pb-3 p-0 my-0 border-bottom map_attribute"
                id="map_attributeSubject"
                style={{ display: "none" }}
              >
                <div className="row col-xs-12 mx-0">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Subject">
                            Subject
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="Subject"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Subject == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="subject"): Subject}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        SubjectListSaveCsv("Subject");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody border-bottom py-4 my-0 p-0"
                style={{ display: "none" }}
                id="import_csvBlukDeleteSubject"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete Subject</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropSubjectDelete}
                        onError={HandleOnErrorSubjectDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileSubjectDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add Subject data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={SubjectMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelSubjectDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteSubject"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Subject">
                            Subject
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="SubjectBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Subject == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="subject"): Subject}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        SaveCsvSubjectBlukDelete("Subject");
                      }}
                      disabled={BtnDisabled}
                      className="btn btn-primary btn-lightgreen mx-0"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {SubjectAdd === true ? (
                <div className="px-0 pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Subject
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            name="Name"
                            id="Name"
                            onBlur={() => {
                              HandleChange("Name");
                            }}
                            type="text"
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            SaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetSubjectAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col"> 
                    <SubjectTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    /> 
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={1}>
            <div className="tabmainerinner pt-0 pb-2 px-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">Greeting</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetGreetingAdd(true);
                            SetTotalDisplayCount(false);
                            CancelGreetingDelete();
                            CancleGreetingBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowGreetingImport();
                            CancelGreetingDelete();
                            SetGreetingAdd(false);
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <Tooltip title="Export"><li>
                       <a
                          onClick={() => {
                            ExportGreetingCsv();
                            SetTotalDisplayCount(false);
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={GreetingExport}
                            filename={`${ClientName}-Greeting`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a>
                      </li></Tooltip>
                      <li>
                      <Tooltip title="Delete"><a
                          onClick={() => {
                            ShowGreetingDelete();
                            SetTotalDisplayCount(false);
                            CancleGreetingBtn();
                            SetGreetingAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {GreetingAdd === true ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Greeting
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            name="GreetingName"
                            id="GreetingName"
                            onBlur={() => {
                              GreetingHandleChange("GreetingName");
                            }}
                            type="text"
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            GreetingSaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetGreetingAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvGreeting"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import Greeting</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropGreeting}
                        onError={HandleOnErrorGreeting}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileGreeting}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add greeting data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={GreetingMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancleGreetingBtn}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeGreeting"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Greeting">
                            Greeting
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="Greeting"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Greeting == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="greeting"): Greeting}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        GreetingListSaveCsv("Greeting");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDelete"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete Greeting</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropGreetingDelete}
                        onError={HandleOnErrorGreetingDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileGreetingDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add greeting data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={GreetingMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelGreetingDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDelete"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Greeting">
                            Greeting
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="GreetingBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Greeting == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="greeting"): Greeting}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        SaveCsvGreetingBlukDelete("Greeting");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="row">
                <div className="col"> 
                    <GreetingTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div> 
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={2}>
            <div className="tabmainerinner p-0">
              <div className="row mx-0 align-items-center borderbtnm">
                <div className="col">
                  <h4 className="headertitlebd">Opener</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetOpenerAdd(true);
                            SetTotalDisplayCount(false);
                            CancelOpenerDelete();
                            CancleOpenerBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowOpenerImport();
                            CancelOpenerDelete();
                            SetTotalDisplayCount(false);
                            SetOpenerAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <Tooltip title="Export"><li>
                        <a
                          onClick={() => {
                            ExportOpenerCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={OpenerExport}
                            filename={`${ClientName}-Opener`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a>
                      </li></Tooltip>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowOpenerDelete();
                            SetTotalDisplayCount(false);
                            CancleOpenerBtn();
                            SetOpenerAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvOpener"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import Opener</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropOpener}
                        onError={HandleOnErrorOpener}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileOpener}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add Opener data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={OpenerMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancleOpenerBtn}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeOpener"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Opener">
                            Opener
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="Opener"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Opener == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="opener"): Opener}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        OpenerListSaveCsv("Opener");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDeleteOpener"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete Opener</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropOpenerDelete}
                        onError={HandleOnErrorOpenerDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileOpenerDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add Opener data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={OpenerMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelOpenerDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteOpener"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Opener">
                            Opener
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="OpenerBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Opener == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="opener"): FUOpener}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        SaveCsvOpenerBlukDelete("Opener");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {OpenerAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Opener
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            name="OpenerName"
                            id="OpenerName"
                            onBlur={() => {
                              OpenerHandleChange("OpenerName");
                            }}
                            type="text"
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                                 disabled={IsDisabled}
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            OpenerSaveBtn();
                          }}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetOpenerAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <OpenerTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div> 
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={3}>
            <div className="tabmainerinner p-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">FUOpener</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetFUOpenerAdd(true);
                            SetTotalDisplayCount(false);
                            CancelFUOpenerDelete();
                            CancleFUOpenerBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowFUOpenerImport();
                            CancelFUOpenerDelete();
                            SetTotalDisplayCount(false);
                            SetFUOpenerAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <Tooltip title="Export"><li>
                        <a
                          onClick={() => {
                            ExportFUOpenerCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={FUOpenerExport}
                            filename={`${ClientName}-FUOpener`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a>
                      </li></Tooltip>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowFUOpenerDelete();
                            SetTotalDisplayCount(false);
                            CancleFUOpenerBtn();
                            SetFUOpenerAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvFUOpener"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import FUOpener</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropFUOpener}
                        onError={HandleOnErrorFUOpener}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileFUOpener}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add FUOpener data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={FUOpenerMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancleFUOpenerBtn}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeFUOpener"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="FUOpener">
                            FUOpener
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="FUOpener"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ FUOpener == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="fuopener"): FUOpener}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        FUOpenerListSaveCsv("FUOpener");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDeleteFUOpener"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete FUOpener</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropFUOpenerDelete}
                        onError={HandleOnErrorFUOpenerDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileFUOpenerDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add FUOpener data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={FUOpenerMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelFUOpenerDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteFUOpener"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="FUOpener">
                            FUOpener
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="FUOpenerBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ FUOpener == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="fuopener"): FUOpener}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        SaveCsvFUOpenerBlukDelete("FUOpener");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {FUOpenerAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          FUOpener
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            name="FUOpenerName"
                            id="FUOpenerName"
                            onBlur={() => {
                              FUOpenerHandleChange("FUOpenerName");
                            }}
                            type="text"
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            FUOpenerSaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetFUOpenerAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <FUOpenerTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div> 
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={4}>
            <div className="tabmainerinner p-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">CTA</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetCTAAdd(true);
                            SetTotalDisplayCount(false);
                            CancelCTADelete();
                            CancleCTABtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowCTAImport();
                            CancelCTADelete();
                            SetTotalDisplayCount(false);
                            SetCTAAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <li> <Tooltip title="Export">
                        <a
                          onClick={() => {
                            ExportCTACsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={CTAExport}
                            filename={`${ClientName}-CTA`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a></Tooltip>
                      </li>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowCTADelete();
                            SetTotalDisplayCount(false);
                            CancleCTABtn();
                            SetCTAAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvCTA"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import CTA</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropCTA}
                        onError={HandleOnErrorCTA}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileCTA}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add CTA data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={CTAMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a onClick={CancleCTABtn} className="btn btn-secondary">
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeCTA"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="CTA">
                            CTA
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="CTA"
                          value={ CTA == null ?DropboxData.findIndex((item) =>item.toLowerCase() ==="cta"): CTA}
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        CTAListSaveCsv("CTA");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDeleteCTA"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete CTA</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropCTADelete}
                        onError={HandleOnErrorCTADelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileCTADelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add CTA data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={CTAMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelCTADelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteCTA"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="CTA">
                            CTA
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="CTABox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ CTA == null ?DropboxData.findIndex((item) =>item.toLowerCase() ==="cta"): CTA}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        SaveCsvCTABlukDelete("CTA");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {CTAAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          CTA
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            name="CTAName"
                            id="CTAName"
                            onBlur={() => {
                              CTAHandleChange("CTAName");
                            }}
                            type="text"
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            CTASaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetCTAAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <CTATable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div>
                </div> 
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={5}>
            <div className="tabmainerinner p-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">Closer</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetCloserAdd(true);
                            SetTotalDisplayCount(false);
                            CancelCloserDelete();
                            CancleCloserBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="import">
                        <a
                          onClick={() => {
                            ShowCloserImport();
                            CancelCloserDelete();
                            SetTotalDisplayCount(false);
                            SetCloserAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Export">
                        <a
                          onClick={() => {
                            ExportCloserCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={CloserExport}
                            filename={`${ClientName}-Closer`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a></Tooltip>
                      </li>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowCloserDelete();
                            SetTotalDisplayCount(false);
                            CancleCloserBtn();
                            SetCloserAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvCloser"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import Closer</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropCloser}
                        onError={HandleOnErrorCloser}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileCloser}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add Closer data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={CloserMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancleCloserBtn}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeCloser"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Closer">
                            Closer
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="Closer"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Closer == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="closer"): Closer}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        CloserListSaveCsv("Closer");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDeleteCloser"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete Closer</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropCloserDelete}
                        onError={HandleOnErrorCloserDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileCloserDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add Closer data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={CloserMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelCloserDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteCloser"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Closer">
                            Closer
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="CloserBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Closer == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="closer"): Closer}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        SaveCsvCloserBlukDelete("Closer");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {CloserAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Closer
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            name="CloserName"
                            id="CloserName"
                            onBlur={() => {
                              CloserHandleChange("CloserName");
                            }}
                            type="text"
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            CloserSaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetCloserAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <CloserTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div>
                </div> 
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={6}>
            <div className="tabmainerinner p-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">OptOut</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetOptOutAdd(true);
                            SetTotalDisplayCount(false);
                            CancelOptOutDelete();
                            CancleOptOutBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowOptOutImport();
                            CancelOptOutDelete();
                            SetTotalDisplayCount(false);
                            SetOptOutAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Export">
                        <a
                          onClick={() => {
                            ExportOptOutCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={OptOutExport}
                            filename={`${ClientName}-OptOut`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a></Tooltip>
                      </li>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowOptOutDelete();
                            SetTotalDisplayCount(false);
                            CancleOptOutBtn();
                            SetOptOutAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvOptOut"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import OptOut</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropOptOut}
                        onError={HandleOnErrorOptOut}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileOptOut}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add OptOut data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={OptOutMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancleOptOutBtn}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeOptOut"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="OptOut">
                            OptOut
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="OptOutBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ OtpOut == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="optout"): OtpOut}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        OptOutListSaveCsv("OptOut");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDeleteOptOut"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete OptOut</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropOptOutDelete}
                        onError={HandleOnErrorOptOutDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileOptOutDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add OptOut data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={OptOutMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelOptOutDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteOptOut"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="OptOut">
                            OptOut
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="OptOut"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ OtpOut == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="optout"): OtpOut}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        SaveCsvOptOutBlukDelete("OptOut");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {OptOutAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          OptOut
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            name="OptOutName"
                            id="OptOutName"
                            onBlur={() => {
                              OptOutHandleChange("OptOutName");
                            }}
                            type="text"
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            OptOutSaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetOptOutAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <OptOutTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div> 
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white " index={7}>
            <div className="tabmainerinner p-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">Day</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetDayAdd(true);
                            SetTotalDisplayCount(false);
                            CancelDayDelete();
                            CancleDayBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowDayImport();
                            CancelDayDelete();
                            SetTotalDisplayCount(false);
                            SetDayAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Export">
                        <a
                          onClick={() => {
                            ExportDayCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={DayExport}
                            filename={`${ClientName}-Day`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a></Tooltip>
                      </li>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowDayDelete();
                            SetTotalDisplayCount(false);
                            CancleDayBtn();
                            SetDayAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvDay"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import Day</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropDay}
                        onError={HandleOnErrorDay}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileDay}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add Day data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={DayMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a onClick={CancleDayBtn} className="btn btn-secondary">
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeDay"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Domain">
                            Day
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="Day"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Day == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="day"): Day}
                          
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      onClick={() => {
                        DayListSaveCsv("Day");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                      disabled={BtnDisabled}
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDeleteDay"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete Day</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropDayDelete}
                        onError={HandleOnErrorDayDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileDayDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add Day data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={DayMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelDayDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteDay"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Day">
                            Day
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="DayBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Day == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="day"): Day}
                        
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      disabled={BtnDisabled}
                      onClick={() => {
                        SaveCsvDayBlukDelete("Day");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {DayAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Day
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            id="DayName"
                            name="Day"
                            type="text"
                            onBlur={() => {
                              DayHandleChange("Day");
                            }}
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            DaySaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetDayAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <DayTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div> 
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={8}>
            <div className="tabmainerinner p-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">Time</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetTimeAdd(true);
                            SetTotalDisplayCount(false);
                            CancelTimeDelete();
                            CancleTimeBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowTimeImport();
                            SetTimeAdd(false);
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Export">
                        <a
                          onClick={() => {
                            ExportTimeCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={TimeExport}
                            filename={`${ClientName}-Time`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a></Tooltip>
                      </li>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowTimeDelete();
                            SetTotalDisplayCount(false);
                            CancleTimeBtn();
                            SetTimeAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvTime"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import Time</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropTime}
                        onError={HandleOnErrorTime}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileTime}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add Time data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={TimeMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a onClick={CancleTimeBtn} className="btn btn-secondary">
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeTime"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Domain">
                            Time
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="Time"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Time == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="time"): Time}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      disabled={BtnDisabled}
                      onClick={() => {
                        TimeListSaveCsv("Time");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDeleteTime"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete Time</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropTimeDelete}
                        onError={HandleOnErrorTimeDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileTimeDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add Time data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={TimeMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelTimeDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteTime"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Time">
                            Time
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="TimeBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Time == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="time"): Time}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      disabled={BtnDisabled}
                      onClick={() => {
                        SaveCsvTimeBlukDelete("Time");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {TimeAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Time
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            type="text"
                            name="Time"
                            id="TimeName"
                            onBlur={() => {
                              TimeHandleChange("TimeName");
                            }}
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            TimeSaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetTimeAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <TimeTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div>
                </div> 
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={9}>
            <div className="tabmainerinner p-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">Length</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li><Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetLengthAdd(true);
                            SetTotalDisplayCount(false);
                            CancelLengthDelete();
                            CancleLengthBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowLengthImport();
                            CancelLengthDelete();
                            SetTotalDisplayCount(false);
                            SetLengthAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Export">
                        <a
                          onClick={() => {
                            ExportLengthCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={LengthExport}
                            filename={`${ClientName}-Length`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a></Tooltip>
                      </li>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowLengthDelete();
                            SetTotalDisplayCount(false);
                            CancleLengthBtn();
                            SetLengthAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvLength"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import Length</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropLength}
                        onError={HandleOnErrorLength}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileLength}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add Length data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={LengthMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancleLengthBtn}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeLength"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Domain">
                            Length
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="Length"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Length == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="length"): Length}

                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      disabled={BtnDisabled}
                      onClick={() => {
                        LengthListSaveCsv("Length");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDeleteLength"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete Length</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropLengthDelete}
                        onError={HandleOnErrorLengthDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileLengthDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add Length data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={LengthMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelLengthDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteLength"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Length">
                            Length
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="LengthBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Length == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="length"): Length}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      disabled={BtnDisabled}
                      onClick={() => {
                        SaveCsvLengthBlukDelete("Length");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {LengthAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Length
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            type="text"
                            name="Length"
                            id="LengthName"
                            onBlur={() => {
                              LengthHandleChange("LengthName");
                            }}
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            LengthSaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetLengthAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <LengthTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div> 
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={10}>
            <div className="tabmainerinner p-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">Action</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li>
                      <Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetActionAdd(true);
                            SetTotalDisplayCount(false);
                            CancelActionDelete();
                            CancleActionBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a>
                      </Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowActionImport();
                            CancelActionDelete();
                            SetTotalDisplayCount(false);
                            SetActionAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Export">
                        <a
                          onClick={() => {
                            ExportActionCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={ActionExport}
                            filename={`${ClientName}-Action`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a></Tooltip>
                      </li>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowActionDelete();
                            SetTotalDisplayCount(false);
                            CancleActionBtn();
                            SetActionAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody border-bottom"
                id="import_csvAction"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import Action</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropAction}
                        onError={HandleOnErrorAction}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileAction}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add Action data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={ActionMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancleActionBtn}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                id="map_attributeAction"
                style={{ display: "none" }}
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                          
                        >
                          <option selected="" value="Action">
                            Action
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="Action"
                          value={ Action == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="action"): Length}

                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      disabled={BtnDisabled}
                      onClick={() => {
                        ActionListSaveCsv("Action");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody p-0 my-0 border-bottom py-3"
                style={{ display: "none" }}
                id="import_csvBlukDeleteAction"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete Action</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropActionDelete}
                        onError={HandleOnErrorActionDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileActionDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add Action data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={ActionMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelActionDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody p-0 my-0 border-bottom pb-3 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteAction"
              >
                <div className="mx-0 row col-xs-12">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12 px-3">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Action">
                            Action
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="ActionBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Action == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="action"): Action}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <button
                      disabled={BtnDisabled}
                      onClick={() => {
                        SaveCsvActionBlukDelete("Action");
                      }}
                      className="btn btn-primary btn-lightgreen mx-0"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border-bottom pb-3" id=" ">
                  <div className="row mx-0 col-xs-12">
                    <h4 className="headertitle xs-headertitle float-left pl-0">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive mx-0">
                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2 d-block text-center">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title xs-headertitle">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {ActionAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Action
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            type="text"
                            id="ActionName"
                            name="ActionName"
                            onBlur={() => {
                              ActionHandleChange("ActionName");
                            }}
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            ActionSaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetActionAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <ActionTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div> 
              </div>
            </div>
          </TabPanel>

          
          <TabPanel value={value} className="whitebg inpt-0 bg-white" index={11}>
            <div className="tabmainerinner p-0">
              <div className="row align-items-center borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlebd">Disclaimer</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right iconbox-pad">
                    <ul className='my-0'>
                      <li>
                      <Tooltip title="Add">
                        <a
                          onClick={() => {
                            SetDisclaimerAdd(true);
                            SetTotalDisplayCount(false);
                            CancelDisclaimerDelete();
                            CancleDisclaimerBtn();
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                        </a>
                      </Tooltip>
                      </li>
                      <li><Tooltip title="Import">
                        <a
                          onClick={() => {
                            ShowDisclaimerImport();
                            CancelDisclaimerDelete();
                            SetTotalDisplayCount(false);
                            SetDisclaimerAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la la-icon-font-size-13 la-upload"></i>
                        </a></Tooltip>
                      </li>
                      <li><Tooltip title="Export">
                        <a
                          onClick={() => {
                            ExportDisclaimerCsv();
                            SetTotalDisplayCount(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <CSVDownloader className="px-0"
                            data={DisclaimerExport}
                            filename={`${ClientName}-Disclaimer`}
                            // bom={true}
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </a></Tooltip>
                      </li>

                      <li><Tooltip title="Delete">
                        <a
                          onClick={() => {
                            ShowDisclaimerDelete();
                            SetTotalDisplayCount(false);
                            CancleDisclaimerBtn();
                            SetDisclaimerAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                          }}
                          className="btn btngroup"
                        >
                          <i className="la flaticon-delete-1"></i>
                        </a></Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Import Csv for Black list*/}
              <div
                className="portletbody"
                id="import_csvDisclaimer"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Import Disclaimer</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropDisclaimer}
                        onError={HandleOnErrorDisclaimer}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileDisclaimer}
                        isReset={Reset}
                      >
                        <span>Select .CSV file</span>
                        <p>Add Disclaimer data from csv file to import.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={DisclaimerMapCsvBtn}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancleDisclaimerBtn}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mapped div */}
              <div
                className="portletbody portletshadow p-4 m-1 map_attribute"
                id="map_attributeDisclaimer"
                style={{ display: "none" }}
              >
                <div className="row col-xs-12">
                  <h4 className="headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                          
                        >
                          <option selected="" value="Disclaimer">
                            Disclaimer
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="Disclaimer"
                          value={ Disclaimer == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="disclaimer"): Length}

                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                  <div className="col-xs-12 border-top pt-4 mt-2">
                    <button
                      disabled={BtnDisabled}
                      onClick={() => {
                        DisclaimerListSaveCsv("Disclaimer");
                      }}
                      className="btn btn-primary btn-lightgreen"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* bluk delete csv */}
              <div
                className="portletbody px-4"
                style={{ display: "none" }}
                id="import_csvBlukDeleteDisclaimer"
              >
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <h3 className="uploadheadingtitle">Bulk Delete Disclaimer</h3>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={HandleOnDropDisclaimerDelete}
                        onError={HandleOnErrorDisclaimerDelete}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={HandleOnRemoveFileDisclaimerDelete}
                        isReset={Reset}
                      >
                        <h6>Select .CSV file</h6>
                        <p>Add Disclaimer data from csv file to bulk delete.</p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2">
                    <div className="alignright">
                      <a
                        onClick={DisclaimerMapCsvBtnDelete}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a
                        onClick={CancelDisclaimerDelete}
                        className="btn btn-secondary"
                      >
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="portletbody portletshadow p-4 m-1 map_attribute"
                style={{ display: "none" }}
                id="map_attributeBlukDeleteDisclaimer"
              >
                <div className="row col-xs-12">
                  <h4 className="headertitle float-left pl-0">Map attributes</h4>
                </div>
                <div className="col-xs-12">
                  <div className="form-group m-form__group row mx-0">
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Disclaimer">
                            Disclaimer
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-2 nopadding">
                      <div>
                        <select
                          id="DisclaimerBox"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={ Disclaimer == null ? DropboxData.findIndex((item) =>item.toLowerCase() ==="disclaimer"): Action}
                        >
                          <option value="not mapped">Not Mapped</option>
                          {DropboxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 border-top pt-4 mt-2">
                    <button
                      disabled={BtnDisabled}
                      onClick={() => {
                        SaveCsvDisclaimerBlukDelete("Disclaimer");
                      }}
                      className="btn btn-primary btn-lightgreen"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>

              {/* Count */}
              {TotalDisplayCount == true ? (
                <div className="portletbody px-4" id=" ">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left">Results</h4>
                  </div>

                  <div className="col-xs-12">
                    <div className="row colfive">
                      <div className="col-md-3">
                        <div className="shadowcard boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2">
                                <span className="box-main-title font-primary">
                                  {Total}
                                </span>
                              </div>
                              <div className="box-inner-title">
                                <string className="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="shadowcard boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2">
                                <span className="box-main-title font-success">
                                  {TotalSuccess}
                                </span>
                              </div>
                              <div className="box-inner-title">
                                <string className="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="shadowcard boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2">
                                <span className="box-main-title font-danger">
                                  {TotalErrors}
                                </span>
                              </div>
                              <div className="box-inner-title">
                                <string className="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="shadowcard boxcardcounter">
                          <div className="d-flex align-center">
                            <div className="contectboxes w-100">
                              <div className="line-title mb-2">
                                <span className="box-main-title font-warning">
                                  {TotalDuplicate}
                                </span>
                              </div>
                              <div className="box-inner-title">
                                <string className="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {DisclaimerAdd ? (
                <div className="pb-2">
                  <div className="row borderbtnm py-3 mx-0">
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Disclaimer
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            type="text"
                            id="DisclaimerName"
                            name="DisclaimerName"
                            onBlur={() => {
                              DisclaimerHandleChange("DisclaimerName");
                            }}
                          />
                          <span style={{ color: "red" }}>{Errors["Name"]}</span>
                          {StatusAvailable && (
                            <span style={{ color: "red" }}>
                              Value already exist.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row borderbtnm pb-3 mx-0">
                    <div className="col-xl-12">
                      <div className="alignright">
                        <button
                          className="btn btn-primary btnColor"
                          onClick={() => {
                            DisclaimerSaveBtn();
                          }}
                          disabled={IsDisabled}
                        >
                          <i className="la la-save"></i> Save
                        </button>
                        <a
                          className="btn btn-secondary"
                          onClick={() => {
                            SetDisclaimerAdd(false);
                            SetErrors({});
                            SetStatusAvailable(null);
                            SetIsDisabled(false)
                          }}
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mx-0 pb-2">
                <div className="col"> 
                    <DisclaimerTable
                      listupdate={ListUpdate}
                      updateFromChild={UpdateFromChild}
                    />
                  </div> 
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </>
  );
}
