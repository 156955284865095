import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import GlobalBlacklistTabing from '../../_components/user/GlobalBlackList/GlobalBlacklistTabing';
import CollapsibleTable from '../../_components/usertable';



class GlobalBlacklistPage extends React.Component {
    constructor(props) {
        super(props);
      }
      componentDidMount() {
        window.addEventListener('storage', (event) => {
                
          if (event.key === 'clientChanged') {
            window.location.reload();
          }
        });
    }
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="headertitlebd lg-headertitle">Global Blacklist</h4>
                        </div>
                    </div> 
                    <div className="col px-1">
                        <GlobalBlacklistTabing propdata = {this.props}/>
                    </div>
                </div>

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedGlobalBlacklistPage = connect(mapState, actionCreators)(GlobalBlacklistPage);
export { connectedGlobalBlacklistPage as GlobalBlacklistPage };