import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");
import parse from "html-react-parser";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import $ from "jquery";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { GetClientDetails } from "../../../_helpers/Utility";
const Fileupload = require("../../../_helpers/fileupload");

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const automator = (props) => {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Cname, SetCname] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [SampleExportData, SetSampleExportData] = React.useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [Reset, SetReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);
  const [TotalResult, SetTotalResult] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [Total, SetTotal] = React.useState(0);

  const [FirstName, SetFirstName] = React.useState(null);
  const [LastName, SetLastName] = React.useState(null);
  const [Email, SetEmail] = React.useState(null);
  const [Title, SetTitle] = React.useState(null);
  const [Company, SetCompany] = React.useState(null);
  const [CompanyNameLong, SetCompanyNameLong] = React.useState(null);
  const [CompanyPhone, SetCompanyPhone] = React.useState(null);
  const [City, SetCity] = React.useState(null);
  const [State, SetState] = React.useState(null);
  const [ContactCategory, SetContactCategory] = React.useState(null);
  const [CompanyCategory, SetCompanyCategory] = React.useState(null);
  const [Website, SetWebsite] = React.useState(null);
  const [Address1, SetAddress1] = React.useState(null);
  const [Address2, SetAddress2] = React.useState(null);
  const [PostalCode, SetPostalCode] = React.useState(null);
  const [LinkedInURL, SetLinkedInURL] = React.useState(null);
  const [Zip, SetZip] = React.useState(null);
  const [Country, SetCountry] = React.useState(null);
  const [ValidationScore, SetValidationScore] = React.useState(null);
  const [Industry, SetIndustry] = React.useState(null);
  const [CompanyRevenue, SetCompanyRevenue] = React.useState(null);
  const [EmployeeCount, setEmployeeCount] = React.useState(null);
  const [MobilePhone, SetMobilePhone] = React.useState(null);
  const [DirectPhone, SetDirectPhone] = React.useState(null);
  const [OtherPhone, SetOtherPhone] = React.useState(null);
  const [DirectPhoneExt, SetDirectPhoneExt] = React.useState(null);
  const [Custom1, SetCustom1] = React.useState(null);
  const [Custom2, SetCustom2] = React.useState(null);
  const [Custom3, SetCustom3] = React.useState(null);
  const [Tag, SetTag] = React.useState(null);
  const [SalesDevelopmentReps, SetSalesDevelopmentReps] = React.useState(null);
  const [PRLLeadID, SetPRLLeadID] = React.useState(null);
  const [ZoomID, SetZoomID] = React.useState(null);
  const [AutomatorDataList, SetAutomatorDataList] = React.useState([]);
  const [CompanyAutomaterIDs, SetCompanyAutomaterIDs] = React.useState(null);
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoadings, SetIsLoadings] = React.useState(false);
  const [FullCsvData, SetFullCsvData] = React.useState([]);
  const [FileNameOnly, SetFileNameOnly] = React.useState([]);

  //Show/Hide
  const toggleVisibility = () => {
    setIsVisible(true);
    document.getElementById("delete_csv").style.display = "none";
  };
  // const toggleVisibility2 = () => {
  //   setIsVisible2(true);
  //   setIsVisible(false);
  // };
  // //close-button
  // const CancelButton = () => {
  //   setIsVisible(false);
  // };

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetClientDetails(GetUserData.ClientID).then((result) => {
      SetCname(result[0]?.Name);
    });
    if(CompanyAutomaterIDs !== null){
      
      document.getElementById(CompanyAutomaterIDs + "text").style.display = "none";
      for(var i=0;i<FullCsvData.length;i++){
        if(FullCsvData[i] === CompanyAutomaterIDs){
          FullCsvData[i].OriginalCompany = FullCsvData[i].Company
          FullCsvData[i].Company = document.getElementById(CompanyAutomaterIDs + "text").value;
          FullCsvData[i].CompanyNameNew = document.getElementById(CompanyAutomaterIDs + "text").value;
        }
      }
      SetCompanyAutomaterIDs(null)
    }
    AutomatorMasterListGet(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      AutomatorDataList
    );
    // Exportdetails(
    //   GetUserData.ClientID,
    //   GetUserData.ParentUserID,
    //   AutomatorDataList
    // );
    SampleFileExportdetails();
  }, [Search, Page, RowsPerPage]);

  //get Automater master list
  const AutomatorMasterListGet = (CID, UID, CsvDatas) => {
    SetIsLoadings(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      ClientID: CID,
      UserID: UID,
      CsvData: CsvDatas,
    };
    const AutomatorMasterList = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/CompanyAutomator/GetAutomatorCompanylist",
      method: "POST",
      data: InputParameter,
    });
    AutomatorMasterList.then((Result) => {
      
      SetRows(Result.data?.PageData);
      SetRlen(Result.data?.TotalCount);
      SetFlen(Result.data?.TotalCount);
      SetCountPage(Result.data?.PageCount);
      SetFullCsvData(Result.data?.FullData);
      SetIsLoadings(false);
      // document.getElementById("hideloding").style.display = "none";
    });
  };

  // Export details
  const Exportdetails = (CID, UID, CsvDatas) => {
    SetIsLoadings(true);
    let InputParameter = {
      ClientID: CID,
      UserID: UID,
      CsvData: CsvDatas,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/CompanyAutomator/GetAutomatorCompanyExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      SetExportData(res?.data);
      SetIsLoadings(false);
    });
  };

  const SampleFileExportdetails = () => {
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/CompanyAutomator/GetSampleAutomatorExport",
      method: "POST",
    }).then((res) => {
      SetSampleExportData(res?.data);
    });
  };
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoadings(true);
    var SearchedVal = document.getElementById("search").value;

    // SetPage(1);
    // SetRowsPerPage(10);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CsvData:AutomatorDataList
      };
      const AutomatorMasterList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/CompanyAutomator/GetAutomatorCompanylist",
        method: "POST",
        data: InputParameter,
      });
      AutomatorMasterList.then((Result) => {
        SetRows(Result.data?.PageData);
        SetRlen(Result.data?.TotalCount);
        SetFlen(Result.data?.TotalCount);
        SetCountPage(Result.data?.PageCount);
        //document.getElementById("hideloding").style.display = "none";
        SetIsLoadings(false);
      });
      
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CsvData:AutomatorDataList
      };
      const AutomatorMasterList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/CompanyAutomator/GetAutomatorCompanylist",
        method: "POST",
        data: InputParameter,
      });
      AutomatorMasterList.then((Result) => {
        SetRows(Result.data?.PageData);
        SetRlen(Result.data?.TotalCount);
        SetFlen(Result.data?.TotalCount);
        SetCountPage(Result?.data.PageCount);
        SetIsLoadings(false);
      });
    }
  };

  //delete new code
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to Master Company Automator?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var data = {
          _id: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/CompanyAutomator/AutomatorMasterDelete",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              toast.success(
                <div className="toastsize">
                  Automator <br /> Automator Message delete successfully.
                </div>
              );
              // document.getElementById("hideloding").style.display = "block";
              AutomatorMasterListGet(ClientID, UserID, Role);
              // history.push("/Campaigns");
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              // props.updateFromChild(true)
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Edit desing change
  const EditAotumatorBtn = (AotumatorID) => {
    document.getElementById(AotumatorID + "labelbox").style.display = "none";
    document.getElementById(AotumatorID + "text").style.display = "block";
    document.getElementById(AotumatorID + "editicon1").style.display = "none";
    document.getElementById(AotumatorID + "saveicon1").style.display = "block";
    document.getElementById(AotumatorID + "editicon2").style.display = "none";
    document.getElementById(AotumatorID + "saveicon2").style.display = "block";
  };

  const HideEditBtn = (AotumatorID) => {
    if(AotumatorID !== undefined && AotumatorID !== null)
    var elementlable = document.getElementById(AotumatorID + "labelbox")
    if(elementlable){
      document.getElementById(AotumatorID + "labelbox").style.display = "block";
    }
    var elementtext = document.getElementById(AotumatorID + "text")
    if(elementtext){
      document.getElementById(AotumatorID + "text").style.display = "none";
    }
    //document.getElementById(AotumatorID + "text").style.display = "none";
  };

  // Update domain data
  const UpdateAutomatorCA = async (e) => {
    
    SetIsLoadings(true);
    try {
      if(CompanyAutomaterIDs!== null){
        var Companys = document.getElementById(
          CompanyAutomaterIDs + "text"
        ).value;
        for(var i=0;i<FullCsvData.length;i++){
          if(FullCsvData[i]._id === CompanyAutomaterIDs){
            FullCsvData[i].OriginalCompany = FullCsvData[i].Company
            FullCsvData[i].Company = document.getElementById(CompanyAutomaterIDs + "text").value;
            FullCsvData[i].CompanyNameNew = document.getElementById(CompanyAutomaterIDs + "text").value;
          }
        }
      }
      var CompanyAutomatorUpdate = {
        FullCsv: FullCsvData
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/CompanyAutomator/AutomatorUpdate",
        method: "POST",
        data: CompanyAutomatorUpdate,
      }).then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Company Automators
              <br />
              Automators updated successfully.
            </div>
          );
          SetIsLoadings(true);
          AutomatorMasterListGet(ClientID, UserID, AutomatorDataList);
          Exportdetails(ClientID, UserID, AutomatorDataList);
          // updateFromChild(true);
          HideEditBtn(CompanyAutomaterIDs);
        } else {
          toast.error(result.data.Message);
        }
      });
    } catch (error) {
      toast.success(
        <div className="toastsize">
          Company Automators
          <br />
          Automators updated successfully.
        </div>
      );
    }
  };

  // reset function
  const ResetCsv = () => {
    SetReset(true);
  };

  //update/impoert

  //  // reset function
  //  const ResetCsv1 = () => {
  //   SetReset(true);
  // };

  // handle on drop
  const HandleOnDrop1 = (data, fileInfo) => {
    var filename = fileInfo.name;

    // this.setState({ files: fileInfo });
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      // this.setState({ reset: true });
      SetReset(true);
      // this.ResetCsv();
      ResetCsv();
    } else {
      SetFileNameOnly(sep[0]);
      var csvfile =  sep.slice(0, -1).join(".") + new Date().getTime() + "." + sep[sep.length-1];
      // this.setState({ filename: csvfile });
      setfilename(csvfile);

      // Filter out rows with all empty strings
      const filteredData = data.filter(
        (row) => !row.data.every((item) => item.trim() === "")
      );

      // this.setState({ csvData: data });
      setcsvData(filteredData);
      // this.setState({ DropboxData: data[0].data });
      SetDropboxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError1 = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile1 = (data) => {};

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "FirstName") {
      SetFirstName(value);
    } else if (Name == "LastName") {
      SetLastName(value);
    } else if (Name == "Email") {
      SetEmail(value);
    } else if (Name == "Title") {
      SetTitle(value);
    } else if (Name == "Company") {
      SetCompany(value);
    } else if (Name == "CompanyNameLong") {
      SetCompanyNameLong(value);
    } else if (Name == "Website") {
      SetWebsite(value);
    } else if (Name == "CompanyPhone") {
      SetCompanyPhone(value);
    } else if (Name == "DirectPhone") {
      SetDirectPhone(value);
    } else if (Name == "DirectPhoneExt") {
      SetDirectPhoneExt(value);
    } else if (Name == "LinkedInURL") {
      SetLinkedInURL(value);
    } else if (Name == "Industry") {
      SetIndustry(value);
    } else if (Name == "Address1") {
      SetAddress1(value);
    } else if (Name == "Address2") {
      SetAddress2(value);
    } else if (Name == "PostalCode") {
      SetPostalCode(value);
    } else if (Name == "City") {
      SetCity(value);
    } else if (Name == "State") {
      SetState(value);
    } else if (Name == "Zip") {
      SetZip(value);
    } else if (Name == "Country") {
      SetCountry(value);
    } else if (Name == "ContactCategory") {
      SetContactCategory(value);
    } else if (Name == "CompanyCategory") {
      SetCompanyCategory(value);
    } else if (Name == "ValidationScore") {
      SetValidationScore(value);
    } else if (Name == "ContactSource") {
      SetContactSource(value);
    } else if (Name == "ContactOwner") {
      SetContactOwner(value);
    } else if (Name == "ClientPOC") {
      SetClientPOC(value);
    } else if (Name == "ReasonUnqualified") {
      SetReasonUnqualified(value);
    } else if (Name == "AccountType") {
      SetAccountType(value);
    } else if (Name == "CompanyRevenue") {
      SetCompanyRevenue(value);
    } else if (Name == "EmployeeCount") {
      setEmployeeCount(value);
    } else if (Name == "IsDoNotCallContact") {
      SetIsDoNotCallContact(value);
    } else if (Name == "SalesRep") {
      SetSalesRep(value);
    } else if (Name == "ColdCaller") {
      SetColdCaller(value);
    } else if (Name == "Tag") {
      SetTag(value);
    } else if (Name == "CallNotes") {
      SetCallNotes(value);
    } else if (Name == "OtherPhone") {
      SetOtherPhone(value);
    } else if (Name == "OtherPhone2") {
      SetOtherPhone2(value);
    } else if (Name == "ContactID") {
      SetContactID(value);
    }else if(Name == "Custom1"){
      SetCustom1(value);
    }else if(Name == "Custom2"){
      SetCustom2(value);
    }else if(Name == "Custom3"){
      SetCustom3(value);
    }else if(Name == "SalesDevelopmentReps"){
      SetSalesDevelopmentReps(value);
    }else if(Name == "PRLLeadID"){
      SetPRLLeadID(value);
    }else if(Name == "ZoomID"){
      SetZoomID(value);
    }

    hideCSVHeaderOptions();
  });

  // Bulk delete contact csv
  const ContactSaveCsv = () => {
    
    //  var FirstNames = document.getElementById("Email").value;
    SetIsLoadings(true);
    var saveAllContacts = [];
    var FirstName = parseInt(document.getElementById("FirstName").value);
    if (FirstName >= 0) {
      var ObjFirstName = {
        FieldAlias: "First Name",
        Fieldname: "FirstName",
        Customfield: null,
        index: FirstName,
      };
      saveAllContacts.push(ObjFirstName);
    }
    var LastName = parseInt(document.getElementById("LastName").value);
    if (LastName >= 0) {
      var ObjLastName = {
        FieldAlias: "Last Name",
        Fieldname: "LastName",
        Customfield: null,
        index: LastName,
      };
      saveAllContacts.push(ObjLastName);
    }
    var Email = parseInt(document.getElementById("Email").value);
    if (Email >= 0) {
      var ObjEmail = {
        FieldAlias: "Email",
        Fieldname: "Email",
        Customfield: null,
        index: Email,
      };
      saveAllContacts.push(ObjEmail);
    }
    var Title = parseInt(document.getElementById("Title").value);
    if (Title >= 0) {
      var ObjTitle = {
        FieldAlias: "Title",
        Fieldname: "Title",
        Customfield: null,
        index: Title,
      };
      saveAllContacts.push(ObjTitle);
    }
    var Company = parseInt(document.getElementById("Company").value);
    if (Company >= 0) {
      var ObjCompany = {
        FieldAlias: "Company",
        Fieldname: "Company",
        Customfield: null,
        index: Company,
      };
      saveAllContacts.push(ObjCompany);
    }
    // var CompanyNameLong = parseInt(
    //   document.getElementById("CompanyNameLong").value
    // );
    // if (CompanyNameLong >= 0) {
    //   var ObjCompanyNameLong = {
    //     FieldAlias: "Company Name Long",
    //     Fieldname: "CompanyNameLong",
    //     Customfield: null,
    //     index: Company,
    //   };
    //   saveAllContacts.push(ObjCompanyNameLong);
    // }
    var Website = parseInt(document.getElementById("Website").value);
    if (Website >= 0) {
      var ObjWebsite = {
        FieldAlias: "Website",
        Fieldname: "Website",
        Customfield: null,
        index: Website,
      };
      saveAllContacts.push(ObjWebsite);
    }
    var CompanyPhone = parseInt(
      document.getElementById("CompanyPhone").value
    );
    if (CompanyPhone >= 0) {
      var ObjCompanyPhone = {
        FieldAlias: "Company Phone",
        Fieldname: "CompanyPhone",
        Customfield: null,
        index: CompanyPhone,
      };
      saveAllContacts.push(ObjCompanyPhone);
    }
    var DirectPhone = parseInt(document.getElementById("DirectPhone").value);
    if (DirectPhone >= 0) {
      var ObjDirectPhone = {
        FieldAlias: "Direct Phone",
        Fieldname: "DirectPhone",
        Customfield: null,
        index: DirectPhone,
      };
      saveAllContacts.push(ObjDirectPhone);
    }

    var PostalCode = parseInt(document.getElementById("PostalCode").value);
    if (PostalCode >= 0) {
      var ObjPostalCode = {
        FieldAlias: "Postal Code",
        Fieldname: "PostalCode",
        Customfield: null,
        index: PostalCode,
      };
      saveAllContacts.push(ObjPostalCode);
    }

    var DirectPhoneExt = parseInt(
      document.getElementById("DirectPhoneExt").value
    );
    if (DirectPhoneExt >= 0) {
      var ObjDirectPhoneExt = {
        FieldAlias: "Direct Phone Ext",
        Fieldname: "DirectPhoneExt",
        Customfield: null,
        index: DirectPhoneExt,
      };
      saveAllContacts.push(ObjDirectPhoneExt);
    }
    var LinkedInURL = parseInt(document.getElementById("LinkedInURL").value);
    if (LinkedInURL >= 0) {
      var ObjLinkedInURL = {
        FieldAlias: "LinkedIn URL",
        Fieldname: "LinkedInURL",
        Customfield: null,
        index: LinkedInURL,
      };
      saveAllContacts.push(ObjLinkedInURL);
    }
    var Industry = parseInt(document.getElementById("Industry").value);
    if (Industry >= 0) {
      var ObjIndustry = {
        FieldAlias: "Industry",
        Fieldname: "Industry",
        Customfield: null,
        index: Industry,
      };
      saveAllContacts.push(ObjIndustry);
    }

    var Address1 = parseInt(document.getElementById("Address1").value);
    if (Address1 >= 0) {
      var ObjAddress1 = {
        FieldAlias: "Address 1",
        Fieldname: "Address1",
        Customfield: null,
        index: Address1,
      };
      saveAllContacts.push(ObjAddress1);
    }

    var Address2 = parseInt(document.getElementById("Address2").value);
    if (Address2 >= 0) {
      var ObjAddress2 = {
        FieldAlias: "Address 2",
        Fieldname: "Address2",
        Customfield: null,
        index: Address2,
      };
      saveAllContacts.push(ObjAddress2);
    }

    var City = parseInt(document.getElementById("City").value);
    if (City >= 0) {
      var ObjCity = {
        FieldAlias: "City",
        Fieldname: "City",
        Customfield: null,
        index: City,
      };

      saveAllContacts.push(ObjCity);
    }
    var State = parseInt(document.getElementById("State").value);
    if (State >= 0) {
      var ObjState = {
        FieldAlias: "State",
        Fieldname: "State",
        Customfield: null,
        index: State,
      };
      saveAllContacts.push(ObjState);
    }

    // var Zip = parseInt(document.getElementById("Zip").value);
    // if (Zip >= 0) {
    //   var ObjZip = {
    //     FieldAlias: "Zip",
    //     Fieldname: "Zip",
    //     Customfield: null,
    //     index: Zip,
    //   };
    //   saveAllContacts.push(ObjZip);
    // }

    var Country = parseInt(document.getElementById("Country").value);
    if (Country >= 0) {
      var ObjCountry = {
        FieldAlias: "Country",
        Fieldname: "Country",
        Customfield: null,
        index: Country,
      };
      saveAllContacts.push(ObjCountry);
    }

    var ContactCategory = parseInt(
      document.getElementById("ContactCategory").value
    );
    if (ContactCategory >= 0) {
      var ObjContactCategory = {
        FieldAlias: "Contact Category",
        Fieldname: "ContactCategory",
        Customfield: null,
        index: ContactCategory,
      };
      saveAllContacts.push(ObjContactCategory);
    }
    var CompanyCategory = parseInt(
      document.getElementById("CompanyCategory").value
    );
    if (CompanyCategory >= 0) {
      var ObjAccountCategory = {
        FieldAlias: "Company Category",
        Fieldname: "CompanyCategory",
        Customfield: null,
        index: CompanyCategory,
      };
      saveAllContacts.push(ObjAccountCategory);
    }

    var ValidationScore = parseInt(
      document.getElementById("ValidationScore").value
    );
    if (ValidationScore >= 0) {
      var ObjValidationScore = {
        FieldAlias: "Validation Score",
        Fieldname: "ValidationScore",
        Customfield: null,
        index: ValidationScore,
      };
      saveAllContacts.push(ObjValidationScore);
    }

    // var ContactSource = parseInt(
    //   document.getElementById("ContactSource").value
    // );
    // if (ContactSource >= 0) {
    //   var ObjContactSource = {
    //     FieldAlias: "Contact Source",
    //     Fieldname: "ContactSource",
    //     Customfield: null,
    //     index: ContactSource,
    //   };
    //   saveAllContacts.push(ObjContactSource);
    // }
    // var ContactOwner = parseInt(
    //   document.getElementById("ContactOwner").value
    // );
    // if (ContactOwner >= 0) {
    //   var ObjContactOwner = {
    //     FieldAlias: "Contact Owner",
    //     Fieldname: "ContactOwner",
    //     Customfield: null,
    //     index: ContactOwner,
    //   };
    //   saveAllContacts.push(ObjContactOwner);
    // }
    // var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
    // if (ClientPOC >= 0) {
    //   var ObjClientPOC = {
    //     FieldAlias: "Client POC",
    //     Fieldname: "ClientPOC",
    //     Customfield: null,
    //     index: ClientPOC,
    //   };
    //   saveAllContacts.push(ObjClientPOC);
    // }
    // var ReasonUnqualified = parseInt(
    //   document.getElementById("ReasonUnqualified").value
    // );
    // if (ReasonUnqualified >= 0) {
    //   var ObjReasonUnqualified = {
    //     FieldAlias: "Reason Unqualified",
    //     Fieldname: "ReasonUnqualified",
    //     Customfield: null,
    //     index: ReasonUnqualified,
    //   };
    //   saveAllContacts.push(ObjReasonUnqualified);
    // }
    // var AccountType = parseInt(document.getElementById("AccountType").value);
    // if (AccountType >= 0) {
    //   var ObjAccountType = {
    //     FieldAlias: "Account Type",
    //     Fieldname: "AccountType",
    //     Customfield: null,
    //     index: AccountType,
    //   };
    //   saveAllContacts.push(ObjAccountType);
    // }
    var CompanyRevenue = parseInt(
      document.getElementById("CompanyRevenue").value
    );
    if (CompanyRevenue >= 0) {
      var ObjCompanyRevenue = {
        FieldAlias: "Company Revenue",
        Fieldname: "CompanyRevenue",
        Customfield: null,
        index: CompanyRevenue,
      };
      saveAllContacts.push(ObjCompanyRevenue);
    }
    var EmployeeCount = parseInt(
      document.getElementById("EmployeeCount").value
    );
    if (EmployeeCount >= 0) {
      var ObjEmployeeCount = {
        FieldAlias: "Employee Count",
        Fieldname: "EmployeeCount",
        Customfield: null,
        index: EmployeeCount,
      };
      saveAllContacts.push(ObjEmployeeCount);
    }

    var MobilePhone = parseInt(document.getElementById("MobilePhone").value);
    if (MobilePhone >= 0) {
      var ObjMobilePhone = {
        FieldAlias: "Mobile Phone",
        Fieldname: "MobilePhone",
        Customfield: null,
        index: MobilePhone,
      };
      saveAllContacts.push(ObjMobilePhone);
    }
    var OtherPhone = parseInt(document.getElementById("OtherPhone").value);
    if (OtherPhone >= 0) {
      var ObjOtherPhone = {
        FieldAlias: "Other Phone",
        Fieldname: "OtherPhone",
        Customfield: null,
        index: OtherPhone,
      };
      saveAllContacts.push(ObjOtherPhone);
    }
    var Custom1 = parseInt(document.getElementById("Custom1").value);
    if (Custom1 >= 0) {
      var ObjCustom1 = {
        FieldAlias: "Custom1",
        Fieldname: "Custom1",
        Customfield: null,
        index: Custom1,
      };
      saveAllContacts.push(ObjCustom1);
    }
    var Custom2 = parseInt(document.getElementById("Custom2").value);
    if (Custom2 >= 0) {
      var ObjCustom2 = {
        FieldAlias: "Custom2",
        Fieldname: "Custom2",
        Customfield: null,
        index: Custom2,
      };
      saveAllContacts.push(ObjCustom2);
    }
    var Custom3 = parseInt(document.getElementById("Custom3").value);
    if (Custom3 >= 0) {
      var ObjCustom3 = {
        FieldAlias: "Custom3",
        Fieldname: "Custom3",
        Customfield: null,
        index: SetCustom3,
      };
      saveAllContacts.push(ObjCustom3);
    }
    var Tag = parseInt(document.getElementById("Tag").value);
    if (Tag >= 0) {
      var ObjTag = {
        FieldAlias: "Tag",
        Fieldname: "Tag",
        Customfield: null,
        index: Tag,
      };
      saveAllContacts.push(ObjTag);
    }
    var SalesDevelopmentReps = parseInt(document.getElementById("SalesDevelopmentReps").value);
    if (SalesDevelopmentReps >= 0) {
      var ObjSalesDevelopmentReps = {
        FieldAlias: "Sales Development Reps",
        Fieldname: "SalesDevelopmentReps",
        Customfield: null,
        index: Tag,
      };
      saveAllContacts.push(ObjSalesDevelopmentReps);
    }
    var PRLLeadID = parseInt(document.getElementById("PRLLeadID").value);
    if (PRLLeadID >= 0) {
      var ObjPRLLeadID = {
        FieldAlias: "PRL Lead ID",
        Fieldname: "PRLLeadID",
        Customfield: null,
        index: PRLLeadID,
      };
      saveAllContacts.push(ObjPRLLeadID);
    }
    var ZoomID = parseInt(document.getElementById("ZoomID").value);
    if (ZoomID >= 0) {
      var ObjZoomID = {
        FieldAlias: "Zoom ID",
        Fieldname: "ZoomID",
        Customfield: null,
        index: ZoomID,
      };
      saveAllContacts.push(ObjZoomID);
    }

    var FirstNames = document.getElementById("FirstName").value;
    var LastNames = document.getElementById("LastName").value;
    var Emails = document.getElementById("Email").value;
    var Titles = document.getElementById("Title").value;
    var Companys = document.getElementById("Company").value;
    //var CompanyNameLongs = document.getElementById("CompanyNameLong").value;
    var Citys = document.getElementById("City").value;
    var States = document.getElementById("State").value;
    var ContactCategorys = document.getElementById("ContactCategory").value;
    var CompanyCategorys = document.getElementById("CompanyCategory").value;
    var PostalCodes = document.getElementById("PostalCode").value;
    var LinkedInURLs = document.getElementById("LinkedInURL").value;
    var Countrys = document.getElementById("Country").value;
    var ValidationScores = document.getElementById("ValidationScore").value;
    var Industrys = document.getElementById("Industry").value;
    var CompanyRevenues = document.getElementById("CompanyRevenue").value;
    var EmployeeCounts = document.getElementById("EmployeeCount").value;
    var MobilePhones = document.getElementById("MobilePhone").value;
    var DirectPhones = document.getElementById("DirectPhone").value;
    var DirectPhoneExts = document.getElementById("DirectPhoneExt").value;
    var Websites = document.getElementById("Website").value;
    var Address1s = document.getElementById("Address1").value;
    var Address2s = document.getElementById("Address2").value;
    var CompanyPhones = document.getElementById("CompanyPhone").value;
    var OtherPhones = document.getElementById("OtherPhone").value;
    var Custom1s = document.getElementById("Custom1").value;
    var Custom2s = document.getElementById("Custom2").value;
    var Custom3s = document.getElementById("Custom3").value; 
    var Tags = document.getElementById("Tag").value;
    var SalesDevelopmentRepss = document.getElementById("SalesDevelopmentReps").value;
    var PRLLeadIDs = document.getElementById("PRLLeadID").value;
    var ZoomIDs = document.getElementById("ZoomID").value;

    if (Companys === "") {
      toast.error(
        <spam>
          Company Automators
          <br />
          Company is required!
        </spam>
      );
      SetIsLoadings(false);
    } else if (Websites === "") {
      toast.error(
        <spam>
          Company Automators
          <br />
          Website is required!
        </spam>
      );
      SetIsLoadings(false);
    } else if (csvData.length <= 1) {
      toast.error(
        <spam>
          Company Automators
          <br />
          Data not exists, Please try again!
        </spam>
      );
      SetIsLoadings(false);
    } else {
      var csvdata = csvData;
      var csvdata2 = csvData.length;

      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var ObjData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/AutomaterCampany",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          saveAllAutomator: JSON.stringify(saveAllContacts),
          ClientID: ClientID,
          UserID: UserID,
          FirstName: FirstNames,
          LastName: LastNames,
          Email: Emails,
          Title: Titles,
          Company: Companys,
          //CompanyNameLong: CompanyNameLongs,
          City: Citys,
          State: States,
          ContactCategory: ContactCategorys,
          CompanyCategory: CompanyCategorys,
          PostalCode: PostalCodes,
          LinkedInURL: LinkedInURLs,
          Country: Countrys,
          ValidationScore: ValidationScores,
          Industry: Industrys,
          CompanyRevenue: CompanyRevenues,
          MobilePhone: MobilePhones,
          DirectPhone: DirectPhones,
          DirectPhoneExt: DirectPhoneExts,
          EmployeeCount: EmployeeCounts,
          Website: Websites,
          Address1: Address1s,
          Address2: Address2s,
          CompanyPhone:CompanyPhones,
          OtherPhone:OtherPhones,
          Custom1:Custom1s,
          Custom2:Custom2s,
          Custom3:Custom3s,
          Tag:Tags,
          SalesDevelopmentReps:SalesDevelopmentRepss,
          PRLLeadID:PRLLeadIDs,
          ZoomID:ZoomIDs
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/CompanyAutomator/ImportComapnyAutomatorCsv",
          method: "POST",
          data: ObjData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success("Automator Save Imports");
            SetTotal(res.data.Total);
            SetTotalSuccess(res.data.TotalSuccess);
            SetTotalError(res.data.TotalError);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetTotalDisplayCount(true);
            SetAutomatorDataList(res.data.CsvData);
            AutomatorMasterListGet(ClientID, UserID, res.data.CsvData);
            // SetTotalUpdated(res.data.TotalUpdated);
            Exportdetails(ClientID, UserID, res.data.CsvData);
            // document.getElementById("total-div-display").style.display =
            //   "block";
            if (res.data.TotalSuccess > 0) {
              document.getElementById("AutomatorListGet").style.display =
                "block";
            }
            //SetIsLoadings(false);
            // document.getElementById("map_attribute").style.display = "none";
            // document.getElementById("import_csv").style.display = "none";
            // document.getElementById("hideloding").style.display = "none";
          }
        });
      });
    }
  };

  const BulkDeleteContactBtnsubject = () => {
    if (csvData.length != 0) {
      document.getElementById(
        "map_attribute_domain_subjectlist"
      ).style.display = "block";
      ResetCsv();
      DropboxData.map((dddd, index) => {});
    } else {
      toast.error(
        <div className="toastsize">
          Company Automator
          <br />
          Please select file
        </div>
      );
    }
    hideCSVHeaderOptions();
  };

  const CancelBulkContactBtnsubject = () => {
    ResetCsv();
    SetTotalDisplayCount(false);
    document.getElementById("deletesubject_csv").style.display = "none";
    // document.getElementById("map_attributeBlukDeleteSubject").style.display = "none";
    document.getElementById("map_attribute_domain_subjectlist").style.display =
      "none";
  };
  const ContactBulkDeleteCsvsubject = () => {
    document.getElementById("deletesubject_csv").style.display = "block";
  };

  const HideTextfieldOnTableCell = (e, AotumatorID,Company) => {
    debugger
    if(AotumatorID !== CompanyAutomaterIDs){
      if(CompanyAutomaterIDs !== null){
        
        document.getElementById(CompanyAutomaterIDs + "labelbox").style.display = "block";
        document.getElementById(CompanyAutomaterIDs + "text").style.display = "none";
        document.getElementById(AotumatorID + "labelbox").style.display = "none";
        document.getElementById(AotumatorID + "text").style.display = "block";
        document.getElementById(AotumatorID + "text").value = Company;
        for(var i=0;i<FullCsvData.length;i++){
          if(FullCsvData[i]._id === CompanyAutomaterIDs){
            FullCsvData[i].OriginalCompany = FullCsvData[i].Company
            FullCsvData[i].Company = document.getElementById(CompanyAutomaterIDs + "text").value;
            FullCsvData[i].CompanyNameNew = document.getElementById(CompanyAutomaterIDs + "text").value;
          }
        }
        for(var i=0;i<Rows.length;i++){
          if(Rows[i]._id === CompanyAutomaterIDs){
            Rows[i].Company = document.getElementById(CompanyAutomaterIDs + "text").value;
            //Rows[i].CompanyNameNew = document.getElementById(CompanyAutomaterIDs + "text").value;
          }
        }
        SetCompanyAutomaterIDs(AotumatorID);
      }else{
        document.getElementById(AotumatorID + "labelbox").style.display = "none";
        document.getElementById(AotumatorID + "text").style.display = "block";
        document.getElementById(AotumatorID + "text").value = Company;
        SetCompanyAutomaterIDs(AotumatorID);
      }
    }
  };

  return (
    <div>
      {IsLoadings ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="bg-white py-4" id="deletesubject_csv">
        <div className="row pt-3">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle mb-1">
              Import Company Automator Details
            </h3>
            <span class="m-alert alert-paragraph">
              <i class="la la-info-circle"></i> A sample file is available for
              download,
              <CSVDownloader
                data={SampleExportData}
                filename={"Sample"}
                bom={true}
              >
                <a>please click here</a>
              </CSVDownloader>
            </span>
            <div className="uplodfilesbox pt-4 mt-2 ">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                // accept="*"
                onDrop={HandleOnDrop1}
                onError={HandleOnError1}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile1}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add Company Automator data from csv file to bulk upload.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2 ">
            <div className="alignright">
              <a
                onClick={BulkDeleteContactBtnsubject}
                className="btn btn-primary btnColor"
              >
                <i className="la la-upload"></i> Upload
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mapped div */}
      <div
        className="portletbody p-0 my-0 bg-white border-top-0"
        id="map_attribute_domain_subjectlist"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle xs-headertitle float-left">Map attributes</h4>
        </div>
        <div className="col-xs-12" id="divContactSelection">
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn1"
                  disabled="disabled"
                >
                  <option selected="" value="FirstName">
                    First Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="FirstName"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    FirstName == null
                      
                     ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*First\\s+Name\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : FirstName
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn2"
                  disabled="disabled"
                >
                  <option selected="" value="LastName">
                    Last Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="LastName"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    LastName == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Last\\s+Name\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : LastName
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn3"
                  disabled="disabled"
                >
                  <option selected="" value="Email">
                    Email
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Email"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Email == null 
                    ? DropboxData.findIndex((item) =>
                    new RegExp("^\\s*Email\\s*$", "i").test(item.trim().toLowerCase())
                  ) 
                    : Email}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn4"
                  disabled="disabled"
                >
                  <option selected="" value="Title">
                    Title
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Title"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={Title == null 
                    ? DropboxData.findIndex((item) =>
                    new RegExp("^\\s*Title\\s*$", "i").test(item.trim().toLowerCase())
                  )
                     : Title}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn5"
                  disabled="disabled"
                >
                  <option selected="" value="Company">
                    Company
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Company"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Company == null 
                    ? DropboxData.findIndex((item) =>
                    new RegExp("^\\s*Company\\s*$", "i").test(item.trim().toLowerCase())
                  )
                    : Company
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn6"
                  disabled="disabled"
                >
                  <option selected="" value="CompanyNameLong">
                    Company Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyPhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyPhone == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Company\\s+Phone\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : CompanyPhone
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn7"
                  disabled="disabled"
                >
                  <option selected="" value="City">
                    City
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="City"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={City == null
                    ? DropboxData.findIndex((item) =>
                    new RegExp("^\\s*City\\s*$", "i").test(item.trim().toLowerCase())
                  ) 
                     : City}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn8"
                  disabled="disabled"
                >
                  <option selected="" value="State">
                    State
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="State"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={State == null
                     ? DropboxData.findIndex((item) =>
                     new RegExp("^\\s*State\\s*$", "i").test(item.trim().toLowerCase())
                   )
                     : State}
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn9"
                  disabled="disabled"
                >
                  <option selected="" value="ContactCategory">
                    Contact Category
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ContactCategory"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ContactCategory == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Contact\\s+Category\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : ContactCategory
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn10"
                  disabled="disabled"
                >
                  <option selected="" value="Company Category">
                    Company Category
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyCategory"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyCategory == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Company\\s+Category\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : CompanyCategory
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn11"
                  disabled="disabled"
                >
                  <option selected="" value="Website">
                    Website
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Website"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Website == null 
                    ? DropboxData.findIndex((item) =>
                    new RegExp("^\\s*Website\\s*$", "i").test(item.trim().toLowerCase())
                  )
                    : Website
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn12"
                  disabled="disabled"
                >
                  <option selected="" value="Address1">
                    Address 1
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Address1"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Address1 == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Address\\s+1\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : Address1
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn12"
                  disabled="disabled"
                >
                  <option selected="" value="Address1">
                    Address 2
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Address2"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Address2 == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Address\\s+2\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : Address2
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn13"
                  disabled="disabled"
                >
                  <option selected="" value="Address2">
                    Postal Code
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="PostalCode"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    PostalCode == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Postal\\s+Code\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : PostalCode
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn14"
                  disabled="disabled"
                >
                  <option selected="" value="LinkedInURL">
                    LinkedIn URL
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="LinkedInURL"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    LinkedInURL == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*LinkedIn\\s+URL\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : LinkedInURL
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn16"
                  disabled="disabled"
                >
                  <option selected="" value="Country">
                    Country
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Country"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Country == null 
                    ? DropboxData.findIndex((item) =>
                    new RegExp("^\\s*Country\\s*$", "i").test(item.trim().toLowerCase())
                  )
                    : Country
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn17"
                  disabled="disabled"
                >
                  <option selected="" value="ValidationScore">
                    Validation Score
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ValidationScore"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ValidationScore == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Validation\\s+Score\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : ValidationScore
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn18"
                  disabled="disabled"
                >
                  <option selected="" value="Industry">
                    Industry
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Industry"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Industry == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Industry\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : Industry
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="CompanyRevenue">
                    Company Revenue
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="CompanyRevenue"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    CompanyRevenue == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Company\\s+Revenue\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : CompanyRevenue
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn20"
                  disabled="disabled"
                >
                  <option selected="" value="EmployeeCount">
                    Employee Count
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="EmployeeCount"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    EmployeeCount == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Employee\\s+Count\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : EmployeeCount
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn24"
                  disabled="disabled"
                >
                  <option selected="" value="MobilePhone">
                    Mobile Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="MobilePhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    MobilePhone == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Mobile\\s+Phone\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : MobilePhone
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="DirectPhone">
                    Direct Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="DirectPhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    DirectPhone == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Direct\\s+Phone\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : DirectPhone
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
          <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="DirectPhoneExt">
                    Other Phone
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="OtherPhone"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    OtherPhone == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Other\\s+Phone\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : OtherPhone
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="DirectPhoneExt">
                    Direct Phone Ext
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="DirectPhoneExt"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    DirectPhoneExt == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Direct\\s+Phone\\s+Ext\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : DirectPhoneExt
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn9"
                  disabled="disabled"
                >
                  <option selected="" value="ContactCategory">
                    Custom 1
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Custom1"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Custom1 == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Custom\\s+1\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : Custom1
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn10"
                  disabled="disabled"
                >
                  <option selected="" value="Company Category">
                  Custom 2
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Custom2"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Custom2 == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Custom\\s+2\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : Custom2
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn9"
                  disabled="disabled"
                >
                  <option selected="" value="ContactCategory">
                    Custom 3
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Custom3"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Custom3 == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Custom\\s+3\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : Custom3
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn10"
                  disabled="disabled"
                >
                  <option selected="" value="Company Category">
                  Tag
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="Tag"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    Tag == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Tag\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : Tag
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn9"
                  disabled="disabled"
                >
                  <option selected="" value="ContactCategory">
                    Sales Development Reps
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="SalesDevelopmentReps"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    SalesDevelopmentReps == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Sales\\s+Development\\s+Reps\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : SalesDevelopmentReps
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn10"
                  disabled="disabled"
                >
                  <option selected="" value="Company Category">
                  PRL Lead ID
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="PRLLeadID"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    PRLLeadID == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*PRL\\s+Lead\\s+ID\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : PRLLeadID
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn9"
                  disabled="disabled"
                >
                  <option selected="" value="ContactCategory">
                  Zoom ID
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id="ZoomID"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ZoomID == null
                      ? DropboxData.findIndex((item) =>
                      new RegExp("^\\s*Zoom\\s+ID\\s*$", "i").test(item.trim().toLowerCase())
                    )
                      : ZoomID
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>

          <div className="col-lg-12 d-flex">
            <a
              onClick={ContactSaveCsv}
              className="btn btn-primary btn-lightgreen my-3"
            >
              <i className="la la-save"></i>
              import
            </a>
          </div>
        </div>
      </div>

      {TotalDisplayCount == true ? (
        <div className="portletbody p-0 my-0 border-top-0 bg-white p-3 py-4" id=" ">
          <div className="row col-xs-12 mx-0">
          <h4 className="headertitle xs-headertitle float-left mb-0 p-0 pb-4">Results</h4>
          </div>

          <div className="col-xs-12 mx-0">
            <div class="row colfive mx-0">
              <div class="col-md-3">
                <div class="boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2 text-center d-block">
                        <span class="box-main-title font-primary">{Total}</span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2 text-center d-block">
                        <span class="box-main-title font-success">
                          {TotalSuccess}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total success</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2 text-center d-block">
                        <span class="box-main-title font-danger">
                          {TotalError}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total error</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="boxcardcounter">
                  <div class="d-flex align-center">
                    <div class="contectboxes w-100">
                      <div class="line-title mb-2 text-center d-block">
                        <span class="box-main-title font-warning">
                          {TotalDuplicate}
                        </span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Total no content</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        className="portletbody px-4 pb-2 pt-0 mt-3"
        id="AutomatorListGet"
        style={{ display: "none" }}
      >
        <div className="row border-bottom">
          <div className="col">
            <h4 className="headertitle float-left">Automator</h4>
          </div>
          <div className="col border-bottom px-5">
            <div className="listing-li float-right padb-15">
              <ul>
                <li>
                  <a
                    // href=""
                    onClick={UpdateAutomatorCA}
                    className="btn btngroup m-btn btn-lightgreen"
                  >
                    <i class="la la-icon-font-size-13 la-upload "></i>
                    <span>Save All Changes</span>
                  </a>
                </li>
                <li>
                  <CSVDownloader
                    data={ExportData}
                    filename={FileNameOnly+"_Automator"}
                    bom={true}
                  >
                    <a className="btn btngroup m-btn m-btn--custom">
                      <i class="la la-icon-font-size-13 la-download"> </i>Export
                    </a>
                  </CSVDownloader>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row padt-25">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                className="form-control form-control-sm"
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:{" "}
              <input
                Type="search"
                id="search"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("CompanyNameLong");
                      }}
                    >
                      Company Name long
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("CompanyNameNew");
                      }}
                    >
                      Saved Name
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Length");
                      }}
                    >
                      Length
                    </a>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <a
                      onClick={() => {
                        SortData("Company");
                      }}
                    >
                      Updated Company Name
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Website");
                      }}
                    >
                      Website
                    </a>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows?.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows?.map((row) => (
                    <>
                      <TableRow>
                        <TableCell>{row?.CompanyNameLong}</TableCell>
                        <TableCell>{row?.CompanyNameNew}</TableCell>
                        <TableCell>{row?.Company?.length}</TableCell>
                        <TableCell onClick={(e) =>
                              HideTextfieldOnTableCell(e, row._id,row?.Company)
                            }>
                          <label
                            id={row._id + "labelbox"}
                          >
                            {row?.Company}{" "}
                          </label>{" "}
                          <input
                            type="textbox"
                            id={row._id + "text"}
                            class="form-control m-input"
                            style={{ display: "none" }}
                            //defaultValue={row?.Company}
                            autocomplete="off"
                          />
                        </TableCell>
                        <TableCell>
                          <a href={`http://${row.Website}`} 
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline', color: 'inherit' }}
                            >
                            <u>{row.Website}</u>
                          </a>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                )}
              </TableBody>

              <div className="row"></div>
            </Table>
          </TableContainer>
        </div>
        {Sflag ? (
          <div className="row">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
            <div className="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries (filtered from {Flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                page={Page}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default automator;
