import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  IconButton,
  Collapse,
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
           <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
           <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

function Notes({ UserID, ClientID, Role,DomainPotential, AccountID, ProspectIDs, ChildUserID }) {
  const [Page, setPage] = useState(1);
  const [RowsPerPage, setRowsPerPage] = useState(100);
  const [Search, setSearch] = useState("");
  const [SortField, setSortField] = useState("CreatedDate");
  const [SortedBy, setSortedBy] = useState(-1);
  const [Sflag, setSflag] = useState(false);
  const [Data, setData] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Rlen, setRlen] = useState(0);
  const [Flen, setFlen] = useState(0);
  const [CountPage, setCountPage] = useState(0);
  const [ShowNotePopup, setShowNotePopup] = useState(false);
  const [TitleAvailableNotes, setTitleAvailableNotes] = useState(null);
  const [Fields, setFields] = useState({});
  const [Errors, setErrors] = useState({});
  const [PageType, setPageType] = useState("");
  const [ids, setids] = useState("");
  const [IsButtonDisabledNoteSave, setIsButtonDisabledNoteSave] =
    useState(false);
  const [IsButtonDisabledNoteUpdate, setIsButtonDisabledNoteUpdate] =
    useState(false);
  const [OldTitleUpdate, setOldTitleUpdate] = useState(false);
  const [NotesUpdatModel, setNotesUpdatModel] = useState(false);
  const [sortedColumn, setSortedColumn] = React.useState('CreatedDate');

  useEffect(() => {
    // Fetch initial data
    NotesDetails(ProspectIDs, Search, Page, RowsPerPage);
  }, [ProspectIDs, Search, Page, RowsPerPage,DomainPotential, AccountID, ClientID]);

  // contact note get
  const NotesDetails = (ContactList, Search, Page, RowPage) => {
    if (
      ContactList?.trim() !== "" &&
      AccountID?.trim() !== "" &&
      DomainPotential?.trim() !== "" &&
      ClientID?.trim() !== ""
    ) {
      var AccountNoteData = {
        Page: parseInt(Page),
        ClientID: ClientID,
        SalesReplyAccountID: AccountID,
        ProspectID: ContactList,
        RowsPerPage: parseInt(RowPage),
        Sort: true,
        Field: SortField,
        Domain:DomainPotential,
        Role: Role,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/reschedules/GetContactAccountNotes",
        method: "POST",
        data: AccountNoteData,
      }).then((responsenote) => {
        if(responsenote.data.StatusMessage === "SUCCESS"){
          setData(responsenote.data?.PageData);
        setRows(responsenote.data?.PageData);
        setRlen(responsenote.data?.TotalCount);
        setFlen(responsenote.data?.TotalCount);
        setCountPage(responsenote.data?.PageCount);
        }else{
          toast.error(responsenote.data.Message)
        }
      });
    }
  };

  // call note contact change display rows
  const ChangeRowSelected = (event) => {
    setPage(1);
    setRowsPerPage(Number(event.target.value));
  };

  // search for record contact note
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
        var SearchedVal = document.getElementById("NotesSearch").value;
        if (SearchedVal == Search) {
          setSearch(SearchedVal);
          setPage(1);
        } else {
          setSearch(SearchedVal);
          setPage(1);
        }
      }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
        setPage(NewPage);
      } else {
        setPage(NewPage);
      }
  };

  const Sorting = (Field) => {
    setSortField(Field)
      setSortedColumn(Field);
      let SearchedVal = document.getElementById("NotesSearch").value;
      const isNewSortField = Field !== SortField;
      let SortBy = SortedBy === 1 ? -1 : 1;
    
      if (isNewSortField) {
        setSortedBy(1);
        SortBy = 1;
      } else {
        setSortedBy(SortBy);
      }
    
      setSortField(Field);
      if (
        ProspectIDs?.trim() !== "" &&
        AccountID?.trim() !== "" &&
        DomainPotential?.trim() !== "" &&
        ClientID?.trim() !== ""
      ) {
        var AccountNoteData = {
          Page: parseInt(Page),
          ClientID: ClientID,
          SalesReplyAccountID: AccountID,
          ProspectID: ProspectIDs,
          RowsPerPage: parseInt(RowsPerPage),
          Sort: true,
          Field: Field,
          Domain:DomainPotential,
          Role: Role,
          SortBy: SortBy,
          Search: SearchedVal,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/reschedules/GetContactAccountNotes",
          method: "POST",
          data: AccountNoteData,
        }).then((responsenote) => {
          if(responsenote.data.StatusMessage === "SUCCESS"){
            setData(responsenote.data?.PageData);
          setRows(responsenote.data?.PageData);
          setRlen(responsenote.data?.TotalCount);
          setFlen(responsenote.data?.TotalCount);
          setCountPage(responsenote.data?.PageCount);
          }else{
            toast.error(responsenote.data.Message)
          }
        });
      }
  };


  return (
    <>
      <div className="row">
        <div className="col">
          <h3 className="xs-headertitle py-3 mb-0">Contact Notes</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              aria-controls="tbl_meeting"
              onChange={ChangeRowSelected}
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="NotesSearch"
              onKeyPress={(event) => RequestSearch(event)}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => {Sorting("Title");}}>
                    Title
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Title"} />
                </TableCell>
                <TableCell onClick={() => {Sorting("Note");}}>
                    Notes
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Note"} />
                </TableCell>
                <TableCell onClick={() => {Sorting("PageName");}}>
                    Type
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"PageName"} />
                </TableCell>
                <TableCell onClick={() => {Sorting("CreatedDate");}}>
                    Created Date
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"CreatedDate"} />
                </TableCell>
                {/* <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row?.Title}</TableCell>
                    <TableCell>{row?.Note}</TableCell>
                    <TableCell>{row?.PageName}</TableCell>

                    <TableCell>
                      {moment(new Date(row?.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
     
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      
    </>
  );
}

export default Notes;
