import React from 'react';
const moment=require('moment');
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar'; 
import Footer from "../../_components/user/footer/footer";
import VariableReportingTabing from '../../_components/user/VariableReporting/VariableReportingTabing.js';
import Popupone from "reactjs-popup";
import{ GetUserDetails } from '../../_helpers/Utility';
import { getFormattedFirstDayOfQuarterUTC } from "../../_helpers/Utility";
import $ from "jquery";
import { toast } from "react-toastify";


class VariableReportingPage extends React.Component {
   
    constructor(props) {
        super(props);
    
        // reset login status
        //    this.props.logout();

        this.state = { 
            ClientID:null,
            UserID:null,
            popupval : true,
            InvitePopup : false,
            FromDate:"",
            ToDate:"",
            DateSearch:{
                "Start":moment(new Date().setMonth(new Date().getMonth() - 1)).format('YYYY/MM/DD'),
                "End":moment(new Date()).format('YYYY/MM/DD')
            },
        };   
        this.SaveBtn = this.SaveBtn.bind(this);
        this.openInvitePopup = this.openInvitePopup.bind(this);
        this.closeInvitePopup = this.closeInvitePopup.bind(this);
        this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this);
        this.daterefect = React.createRef();
        this.daterefect2 = React.createRef();
    } 

    componentDidMount() {
            window.addEventListener('storage', (event) => {
                    
              if (event.key === 'clientChanged') {
                window.location.reload();
              }
            });
        
        document.title = "Client KPIs | SalesHive";
        // Format the date
        const formattedDate = getFormattedFirstDayOfQuarterUTC()        

        var Details=GetUserDetails();
        if(Details != null)
        {
            this.state.ClientID= (Details.ClientID);
            this.state.UserID=(Details.ChildUserID);
        }

        // Set the value of the "from-date" input field
        document.getElementById("from-date").value = formattedDate;
            const $datepickerElement = $(this.daterefect.current); 
        $datepickerElement.datetimepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            container: '#app',
            todayBtn: true,
            todayHighlight: true,
            minView: '2',
        });
        document.getElementById("to-date").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const $datepickerElement2 = $(this.daterefect2.current); 
        $datepickerElement2.datetimepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            container: '#app',
            todayBtn: true,
            todayHighlight: true,
            minView: '2',
        });
    
        var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
        var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
        
        this.setState({FromDate: FromDate,ToDate: ToDate});

    } 

   
    SaveBtn(){
        var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
        var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
        
        if (moment(FromDate, "YYYY-MM-DD").isAfter(moment(ToDate, "YYYY-MM-DD"))) {
            toast.error("Please select an appropriate date range");
          } else {

              this.setState({ FromDate: FromDate, ToDate: ToDate});
          }
    }
 
 
    openInvitePopup(){
        console.log("--it's true one new"); 
         this.setState({ InvitePopup : true });
         console.log(this.state); 

    } 
    closeInvitePopup(){
        console.log("--it's false new");
        this.setState({ InvitePopup : false });
    }
    
    SubmitInvitePopup() {
        this.setState({ InvitePopup : false });
    } 
    

    render() { 
        return (
            <>
                     
                <Popupone open={this.state.InvitePopup} modal>
                <div className="modal-black"></div>
                    <div className="filterPopup bigpopupcontent">
                        <div className="paddingboxTerms">
                            <div className="modal-header">
                                <h5>Send Mail</h5>
                                <a className="close-ion" onClick={this.closeInvitePopup}><span aria-hidden="true" className="la la-remove"></span></a>
                            </div>
                            <div className="modal-content bordernone"> 
                                <div className="row max-cols">
                                    <label className="col-lg-4 view-lable-meet">First Name</label>
                                    <div className="col-lg-8">
                                        <input className="form-control m-input" data-val="true" data-val-required="Please enter first name" id="FirstName" name="FirstName" placeholder="First name" type="text" value="" />
                                    </div>
                                </div> 
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <a id="m_emailInvite_submit" href="javascript:void(0);" className="btn btn-primary btnColor">Send </a>
                            </div>
                        </div> 
                    </div> 
                </Popupone>


                <div className="adminmain mheight"> 
                    <Sidebar className=""/> 
                    <div className="bodyhome">
                        <div className="row align-items-center">
                            <div className="col"> 
                                <h4 className="headertitlebd lg-headertitle pl-0">Variable Reporting</h4>
                            </div>
                            <div className="col pt-3">
                                <div className="btn-group datepicker-hed">
                                    <input id="fromtab" name="fromtab" type="hidden" value="" />
                                    <div className="col-lg-6 timedatepikker date-input">
                                        <input className="form-control datepicker" id="from-date" name="from-date" type="text" ref={this.daterefect} autoComplete="off" />
                                    </div>
                                    <div className="col-lg-6 timedatepikker date-input">
                                        <input className="form-control datepicker" id="to-date" name="to-date" type="text" ref={this.daterefect2} autoComplete="off" />
                                    </div>
                                    <button id="aCompanyState" className="btn btn-primary btnColor btn-radius" onClick={this.SaveBtn} autoComplete="off">
                                        <i className="la flaticon-diagram"></i>
                                        <span>Analyze</span>
                                    </button>
                                </div>
                            </div>
                        </div> 
                        <div className="col padd-0">
                            
                        </div>

                        <div className="row"> 
                            <div className="col"> 
                                <div className="listblock">
                                    <VariableReportingTabing ToDate={this.state.ToDate} FromDate={this.state.FromDate} daterange={this.state.DateSearch} />
                                </div> 
                            </div> 
                        </div> 
                        
                    </div>
                </div>
                <Footer />
            </>
        );
    }

}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedVariableReportingPage = connect(mapState, actionCreators)(VariableReportingPage);
export { connectedVariableReportingPage as VariableReportingPage };