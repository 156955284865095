import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { arrayMoveImmutable } from "array-move";

import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));
const SortableList = SortableContainer(({ Items }) => {
  return (
    <ul>
      {Items.map((value, Index) => (
        <SortableItem
          id={Index}
          key={`item-${Index}`}
          index={Index}
          value={value.Reason}
        />
      ))}
    </ul>
  );
});
class ContactReasonSetOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: [],
      ClientID: null,
      UserID: null,
      Role:null,
      BtnDisabled:false,
      IsLoading:true
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.BackBtn = this.BackBtn.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title = 'Contact Reason Unqualified Order | SalesHive';
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.Role=Details.Role
    }
    this.ContactReasonUnqualifiedOrder(Details.ClientID);
  }

  // contact reason unqualified order
  ContactReasonUnqualifiedOrder(CID) {
    var str_in = {
      Page: 1,
      RowsPerPage: 100,
      Field: "OrderBy",
      SortBy: 1,
      Type: "User",
      ClientID: CID,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/contactreasonunqualified/ContactReasonUnqualifiedGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      if(result.data?.StatusMessage === "SUCCESS"){
        this.setState({ Items: result.data.PageData });
        this.setState({ IsLoading: false});
      }else{
        const errorMessage =
        result?.data && result.data?.Message ? result.data?.Message : "Unknown error";
        toast.error(errorMessage);
      }
      this.setState({ IsLoading: false});

    });
  }

  // on sort end
  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      Items: arrayMoveImmutable(this.state.Items, oldIndex, newIndex),
    });
  };

  // save button
  SaveBtn() {
    this.setState({ IsLoading: true});
    let OrderBy = 0;
    for (let i = 0; i < this.state.Items.length; i++) {
      this.state.Items[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.SaveOrderData();
  }

  // save order data
  SaveOrderData() {
    this.setState({BtnDisabled:true})
    let str_in = {
      ContactReasonunqualifiedList: this.state.Items,
      Role:this.state.Role
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/contactreasonunqualified/ContactReasonUnqualifiedSetOrder",
      method: "POST",
      data: str_in,
    });
    rows1.then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Contact Reason Unqualified
            <br />
            Contact Reason Unqualified updated Successfully.
          </div>
        );
        history.push("/contactreasonunqualified");
        this.setState({ IsLoading: false});
      } else {
        toast.error(res.data.Message);
        this.setState({BtnDisabled:false})
        this.setState({ IsLoading: false});
      }
      this.ContactReasonUnqualifiedOrder();
    });
  }

  // back button
  BackBtn() {
    history.push("/contactreasonunqualified");
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading === true ?   <div id="hideloding" className="loding-display">
                <img src={loadingicon} />
              </div> : null
      }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
            <div className="row pt-4 pb-3">
              <div className="col px-3">
                <h4 className="headertitlenop float-left px-1">
                Contact Reason Unqualified Order
                </h4>
              </div>
            </div>

            <div class="whiterow">
              <div className="row pt-4 px-3">
                <div className="col-xl-12 offset-xl-12 px-4 pt-1">
                  <span className="alertinfo mx-1">
                    &nbsp;<b>NOTE</b> : Please drag & drop item to change order of Reason Unqualified and press save button.
                  </span>

                  <div class="pb-4 pt-3 px-1">
                    <SortableList
                      Items={this.state.Items}
                      onSortEnd={this.OnSortEnd}
                    />
                  </div>
                </div>
              </div>
            
            </div>

              <div class="row py-4 mx-0">
                <div class="pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={() => {
                      this.SaveBtn();
                    }}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save order
                  </button>
                  <a
                    id="backtolist"
                    onClick={() => {
                      this.BackBtn();
                    }}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedContactReasonSetOrderPage = connect(
  mapState,
  actionCreators
)(ContactReasonSetOrderPage);
export { connectedContactReasonSetOrderPage as ContactReasonSetOrderPage };
