import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import DespammerTabing from "../../_components/user/OpenRateReporting/OpenRateReportingTabing";

class OpenRateReportingPage extends React.Component {
  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title = `Open Rate Reporting | SalesHive`;
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center py-4">
              <div className="col">
                <h4 className="headertitlenop lg-headertitle float-left">Open Rate Reporting</h4>
              </div>
              <div className="col padright"></div>
            </div>
            <div className="col padd-0 px-0">
              <DespammerTabing />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedOpenRateReportingPage = connect(
  mapState,
  actionCreators
)(OpenRateReportingPage);
export { connectedOpenRateReportingPage as OpenRateReportingPage };
