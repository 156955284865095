import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class CAddContactTagpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      TagNameAvailable: null,
      ClientID: null,
      UserID: null,
      Role: null,
      UserIDby: null,
      IsExist: false,
      BtnDisabled:false,
      IsLoading:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = 'Add Tag | SalesHive'
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ Role: Details.Role });
      this.setState({ UserIDby: Details.ChildUserID });
    }
    this.setState({IsLoading:false})
  }
 

// form validation
async FromValidation() {
  let FormIsValid = true;
  let Errors = {};
  var Name = document.getElementById("Name").value.trim();
  var IsExists = await this.ContactTagExits(Name); // Change 'Country' to 'Name'
  
  if (IsExists == "BlankData" && Name === "") { // Check for an empty string or a space in the name
    FormIsValid = false;
    Errors["Name"] = "Please enter tag"; // Modify the error message
  }

  if (IsExists === "ExistCountry") {
    FormIsValid = false;
    Errors["Name"] = "Contact tag already exist."; // Change 'Country' to 'Name'
  }

  this.setState({ Errors: Errors });
  return FormIsValid;
}


  // handle change
  async HandleChange(field, e) {
    let Errors = { ...this.state.Errors };
    const value = e.target.value.trim();
    let IsExists;

  
    if (field === "Name") { // Change "Country" to "Name"
      IsExists = await this.ContactTagExits(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"
  
      if (IsExists === "BlankData" && value === "") {
        Errors.Name = "Please enter name"; // Change "Country" to "Name"
      } else if (IsExists === "ExistCountry") {
        Errors.Name = "Contact Tag already exist."; // Change "Country" to "Name"
      } else if (IsExists === "AllDone") {
        Errors.Name = null; // Change "Country" to "Name"
      }
    }
  
    this.setState({ Errors });
  }

   // check contact tag exists
   async ContactTagExits(Name) {
    if(Name !== ""){
      var ContactTagData = {
        ClientID: this.state.ClientID,
        Tag: Name,
        Role:this.state.Role
      };
      var resdata = await   Axios({
        url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagExists",
        method: "POST",
        data: ContactTagData,
      })
        // if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            this.setState({ TagNameAvailable: resdata.data.Data[0]?.Tag });
            return "ExistCountry";
          }else{
            return "AllDone";
          }
        // }
    }else{
      return "BlankData";
    }
  }
  // back button
  BackBtn() {
    history.push("/CContactTag");
  }

  // save button
  async SaveBtn() {
    this.setState({BtnDisabled:true})
    var Final_flag = await this.FromValidation();

    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var ContactTagData = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Tag: Name,
        CreatedBy: this.state.UserIDby,
        CreatedDate: new Date(),
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagAdd",
        method: "POST",
        data: ContactTagData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Tag Added <br />
              Tag added successfully
            </div>
          );
       
          this.setState({BtnDisabled:false})
          history.push("/CContactTag");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})

        }
      });
    } else {
      this.setState({BtnDisabled:false})

    }
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading == true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
      }

        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle pl-0 mb-0">Add Tag</h4>
                </div>
              </div>

            <div class="bg-white">
              <div className="row py-3 mx-0">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Tag</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Enter Tag"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddContactTagpage = connect(
  mapState,
  actionCreators
)(CAddContactTagpage);
export { connectedCAddContactTagpage as CAddContactTagpage };
