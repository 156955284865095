import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import welistloadingicon from "../../../images/loading.gif";


export default function ContactCampaignTable({ CampaignID, onDataFromContact, updateFromChild }) {

  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("FirstName");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [ContactListData, SetContactListData] = React.useState([]);
  // const [ContactAddInArrayCampaign, SetContactAddInArrayCampaign] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoadingAdd,SetIsLoadingAdd] = React.useState(true)
  const [ContactAddInArrayCampaign, setContactAddInArrayCampaign] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [IsDisabledBtn, SetIsDisabledBtn] =React.useState(false)
  const [sortedColumn, setSortedColumn] = React.useState("FirstName");

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ContactCampaignGet(GetUserData.ClientID, GetUserData.ParentUserID);

  }, [Search, Page, RowsPerPage]);


  //get contact list
  const ContactCampaignGet = (CID, UID) => {
        SetIsLoadingAdd(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      CampaignID: CampaignID,
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const ContactCampaignData = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CampaignsExistingContactGet",
      method: "POST",
      data: InputParameter,
    });
    ContactCampaignData.then((Result) => {

      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      // document.getElementById("werhideloding").style.display = "none";
      SetIsLoadingAdd(false)
    });
  };

  //search for record
  const RequestSearch = () => {
    
    var SearchedVal = document.getElementById("Search").value;
    
    SetSearch(SearchedVal);
    SetPage(1);
    SetIsLoadingAdd(false)

  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      // document.getElementById("werhideloding").style.display = "block";
      SetIsLoadingAdd(true)
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    // document.getElementById("werhideloding").style.display = "block";
    SetIsLoadingAdd(true)
  };

  // all check handler
  const CheckHandler = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);

    if (checked) {
      setContactAddInArrayCampaign([...Rows]);
    } else {
      setContactAddInArrayCampaign([]);
    }
  };

  const CheckBoxAdd = (e, ID) => {
    const checked = e.target.checked;
    if (checked) {
      setContactAddInArrayCampaign([...ContactAddInArrayCampaign, Rows.find(row => row._id === ID)]);
    } else {
      setContactAddInArrayCampaign(ContactAddInArrayCampaign.filter(contact => contact._id !== ID));
    }
  };



  //Add and Update list contact with multiple check
  const SaveListBtn = () => {
    if (ContactAddInArrayCampaign.length > 0) {
      SetIsDisabledBtn(true)
      SetIsLoadingAdd(true)
      var data = {
        CheckedArrayList: ContactAddInArrayCampaign,
        CampaignID: CampaignID,
        ClientID: ClientID,
        UserID: UserID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),

      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/prospect_campaign/CampaginAddToContact",
        method: "POST",
        data: data,
      }).then((Res) => {
        if (Res.data.StatusMessage == "SUCCESS") {
          toast.success(<>Add Campaign <br />Selected contacts added successfully.</>)
          onDataFromContact(false)
         
          SetIsLoadingAdd(false)
          updateFromChild(CampaignID)
          SetIsDisabledBtn(false)
        } else {
          toast.error(Res.data.Message);
          onDataFromContact(false)
          updateFromChild(CampaignID)
          SetIsDisabledBtn(false)
        }
      });

    } else {
      toast.error("Please select at least one contacts from list.")
    }
    // document.getElementById("werhideloding").style.display = "none";
    SetIsLoadingAdd(false)
  };


  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoadingAdd(true);
    const SearchedVal = document.getElementById("Search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: Field,
        SortBy: SortBy,
        Search:SearchedVal,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };

      const ContactCampaignData = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignsContactGet",
        method: "POST",
        data: InputParameter,
      });
      ContactCampaignData.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoadingAdd(false)
      });

  };

  // popup close
  const popupclose = () => {
    onDataFromContact(false)
  }

  

  return (
    <div>
{
  IsLoadingAdd ?   <div id="werhideloding" className="loding-display">
        <img src={welistloadingicon} />
      </div> : null

}
    
      <div className="row pb-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte float-left">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="Search"
              onChange={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                <input type="checkbox" id="main-check" onChange={(e) => CheckHandler(e)} checked={selectAll} />
                </TableCell>
                <TableCell className="shorting"
                    onClick={() => {
                      SortData("FirstName");
                    }}>Name                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "FirstName" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "FirstName" ? "active" : null} />
                  </span>
                    </TableCell>
                <TableCell
                    onClick={() => {
                      SortData("Title");
                    }}>Title<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Title" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Title" ? "active" : null} />
                  </span></TableCell>
                <TableCell
                    onClick={() => {
                      SortData("Company");
                    }}>Company<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Company" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Company" ? "active" : null} />
                  </span></TableCell>
                       <TableCell
                    onClick={() => {
                      SortData("Email");
                    }}>Email<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                  </span></TableCell>


              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? <p>No data available in table</p> : Rows.map((row) => (

                <TableRow>
                  <TableCell>
                  <input
                    type="checkbox"
                    onChange={(e) => CheckBoxAdd(e, row._id)}
                    checked={ContactAddInArrayCampaign.some(contact => contact._id === row._id)}
                  />
                  </TableCell>
                  <TableCell>{row?.FirstName + row?.LastName}</TableCell>
                  <TableCell>{row?.Title}</TableCell>
                  <TableCell>{row?.Company}</TableCell>
                  <TableCell>{row?.Email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      {/* )} */}

      <div className="modal-footer ">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={popupclose}
        >
          Cancel
        </button>
        <button
              disabled={IsDisabledBtn}
          type="button"
          id="popup-close"
          className="btn btn-primary btn-lightgreen"
          onClick={SaveListBtn}
        >
          <i className="la la-save"></i> Save
        </button>
      </div>
    </div>
  );
}
