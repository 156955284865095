import React, { Component } from 'react'
import { connect } from "react-redux";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddSDRAssignmentPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ClientList: [],
            SDRList: [],
            SDRListReminderEmailAccount:[],
            ClientID: null,
            UserID: null,
            CUserID: null,
            Role: null,
            PhoneNumbers: [],
            PhoneNumbers1: [],
            PhoneNumbers2: [],
            PhoneNumbers3: [],
            selectedPhoneNumber: "",
            errors: {},
            formData: {
                SDRId: "",
                SDRReminderEmailAccountId: "",
                ClientId: "",
                PhoneNumber1: "",
                PhoneNumber2: "",
                PhoneNumber3: "",
                SelectedNumber: []
            },
            IsSDRAssignmentExists:false,
          //  IsSDRReminderEmailAccountIdExists:false,
            IsLoading:false
        };
    }

    componentDidMount() {
        document.title = `Add SDR Assignment | SalesHive`;
        let Details = GetUserDetails();
        if (Details) {
            this.setState({ 
                ClientID: Details.ClientID,
                UserID: Details.ParentUserID,
                CUserID: Details.ChildUserID,
                Role: Details.Role
            });
            this.getClientList(Details.Role, Details.ChildUserID, Details.ParentUserID);
        }
    }

    // Fetching client list for client drop down
    getClientList = async (Role, ChildUserID, ParentUserID) => {
        try {
            this.setState({IsLoading:true})
            const RequestBody = { Role, ChildUserID, ParentUserID };
            const response = await Axios.post(CommonConstants.MOL_APIURL + "/sdrassignment/GetClientList", RequestBody);
            if (response.data?.StatusMessage === "SUCCESS") {
                this.setState({ ClientList: response.data?.Data,IsLoading:false });
            } else {
                this.setState({ ClientList: [],IsLoading:false });
            }
        } catch (err) {
            console.error(err);
            this.setState({IsLoading:false})

        }
    };

    //Fetching Available Phone numbers
    getAvailablePhoneNumbers = async (Role, UserID, SelectedNumbers,ClientID) => {
        try {
            const RequestBody = { Role, UserID, SelectedNumbers,ClientID };
            const response = await Axios.post(CommonConstants.MOL_APIURL + "/sdrassignment/GetAvailablePhoneNumbers", RequestBody);

            if (response.data?.StatusMessage === "SUCCESS") {
                this.setState({ PhoneNumbers: response.data?.Data });
                this.setState({ PhoneNumbers1: response.data?.Data });
                this.setState({ PhoneNumbers2: response.data?.Data });
                this.setState({ PhoneNumbers3: response.data?.Data });
            } else {
                this.setState({ PhoneNumbers: [] });
            }
        } catch (err) {
            console.error(err);
        }
    }

    // PHone number change handler for all drop downs
    handlePhoneNumberChange = (e, phoneNumberField) => {
        const selectedPhoneNumber = e.target.value;
    
        // Copy state objects to avoid direct mutations
        let formData = { ...this.state.formData };
        let selectedNumbers = [...formData.SelectedNumber];
        if(selectedPhoneNumber!= ""){
            this.setState((prevState) => ({
                errors: { ...prevState.errors, PhoneNumber1: "" }
                }));
        }
    
        // Handle unselect (i.e., if the user deselects a phone number)
        const prevSelectedNumber = formData[phoneNumberField];
        if (prevSelectedNumber && selectedNumbers.includes(prevSelectedNumber)) {
            selectedNumbers = selectedNumbers.filter(num => num !== prevSelectedNumber);
        }
    
        // Update formData with the selected phone number or remove it if deselected
        if (selectedPhoneNumber) {
            formData[phoneNumberField] = selectedPhoneNumber;
            if (!selectedNumbers.includes(selectedPhoneNumber)) {
                selectedNumbers.push(selectedPhoneNumber);
            }
        } else {
            formData[phoneNumberField] = "";  // If no number is selected, clear the field
        }
    
        // Filter each phone number list based on currently selected numbers
        const { PhoneNumbers } = this.state;
    
        const PhoneNumbers1 = PhoneNumbers.filter(el => !selectedNumbers.includes(el._id) || el._id === formData.PhoneNumber1);
        const PhoneNumbers2 = PhoneNumbers.filter(el => !selectedNumbers.includes(el._id) || el._id === formData.PhoneNumber2);
        const PhoneNumbers3 = PhoneNumbers.filter(el => !selectedNumbers.includes(el._id) || el._id === formData.PhoneNumber3);
    
        // Set the updated state in one call
        this.setState({
            formData: { ...formData, SelectedNumber: selectedNumbers },
            PhoneNumbers1,
            PhoneNumbers2,
            PhoneNumbers3
        });
    };

    // Filter phone numbers for each dropdown, excluding selected ones
    handleClientChange = async (e) => {
        const ClientID = e.target.value;
        let { formData } = this.state;
        formData.SDRId = "";
        formData.SDRReminderEmailAccountId = "";
        formData.ClientId = ClientID;
        if(ClientID != ""){
            this.setState((prevState) => ({
                errors: { ...prevState.errors, ClientId: "" }
                }));
        }
        this.setState({ formData });

        this.getSDRList(ClientID);
        this.getSDRReminderEmailAccount(ClientID);
        this.getAvailablePhoneNumbers(this.state.Role, this.state.UserID, [], ClientID);
    };

    // Fetching SDR list for SDR Drop down based on client Id
    getSDRList = async (ClientID) => {
        try {
            const response = await Axios.post(CommonConstants.MOL_APIURL + "/sdrassignment/GetSDRUsingClient", { ClientID });
            if (response.data?.StatusMessage === "SUCCESS") {
                this.setState({ SDRList: response.data?.Data?.ColdCallersDetails });
            } else {
                this.setState({ SDRList: [] });
            }
        } catch (error) {
            console.error(error);
        }
    };

        // Fetching SDR list for SDR Reminder Email Account Drop down based on client Id
        getSDRReminderEmailAccount = async (ClientID) => {
            try {
                const response = await Axios.post(CommonConstants.MOL_APIURL + "/sdrassignment/GetSDRListReminderEmailAccount", { ClientID });
                if (response.data?.StatusMessage === "SUCCESS") {
                    this.setState({ SDRListReminderEmailAccount: response.data?.Data });
                } else {
                    this.setState({ SDRListReminderEmailAccount: [] });
                }
            } catch (error) {
                console.error(error);
            }
        };

    // SDR handler for SDR Drop Down
    sdrHandler = async (e) => {
        const SDRId = e.target.value;
        let { formData } = this.state;
        formData.SDRId = SDRId;
            if(SDRId != ""){
                await this.CheckSDRAssignmentExists(SDRId,formData?.ClientId,this.state.Role,this.state.UserID);
                this.setState((prevState) => ({
                    errors: { ...prevState.errors, SDRId: "" }
                    }));
            }else{
                this.setState((prevState) => ({
                    errors: { ...prevState.errors, SDRId: "Please select SDR." },
                    }));
                    this.setState({IsSDRAssignmentExists:false})
            }
        this.setState({ formData });
    }

        // SDR handler for SDR Drop Down
        sdrReminderEmailAccountHandler = async (e) => {
            const SDRReminderEmailAccountId = e.target.value;
            let { formData } = this.state;
            formData.SDRReminderEmailAccountId = SDRReminderEmailAccountId;
                if(SDRReminderEmailAccountId != ""){
                  //  await this.CheckSDRAssignmentExists(SDRId,formData?.ClientId,this.state.Role,this.state.UserID);
                    this.setState((prevState) => ({
                        errors: { ...prevState.errors, SDRReminderEmailAccountId: "" }
                        }));
                }else{
                    this.setState((prevState) => ({
                        errors: { ...prevState.errors, SDRReminderEmailAccountId: "Please select Reminder Email Account." },
                        }));
                       // this.setState({IsSDRReminderEmailAccountIdExists:false})
                }
            this.setState({ formData });
        }

    // CheckSDRAssignment Exists 
    CheckSDRAssignmentExists = async (SDRId,ClientId,Role,UserID) => {
        try {
        const response = await Axios.post(CommonConstants.MOL_APIURL +  "/sdrassignment/CheckSDRAssignmentExists", {
            SDRId: SDRId,
            ClientId: ClientId,
            UserID:UserID,
            Role: Role,
        });
    
        if (response.data?.Data?.IsExists) {
            toast.error(<div className='toastsize'>SDR Assignment is already exists !</div>)
            this.setState((prevState) => ({
                IsSDRAssignmentExists: true,
                errors: { ...prevState.errors, SDRId: "SDR Assignment is already exists !" },
                }));
                return true;
        } else {
            this.setState((prevState) => ({
                IsSDRAssignmentExists: false,
                errors: { ...prevState.errors, SDRId: "" },
                }));
            return false;
        }
        } catch (error) {
            console.error("Error checking sdr assignment:", error);
            toast.error("Failed to check SDR Assignment.");
            return true;
        }
    }

    // Form Submit Handler
    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({IsLoading:true})
        const { formData,UserID,CUserID,Role,IsSDRAssignmentExists } = this.state;
        const errors = {};

        // Validation
        if (!formData.ClientId) errors.ClientId = "Please select client.";
        if (!formData.SDRId) errors.SDRId = "Please select SDR.";
        if (!formData.PhoneNumber1) errors.PhoneNumber1 = "Please select Phone number 1.";
        if (!formData.SDRReminderEmailAccountId) errors.SDRReminderEmailAccountId = "Please select Reminder Email Account.";
        if (IsSDRAssignmentExists) errors.SDRId = "SDR Assignment is already exists !";

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
        this.setState({IsLoading:false})

            return;
        }
        if(IsSDRAssignmentExists){
            toast.error(<div className='toastsize'>SDR Assignment <br/> SDR Assignment is already exists !</div>);
            this.setState({IsLoading:false});
            return;
        }else{
            let RequestBody = {
                UserID: UserID,
                SDRId: formData.SDRId,
                ClientId: formData.ClientId,
                PhoneNumber1: formData.PhoneNumber1,
                PhoneNumber2: formData.PhoneNumber2,
                PhoneNumber3: formData.PhoneNumber3,
                ReminderEmailAccount: formData.SDRReminderEmailAccountId,
                CreatedBy: CUserID,
                CreatedDate: new Date(),
                Role: Role
            }
            const response = await Axios.post(CommonConstants.MOL_APIURL + "/sdrassignment/SaveSDRAssignmentDetails", RequestBody);
            if (response.data?.StatusMessage === "SUCCESS") {
                history.push("/sdrassignments", { tab: 1 });
                this.setState({IsLoading:false})
                toast.success(<div className="toastsize">SDR Assignment <br/> SDR Assignment is saved successfully !</div>)
            } else {
                this.setState({IsLoading:false})
                toast.success(<div className="toastsize">SDR Assignment <br/> SDR Assignment is not saved. Please try again later !</div>)
            }
        }
        
    };
    // Back button functionality
    BackBtn = () => {
        history.push("/sdrassignments", { tab: 1 });
      };

    render() {
        return (
            <>
             {this.state.IsLoading === true ? (
                <div id="hideloding" className="loding-display">
                <img src={loadingicon} />
                </div>
                ) : (
                    <></>
                )}
            <div className="adminmain mheight">
                <Sidebar />
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlepad lg-headertitle float-left">Add SDR Assignment</h4>
                        </div>
                    </div>

           
                        <div className="bg-white p-3 mb-3">
                            <div className="row py-2">
                                {/* Client select field */}
                                <div className="col-lg-6 boxsinput_group">
                                    <div className="row max-cols">
                                        <label className="col-lg-4 view-lable-meet">Client</label>
                                        <div className="col-lg-8">
                                            <select
                                                className="form-control m-input"
                                                onChange={this.handleClientChange}
                                                value={this.state.formData.ClientId}
                                            >
                                                <option value="">--Select--</option>
                                                {this.state.ClientList.map((client) => (
                                                    <option key={client._id} value={client._id}>
                                                        {client.Name}
                                                    </option>
                                                ))}
                                            </select>
                                            {this.state.errors.ClientId && <span style={{ color: "red" }}>{this.state.errors.ClientId}</span>}
                                        </div>
                                    </div>
                                </div>

                                {/* SDR select field */}
                                <div className="col-lg-6 boxsinput_group">
                                    <div className="row max-cols">
                                        <label className="col-lg-4 view-lable-meet">SDR</label>
                                        <div className="col-lg-8">
                                            <select className="form-control m-input" value={this.state.formData.SDRId} onChange={this.sdrHandler}>
                                                <option value="">--Select--</option>
                                                {this.state.SDRList.map((sdr) => (
                                                    <option key={sdr.value} value={sdr.value}>
                                                        {sdr.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {this.state.errors.SDRId && <span style={{ color: "red" }}>{this.state.errors.SDRId}</span>}
                                        </div>
                                    </div>
                                </div>

                                {/* Phone Number 1 */}
                                <div className="col-lg-6 boxsinput_group">
                                    <div className="row max-cols">
                                        <label className="col-lg-4 view-lable-meet">Phone Number 1</label>
                                        <div className="col-lg-8">
                                            <select
                                                className="form-control m-input"
                                                onChange={(e) => this.handlePhoneNumberChange(e, "PhoneNumber1")}
                                                value={this.state.formData.PhoneNumber1}
                                            >
                                                <option value="">--Select--</option>
                                                {this.state.PhoneNumbers1?.map((phone) => (
                                                    <option key={phone._id} value={phone._id}>
                                                        {phone.PhoneNumber}
                                                    </option>
                                                ))}
                                            </select>
                                            {this.state.errors.PhoneNumber1 && <span style={{ color: "red" }}>{this.state.errors.PhoneNumber1}</span>}
                                        </div>
                                    </div>
                                </div>

                                {/* Phone Number 2 */}
                                <div className="col-lg-6 boxsinput_group">
                                    <div className="row max-cols">
                                        <label className="col-lg-4 view-lable-meet">Phone Number 2</label>
                                        <div className="col-lg-8">
                                            <select
                                                className="form-control m-input"
                                                onChange={(e) => this.handlePhoneNumberChange(e, "PhoneNumber2")}
                                                value={this.state.formData.PhoneNumber2}
                                            >
                                                <option value="">--Select--</option>
                                                {this.state.PhoneNumbers2?.map((phone) => (
                                                    <option key={phone._id} value={phone._id}>
                                                        {phone.PhoneNumber}
                                                    </option>
                                                ))}
                                            </select>
                                            {/* {this.state.errors.PhoneNumber2 && <span style={{ color: "red" }}>{this.state.errors.PhoneNumber2}</span>} */}
                                        </div>
                                    </div>
                                </div>

                                {/* Phone Number 3 */}
                                <div className="col-lg-6 boxsinput_group">
                                    <div className="row max-cols">
                                        <label className="col-lg-4 view-lable-meet">Phone Number 3</label>
                                        <div className="col-lg-8">
                                            <select
                                                className="form-control m-input"
                                                onChange={(e) => this.handlePhoneNumberChange(e, "PhoneNumber3")}
                                                value={this.state.formData.PhoneNumber3}
                                            >
                                                <option value="">--Select--</option>
                                                {this.state.PhoneNumbers3?.map((phone) => (
                                                    <option key={phone._id} value={phone._id}>
                                                        {phone.PhoneNumber}
                                                    </option>
                                                ))}
                                            </select>
                                            {/* {this.state.errors.PhoneNumber3 && <span style={{ color: "red" }}>{this.state.errors.PhoneNumber3}</span>} */}
                                        </div>
                                    </div>
                                </div>

                                 {/* SDR Reminder email account select field */}
                                 <div className="col-lg-6 boxsinput_group">
                                    <div className="row max-cols">
                                        <label className="col-lg-4 view-lable-meet">Email Account</label>
                                        <div className="col-lg-8">
                                            <select className="form-control m-input" value={this.state.formData.SDRReminderEmailAccountId} onChange={this.sdrReminderEmailAccountHandler}>
                                                <option value="">--Select--</option>
                                                {this.state.SDRListReminderEmailAccount.map((sdrReminder) => (
                                                    <option key={sdrReminder._id} value={sdrReminder._id}>
                                                        {sdrReminder.SMTPFromEmail}
                                                    </option>
                                                ))}
                                            </select>
                                            {this.state.errors.SDRReminderEmailAccountId && <span style={{ color: "red" }}>{this.state.errors.SDRReminderEmailAccountId}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Submit Button */}
                  <div className="row mb-3">
                    <div className="col-lg-12 pull-left">
                      <button type="submit" onClick={this.handleSubmit} className="btn btn-primary btn-lightgreen mr-1">
                        <i className="la la-save"></i> Save
                      </button>
                      <button type="button" onClick={this.BackBtn} className="btn btn-secondary">
                        <i className="la la-arrow-circle-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
            </div>
                <Footer/>
            </>
            
        );
    }
}



     function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
};

const connectedAddSDRAssignmentPage = connect(mapState, actionCreators)(AddSDRAssignmentPage);
export { connectedAddSDRAssignmentPage as AddSDRAssignmentPage };
