import React, { useEffect } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
const moment = require("moment");
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";


export default function ColdCallersTeamTable({updateFromChild}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [ArchivePopUp, SetArchivePopUp] = React.useState(false);
  const [CancelArchivePopUpval, SetCancelArchivePopUpval] =
    React.useState(false);
  const [Question, SetQuestion] = React.useState("");
  const [Msg, SetMsg] = React.useState("");
  const [type, Settype] = React.useState("");
  const [PopUpButton, SetPopUpButton] = React.useState("");
  const [CancelPopUpval, SetCancelPopUp] = React.useState(false);
  const [ArchivedPopup, SetArchivedPopup] = React.useState(false);
  const [UserID, SetUserID] = React.useState(0);
  const [SortField, SetSortField] = React.useState("ColdCallerTeamName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ColderCaller, SetColderCaller] = React.useState([]);
  const [DefaultColdCaller,SetDefaultColdCaller] = React.useState([])
  const [CUserID,SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("ColdCallerTeamName");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [IsLoading, SetIsLoading] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
    }
    ColdCallerTeamGet(Details.ParentUserID);
  }, [Search, Page, RowsPerPage]);

  // cold caller team get
  const ColdCallerTeamGet = (UID) => {
    SetIsLoading(true);
    var ColdCallerTeam = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      UserID: UID,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/cold_callers_team/ColdCallerTeamListGet",
      method: "POST",
      data: ColdCallerTeam,
    });
    rows1.then((result) => {
      
      
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);

    
      SetColderCaller(result?.data.ColdCallerList);

      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };

  let ColdcallerOptions = ColderCaller.map((val) => {
    return { value: val._id, label: val.ColdCallersName + " " + val.LastName};
  });

  const handleChangeColdCallerOption = async (val, ColdCallerTeamsID) => {
  
    var temArray = [];
    var str_in = {
      id: ColdCallerTeamsID,
    };
    var ColdcallerTeam = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/cold_callers_team/ColdCallerTeamGetByID",
      method: "POST",
      data: str_in,
    });
   
   
    if (ColdcallerTeam.data.Data != null) {
      
      
      if (ColdcallerTeam.data.Data.ColdCollerList.length < val?.length) {
        val?.map((item) => {
          temArray.push({
            ColdCallerID: item.value,
            ColdCallersName: item.label,
           
          });
        });
        
        var data = {
          ColdCallerlistArray: temArray,
          ColdCallersTeamId: ColdCallerTeamsID,
        };
        
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/cold_callers_team/UpdateColdCallerDropDown",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(<div>Sales development reps successfully added.</div>);
            // window.location.reload(true);
          } else {
            toast.error(res.data.Message);
          }
        });
      } else {
        var str_inDelete = {
          ID: ColdCallerTeamsID,
          CCList: val,
        };
        const UpdateData = await Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/cold_callers_team/DeleteColdCallerDropDown",
          method: "POST",
          data: str_inDelete,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              "Sales development reps successfully remove."
            );
            // window.location.reload(true);
          } else {
            toast.error(res.data.Message);
          }
        });
      }
    }else{

    }
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
      SetPage(1);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // cold caller team delete
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a cold caller team.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No, cancel!",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          ColdCallerTeamsID: id,
          IsDeleted: true,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/cold_callers_team/ColdCallerTeamDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "cold caller team deleted successfully.",
                "success"
              );
             // window.location.reload();
              ColdCallerTeamGet(UserID);
            history.push("/coldcallerteams");
            SetPage(1);
            SetSearch("");
            document.getElementById("search").value = "";
            updateFromChild(true);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // cold callers team edit
  const EditBtn = (id) => {
    history.push({ pathname: "/editcoldcallerteams", state: { data: id } });
  };

  // handle click
  const handleClick = (event) => {
    SetAnchorEl(event.currentTarget);
  };

  // handle close
  const handleClose = () => {
    SetAnchorEl(null);
  };

  // archive submit
  const ArchiveSubmit = () => {
    SetArchivePopUp(false);

    if (type == "archive") {
      SetMsg("Responder Archived successfully.");
      SetQuestion("Archived!");
      SetArchivedPopup(true);
      ArchiveUser();
    } else if (type == "unarchive") {
      SetMsg("Responder Unarchived successfully.");
      SetQuestion("Unarchived!");
      SetArchivedPopup(true);
      UnArchiveUser();
    } else {
      SetMsg("Responder Delete successfully.");
      SetQuestion("Delete");
      SetArchivedPopup(true);
      DeleteUser();
    }
  };

  // archive cancle
  const ArchiveCancle = () => {
    SetArchivePopUp(false);
    OpenArchivePopUp();
  };

  // archive open popup
  const OpenArchivePopUp = () => {
    SetCancelArchivePopUpval(true);
  };

  // archive cancle popup
  const CancleArchivePopUp = () => {
    SetCancelArchivePopUpval(false);
  };

  // responder archive
  const ResponderArchive = () => {
    SetArchivedPopup(false);
  };

   //get sort field data
   const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }

    SetSortField(Field);
    SetSflag(SearchedVal !== "");
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: Field,
        SortBy: SortBy,
        Type: "User",
        UserID: UserID,
        Search:SearchedVal
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/cold_callers_team/ColdCallerTeamListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetColderCaller(Result?.data.ColdCallerList);
        SetIsLoading(false);
      }).catch((error) => {
        SetIsLoading(false);
      });
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <Popup open={ArchivePopUp} modal>
        <div className="modal-black"></div>
        <div className="filterPopup maxpopup">
          <div className="paddingboxTerms">
            <div className="modal-content bordernone aligncentertext">
              <div class="swal2-icon swal2-warning swal2-animate-warning-icon">
                <span class="swal2-icon-text">!</span>
              </div>
              <h3>{Question}</h3>
              <p>{Msg}</p>
              <div class="swal2-actions">
                <button
                  type="button"
                  onClick={ArchiveCancle}
                  class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2"
                >
                  No, cancel!
                </button>
                <button
                  type="button"
                  onClick={ArchiveSubmit}
                  class="swal2-confirm btn btn-success m-btn m-btn--custom"
                >
                  {PopUpButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup open={CancelArchivePopUpval} modal>
        <div className="modal-black"></div>
        <div className="filterPopup maxpopup">
          <div className="paddingboxTerms">
            <div className="modal-content bordernone aligncentertext">
              <div class="swal2-icon swal2-error swal2-animate-error-icon d-flex">
                <span class="swal2-x-mark">
                  <span class="swal2-x-mark-line-left"></span>
                  <span class="swal2-x-mark-line-right"></span>
                </span>
              </div>
              <h3>Cancelled</h3>
              <p>Your data is safe :)</p>
              <div class="swal2-actions">
                {/* <button type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
                <button
                  onClick={CancleArchivePopUp}
                  type="button"
                  class="swal2-confirm btn btn-success m-btn m-btn--custom"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup open={ArchivedPopup} modal>
        <div className="modal-black"></div>
        <div className="filterPopup maxpopup">
          <div className="paddingboxTerms">
            <div className="modal-content bordernone aligncentertext">
              <div class="swal2-icon swal2-success swal2-animate-success-icon">
                <div class="swal2-success-circular-line-left"></div>
                <span class="swal2-success-line-tip"></span>
                <span class="swal2-success-line-long"></span>
                <div class="swal2-success-ring"></div>
                <div class="swal2-success-fix"></div>
                <div class="swal2-success-circular-line-right"></div>
              </div>
              <h3>{Question}</h3>
              <p>{Msg}</p>
              <div class="swal2-actions">
                {/* <button type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
                <button
                  type="button"
                  onClick={ResponderArchive}
                  class="swal2-confirm btn btn-success m-btn m-btn--custom"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div class='bg-white p-3'>
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" aria-label="collapsible table">   
                   <TableHead>
              <TableRow>
              <TableCell onClick={() => { SortData("ColdCallerTeamName"); setSortedColumn("ColdCallerTeamName");}}>
                    Add Sales Development Reps Team Name
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "ColdCallerTeamName" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "ColdCallerTeamName" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("IsColdCallerTeamUpdate"); setSortedColumn("IsColdCallerTeamUpdate");}}>
                    Sales Development Reps
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsColdCallerTeamUpdate" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsColdCallerTeamUpdate" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("CreatedDt"); setSortedColumn("CreatedDt");}}>
                    Created Date
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "CreatedDt" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "CreatedDt" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.ColdCallerTeamName}</TableCell>
                    <TableCell className="dropbox-revert" width={200}>
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={ColdcallerOptions}
                        id="ContactTagID"
                        defaultValue={row.ColdCollerList.map(coldColler => {
                          return { value: coldColler.ColdCallersID, label: coldColler.ColdCallersName };
                        })}
                        isMulti
                        onChange={(val) =>
                          handleChangeColdCallerOption(
                            val,
                            row._id
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {moment(new Date(row.CreatedDt).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>

                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Delete"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row ">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row ">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
      </div>
    </div>
  );
}
