import React from "react";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import ColdcallersteamsTable from "../../../_components/user/ColdCallers/ColdcallersteamsTable.js";

import { history } from "../../../_helpers";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";

class ColdCallerTeamsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupval: true,
      InvitePopup: false,
    };
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this);
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this);
    this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this);
    this.ColdCallerTeamAdd = this.ColdCallerTeamAdd.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.title = "Add Sales Development Reps Teams | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.UserID = Details.ChildUserID;
    }
  }

  //   cold caller add page
  ColdCallerTeamAdd() {
    history.push("/addcoldcallerteams");
  }

  //  open invite popup
  OpenInvitePopup() {
    
    this.setState({ InvitePopup: true });
    
  }
  //   close invite popup
  CloseInvitePopup() {
    
    this.setState({ InvitePopup: false });
  }
  // submit invite popup
  SubmitInvitePopup() {
    this.setState({ InvitePopup: false });
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if(value == true){
      this.componentDidMount()
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
            <div className="bodyhome">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="headertitlebd lg-headertitle mt-0">Add Sales Development Reps Teams</h4>
                </div>
                <div className="col">
                  <div className="listing-li float-right ">
                    <ul class='my-0'>
                      <li>
                        <a
                          onClick={() => {
                            this.ColdCallerTeamAdd();
                          }}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-plus la-icon-font-size-13"></i>
                          <span>Add</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> 
              <div className="col padd-0"></div>
            

            <div className="">
              <div className="row">
                <div className="col"> 
                    <ColdcallersteamsTable updateFromChild={this.UpdateFromChild} /> 
                </div>
              </div>
            </div>
            </div> 
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedColdCallerTeamsPage = connect(
  mapState,
  actionCreators
)(ColdCallerTeamsPage);
export { connectedColdCallerTeamsPage as ColdCallerTeamsPage };
