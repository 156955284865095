import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';
import Sidebar from '../../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../../_components/user/footer/footer';
import Axios from 'axios';
import { history } from '../../../_helpers';
import ReactApexchart  from "react-apexcharts";
import { CommonConstants } from '../../../_constants/common.constants';

import{GetUserDetails} from '../../../_helpers/Utility'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stepwizardclient from '../../../_components/clients/react-step-wizard/campang-step-wizard';



class CAddCampaignsPage extends React.Component {
  constructor(props) {
    debugger
    var Details = GetUserDetails();
    super(props);

    this.state = {
        pager: {},
        pageOfItems: [],
        files: [],
        list:[],
        columns:[],
        reset:false,
        csvData:[],

        fileds: {},
        errors: {},
        statusAvailable: null,
        ClientID:Details.ClientID ,
        UserID:Details.ChildUserID,
        Role:Details.Role,
    CampaignStepID:""
    }; 
}  
        // npm install react-multistep
        componentDidMount(){
            debugger
            if(this.props.location.state != undefined){
                this.setState({CampaignStepID:this.props.location.state.data})
            }
            
        }
  
render() {
    return (
        <>
            <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">

                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlenop lg-headertitle float-left pl-0 py-4 mb-0">Add Campaign</h4>
                        </div> 
                    </div> 
                    
                    <div class="bg-white">
                        <div className="row pt-4">  
                            <Stepwizardclient CreateTemplateCampaign={this.props.location.state!=undefined?this.props.location.state.data:""} prospectID ={this.props.location.data != undefined?this.props.location.data:""} PEmailData = {this.props.location.EmailData != undefined?this.props.location.EmailData:""}/> 
                        </div>     
                    </div>
                </div> 
            </div>
            <Footer />
        </>
    );
}
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCAddCampaignsPage = connect(mapState, actionCreators)(CAddCampaignsPage);
export { connectedCAddCampaignsPage as CAddCampaignsPage };